import React from "react";
import { I18n } from "react-redux-i18n";

export const Warning: React.FC = () => (
  <svg
    width="22px"
    height="19px"
    viewBox="0 0 22 19"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>{I18n.t("hoverIcons.warning")}</title>
    <g
      id="Design-System"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Icons-pg2"
        transform="translate(-117.000000, -836.000000)"
        fill-rule="nonzero"
      >
        <g
          id="warning_black_24dp"
          transform="translate(117.000000, 836.000000)"
        >
          <polygon id="Shape" fill="#CD3C14" points="0 19 22 19 11 0"></polygon>
          <polygon
            id="Path"
            fill="#FFFFFF"
            points="13.872 8 11 10.872 8.128 8 7 9.128 9.872 12 7 14.872 8.128 16 11 13.128 13.872 16 15 14.872 12.128 12 15 9.128"
          ></polygon>
        </g>
      </g>
    </g>
  </svg>
);
