import React from "react";
import { I18n } from "react-redux-i18n";

export const Back: React.FC = () => (
  <svg
    width="8px"
    height="12px"
    viewBox="0 0 8 12"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>{I18n.t("common.return")}</title>
    <g
      id="Homepage"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Restaurant/Menu/OrderState/Validée"
        transform="translate(-157.000000, -113.000000)"
      >
        <g
          id="Restaurant-Details"
          transform="translate(149.201613, 104.000000)"
        >
          <g id="Group" transform="translate(0.000000, 3.000000)">
            <g
              id="Icons/Navigation/Arrows/Accordeon_arrow_default_black"
              transform="translate(12.000000, 12.000000) rotate(90.000000) translate(-12.000000, -12.000000) "
            >
              <g
                id="keyboard_arrow_right_black_24dp"
                transform="translate(12.000000, 12.000000) rotate(90.000000) translate(-12.000000, -12.000000) "
              >
                <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                <polygon
                  id="Path"
                  fill="#090909"
                  fill-rule="nonzero"
                  points="8.59 16.59 13.17 12 8.59 7.41 10 6 16 12 10 18"
                ></polygon>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
