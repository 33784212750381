"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_USER_INFO_FOR_TRACKING = void 0;
const client_1 = require("@apollo/client");
exports.GET_USER_INFO_FOR_TRACKING = client_1.gql `
  query getUserInfoForTracking {
    getUser {
      id
      language
      guests {
        edges {
          node {
            id
            holding {
              id
              name
              brand
              address {
                zip
              }
            }
          }
        }
      }
    }
  }
`;
