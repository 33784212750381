import { GetPickupPointsCommand, getUserLastUsedPickupPointRequest } from "@foodi/core";
import { ThunkResult } from "@redux";
import { displayToastError } from "@utils";

const ThunkActionCreators = {
  getOfferTemplatePickupPoints: (
    params: GetPickupPointsCommand
  ): ThunkResult<Promise<any>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { getPickupPoints } = getDependencies();
      return getPickupPoints.execute(params);
    } catch (error) {
      displayToastError(dispatch);
      return Promise.reject(error);
    }
  },
  getUserLastUsedPickupPoint: (
    params: getUserLastUsedPickupPointRequest
  ): ThunkResult<Promise<any>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { getUserLastUsedPickupPoint } = getDependencies();
      return getUserLastUsedPickupPoint.execute(params);
    } catch (error) {
      displayToastError(dispatch);
      return Promise.reject(error);
    }
  }
};

export { ThunkActionCreators as PickupPointsThunks };
