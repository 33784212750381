"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetBrand = void 0;
class GetBrand {
    constructor(marketingCardService) {
        this.marketingCardService = marketingCardService;
    }
    execute(request) {
        return this.marketingCardService.getBrand(request);
    }
}
exports.GetBrand = GetBrand;
