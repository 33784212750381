import { Colors } from "@constants";
import { GlobalState } from "@redux";
import { Title22 } from "@stylesheets";
import React, { useMemo } from "react";
import { StyleSheet, TouchableOpacity, ViewStyle } from "react-native";
import { useSelector } from "react-redux";

interface IProps {
  title: string;
  forwardTestID?: string;
  style?: ViewStyle;
  textStyle?: ViewStyle;
  onPress: Function;
  isDisabled: boolean;
}

export const ValidButton: React.FC<IProps> = React.memo(
  ({ style, forwardTestID, title, onPress, textStyle, isDisabled }) => {
    const brandTheme = useSelector(
      (state: GlobalState) => state.brandTheme.brandTheme
    );

    const styles = useMemo(() => _styles(brandTheme), [brandTheme]);

    return (
      <TouchableOpacity
        disabled={isDisabled}
        testID={forwardTestID}
        style={[styles.container, style, isDisabled && styles.disabled]}
        onPress={onPress as () => void}
      >
        <Title22 style={[textStyle, styles.textStyle]}>{title}</Title22>
      </TouchableOpacity>
    );
  }
);

const _styles = (brandTheme: any) =>
  StyleSheet.create({
    container: {
      backgroundColor:
        brandTheme.buttonActionColors?.backgroundColor || Colors.foodiDefault,
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 8,
    },
    disabled: {
      backgroundColor: Colors.disabledBackground,
    },
    textStyle: {
      color: brandTheme.buttonActionColors?.textColor || Colors.foodiBlack,
    },
  });
