import React, { useEffect, useMemo, useRef, useState } from "react";
import { StyleSheet, View } from "react-native";
import { Title22, Title36 } from "@stylesheets";
import { DEV, TestIDs } from "@utils";
import { I18n } from "react-redux-i18n";
import { Colors, Spacing } from "@constants";
import { HistoricalList, TransactionTabs } from "@atomic";

import {
  HistoricalThunks,
  HistoricalViewModel,
  IHistoric,
  IHistoricalType,
} from "@modules";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "@redux";
import { useDevices } from "@hooks";
import { NavigationProp } from "@react-navigation/native";

interface IProps {
  navigation: NavigationProp<any>;
}

export const HistoricalTemplate: React.FC<IProps> = React.memo(
  ({ navigation }) => {
    const historicPerPage = 20;
    const historicalVM = new HistoricalViewModel();
    const dispatch = useDispatch();
    const [tabs, setTabs] = useState<IHistoricalType[]>([]);
    const [currentTab, setCurrentTab] = useState<number>(0);
    const [groupedHistoric, setGroupedHistoric] = useState<
      Record<string, IHistoric[]>
    >({});

    const [isMobile] = useDevices();
    const styles = useMemo(() => _styles(isMobile), [isMobile]);
    const Title = isMobile ? Title22 : Title36;

    const userlanguage = useSelector(
      (state: GlobalState) => state.auth?.userInfo?.language
    );

    const prevSelectedTab = useRef(-1);

    // selectors
    const { payments, invoices } = useSelector(
      (state: GlobalState) => state.historical
    );

    // useEffects
    useEffect(() => {
      setTabs(historicalVM.getInitHistoricalTabs());
      handleHistorical();
    }, []);

    useEffect(() => {
      tabs.length > 0 &&
        setTabs(historicalVM.getTranslatedHistoricalTabs(tabs));
      historicalVM.setPayments(payments);
      historicalVM.setInvoice(invoices);
      historicalVM.filterHistoric(currentTab);
      setGroupedHistoric(historicalVM.getGroupedHistoric());
    }, [currentTab, payments, invoices, userlanguage]);

    const handleHistorical = () => {
      dispatch(
        HistoricalThunks.getScolapassPayments({
          first: historicPerPage,
        })
      );
      dispatch(
        HistoricalThunks.getScolapassInvoices({
          first: historicPerPage,
        })
      );
    };

    const onTabSelected = (tabIndex: number) => {
      if (prevSelectedTab.current === tabIndex) return;
      prevSelectedTab.current = tabIndex;
      setTabs(historicalVM.getHistoricalTabs(tabIndex));
      setCurrentTab(tabIndex);
    };

    const onInvoiceSelected = async (invoiceName: string) => {
      const pdfFile: any = await dispatch(
        HistoricalThunks.getInvoiceScolapassFile(invoiceName)
      );

      if (!pdfFile) {
        return;
      }

      const linkSource = `data:application/octet-stream;base64,${pdfFile?.getPDFFile}`;
      const downloadLink = document.createElement("a");

      downloadLink.href = linkSource;
      downloadLink.download = DEV ? invoiceName : atob(invoiceName);
      downloadLink.click();

      // cleans up the element, after no longer being necessary and avoid pollution
      downloadLink.remove();
    };

    return (
      <View style={styles.container}>
        <Title
          testID={TestIDs.home.balance.texts.historicalTitle}
          style={styles.title}
        >
          {I18n.t("refill.myHistorical")}
        </Title>
        <View style={styles.tabsContainer}>
          <TransactionTabs
            isHistorical
            onTabSelected={onTabSelected}
            tabs={tabs}
            styleContainer={styles.tabMargin}
          />
          <HistoricalList
            navigation={navigation}
            groupedHistoric={groupedHistoric}
            currentTab={currentTab}
            callback={onInvoiceSelected}
            isClickable={currentTab === 0}
          />
        </View>
      </View>
    );
  }
);

const _styles = (isMobile: boolean) =>
  StyleSheet.create({
    container: {
      marginTop: Spacing.L,
      paddingBottom: isMobile ? Spacing.L : Spacing.XL,
      backgroundColor: Colors.background1,
      borderRadius: 8,
      marginBottom: isMobile ? Spacing.XL : 0,
    },
    title: {
      marginTop: isMobile ? Spacing.XL : 40,
      color: Colors.foodiBlack,
      fontFamily: "manrope-bold",
    },
    tabsContainer: {
      flex: 1,
      marginTop: isMobile ? Spacing.L : Spacing.XL,
      alignItems: "center",
    },
    tabMargin: {
      paddingRight: Spacing.XL,
    },
  });
