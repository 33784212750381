import { IOptoutCode, IUserOptout } from '@foodi/core';
import _ from "lodash";

export class OptoutsViewModel {
  static initOptouts(
    allOptouts: { [key in IOptoutCode]: { enabled: boolean } },
    selectedOptouts: IUserOptout[]
  ) {
    const _allOptouts = _.cloneDeep(allOptouts);
    selectedOptouts.forEach((userOptout) => (_allOptouts[userOptout.code] = {
      enabled: false
    }));
    return _allOptouts;
  }

  static getSelectedOptouts(
    allOptins: { [key in IOptoutCode]: { enabled: boolean } }
  ): {code: IOptoutCode }[] {
    return Object.entries<any>(allOptins)
      .filter(([code, value]) => !value.enabled)
      .map(([code, value]: any) => (code))
      .flat();
  }

  static userHasOptout(optouts: IUserOptout[], desiredOptoutCode: IOptoutCode): boolean {
    return optouts.some((o) => o.code === desiredOptoutCode);
  }
}
