"use strict";
/* istanbul ignore file */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RefreshToken = void 0;
class RefreshToken {
    constructor(authService) {
        this.authService = authService;
    }
    execute() {
        return this.authService.refreshToken();
    }
}
exports.RefreshToken = RefreshToken;
