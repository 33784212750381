import { Warning } from "@assets";
import { Button } from "@atomic";
import { OffersActions } from "@modules";
import { GlobalState } from "@redux";
import { TestIDs } from "@utils";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { I18n } from "react-redux-i18n";
import {
  Container,
  Title,
  ItemText,
  ListContainer,
  Row,
  IconContainer,
  Wrapper,
  ContentContainer,
  TextContainer,
  ButtonContainer,
  FormulaContainer,
} from "./OutOfStockModal.style";

interface IProps {}

export const OutOfStockModal: React.FC<IProps> = React.memo(({}) => {
  const dispatch = useDispatch();

  const { outOfStockItems, outOfStockItemsFormula, outOfStockModalOpen } =
    useSelector((state: GlobalState) => state.offers) || {};

  const handleGoToCart = () => {
    dispatch(OffersActions.removeOrderItems([...outOfStockItems, ...outOfStockItemsFormula]));
  };

  return !outOfStockModalOpen ? null : (
    <Wrapper testID={TestIDs.restaurantDetail.views.outOfStockModalContainer}>
      <Container>
        <ContentContainer>
          <Row>
            <IconContainer>
              <Warning />
            </IconContainer>
            <TextContainer>
              {!!outOfStockItems.length && <>
                <Title>
                  {I18n.t("restaurantDetail.productNoLongerAvailable")}
                </Title>
                <ListContainer>
                  {outOfStockItems?.map((o, index) => (
                    <ItemText
                      key={`${o?.quantity} ${o?.title}_${index}`}
                    >{`${o?.quantity} ${o?.title}`}</ItemText>
                  ))}
                </ListContainer>
              </>}
              {!!outOfStockItemsFormula.length && <>
                <Title>
                  {I18n.t("restaurantDetail.productNoLongerAvailableFormulaText")}
                  {outOfStockItemsFormula?.map((o) => I18n.t("restaurantDetail.productNoLongerAvailableFormulaSubText", {formulaSubProduct: o.title, formulaProduct: o.parentTitle})).join(', ')}
                </Title>
                <FormulaContainer>
                  {I18n.t("restaurantDetail.productNoLongerAvailableFormulaFooterText",
                    {formulaProduct: outOfStockItemsFormula?.map((o) => o.parentTitle).join(', ')})
                  }
                </FormulaContainer>
              </>}
              <ButtonContainer>
                <Button
                  forwardTestID={TestIDs.components.Button.views.container}
                  label={I18n.t("restaurantDetail.backToCart")}
                  onPress={handleGoToCart}
                />
              </ButtonContainer>
            </TextContainer>
          </Row>
        </ContentContainer>
      </Container>
    </Wrapper>
  );
});
