"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InMemoryMarketingCardService = void 0;
const domain_1 = require("../../../domain");
class InMemoryMarketingCardService {
    constructor(newsFixture, brandFixture) {
        this.newsFixture = newsFixture;
        this.brandFixture = brandFixture;
    }
    /* istanbul ignore next */
    getMarketingCards(variables) {
        throw new Error("Method not implemented.");
    }
    getPublishedMarketingCardsNew(variables) {
        if (this.newsFixture) {
            return Promise.resolve(this.newsFixture);
        }
        return Promise.reject(new domain_1.MarketingCardErrors.GetPublishedMarketingCardsNewRequestError("GET_PUBLISHED_MARKETING_CARDS_NEW_REQUEST_FAILED"));
    }
    getBrand(variables) {
        if (this.brandFixture) {
            return Promise.resolve(this.brandFixture);
        }
        return Promise.reject(new domain_1.MarketingCardErrors.GetBrandRequestError("GET_BRAND_REQUEST_FAILED"));
    }
}
exports.InMemoryMarketingCardService = InMemoryMarketingCardService;
