import React from "react";

export const BigBlackArrowRight: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px">
    <g fill="none" fillRule="evenodd">
      <circle cx={15} cy={15} r={15} fill="#090909" />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M11.25 20.738 17.045 15 11.25 9.262 13.034 7.5l7.591 7.5-7.591 7.5z"
      />
    </g>
  </svg>
);
