"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetOfferTemplates = void 0;
class GetOfferTemplates {
    constructor(offerService) {
        this.offerService = offerService;
    }
    execute(request) {
        return this.offerService.getOfferTemplates(request);
    }
}
exports.GetOfferTemplates = GetOfferTemplates;
