"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_PUBLISHED_MARKETING_CARDS_NEW = void 0;
const client_1 = require("@apollo/client");
exports.GET_PUBLISHED_MARKETING_CARDS_NEW = client_1.gql `
  query getPublishedMarketingCardsNew(
    $idHolding: ID!
    $type: [String!]!
    $language: [String!]
  ) {
    getPublishedMarketingCardsNew(
      idHolding: $idHolding
      type: $type
      language: $language
    ) {
      id
      type
      publicationRange
      linkToExternalPage
      mainImage {
        path
      }
      marketingCardContents {
        id
        resume
        language
        contentTitle
        content {
          key
          content
        }
      }
    }
  }
`;
