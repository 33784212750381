import React from "react";
import { Pressable } from "react-native";
import { getBaseOs, TestIDs } from "@utils";
import { NavigationProp } from "@react-navigation/native";
import { CloseBlack, MasterLogo } from "@assets";
import { I18n } from "react-redux-i18n";
import {
  SeeAction,
  ButtonText,
  Container,
  LeftContainer,
  MiddleContainer,
  RightContainer,
  Title,
  SubTitle,
} from "./GetAppMobile.style";
import { TextContainer } from "../RestaurantCard/RestaurantCard.style";

interface IProps {
  navigation?: NavigationProp<any>;
  closeGetApp: () => void;
}

export const GetAppMobile: React.FC<IProps> = React.memo(
  ({ navigation, closeGetApp }) => {
    const currentOS = getBaseOs();

    const handleSeeAction = () => {
      if (currentOS === "iOS") {
        window
          .open(
            "https://apps.apple.com/fr/app/foodi/id1377579624?l=fr&ls=1",
            "_blank"
          )
          ?.focus();
        return;
      }

      window
        .open(
          "https://play.google.com/store/apps/details?id=fr.compassgroup.foodi",
          "_blank"
        )
        ?.focus();
    };

    return (
      <Container testID={TestIDs.home.gepAppMobile.views.container}>
        <LeftContainer>
          <Pressable
            testID={TestIDs.home.gepAppMobile.actions.closeComponent}
            onPress={closeGetApp}
          >
            <CloseBlack />
          </Pressable>
        </LeftContainer>
        <MiddleContainer>
          <MasterLogo />
          <TextContainer>
            <Title>{I18n.t("homepage.getAppMobile.title")}</Title>
            <SubTitle>{I18n.t("homepage.getAppMobile.subTitle")}</SubTitle>
          </TextContainer>
        </MiddleContainer>
        <RightContainer>
          <SeeAction
            testID={TestIDs.home.gepAppMobile.actions.goToStore}
            onPress={handleSeeAction}
          >
            <ButtonText>{I18n.t("homepage.getAppMobile.button")}</ButtonText>
          </SeeAction>
        </RightContainer>
      </Container>
    );
  }
);
