"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateEpointageComment = exports.AddEpointageComment = void 0;
class AddEpointageComment {
    constructor(epointageRepository) {
        this.epointageRepository = epointageRepository;
    }
    execute(request) {
        return this.epointageRepository.addEpointageComment(request);
    }
}
exports.AddEpointageComment = AddEpointageComment;
class UpdateEpointageComment {
    constructor(epointageRepository) {
        this.epointageRepository = epointageRepository;
    }
    execute(request) {
        return this.epointageRepository.updateEpointageComment(request);
    }
}
exports.UpdateEpointageComment = UpdateEpointageComment;
