import React, { useEffect, useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { Colors } from "@constants";
import { IStep } from "@modules";
import { Step } from "@atomic";
import { TestIDs } from "@utils";
import { useDevices } from "@hooks";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "@redux";
import { setLocale } from "react-redux-i18n";
interface IProps {
  callback?: (stepNumber: number) => void;
  stepperData: IStep[];
}

export const Stepper: React.FC<IProps> = React.memo(
  ({ stepperData, callback }) => {
    const [isMobile] = useDevices();
    const styles = useMemo(() => _styles(isMobile), [isMobile]);

    const onSelectStep = (stepNumber: number) => {
      callback?.(stepNumber);
    };
    
    const userLanguage = useSelector(
      (state: GlobalState) => state.auth?.userInfo?.language || state.i18n?.locale
    );
    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(setLocale(userLanguage));
    }, [userLanguage]);


    return (
      <View style={styles.container}>
        <View style={styles.line} />
        <View style={styles.stepsContainer}>
          {stepperData.map((step, index) => {
            const stepNumber = index + 1;
            return (
              <Step
                key={index}
                forwardTestID={`${TestIDs.account.actions.step}${index}`}
                stepStatus={step.status}
                stepNumber={stepNumber}
                title={step.title}
                onSelectStep={onSelectStep}
                isFinalStep={stepperData.length === stepNumber}
              />
            );
          })}
        </View>
      </View>
    );
  }
);

const _styles = (isMobile: boolean) =>
  StyleSheet.create({
    container: {
      width: isMobile ? "85vw" : 653,
    },
    stepsContainer: {
      flex: 1,
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: "row",
    },
    line: {
      position: "absolute",
      top: isMobile ? 20 : 25,
      height: 2,
      width: "75%",
      backgroundColor: Colors.foodiBlack,
      alignSelf: "center",
    },
  });
