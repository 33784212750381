"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPSERT_ORDER = void 0;
const client_1 = require("@apollo/client");
exports.UPSERT_ORDER = client_1.gql `
  mutation upsertOrder($input: InputUpsertOrder!) {
    order: upsertOrder(input: $input) {
      id
    }
  }
`;
