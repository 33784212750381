"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetI18nProviderLocale = void 0;
class GetI18nProviderLocale {
    constructor(userRepository) {
        this.userRepository = userRepository;
    }
    execute(callback) {
        return this.userRepository.getI18nProviderLocale(callback);
    }
}
exports.GetI18nProviderLocale = GetI18nProviderLocale;
