import React, { useEffect, useMemo, useState } from "react";
import { Pressable, StyleSheet, View, Text } from "react-native";
import { Title16, Title30 } from "@stylesheets";
import { Colors } from "@constants";
import { I18n } from "react-redux-i18n";
import { Back } from "@assets";
import { TestIDs } from "@utils";
import { useDevices } from "@hooks";
import _ from "lodash";
import { NavigationProp } from "@react-navigation/native";
import { OrderViewModel } from "@modules/home";
import { useDispatch, useSelector } from "react-redux";
import { Order } from "@foodi/core";
import { GlobalState } from "@redux";
import { LocalLoader, OrdersList } from "@atomic";
import moment from "moment";

interface IProps {
  handleShowLeftContainer: () => void;
  navigation: NavigationProp<any>;
}

export const OrdersScreen: React.FC<IProps> = React.memo(
  ({ handleShowLeftContainer, navigation }) => {
    const dispatch = useDispatch();

    const idGuest =
      useSelector((state: GlobalState) => state?.auth?.userInfo?.idGuest) ?? "";

    const [isMobile] = useDevices();
    const styles = useMemo(() => _styles(isMobile), [isMobile]);

    const [isLoading, setLoading] = useState<boolean>(false);
    const [groupedOrders, setGroupedOrders] = useState<Record<string, Order[]>>(
      {}
    );

    const orderVM = new OrderViewModel(dispatch, idGuest);

    const handleGetAllOrders = async () => {
      setLoading(true);
      const ordersResult: any = await orderVM.getAllOrdersResult();

      if (ordersResult && ordersResult.orders !== undefined) {
        const groupedOrders = orderVM.getGroupedOrders(ordersResult.orders);
        setGroupedOrders(groupedOrders);
      }
      setLoading(false);
    };

    useEffect(() => {
      handleGetAllOrders();
    }, []);

    return (
      <View
        testID={TestIDs.profile.views.myOrdersView}
        style={[styles.container, isLoading && styles.minHeightcontainer]}
      >
        {isMobile && (
          <Pressable
            style={styles.goBackContainer}
            testID={TestIDs.profile.actions.goBack}
            onPress={handleShowLeftContainer}
          >
            <Back />
            <Text style={styles.goBackText}>{I18n.t("common.return")}</Text>
          </Pressable>
        )}
        <Title30 isBold>{I18n.t("profile.myOrders")}</Title30>
        <OrdersList navigation={navigation} groupedOrders={groupedOrders} />
        {!isLoading && Object.keys(groupedOrders).length === 0 && (
          <View testID={TestIDs.profile.texts.noOrders} style={styles.noOrders}>
            <Title16>{I18n.t("profile.orders.noOrders")}</Title16>
          </View>
        )}
        {isLoading && (
          <View style={styles.loader}>
            <LocalLoader />
          </View>
        )}
      </View>
    );
  }
);

const _styles = (isMobile: boolean) =>
  StyleSheet.create({
    container: {
      flex: 1,
      width: isMobile ? "90vw" : "100%",
      borderWidth: isMobile ? 0 : 1,
      borderRadius: 8,
      borderColor: Colors.darkGrey,
      justifyContent: "flex-start",
      alignItems: "flex-start",
      paddingHorizontal: isMobile ? 0 : 40,
      paddingVertical: isMobile ? 0 : 20,
      marginBottom: isMobile ? 0 : 20,
    },
    noOrders: {
      paddingVertical: 10,
    },
    minHeightcontainer: {
      minHeight: isMobile ? 400 : 300,
    },
    goBackContainer: {
      flexDirection: "row",
      alignItems: "center",
      maxWidth: 250,
      marginBottom: 10,
    },
    goBackText: {
      color: Colors.foodiBlack,
      fontFamily: "manrope-bold",
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: 30,
      marginLeft: 7,
    },
    loader: {
      height: "100%",
      width: "100%",
      position: "absolute",
      justifyContent: "center",
      alignItems: "center",
    },
  });
