"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_TRANSACTIONS = void 0;
const client_1 = require("@apollo/client");
const transactionsFragment_1 = require("./transactionsFragment");
exports.GET_TRANSACTIONS = client_1.gql `
  ${transactionsFragment_1.TRANSACTIONS_FRAGMENT}
  query getTransactions($after: Cursor, $first: Int) {
    getUser {
      id
      guests {
        edges {
          node {
            id
            transactions: tickets(
              order: "-date"
              first: $first
              after: $after
            ) {
              ...transactionsFragment
            }
          }
        }
      }
    }
  }
`;
