"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetPdfExport = void 0;
class GetPdfExport {
    constructor(epointageRepository) {
        this.epointageRepository = epointageRepository;
    }
    execute(request) {
        return this.epointageRepository.getPdfExport(request);
    }
}
exports.GetPdfExport = GetPdfExport;
