"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pickupPointsFixture = void 0;
exports.pickupPointsFixture = {
    loading: false,
    pickupPoints: [
        {
            id: 'PickUpPointWithSelected:3',
            numericId: 3,
            idHolding: 142,
            name: 'almada',
            withdrawalSchedule: "as 14h",
            companyCode: "Holding:142",
            description: null,
            selected: true
        }
    ]
};
