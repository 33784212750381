import { IOptinCode, IUserOptin } from "@foodi/core";
import _ from "lodash";

export class TableNumberViewModel {
  static isTableNumberValid(tableNumber: number): boolean {
    return tableNumber > 0 && tableNumber < 1000;
  }

  static getCorrectValue(_tableNumber: string): string {
    let tableNumber = parseInt(_tableNumber);
    tableNumber = isNaN(tableNumber) ? 0 : tableNumber;
    return tableNumber === 0 ? "" : `${tableNumber}`;
  }

  static isEmpty(tableNumber: number | undefined): boolean {
    return [NaN, undefined].includes(tableNumber);
  }
}
