import React from "react";
import { Title16 } from "@stylesheets";
import { DownloadOptions, RadioGroup } from "@atomic";
import { View, StyleSheet, Pressable } from "react-native";
import { I18n } from "react-redux-i18n";
import { NextStep } from "@assets";

interface IProps {
  handleNextStep: () => void;
  downloadOption: string | undefined;
  setDownloadOption: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const Step1: React.FC<IProps> = ({
  handleNextStep,
  downloadOption,
  setDownloadOption,
}) => (
  <View>
    <Title16 bold>{I18n.t("epointage.homepage.downloadModal.options")}</Title16>
    <View style={styles.optionsContainer}>
      <View style={styles.optionRow}>
        <RadioGroup
          radioButtonsProps={[{ id: DownloadOptions.EXCEL }]}
          value={downloadOption}
          onPress={() => setDownloadOption(DownloadOptions.EXCEL)}
          style={styles.buttonContainer}
        />
        <Title16>
          {I18n.t("epointage.homepage.downloadModal.excelOption")}
        </Title16>
      </View>
      <View style={styles.optionRow}>
        <RadioGroup
          radioButtonsProps={[{ id: DownloadOptions.PDF }]}
          value={downloadOption}
          onPress={() => setDownloadOption(DownloadOptions.PDF)}
          style={styles.buttonContainer}
        />
        <Title16>
          {I18n.t("epointage.homepage.downloadModal.pdfOption")}
        </Title16>
      </View>
    </View>
    <Pressable style={styles.iconContainer} onPress={handleNextStep}>
      <NextStep />
    </Pressable>
  </View>
);

const styles = StyleSheet.create({
  optionsContainer: {
    marginTop: 20,
  },
  optionRow: {
    marginBottom: 10,
    flexDirection: "row",
  },
  buttonContainer: {
    marginRight: 10,
  },
  iconContainer: {
    alignSelf: "center",
    marginTop: 10,
    width: 40,
  },
});
