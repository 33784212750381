import React from "react";
import { I18n } from "react-redux-i18n";

interface IProps {
  size?: number;
}
export const ProfileBlack: React.FC<IProps> = ({ size }) => (
  <svg
    width={size ? `${size}px` : "20px"}
    height={size ? `${size}px` : "20px"}
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>{I18n.t("profile.myAccount")}</title>
    <g
      id="Homepage"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="Profil-parametres" transform="translate(-152.000000, -111.000000)">
        <g id="item_nav" transform="translate(149.000000, 109.000000)">
          <g id="Prenom" transform="translate(1.000000, 0.000000)">
            <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
            <path
              d="M12.8064,4.8 C14.2434743,4.8 15.408,5.87428571 15.408,7.2 C15.408,8.52571429 14.2434743,9.6 12.8064,9.6 C11.3693257,9.6 10.2048,8.52571429 10.2048,7.2 C10.2048,5.87428571 11.3693257,4.8 12.8064,4.8 M12.156,15.6 C15.6393718,15.6 19.3104,17.2425 19.3104,17.9625 L19.3104,19.2 L5.0016,19.2 L5.0016,17.9625 C5.0016,17.2425 8.6726282,15.6 12.156,15.6 M12.8064,2.4 C9.931632,2.4 7.6032,4.548 7.6032,7.2 C7.6032,9.852 9.931632,12 12.8064,12 C15.681168,12 18.0096,9.852 18.0096,7.2 C18.0096,4.548 15.681168,2.4 12.8064,2.4 Z M12.156,13.2 C8.899935,13.2 2.4,14.808 2.4,18 L2.4,21.6 L21.912,21.6 L21.912,18 C21.912,14.808 15.412065,13.2 12.156,13.2 Z"
              id="Shape"
              fill="#090909"
              fill-rule="nonzero"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
