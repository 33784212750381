"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_USER_HOLDING_VIEW = void 0;
const client_1 = require("@apollo/client");
exports.GET_USER_HOLDING_VIEW = client_1.gql `
  mutation getUserHoldingView($input: String) {
    getUserHoldingView(token: $input) {
      id
      idHolding
      alias
      holding {
        id
        name
        isBadgeRequired
        isScolaPassCashSystem
      }
      guest {
        id
        lastName
        serialNumber
        supportSerialNumber
        validationCode
      }
    }
  }
`;
