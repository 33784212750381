import {
  ApolloLink,
  FetchResult,
  NextLink,
  Observable,
  Operation,
} from "@apollo/client";
import { store } from "@redux";

interface ICustomHeaders {
  Authorization?: string | undefined;
}

/* istanbul ignore next */
export const authLink = new ApolloLink(
  (
    operation: Operation,
    forward?: NextLink
  ): Observable<FetchResult> | null => {
    if (!forward) {
      return null;
    }

    const headers: ICustomHeaders = {};

    const token = store.getState().auth.authToken?.accessToken;

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    operation.setContext({
      headers,
    });

    return forward(operation);
  }
);
