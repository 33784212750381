import {
  ArticleFamily,
  IMealHeartRule,
  OrderItem,
  QuantityRuleType,
} from "@foodi/core";
import { OfferViewModel } from "@modules";
import { I18n } from "react-redux-i18n";

export class HeartMealsViewModel {
  private totalQuantity = 0;
  private orderItems: OrderItem[];
  constructor(
    private mealHeartRule: IMealHeartRule,
    _orderItems?: OrderItem[]
  ) {
    this.orderItems = _orderItems ?? [];
    if (this.orderItems.length > 0) {
      const filteredItems = this.getOrderProductsOfHeartFamilies();
      this.totalQuantity = this.getQuantityOfOrders(filteredItems);
    }
  }

  isHeartFamily = (
    familyName: ArticleFamily | string
    //@ts-ignore
  ): boolean => this.mealHeartRule.selectedFamilies.includes(familyName);

  getHeatMealQuantity = () => {
    return this.totalQuantity;
  };

  private getOrderProductsOfHeartFamilies = (): OrderItem[] => {
    return this.orderItems.filter(({ offerItem }: OrderItem) => {
      if (offerItem && offerItem.inheritedFamily) {
        return this.isHeartFamily(offerItem.inheritedFamily);
      } else return false;
    });
  };

  private getQuantityOfOrders = (orderItems: OrderItem[]): number => {
    return orderItems.reduce<number>(
      (total, { quantity }) => (total += quantity),
      0
    );
  };

  getMealsRules = (_rule: QuantityRuleType) =>
    this.mealHeartRule.quantityRules.filter(
      (rule) => rule.property === _rule
    )[0];

  getFamiliesNames = () => {
    const translated = this.mealHeartRule.selectedFamilies.map((familyName) =>
      OfferViewModel.getLabelFromProductFamily(familyName)
    );
    const sorted = OfferViewModel.sortFamiliesList(translated, true);
    return sorted
      ?.map((f: string) => I18n.t(`restaurantDetail.productFamilies.${f}`))
      ?.join(", ");
  };

  private getHeartMealMessageError = (
    stringKey: string,
    min: number,
    max: number
  ): string => {
    const familiesNames = this.getFamiliesNames();
    return I18n.t(`restaurantDetail.${stringKey}`, { familiesNames, min, max});
  };

  getMealsHeartRulesMessage = (): string => {
    const { min, max } = this.getMealsRules(QuantityRuleType.mealQuantity);
    const { stringKey } = this.getMealsHeartRulesResult();
    return this.getHeartMealMessageError(stringKey, min, max);
  };

  isMealsHeartRulesValid = (): boolean => {
    const { isRuleNotValid } = this.getMealsHeartRulesResult();
    return !isRuleNotValid;
  };

  private getMealsHeartRulesResult = () => {
    const { min, max } = this.getMealsRules(QuantityRuleType.mealQuantity);
    let stringKey = "";
    let isRuleNotValid = true;
    if (min === 0 && max === 1) {
      isRuleNotValid = this.totalQuantity > max;
      stringKey = "min0max1";
    } else if (min === 1 && max === 1) {
      isRuleNotValid = this.totalQuantity !== 1;
      stringKey = "min1max1";
    } else if (min === 0 && max > 1) {
      isRuleNotValid = this.totalQuantity > max;
      stringKey = "min0maxY";
    } else if (min === 1 && max > 1) {
      isRuleNotValid = this.totalQuantity < 1 || this.totalQuantity > max;
      stringKey = "min1maxY";
    } else if (min > 1 && max > min) {
      isRuleNotValid = this.totalQuantity < min || this.totalQuantity > max;
      stringKey = "minXmaxY";
    } else if (min > 1 && max === min) {
      isRuleNotValid = this.totalQuantity !== min;
      stringKey = "minXmaxX";
    }
    return {
      stringKey,
      isRuleNotValid,
    };
  };

  getMaxProductsPerOrder(): number {
    return this.getMealsRules(QuantityRuleType.productsPerOrder).max;
  }

  getSameProductPerOrder(): number {
    return this.getMealsRules(QuantityRuleType.sameProductPerOrder).max;
  }

  getTotalQuantity(): number {
    return this.getQuantityOfOrders(this.orderItems);
  }
}
