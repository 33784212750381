"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_PAYMENT_REQUEST = void 0;
const client_1 = require("@apollo/client");
exports.GET_PAYMENT_REQUEST = client_1.gql `
  query getPaymentRequest(
    $token: String!
    $amount: String!
    $userRedirectUrl: String!
  ) {
    mercanetV2(
      token: $token
      amount: $amount
      userRedirectUrl: $userRedirectUrl
    ) {
      uri
      method
      headers {
        key
        value
      }
      body
    }
  }
`;
