import { Checked } from "@assets";
import { TestIDs } from "@utils";
import React from "react";
import { View } from "react-native";
import { Container } from "./Checkbox.style";

interface IProps {
  checked?: boolean;
  forwardTestID?: string;
  onPress?: () => void;
  hasError?: boolean;
}

export const Checkbox: React.FC<IProps> = React.memo(
  ({ checked = false, forwardTestID, onPress, hasError }) => {
    return (
      <Container testID={forwardTestID} onPress={onPress} hasError={hasError}>
        {checked && (
          <View testID={TestIDs.components.Checkbox.views.checkedIcon}>
            <Checked />
          </View>
        )}
      </Container>
    );
  }
);
