"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_BOOKING = void 0;
const client_1 = require("@apollo/client");
exports.GET_BOOKING = client_1.gql `
  query getBooking($idOrder: ID!) {
    # Order:1
    order: get(id: $idOrder, orderType: "booking") {
      ... on Order {
        id
        cancelableUntil
        cancelableAt
        withdrawRange
        idWithdrawal
        state
        withdrawLocation
        offerTemplateName
        offer {
          id
          offerTemplate: offerTemplate_v2 {
            ... on OfferTemplate {
              id
              pos {
                id
                name
                zone {
                  id
                  holding {
                    id
                    name
                  }
                  __typename
                }
              }
            }
          }
        }
      }
    }
  }
`;
