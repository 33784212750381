"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckTablesAvailability = void 0;
class CheckTablesAvailability {
    constructor(tableService) {
        this.tableService = tableService;
    }
    execute(request) {
        return this.tableService.checkTablesAvailability(request);
    }
}
exports.CheckTablesAvailability = CheckTablesAvailability;
