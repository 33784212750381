/* eslint-disable camelcase */
import icomoon from "../assets/fonts/icomoon.ttf";
import manrope from "../assets/fonts/manrope.ttf";
import manropeBold from "../assets/fonts/Manrope-Bold.ttf";
import manropeRegular from "../assets/fonts/Manrope-Regular.ttf";
const IconsCSS = `
@font-face {
  src: url(${manrope});
  font-family: manrope;
}
@font-face {
  src: url(${manropeBold});
  font-family: manrope-bold;
}
@font-face {
  src: url(${manropeRegular});
  font-family: manrope-regular;
}
@font-face {
  src: url(${icomoon});
  font-family: icomoon;
}
`;

const style = document.createElement("style");
style.type = "text/css";
if (style.styleSheet) style.styleSheet.cssText = IconsCSS;
else style.appendChild(document.createTextNode(IconsCSS));

document.head.appendChild(style);
