import { createGlobalStyle, DefaultTheme } from "styled-components/macro";

/* istanbul ignore next */
export const theme: DefaultTheme = {
  // Colors
  primary: "black",

  // Typography
  baseFont: `'Manrope'`,
};

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
  }
`;
