import { Time, User } from "@assets";
import { ComputedSchedule, Scheduler } from "@foodi/core";
import { SchedulerViewModel } from "@modules";
import { Title16 } from "@stylesheets";
import _ from "lodash";
import React from "react";
import { StyleSheet, View, Text } from "react-native";
import { useDispatch } from "react-redux";

interface IProps {
  scheduler?: Scheduler;
  serviceId?: number;
  selectedDate: string;
}

export const EpointageSchedules: React.FC<IProps> = ({
  scheduler,
  serviceId,
  selectedDate,
}) => {
  const dispatch = useDispatch();
  const [schedulerVM] = React.useState<SchedulerViewModel>(
    new SchedulerViewModel(dispatch)
  );
  const schedules = _.sortBy(scheduler?.schedules, "order").filter(
    (s) => s.serviceId === serviceId
  );
  const totalsInfo = schedulerVM.getTotalPresentWithColor(
    schedules,
    selectedDate
  );

  return (
    <View style={styles.listContainer}>
      {schedules.map((s: ComputedSchedule, index: number) => (
        <View key={index} style={styles.sectionWrapper}>
          <Time />
          <Title16 bold>{s.deadline}</Title16>
          <Title16 bold color={totalsInfo?.[index].color}>
            <View style={styles.totalContainer}>
              <Text>{"("}</Text>
              <User color={totalsInfo?.[index].color} />
              {totalsInfo?.[index].total}
              <Text>{")"}</Text>
            </View>
          </Title16>
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  listContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  sectionWrapper: {
    flex: 1,
    justifyContent: "center",
    flexDirection: "row",
  },
  totalContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 10,
  },
});
