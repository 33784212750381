import React from "react";
import { ActivityIndicator, StyleSheet, View, ViewStyle } from "react-native";
import { Title13, Title22 } from "@stylesheets";
import { Colors } from "@constants";
import { I18n } from "react-redux-i18n";

interface IProps {
  containerStyle?: ViewStyle;
}

export const LocalLoader: React.FC<IProps> = React.memo(
  ({ containerStyle }) => {
    return (
      <View style={styles.container}>
        <View style={[styles.background, containerStyle]} />
        <View style={styles.spinnerDiv}>
          <ActivityIndicator
            style={styles.activityIndicator}
            color={Colors.foodiBlack}
            size={50}
          />
          <Title22 style={styles.title}>{I18n.t("common.loading")}</Title22>
        </View>
      </View>
    );
  }
);

const styles = StyleSheet.create({
  container: {
    borderRadius: 8,
    overflow: "hidden",
    height: 150,
    width: 370,
    justifyContent: "center",
    alignItems: "center",
  },
  background: {
    position: "absolute",
    height: 150,
    width: 370,
    backgroundColor: Colors.footerBackgroundGray,
    opacity: 0.7,
  },
  spinnerDiv: {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  title: {
    color: Colors.black,
  },
  activityIndicator: {
    marginRight: 10,
  },
});
