import { Colors } from "@constants";
import { Title18, Title24 } from "@stylesheets";
import React, { useMemo } from "react";
import { StyleSheet, TouchableOpacity, ViewStyle } from "react-native";
import { useDevices } from "@hooks";
import { useSelector } from "react-redux";
import { GlobalState } from "@redux";

interface IProps {
  index: number;
  title: string;
  forwardTestID?: string;
  style?: ViewStyle;
  textStyle?: ViewStyle;
  onPress: Function;
  isSelected: boolean;
}

export const RefillInput: React.FC<IProps> = React.memo(
  ({ index, style, forwardTestID, title, onPress, textStyle, isSelected }) => {
    const [isMobile] = useDevices();
    const brandTheme = useSelector(
      (state: GlobalState) => state.brandTheme.brandTheme
    );

    const styles = useMemo(() => _styles(isMobile, brandTheme), [
      isMobile,
      brandTheme,
    ]);
    const Title = isMobile ? Title18 : Title24;

    return (
      <TouchableOpacity
        testID={forwardTestID}
        style={[styles.container, style, isSelected && styles.selectedInput]}
        onPress={() => onPress(index)}
      >
        <Title style={[textStyle, styles.mobileText]}>{title}</Title>
      </TouchableOpacity>
    );
  }
);

const _styles = (isMobile: boolean, brandTheme: any) =>
  StyleSheet.create({
    container: {
      margin: isMobile ? 7 : 10,
      backgroundColor: Colors.white,
      height: 50,
      width: isMobile ? 75 : 200,
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 8,
      borderWidth: 1,
      borderColor: Colors.darkGrey,
    },
    selectedInput: {
      borderWidth: 2,
      borderColor:
        brandTheme.buttonActionColors?.backgroundColor || Colors.foodiDefault,
    },
    mobileText: {
      fontFamily: "manrope-bold",
    },
  });
