import { Colors } from "@constants";
import style from "styled-components/native";
import { RESTAURANT_CARD_WIDTH } from "../RestaurantCard/RestaurantCard.style";

export const Container = style.View`
    align-items: center;
`;

export const InfoContainer = style.View`
    width: ${RESTAURANT_CARD_WIDTH}px;
    flex-direction: row;
    marginVertical: 20px; 
`;

export const Text = style.Text`
    margin-left: 6px;
    color: ${Colors.foodiBlack};
    font-family: manrope;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
`;
