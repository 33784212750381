import {
  SmallLogoBlackMobile,
  SmallLogoBlackWeb,
  SmallLogoWhiteMobile,
  SmallLogoWhiteWeb,
} from "@assets";
import { useDevices } from "./useDevices";

export const useLogoIconForBrand = (brand?: string) => {
  const [isMobile] = useDevices();

  const BlackLogo = isMobile ? SmallLogoBlackMobile : SmallLogoBlackWeb;
  const WhiteLogo = isMobile ? SmallLogoWhiteMobile : SmallLogoWhiteWeb;

  switch (brand?.toLowerCase()) {
    case "exalt":
      return WhiteLogo; // TODO: complete when the mapping is defined by the design team
    case "eurest":
      return WhiteLogo;
    case "medirest":
      return BlackLogo;
    case "mediance":
      return WhiteLogo;
    case "scolarest":
      return WhiteLogo;
    case "popote":
      return WhiteLogo;
    default:
      return WhiteLogo;
  }
};
