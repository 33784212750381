import { Colors, Spacing } from "@constants";
import { NavigationProp } from "@react-navigation/native";
import { TestIDs } from "@utils";
import React, { useEffect, useMemo } from "react";
import { StyleSheet, ScrollView, View } from "react-native";
import { I18n } from "react-redux-i18n";
import { Title16, Title18, Title22, Title36 } from "@stylesheets";
import { upperFirst } from "lodash";
import {
  ScreenWithFooter,
  RefillsTemplate,
  TransactionsTemplate,
  CompleteProfile,
  HistoricalTemplate,
} from "@atomic";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "@redux";
import { useDevices } from "@hooks";
import { TransactionsActions } from "@modules";
import { ManagementMode } from "@foodi/core";

interface IProps {
  navigation: NavigationProp<any>;
}

export const BalanceScreen: React.FC<IProps> = React.memo(({ navigation }) => {
  const dispatch = useDispatch();
  // selectors
  const balanceInfo = useSelector(
    (state: GlobalState) => state.account.userBalance?.balance
  );
  const {
    badgeNumber,
    managementMode,
    refillAllowedHolding,
    language,
    isScolaPassCashSystem,
  } = useSelector((state: GlobalState) => state?.auth?.userInfo) ?? {};

  moment.locale(language);

  const [isMobile] = useDevices();
  const styles = useMemo(() => _styles(isMobile, !!badgeNumber), [
    isMobile,
    !!badgeNumber,
  ]);
  const balance = balanceInfo?.amount || "-";
  const BalanceTitle = isMobile ? Title22 : Title36;
  const DateText: React.FC<any> = isMobile ? Title16 : Title18;

  const displayRefill =
    refillAllowedHolding && managementMode === ManagementMode.PRE;

  useEffect(() => {
    return () => {
      dispatch(TransactionsActions.setMercanetStatus(false));
    };
  }, []);

  return (
    <ScreenWithFooter navigation={navigation}>
      <View style={styles.container}>
        <ScrollView style={styles.scrollContainer}>
          <BalanceTitle
            testID={TestIDs.home.balance.texts.title}
            style={styles.title}
          >
            {I18n.t("refill.myBalance", { balance })}
          </BalanceTitle>
          <DateText
            testID={TestIDs.home.balance.texts.date}
            style={styles.titleTime}
          >
            {isScolaPassCashSystem && (
              <p>{I18n.t("refill.scolapass.balance.description")}</p>
            )}
            {!isScolaPassCashSystem &&
              upperFirst(moment().format("dddd DD MMMM HH:mm"))}
          </DateText>
          {badgeNumber ? (
            <>
              {displayRefill && <RefillsTemplate />}
              {isScolaPassCashSystem ? (
                <HistoricalTemplate navigation={navigation} />
              ) : (
                <TransactionsTemplate navigation={navigation} />
              )}
            </>
          ) : (
            <CompleteProfile
              subtitle={I18n.t("refill.profileIncompleteDesc")}
              navigation={navigation}
            />
          )}
        </ScrollView>
      </View>
    </ScreenWithFooter>
  );
});

const _styles = (isMobile: boolean, badgeNumber: boolean) =>
  StyleSheet.create({
    container: {
      backgroundColor: Colors.white,
      flex: 1,
    },
    scrollContainer: {
      backgroundColor: Colors.white,
      alignSelf: "center",
      width: isMobile ? "100%" : "80%",
      marginTop: isMobile ? 0 : 26,
      marginBottom: isMobile ? 0 : Spacing.XL,
    },
    title: {
      marginTop: isMobile ? Spacing.M : Spacing.S,
      marginLeft: isMobile ? Spacing.M : Spacing.S,
      textAlign: "left",
      color: Colors.foodiBlack,
    },
    titleTime: {
      marginTop: isMobile ? 0 : Spacing.XS,
      marginLeft: isMobile ? Spacing.M : Spacing.S,
      marginBottom: !badgeNumber ? 100 : isMobile ? Spacing.M : Spacing.L,
      textAlign: "left",
    },
  });
