import React from "react";

export const Scroll: React.FC = () => (
  <svg width="9px" height="19px" xmlns="http://www.w3.org/2000/svg">
    <title>Icons/Navigation/Scroll</title>
    <g transform="translate(-4 -.5)" fill="none" fillRule="evenodd">
      <path d="M0 13V-4h17v17z" />
      <path
        fill="#090909"
        fillRule="nonzero"
        d="M11.751 5.915 8.5 2.671 5.249 5.915l-.999-.998L8.5.667l4.25 4.25z"
      />
      <path d="M0 0h17v20H0z" />
      <circle stroke="#090909" strokeWidth={1.5} cx={8.5} cy={10} r={2.25} />
      <path d="M17 8v17H0V8z" />
      <path
        fill="#090909"
        fillRule="nonzero"
        d="M5.249 14.085 8.5 17.329l3.251-3.244.999.998-4.25 4.25-4.25-4.25z"
      />
    </g>
  </svg>
);
