"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IDP_SSO = void 0;
const client_1 = require("@apollo/client");
exports.IDP_SSO = client_1.gql `
  mutation idpSignUp($input: IdpSsoSignUp!) {
    idpSsoSignUp(input: $input) {
      accessToken
      refreshToken
      expiresIn
    }
  }
`;
