"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FProfileService = void 0;
const __1 = require("../../..");
const domain_1 = require("../../../domain");
class FProfileService {
    constructor(apolloClient) {
        this.apolloClient = apolloClient;
    }
    updateOptins(input) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield this.apolloClient.mutate({
                    mutation: __1.UPDATE_OPTINS,
                    variables: { input },
                });
                return Promise.resolve(response);
            }
            catch (error) {
                return Promise.reject(new domain_1.ProfileServiceErrors.UpdateOptinsRequestError("UPDATE_OPTINS_REQUEST_FAILED", error));
            }
        });
    }
    updateOptouts(input) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield this.apolloClient.mutate({
                    mutation: __1.UPDATE_OPTOUTS,
                    variables: { input },
                });
                return Promise.resolve(response);
            }
            catch (error) {
                return Promise.reject(new domain_1.ProfileServiceErrors.UpdateOptoutsRequestError("UPDATE_OPTOUTS_REQUEST_FAILED", error));
            }
        });
    }
    updateLanguage(input) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield this.apolloClient.mutate({
                    mutation: __1.UPDATE_LANGUAGE,
                    variables: { input },
                });
                return Promise.resolve(response);
            }
            catch (error) {
                return Promise.reject(new domain_1.ProfileServiceErrors.UpdateLanguageRequestError("UPDATE_LANGUAGE_REQUEST_FAILED", error));
            }
        });
    }
    updateVirtualTicket(input) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield this.apolloClient.mutate({
                    mutation: __1.UPDATE_VIRTUAL_TICKET,
                    variables: { input },
                });
                return Promise.resolve(response);
            }
            catch (error) {
                return Promise.reject(new domain_1.ProfileServiceErrors.UpdateVirtualTicketRequestError("UPDATE_VIRTUAL_TICKET_REQUEST_FAILED", error));
            }
        });
    }
}
exports.FProfileService = FProfileService;
