"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_VIRTUAL_TICKET = void 0;
const client_1 = require("@apollo/client");
exports.UPDATE_VIRTUAL_TICKET = client_1.gql `
  mutation updateVirtualTicket($input: InputUpdateUser) {
    updateUser(input: $input) {
      id
      virtualTicket
    }
  }
`;
