import React from "react";
import { ViewStyle, TextStyle, Pressable } from "react-native";
import { I18n } from "react-redux-i18n";
import { useDevices } from "@hooks";
import { ViewIconBordered, LabelIconRadius, ComingSoonModal } from "@atomic";
import { BookingOrder, Order } from "@foodi/core";
import { Container, ModalContainer, Wrapper } from "./ComingSoon.style";
import { ComingSoonViewModel } from "@modules";
import { TestIDs } from "@utils";
import { NavigationProp } from "@react-navigation/native";
import { useDispatch } from "react-redux";

interface IProps {
  style?: ViewStyle;
  orders: Order[];
  bookingOrders: BookingOrder[];
  navigation?: NavigationProp<any>;
}

export const ComingSoon: React.FC<IProps> = ({
  orders,
  bookingOrders,
  style,
  navigation,
}) => {
  const dispatch = useDispatch();

  const [isMobile] = useDevices();

  const isFixed = isMobile;

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isHover, setIsHover] = React.useState(false);

  const comingSoonViewModel = new ComingSoonViewModel();

  const upToDateOrders = comingSoonViewModel.getUpToDateOrders(orders || []);

  const upToDateBookingOrders = comingSoonViewModel.getUpToDateOrders(
    bookingOrders || [],
    dispatch,
    true
  );

  if (!upToDateOrders?.length && !upToDateBookingOrders?.length) return null;

  const title = upToDateOrders.length
    ? `${I18n.t("homepage.comingSoon.orders", {
        count: upToDateOrders.length,
      })}`
    : undefined;

  const title2 = upToDateBookingOrders.length
    ? `${I18n.t("homepage.comingSoon.reservations", {
        count: upToDateBookingOrders.length,
      })}`
    : undefined;

  const totalOrders = upToDateOrders.length + upToDateBookingOrders.length;

  const arrowIcon = isModalOpen
    ? "Accordeon_arrow_open_white"
    : "Accordeon_arrow_default_white";

  const labelsStyle =
    isModalOpen || isHover
      ? ({
          fontFamily: "manrope-bold",
          width: "100%",
          textAlign: "center",
          fontSize: 12
        } as TextStyle)
      : ({
          width: "100%",
          textAlign: "center",
          fontSize: 12
        } as TextStyle);

  const handleOpenModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  /* istanbul ignore next */
  const handleHoverIn = () => {
    setIsHover(true);
  };

  /* istanbul ignore next */
  const handleHoverOut = () => {
    setIsHover(false);
  };

  return (
    <Wrapper testID={TestIDs.home.comingSoon.views.mainContainer}>
      <Pressable
        testID={TestIDs.home.comingSoon.actions.openModal}
        onPress={handleOpenModal}
        // @ts-ignore
        onHoverIn={handleHoverIn}
        onHoverOut={handleHoverOut}
      >
        <Container
          style={[style]}
          isFullWidth={!!title && !!title2}
          isMobile={isMobile}
        >
          <ViewIconBordered
            title={title}
            iconName={arrowIcon}
            title2={title2}
            labelsStyle={labelsStyle}
            isMobile={isMobile}
          >
            <LabelIconRadius
              title={I18n.t("homepage.comingSoon.comingSoon")}
              iconName={"Coming-next_white"}
              totalOrders={totalOrders}
              isMobile={isMobile}
            />
          </ViewIconBordered>
        </Container>
      </Pressable>
      {isModalOpen && (
        <ModalContainer isAligned={!!title && !!title2} isFixed={isFixed}>
          <ComingSoonModal
            navigation={navigation}
            orders={upToDateOrders}
            bookingOrders={upToDateBookingOrders}
            isMobile={isMobile}
            handleCloseModal={handleOpenModal}
          />
        </ModalContainer>
      )}
    </Wrapper>
  );
};
