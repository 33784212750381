"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CART_SUMMARY_ADMISSION_AND_PRICE = void 0;
const client_1 = require("@apollo/client");
exports.CART_SUMMARY_ADMISSION_AND_PRICE = client_1.gql `
  query cartSummaryAdmissionAndPrice(
    $idOrder: ID!
    $paymentMethod: PaymentMethod
  ) {
    orderAdmissionAndPrice: get(id: $idOrder) {
      ... on Order {
        id
      quotationComplete(paymentMethod: $paymentMethod) {
        admission {
          amount
          currency
        }
        subsidies{
          amount
          currency
        }
        totalPrice{
          amount
          currency
        }
        fee {
          amount
          currency
        }
      }
    }
  }
}
`;
