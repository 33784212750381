"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FPaymentsService = void 0;
const __1 = require("../../..");
const domain_1 = require("../../../domain");
const mappers_1 = require("../mappers");
const __2 = require("..");
class FPaymentsService {
    constructor(apolloClient) {
        this.apolloClient = apolloClient;
    }
    getPaymentRequest(variables) {
        return __awaiter(this, void 0, void 0, function* () {
            const mercanetResponseMapper = new mappers_1.MercanetResponseMapper();
            try {
                const response = yield this.apolloClient.query({
                    query: __1.GET_PAYMENT_REQUEST,
                    variables,
                });
                return Promise.resolve(mercanetResponseMapper.toDomain(response));
            }
            catch (error) {
                return Promise.reject(new domain_1.PaymentServiceErrors.GetPaymentRequestError("GET_PAYMENT_REQUEST_FAILED", error));
            }
        });
    }
    getPaymentStatus(variables) {
        return __awaiter(this, void 0, void 0, function* () {
            const mercanetResponseStatusMapper = new mappers_1.MercanetResponseStatusMapper();
            try {
                const response = yield this.apolloClient.query({
                    query: __1.GET_PAYMENT_STATUS,
                    variables,
                });
                return Promise.resolve(mercanetResponseStatusMapper.toDomain(response));
            }
            catch (error) {
                return Promise.reject(new domain_1.PaymentServiceErrors.GetPaymentStatusError("GET_PAYMENT_STATUS_FAILED", error));
            }
        });
    }
    getEdenredAuthorizationURL(variables) {
        return __awaiter(this, void 0, void 0, function* () {
            const edenredAuthorizationURLMapper = new mappers_1.EdenredAuthorizationURLMapper();
            try {
                const response = yield this.apolloClient.query({
                    query: __2.GET_EDENRED_AUTHORIZATION_URL,
                    variables,
                });
                return Promise.resolve(edenredAuthorizationURLMapper.toDomain(response));
            }
            catch (error) {
                return Promise.reject(new domain_1.PaymentServiceErrors.GetEdenredAuthorizationURLRequestError("GET_EDENRED_AUTHORIZATION_URL_REQUEST_FAILED", error));
            }
        });
    }
    getEdenredDailyBalance(variables) {
        return __awaiter(this, void 0, void 0, function* () {
            const edenredDailyBalanceMapper = new mappers_1.EdenredDailyBalanceMapper();
            try {
                const response = yield this.apolloClient.query({
                    query: __2.GET_EDENRED_DAILY_BALANCE,
                    variables,
                    fetchPolicy: "no-cache",
                });
                return Promise.resolve(edenredDailyBalanceMapper.toDomain(response));
            }
            catch (error) {
                return Promise.reject(new domain_1.PaymentServiceErrors.GetEdenredDailyBalanceRequestError("GET_EDENRED_DAILY_BALANCE_REQUEST_FAILED", error));
            }
        });
    }
    generateEdenredTokens(variables) {
        return __awaiter(this, void 0, void 0, function* () {
            const generateEdenredTokensMapper = new mappers_1.GenerateEdenredTokensMapper();
            try {
                const response = yield this.apolloClient.query({
                    query: __2.GENERATE_EDENRED_TOKENS,
                    variables,
                });
                return Promise.resolve(generateEdenredTokensMapper.toDomain(response));
            }
            catch (error) {
                return Promise.reject(new domain_1.PaymentServiceErrors.GenerateEdenredTokensRequestError("GENERATE_EDENRED_TOKENS_REQUEST_FAILED", error));
            }
        });
    }
}
exports.FPaymentsService = FPaymentsService;
