import React from "react";
import { I18n } from "react-redux-i18n";

export const WarningOrange: React.FC = () => (
  <svg
    width="22px"
    height="22px"
    viewBox="0 0 22 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>{I18n.t("hoverIcons.warning")}</title>
    <g
      id="Atom/Alerts/Warning"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <circle id="Oval" fill="#F4B83A" cx="11" cy="11" r="11"></circle>
      <text
        id="!"
        font-family="Manrope-ExtraLight_ExtraBold, Manrope"
        font-size="16"
        font-weight="700"
        fill="#FFFFFF"
      >
        <tspan x="8" y="17">
          !
        </tspan>
      </text>
    </g>
  </svg>
);
