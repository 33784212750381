"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InMemoryEPointageRepository = void 0;
class InMemoryEPointageRepository {
    constructor(epointageFixtures, hasFixture) {
        this.epointageFixtures = epointageFixtures;
        this.hasFixture = hasFixture;
    }
    /* istanbul ignore next */
    getPdfExport(request) {
        throw new Error("Method not implemented.");
    }
    /* istanbul ignore next */
    getEpointageScheduler(request) {
        throw new Error("Method not implemented.");
    }
    /* istanbul ignore next */
    addEpointageScheduler(request) {
        throw new Error("Method not implemented.");
    }
    /* istanbul ignore next */
    updateEpointageScheduler(request) {
        throw new Error("Method not implemented.");
    }
    /* istanbul ignore next */
    getEpointageServices(request) {
        throw new Error("Method not implemented.");
    }
    /* istanbul ignore next */
    getEpointageSchools(request) {
        throw new Error("Method not implemented.");
    }
    /* istanbul ignore next */
    addEpointageComment(request) {
        throw new Error("Method not implemented.");
    }
    /* istanbul ignore next */
    updateEpointageComment(request) {
        throw new Error("Method not implemented.");
    }
}
exports.InMemoryEPointageRepository = InMemoryEPointageRepository;
