import { Colors } from "@constants";
import React, { useMemo } from "react";
import { StyleSheet, TouchableHighlight, View } from "react-native";
import { Title16 } from "@stylesheets";
import { I18n } from "react-redux-i18n";
import { ComputedStudent } from "@foodi/core";
import { TextAreaInput } from "@atomic/atoms/TextAreaInput";

interface IProps {
  studentList: ComputedStudent[];
  classComments: string;
  submitComment: Function;
  haveWritePermission: boolean;
}

export const EPointageClassDetailStudent: React.FC<IProps> = ({
  studentList,
  classComments,
  submitComment,
  haveWritePermission,
}) => {
  const styles = useMemo(() => _styles(haveWritePermission), [
    haveWritePermission,
  ]);

  const [comment, setComment] = React.useState<string>(classComments);
  const [textAreaFocus, setTextAreaFocus] = React.useState<any>(
    styles.commentInput
  );
  const [showButtons, setShowButtons] = React.useState<boolean>(false);

  const initialComment = classComments;

  const onFocusTextArea = () => {
    if (!haveWritePermission) return;
    setTextAreaFocus(styles.commentInputWithBorder);
    setShowButtons(true);
  };
  const onBlurTextArea = () => {
    setTextAreaFocus(styles.commentInput);
  };
  const onClickValidate = () => {
    submitComment(comment);
    setShowButtons(false);
  };
  const onClickCancel = () => {
    setComment(initialComment);
    setShowButtons(false);
  };

  return (
    <View style={styles.studentColumn}>
      <View style={styles.studentColumnHeader}>
        <Title16 bold>
          {I18n.t("epointage.homepage.classroom.students")}
        </Title16>
      </View>
      <View>
        {studentList.map((student: ComputedStudent, index: number) => (
          <View key={index} style={styles.row}>
            <Title16>{student.name}</Title16>
          </View>
        ))}
      </View>
      <View style={styles.studentColumnFooter}>
        <Title16 bold style={styles.commentTitleContainer}>
          {I18n.t("epointage.homepage.classroom.commentTitle")}
        </Title16>
        <TextAreaInput
          placeholder={I18n.t(
            "epointage.homepage.classroom.commentPlaceholder"
          )}
          maxLength={255}
          numberOfLines={2}
          value={comment}
          onChangeText={setComment}
          onFocus={onFocusTextArea}
          onBlur={onBlurTextArea}
          style={textAreaFocus}
          textStyle={styles.commentInput}
          editable={haveWritePermission}
        />

        {showButtons && (
          <View style={styles.commentContainer}>
            <TouchableHighlight onPress={onClickValidate}>
              <View style={styles.buttonCommentValidate}>
                {I18n.t("epointage.homepage.classroom.validate")}
              </View>
            </TouchableHighlight>
            <TouchableHighlight onPress={onClickCancel}>
              <View style={styles.buttonCommentCancel}>
                {I18n.t("epointage.homepage.classroom.cancel")}
              </View>
            </TouchableHighlight>
          </View>
        )}
      </View>
    </View>
  );
};

const _styles = (haveWritePermission?: boolean) =>
  StyleSheet.create({
    studentColumn: {
      flex: 1,
      maxWidth: 310,
      flexDirection: "column",
      paddingVertical: 14,
    },
    studentColumnHeader: {
      borderBottomColor: Colors.disabledBackground,
      borderBottomWidth: 1,
      height: 85,
    },
    commentInputWithBorder: {
      borderColor: Colors.blue1,
      borderWidth: 2,
      height: 90,
    },
    commentInput: {
      height: 90,
      cursor: haveWritePermission ? "text" : "default",
    },
    row: {
      height: 45,
      justifyContent: "center",
      borderBottomColor: Colors.disabledBackground,
      borderBottomWidth: 1,
    },
    studentColumnFooter: {
      paddingVertical: 16,
      paddingRight: 16,
    },
    commentTitleContainer: {
      marginBottom: 12,
    },
    commentContainer: {
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "flex-end",
      marginTop: 12,
    },
    buttonCommentValidate: {
      width: 67,
      height: 27,
      justifyContent: "center",
      backgroundColor: Colors.foodiDefault,
      textAlign: "center",
      borderRadius: 8,
      fontFamily: "Manrope",
      fontSize: 12,
      fontWeight: "bold",
    },
    buttonCommentCancel: {
      width: 67,
      height: 27,
      justifyContent: "center",
      backgroundColor: Colors.white,
      textAlign: "center",
      borderRadius: 8,
      fontFamily: "Manrope",
      fontSize: 12,
    },
  });
