import React from "react";
import { StyleSheet, TouchableOpacity, ViewStyle } from "react-native";
import { BigBlackArrowLeft, CarouselArrowLeft } from "@assets";

interface IProps {
  style?: ViewStyle;
  forwardTestID?: string;
  onPress?: () => void;
  bigBlackArrow?: boolean;
  size?: number;
}

export const LeftArrow: React.FC<IProps> = React.memo(
  ({ forwardTestID, onPress, style, bigBlackArrow, size }) => {
    return (
      <TouchableOpacity
        testID={forwardTestID}
        style={[styles.container, style]}
        onPress={onPress}
      >
        {bigBlackArrow ? <BigBlackArrowLeft /> : <CarouselArrowLeft size={size} />}
      </TouchableOpacity>
    );
  }
);

const styles = StyleSheet.create({
  container: {},
});
