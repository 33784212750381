import React from "react";
import { StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";
import { Colors } from "@constants";
import { GlobalState } from "@redux";
import { ILanguage } from "@foodi/core";
import { enGB, fr } from 'date-fns/locale';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

const datePickerStyle = require('./style.css');

interface IProps {
    date: Date | undefined;
    setDate: (date: Date | undefined) => void;
}

export const DatePicker: React.FC<IProps> = React.memo(({
    date,
    setDate,
}) => {
    const i18nLocale = useSelector(
        (state: GlobalState) => state.i18n.locale
    );

    const locale = i18nLocale === ILanguage.fr ? fr : enGB;

    return (
        <View style={styles.container}>
            <DayPicker
                mode="single"
                today={date}
                selected={date}
                onSelect={setDate}
                weekStartsOn={1}
                locale={locale}
                classNames={ datePickerStyle }
            />
        </View>
    );
});

const styles = StyleSheet.create({
    container: {
        backgroundColor: Colors.white,
        boxSizing: 'border-box',
        border: `1px solid ${Colors.foodiBlack}`,
        borderRadius: 8,
    }
});
