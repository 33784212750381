import React, { useMemo } from "react";
import { StyleSheet, View, Image } from "react-native";
import { Title22, Title30, Title48 } from "@stylesheets";
import { Images } from "@assets";
import { I18n } from "react-redux-i18n";
import { Colors } from "@constants";
import _ from "lodash";
import { useSelector } from "react-redux";
import { GlobalState } from "@redux";

interface IProps {
  isMobile: boolean;
  isLogin: boolean;
  username?: string;
}

enum LANG {
  FR = "fr",
  EN = "en",
}

const renderBanner = (isMobile: boolean) => {
  return {
    [LANG.FR]: isMobile ? Images.defaultBannerMobile : Images.defaultBanner,
    [LANG.EN]: isMobile ? Images.defaultBannerMobileEng : Images.defaultBannerEng,
  };
};

export const DefaultBanner: React.FC<IProps> = React.memo(
  ({ isLogin, isMobile, username }) => {
    const Title: React.FC<any> = isMobile ? Title30 : Title48;
    const SubTitle: React.FC<any> = isMobile ? Title22 : Title30;

    const styles = useMemo(() => _styles(isMobile), [isMobile]);

    const language : LANG = useSelector(
      (state: GlobalState) =>
        state.auth?.userInfo?.language || state.i18n?.locale
    );

    const renderTitle = () => {
      return (
        <View style={styles.subtitle}>
          <SubTitle isBlack style={styles.firstSentence}>
            {I18n.t("homepage.welcomingSentence.forYour")}
          </SubTitle>
          <SubTitle isBlack styles={styles.midSentence}>
            {I18n.t("homepage.welcomingSentence.delightMeals")}
          </SubTitle>
          <SubTitle isBlack style={styles.lastSentence}>
            {I18n.t("homepage.welcomingSentence.breaks")}
          </SubTitle>
        </View>
      );
    };

    const rightBanner = renderBanner(isMobile);

    return (
      <View style={styles.bannerContainer}>
        <View style={styles.texts}>
          {isLogin ? (
            <Title styles={styles.letterSpaccing}>
              {username}, {I18n.t("homepage.welcomingSentence.greeting")}!
            </Title>
          ) : (
            <Title styles={styles.letterSpaccing}>
              {I18n.t("homepage.welcomingSentence.welcome")}
            </Title>
          )}
          {renderTitle()}
        </View>
        <View style={styles.imageContainer}>
        <Image
          style={styles.image}
          source={{
            uri: rightBanner[language],
          }}
          resizeMode="contain"
/>
        </View>
      </View>
    );
  }
);

const _styles = (isMobile: boolean) =>
  StyleSheet.create({
    texts: {
      position: "absolute",
      top: 80,
      alignSelf: "center",
      zIndex: 10,
    },
    image: {
      alignSelf: "center",
      width: "100%",
      height: isMobile ? 400 : "100%",
      ...(isMobile && { marginTop: 30 }),
    },
    bannerContainer: {
      width: "100%",
      height: isMobile ? 480 : 600,
      backgroundColor: Colors.background1,
    },
    imageContainer: {
      position: "absolute",
      bottom: 0,
      alignSelf: "center",
      width: "100%",
      height: "90%",
    },
    subtitle: {
      flexDirection: "row",
      justifyContent: "center",
    },
    firstSentence: {
      fontFamily: "manrope",
      fontWeight: "normal",
      marginRight: 8,
      letterSpacing: isMobile ? 0 : 1,
    },
    letterSpaccing: {
      letterSpacing: isMobile ? 0 : 1,
    },
    midSentence: {
      fontFamily: "manrope",
      fontWeight: "normal",
      letterSpacing: isMobile ? 0 : 1,
    },
    lastSentence: {
      fontFamily: "manrope",
      fontWeight: "normal",
      marginLeft: 8,
      letterSpacing: isMobile ? 0 : 1,
    }
  });
