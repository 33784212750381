import React from "react";

export const CopyBlack: React.FC = () => (
    <svg 
        xmlns="http://www.w3.org/2000/svg" 
        xmlnsXlink="http://www.w3.org/1999/xlink" 
        width="20px" 
        height="20px" 
        viewBox="0 0 20 20" 
        version="1.1">
        <title>1189C909-9C3A-4FFB-93C2-4C228B5B1438</title>
        <g 
            id="Icons/Copy_black" 
            stroke="none" 
            stroke-width="1" 
            fill="none" 
            fill-rule="evenodd">
            <path 
                d="M3.82352941,19 C3.47058824,19 3.16176471,18.865 2.89705882,18.595 C2.63235294,18.325 2.5,18.01 2.5,17.65 L2.5,4.0825 L3.82352941,4.0825 L3.82352941,17.65 L14.2794118,17.65 L14.2794118,19 L3.82352941,19 Z M6.47058824,16.3 C6.11764706,16.3 5.80882353,16.165 5.54411765,15.895 C5.27941176,15.625 5.14705882,15.31 5.14705882,14.95 L5.14705882,2.35 C5.14705882,1.99 5.27941176,1.675 5.54411765,1.405 C5.80882353,1.135 6.11764706,1 6.47058824,1 L16.1764706,1 C16.5294118,1 16.8382353,1.135 17.1029412,1.405 C17.3676471,1.675 17.5,1.99 17.5,2.35 L17.5,14.95 C17.5,15.31 17.3676471,15.625 17.1029412,15.895 C16.8382353,16.165 16.5294118,16.3 16.1764706,16.3 L6.47058824,16.3 Z M6.47058824,14.95 L16.1764706,14.95 L16.1764706,2.35 L6.47058824,2.35 L6.47058824,14.95 Z M6.50955882,14.95 L6.50955882,2.35 L6.50955882,14.95 Z" 
                id="Shape" 
                fill="#090909" 
                fill-rule="nonzero"/>
        </g>
    </svg>
);
