import React from "react";

export const Container: React.FC = () => (
  <svg width="24px" height="12px" viewBox="0 0 24 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>Consigne</title>
    <g id="FOODI" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="CNC-MENU-PRODUCT-LIST-Copy-3" transform="translate(-244.000000, -572.000000)" stroke="#1D2C39">
            <g id="Consigne-OFF" transform="translate(244.000000, 572.000000)">
                <rect id="Rectangle" x="2.35714286" y="1.35714286" width="19.5714286" height="10.1428571" rx="4"></rect>
                <rect id="Rectangle" fill="#FFFFFF" x="0.5" y="0.5" width="23" height="2.97959184" rx="1.48979592"></rect>
            </g>
        </g>
    </g>
</svg>
);
