import style from "styled-components/native";

export const AffluenceBlock = style.View`
    justify-content: end;
    align-items: center;
    padding-right: 12px;
    padding-bottom: 22px;
    -webkit-box-pack: end;
    flex-direction: column;
`
export const AffluenceTitle = style.Text`
    font-family: manrope;
    font-size: 13px;
    margin-top: 4px;
`
