"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SendUserEmailVerification = void 0;
class SendUserEmailVerification {
    constructor(accountService) {
        this.accountService = accountService;
    }
    execute(request) {
        return this.accountService.sendUserEmailVerification(request);
    }
}
exports.SendUserEmailVerification = SendUserEmailVerification;
