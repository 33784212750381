import React, { useCallback, useMemo } from "react";
import { Order } from "@foodi/core";
import { StyleSheet, View, Text } from "react-native";
import { OrderRatingModal, OrderRow } from '@atomic';
import { Colors, Spacing } from "@constants";
import { Title18, Title22 } from "@stylesheets";
import moment from "moment";
import _ from "lodash";
import { TestIDs } from "@utils";
import { useDevices } from "@hooks";
import { NavigationProp } from "@react-navigation/native";
import { useSelector } from 'react-redux';
import { GlobalState } from '@redux';

interface IProps {
  navigation: NavigationProp<any>;
  groupedOrders: Record<string, Order[]>;
}
export const OrdersList: React.FC<IProps> = React.memo(
  ({ navigation, groupedOrders }) => {
    const isOrderRatingEnabled = useSelector((state: GlobalState) => state.auth?.userInfo?.rating);
    const groupedOrdersArray = Object.entries(groupedOrders);
    const [isMobile] = useDevices();
    const [showOrderRatingModal, setShowOrderRatingModal] = React.useState<boolean>(false);
    const [orderToRate, setOrderToRate] = React.useState<Order|null>(null);
    const styles = useMemo(() => _styles(isMobile), [isMobile]);

    const DateText = useCallback(
      ({ children, style }) =>
        isMobile ? (
          <Title18 style={style}>{children}</Title18>
        ) : (
          <Title22 style={style}>{children}</Title22>
        ),
      [isMobile]
    );

    const openOrderRatingModal = (order: Order) => {
      setOrderToRate(order);
      setShowOrderRatingModal(true);
    }

    const closeOrderRatingModal = () => {
        setShowOrderRatingModal(false);
        setOrderToRate(null);
    }

    const renderOrderRow = (
      order: Order,
      rowIndex: number,
      groupIndex: number
    ) => {
      return (
        <OrderRow
          forwardTestID={`${TestIDs.profile.views.orderRow}${rowIndex}_${groupIndex}`}
          key={`${rowIndex}_${groupIndex}`}
          id={order.id}
          posName={order.offer?.offerTemplate_v2.pos.name || ""}
          total={order.totalPrice}
          orderDate={order.updated}
          orderState={order.state}
          orderType={order.offer?.offerTemplate_v2.withdrawalType}
          paymentType={order.paymentMethod}
          navigation={navigation}
          orderRating={order.orderRating}
          lastUpdateAt={order.updated}
          isOrderRatingEnabled={isOrderRatingEnabled}
          openOrderRatingModal={() => { openOrderRatingModal(order)}}
        />
      );
    };

    return (
      <>
        <View style={styles.container}>
          {groupedOrdersArray.map((groupOrder, groupIndex, group) => {
            const [orderDate, orders] = groupOrder;
            const [month, year] = orderDate.split("/");
            const _date = new Date(parseInt(year), parseInt(month));

            return (
              <>
                <View
                  testID={`${TestIDs.profile.views.orderGroup}${groupIndex}`}
                  style={styles.group}
                  key={groupIndex}
                >
                  <DateText style={styles.date}>
                    {_.upperFirst(moment(_date).format("MMMM YYYY"))}
                  </DateText>
                  <View>
                    {orders.map((order, rowIndex) =>
                      renderOrderRow(order, rowIndex, groupIndex)
                    )}
                  </View>
                </View>
                {groupIndex + 1 < group.length && (
                  <View style={styles.separator} />
                )}
              </>
            );
          })}
        </View>
        {isOrderRatingEnabled && orderToRate && <OrderRatingModal
          open={showOrderRatingModal}
          onClose={closeOrderRatingModal}
          onSuccessOrderRating={(idOrder, rating) => {
              orderToRate.orderRating = {
                  ...rating,
                  __typename: "OrderRating"
              };
          }}
          posName={orderToRate.offer?.offerTemplate_v2.pos.name || ""}
          idOrder={orderToRate.id}
          idHuman={orderToRate?.idHuman || 0}
          withdrawalRange={orderToRate.withdrawRange}
          preventOrderHistoryLink={true}
          navigation={navigation}
        />
        }
      </>
    );
  }
);

const _styles = (isMobile: boolean) =>
  StyleSheet.create({
    container: {
      width: "100%",
    },
    group: {
      width: isMobile ? "100%" : 600,
      marginTop: Spacing.L,
    },
    date: {
      color: Colors.foodiBlack,
      textAlign: "left",
      fontFamily: "manrope-bold",
    },
    separator: {
      width: isMobile ? "90vw" : "100%",
      height: 1,
      backgroundColor: Colors.border1,
      marginVertical: Spacing.L,
    },
  });
