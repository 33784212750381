"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetEpointageScheduler = void 0;
class GetEpointageScheduler {
    constructor(epointageRepository) {
        this.epointageRepository = epointageRepository;
    }
    execute(request) {
        return this.epointageRepository.getEpointageScheduler(request);
    }
}
exports.GetEpointageScheduler = GetEpointageScheduler;
