import { useFocusEffect } from "@react-navigation/core";
import { JEST } from "@utils";
import { useCallback } from "react";

interface IUseHideZenDesk {
  condition: boolean;
}
const useHideZenDesk = ({ condition }: IUseHideZenDesk) => {
  JEST ||
    useFocusEffect(
      useCallback(() => {
        if (condition) {
          window.zE("messenger:set", "zIndex", -1);
          return () => {
            window.zE("messenger:set", "zIndex", 99999);
          };
        }
      }, [condition])
    );
};

export { useHideZenDesk };
