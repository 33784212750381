import React from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import { Title13, Title16 } from "@stylesheets";
import { IExternalService } from "@foodi/core";
import { Colors } from "@constants";

interface IProps {
  service: IExternalService;
  onPress: (onPress: IExternalService) => void;
  isMobile: boolean;
  forwardTestID?: string;
}

export const ServiceItem: React.FC<IProps> = React.memo(
  ({ service, onPress, isMobile, forwardTestID }) => {
    const TextView = isMobile ? Title16 : Title13;
    return (
      <TouchableOpacity
        style={[styles.container, isMobile && styles.mobileStyle]}
        onPress={() => onPress(service)}
      >
        <TextView
          color={Colors.foodiBlack}
          testID={forwardTestID}
          style={isMobile && styles.text}
        >
          {service.title}
        </TextView>
      </TouchableOpacity>
    );
  }
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: 26,
    paddingVertical: 4,
    paddingHorizontal: 16,
  },
  mobileStyle: {
    height: 50,
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    textAlign: "center",
  },
});
