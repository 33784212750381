import { Colors } from "@constants";
import styled from "styled-components/native";

export const Container = styled.View`
    width: 100%;
`;

export const Circle = styled.View<{ isGreen?: boolean, isRed?: boolean }>`
    background-color: ${({ isGreen, isRed }) => (isGreen ? Colors.middleGreen : (isRed ? Colors.redError : Colors.foodiBlack))};
    border-radius: 2.5px;
    height: 5px;
    width: 5px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 2px;
`;

export const Text = styled.Text<{ isBold?: boolean, isUnderline?: boolean, isGreen?: boolean, isRed?: boolean, isErrorMessage?: boolean }>`
    margin-left: 5px;
    color: ${({ isGreen, isRed }) => (isGreen ? Colors.middleGreen : (isRed ? Colors.redError : Colors.foodiBlack))};
    flex-direction: row;
    text-align: left;
    font-family: ${({ isBold }) => (isBold ? "manrope-bold" : "manrope")};
    font-size: 13;
    align-items: center;
    text-decoration: ${({ isUnderline }) => (isUnderline ? "underline" : "")};
    display: ${({ isErrorMessage }) => (isErrorMessage ? "none" : "flex")};
`;



