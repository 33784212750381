import { AccountScreens } from "@modules";
import { NavigationProp } from "@react-navigation/core";
import _ from "lodash";

export enum StepStatus {
  DONE = "DONE",
  TODO = "TODO",
  IN_PROGRESS = "IN_PROGRESS",
}

export interface IStep {
  status: StepStatus;
  title: string;
}

export class StepperViewModel {
  public static stepperData: IStep[] = [
    { title: "account.identifiers", status: StepStatus.IN_PROGRESS },
    { title: "account.establishment", status: StepStatus.TODO },
    { title: "account.done", status: StepStatus.TODO },
  ];

  public static updateStepperData(
    _stepperData: IStep[],
    stepNumber: number
  ): IStep[] {
    const updatedSteps = _stepperData.map((_stepData, index) => {
      const stepIndex = stepNumber - 1;
      let newStatus = _stepData.status;
      if (stepIndex === index) newStatus = StepStatus.IN_PROGRESS;
      if (stepIndex < index) newStatus = StepStatus.TODO;
      if (stepIndex > index) newStatus = StepStatus.DONE;
      return {
        ..._stepData,
        status: newStatus,
      };
    });
    return updatedSteps;
  }

  public static navigateToStep(
    navigation: NavigationProp<any>,
    stepNumber: number
  ) {
    if (stepNumber === 1) navigation.navigate(AccountScreens.STEP_ONE_SCREEN);
    if (stepNumber === 2) navigation.navigate(AccountScreens.STEP_TWO_SCREEN);
    if (stepNumber === 3) navigation.navigate(AccountScreens.STEP_THREE_SCREEN);
  }
}
