import React, { useState, useMemo } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { Title22, Title36 } from "@stylesheets";
import { I18n } from "react-redux-i18n";
import {
  ValidButton,
  RefillInput,
  RefillTextInput,
  LabelIconError,
  Icon,
} from "@atomic";
import { Colors, Spacing } from "@constants";
import { Refills } from "@modules";
import { TestIDs } from "@utils";
import { CloseBlack } from "@assets";
import { useDevices } from "@hooks";
import { useSelector } from "react-redux";
import { GlobalState } from "@redux";

interface IProps {
  forwardTestID?: string;
  children?: React.ReactNode;
  onValidate: Function;
  onSelectRefillInput: Function;
  setOtherRefill: Function;
  refills: Refills[];
  isOtherRefill: boolean;
  isDisabled: boolean;
  handleOtherValue: (value: number) => void;
  title: string;
  errorDescription: string;
}

export const RefillInputs: React.FC<IProps> = React.memo(
  ({
    onSelectRefillInput,
    onValidate,
    refills,
    isOtherRefill,
    isDisabled,
    setOtherRefill,
    handleOtherValue,
    title,
    errorDescription,
  }) => {
    const [invalidInput, setInvalidInput] = useState<boolean>(false);
    const [value, setValue] = useState<number>(0);
    const [isMobile] = useDevices();
    const styles = useMemo(() => _styles(isMobile), [isMobile]);
    const Title = isMobile ? Title22 : Title36;

    //This line is used to force the component rerender with the right language when changed
    useSelector((state: GlobalState) => state.auth?.userInfo?.language);

    const refillInputsList = React.useMemo(() => {
      return (
        <View style={styles.inputs}>
          {refills.map(({ amount, currencySymbol, isSelected }, index) => (
            <RefillInput
              key={index}
              forwardTestID={`${TestIDs.home.balance.texts.refillInput}${index}`}
              index={index}
              title={`${amount}${currencySymbol}`}
              onPress={onSelectRefillInput}
              isSelected={isSelected}
            />
          ))}
        </View>
      );
    }, [refills]);

    const otherRefill = React.useMemo(() => {
      return (
        <View style={styles.textInputDiv}>
          <RefillTextInput
            forwardTestID={TestIDs.home.balance.texts.refillTextInput}
            invalidInput={invalidInput}
            setInvalidInput={setInvalidInput}
            handleOtherValue={(value) => {
              handleOtherValue(value);
              setValue(value);
            }}
          />
          {invalidInput && (
            <LabelIconError
              forwardTestID={TestIDs.home.balance.views.errorRefillLimit}
              style={styles.labelIconError}
              textStyle={styles.labelIconErrorText}
              title={errorDescription}
            />
          )}
        </View>
      );
    }, [invalidInput]);

    const closeRefillTextInput = () => {
      setOtherRefill(false);
      setInvalidInput(false);
    };

    const CloseButtonView = React.memo(() => (
      <TouchableOpacity
        testID={TestIDs.home.balance.actions.closeRefillInput}
        onPress={closeRefillTextInput}
        style={styles.closeBtn}
      >
        <CloseBlack />
      </TouchableOpacity>
    ));

    return (
      <View style={styles.container}>
        <Title
          testID={TestIDs.home.balance.texts.refillText}
          style={styles.title}
        >
          {title}
        </Title>

        <View>{isOtherRefill ? otherRefill : refillInputsList}</View>
        <ValidButton
          forwardTestID={TestIDs.home.balance.actions.validate}
          title={I18n.t("refill.validate")}
          onPress={onValidate}
          isDisabled={
            isDisabled || invalidInput || (isOtherRefill && value === 0)
          }
          textStyle={styles.labelValidateButton}
        />
        {isOtherRefill && <CloseButtonView />}
      </View>
    );
  }
);

const _styles = (isMobile: boolean) =>
  StyleSheet.create({
    container: {
      width: "100%",
      backgroundColor: Colors.background1,
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 8,
      paddingHorizontal: isMobile ? 0 : Spacing.XL,
      paddingVertical: isMobile ? Spacing.M : Spacing.XXL,
    },
    inputs: {
      marginVertical: isMobile ? Spacing.M : Spacing.XL,
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      flexWrap: "wrap",
    },
    labelIconError: {
      marginTop: Spacing.M,
    },
    labelIconErrorText: {
      fontSize: isMobile ? 13 : 16,
    },
    labelValidateButton: {
      paddingHorizontal: 54,
      paddingVertical: 15,
    },
    textInputDiv: {
      flex: 1,
      marginVertical: isMobile ? Spacing.M : Spacing.XL,
      justifyContent: "center",
      alignItems: "center",
    },
    closeBtn: {
      position: "absolute",
      top: 0,
      right: Spacing.XS,
      padding: Spacing.M,
    },
    title: {
      color: Colors.foodiBlack,
      fontFamily: "manrope-bold",
    },
  });
