import React, { useState, useMemo } from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Modal,
  TouchableWithoutFeedback,
  Pressable,
} from "react-native";
import { I18n } from "react-redux-i18n";
import { Colors } from "@constants/Colors";
import { Title16, Title22 } from "@stylesheets";
import { Button, CloseXButton, FloatTextInput } from "@atomic";
import { TestIDs } from "@utils";
import { responsiveSizeWidth, useDevices } from "@hooks";
import { Back, ToolTip } from "@assets";
import { useDispatch } from "react-redux";
import { HoldingView } from "@foodi/core";
import { HoldingThunks } from "@modules/holding/ducks/holding";

interface IProps {
  userHoldingViewInputData: any;
  currentHoldingView?: HoldingView;
  closeModal: () => void;
  closeParentModal: () => void;
  cancelClose: () => void;
}

const SecurityCodeProfileModal: React.FC<IProps> = ({
  userHoldingViewInputData,
  currentHoldingView,
  closeModal,
  closeParentModal,
  cancelClose,
}) => {
  const dispatch = useDispatch();
  const [isMobile] = useDevices();
  const styles = useMemo(() => _styles(isMobile), [isMobile]);
  const [loading, setLoading] = useState(false);
  const [securityCode, setSecurityCode] = useState<string>("");
  const [showToolTipMessage, setShowToolTipMessage] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [
    wrongSecuriyCodeMessage,
    setWrongSecuriyCodeMessage,
  ] = useState<string>("");
  const isSubmitBtnDisabled = !securityCode || showError;

  const onChangeSecurityCode = (text: string) => {
    setShowError(false);
    setWrongSecuriyCodeMessage("");
    setSecurityCode(text);
  };

  const onSubmitSecurityCode = async () => {
    const { actualValidationCode, ...rest } = userHoldingViewInputData;
    
    if (!!currentHoldingView && actualValidationCode && securityCode !== actualValidationCode){
      setShowError(true);
      setWrongSecuriyCodeMessage(
        I18n.t("profile.badgeValidationModal.securityCodeNotMatched")
      );
      setSecurityCode("");
      return;
    }

    const inputWithCode = {
      ...rest,
      guestValidationCode: securityCode,
    };
    try {
      setLoading(true);
      if (!!currentHoldingView) {
        await dispatch(HoldingThunks.updateUserHoldingView(inputWithCode));
      } else {
        await dispatch(HoldingThunks.addUserHoldingView(inputWithCode));
      }
      closeModal();
      closeParentModal();
    } catch (error) {
      if (
        error?.cause?.error?.message.includes("Validation code invalid") ||
        error?.cause?.error?.message.includes("Validation code needed")
      ) {
        setShowError(true);
        setWrongSecuriyCodeMessage(
          I18n.t("profile.badgeValidationModal.securityCodeNotMatched")
        );
        setSecurityCode("");
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <TouchableWithoutFeedback onPress={() => setShowToolTipMessage(false)}>
      <View testID={TestIDs.profile.views.securityCodeProfileModal}>
        <View style={styles.topContainer}>
          <Pressable style={styles.backButton} onPress={closeModal}>
            <Back />
            <Text style={styles.returnText}>{I18n.t("common.return")}</Text>
          </Pressable>
          <View style={styles.margin}>
            <View style={styles.textContainer}>
              <Title22 isBlack>
                {I18n.t("profile.badgeValidationModal.securityStep")}
              </Title22>
            </View>
            <View style={styles.textContainer}>
              <Title16>
                {I18n.t(
                  "profile.badgeValidationModal.enterSecurityCodeProvided"
                )}
              </Title16>
            </View>
            <View style={styles.listContainer}>
              <View style={styles.textInputContainer}>
                <FloatTextInput
                  forwardTestID={TestIDs.profile.texts.securityCode}
                  style={styles.textInput}
                  onChangeValue={onChangeSecurityCode}
                  value={securityCode}
                  hasError={showError}
                  errorMessage={wrongSecuriyCodeMessage}
                  textPlaceHolder={I18n.t(
                    "profile.badgeValidationModal.securityCode"
                  )}
                  maxLength={6}
                />
              </View>
              <View style={styles.toolTipContainer}>
                <TouchableOpacity
                  onPress={() => setShowToolTipMessage(!showToolTipMessage)}
                >
                  <ToolTip />
                  {showToolTipMessage && (
                    <View>
                      <Text style={styles.toolTipText}>
                        {I18n.t(
                          "profile.badgeValidationModal.securityCodeFoundInAssociatedAccountWithBadge"
                        )}
                      </Text>
                    </View>
                  )}
                </TouchableOpacity>
              </View>
            </View>
            <View style={styles.textContainer}>
              <Button
                forwardTestID={
                  TestIDs.profile.actions.pressSecurityCodeValidateBtnModal
                }
                loading={loading}
                onPress={onSubmitSecurityCode}
                disabled={isSubmitBtnDisabled}
                label={I18n.t("profile.badgeValidationModal.validateBtn")}
                styleButton={styles.firstButton}
              />
            </View>
            <View style={styles.textContainer}>
              <Pressable onPress={cancelClose}>
                <Title16 style={styles.skipText}>
                  {!!currentHoldingView
                    ? I18n.t(
                        "profile.badgeValidationModal.cancelBadgeConfirmation"
                      )
                    : I18n.t(
                        "profile.badgeValidationModal.cancelAddBadgeConfirmation"
                      )}
                </Title16>
              </Pressable>
            </View>
          </View>
        </View>
      </View>
    </TouchableWithoutFeedback>
  );
};
const _styles = (isMobile: boolean) =>
  StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      width: "100vw",
      height: "100vh",
      backgroundColor: "rgba(0,0,0,0.5)",
      zIndex: 9999,
    },
    topContainer: {
      borderRadius: 8,
      alignItems: "center",
      position: "relative",
      zIndex: 99999999,
    },
    backButton: {
      position: "absolute",
      color: Colors.gray1,
      top: -15,
      left: 0,
      height: 30,
      width: 100,
      borderRadius: 4,
      boxSizing: "border-box",
      flexDirection: "row",
      alignItems: "center",
      maxWidth: 250,
      marginBottom: 10,
      cursor: "pointer",
    },
    returnText: {
      color: Colors.foodiBlack,
      fontFamily: "manrope-bold",
      letterSpacing: 0,
      lineHeight: isMobile ? 22 : 30,
      fontSize: 16,
      marginLeft: 8,
    },
    closeModal: {
      position: "absolute",
      color: Colors.gray1,
      top: 10,
      right: isMobile ? 20 : 10,
      height: 4,
      borderRadius: 4,
    },
    closeModalBtn: {
      backgroundColor: "transparent",
    },
    bottomContainer: {
      marginTop: 20,
    },
    textContainer: {
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      margin: 4,
      zIndex: 9999,
    },
    listContainer: {
      flexDirection: "row",
      alignItems: "center",
      backgroundColor: Colors.white,
      zIndex: 99999,
    },
    textInputContainer: {
      margin: 10,
      backgroundColor: Colors.white,
      width: isMobile ? responsiveSizeWidth(320) : 350,
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 8,
      borderWidth: 1,
      borderColor: Colors.transparent,
    },
    textInput: {
      height: 50,
      width: isMobile ? responsiveSizeWidth(320) : 350,
      fontSize: 16,
      color: Colors.foodiBlack,
      fontWeight: "400",
      borderRadius: 8,
      paddingHorizontal: 17,
      paddingTop: 15,
      paddingBottom: 14,
      maxHeight: 120,
    },
    firstButton: {
      marginTop: 16,
      alignSelf: "center",
    },
    toolTipContainer: {
      position: "relative",
      flex: 1,
    },
    toolTipText: {
      borderRadius: 4,
      fontFamily: "manrope",
      justifyContent: "center",
      width: isMobile ? responsiveSizeWidth(278) : 270,
      minWidth: 270,
      minHeight: 10,
      backgroundColor: Colors.background1,
      textAlign: "left",
      padding: 5,
      position: "absolute",
      top: "130%",
      left: "50%",
      marginLeft: isMobile ? responsiveSizeWidth(-270) : -210,
    },
    skipText: {
      marginTop: 8,
      textDecorationLine: "underline",
    },
    margin: {
      marginTop: 16,
      marginBottom: 16,
    },
  });
export default React.memo(SecurityCodeProfileModal);
