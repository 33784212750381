"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_TOGGLE_TOKEN = void 0;
const client_1 = require("@apollo/client");
exports.GET_TOGGLE_TOKEN = client_1.gql `
    query getToggleToken($type: String!) {
        getWsToken(type: $type) {
            access_token
            message
        }
    }
`;
