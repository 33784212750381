import React from "react";
import { I18n } from "react-redux-i18n";
import {
  AffluenceBlock,
  AffluenceTitle
} from "./Affluence.style";
import { PosAffluence } from "@foodi/core/lib/domain/entities/PosAffluence";

interface IProps {
    affluence: PosAffluence | undefined | null;
}

export const Affluence: React.FC<IProps> = React.memo(
  ({ affluence }) => {

    if(!affluence || !affluence.enabled) return null;
    return (<AffluenceBlock>
        <affluence.icon />
        <AffluenceTitle>{I18n.t(affluence.label)}</AffluenceTitle>
    </AffluenceBlock>);
  }
);
