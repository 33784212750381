"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetBookingOrders = void 0;
class GetBookingOrders {
    constructor(orderRepository) {
        this.orderRepository = orderRepository;
    }
    execute(request) {
        return this.orderRepository.getBookingOrders(request);
    }
}
exports.GetBookingOrders = GetBookingOrders;
