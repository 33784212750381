import { NavigationProp, Route } from "@react-navigation/native";
import { View, StyleSheet } from "react-native";
import React, { useEffect, useMemo } from "react";
import {
  ClickAndCollectTemplate,
  FullBookingCart,
  FullCart,
  LoaderAnimationComponent,
  MiniBookingCart,
  MiniCart,
  OutOfStockModal,
  RestaurantInfo,
  ScreenWithFooter,
} from "@atomic";
import { Spacing, Colors } from "@constants";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState, LoaderActions } from "@redux";
import {
  HoldingSettingActions,
  OffersActions,
  PointOfSaleActions,
  PointOfSaleThunks,
} from "@modules";
import { useDevices, useHideZenDesk } from "@hooks";
import { PosResponse } from "@foodi/core";
import _ from "lodash";
import * as Sentry from '@sentry/react';
import { getNumericId } from '@utils';

interface IProps {
  navigation: NavigationProp<any>;
  route?: Route<any>;
}

export const RestaurantDetailScreen: React.FC<IProps> = 
  ({ navigation, route }) => {
    const { isModify, isRefillFromCart, id }: any = route?.params || {};

    const currentPosIdFromParams = _.get(route, "params.id");

    const dispatch = useDispatch();

    const [isMobile] = useDevices();

    const userLanguage = useSelector(
      (state: GlobalState) => state.auth?.userInfo?.language
    );
    const pointOfSale = useSelector((state: GlobalState) => state?.pointOfSale);
    const isCCButtonAvailable = pointOfSale?.posCCButtonStatus;
    const isBookingButtonAvailable = pointOfSale?.posBookingButtonStatus;
    const isMiniCartAvailable = pointOfSale?.miniCartEnabled;
    const isFullCartAvailable = pointOfSale?.fullCartEnabled;
    const isMiniBookingCartAvailable = pointOfSale?.miniBookingCartEnabled;
    const isFullBookingCartAvailable = pointOfSale?.fullBookingCartEnabled;
    const isPOSButtonEnabled = isCCButtonAvailable || isBookingButtonAvailable;

    const styles = useMemo(() => _styles(isPOSButtonEnabled, isMobile), [
      isPOSButtonEnabled,
      isMobile,
    ]);

      useEffect(() => {
          dispatch(OffersActions.resetOfferInfo());
          dispatch(PointOfSaleActions.setSelectedPos(null));
          return () => {
              dispatch(OffersActions.resetOfferInfo());
              dispatch(PointOfSaleActions.setSelectedPos(null));
          };
      }, []);

    const selectedPointOfSale = useSelector((state: GlobalState) => {
      const selectedPos = state.pointOfSale?.selectedPos?.pointOfSale;
      if (selectedPos && getNumericId(selectedPos.id) === currentPosIdFromParams) {
          return selectedPos;
      }

      if (selectedPos && selectedPos.id) {
        Sentry.captureMessage('Detected a POS mismatch between the POS in store and the POS in URL', {
            level: 'error',
            tags: {
                WRONG_POS_OFFERS_BUG: true
            },
            extra: {
                posIdFromURL: currentPosIdFromParams,
                mismatchPosId: getNumericId(selectedPos.id),
                component: 'modules/pointOfSale/screens/RestaurantDetail/index.ts'
            }
        });
      }
      return undefined;
    });

    const handlePosResponse = (res: PosResponse) => {
        const holdingSettings = res?.pointOfSale?.zone.holding?.settings;
        if (holdingSettings) {
            dispatch(HoldingSettingActions.setProductTransparencyPreferences(holdingSettings.productTransparencyPreferences));
            dispatch(HoldingSettingActions.setOffersProductTransparencyPreferences(holdingSettings.offersProductTransparencyPreferences));
        }
    };

    const getPos = () => {
        dispatch(LoaderActions.setLoading(true));
        dispatch(
            PointOfSaleThunks.getPos({
                id,
                days: 30,
            })
        )
            //@ts-ignore
            .then(handlePosResponse)
            .finally(() => {
                dispatch(LoaderActions.setLoading(false));
            });
      };

    useEffect(() => {
        if (id) getPos();

        return () => {
          dispatch(HoldingSettingActions.setProductTransparencyPreferences(null));
          dispatch(HoldingSettingActions.setOffersProductTransparencyPreferences(null));
          dispatch(PointOfSaleActions.setSelectedPos(null));
        }
      }, [id, userLanguage]);

    const isCartAvailable =
      isMiniCartAvailable ||
      isFullCartAvailable ||
      isMiniBookingCartAvailable ||
      isFullBookingCartAvailable;

    const shouldHideZenDesk = isMobile && isCartAvailable;

    useHideZenDesk({ condition: shouldHideZenDesk });

    // Prevent access to the detail page if the selected point od sale is not yet loaded.
    if (!selectedPointOfSale) {
      return (
        <View style={styles.loadingStyle}>
          <LoaderAnimationComponent />
        </View>
      );
    }

    return (
      <>
        <ScreenWithFooter navigation={navigation}>
          <View style={styles.container}>
            <RestaurantInfo navigation={navigation} route={route} />
            <View style={styles.bookingView}>
              <ClickAndCollectTemplate
                navigation={navigation}
                route={route}
                isModify={isModify}
                isRefillFromCart={isRefillFromCart}
                posId={id}
              />
            </View>
          </View>
        </ScreenWithFooter>
        {isMobile && isMiniCartAvailable && (
          <MiniCart
            navigation={navigation}
            isRefillFromCart={isRefillFromCart}
          />
        )}
        {isMobile && isFullCartAvailable && (
          <FullCart
            navigation={navigation}
            isRefillFromCart={isRefillFromCart}
            route={route}
          />
        )}
        {isMobile && isMiniBookingCartAvailable && (
          <MiniBookingCart navigation={navigation} />
        )}
        {isMobile && isFullBookingCartAvailable && (
          <FullBookingCart navigation={navigation} route={route} />
        )}
        <OutOfStockModal />
      </>
    );
  };

const _styles = (isPOSButtonEnabled: boolean, isMobile: boolean) =>
  StyleSheet.create({
    container: {
      flex: 1,
      paddingHorizontal: isMobile ? 0 : 150,
      marginBottom: isPOSButtonEnabled ? 50 : 0,
      backgroundColor: Colors.white,
    },
    calendarView: {
      marginVertical: Spacing.XL,
      alignItems: "flex-start",
      paddingLeft: isMobile ? 18 : 0,
    },
    title: {
      paddingLeft: isMobile ? 18 : 0,
    },
    bookingView: {
      backgroundColor: Colors.background1,
      paddingHorizontal: 150,
      marginHorizontal: -150,
      alignItems: "flex-start",
    },
    loadingStyle: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
    },
  });
