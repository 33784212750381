"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_LATEST_PICKUP_POINT = void 0;
const client_1 = require("@apollo/client");
exports.GET_LATEST_PICKUP_POINT = client_1.gql `
  query getUserLastUsedPickupPoint($idGuest: ID! $idOfferTemplate: ID) {
    userLastUsedPickupPoint(idGuest:$idGuest idOfferTemplate:$idOfferTemplate){
          id
          numericId
          idHolding
          name
          withdrawalSchedule
          companyCode
          description
    }
  }
`;
