import { Action } from "../action";
import { createReducer } from "@redux/createReducer";

export interface LoaderState {
  loading: boolean;
}

/*************  Actions  ****************/

const ActionTypes = {
  SET_LOADING: "loader/SET_LOADING",
};

const ActionCreators = {
  setLoading: (loading: boolean): Action<boolean> => ({
    type: ActionTypes.SET_LOADING,
    payload: loading,
  }),
};

/*************  Reducer  ****************/

const initialState: LoaderState = {
  loading: false,
};

const Reduction = {
  setLoading: (state: LoaderState, { payload: loading }: Action<boolean>) => ({
    loading,
  }),
};

const reducer = createReducer(initialState, {
  [ActionTypes.SET_LOADING]: Reduction.setLoading,
});

export default reducer;

export {
  reducer as LoaderReducer,
  ActionTypes as LoaderActionTypes,
  ActionCreators as LoaderActions,
};
