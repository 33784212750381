import React, { useMemo, useState } from "react";
import { formatAmount, IAmount, IPosType } from "@foodi/core";
import { Pressable, StyleSheet, View, ViewStyle } from "react-native";
import { Receipt, Title13, Title16 } from "@stylesheets";
import { Colors, Spacing } from "@constants";
import moment from "moment";
import _ from "lodash";
import { I18n } from "react-redux-i18n";
import { VisibilityBlack, Download } from "@assets";
import { useDevices } from "@hooks";
import {
  BalanceScreens,
  TransactionsViewModel,
  TransactionTypes,
} from "@modules";
import { useDispatch } from "react-redux";
import { CloseXButton, ModalTemplate } from "@atomic";
import { NavigationProp } from "@react-navigation/native";
import { htmlStringToPdfDownload } from "@utils";

interface IProps {
  title: string;
  transactionDate: string;
  transactionType: string;
  type?: IPosType;
  total: IAmount;
  style?: ViewStyle;
  forwardTestID?: string;
  id: string;
  navigation: NavigationProp<any>;
}

export const TransactionRow: React.FC<IProps> = React.memo(
  ({
    forwardTestID,
    title,
    transactionDate,
    total,
    id,
    navigation,
    transactionType,
  }) => {
    const dispatch = useDispatch();
    const [isMobile] = useDevices();
    const styles = useMemo(() => _styles(isMobile), [isMobile]);

    const [receipt, setReceipt] = useState<string | undefined>("");

    const amount = parseFloat(total?.amount ?? "0");
    const isRecharge = !title;
    let formattedAmount = isRecharge ? "+" : "";
    formattedAmount += `${formatAmount(Math.abs(amount), "€")}`;

    const transactionVM = new TransactionsViewModel();

    const handleDetail = () => {
      navigation?.navigate(BalanceScreens.TICKET_PAGE, {
        id,
      });
    };

    const handleReceipt = async () => {
      const result = await transactionVM.getHandleReceipt(
        receipt || "",
        setReceipt,
        dispatch,
        id
      );
      if (_.isEmpty(result))
        navigation.navigate(BalanceScreens.ERROR_RECEIPT, {
          errorType: "errorHandler.errorReceipt",
        });
    };

    const handleClose = () => {
      setReceipt(undefined);
    };

    const handlePdfReceiptDownload = async () => {
      const htmlReceipt =
        (await transactionVM.getHandleReceipt(
          "",
          (value) => value,
          dispatch,
          id
        )) || "";
      await htmlStringToPdfDownload(
        htmlReceipt,
        `${moment(new Date(transactionDate)).format(
          "yyyy/MM/DD_HH:mm"
        )}_${I18n.t("refill.receiptCopy")}`,
        isMobile
      );
    };

    return (
      <>
        {/* FS-7323: Hide transaction detail for Release 18.
        NOTE: Dont delete this code, its to be ready after Release 18.
        <Pressable
          testID={forwardTestID}
          style={styles.container}
          onPress={handleDetail}
          disabled={!title}
        > */}
        <View style={styles.container}>
          <View>
            <View style={styles.rowStyle}>
              <View style={styles.infoDiv}>
                <Title16
                  style={styles.title}
                  numberOfLines={1}
                  ellipsizeMode={"tail"}
                >
                  {transactionType === TransactionTypes.ORDER
                    ? title
                    : _.upperFirst(I18n.t("refill.recharge"))}
                </Title16>
                <View style={styles.rowStyle2}>
                  <Title13 style={styles.date}>
                    {_.upperFirst(
                      moment(new Date(transactionDate)).format(
                        "DD/MM/yyyy  HH:mm"
                      )
                    )}
                  </Title13>
                  <Pressable onPress={handleReceipt}>
                    <VisibilityBlack />
                  </Pressable>
                  <Pressable
                    style={styles.downLoadContainer}
                    onPress={handlePdfReceiptDownload}
                  >
                    <Download />
                  </Pressable>
                </View>
              </View>
            </View>
          </View>
          <Title16 style={[styles.title, isRecharge && [styles.recharge]]}>
            {formattedAmount}
          </Title16>
        </View>
        {/* </Pressable> */}
        <ModalTemplate
          isOpen={!!receipt}
          closeOnClickOutside
          handleClose={handleClose}
          style={styles.receiptDiv}
          customComponentCloseButton={
            <>
              <CloseXButton callback={handleClose} />
              <Pressable
                style={styles.downLoadContainerModal}
                onPress={handlePdfReceiptDownload}
              >
                <Download />
              </Pressable>
            </>
          }
        >
          {receipt && (
            <Receipt
              style={styles.receipt}
              dangerouslySetInnerHTML={{ __html: receipt }}
            />
          )}
        </ModalTemplate>
      </>
    );
  }
);

const _styles = (isMobile: boolean) =>
  StyleSheet.create({
    container: {
      marginTop: isMobile ? Spacing.M : Spacing.L,
      flex: 1,
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    rowStyle: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-start",
    },
    rowStyle2: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    infoDiv: {
      marginLeft: 0,
      justifyContent: "center",
      alignItems: "flex-start",
    },
    title: {
      fontWeight: "600",
    },
    date: {
      color: Colors.foodiBlack,
      marginRight: 10,
    },
    recharge: {
      color: Colors.middleGreen,
    },
    receiptDiv: {
      width: isMobile ? "100%" : 520,
      maxHeight: isMobile ? "100%" : "95vh",
    },
    downLoadContainer: {
      marginLeft: 10,
    },
    downLoadContainerModal: {
      position: "absolute",
      right: 60,
      top: -5,
    },
    receipt: {
      marginTop: 10,
    },
  });
