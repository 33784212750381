import React from "react";
import { I18n } from "react-redux-i18n";

export const CircleAdd: React.FC = () => (
  <svg
    width="40px"
    height="40px"
    viewBox="0 0 40 40"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>{I18n.t("account.add")}</title>
    <g
      id="Homepage"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Profil-compte_Default_NoHolding_NoBadge"
        transform="translate(-554.000000, -634.000000)"
      >
        <g id="Group" transform="translate(554.000000, 633.000000)">
          <circle id="Oval" stroke="#090909" cx="20" cy="21" r="19.5"></circle>
          <text
            id="+"
            font-family="Manrope-Bold, Manrope"
            font-size="30"
            font-weight="bold"
            fill="#090909"
          >
            <tspan x="11" y="32">
              +
            </tspan>
          </text>
        </g>
      </g>
    </g>
  </svg>
);
