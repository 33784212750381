"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_TICKET = void 0;
const client_1 = require("@apollo/client");
exports.GET_TICKET = client_1.gql `
  query getTicket($transactionId: ID!) {
    getTicket(idTransaction: $transactionId) {
      id
      admission {
        amount
        currency
        subsidy
      }
      elements {
        label
        quantity
        total {
          amount
          currency
        }
      }
      fee {
        amount
        currency
        subsidy
      }
      foodstuff {
        subsidy
        currency
      }
      guest {
        id
        lastName
        firstName
        holding {
          id
          name
        }
      }
      pos {
        id
        name
      }
      date: validDate
      total {
        amount
        currency
      }
      oldBalance {
        amount
        currency
      }
      refill {
        amount
        currency
      }
      newBalance {
        amount
        currency
      }
    }
  }
`;
