import React from "react";
import { I18n } from "react-redux-i18n";

export const TakeAway: React.FC = () => (
  <svg
    width="19px"
    height="18px"
    viewBox="0 0 19 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>{I18n.t("homepage.restaurantCard.takeOut")}</title>
    <g
      id="Design-System"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="Icons-pg1" transform="translate(-407.000000, -409.000000)">
        <g id="Group" transform="translate(406.500000, 408.000000)">
          <rect id="Rectangle" x="0" y="0" width="20" height="20"></rect>
          <path
            d="M16.053011,15.2495678 C15.5711855,11.9855283 14.9114667,5.87601749 14.6423938,5.24754553 C14.3733208,4.61907356 15.4064419,3.53399293 14.9114667,2.67727858 C14.4164916,1.82056424 5.24208335,1.83332698 4.38052882,2.3986048 C3.5189743,2.96388262 3.65431713,5.17270384 4.01742298,5.57256169 C4.38052882,5.97241954 9.38349966,5.91356225 11.6829633,5.83723363 C13.9824269,5.76090502 16.1133012,6.4546776 13.4432885,6.46793598 C10.7732758,6.48119436 4.049677,5.28372727 3.61002988,7.34360857 C3.17038276,9.40348986 2.14768218,15.307186 2.00241503,16.2254836 C1.85714788,17.1437813 8.29815185,18 12.464379,18 C16.6306061,18 16.3602925,16.8962089 15.3006239,10.4377674 C14.2409552,3.97932578 15.6490334,2.24273986 15.6490334,3.1092431 C15.6490334,3.97574634 14.6383,5.39028995 16.053011,8.61158049 C17.467722,11.832871 17.7214122,15.4866078 17.9836622,16.2254836 C18.2459122,16.9643595 15.2285864,16.9156911 17.6851023,16.8352735 C16.9182584,17.9541627 16.3742279,17.4255941 16.053011,15.2495678 Z"
            id="Path-5-Copy-8"
            stroke="#090909"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);
