import React from "react";
import { StyleSheet, ViewStyle } from "react-native";
import { Title13 } from "@stylesheets";

interface IProps {
  title: string;
  style?: ViewStyle;
  forwardTestID?: string;
  children?: React.ReactNode;
}

export const Label: React.FC<IProps> = React.memo(
  ({ style, forwardTestID, title }) => {
    return (
      <Title13 testID={forwardTestID} style={[styles.container, style]}>
        {title}
      </Title13>
    );
  }
);

const styles = StyleSheet.create({
  container: {
    margin: 2,
  },
});
