"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SCOLAPASS_PAYMENTS_FRAGMENT = void 0;
const client_1 = require("@apollo/client");
exports.SCOLAPASS_PAYMENTS_FRAGMENT = client_1.gql `
  fragment scolapassPaymentsFragment on PaymentConnection {
    pageInfo {
      endCursor
      hasNextPage
      __typename
    }
    edges {
      node {
        PAI_No_Reglt
        FAM_ID
        PAI_C_Reglt
        PAI_Date
        PAI_Montant
        __typename
      }
      __typename
    }
    __typename
  }
`;
