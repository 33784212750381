import React, { useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { Title16, Title18 } from "@stylesheets";
import { I18n } from "react-redux-i18n";
import { useDevices } from "@hooks";
import { IMappedSchedules, RestaurantCardViewModel } from "@modules";
import { useSelector } from "react-redux";
import { GlobalState } from "@redux";
import { PosScheduleRow } from "@atomic/molecules";
import { TestIDs } from "@utils";
import moment from "moment";

interface IProps {
  schedules: IMappedSchedules[];
}

export const PosScheduleList: React.FC<IProps> = React.memo(({ schedules }) => {
  const [isMobile] = useDevices();
  const language = useSelector(
    (state: GlobalState) => state.auth?.userInfo?.language
  );
  const restaurantCardViewModel = new RestaurantCardViewModel();

  const addTopMargin = moment().isoWeekday() !== 1;
  const styles = useMemo(() => _styles(addTopMargin), [addTopMargin]);

  const ScheduleText: React.FC<any> = isMobile ? Title16 : Title18;

  const formattedScheduleList = restaurantCardViewModel.formattedScheduleList(
    schedules,
    language
  );

  return (
    <View
      testID={TestIDs.restaurantDetail.views.posScheduleList}
      style={styles.container}
    >
      <View style={styles.leftDiv}>
        <ScheduleText isBold bold>
          {I18n.t("restaurantDetail.schedule")}
        </ScheduleText>
      </View>
      <View style={styles.rightDiv}>
        {formattedScheduleList.map((schedule) => (
          <PosScheduleRow schedule={schedule} />
        ))}
      </View>
    </View>
  );
});

const _styles = (addTopMargin: boolean) =>
  StyleSheet.create({
    container: {
      flexDirection: "row",
      marginTop: 5,
    },
    leftDiv: {
      marginRight: 10,
    },
    rightDiv: {
      flex: 1,
      flexDirection: "column",
      marginTop: addTopMargin ? 3 : 0,
    },
  });
