import React from "react";

interface IProps {
    width?: number;
    height?: number;
  }

export const SecurityCode: React.FC<IProps> = ({ width = 30, height = 30 }) => (
    <svg 
        xmlns="http://www.w3.org/2000/svg" 
        xmlnsXlink="http://www.w3.org/1999/xlink" 
        width={`${width}px`}
        height={`${height}px`} 
        viewBox="0 0 30 30" 
        version="1.1">
        <title>81052F46-A599-4621-B2BB-E2AEB7F9BA78</title>
        <g 
            id="Icons/Security_code_black" 
            stroke="none" 
            stroke-width="1" 
            fill="none" 
            fill-rule="evenodd">
            <circle 
                id="Oval" 
                stroke="#090909" 
                cx="15" 
                cy="15" 
                r="14.5"/>
            <path 
                d="M9.5744769,10.7530483 C10.5744769,10.7530483 11.4554293,11.0877591 12.217334,11.7571806 C12.9792388,12.426602 13.5030483,13.2695773 13.7887626,14.2861062 L25.2530483,14.2861062 L25.2530483,16.5175111 L23.8244769,16.5175111 L23.8244769,19.7530483 L21.6816198,19.7530483 L21.6816198,16.5175111 L13.7887626,16.5175111 C13.5268579,17.4844533 13.0010245,18.265445 12.2112626,18.8604863 C11.4215007,19.4555277 10.5425721,19.7530483 9.5744769,19.7530483 C8.36019118,19.7530483 7.33638166,19.319164 6.50304833,18.4513954 C5.66971499,17.5836268 5.25304833,16.5175111 5.25304833,15.2530483 C5.25304833,13.9885855 5.66971499,12.9224698 6.50304833,12.0547012 C7.33638166,11.1869326 8.36019118,10.7530483 9.5744769,10.7530483 Z M9.57258404,12.9844533 C8.95479833,12.9844533 8.43757214,13.2020525 8.02090547,13.6372508 C7.6042388,14.0724244 7.39590547,14.6116806 7.39590547,15.2550194 C7.39590547,15.8983335 7.60486976,16.4369326 8.02279833,16.8708169 C8.44070309,17.3047012 8.95856023,17.5216434 9.57636976,17.5216434 C10.1941555,17.5216434 10.7113817,17.3040442 11.1280483,16.8688458 C11.544715,16.4336723 11.7530483,15.8944161 11.7530483,15.2510773 C11.7530483,14.6077632 11.544084,14.069164 11.1261555,13.6352797 C10.7082507,13.2013954 10.1903936,12.9844533 9.57258404,12.9844533 Z" 
                id="Combined-Shape" 
                fill="#090909" 
                transform="translate(15.253, 15.253) scale(-1, 1) rotate(45) translate(-15.253, -15.253)"/>
        </g>
    </svg>
);
