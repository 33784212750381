import { Colors } from "@constants";
import { BalanceScreens, PaymentsThunks, AccountThunks } from "@modules";
import { ForceInfoAppUpdateActions, GlobalState, LoaderActions } from "@redux";
import { NavigationProp } from "@react-navigation/native";
import React from "react";
import { ActivityIndicator } from "react-native";
import { useDispatch, useSelector } from "react-redux";

interface IProps {
  navigation: NavigationProp<any>;
}

export const MercanetLoadingScreen: React.FC<IProps> = React.memo(
  ({ navigation }) => {
    const dispatch = useDispatch();
    const accessToken = useSelector(
      (state: GlobalState) => state.auth.authToken?.accessToken
    );
    const userInfo = useSelector((state: GlobalState) => state.auth?.userInfo);

    const refillId = new URLSearchParams(window?.location?.search).get(
      "idRefill"
    );

    React.useEffect(() => {
      if (refillId) {
        dispatch(PaymentsThunks.getPaymentSatus(refillId, accessToken || ""))
          // @ts-ignore
          .then(
            // @ts-ignore
            async ({ refillSuccess, transactionSuccess, isNetworkError }) => {
              let errorType = "refill.defaultTitle";
              let successMessage = null;

              if (isNetworkError) {
                errorType = "refill.networkErrorTitle";
              } else if (transactionSuccess === false) {
                errorType = "refill.failTitle";
              } else if (transactionSuccess === null) {
                // this case should never produce
              } else if (refillSuccess) {
                successMessage = "refill.successTitle";
              } else if (refillSuccess === false || refillSuccess === null) {
                successMessage = "refill.delayTitle";
              }

              if (successMessage) {
                dispatch(LoaderActions.setLoading(true));
                // @ts-ignore
                await dispatch(
                  AccountThunks.getBalance({
                    id: userInfo?.id || "",
                  })
                );
                dispatch(ForceInfoAppUpdateActions.setForceAppUpdate(true));
                dispatch(LoaderActions.setLoading(false));
                navigation.navigate(BalanceScreens.SUCCEED_REFILL_SCREEN, {
                  successMessage,
                });
                return;
              }

              return navigation.navigate(BalanceScreens.ERROR_RECEIPT, {
                errorType,
              });
            }
          );
      }
    }, []);

    return (
      <ActivityIndicator
        size="large"
        color={Colors.black}
        style={{ height: "100vh" }}
      />
    );
  }
);
