import { Colors } from "@constants";
import { GlobalState } from "@redux";
import { TestIDs } from "@utils";
import React from "react";
import { Animated, View, StyleSheet } from "react-native";
import { useSelector } from "react-redux";
//@ts-ignore
import LottieView from "react-native-web-lottie";

export const LoaderAnimation: React.FC = React.memo(({}) => {
  const loading = useSelector((state: GlobalState) => state.loader.loading);
  return loading ? (
    <View
      style={styles.container}
      testID={TestIDs.components.loader.views.container}
    >
      <LoaderAnimationComponent />
    </View>
  ) : null;
});

export const LoaderAnimationComponent = () => (
  <Animated.View style={styles.animation}>
    <LottieView
      source={require("../../../assets/animations/foodi-loader-animation.json")}
      autoPlay
      loop={true}
    />
  </Animated.View>
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: "absolute",
    zIndex: 1,
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    backgroundColor: Colors.background1,
  },
  animation: {
    height: 250,
    width: 250,
  },
});
