import _ from "lodash";
import { BookingOffers } from "../ducks";

export enum BookingPeriod {
  DAILY = "DAILY",
  WEEKLY_MON = "WEEKLY_MON",
}

export class BookingViewModel {

  getBookingDayIndex = (currentReservation: any, nextBookingOffers:BookingOffers[] | null): number => {
    const haveOffer = nextBookingOffers && nextBookingOffers.findIndex(({ withdrawRange }) => 
      withdrawRange.split("/")?.[1].split("T")?.[0] === currentReservation?.withdrawRange?.split("/")?.[1].split("T")?.[0]
    );
    return haveOffer || 0;
  };
 
}
