import React from "react";
import { StyleSheet, TextStyle, View, ViewStyle } from "react-native";
import { Colors } from "@constants";
import { Title16 } from "@stylesheets";
import { Warning } from "@assets";
interface IProps {
  title: string;
  style?: ViewStyle;
  textStyle?: TextStyle;
  forwardTestID?: string;
}

export const LabelIconError: React.FC<IProps> = React.memo(
  ({ title, style, forwardTestID, textStyle }) => {
    return (
      <View style={[styles.invalidDiv, style]} testID={forwardTestID}>
        <Warning />
        <Title16 style={[styles.invalidText, textStyle]}>{title}</Title16>
      </View>
    );
  }
);

const styles = StyleSheet.create({
  invalidText: {
    color: Colors.redError,
    marginLeft: 5,
  },
  invalidDiv: {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
});
