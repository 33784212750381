import React from "react";
import _ from "lodash";
import { Title16 } from "@stylesheets";
import { View, StyleSheet, Pressable } from "react-native";
import { VisibilityBlack } from "@assets";
import { ComputedClassroom } from "@foodi/core";
import { Colors } from "@constants";
import { I18n } from "react-redux-i18n";
import { useDispatch } from "react-redux";
import { SchedulerViewModel } from "@modules";

interface IProps {
  commentsByClassroom?: ComputedClassroom[];
  handleCommentsModal: () => void;
  serviceId: number;
}

export const EpointageComments: React.FC<IProps> = ({
  commentsByClassroom,
  handleCommentsModal,
  serviceId,
}) => {
  const dispatch = useDispatch();
  const [schedulerVM] = React.useState<SchedulerViewModel>(
    new SchedulerViewModel(dispatch)
  );

  const classroomFilteredbyService = commentsByClassroom?.filter(
    (c: ComputedClassroom) =>
      schedulerVM.getCommentWithServiceId(c.comments, serviceId)
  );
  const commentsLength = classroomFilteredbyService?.length;
  const commentsTitle =
    commentsLength === 1
      ? I18n.t("epointage.homepage.classroom.comment")
      : I18n.t("epointage.homepage.classroom.comments");
  return (
    <View style={styles.commentsWrapper}>
      {commentsLength !== 0 && (
        <Pressable
          style={styles.commentsContainer}
          onPress={handleCommentsModal}
        >
          <Title16 color={Colors.blue1} bold>
            {commentsLength} {commentsTitle}
          </Title16>
          <View style={styles.visibilityIconContainer}>
            <VisibilityBlack />
          </View>
        </Pressable>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  commentsWrapper: {
    paddingLeft: 20,
    maxWidth: 310,
    flex: 0.5,
  },
  commentsContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    textDecorationLine: "underline",
    textDecorationColor: Colors.blue1,
  },
  visibilityIconContainer: {
    marginLeft: 5,
    marginTop: 2,
  },
});
