import { ToolTip } from "@assets";
import { InSite } from "@assets/icons/InSite";
import { RadioGroup } from "@atomic/molecules";
import { CloseClickOutsideTemplate } from "@atomic/templates";
import { Colors } from "@constants";
import { IPaymentTypes } from "@foodi/core";
import { Title13 } from "@stylesheets";
import React, { useState } from "react";
import { TouchableOpacity, View, StyleSheet } from "react-native";
import { I18n } from "react-redux-i18n";
import {
  FlexDirectionRow,
  PaymentContainer,
  PaymentTextContainer,
  ProductDescription,
  TolTipRow,
} from "./Cart.style";

interface IOnSite {
  paymentMethod: any;
  setPaymentMethod: React.Dispatch<React.SetStateAction<string | undefined>>;
  handleTriggerQuotationAndPaymentMethod: (
    newPaymentMethod: string
  ) => Promise<void>;
}
export const OnSiteMethodPayment = ({
  paymentMethod,
  setPaymentMethod,
  handleTriggerQuotationAndPaymentMethod,
}: IOnSite) => {
  const [isToolTipOpen, setToolTipOpen] = useState<boolean>(false);
  return (
    <View style={styles.container}>
      <PaymentContainer>
        <FlexDirectionRow>
          <InSite />
          <PaymentTextContainer>
            <TolTipRow>
              <ProductDescription>
                {I18n.t("restaurantDetail.cart.onSite")}
              </ProductDescription>
              <TouchableOpacity
                style={styles.tooltipIconDiv}
                onPress={() => setToolTipOpen(true)}
              >
                <ToolTip />
              </TouchableOpacity>
            </TolTipRow>
          </PaymentTextContainer>
        </FlexDirectionRow>
        <RadioGroup
          radioButtonsProps={[{ id: IPaymentTypes.OnSite }]}
          value={paymentMethod}
          onPress={handleTriggerQuotationAndPaymentMethod}
        />
        {isToolTipOpen && (
          <View style={styles.tooltipContainer}>
            <CloseClickOutsideTemplate
              handleClose={() => setToolTipOpen(false)}
            >
              <View style={styles.tooltip}>
                <Title13 color={Colors.foodiBlack}>
                  {I18n.t("restaurantDetail.cart.tooltipMessage")}
                </Title13>
              </View>
            </CloseClickOutsideTemplate>
          </View>
        )}
      </PaymentContainer>
      <Title13 style={styles.description}>
        {I18n.t("restaurantDetail.cart.pickUpPaid")}
      </Title13>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    zIndex: 10000,
    marginBottom: 16,
  },
  tooltipContainer: {
    position: "absolute",
    backgroundColor: "white",
    top: 50,
  },
  tooltip: {
    padding: 8,
    borderWidth: 1,
    borderColor: "black",
    backgroundColor: "white",
  },
  tooltipIconDiv: {
    marginLeft: -16,
  },
  description: {
    marginTop: -14,
    color: Colors.foodiBlack,
    marginLeft: 32,
  },
});
