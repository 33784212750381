"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetBalance = void 0;
class GetBalance {
    constructor(accountService) {
        this.accountService = accountService;
    }
    execute(request) {
        return this.accountService.getBalance(request);
    }
}
exports.GetBalance = GetBalance;
