import { JEST } from "@utils";
import { Dimensions } from "react-native";
import style from "styled-components/native";
import { RESTAURANT_CARD_WIDTH } from "../RestaurantCard/RestaurantCard.style";

export const COMING_SOON_WIDTH = 305;
const marginLeftContainer = Math.round((Dimensions.get('window').width - RESTAURANT_CARD_WIDTH) / 2);

export const Wrapper = style.View``;

export const Container = style.View<{ isFullWidth?: boolean, isMobile?: boolean }>`
  ${({isMobile, isFullWidth}) => !isMobile ? ` width: ${(isFullWidth ? COMING_SOON_WIDTH : 250)}px;` : 'width: 120px'}
  position: relative;
  justify-content: space-between;
  align-items: center;
`;

export const ModalContainer = style.View<{
  isAligned?: boolean;
  isFixed?: boolean 
  }>`
  position: ${({ isFixed }) =>
      /* istanbul ignore next */
      JEST || !isFixed ? "absolute" : "fixed"};

  margin-top: 1px;
  
  ${({isFixed, isAligned}) => isFixed ? ( 
    `left: ${marginLeftContainer}px;
    top: 45px;`
  ) :
  (
  `left: ${isAligned ? `0` : `-60`}px;
  top: 30px;`
  )}
`;
