"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_OFFER_TEMPLATES = void 0;
const client_1 = require("@apollo/client");
exports.GET_OFFER_TEMPLATES = client_1.gql `
  query getOfferTemplates(
    $type: String!
    $withdrawalType: String!
    $idPos: String!
  ) {
    list(
      type: "OfferTemplate_v2"
      querySearch: [
        { key: "published", value: "true" }
        { key: "type", operator: "*", value: $type }
        { key: "withdrawalType", operator: "*", value: $withdrawalType }
        { key: "idPos", operator: "*", value: $idPos }
        { key: "onlyOpenOffers", value: "true" }
      ]
      order: "name"
    ) {
      edges {
        node {
          ... on Entity {
            id
          }
          ... on OfferTemplate {
            id
            name
            orderStartDefault
            orderEndDefault
            withdrawalType
            daysInAdvanceStart
            daysInAdvanceEnd
            type
            published
            enableComment
            pos {
              id
            }
            description
            fullDescription
            image: image_v2 {
              path
            }
            period
            salesType
            bundle {
              id
              name
              description
              price
            }
            mealHeartRule {
              information
              allowedFamilies
              selectedFamilies
              quantityRules {
                property
                min
                max
              }
            }
            nextOrderableOffer {
              id
              orderRange
              withdrawRange
              available
              mealHeartOrderAvailable
              isMealHeartRuleFullfilled
              offerItems {
                id
                quantityPurchasable
                quantityRemaining
                baking
                articleTags {
                  numericId
                  label
                }
                articleCertifications {
                  numericId
                  label
                }
                inheritedLabel
                inheritedDescription
                inheritedFamily
                inheritedImage
                inheritedPrice {
                  amount
                  currency
                }
                container {
                  id
                  label
                  price {
                    amount 
                    currency
                  }
                }
                quantityOverall
              }
              offerItemsFormula {
                isFormulaComplete
              }
            }
            translations {
              name
              description
              language
              fullDescription
            }
            nextOrderableOffers {
              id
              published
              orderRange
              withdrawRange
              fullyBooked
              offerItems {
                id
                quantityPurchasable
                quantityRemaining
                baking
                articleTags {
                  numericId
                  label
                }
              }
            }
            paymentMethod {
              id
              paymentTypes
            }
            pickupPoints {
              id
              name
              withdrawalSchedule
              description
            }
            admission
            subsidies
            isRoomService
          }
          ... on TableServiceOfferTemplate {
            id
            name
            enableComment
            pos {
              id
            }
            description
            fullDescription
            image: image_v2 {
              path
            }
            salesType
            bundle {
              id
              name
              description
              price
            }
            mealHeartRule {
              information
              allowedFamilies
              selectedFamilies
              quantityRules {
                property
                min
                max
              }
            }
            nextOrderableOffer {
              id
              orderRange
              available
              mealHeartOrderAvailable
            }
          }
        }
      }
    }
  }
`;
