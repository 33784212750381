"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_OPTOUTS = void 0;
const client_1 = require("@apollo/client");
exports.UPDATE_OPTOUTS = client_1.gql `
  mutation updateOptouts($input: InputUpdateUser) {
    updateUser(input: $input) {
      id
      userOptouts {
        code
      }
    }
  }
`;
