import React from "react";
import { Title13, Title16, Title18 } from "@stylesheets";
import { View, StyleSheet, Pressable, ActivityIndicator } from "react-native";
import { I18n } from "react-redux-i18n";
import { Back, CheckCircle, Warning } from "@assets";
import { DownloadOptions } from ".";
import { Colors } from "@constants";
import { Checkbox, DatePicker, EpointagePDF } from "@atomic";
import moment from "moment";
import { DropDownSelect, EpointageThunks, ExportFilesViewModel, IListItem } from "@modules";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "@redux";
import { pdf } from "@react-pdf/renderer";
import { downloadFile } from "@utils";
import * as Sentry from "@sentry/react";

interface IProps {
  downloadOption: string | undefined;
  handleGoBack: () => void;
}

export const Step2: React.FC<IProps> = React.memo(
  ({ downloadOption, handleGoBack }) => {
    const dispatch = useDispatch();
    const [hasSchool, setHasSchool] = React.useState<boolean>(false);
    const [hasClassroom, setHasClassroom] = React.useState<boolean>(false);
    const [hasStudent, setHasStudent] = React.useState<boolean>(false);
    const [showStartDate, setShowStartDate] = React.useState<boolean>(false);
    const [showEndDate, setShowEndDate] = React.useState<boolean>(false);
    const [startDate, setStartDate] = React.useState<Date | undefined>();
    const [endDate, setEndDate] = React.useState<Date | undefined>();

    const startDateText = startDate
      ? moment(startDate).format("DD.MM.YYYY")
      : I18n.t("epointage.homepage.downloadModal.startDate");
    const endDateText = endDate
      ? moment(endDate).format("DD.MM.YYYY")
      : I18n.t("epointage.homepage.downloadModal.endDate");

    const serviceText = I18n.t("epointage.homepage.service");
    const schoolText = I18n.t("epointage.homepage.school");
    const allSchoolsText = I18n.t(
      "epointage.homepage.downloadModal.allSchools"
    );

    const isExcel = downloadOption === DownloadOptions.EXCEL;
    const fileType = isExcel ? "excelOption" : "pdfOption";
    const description = isExcel ? "excelInfoText" : "pdfInfoText";

    const { services, schools } = useSelector(
      (state: GlobalState) => state.epointage
    );

    const accessToken = useSelector(
      (state: GlobalState) => state.auth.authToken?.accessToken
    );

    const [selectedService, setSelectedService] = React.useState<
      IListItem | undefined
    >(services?.[0]);
    const [checks, setChecks] = React.useState<IListItem[]>(schools);
    const [
      selectedSchoolsText,
      setSelectedSchoolsText,
    ] = React.useState<string>(allSchoolsText);
    const [errors, setErrors] = React.useState<boolean>(false);
    const [downloaded, setDownloaded] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);

    const selected =
      selectedSchoolsText !== schoolText ? selectedSchoolsText : undefined;

    const hasErrors =
      !selectedService ||
      !checks.length ||
      !startDate ||
      !endDate ||
      (isExcel && !hasClassroom && !hasSchool && !hasStudent);

    const styles = React.useMemo(
      () => _styles(startDate, endDate, hasErrors, errors, isExcel),
      [startDate, endDate, hasErrors, errors, isExcel]
    );

    const handleDownload = async () => {
      let pdfExport: any = null, excelExport: any = null;
      try {
        const params = {
          startDate: moment(startDate).format("YYYY.MM.DD"),
          endDate: moment(endDate).format("YYYY.MM.DD"),
          idService: Number(selectedService?.id),
          idSchools: checks?.map((school: IListItem) => school.id),
          siteId: window.config.EPOINTAGE_SITEID,
        };

        if (hasErrors && !errors) setErrors(true);
        else if (!isExcel && selectedService) {
          setLoading(true);
          pdfExport = await dispatch(
            EpointageThunks.getPdfExport(params)
          );

          const blob = await pdf(
            <EpointagePDF
              schedules={pdfExport?.schedules}
              dates={pdfExport?.dates}
              service={selectedService}
            />
          ).toBlob();
          downloadFile(
            new Blob([blob], { type: "application/pdf" }),
            "pointage_pdf.pdf"
          );
        } else if (isExcel && accessToken) {
          setLoading(true);
          const exportVM = new ExportFilesViewModel(dispatch, accessToken);
          const exportTypes = [];
          if (hasClassroom) exportTypes.push('DetailsPerClass');
          if (hasSchool) exportTypes.push('DetailsPerSchool');
          if (hasStudent) exportTypes.push('DetailsPerStudent');
          
          await exportVM.handleExcelExport({
            ...params,
            exportTypes
          });
          excelExport = 'done';
        }
      } catch (err) {
        Sentry.captureException(err);
      } finally {
        setLoading(false);
      }
      if (!hasErrors && (!!pdfExport || !!excelExport)) setDownloaded(true);
    };

    React.useEffect(() => {
      if (!hasErrors) setErrors(false);
    }, [
      selectedService,
      checks,
      startDate,
      endDate,
      hasClassroom,
      hasSchool,
      hasStudent,
    ]);

    const handleHasSchool = () => {
      setHasSchool(!hasSchool);
    };

    const handleHasClassroom = () => {
      setHasClassroom(!hasClassroom);
    };

    const handleHasStudent = () => {
      setHasStudent(!hasStudent);
    };

    const handleStartDate = () => {
      if (showEndDate) setShowEndDate(false);
      setShowStartDate(!showStartDate);
    };

    const handleEndDate = () => {
      if (showStartDate) setShowStartDate(false);
      setShowEndDate(!showEndDate);
    };

    const handleSetStartDate = (date: Date | undefined) => {
      setStartDate(date);
      setShowStartDate(!showStartDate);
      if (!endDate) setShowEndDate(!showEndDate);
    };

    const handleSetEndDate = (date: Date | undefined) => {
      setEndDate(date);
      setShowEndDate(!showEndDate);
    };

    const handleService = (item: IListItem) => {
      setSelectedService(item);
    };

    const joinSchoolNames = (list: IListItem[]) => {
      return list.map((item: IListItem) => item.name).join(", ");
    };

    const handleCheckOption = (school: IListItem, check: boolean) => {
      const isAll = school.id === "all";
      let result, text;
      if (isAll) {
        result = !check ? [] : schools;
        text = !check ? schoolText : allSchoolsText;
      } else if (check) {
        result = [...checks, school];
        text =
          result.length === schools.length
            ? allSchoolsText
            : joinSchoolNames(result);
      } else {
        result = checks.filter((item: IListItem) => item.id !== school.id);
        text = joinSchoolNames(result);
      }

      setChecks(result);
      setSelectedSchoolsText(text);
    };

    const form = () => (
      <View style={styles.container}>
        <Title13 color={Colors.gray1}>
          {I18n.t(`epointage.homepage.downloadModal.${fileType}`)}
        </Title13>
        {isExcel && (
          <>
            <Title16 bold style={styles.levelOfInfoContainer}>
              {I18n.t("epointage.homepage.downloadModal.levelOfInfo")}
            </Title16>
            <View style={styles.checkboxesContainer}>
              <View style={styles.checkboxRow}>
                <Checkbox
                  onPress={handleHasSchool}
                  checked={hasSchool}
                  hasError={errors}
                />
                <View style={styles.checkboxText}>
                  <Title16>
                    {I18n.t("epointage.homepage.downloadModal.exportSchool")}
                  </Title16>
                </View>
              </View>
              <View style={styles.checkboxRow}>
                <Checkbox
                  onPress={handleHasClassroom}
                  checked={hasClassroom}
                  hasError={errors}
                />
                <View style={styles.checkboxText}>
                  <Title16>
                    {I18n.t("epointage.homepage.downloadModal.exportClassroom")}
                  </Title16>
                </View>
              </View>
              <View style={styles.checkboxRow}>
                <Checkbox
                  onPress={handleHasStudent}
                  checked={hasStudent}
                  hasError={errors}
                />
                <View style={styles.checkboxText}>
                  <Title16>
                    {I18n.t("epointage.homepage.downloadModal.exportStudent")}
                  </Title16>
                </View>
              </View>
            </View>
          </>
        )}
        <View style={styles.infoTextContainer}>
          <Title16 bold>
            {I18n.t("epointage.homepage.downloadModal.chooseInfoToExport")}
          </Title16>
        </View>
        <View style={styles.fieldsOrder}>
          <View>
            <View style={styles.inputsContainer}>
              <Pressable
                style={styles.startDateInputContainer}
                onPress={handleStartDate}
              >
                <Title16 style={styles.startDateInputText}>{startDateText}</Title16>
              </Pressable>
              <View style={styles.dateSeparator} />
              {showStartDate && (
                <View style={styles.datePickerContainer}>
                  <DatePicker date={startDate} setDate={handleSetStartDate} />
                </View>
              )}
              <Pressable
                style={styles.endDateInputContainer}
                onPress={handleEndDate}
              >
                <Title16 style={styles.endDateInputText}>{endDateText}</Title16>
              </Pressable>
              {showEndDate && (
                <View style={[styles.datePickerContainer, styles.endDatePicker]}>
                  <DatePicker date={endDate} setDate={handleSetEndDate} />
                </View>
              )}
            </View>
            <View style={styles.dropdownContainer}>
              <DropDownSelect
                key={serviceText}
                type={serviceText}
                selected={selectedService?.name}
                list={services}
                selectIdHandler={handleService}
                staysOpen
                hasErrors={errors}
              />
            </View>
          </View>
          <View style={[styles.dropdownContainer, styles.nextDropdown]}>
            <DropDownSelect
              key={schoolText}
              type={schoolText}
              selected={selected}
              list={schools}
              staysOpen
              handleCheckOption={handleCheckOption}
              allOptionsText={allSchoolsText}
              optionsChecked={checks}
              hasErrors={errors}
            />
          </View>
        </View>
        {errors && (
          <View style={styles.error}>
            <Warning />
            <Title13 style={styles.errorText}>
              {I18n.t("epointage.homepage.downloadModal.error")}
            </Title13>
          </View>
        )}
        <Title13 style={styles.infoText}>
          {I18n.t(`epointage.homepage.downloadModal.${description}`)}
        </Title13>
        <Pressable
          onPress={handleDownload}
          style={styles.button}
          disabled={loading}
        >
          <Title16 bold style={styles.buttonText}>
            {loading ? (
              <View style={styles.loading}>
                <ActivityIndicator
                  color={Colors.foodiBlack}
                  style={styles.activityIndicator}
                />
                {I18n.t("common.loading")}
              </View>
            ) : (
              I18n.t("epointage.homepage.downloadModal.download")
            )}
          </Title16>
        </Pressable>
      </View>
    );

    return downloaded ? (
      <View style={styles.successModal}>
        <CheckCircle size={22} />
        <Title16 bold style={styles.successText}>
          {I18n.t("epointage.homepage.downloadModal.successMessage")}
        </Title16>
      </View>
    ) : (
      <>
        <Pressable onPress={handleGoBack} style={styles.goBackContainer}>
          <Back />
          <Title18 isBold style={styles.textContainer}>
            {I18n.t("common.return")}
          </Title18>
        </Pressable>
        {form()}
      </>
    );
  }
);

const _styles = (
  startDate: Date | undefined,
  endDate: Date | undefined,
  hasErrors: boolean,
  errors: boolean,
  isExcel: boolean
) =>
  StyleSheet.create({
    goBackContainer: {
      flexDirection: "row",
      alignItems: "center",
      width: "max-content",
    },
    textContainer: {
      marginLeft: 7,
    },
    container: {
      marginTop: 30,
    },
    levelOfInfoContainer: {
      marginTop: 5,
      marginBottom: 20,
    },
    checkboxRow: {
      flexDirection: "row",
      marginBottom: 10,
    },
    checkboxText: {
      marginLeft: 10,
    },
    checkboxesContainer: {
      marginBottom: 30,
    },
    infoTextContainer: {
      marginBottom: 20,
    },
    startDateInputContainer: {
      boxSizing: "border-box",
      height: 34,
      width: 197,
      border: `2px solid ${
        startDate
          ? Colors.foodiDefault
          : errors
          ? Colors.redError
          : Colors.darkGrey
      }`,
      borderRadius: 8,
      paddingHorizontal: 20,
      paddingVertical: 5,
    },
    endDateInputContainer: {
      boxSizing: "border-box",
      height: 34,
      width: 197,
      border: `2px solid ${
        endDate
          ? Colors.foodiDefault
          : errors
          ? Colors.redError
          : Colors.darkGrey
      }`,
      borderRadius: 8,
      paddingHorizontal: 20,
      paddingVertical: 5,
    },
    startDateInputText: {
      color: startDate ? Colors.foodiBlack : Colors.gray1,
    },
    endDateInputText: {
      color: endDate ? Colors.foodiBlack : Colors.gray1,
    },
    inputsContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: 20,
    },
    datePickerContainer: {
      position: "absolute",
      top: 34,
    },
    endDatePicker: {
      right: 0,
    },
    dropdownContainer: {
      zIndex: isExcel ? -2 : -3,
      marginBottom: isExcel ? 20 : 0
    },
    nextDropdown: {
      zIndex: isExcel ? -3 : 2,
      marginBottom: isExcel ? 0 : 20,
    },
    error: {
      flexDirection: "row",
      justifyContent: "center",
      marginTop: 10,
      zIndex: -4,
    },
    errorText: {
      color: Colors.redError,
      marginLeft: 5,
    },
    infoText: {
      color: Colors.gray1,
      marginTop: 30,
      marginBottom: 10,
      width: 267,
      textAlign: "center",
      alignSelf: "center",
      zIndex: -5,
    },
    button: {
      width: "max-content",
      paddingHorizontal: 24,
      paddingVertical: 13,
      borderRadius: 8,
      backgroundColor: hasErrors
        ? Colors.disabledBackground
        : Colors.foodiDefault,
      alignSelf: "center",
      zIndex: -5,
      marginBottom: 10,
      cursor: hasErrors ? "auto" : "pointer",
    },
    buttonText: {
      color: hasErrors ? Colors.white : Colors.foodiBlack,
    },
    successModal: {
      width: 350,
      flexDirection: "row",
      justifyContent: "center",
      alignSelf: "center",
    },
    successText: {
      marginLeft: 10,
    },
    dateSeparator: {
      width: 20,
    },
    loading: {
      flexDirection: "row",
    },
    activityIndicator: {
      marginRight: 10,
    },
    fieldsOrder: {
      flexDirection: isExcel ? "column" : "column-reverse"
    }
  });
