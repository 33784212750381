import React from "react";
interface IProps {
  color?: string;
}

export const ArrowRight: React.FC<IProps> = ({ color }) => (
  <svg
    width="20px"
    height="18px"
    viewBox="0 0 20 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Icons/Navigation/Arrows_Right_Black</title>
    <g
      id="Icons/Navigation/Arrows_Right_Black"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <polygon
        id="Path"
        fill={color ? color : "#090909"}
        fill-rule="nonzero"
        points="13.2166664 0 11.7831665 1.77079409 16.4394997 7.53529399 -9.09494702e-13 7.53529399 -9.09494702e-13 10.0470587 16.4394997 10.0470587 11.7729998 15.8115586 13.2166664 17.5823527 20.333333 8.79117633"
      ></polygon>
    </g>
  </svg>
);
