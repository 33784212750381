import React, { useMemo } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { Title18, Title22, Title30 } from "@stylesheets";
import { StepStatus } from "@modules";
import { Colors } from "@constants";
import { Checked } from "@assets";
import { I18n } from "react-redux-i18n";
import { useDevices } from "@hooks";

interface IProps {
  stepStatus: StepStatus;
  stepNumber: number;
  title: string;
  onSelectStep?: (stepNumber: number) => void;
  forwardTestID?: string;
  isFinalStep?: boolean;
}

export const Step: React.FC<IProps> = React.memo(
  ({
    forwardTestID,
    title,
    stepNumber,
    onSelectStep,
    stepStatus,
    isFinalStep,
  }) => {
    const onPress = () => {
      onSelectStep?.(stepNumber);
    };

    const [isMobile] = useDevices();
    const styles = useMemo(() => _styles(stepStatus, isMobile), [
      stepStatus,
      isMobile,
    ]);

    const StepNumber: React.FC<any> = isMobile ? Title22 : Title30;
    const StepTitle: React.FC<any> = isMobile ? Title18 : Title22;

    return (
      <Pressable
        testID={forwardTestID}
        style={styles.container}
        onPress={onPress}
      >
        <View style={styles.circle}>
          {isFinalStep ? (
            <Checked height={30} width={30} />
          ) : (
            <StepNumber isBlack style={styles.stepNumber}>
              {stepNumber}
            </StepNumber>
          )}
        </View>
        <StepTitle isBlack style={styles.title}>
          {I18n.t(title)}
        </StepTitle>
      </Pressable>
    );
  }
);

const _styles = (stepStatus: StepStatus, isMobile: boolean) =>
  StyleSheet.create({
    container: {
      justifyContent: "center",
      alignItems: "center",
      width: isMobile ? "25%" : 155,
    },
    circle: {
      height: isMobile ? 40 : 50,
      width: isMobile ? 40 : 50,
      borderRadius: 25,
      borderWidth: 2,
      borderColor:
        stepStatus === StepStatus.TODO
          ? Colors.foodiBlack
          : Colors.foodiDefault,
      backgroundColor:
        stepStatus === StepStatus.IN_PROGRESS
          ? Colors.foodiDefault
          : Colors.white,
      justifyContent: "center",
      alignItems: "center",
    },
    title: {
      marginTop: 2,
      color: isMobile
        ? stepStatus === StepStatus.IN_PROGRESS
          ? Colors.foodiBlack
          : Colors.white
        : Colors.foodiBlack,
      fontFamily:
        stepStatus === StepStatus.IN_PROGRESS
          ? "manrope-bold"
          : "manrope-regular",
    },
    stepNumber: {
      ...(stepStatus === StepStatus.IN_PROGRESS && {
        fontFamily: "manrope-bold",
      }),
      ...(stepStatus !== StepStatus.IN_PROGRESS && { fontWeight: "normal" }),
    },
  });
