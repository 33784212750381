import React, { useMemo } from "react";
import { Pressable, StyleSheet, View, Text } from "react-native";
import { Title18, Title22, Title30 } from "@stylesheets";
import { Colors, Spacing } from "@constants";
import { I18n } from "react-redux-i18n";
import { Back, ProfileBlack } from "@assets";
import { useSelector } from "react-redux";
import { GlobalState } from "@redux";
import { UserProfile } from "@foodi/core";
import { TestIDs } from "@utils";
import { useDevices } from "@hooks";
import _ from "lodash";
import { HoldingViewList } from "@atomic";
import { NavigationProp } from "@react-navigation/native";

interface IProps {
  handleShowLeftContainer: () => void;
  navigation: NavigationProp<any>;
}

export const AccountScreen: React.FC<IProps> = React.memo(
  ({ handleShowLeftContainer, navigation }) => {
    const [isMobile] = useDevices();
    const styles = useMemo(() => _styles(isMobile), [isMobile]);

    const Title: React.FC<any> = isMobile ? Title22 : Title30;
    const Info: React.FC<any> = isMobile ? Title18 : Title22;

    const { firstName, lastName, email } =
      useSelector((state: GlobalState) => state.auth?.userInfo) ??
      ({} as UserProfile);

    return (
      <>
        <View
          testID={TestIDs.profile.views.myAccountView}
          style={styles.container}
        >
          {isMobile && (
            <Pressable
              style={styles.goBackContainer}
              testID={TestIDs.profile.actions.goBack}
              onPress={handleShowLeftContainer}
            >
              <Back />
              <Text style={styles.goBackText}>{I18n.t("common.return")}</Text>
            </Pressable>
          )}
          {isMobile && (
            <Title30 isBold style={styles.marginTitle}>
              {I18n.t("profile.myAccount")}
            </Title30>
          )}
          <Title isBlack isBold>
            {I18n.t("profile.myInfos")}
          </Title>
          <View style={styles.accountInfo}>
            <View style={styles.iconDiv}>
              <ProfileBlack size={65} />
            </View>
            <Title
              isBlack
              isNotBold
              style={styles.fullName}
            >{`${lastName} ${firstName}`}</Title>
          </View>

          <View style={styles.infoDiv}>
            <Info isBlack isBold>{`${I18n.t("profile.email")} : `}</Info>
            <Info isBlack isNotBold>{`${email}`}</Info>
          </View>
          <View style={styles.infoDiv}>
            <Info isBlack isBold>{`${I18n.t("profile.password")} : `}</Info>
            <Info isBlack isNotBold>
              **********
            </Info>
          </View>
        </View>
        {isMobile && <View style={styles.separator} />}
        <HoldingViewList navigation={navigation} />
      </>
    );
  }
);

const _styles = (isMobile: boolean) =>
  StyleSheet.create({
    container: {
      flex: 1,
      width: isMobile ? "90vw" : "100%",
      borderWidth: isMobile ? 0 : 1,
      borderRadius: 8,
      borderColor: Colors.darkGrey,
      justifyContent: "flex-start",
      alignItems: "flex-start",
      paddingHorizontal: isMobile ? 0 : 40,
      paddingVertical: isMobile ? 0 : 20,
      marginBottom: isMobile ? 0 : 20,
    },
    fullName: {
      fontWeight: isMobile ? "normal" : "200",
      marginTop: Spacing.S,
    },
    accountInfo: {
      marginTop: Spacing.M,
      marginBottom: Spacing.L,
      justifyContent: "center",
      alignItems: "center",
    },
    iconDiv: {
      height: 120,
      width: 120,
      borderRadius: 60,
      borderWidth: 1,
      borderColor: Colors.foodiBlack,
      justifyContent: "center",
      alignItems: "center",
    },
    infoDiv: {
      flexDirection: "row",
      marginBottom: Spacing.M,
      alignItems: "center",
      justifyContent: "flex-start",
      flexWrap: "wrap",
    },
    marginTitle: {
      marginBottom: 20,
    },
    goBackContainer: {
      flexDirection: "row",
      alignItems: "center",
      maxWidth: 250,
      marginBottom: 10,
    },
    goBackText: {
      color: Colors.foodiBlack,
      fontFamily: "manrope-bold",
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: 30,
      marginLeft: 7,
    },
    separator: {
      width: "100%",
      height: 1,
      backgroundColor: Colors.darkGrey,
      marginBottom: 30,
      marginTop: 15,
    },
  });
