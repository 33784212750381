import React, { useEffect, useMemo, useRef, useState } from "react";
import { StyleSheet } from "react-native";
import { RestaurantCardsList, TransactionTabs } from "@atomic";
import { INode, IPos } from "@foodi/core";
import {
  BookingActions,
  OffersActions,
  PointOfSaleActions,
  RestaurantCardViewModel,
} from "@modules";
import { Container, InfoContainer, Text } from "./RestaurantCardFilters.style";
import { Information } from "@assets";
import { NavigationProp } from "@react-navigation/native";
import { TestIDs } from "@utils";
import { useDevices } from "@hooks";
import { Spacing } from "@constants";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "@redux";
import { PosAffluence } from "@foodi/core/lib/domain/entities/PosAffluence";

interface IProps {
  restaurantCardsList?: INode<IPos>[];
  navigation?: NavigationProp<any>;
  affluence: PosAffluence[];
}

export const RestaurantCardFilters: React.FC<IProps> = React.memo(
  ({ restaurantCardsList, navigation, affluence }) => {
    const dispatch = useDispatch();
    const [currentTab, setCurrentTab] = useState(0);
    const [isMobile] = useDevices();

    const styles = useMemo(() => _styles(isMobile), [isMobile]);

    const restaurantCardVM = new RestaurantCardViewModel();

    const initialTabs = restaurantCardVM.getInitTabs();

    const [tabs, setTabs] = useState(initialTabs);

    const prevSelectedTab = useRef(-1);

    const getCardsSorted = restaurantCardVM.getCardsFiltered(restaurantCardsList);

    const [cards, setCards] = useState<INode<IPos>[] | undefined>(getCardsSorted);

    const { holdingName } =
      useSelector((state: GlobalState) => state?.auth?.userInfo) ?? {};
    const hideMenuBooking = useSelector(
      (state: GlobalState) =>
        state.pointOfSale?.selectedPos?.pointOfSale.hideMenuBooking
    );

    /* istanbul ignore next */
    const onTabSelected = (tabIndex: number) => {
      if (prevSelectedTab.current === tabIndex) return;
      prevSelectedTab.current = tabIndex;
      setCurrentTab(tabIndex);
      setCards(restaurantCardVM.getRestaurantCards(tabIndex, getCardsSorted));
      setTabs(restaurantCardVM.getTabs(tabIndex));
      if (tabIndex === 2) {
        dispatch(BookingActions.setBookingSelected(false));
        dispatch(PointOfSaleActions.setMiniBookingCartStatus(false));
      } else {
        dispatch(BookingActions.setBookingSelected(true));
        dispatch(PointOfSaleActions.setMiniCartStatus(false));
        dispatch(OffersActions.initOrderItems());
      }
    };

    const message = restaurantCardVM.getInfoMessage(prevSelectedTab.current);

    return !!holdingName ? (
      <Container
        testID={
          TestIDs.home.restaurantCard.views.restaurantCardFiltersContainer
        }
      >
        <TransactionTabs
          onTabSelected={onTabSelected}
          tabs={tabs}
          style={styles.transactionTabs}
          styleContainer={styles.tabMargin}
        />
        {cards?.length ? (
          <RestaurantCardsList
            restaurantCardsList={cards}
            navigation={navigation}
            currentTab={currentTab}
            affluence={affluence}
          />
        ) : (
          <InfoContainer style={styles.oneline}>
            <Information />
            <Text style={styles.fontResponsive}>{message}</Text>
          </InfoContainer>
        )}
      </Container>
    ) : null;
  }
);

const _styles = (isMobile: boolean) =>
  StyleSheet.create({
    transactionTabs: {
      width: isMobile ? "100%" : 652,
      marginBottom: isMobile ? 32 : 50,
      paddingHorizontal: isMobile ? 0 : "auto",
    },
    tabMargin: {
      paddingRight: Spacing.L,
    },
    oneline: {
      width: "auto",
    },
    fontResponsive: {
      fontSize: isMobile ? 11 : 16,
    },
  });
