import { IExternalService } from "@foodi/core";

export class PosViewModel {
  getActiveExternalServices = (
    externalServicesList: IExternalService[]
  ): Array<{}> => {
    const activeServices = externalServicesList?.filter(
      (service: IExternalService) =>
        service.active && !!service.content && !!service.title
    );
    return activeServices;
  };
}
