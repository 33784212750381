"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_EPOINTAGE_SCHEDULER = void 0;
const client_1 = require("@apollo/client");
exports.GET_EPOINTAGE_SCHEDULER = client_1.gql `
query getEPointageScheduler($siteId: String! $date: String! $etaId: String!) {
    scheduler: getEPointageScheduler(siteId: $siteId date: $date etaId: $etaId) {
        user {
            userId
            userRole
            holdingId
            name
            __typename @skip(if: true)
        }
        schedules {
            id
            isInvoice
            deadline
            order
            serviceId
            name
            __typename @skip(if: true)
            students {
                id
                familyId
                name
                presence
                supposedPresence
                updateDate
                classroomId
                schoolId
                __typename @skip(if: true)
            }
        }
        school {
            isOpenToday
            schoolId
            __typename @skip(if: true)
            classrooms {
                id
                name
                comments {
                    scheduleId
                    date
                    updateUser
                    updateDate
                    comment
                }
                __typename @skip(if: true)
            }
        }
    }
}
`;
