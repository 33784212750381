import { View, StyleSheet, Dimensions } from "react-native";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Carousel, Offer } from "@atomic";
import {
  BookingActions,
  IOrderableOffer,
  OffersActions,
  OfferViewModel,
  PointOfSaleActions,
} from "@modules";
import { useDispatch, useSelector } from "react-redux";
import { Route } from "@react-navigation/native";
import _ from "lodash";
import * as Sentry from "@sentry/react";
import { GlobalState } from "@redux";
import { ActiveOffer, IOfferTemplate } from "@foodi/core";
import { JEST, TestIDs, getNumericId } from "@utils";
import { OffersNoData } from "./noData";
import { Colors } from "@constants";

type IOffer = IOfferTemplate & {
  isSelected: boolean;
  published: boolean;
  orderStartDefault?: string;
  orderEndDefault?: string;
};

interface IProps {
  isRefillFromCart?: boolean;
  offers: IOfferTemplate[];
  setIsOfferFetching: React.Dispatch<React.SetStateAction<boolean>>;
  offerTemplatesError?: string;
  refreshDelay?: number;
  route?: Route<any>;
}

export const Offers: React.FC<IProps> =
  ({ isRefillFromCart, offers:_offers, setIsOfferFetching, offerTemplatesError, refreshDelay, route }) => {
    const currentPosIdFromParams = _.get(route, "params.id");
    const offers = useRef((() => {
        let mismatchPosId = '';
        return _offers?.filter(
            (item) => {
                if (!mismatchPosId && item.pos?.id && getNumericId(item.pos.id) !== currentPosIdFromParams) {
                    mismatchPosId = item.pos.id;
                    Sentry.captureMessage('Detected a POS mismatch between the offers to display and the POS in URL', {
                        level: 'error',
                        tags: {
                            WRONG_POS_OFFERS_BUG: true
                        },
                        extra: {
                            posIdFromURL: currentPosIdFromParams,
                            mismatchPosId: getNumericId(mismatchPosId),
                            component: 'atomic/organisms/Offers/index.ts'
                        }
                    });
                }
                return getNumericId(item.pos?.id) === currentPosIdFromParams;
            }
        );
    })());
    const prevSelectedOffer = useRef<IOfferTemplate | undefined>();
    const dispatch = useDispatch();
    const userInfo = useSelector((state: GlobalState) => state.auth?.userInfo);
    const selectedOffer = useSelector((state: GlobalState) => {
      const currentOffer = state.offers?.selectedOffer;
      if (currentOffer && getNumericId(currentOffer.pos.id) === currentPosIdFromParams) {
          return currentOffer;
      }
      return undefined;
    });
    const [offerVM] = useState(new OfferViewModel(dispatch));
    
    const [maxOffersDisplay, setMaxOffersDisplay] = useState(
      offerVM.MIN_OFFERCARD_DISPLAY
    );

    const hideMenuBooking = useSelector(
      (state: GlobalState) =>
        state.pointOfSale?.selectedPos?.pointOfSale.hideMenuBooking
    );
    const isBookingSelected = useSelector(
      (state: GlobalState) => state?.booking?.isBookingSelected
    );

    const showCarousel = !!selectedOffer || !!offers.current[0];
    const styles = useMemo(() => _styles(showCarousel, !!hideMenuBooking), [
      showCarousel,
      hideMenuBooking,
    ]);

    const resetOffers = async (offer: IOffer | IOfferTemplate) => {
      JEST || setIsOfferFetching(true);
      dispatch(BookingActions.setSelectedDay(0));
      const activeOffer: ActiveOffer | null =
        offer?.daysInAdvanceStart === 0
          ? await offerVM.getOffer(offer?.nextOrderableOffers?.[0]?.id || "")
          : null;
      dispatch(
        OffersActions.setNextOrderableOffers(
          (offer?.nextOrderableOffers as IOrderableOffer[]) ?? []
        )
      );
      JEST ||
        dispatch(OffersActions.setActiveOrderableOffer(activeOffer || null));
      setIsOfferFetching(false);
    };

    const checkMaxOffersToDisplay = () => {
      let numberOffersToDisplay =
        (Math.round(
          Dimensions.get("window").width / offerVM.OFFER_CARD_WIDTH_WITHGAP
        ) -
          1) *
        offerVM.OFFER_CARD_WIDTH_WITHGAP;

      if (numberOffersToDisplay < offerVM.MIN_OFFERCARD_DISPLAY)
        numberOffersToDisplay = offerVM.MIN_OFFERCARD_DISPLAY;
      setMaxOffersDisplay(numberOffersToDisplay);
    };

    useEffect(() => {
      if (isBookingSelected && hideMenuBooking)
        dispatch(BookingActions.setBookingSelected(false));
      if ((!offers.current && !offers.current?.[0]) || isRefillFromCart) return;
      const firstOffer =
        isRefillFromCart && selectedOffer ? selectedOffer : offers.current[0];
      dispatch(OffersActions.setSelectedOffer(firstOffer));
      resetOffers(firstOffer);
      prevSelectedOffer.current = firstOffer;
    }, [offers]);

    useEffect(() => {
      // To handle the cases that the user came from homepage from 'À emporter' and dont
      // exists any offer. In this case booking its selected.
      if (!(!!selectedOffer || !!offers.current[0]) && !isBookingSelected)
        dispatch(BookingActions.setBookingSelected(true));
    }, [isBookingSelected]);

    useEffect(() => {
      checkMaxOffersToDisplay();
      try {
        window.addEventListener("resize", checkMaxOffersToDisplay);
        checkMaxOffersToDisplay();
      } catch (e) {}

      return () => {
        window.addEventListener("resize", checkMaxOffersToDisplay);
      };
    }, []);

    const onSelectOffer = async (_selectedOffer: IOffer) => {
      dispatch(BookingActions.setBookingSelected(false));
      dispatch(PointOfSaleActions.setMiniBookingCartStatus(false));
      await resetOffers(_selectedOffer);
      dispatch(OffersActions.resetOutOfStockOrderItems());
      dispatch(OffersActions.resetOutOfStockOrderItemsFormula());
      if (
        prevSelectedOffer.current?.nextOrderableOffers?.[0]?.id ===
          _selectedOffer.nextOrderableOffers?.[0]?.id &&
        prevSelectedOffer.current?.name === _selectedOffer.name
      )
        return;
      dispatch(OffersActions.setSelectedOffer(_selectedOffer));
      dispatch(OffersActions.initOrderItems());
      prevSelectedOffer.current = _selectedOffer;
    };

    const renderItems = (
        _offers: IOffer[],
        onSelectedItem: (selectedElementIndex: number) => void
    ) => {
        return _offers?.filter(offer => offer.published && getNumericId(offer.pos?.id) === currentPosIdFromParams).map((offer: IOffer, index: number) => (
            <Offer
                forwardTestID={`${TestIDs.restaurantDetail.actions.selectOffer}${offer.name}`}
                key={offer.id}
                index={index}
                title={offer.name}
                type={offer.withdrawalType || ""}
                image={offer.image.path}
                isSelected={!isBookingSelected && offer.isSelected}
                onSelected={onSelectedItem}
                orderStartDefault={offer.orderStartDefault}
                orderEndDefault={offer.orderEndDefault}
                availableToday={offerVM.isOfferAvailable(offer)}
                orderRange={offer.nextOrderableOffer?.orderRange}
            />
        ));
    };
    if (offerTemplatesError) return <OffersNoData offerTemplatesError={offerTemplatesError} refreshDelay={refreshDelay}/>
    return (
      <View style={styles.offerDiv}>
        {showCarousel && (
          <Carousel
            items={offers.current}
            itemWidth={offerVM.OFFER_CARD_WIDTH_WITHGAP}
            onSelectItem={onSelectOffer}
            renderItems={renderItems}
            width={maxOffersDisplay}
            height={offerVM.OFFER_CARD_HEIGHT}
            showRestaurantMenu={!hideMenuBooking}
            offersDisplayed={
              maxOffersDisplay / offerVM.OFFER_CARD_WIDTH_WITHGAP
            }
            biggerArrows
          />
        )}
      </View>
    );
};

const _styles = (showCarousel: boolean, hideMenuBooking: boolean) =>
  StyleSheet.create({
    offerDiv: {
      flexDirection: "row",
      minHeight: !showCarousel && hideMenuBooking ? 140 : 0,
      paddingBottom: 30,
      backgroundColor: Colors.white,
      paddingHorizontal: 150,
      marginHorizontal: -150,
    },
  });
