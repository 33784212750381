import React, { useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { HistoricRow } from "@atomic";
import { Colors, Spacing } from "@constants";
import { Title18, Title22 } from "@stylesheets";
import moment from "moment";
import _ from "lodash";
import { TestIDs } from "@utils";
import { useDevices } from "@hooks";
import { NavigationProp } from "@react-navigation/native";
import { IHistoric } from "@modules";

interface IProps {
  navigation: NavigationProp<any>;
  groupedHistoric: Record<string, IHistoric[]>;
  currentTab?: number;
  callback?: (params: any) => void;
  pdfFileName?: string | undefined;
  isClickable: boolean;
}
export const HistoricalList: React.FC<IProps> = React.memo(
  ({ navigation, groupedHistoric, currentTab, callback, isClickable }) => {
    const groupedHistArray = Object.entries(groupedHistoric);
    const [isMobile] = useDevices();
    const styles = useMemo(() => _styles(isMobile), [isMobile]);

    const DateText: React.FC<any> = isMobile ? Title18 : Title22;

    const renderHistoricRow = (
      historic: IHistoric,
      rowIndex: number,
      groupIndex: number
    ) => {
      const {
        amount,
        date,
        id,
        paymentType,
        invoiceNumber,
        pdfFileName
      } = historic;

      return (
        <HistoricRow
          forwardTestID={`${TestIDs.home.balance.views.historicRow}${rowIndex}_${groupIndex}`}
          key={`${rowIndex}_${groupIndex}`}
          id={id}
          total={amount}
          historicDate={date}
          title={currentTab === 1 ? paymentType : invoiceNumber}
          currentTab={currentTab}
          navigation={navigation}
          callback={callback}
          pdfFileName={pdfFileName}
          isClickable={isClickable}
        />
      );
    };

    return (
      <>
        {groupedHistArray.map((groupHistoric, groupIndex) => {
          const [histDate, historical] = groupHistoric;
          const [month, year] = histDate.split("/");
          const _date = new Date(parseInt(year), parseInt(month));

          return (
            <View
              testID={`${TestIDs.home.balance.views.historicGroup}${groupIndex}`}
              style={styles.group}
              key={groupIndex}
            >
              <DateText style={styles.date}>
                {_.upperFirst(moment(_date).format("MMMM YYYY"))}
              </DateText>
              <View>
                {historical.map((historic, rowIndex) =>
                  renderHistoricRow(historic, rowIndex, groupIndex)
                )}
              </View>
            </View>
          );
        })}
      </>
    );
  }
);

const _styles = (isMobile: boolean) =>
  StyleSheet.create({
    group: {
      width: isMobile ? "100%" : 500,
      marginTop: Spacing.XL,
      borderWidth: isMobile ? 0 : 1,
      borderColor: Colors.border1,
      padding: isMobile ? 0 : Spacing.L,
      paddingHorizontal: isMobile ? Spacing.M : Spacing.L,
      borderRadius: isMobile ? 0 : 8,
    },
    date: {
      color: Colors.foodiBlack,
      textAlign: "left",
      fontFamily: "manrope-bold",
    },
  });
