"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InMemoryOrderRepository = void 0;
const __1 = require("..");
const domain_1 = require("../../../domain");
const cartAdmissionAndPriceFixtures_1 = require("../fixtures/cartAdmissionAndPriceFixtures");
class InMemoryOrderRepository {
    constructor(hasFixture) {
        this.hasFixture = hasFixture;
    }
    /* istanbul ignore next */
    getAllOrders(request) {
        throw new Error("Method not implemented.");
    }
    /* istanbul ignore next */
    getOrders(request) {
        throw new Error("Method not implemented.");
    }
    /* istanbul ignore next */
    getOrder(request) {
        throw new Error("Method not implemented.");
    }
    /* istanbul ignore next */
    getBookingOrders(request) {
        throw new Error("Method not implemented.");
    }
    upsertOrder(request) {
        if (this.hasFixture)
            return Promise.resolve(__1.upsertOrderFixtures);
        return Promise.reject(new domain_1.OrderRepositoryErrors.UpsertOrderRequestError("UPSERT_ORDER_REQUEST_FAILED"));
    }
    /* istanbul ignore next */
    confirmOrder(request) {
        throw new Error("Method not implemented.");
    }
    /* istanbul ignore next */
    cancelOrder(request) {
        throw new Error("Method not implemented.");
    }
    /* istanbul ignore next */
    cartSummaryAdmissionAndPrice(request) {
        if (this.hasFixture)
            return Promise.resolve(cartAdmissionAndPriceFixtures_1.cartAdmissionAndPriceFixtures);
        return Promise.reject(new domain_1.OrderRepositoryErrors.CartSummaryAdmissionAndPriceRequestError("CART_SUMMARY_ADMISSION_AND_PRICE_REQUEST_FAILED"));
    }
    /* istanbul ignore next */
    getOrderCC(request) {
        throw new Error("Method not implemented.");
    }
    /* istanbul ignore next */
    getBooking(request) {
        throw new Error("Method not implemented.");
    }
    /* istanbul ignore next */
    createBookingOrder(request) {
        throw new Error("Method not implemented.");
    }
    /* istanbul ignore next */
    updateBookingOrderSlot(request) {
        throw new Error("Method not implemented.");
    }
    /* istanbul ignore next */
    cancelBookingOrder(request) {
        throw new Error("Method not implemented.");
    }
    /* istanbul ignore next */
    getOrderReceipt(request) {
        throw new Error("Method not implemented.");
    }
    addOrderRating(request) {
        throw new Error("Method not implemented.");
    }
    discardOrderRatingNotification(request) {
        throw new Error("Method not implemented.");
    }
    recentOrdersToRate() {
        throw new Error("Method not implemented.");
    }
}
exports.InMemoryOrderRepository = InMemoryOrderRepository;
