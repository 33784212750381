"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatAmount = void 0;
exports.formatAmount = (amount, currencySymbol) => {
    const stringifiedNumber = amount ? amount.toFixed(2) : '0';
    return stringifiedNumber
        .replace('.', ',')
        .concat(currencySymbol)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};
