import { Colors } from "@constants";
import { responsiveSizeHeight, responsiveSizeWidth } from "@hooks";
import styled from "styled-components/native";

export const SeeAction = styled.TouchableOpacity`
  border: 1px solid ${Colors.foodiBlack};
  border-radius: 8px;
  align-self: center;
  justify-content: center;
`;

export const Container = styled.View`
  width: 100vw;
  height: 70px;
  background-color: ${Colors.white};
  border: 1px solid ${Colors.border1};
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  padding-left: ${responsiveSizeWidth(10)}px;
  padding-right: ${responsiveSizeWidth(10)}px;
  padding-top: ${responsiveSizeHeight(10)}px;
  padding-bottom: ${responsiveSizeHeight(10)}px;
  flex-direction: row;
  justify-content: space-evenly;
`;

export const LeftContainer = styled.View`
  justify-content: center;
  margin-right: ${responsiveSizeWidth(15)}px;
`;

export const MiddleContainer = styled.View`
  flex-direction: row;
  align-items: center;
  width: ${responsiveSizeWidth(210)}px;
`;

export const TextContainer = styled.View`
  flex-direction: column;
`;

export const RightContainer = styled.View`
  justify-content: center;
  align-content: center;
`;

export const ButtonText = styled.Text`
  font-size: ${responsiveSizeWidth(22)}px;
  color: ${Colors.foodiBlack};
  font-family: manrope-bold;
  line-height: ${responsiveSizeWidth(30)}px;
  text-align: center;
  padding-left: ${responsiveSizeWidth(54)}px;
  padding-right: ${responsiveSizeWidth(54)}px;
  padding-top: ${responsiveSizeHeight(5)}px;
  padding-bottom: ${responsiveSizeHeight(5)}px;
`;

export const Title = styled.Text`
  font-family: manrope-bold;
  font-size: ${responsiveSizeWidth(13)}px;
  line-height: ${responsiveSizeWidth(18)}px;
`;
export const SubTitle = styled.Text`
  font-family: manrope;
  font-size: ${responsiveSizeWidth(13)}px;
  line-height: ${responsiveSizeWidth(18)}px;
`;
