import { Text, View, StyleSheet } from "react-native";
import _ from "lodash";
import React, { useMemo } from "react";
import { useDevices } from "@hooks";
import { Images } from "@assets";
import { I18n } from "react-redux-i18n";
import { Colors } from "@constants";
import { BrandThemeActions, persistedStore, store } from "@redux";
import { getApolloClient } from "../../../../apollo";
import { AuthActions } from "@modules/auth";
import { AccountActions } from "@modules/account";
import { BookingActions, OffersActions } from "@modules/pointsOfSale";
import { PaymentsActions } from "@modules/payments";

export const MaintenancePage: React.FC = React.memo(() => {
  const [isMobile] = useDevices();

  const styles = useMemo(() => _styles(isMobile), [isMobile]);

  (async () => {
    await persistedStore.purge();

    await getApolloClient().cache.reset();

    store.dispatch(AuthActions.logOutUser());
    store.dispatch(AccountActions.setUserBalance(null));
    store.dispatch(BookingActions.initBookingOrders());
    store.dispatch(OffersActions.initOrderItems());
    store.dispatch(BrandThemeActions.resetBrandTheme());
    store.dispatch(PaymentsActions.resetPayment());

    localStorage.removeItem("persist:root");
    localStorage.clear();

    document.cookie =
      "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

    await persistedStore.flush();
  })();

  return (
    <View style={styles.mainContainer}>
      <View style={styles.textContainer}>
        <Text style={styles.title}>{I18n.t("maintenance.title")}</Text>
        {isMobile ? (
          <Text style={styles.message}>
            {I18n.t("maintenance.mainMessage") +
              " " +
              I18n.t("maintenance.backSoon")}
          </Text>
        ) : (
          <>
            <Text style={styles.message}>
              {I18n.t("maintenance.mainMessage")}
            </Text>
            <Text style={styles.message}>{I18n.t("maintenance.backSoon")}</Text>
          </>
        )}
      </View>
      <View style={styles.imageContainer}>
        {isMobile ? (
          <Images.MaintenanceWebMobile />
        ) : (
          <Images.MaintenanceDefault />
        )}
      </View>
    </View>
  );
});

const _styles = (isMobile: boolean) =>
  StyleSheet.create({
    mainContainer: {
      flex: 1,
      backgroundColor: "white",
      paddingHorizontal: isMobile ? 20 : 150,
      paddingVertical: isMobile ? 150 : 80,
    },
    textContainer: {
      alignItems: "flex-start",
    },
    imageContainer: {
      alignItems: "center",
    },
    title: {
      fontSize: isMobile ? 22 : 30,
      color: Colors.foodiBlack,
      fontFamily: "manrope-bold",
      lineHeight: isMobile ? 24 : 60,
      marginBottom: 10,
    },
    message: {
      fontSize: isMobile ? 16 : 22,
      color: Colors.foodiBlack,
      fontFamily: "manrope",
      lineHeight: isMobile ? 17 : 24,
      marginBottom: isMobile ? 24 : 5,
    },
  });
