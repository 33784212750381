"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ADD_USER_HOLDING_VIEW = void 0;
const client_1 = require("@apollo/client");
exports.ADD_USER_HOLDING_VIEW = client_1.gql `
  mutation addUserHoldingView($input: InputAddUserHoldingView!) {
    addUserHoldingView(input: $input) {
      id
    }
  }
`;
