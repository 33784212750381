import { AccordeonArrow, Edenred, WarningOrange } from "@assets";
import { RadioGroup } from "@atomic/molecules";
import { IPaymentTypes } from "@foodi/core";
import { GlobalState } from "@redux/rootReducer";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import { I18n } from "react-redux-i18n";
import {
  AccordeonArrowStyle,
  DropdownRow,
  FlexDirectionRow,
  IconContainer,
  PaymentContainer,
  PaymentTextContainer,
  ProductDescription,
  SmallDescription,
  WarningMessage,
  WarningMessageContainer,
} from "./Cart.style";
import { OnSiteMethodPayment } from "./OnSiteMethodPayment";

interface IOtherCards {
  edenredBalance: string | undefined;
  handleEdenredPayment: (id: string) => Promise<void>;
  paymentMethod: IPaymentTypes;
  isOtherCardOpen: boolean;
  setIsOtherCardOpen: React.Dispatch<React.SetStateAction<boolean>>;
  paymentTypes: IPaymentTypes[];
  setPaymentMethod: React.Dispatch<React.SetStateAction<string | undefined>>;
  handleTriggerQuotationAndPaymentMethod: (
    newPaymentMethod: string
  ) => Promise<void>;
}

export const OtherCards: React.FC<IOtherCards> = memo(
  ({
    edenredBalance,
    handleEdenredPayment,
    paymentMethod,
    setIsOtherCardOpen,
    isOtherCardOpen,
    paymentTypes,
    setPaymentMethod,
    handleTriggerQuotationAndPaymentMethod,
  }) => {
    const { isSchool } = useSelector(
      (state: GlobalState) => state.auth?.userInfo || {}
    );

    const warningInfoMessages = {
      [IPaymentTypes.OnSite]: isSchool
        ? I18n.t("restaurantDetail.cart.payOnSiteSchoolInfo")
        : I18n.t("restaurantDetail.cart.payOnSiteInfo"),
      [IPaymentTypes.Edenred]: I18n.t("restaurantDetail.cart.noSubventions"),
      [IPaymentTypes.Badge]: undefined,
      [IPaymentTypes.CreditCard]: undefined,
    };

    const warningMsg: string | undefined = warningInfoMessages[paymentMethod];

    return (
      <>
        <DropdownRow onPress={() => setIsOtherCardOpen((state) => !state)}>
          <ProductDescription>
            {I18n.t("restaurantDetail.cart.otherCards")}
          </ProductDescription>
          <AccordeonArrowStyle rotate={isOtherCardOpen}>
            <AccordeonArrow />
          </AccordeonArrowStyle>
        </DropdownRow>
        {isOtherCardOpen && (
          <>
            {paymentTypes.includes(IPaymentTypes.Edenred) && (
              <>
                <PaymentContainer>
                  <FlexDirectionRow>
                    <IconContainer>
                      <Edenred />
                    </IconContainer>
                    <PaymentTextContainer>
                      <ProductDescription>
                        {I18n.t("restaurantDetail.cart.edenred")}
                      </ProductDescription>
                      <FlexDirectionRow>
                        <SmallDescription>
                          {I18n.t("restaurantDetail.cart.pay")}
                        </SmallDescription>
                        <SmallDescription hasMargin bold>
                          {edenredBalance ? `${edenredBalance}€` : "..."}
                        </SmallDescription>
                      </FlexDirectionRow>
                    </PaymentTextContainer>
                  </FlexDirectionRow>
                  <RadioGroup
                    radioButtonsProps={[{ id: IPaymentTypes.Edenred }]}
                    value={paymentMethod}
                    onPress={handleEdenredPayment}
                  />
                </PaymentContainer>
              </>
            )}
            {paymentTypes.includes(IPaymentTypes.OnSite) && (
              <OnSiteMethodPayment
                paymentMethod={paymentMethod}
                setPaymentMethod={setPaymentMethod}
                handleTriggerQuotationAndPaymentMethod={
                  handleTriggerQuotationAndPaymentMethod
                }
              />
            )}
            {warningMsg && (
              <WarningMessageContainer>
                <WarningOrange />
                <WarningMessage>{warningMsg}</WarningMessage>
              </WarningMessageContainer>
            )}
          </>
        )}
      </>
    );
  }
);
