export enum HomeScreens {
  HOME_SCREEN = "HomePage",
  BALANCE_SCREEN = "Balance",
  POINT_OF_SALE_STACK = "POS",
  PROFILE_STACK = "Profile",
  ARTICLE_SCREEN = "Article",
  ACCOUNT_STACK = "Account",
  MAINTENANCE_SCREEN = "Maintenance",
  IDPCallback = "IDPCallback",
}
