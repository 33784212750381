import { Back, VisibilityBlack } from "@assets";
import { Colors } from "@constants";
import { Title18, Title22, Title30 } from "@stylesheets";
import { TestIDs } from "@utils";
import React, { useMemo } from "react";
import { View, StyleSheet, Pressable, Text } from "react-native";
import { I18n } from "react-redux-i18n";
import { useDevices } from "@hooks";

interface IProps {
  handleShowLeftContainer: () => void;
}

export const LegalInfoScreen: React.FC<IProps> = React.memo(
  ({ handleShowLeftContainer }) => {
    const [isMobile] = useDevices();
    const styles = useMemo(() => _styles(isMobile), [isMobile]);

    const Info: React.FC<any> = isMobile ? Title18 : Title22;

    const handleCGV = () => {
      window.open(window.config.CGV, "_blank")?.focus();
    };

    const handleCGU = () => {
      window.open(window.config.CGU, "_blank")?.focus();
    };

    const handlePUD = () => {
      window.open(window.config.PUD, "_blank")?.focus();
    };

    const handleCookiesPolicy = () => {
      window.open(window.config.COOKIES_POLICIES, "_blank")?.focus();
    };

    return (
      <View
        testID={TestIDs.profile.views.legalInformationTab}
        style={styles.container}
      >
        {isMobile && (
          <Pressable
            style={styles.goBackContainer}
            testID={TestIDs.profile.actions.goBack}
            onPress={handleShowLeftContainer}
          >
            <Back />
            <Text style={styles.goBackText}>{I18n.t("common.return")}</Text>
          </Pressable>
        )}
        {isMobile && (
          <Title30 isBold style={styles.marginTitle}>
            {I18n.t("profile.legalInformation")}
          </Title30>
        )}
        <View style={styles.row}>
          <Info
            testID={TestIDs.profile.actions.actionCGV}
            onPress={handleCGV}
            isBlack
            isBold
          >
            {I18n.t("profile.termsOfSale")}       
          <View style={styles.iconContainer}>
            <VisibilityBlack />
          </View>
        </Info>
        </View>
        <View style={styles.separator} />
        <View style={styles.row}>
          <Info
            testID={TestIDs.profile.actions.actionCGU}
            onPress={handleCGU}
            isBlack
            isBold
          >
            {I18n.t("profile.termsOfService")}          
          <View style={styles.iconContainer}>
            <VisibilityBlack />
          </View>
          </Info>
        </View>
        <View style={styles.separator} />
        <View style={styles.row}>
          <Info
            testID={TestIDs.profile.actions.actionPUD}
            onPress={handlePUD}
            isBlack
            isBold
          >
            {I18n.t("profile.useOfPersonalData")}
          <View style={styles.iconContainer}>
            <VisibilityBlack />
          </View>
          </Info>
        </View>
        <View style={styles.separator} />
        <View style={styles.row}>
          <Info
            testID={TestIDs.profile.actions.actionCookiesPolicy}
            onPress={handleCookiesPolicy}
            isBlack
            isBold
          >
            {I18n.t("profile.cookiesPlicy")}
          <View style={styles.iconContainer}>
            <VisibilityBlack />
          </View>
          </Info>
        </View>
        {isMobile && <View style={styles.separator} />}
      </View>
    );
  }
);

const _styles = (isMobile: boolean) =>
  StyleSheet.create({
    container: {
      flex: 1,
      width: "100%",
      borderWidth: isMobile ? 0 : 1,
      borderRadius: 8,
      borderColor: Colors.darkGrey,
      justifyContent: "flex-start",
      alignItems: "flex-start",
      paddingHorizontal: isMobile ? 0 : 40,
      paddingVertical: isMobile ? 0 : 30,
    },
    row: {
      flexDirection: "row",
      alignItems: "center",
    },
    iconContainer: {
      marginLeft: 10,
      top: 3,
    },
    separator: {
      width: "100%",
      height: 1,
      backgroundColor: Colors.border1,
      marginVertical: 30,
    },
    goBackContainer: {
      flexDirection: "row",
      alignItems: "center",
      maxWidth: 250,
      marginBottom: 10,
    },
    goBackText: {
      color: Colors.foodiBlack,
      fontFamily: "manrope-bold",
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: 30,
      marginLeft: 7,
    },
    marginTitle: {
      marginBottom: 20,
    },
  });
