import React from "react";
import { I18n } from "react-redux-i18n";

export const SeeAll: React.FC = () => (
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>{I18n.t("refill.seeAll")}</title>
    <g
      id="Design-System"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="Icons-pg1" transform="translate(-68.000000, -410.000000)">
        <g id="Group" transform="translate(66.000000, 408.000000)">
          <path
            d="M13.5555556,5.2 L13.5555556,11.6 L16.2222222,11.6 L16.2222222,18 L18,18 L18,2 C15.5466667,2 13.5555556,3.792 13.5555556,5.2 Z M9.11111111,7.6 L7.33333333,7.6 L7.33333333,2 L5.55555556,2 L5.55555556,7.6 L3.77777778,7.6 L3.77777778,2 L2,2 L2,7.6 C2,9.368 3.59111111,10.8 5.55555556,10.8 L5.55555556,18 L7.33333333,18 L7.33333333,10.8 C9.29777778,10.8 10.8888889,9.368 10.8888889,7.6 L10.8888889,2 L9.11111111,2 L9.11111111,7.6 Z"
            id="Tout-Voir"
            fill="#000000"
            fill-rule="nonzero"
          ></path>
          <rect id="Rectangle" x="0" y="0" width="20" height="20"></rect>
        </g>
      </g>
    </g>
  </svg>
);
