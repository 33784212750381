import { IOptinCode } from "@foodi/core";

export class Step2ViewModel {
  public static setOptins(checkBoxComm: boolean = false): IOptinCode[] {
    const optins: IOptinCode[] = ["AGE_VALIDATED", "FOODI_TOS"];

    if (checkBoxComm) {
      optins.push("PUSH_FOODI_MARKETING");
      optins.push("MAIL_FOODI_MARKETING");
    }

    return optins;
  }
}
