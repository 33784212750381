import { Colors } from "@constants";
import { Title16 } from "@stylesheets";
import style from "styled-components/native";

export const Container = style.Pressable<{
  isMobile?: boolean;
  isDetail?: boolean;
}>`
    max-height: ${({ isDetail }) => (isDetail ? "100%" : "498px")};
    width: ${({ isMobile, isDetail }) =>
      isMobile ? 380 : isDetail ? 800 : 450}px;
`;

export const ImageContainer = style.Image<{
  isMobile?: boolean;
  isDetail?: boolean;
}>`
    height: ${({ isMobile, isDetail }) =>
      isMobile ? 303 : isDetail ? 460 : 336}px;
    width: ${({ isMobile, isDetail }) =>
      isMobile ? 380 : isDetail ? 800 : 450}px;
    margin: 12px 0px ${({ isDetail }) => (isDetail ? 20 : 10)}px 0px;
`;

export const DateText = style(Title16)<{ isDetail?: boolean }>`
    color: ${Colors.gray1};
    margin: ${({ isDetail }) =>
      isDetail ? "10px 0px 20px 0px" : "1px 0px 10px 0px"};
`;

export const TagIconContainer = style.View`
    z-index: -1;
    position: absolute;
    width: 90px;
    border-radius: 8px;
    background-color: ${Colors.foodiDefault};
`;

export const Tag = style.View<{ isMobile?: boolean; isDetail?: boolean }>`
    justify-content: center;
    top: 2px;
    left: ${({ isMobile, isDetail }) => (isMobile || isDetail ? 18 : 40)}px;
    z-index: 2;
    position: absolute;
`;

export const TagTextContainer = style.View`
    margin-left: 30px;
`;
