import { Colors } from "@constants";
import style from "styled-components/native";

export const MENU_WIDTH = 179;

export const Container = style.View`
  background-color: ${Colors.foodiBlack};
  width: ${MENU_WIDTH}px;
  padding: 12px;
  align-items: center;
`;

export const LogoContainer = style.Text`
  margin-top: 18px;
  margin-bottom: 30px; 
`;

export const Button = style.TouchableOpacity`
  padding: 10px 15px;
  justify-content: center;
  background-color: ${Colors.foodiDefault};
  text-align: center;
  border-radius: 8px;
`;

export const Label = style.Text`
  font-family: manrope-bold;
  font-size: 16px;
  line-height: 15px;
  color: ${Colors.foodiBlack};
`;

export const LabelLogOut = style.Text`
  font-family: manrope;
  font-size: 16px;
  line-height: 22px;
  color: ${Colors.white};
`;

export const HelpContainer = style.View`
  margin-top: 265px;
`;

export const LogOutContainer = style.View`
margin-top: 20px;
`;
