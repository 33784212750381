import { ScreenWithFooter, Stepper } from "@atomic";
import { Colors, Spacing } from "@constants";
import { AccountActions, IStep, StepperViewModel } from "@modules";
import { NavigationProp } from "@react-navigation/native";
import { GlobalState } from "@redux";
import { Title22, Title36 } from "@stylesheets";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { View, StyleSheet } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { I18n } from "react-redux-i18n";
import { useDevices } from "@hooks";

interface IProps {
  children?: React.ReactNode;
  navigation: NavigationProp<any>;
}

export const AccountCreationTemplate: React.FC<IProps> = React.memo(
  ({ children, navigation }) => {
    const dispatch = useDispatch();
    const [isMobile] = useDevices();

    const styles = useMemo(() => _styles(isMobile), [isMobile]);
    const Title: React.FC<any> = isMobile ? Title22 : Title36;

    const [stepperData, setStepperData] = useState<IStep[]>(
      StepperViewModel.stepperData
    );

    const currentStepIndex = useSelector(
      (state: GlobalState) => state.account.currentStepIndex
    );

    const onStepSelected = useCallback(
      (stepNumber: number) => {
        if (
          stepNumber >= currentStepIndex ||
          currentStepIndex === stepperData.length
        )
          return;
        StepperViewModel.navigateToStep(navigation, stepNumber);
        dispatch(AccountActions.setCurrentStepIndex(stepNumber));
      },
      [currentStepIndex]
    );

    useEffect(() => {
      setStepperData(
        StepperViewModel.updateStepperData(stepperData, currentStepIndex)
      );
    }, [currentStepIndex]);

    return (
      <ScreenWithFooter
        navigation={navigation}
        style={{ backgroundColor: Colors.white }}
      >
        <View style={styles.container}>
          <Title isBlack style={styles.title}>
            {I18n.t("account.accountCreation")}
          </Title>
          <View style={styles.stepsContainer}>
            <Stepper stepperData={stepperData} callback={onStepSelected} />
            {children}
          </View>
        </View>
      </ScreenWithFooter>
    );
  }
);

const _styles = (isMobile: boolean) =>
  StyleSheet.create({
    container: {
      flex: 1,
      marginVertical: 30,
      marginHorizontal: isMobile ? 0 : 150,
      justifyContent: "flex-start",
      alignItems: "flex-start",
      backgroundColor: Colors.white,
      width: isMobile ? "100vw" : "auto",
    },
    title: {
      marginLeft: 18,
    },
    stepsContainer: {
      width: "100%",
      flex: 1,
      padding: isMobile ? Spacing.L : Spacing.XL,
      marginTop: isMobile ? Spacing.XS : Spacing.M,
      borderRadius: 8,
      borderWidth: isMobile ? 0 : 1,
      borderColor: Colors.border1,
      justifyContent: "center",
      alignItems: "center",
    },
  });
