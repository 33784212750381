"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_HOLDING_AFFLUENCE = void 0;
const client_1 = require("@apollo/client");
exports.GET_HOLDING_AFFLUENCE = client_1.gql `
query getHoldingAffluence {
  getHoldingAffluence {
      id
      idPos
      state
      enabled
  }
}
`;
