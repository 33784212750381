"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CANCEL_ORDER = void 0;
const client_1 = require("@apollo/client");
exports.CANCEL_ORDER = client_1.gql `
mutation cancelOrder(
  $idOrder: ID!
  $newState: OrderState!
  $paymentMethod: PaymentMethod
  $accessToken: String
) {
  order: changeOrderState(
    idOrder: $idOrder
    newState: $newState
    paymentMethod: $paymentMethod
    accessToken: $accessToken
  ) {
    id
    cancelableUntil
    cancelableAt
    state
  }
}
`;
