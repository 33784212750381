import React, { useState } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { Title18 } from "@stylesheets";
import { Holding } from "@foodi/core";
import { Colors, Spacing } from "@constants";
import { TestIDs } from "@utils";

interface IProps {
  holding: Holding;
  onSelectHolding: (holding: Holding) => void;
  forwardTestID?: string;
}

export const HoldingRow: React.FC<IProps> = React.memo(
  ({ holding, onSelectHolding, forwardTestID }) => {
    const [isOver, setIsHover] = useState<boolean>(false);

    const onHoldingPress = (holding: Holding) => {
      onSelectHolding(holding);
    };

    return (
      <Pressable
        testID={forwardTestID}
        // @ts-ignore
        onHoverIn={() => setIsHover(true)}
        onHoverOut={() => setIsHover(false)}
        style={[styles.holdingItem]}
        onPress={() => onHoldingPress(holding)}
      >
        <View
          testID={TestIDs.components.HoldingRow.views.style}
          style={isOver && styles.overStyle}
        ></View>
        <Title18 style={styles.text}>{holding.name}</Title18>
      </Pressable>
    );
  }
);

const styles = StyleSheet.create({
  holdingItem: {
    height: 30,
    justifyContent: "center",
    alignItems: "flex-start",
    marginTop: Spacing.XS,
  },
  overStyle: {
    backgroundColor: Colors.lightGray,
    opacity: 0.1,
    position: "absolute",
    height: "100%",
    width: "100%",
  },
  text: {
    paddingHorizontal: Spacing.M,
  },
});
