import { displayToastError, downloadFile } from "@utils";
import { AnyAction, Dispatch } from "redux";

export class ExportFilesViewModel {
    constructor(private dispatch: Dispatch<AnyAction>, private token: string) {}

    decodeEntityId = (id: any) => {
        let entityId = '';
    
        try {
            entityId = atob(id);
        } catch (error) {
            // will throw error when trying to decode base64 id with character ':', which occurs in local env,
            // where id is entity:id instead of being encoded in base64
            entityId = id;
        }
        return entityId.split(':')[1];
    }

    extractFilenameFromHeader = (value: string | null) => {
        if (!value) {
            return null;
        }
    
        const filenameSection = value.split('filename=')[1];
        if (!filenameSection) {
            return null;
        }
    
        // start at 1, and discard last character, because filename is wrapped in double quotes. ex: "my.name.txt"
        return filenameSection.slice(1, filenameSection.length - 1);
    }

    fetchExcelReport = async (url: string, authToken: string, body: any) => {
        try {
            const headers = new Headers();
            headers.append('authorization', authToken);
            headers.append('content-type', "application/json");

            const params = {
                method: 'POST',
                headers, 
                body: JSON.stringify(body)
            };

            const report = await fetch(url, params);

            if (report.status !== 200) {
                return;
            }
    
            const filename = "pointage_xls.xls";
    
            await downloadFile(await report.blob(), filename);
        } catch (error) {
            displayToastError(this.dispatch);
        }
    };
    
    handleExcelExport = async (params: any) => {
        const apiEndpoint = window.config.API_GRAPHQL_ENDPOINT.split('/graphql')[0];
        const url = `${apiEndpoint}/epointage/export/xls`;
    
        await this.fetchExcelReport(url, this.token, params);
    };  
}