import style from 'styled-components/native';
import {Colors} from '@constants';

export const PickupPointContainer = style.TouchableOpacity<{
  margin?: 'top' | 'bottom';
  color?: string;
  backgroundColor?: string;
}>`
  border: 1px solid ${({backgroundColor}) =>
    backgroundColor || Colors.foodiBlack};
  ${({margin = 'top'}) =>
    margin === 'top' ? 'marginTop: 10px;' : 'marginBottom: 10px;'}
  padding: 10px 8px;
  border-radius: 8px;
  background: ${({backgroundColor}) => backgroundColor || Colors.white};
`;

export const PointName = style.Text<{color?: string}>`
  font-family: manrope-bold;
  line-height: 21px;
  font-size: 16px
  text-align: center;
  color: ${({color}) => color || Colors.foodiBlack};
`;

export const PointSchedule = style.Text<{color?: string}>`
  font-family: manrope-regular;
  line-height: 21px;
  font-size: 16px
  text-align: center;
  color: ${({color}) => color || Colors.foodiBlack};
`;

export const PointDescription = style.Text<{color?: string}>`
  margin-top: 5px;
  font-family: manrope-regular;
  line-height: 15px;
  font-size: 13px
  text-align: center;
  color: ${({color}) => color || Colors.foodiBlack};
`;

