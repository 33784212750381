import { capitalize } from "lodash";
import { IOfferTemplate } from "@foodi/core";
import moment from "moment";
import { BookingOffers } from "../ducks";
import { BookingPeriod } from "./BookingViewModel";

export enum CalendarFlow {
  BOOKING = "BOOKING",
  ORDER = "ORDER",
}

export class CalendarViewModel {
  public readonly DAYS_NUMBER = 5;
  private readonly MAX_MENU_DAYS = 30;
  private static readonly TODAY_DATE = new Date();
  private readonly ROOM_SERVICE_RANGE_IN_DAYS = 30;

  getUpdatedDays(dayNumber: number, plusDays: number) {
    return Array.from(Array(this.DAYS_NUMBER).keys()).map((index) => {
      const updatedIndex = index + plusDays;
      return { index: updatedIndex, isSelected: updatedIndex === dayNumber };
    });
  }

  getDateInfo(dayIndex: number, userLanguage?: string) {
    const new_date = CalendarViewModel.getDateAtIndex(dayIndex);
    const weekDayName = capitalize((moment(new_date, 'MM-DD-YYYY').locale(userLanguage || 'fr').format("dddd")).slice(0, 3));
    const fullDate = new_date.format('YYYY-MM-DD');
    const dayNumber = new_date.format("DD");
    return { weekDayName, dayNumber, fullDate };
  }

  checkCalendarBehaviour(bookingPeriod: BookingPeriod, bookingOffers: BookingOffers[], selectedOffer: IOfferTemplate | null, plusDays: number, calendarFlow: CalendarFlow) {
    // Add 2 days related to weekend for each week in calendar, if the offer dont include weekends
    const checkCalendarBookingDays =
      bookingPeriod === BookingPeriod.WEEKLY_MON
        ? (Math.floor(bookingOffers.length / 5) * 2) + (bookingOffers.length - Math.floor(bookingOffers.length / 5))
        : bookingOffers.length;

    const maxCalendarBookingDays = checkCalendarBookingDays > this.MAX_MENU_DAYS ? checkCalendarBookingDays :  this.MAX_MENU_DAYS;
    
    const daysInAdvanceEnd = selectedOffer?.isRoomService ? this.ROOM_SERVICE_RANGE_IN_DAYS : selectedOffer?.daysInAdvanceEnd

    const canDecreaseCalendarOrder = daysInAdvanceEnd > 4;
    const canDecreaseCalendarBooking = maxCalendarBookingDays > 5;
    const canDecreaseCalendar =
      plusDays > 0 &&
      (calendarFlow === CalendarFlow.ORDER
        ? canDecreaseCalendarOrder
        : canDecreaseCalendarBooking);

    const canIncreaseCalendarOrder =
      plusDays + this.DAYS_NUMBER < daysInAdvanceEnd + 1 &&
      daysInAdvanceEnd > this.DAYS_NUMBER - 1;
    const plusDaysBooking =  bookingPeriod === BookingPeriod.WEEKLY_MON ? 2 : 0;
    const canIncreaseCalendarBooking = 
      plusDays + this.DAYS_NUMBER + plusDaysBooking < maxCalendarBookingDays &&
      maxCalendarBookingDays > this.DAYS_NUMBER - 1;

    const canIncreaseCalendar =
      calendarFlow === CalendarFlow.ORDER
        ? canIncreaseCalendarOrder
        : canIncreaseCalendarBooking;
    return { canDecreaseCalendar, canIncreaseCalendar };
  }

  static getDateAtIndex = (dayIndex: number) =>
    moment(CalendarViewModel.TODAY_DATE, "DD-MM-YYYY").add(dayIndex, "days");

  static isTheSameDay = (date1: string, date2: string) =>
    moment(date1).isSame(moment(date2), "days");
}
