import defaultBanner from "./principal-banner.png";
import defaultBannerMobile from "./principal-banner-mobile.png";
import defaultBannerEng from "./principal-banner-eng.png";
import defaultBannerMobileEng from "./principal-banner-mobile-eng.png";
import defaultPosImg from "./pos-default.jpg";
import NoPointageAccount from "./DIGITAL_FOODI_POINTAGE_illustrations_Acces.png";
import noBooking from "./noBooking.png";
import { InfonewsBackground } from "./InfonewsBackground";
import { MaintenanceDefault } from "./MaintenanceDefault";
import { MaintenanceWebMobile } from "./MaintenanceWebMobile";
import foodiAppScreens from "./FoodiAppScreens.png";
import InfonewsEmpty from "./generic_emptyInfonews.png";

const Images = {
  defaultBanner,
  defaultBannerEng,
  defaultPosImg,
  noBooking,
  InfonewsBackground,
  InfonewsEmpty,
  foodiAppScreens,
  defaultBannerMobile,
  defaultBannerMobileEng,
  MaintenanceWebMobile,
  MaintenanceDefault,
  NoPointageAccount
};

export { Images };
