"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FBrandThemeGateway = void 0;
const domain_1 = require("../../../domain");
const mappers_1 = require("../mappers");
class FBrandThemeGateway {
    constructor(baseUrl, headers) {
        this.baseUrl = baseUrl;
        this.headers = headers;
    }
    getBrandTheme({ application_id, holding_name, environment, feature_name, }) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const brandThemeMapper = new mappers_1.BrandThemeMapper();
                let uri = `${this.baseUrl}/conf?application_id=${application_id}`;
                uri += holding_name ? `&holding_name=${holding_name}` : "";
                uri += environment ? `&environment=${environment}` : "";
                uri += feature_name ? `&feature_name=${feature_name}` : "";
                const response = yield window.fetch(uri, {
                    method: "get",
                    headers: this.headers,
                });
                const { data, errors } = yield response.json();
                if (data.statusCode)
                    throw Error;
                return Promise.resolve(brandThemeMapper.toDomain(data));
            }
            catch (error) {
                return Promise.reject(new domain_1.BrandThemeGatewayErrors.GetBrandThemeError("GET_BRAND_THEME_ERROR", error));
            }
        });
    }
}
exports.FBrandThemeGateway = FBrandThemeGateway;
