import { Colors } from "@constants";
import { ComputedClassroom, ComputedSchedule, ComputedStudent, IEpointageComment, Scheduler, StudentGenerateForDatabase } from "@foodi/core";
import { EpointageThunks } from "@modules";
import _ from "lodash";
import moment from "moment";
import { Dispatch } from "react";

export interface GroupedObject<T> {
    [key: string]: T | GroupedObject<T>;
  }

export type SchedulersWithDynamicKeys = {
    [key: string]: SchedulerWithDynamicKeys
}

export type SchedulerWithDynamicKeys = ComputedSchedule & {
    [key: string]: CLassroomWithDynamicKeys
}
 
export type CLassroomWithDynamicKeys = ComputedClassroom & {
    [key: string]: ComputedSchedule & {
        [key: string]: ComputedStudent
    }
}

export interface CheckboxStyle {
    borderColor: string;
    backgroundColor: string;
}

export interface ScheduleCheckboxStyle {
    checked: CheckboxStyle;
    unChecked: CheckboxStyle;
}

export interface ScheduleStyle {
    deadlineColor: string;
    scheduleBackground: string;
    checkboxStyle: ScheduleCheckboxStyle;
}

export enum ScheduleTimeline {
    PAST_SCHEDULE = -1,
    CURRENT_SCHEDULE = 0,
    FUTURE_SCHEDULE = 1,
}

export enum SelectedDateTimeline {
    PAST_DATE = -1,
    CURRENT_DATE = 0,
    FUTURE_DATE = 1,
}

export enum EPointageRules {
    Pointer = "Pointer",
    Client = "Client",
    Regie = "Regie",
    Admin = "Admin",
}

export interface Schedules {
    deadline: string;
    schedulerTimeline: ScheduleTimeline;
    currentStyleSchedule: ScheduleStyle;
    id: number;
    isInvoice: boolean;
    name: string;
    order: number;
    serviceId: number;
    students: [string, ComputedStudent][];
    haveInitialPreCheck?: boolean;
    needsPrecheckFromPrevious?: boolean;
}


export class SchedulerViewModel {
    constructor(private dispatch: Dispatch<any>) {}

    private date = new Date();

    getCommentsGroupedByClassroom = (scheduler: Scheduler): ComputedClassroom[] => {
        return scheduler?.school?.classrooms?.filter((c: ComputedClassroom) => 
                c.comments.some((cm: IEpointageComment) => cm.comment !== '')
            );
    };

    getCommentWithServiceId = (comments: IEpointageComment[], serviceId: number): IEpointageComment | undefined => {
        return comments.find((cm: IEpointageComment) => cm.scheduleId === serviceId);
    };

    getSchedulerInfo = async (siteId: string, etaId: string, date: string): Promise<Scheduler> => {
        const schedulerResponse = await this.dispatch(EpointageThunks.getEpointageScheduler({
            siteId,
            etaId,
            date,
        }))
        return _.get(schedulerResponse, 'epointageScheduler');
    };

    filterStudents = (classroomId: string, s: ComputedSchedule, index: number, schedulerTimeline: ScheduleTimeline) => {
        let haveInitialPreCheck = false;
        let needsPrecheckFromPrevious = true;
        const filterStudents = Object.entries(s).filter(([key, value]) => {
            if (!!value.supposedPresence
                && !value.updateDate
                && index === 0
                && schedulerTimeline === ScheduleTimeline.CURRENT_SCHEDULE) haveInitialPreCheck = true;

            if (!!value.presence
                && schedulerTimeline === ScheduleTimeline.CURRENT_SCHEDULE) needsPrecheckFromPrevious = false;

            return (
            key.includes('studentId') && (value.classroomId === classroomId) && (!!value.presence || 
                (!!value.supposedPresence 
                    && !value.updateDate
                    && index === 0
                    && schedulerTimeline === ScheduleTimeline.CURRENT_SCHEDULE))
        )});
        return {
            haveInitialPreCheck,
            needsPrecheckFromPrevious,
            students: filterStudents
        }
    };

    getSchedulerTimeline = (
        schedule: ComputedSchedule,
        sortedSchedules: ComputedSchedule[],
        scheduleIndex: number,
        selectedDate: string): ScheduleTimeline => {
        const time = `${String(this.date.getHours()).padStart(2, '0')}:${String(this.date.getMinutes()).padStart(2, '0')}`;
        let schedulerTimeline = ScheduleTimeline.FUTURE_SCHEDULE;

        const selectedDateTimeline = this.checkSelectedDateTimeline(selectedDate);
       
        if (selectedDateTimeline === SelectedDateTimeline.PAST_DATE) {
            schedulerTimeline = ScheduleTimeline.PAST_SCHEDULE;
        }  else if (selectedDateTimeline === SelectedDateTimeline.FUTURE_DATE) {
            if (scheduleIndex === 0) {
                schedulerTimeline = ScheduleTimeline.CURRENT_SCHEDULE;
            } else {
                schedulerTimeline = ScheduleTimeline.FUTURE_SCHEDULE;
            }
        } else if (sortedSchedules.length === 3) {
            if (time > schedule.deadline) {
                schedulerTimeline = ScheduleTimeline.PAST_SCHEDULE;
            } else if (time < sortedSchedules?.[scheduleIndex+1]?.deadline && time > sortedSchedules?.[scheduleIndex-1]?.deadline) {
                schedulerTimeline = ScheduleTimeline.CURRENT_SCHEDULE;
            }
            else if (time > sortedSchedules?.[scheduleIndex-2]?.deadline && time > sortedSchedules?.[scheduleIndex-1]?.deadline) {
                schedulerTimeline = ScheduleTimeline.CURRENT_SCHEDULE;
            }
            else if (time < sortedSchedules?.[scheduleIndex+2]?.deadline && time < sortedSchedules?.[scheduleIndex+1]?.deadline) {
                schedulerTimeline = ScheduleTimeline.CURRENT_SCHEDULE;
            };
        } else if (sortedSchedules.length === 2) {
            if (time > schedule.deadline) {
                schedulerTimeline = ScheduleTimeline.PAST_SCHEDULE;
            } else if (time < sortedSchedules?.[scheduleIndex+1]?.deadline && time > sortedSchedules?.[scheduleIndex-1]?.deadline) {
                schedulerTimeline = ScheduleTimeline.CURRENT_SCHEDULE;
            }
            else if (time > sortedSchedules?.[scheduleIndex-1]?.deadline) {
                schedulerTimeline = ScheduleTimeline.CURRENT_SCHEDULE;
            }
            else if (time < sortedSchedules?.[scheduleIndex+1]?.deadline) {
                schedulerTimeline = ScheduleTimeline.CURRENT_SCHEDULE;
            };
        } else {
            if (time > schedule.deadline) {
                schedulerTimeline = ScheduleTimeline.PAST_SCHEDULE;
            } else {
                schedulerTimeline = ScheduleTimeline.CURRENT_SCHEDULE;
            }
        }

        return schedulerTimeline;
    };

    getSchedulesFromClassroom = (classroom: ComputedClassroom, selectedDate: string) : Schedules[] => {
        const time = `${String(this.date.getHours()).padStart(2, '0')}:${String(this.date.getMinutes()).padStart(2, '0')}`;
        const schedules: ComputedSchedule[]= Object.entries(classroom)
            .filter(([key]) => key.includes('scheduleId')).map((item) => item[1]);
        const sortedSchedules = _.sortBy(schedules, 'order');
        const selectedDateTimeline = this.checkSelectedDateTimeline(selectedDate);

        const styleSchedule = {
            pastSchedule: {
                deadlineColor: Colors.foodiBlack,
                scheduleBackground: Colors.background1,
                checkboxStyle: {
                    checked: {
                        borderColor: Colors.darkGrey,
                        backgroundColor: Colors.darkGrey
                    },
                    unChecked: {
                        borderColor: Colors.darkGrey,
                        backgroundColor: ""
                    }
                }
            },
            currentSchedule: {
                deadlineColor: Colors.middleGreen,
                scheduleBackground: Colors.white,
                checkboxStyle: {
                    checked: {
                        borderColor: Colors.middleGreen,
                        backgroundColor: Colors.middleGreen
                    },
                    unChecked: {
                        borderColor: Colors.foodiBlack,
                        backgroundColor: ""
                    }
                }
            },
            futureSchedule: {
                deadlineColor: Colors.darkGrey,
                scheduleBackground: Colors.white,
                checkboxStyle: {
                    checked: {
                        borderColor: Colors.disabledBackground,
                        backgroundColor: Colors.background1
                    },
                    unChecked: {
                        borderColor: Colors.disabledBackground,
                        backgroundColor: Colors.background1
                    }
                }
            }
        }

        return sortedSchedules?.map((s: ComputedSchedule, index) => {
            let currentStyleSchedule = styleSchedule.futureSchedule;
            let schedulerTimeline = ScheduleTimeline.FUTURE_SCHEDULE;
            
            if (selectedDateTimeline === SelectedDateTimeline.PAST_DATE) {
                schedulerTimeline = ScheduleTimeline.PAST_SCHEDULE;
                currentStyleSchedule = styleSchedule.pastSchedule;
            } else if (selectedDateTimeline === SelectedDateTimeline.FUTURE_DATE) {
                if (index === 0) {
                    schedulerTimeline = ScheduleTimeline.CURRENT_SCHEDULE;
                    currentStyleSchedule = styleSchedule.currentSchedule;
                } else {
                    schedulerTimeline = ScheduleTimeline.FUTURE_SCHEDULE;
                    currentStyleSchedule = styleSchedule.futureSchedule;
                }
            } else if (sortedSchedules.length === 3) {
                if (time > s.deadline) {
                    schedulerTimeline = ScheduleTimeline.PAST_SCHEDULE;
                    currentStyleSchedule = styleSchedule.pastSchedule;
                } else if (time < sortedSchedules?.[index+1]?.deadline && time > sortedSchedules?.[index-1]?.deadline) {
                    schedulerTimeline = ScheduleTimeline.CURRENT_SCHEDULE;
                    currentStyleSchedule = styleSchedule.currentSchedule;
                }
                else if (time > sortedSchedules?.[index-2]?.deadline && time > sortedSchedules?.[index-1]?.deadline) {
                    schedulerTimeline = ScheduleTimeline.CURRENT_SCHEDULE;
                    currentStyleSchedule = styleSchedule.currentSchedule;
                }
                else if (time < sortedSchedules?.[index+2]?.deadline && time < sortedSchedules?.[index+1]?.deadline) {
                    schedulerTimeline = ScheduleTimeline.CURRENT_SCHEDULE;
                    currentStyleSchedule = styleSchedule.currentSchedule;
                };
            } else if (sortedSchedules.length === 2) {
                if (time > s.deadline) {
                    schedulerTimeline = ScheduleTimeline.PAST_SCHEDULE;
                    currentStyleSchedule = styleSchedule.pastSchedule;
                } else if (time < sortedSchedules?.[index+1]?.deadline && time > sortedSchedules?.[index-1]?.deadline) {
                    schedulerTimeline = ScheduleTimeline.CURRENT_SCHEDULE;
                    currentStyleSchedule = styleSchedule.currentSchedule;
                }
                else if (time > sortedSchedules?.[index-1]?.deadline) {
                    schedulerTimeline = ScheduleTimeline.CURRENT_SCHEDULE;
                    currentStyleSchedule = styleSchedule.currentSchedule;
                }
                else if (time < sortedSchedules?.[index+1]?.deadline) {
                    schedulerTimeline = ScheduleTimeline.CURRENT_SCHEDULE;
                    currentStyleSchedule = styleSchedule.currentSchedule;
                };
            } else {
                if (time > s.deadline) {
                    schedulerTimeline = ScheduleTimeline.PAST_SCHEDULE;
                    currentStyleSchedule = styleSchedule.pastSchedule;
                } else {
                    schedulerTimeline = ScheduleTimeline.CURRENT_SCHEDULE;
                    currentStyleSchedule = styleSchedule.currentSchedule;
                }
            }
           

            const {haveInitialPreCheck, needsPrecheckFromPrevious, students} = this.filterStudents(classroom.id, s, index, schedulerTimeline);
            const previousSchedule = schedulerTimeline === ScheduleTimeline.CURRENT_SCHEDULE 
                && index !== 0 && needsPrecheckFromPrevious 
                && this.filterStudents(classroom.id, sortedSchedules[index-1], index, schedulerTimeline);

            return {
                ...s,
                haveInitialPreCheck,
                needsPrecheckFromPrevious,
                schedulerTimeline,
                currentStyleSchedule,
                students: previousSchedule ? previousSchedule.students : students,
            };
        });
    }
    
    getGroupedClassrooms = (scheduler: Scheduler): GroupedObject<ComputedClassroom> => {
        return scheduler?.school?.classrooms.reduce<Record<string, ComputedClassroom>>(
            (groupClassrooms, classroom) => {
              groupClassrooms[`classroomId_${classroom.id}`] = classroom;
              return groupClassrooms;
            },
            {}
          );
    };

    mapScheduler = (scheduler: Scheduler): SchedulersWithDynamicKeys => {
        const groupedClassrooms = this.getGroupedClassrooms(scheduler);

        const schedulerMapped: any = {};

        scheduler?.schedules.forEach((service) => {
            const {classrooms, ...schoolInfo} = scheduler?.school;
            const schoolInfoFormated = {...schoolInfo , ...groupedClassrooms}
            schedulerMapped[`serviceId_${service.serviceId}`] = schoolInfoFormated;
        });

        scheduler?.schedules.forEach((schedule) => {
            const { students, ...scheduleInfo } = schedule;

            const groupStudents: any = {};
            students.forEach(
                (student) => {
                    groupStudents[student.classroomId] = {
                        ...groupStudents[student.classroomId],
                        [`studentId_${student.id}${student.familyId}`]: {
                            ...student
                        }
                    }
                }
                );

            students.forEach((student) => {
                schedulerMapped[`serviceId_${schedule.serviceId}`][`classroomId_${student.classroomId}`] = { 
                    ...schedulerMapped[`serviceId_${schedule.serviceId}`][`classroomId_${student.classroomId}`],
                    [`scheduleId_${scheduleInfo.id}`]: {
                        ...scheduleInfo,
                        ...groupStudents[student.classroomId]
                    }
                }
            });
        })
        return schedulerMapped;
    };

    getMappedClassDetail = (
        serviceId: number,
        classroomId: string,
        mappedSchedule: SchedulersWithDynamicKeys
    ) : CLassroomWithDynamicKeys => {
        return mappedSchedule[`serviceId_${serviceId}`][`classroomId_${classroomId}`];
    }

    getCountPresences(students: ComputedStudent[]) {
        return students.reduce(
          (countPresence, student) => {
            if(student.presence) countPresence += 1
            return countPresence;
          },
          0
        );
      }

    getClassroomsFromScheduler = (scheduler?: SchedulerWithDynamicKeys, classroomId?: string) : string[] => {
        let classroomList = scheduler && Object.keys(scheduler).filter((key: string) => key.includes('classroomId')) || [];
        if (classroomId) {
            classroomList = classroomList.filter((c) => c === `classroomId_${classroomId}`)
        }
        classroomList = _.sortBy(classroomList, (item: string) => scheduler?.[item]?.name?.toLowerCase?.());
        return classroomList;
    };

    getStudentsFromSchedule = (schedule: Schedules) : ComputedStudent[] => {
        const sorted = Object.entries(schedule)
            .filter(([key]) => key.includes("studentId_"))
            .map((item) => item[1]) as ComputedStudent[];
        return _.sortBy(sorted, (item: ComputedStudent) => item?.name?.toLowerCase());
    };

    getTotalPresentWithColor = (schedule: ComputedSchedule[], selectedDate: string) => {
        return schedule.map((s: ComputedSchedule, scheduleIndex:number) => {
            const scheduleTimeline = this.getSchedulerTimeline(s, schedule, scheduleIndex, selectedDate);
            const studentsFiltered = s.students.filter((st: ComputedStudent, studentIndex: number) => {
                return !!st.presence
                }
            )
            if (scheduleTimeline === ScheduleTimeline.PAST_SCHEDULE) {
                return {
                    total: studentsFiltered.length,
                    color: Colors.foodiBlack
                }
            } else if (scheduleTimeline === ScheduleTimeline.CURRENT_SCHEDULE) {
                return {
                    total: studentsFiltered.length,
                    color: studentsFiltered.length > 0 ? Colors.middleGreen : Colors.foodiBlack
                }
            } else {
                return {
                    total: 0,
                    color: Colors.darkGrey
                }
            }
        });
    };

    handleInitialPreCheck = (scheduleIndex: number, studentList: ComputedStudent[], schedulerTimeline: ScheduleTimeline) => {
        if (scheduleIndex === 0 && schedulerTimeline === ScheduleTimeline.CURRENT_SCHEDULE) {
            return studentList.reduce((updatedStudentList: ComputedStudent[], student: ComputedStudent) =>{
                if(!student.updateDate) {
                    updatedStudentList.push({...student, presence: student.supposedPresence});
                } else updatedStudentList.push(student);
                return updatedStudentList;
            }, []);
        }
        return studentList;
    };

    checkIfNeedsPreCheck = (studentList: ComputedStudent[]) => {
        let preCheckNeeded = true;
        studentList.forEach((student: ComputedStudent) => {
                if (!!student.updateDate) preCheckNeeded = false
            }
        )
        return preCheckNeeded;
    }

    getStudentValuesForMutation = (studentList: ComputedStudent[]) => {
        return studentList.reduce((studentList: StudentGenerateForDatabase[], student: ComputedStudent) => { 
            studentList.push({
                familyId: student.familyId,
                studentId: student.id,
                presence: student.presence
            });
            return studentList;
        },[])
    }

    studentsListToUse = (scheduleIndex: number, studentList: ComputedStudent[], prevStudentList: ComputedStudent[], schedulerTimeline: ScheduleTimeline) => {
        //Schedule needs precheck if students list dont have update date
        const isPreckNeeded = this.checkIfNeedsPreCheck(studentList);
        const isInitialPrecked = isPreckNeeded && scheduleIndex === 0;

        // If first scheduler and needs precheck, get check value from supposedPresence
        const updatedStudentsChecks = this.handleInitialPreCheck(
            scheduleIndex,
            studentList,
            schedulerTimeline
        );

        // If precheck needed, and isn't the first schedule returns checks from previous schedulers
        const studentsListWithPrecheck = isPreckNeeded && schedulerTimeline === ScheduleTimeline.CURRENT_SCHEDULE
            ? prevStudentList
            : studentList;
        
        return isInitialPrecked && schedulerTimeline === ScheduleTimeline.CURRENT_SCHEDULE
            ? updatedStudentsChecks
            : studentsListWithPrecheck;
    }

    checkIfHaveWritePermission = (userRole: string) => userRole === EPointageRules.Admin || userRole === EPointageRules.Pointer;

    checkSelectedDateTimeline = (selectedDate: string): SelectedDateTimeline => {
        const selectedDateFormated = new Date(selectedDate);
        selectedDateFormated.setHours(0,0,0,0);
        const currentDateFormated = new Date(this.date);
        currentDateFormated.setHours(0,0,0,0);

        let selectedDateTimeline = SelectedDateTimeline.CURRENT_DATE;
        if (selectedDateFormated < currentDateFormated) selectedDateTimeline = SelectedDateTimeline.PAST_DATE
        if (selectedDateFormated > currentDateFormated) selectedDateTimeline = SelectedDateTimeline.FUTURE_DATE
        return selectedDateTimeline;
    }

    checkIfDeadlinePassed = (deadline: string): any => {
        const deadlineSplited = deadline.split(":");
        const currentTime = moment();
        const deadlineTime = moment().set("hour", parseInt(deadlineSplited[0])).set("minute", parseInt(deadlineSplited[1]))
        
        return currentTime.isSameOrAfter(deadlineTime);;
    }
}
