import React from "react";
import { I18n } from "react-redux-i18n";

export const BlackBadge: React.FC = () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>{I18n.t("cart.badge")}</title>
    <g
      id="Symbols"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="Module/Basket" transform="translate(-11.000000, -506.000000)">
        <g
          id="badge_black_24dp-(1)"
          transform="translate(11.000000, 506.000000)"
        >
          <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
          <path
            d="M20,7 L15,7 L15,4 C15,2.9 14.1,2 13,2 L11,2 C9.9,2 9,2.9 9,4 L9,7 L4,7 C2.9,7 2,7.9 2,9 L2,20 C2,21.1 2.9,22 4,22 L20,22 C21.1,22 22,21.1 22,20 L22,9 C22,7.9 21.1,7 20,7 Z M9,12 C9.83,12 10.5,12.67 10.5,13.5 C10.5,14.33 9.83,15 9,15 C8.17,15 7.5,14.33 7.5,13.5 C7.5,12.67 8.17,12 9,12 Z M12,18 L6,18 L6,17.25 C6,16.25 8,15.75 9,15.75 C10,15.75 12,16.25 12,17.25 L12,18 Z M13,9 L11,9 L11,4 L13,4 L13,9 Z M18,16.5 L14,16.5 L14,15 L18,15 L18,16.5 Z M18,13.5 L14,13.5 L14,12 L18,12 L18,13.5 Z"
            id="Shape"
            fill="#090909"
            fill-rule="nonzero"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);
