import React, { useMemo } from "react";
import { StyleSheet, Switch, View } from "react-native";
import { Title16 } from "@stylesheets";
import { Colors, Spacing } from "@constants";
import { useDevices } from "@hooks";

interface IProps {
  forwardTestID?: string;
  title: string;
  switchValue: boolean;
  noMarginTop?: boolean;
  onValueChange: (value: boolean) => void;
  disabled?: boolean;
}

export const SwitchItem: React.FC<IProps> = React.memo(
  ({
    forwardTestID,
    title,
    switchValue,
    noMarginTop,
    onValueChange,
    disabled,
  }) => {
    const [isMobile] = useDevices();
    const styles = useMemo(() => _styles(isMobile, noMarginTop), [
      isMobile,
      noMarginTop,
    ]);

    return (
      <View style={styles.infoDiv}>
        <Title16 style={styles.infoNameSwitch}>{title}</Title16>
        <Switch
          disabled={disabled}
          testID={forwardTestID}
          //@ts-ignore
          activeThumbColor={Colors.white}
          activeTrackColor={Colors.foodiDefault}
          thumbColor={Colors.white}
          value={switchValue}
          onValueChange={(value) => {
            onValueChange(value);
          }}
        />
      </View>
    );
  }
);

const _styles = (isMobile: boolean, noMarginTop?: boolean) =>
  StyleSheet.create({
    infoDiv: {
      flexDirection: "row",
      marginTop: noMarginTop ? 0 : Spacing.M,
      alignContent: "center",
      alignItems: "center",
      justifyContent: isMobile ? "space-between" : "flex-start",
      width: "100%",
    },
    infoNameSwitch: {
      color: Colors.foodiBlack,
      marginRight: Spacing.M,
      fontFamily: "manrope-bold",
    },
  });
