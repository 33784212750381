import React from "react";

interface IProps {
  size?: number;
}

export const CarouselArrowLeft: React.FC<IProps> = ({ size }) => (
  <svg
    width={`${size ? size : 24}px`}
    height={`${size ? size : 24}px`}
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Design-System"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="Icons-pg1" transform="translate(-221.000000, -665.000000)">
        <g
          id="Arrow_carroussel_left_black"
          transform="translate(221.000000, 665.000000)"
        >
          <g id="keyboard_arrow_right_black_24dp" fill="#D8D8D8">
            <circle id="Oval" cx="12" cy="12" r="12"></circle>
          </g>
          <g
            id="keyboard_arrow_right_black_24dp"
            transform="translate(12.000000, 12.000000) rotate(-180.000000) translate(-12.000000, -12.000000) "
          >
            <polygon
              id="Path"
              transform="translate(12.000000, 12.000000) rotate(90.000000) translate(-12.000000, -12.000000) "
              points="0 0 24 0 24 24 0 24"
            ></polygon>
            <polygon
              id="Path"
              fill="#000000"
              fill-rule="nonzero"
              points="8.59 16.59 13.17 12 8.59 7.41 10 6 16 12 10 18"
            ></polygon>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
