import React from "react";

export const InSite: React.FC = () => (
  <svg
    width="26px"
    height="24px"
    viewBox="0 0 26 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Restaurant</title>
    <g
      id="FOODI"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Accueil---Brasserie-Copy-2"
        transform="translate(-26.000000, -520.000000)"
      >
        <g id="Restaurant" transform="translate(26.000000, 521.000000)">
          <path
            d="M16.4545455,9.6 L16.4545455,14.8 L18.5818182,14.8 L18.5818182,20 L20,20 L20,7 C18.0429091,7 16.4545455,8.456 16.4545455,9.6 Z M12.6727273,11.55 L11.2545455,11.55 L11.2545455,7 L9.83636364,7 L9.83636364,11.55 L8.41818182,11.55 L8.41818182,7 L7,7 L7,11.55 C7,12.9865 8.26927273,14.15 9.83636364,14.15 L9.83636364,20 L11.2545455,20 L11.2545455,14.15 C12.8216364,14.15 14.0909091,12.9865 14.0909091,11.55 L14.0909091,7 L12.6727273,7 L12.6727273,11.55 Z"
            id="Sur-Place"
            fill="#000000"
            fill-rule="nonzero"
          ></path>
          <rect id="Rectangle" x="0" y="2" width="20" height="20"></rect>
          <polyline
            id="Path"
            stroke="#000000"
            stroke-width="1.5"
            fill-rule="nonzero"
            points="14 -2.31076977e-17 14.6713015 0.447534342 25 7.33333333 25 22 3 22 3 7.33333333 14.7954681 -4.69069228e-15"
          ></polyline>
        </g>
      </g>
    </g>
  </svg>
);
