import React from "react";
import { ViewStyle, TextStyle } from "react-native";
import { Colors } from "@constants";
import { Icon, IconName, Label } from "@atomic";
import {
  Circle,
  Container,
  LableContainer,
  IconView,
  CircleContainer,
} from "./ViewIconBordered.style";
interface IProps {
  title?: string;
  title2?: string;
  iconName: IconName;
  style?: ViewStyle;
  forwardTestID?: string;
  children?: React.ReactNode;
  labelsStyle?: TextStyle;
  isMobile?: boolean;
}

export const ViewIconBordered: React.FC<IProps> = ({
  style,
  forwardTestID,
  title,
  title2,
  iconName,
  children,
  labelsStyle,
  isMobile,
}) => {
  return (
    <Container testID={forwardTestID} style={[style]} isMobile={isMobile}>
      {children}
      {!!title && !isMobile ? (
        <Label title={title} style={labelsStyle} />
      ) : null}
      {!!title2 ? (
        <>
          {title && !isMobile && <Circle />}
          {!isMobile && <Label title={title2} style={labelsStyle} />}
        </>
      ) : null}
      {!isMobile && (
        <IconView>
          <Icon name={iconName} color={Colors.white} size={16} />
        </IconView>
      )}
    </Container>
  );
};
