"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetPaymentRequest = void 0;
class GetPaymentRequest {
    constructor(paymentsService) {
        this.paymentsService = paymentsService;
    }
    execute(request) {
        const { amount, token, userRedirectUrl } = request;
        return this.paymentsService.getPaymentRequest({ amount, token, userRedirectUrl });
    }
}
exports.GetPaymentRequest = GetPaymentRequest;
