import {
  HomeScreens,
  BalanceScreens,
  POSScreens,
  ProfileScreens,
} from "@modules";

export const DeeplinkConfigs = (): any => {
  return {
    prefixes: ["foodi://", "https://foodi.com"],
    config: {
      screens: {
        Root: {
          initialRouteName: HomeScreens.HOME_SCREEN,
          screens: {
            [HomeScreens.HOME_SCREEN]: "homepage",
            [HomeScreens.BALANCE_SCREEN]: {
              screens: {
                [BalanceScreens.BALANCE_MAIN_SCREEN]: "balance",
                [BalanceScreens.MERCANET_LOADING]: "mercanet",
                [BalanceScreens.SUCCEED_REFILL_SCREEN]: {
                  path: "refill_success/:successMessage",
                  parse: {
                    successMessage: () => "",
                  },
                  stringify: {
                    successMessage: () => "",
                  },
                },
                [BalanceScreens.ERROR_RECEIPT]: {
                  path: "refill_error/:errorType",
                  parse: {
                    errorType: () => "",
                  },
                  stringify: {
                    errorType: () => "",
                  },
                },
              },
            },
            [HomeScreens.POINT_OF_SALE_STACK]: {
              screens: {
                [POSScreens.RESTAURANT_DETAIL]: {
                  path: "restaurant/:id/:isModify?/:isRefillFromCart?",
                  parse: {
                    isModify: () => "",
                    isRefillFromCart: () => "",
                  },
                  stringify: {
                    isModify: () => "",
                    isRefillFromCart: () => "",
                  },
                },
                [POSScreens.EDENRED_CALLBACK]: "edenred/login-callback",
                [POSScreens.CONFIRMATION_PAGE]: "order",
              },
            },
            [HomeScreens.PROFILE_STACK]: {
              screens: {
                [ProfileScreens.PROFILE_SCREEN]: {
                  path: "profile/:tab?/:showLeftContainerFlag?",
                  parse: {
                    tab: () => "",
                    showLeftContainerFlag: () => "",
                  },
                  stringify: {
                    tab: () => "",
                    showLeftContainerFlag: () => "",
                  },
                },
              },
            },
            [HomeScreens.ARTICLE_SCREEN]: "article",
            [HomeScreens.IDPCallback]: "idp-authentication-callback",
          },
        },
      },
    },
  };
};
