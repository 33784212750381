import React from "react";

export const InfonewsBackground: React.FC<{
  color?: string;
  width?: number;
  height?: number;
}> = ({ color, width, height }) => (
  <svg
    width={`${width || 560}px`}
    height={`${height || 400}px`}
    viewBox="0 0 560 400"
    preserveAspectRatio="none"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Cercles</title>
    <defs>
      <rect id="path-1" x="0" y="0" width="560" height="400" rx="8"></rect>
    </defs>
    <g
      id="Homepage"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="HP-UI-D---Default"
        transform="translate(-730.000000, -1991.000000)"
      >
        <g id="L'actualité" transform="translate(150.000000, 1887.000000)">
          <g id="Info-News" transform="translate(580.000000, 89.000000)">
            <g id="Cercles" transform="translate(0.000000, 15.000000)">
              <mask id="mask-2" fill="white">
                <use xlinkHref="#path-1"></use>
              </mask>
              <use id="Mask" fill={color} xlinkHref="#path-1"></use>
              <ellipse
                id="Oval"
                fill-opacity="0.2"
                fill="#FFFFFF"
                mask="url(#mask-2)"
                cx="554.825127"
                cy="365.096953"
                rx="89"
                ry="86.9806094"
              ></ellipse>
              <ellipse
                id="Oval"
                fill-opacity="0.3"
                fill="#FFFFFF"
                mask="url(#mask-2)"
                cx="426.438879"
                cy="391.689751"
                rx="56.5"
                ry="51.5235457"
              ></ellipse>
              <ellipse
                id="Oval"
                fill-opacity="0.15"
                fill="#FFFFFF"
                mask="url(#mask-2)"
                cx="319.893039"
                cy="369.501385"
                rx="106"
                ry="103.601108"
              ></ellipse>
              <circle
                id="Oval"
                fill-opacity="0.3"
                fill="#FFFFFF"
                mask="url(#mask-2)"
                cx="31.4278438"
                cy="382.024931"
                r="60"
              ></circle>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
