"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GUEST_WITH_PAYMENT_PROFILE_FRAGMENT = void 0;
const client_1 = require("@apollo/client");
exports.GUEST_WITH_PAYMENT_PROFILE_FRAGMENT = client_1.gql `
  fragment guestWithPaymentProfileFragment on Guest {
    id
    managementMode
  }
`;
