import React from "react";

interface IProps {
  size?: number;
}

export const PaymentSVG: React.FC<IProps> = ({ size }) => (
  <svg
    width={size ? `${size}px` : "20px"}
    height={size ? `${size}px` : "20px"}
    viewBox="0 0 22 22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{"Icons/payment"}</title>
    <g fill="none" fillRule="evenodd">
      <circle stroke="#242424" strokeWidth={2.208} cx={11} cy={11} r={10} />
      <path
        d="M11.992 7.358c-.611 0-1.115.171-1.51.515-.396.343-.652.841-.767 1.494h3.028v1.286h-3.14l-.015.256v.343l.015.241h2.667v1.3H9.73c.256 1.185 1.06 1.776 2.412 1.776.716 0 1.405-.138 2.066-.416v1.87c-.58.288-1.315.432-2.201.432-1.227 0-2.237-.324-3.028-.972-.791-.648-1.29-1.544-1.495-2.69h-1.03v-1.3h.887a2.693 2.693 0 0 1-.03-.453l.015-.387h-.871V9.367h.999c.185-1.179.683-2.11 1.495-2.795.811-.684 1.826-1.027 3.043-1.027.941 0 1.823.2 2.644.6L13.9 7.84a7.661 7.661 0 0 0-.97-.355 3.425 3.425 0 0 0-.938-.127Z"
        fill="#242424"
      />
    </g>
  </svg>
);
