import { Colors } from "@constants";
import style from "styled-components/native";

export const Container = style.Pressable<{ hasError?: boolean }>`
    width: 20px;
    height: 20px;
    border: 2px solid ${({ hasError }) =>
        hasError ? Colors.redError : Colors.border1};
    align-items: center;
    justify-content: center;
`;
