import { AccountScreens } from "@modules/account/navigation";
import { HomeScreens } from "@modules/home/navigation";
import { navigationService } from "@navigation";

const allowedGuestRoutes: any = [
  HomeScreens.HOME_SCREEN,
  HomeScreens.ACCOUNT_STACK,
  HomeScreens.MAINTENANCE_SCREEN,
  AccountScreens.STEP_ONE_SCREEN,
  AccountScreens.STEP_TWO_SCREEN,
  AccountScreens.STEP_THREE_SCREEN,
];

export const navigationPermission = (state: any, store: any) => {
  const isUserLogin = store.getState()?.auth?.isLogin;
  const activeRouteName = navigationService.getActiveRouteName();
  if (!isUserLogin && !allowedGuestRoutes.includes(activeRouteName)) {
    navigationService.navigate(HomeScreens.HOME_SCREEN);
  }
};
