import React from "react";
import { useDevices } from "@hooks";
import { Container } from "./MaintenanceHeader.style";
import { SmallLogoWhiteMobile, SmallLogoWhiteWeb } from "@assets";

export const MaintenanceHeader: React.FC = ({}) => {
  const [isMobile] = useDevices();

  const WhiteLogo = isMobile ? SmallLogoWhiteMobile : SmallLogoWhiteWeb;

  return (
    <Container isMobile={isMobile}>
      <WhiteLogo />
    </Container>
  );
};
