"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ADD_ORDER_RATING = void 0;
const client_1 = require("@apollo/client");
exports.ADD_ORDER_RATING = client_1.gql `
  mutation createOrderRating(
  $idOrder: ID!
  $ratings: RatingsInput!
  $comment: String
  $contactInfo: String
) {
  orderRating: createOrderRating(
    idOrder: $idOrder
    ratings: $ratings
    comment: $comment
    contactInfo: $contactInfo
  ) {
    id
    idOrder
    created
  }
}
`;
