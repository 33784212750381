import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { HomeScreens } from "./HomeScreens";
import { MaintenancePage } from "../screens/MaintenancePage";
import { MaintenanceHeader } from "@atomic";

export type MaintenanceParamList = {
  [HomeScreens.MAINTENANCE_SCREEN]: undefined;
};

const Stack = createStackNavigator<MaintenanceParamList>();

export const MaintenanceNavigation = () => {
  return (
    <Stack.Navigator
      screenOptions={() => ({
        header: () => <MaintenanceHeader />,
      })}
    >
      <Stack.Screen
        name={HomeScreens.MAINTENANCE_SCREEN}
        key="Maintenance"
        component={MaintenancePage}
      />
    </Stack.Navigator>
  );
};
