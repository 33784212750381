"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_PAYMENT_STATUS = void 0;
const client_1 = require("@apollo/client");
exports.GET_PAYMENT_STATUS = client_1.gql `
query getPaymentStatus($token: String!, $idRefill: ID!) {
    mercanetV2Status(token: $token, idRefill: $idRefill) {
      success
      transactionSuccess
      refillSuccess
      date
      amount {
        amount
        currency
      }
    }
  }
`;
