import React, { useMemo } from "react";
import { formatAmount } from "@foodi/core";
import { Pressable, StyleSheet, View, ViewStyle } from "react-native";
import { Title13, Title16 } from "@stylesheets";
import { Colors, Spacing } from "@constants";
import moment from "moment";
import _ from "lodash";
import { I18n } from "react-redux-i18n";
import { useDevices } from "@hooks";
import { HistoricalViewModel } from "@modules";
import { NavigationProp } from "@react-navigation/native";
import { Forward, PaymentSVG } from "@assets";

interface IProps {
  historicDate: string;
  title: any;
  total: number | null;
  styles?: any;
  forwardTestID?: string;
  id: number;
  navigation: NavigationProp<any>;
  currentTab?: number;
  callback?: (params: any) => void;
  pdfFileName?: string | undefined;
  isClickable: boolean;
}

export const HistoricRow: React.FC<IProps> = React.memo(
  ({
    forwardTestID,
    historicDate,
    total,
    title,
    currentTab,
    pdfFileName,
    callback,
    isClickable,
  }) => {
    const [isMobile] = useDevices();
    const historicalVM = new HistoricalViewModel();
    const styles = useMemo(() => _styles(isMobile), [isMobile]);

    const amount = total ? total : 0;
    const formattedAmount = `${formatAmount(amount, "€")}`;
    const formattedHour = ` ${I18n.t("common.at")} ${moment(
      new Date(historicDate)
    ).format("HH:mm")}`;
    const haveTime = historicalVM.checkIfDateHaveTime(historicDate);

    return (
      <> 
      <Pressable
      testID={forwardTestID}
      style={styles.container}
      onPress={() => callback?.(pdfFileName)}
      disabled={!isClickable}
      >
          <View>
            <View style={styles.rowStyle}>
              <View style={styles.iconStyle}>
                <PaymentSVG />
              </View>
              <View style={styles.infoDiv}>
                <Title16
                  style={styles.title}
                  numberOfLines={1}
                  ellipsizeMode={"tail"}
                >
                  {currentTab === 1
                    ? I18n.t("refill.payment") +
                      " - " +
                      historicalVM.getTypeOfPaymentLabel(title)
                    : I18n.t("refill.invoiceNumber", { invoiceNumber: title })}
                </Title16>
                <View style={styles.rowStyle2}>
                  <Title13 style={styles.date}>
                    {moment(new Date(historicDate)).format("DD/MM/yyyy")}
                    {haveTime && formattedHour}
                  </Title13>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.rowStyle2}>
            <Title16 style={styles.title}>{formattedAmount}</Title16>
            {currentTab === 0 && <Forward />}
          </View>
        </Pressable>
      </>
    );
  }
);

const _styles = (isMobile: boolean) =>
  StyleSheet.create({
    container: {
      marginTop: isMobile ? Spacing.M : Spacing.L,
      flex: 1,
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    rowStyle: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-start",
    },
    rowStyle2: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    infoDiv: {
      marginLeft: 0,
      justifyContent: "center",
      alignItems: "flex-start",
    },
    title: {
      fontWeight: "600",
    },
    date: {
      color: Colors.foodiBlack,
      marginRight: 10,
    },
    iconStyle: {
      marginRight: 10,
      justifyContent: "center",
    },
  });