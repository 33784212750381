import React, { useMemo, useState } from "react";
import { Pressable, StyleSheet } from "react-native";
import { Title14, Title18 } from "@stylesheets";
import { useDevices } from "@hooks";
import { TestIDs } from "@utils";
import { IExternalService } from "@foodi/core";
import _ from "lodash";

interface IProps {
  service: IExternalService;
  index: number;
}

export const ExternalServiceLink: React.FC<IProps> = React.memo(
  ({ service, index }) => {
    const [isMobile] = useDevices();
    const [isHover, setIsHover] = useState(false);
    const styles = useMemo(() => _styles(isHover), [isHover]);

    const TextLink: React.FC<any> = isMobile ? Title14 : Title18;

    const openServicePage = () => {
      if (!_.isEmpty(service.content))
        window.open(service.content, "_blank")?.focus();
    };

    return (
      <Pressable
        testID={`${TestIDs.restaurantDetail.actions.externalServiceLink}_${index}`}
        onPress={openServicePage}
        // @ts-ignore
        onHoverIn={() => setIsHover(true)}
        onHoverOut={() => setIsHover(false)}
      >
        <TextLink
          testID={`${TestIDs.restaurantDetail.texts.externalServiceLink}_${index}`}
          style={styles.textLink}
          isBold
          bold
        >
          {service.title}
        </TextLink>
      </Pressable>
    );
  }
);

const _styles = (isHover: boolean) =>
  StyleSheet.create({
    textLink: {
      textDecorationLine: isHover ? "none" : "underline",
    },
  });
