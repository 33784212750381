import { View, StyleSheet, Dimensions } from "react-native";
import React, { useMemo, useState } from "react";
import { CalendarDays, Cart } from "@atomic";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "@redux";
import { Title16, Title22, Title36 } from "@stylesheets";
import { I18n } from "react-redux-i18n";
import { Colors, Spacing } from "@constants";
import {
  CalendarFlow,
  OffersActions,
  OfferViewModel,
  PointOfSaleActions,
} from "@modules";
import { OfferProductsList } from "@atomic/organisms/OfferProductsList";
import { NavigationProp, Route } from "@react-navigation/native";
import { useDevices } from "@hooks";
import { FormulaApp, LocalLoader, OfferDescription } from "@atomic/molecules";
import { ActiveOffer, OfferTemplateSalesType } from "@foodi/core";
import _ from "lodash";
import { getNumericId } from '@utils';

interface IProps {
  navigation: NavigationProp<any>;
  route?: Route<any>;
  isRefillFromCart?: boolean;
  isOfferFetching: boolean;
}

const pageLateralPadding = 300;

export const OfferOderContainer: React.FC<IProps> = 
  ({ navigation, route, isRefillFromCart, isOfferFetching }) => {
    const currentPosIdFromParams = _.get(route, "params.id");
    const dispatch = useDispatch();
    const [isMobile] = useDevices();
    // we need to keep the cart component aligned with the end of the username in the page header
    // in order to do this we must change the component max width according to the window width
    const [listMaxWidth, setListMaxWidth] = useState(Dimensions.get('window').width - pageLateralPadding);

    Dimensions.addEventListener('change', ({window: { width }}) => {
      if(width-pageLateralPadding !== listMaxWidth){
        setListMaxWidth(width - pageLateralPadding);
      }
    });

    const styles = useMemo(() => _styles(isMobile, listMaxWidth), [isMobile, listMaxWidth]);

    const userLanguage = useSelector(
      (state: GlobalState) => state.auth?.userInfo?.language
    );
    const offers = useSelector((state: GlobalState) => state.offers);
    const selectedOffer =
      getNumericId(offers.selectedOffer?.pos?.id) === currentPosIdFromParams
        ? offers.selectedOffer
        : undefined;
    const selectedActiveOffer = offers.activeOrderableOffer;
    const orderItems = offers.orderItems;

    const cartCurrentInfo = useSelector(
      (state: GlobalState) => state.pointOfSale?.cartCurrentInfo
    );
    const [offerVM] = useState(new OfferViewModel());

    const offerFormulaItems = offerVM.convertFormulaItemsToProducts(selectedActiveOffer?.offerItemsFormula);

    const offerItems = offerVM.getGroupedProducts(
      // @ts-ignore
      [...(selectedActiveOffer?.offerItems || []), ...offerFormulaItems]
    );

    const selectedDay = useSelector(
      (state: GlobalState) => state.booking?.selectedDay ?? 0
    );

    const hasContainers = offerVM.hasContainers(
      selectedActiveOffer?.offerItems
    );

    const pointOfSale = useSelector((state: GlobalState) => {
      const selectedPos = state.pointOfSale?.selectedPos?.pointOfSale;
      if (
        selectedPos &&
        getNumericId(selectedPos.id) === currentPosIdFromParams
      )
        return selectedPos;
      return undefined;
    });

    React.useEffect(() => {
      if (selectedOffer && !isRefillFromCart) {
        dispatch(OffersActions.initOrderItems());
        dispatch(OffersActions.resetOutOfStockOrderItems());
        dispatch(OffersActions.resetOutOfStockOrderItemsFormula());
      }
      return () => {
        if (!isRefillFromCart) {
          dispatch(OffersActions.initOrderItems());
          dispatch(OffersActions.resetOutOfStockOrderItems());
          dispatch(OffersActions.resetOutOfStockOrderItemsFormula());
        }
      };
    }, [selectedOffer]);

    const isFormulaType =
      selectedOffer?.salesType === OfferTemplateSalesType.BUNDLE;
    const hasAvailableOffer = selectedOffer
      ? offerVM.hasAvailableOffer(selectedOffer)
      : false;

    React.useEffect(() => {
      if (!isRefillFromCart) {
        if (hasAvailableOffer)
          dispatch(PointOfSaleActions.setMiniCartStatus(true));
        else dispatch(PointOfSaleActions.setMiniCartStatus(false));
      }
    }, [hasAvailableOffer]);

    if (isOfferFetching) {
      return (
        <View style={styles.loaderContainer}>
          <LocalLoader containerStyle={{ backgroundColor: "transparent" }} />
        </View>
      );
    }

    return (
      <View style={styles.container}>
        {!!selectedOffer && (
          <View>
            <OfferDescription
              offerName={selectedOffer.name}
              offerDescription={selectedOffer.description}
              offerType={selectedOffer.withdrawalType}
            />
            {isFormulaType ? (
              <FormulaApp />
            ) :  (
              <>
                {(selectedOffer?.daysInAdvanceEnd !== 0 ||
                  selectedOffer?.isRoomService) && (
                  <CalendarDays
                    style={styles.calendarView}
                    isModify={false}
                    userLanguage={userLanguage}
                    calendarFlow={CalendarFlow.ORDER}
                    isRefillFromCart={isRefillFromCart}
                  />
                )}
                {selectedActiveOffer &&
                  offerVM.isActiveOfferReady(
                    selectedActiveOffer as ActiveOffer
                  ) && (
                    <View style={[styles.unavailable, { marginTop: 0 }]}>
                      <Title16 style={styles.unavailableText}>
                        {I18n.t("restaurantDetail.offerReady")}
                      </Title16>
                    </View>
                  )}
                {selectedActiveOffer &&
                  (offerVM.isActiveOfferReady(
                    selectedActiveOffer as ActiveOffer
                  ) ||
                    offerVM.isActiveOfferAvailable(
                      selectedActiveOffer as ActiveOffer
                    )) && (
                    <View style={styles.productsDiv}>
                      {!!offerItems && !!pointOfSale?.zone?.holding && (
                        <View style={styles.productsList}>
                          <OfferProductsList
                            holding={pointOfSale?.zone?.holding}
                            selectedDay={selectedDay}
                            offerItems={offerItems}
                            selectedActiveOfferId={selectedActiveOffer.id}
                            selectedOfferId={
                              selectedOffer.id ||
                              `${selectedOffer.nextOrderableOffer?.id}` ||
                              selectedOffer.name
                            }
                            mealHeartRule={selectedOffer.mealHeartRule}
                            orderItems={
                              isRefillFromCart
                                ? cartCurrentInfo?.products
                                : orderItems
                            }
                            hasContainers={hasContainers}
                          />
                        </View>
                      )}
                      {!isMobile &&
                        !offerVM.isActiveOfferReady(
                          selectedActiveOffer as ActiveOffer
                        ) && (
                          <View style={styles.cardDiv}>
                            <Cart
                              route={route}
                              navigation={navigation}
                              products={orderItems}
                              isRefillFromCart={isRefillFromCart}
                            />
                          </View>
                        )}
                    </View>
                  )}
                {selectedActiveOffer === null && (
                  <View style={[styles.unavailable, { marginTop: 0 }]}>
                    <Title16 style={styles.unavailableText}>
                      {I18n.t("restaurantDetail.unavailableOfferCalendar")}
                    </Title16>
                  </View>
                )}
              </>
            )}
          </View>
        )}
      </View>
    );
  };

const _styles = (isMobile: boolean, listMaxWidth: number) =>
  StyleSheet.create({
    container: {
      backgroundColor: Colors.background1,
      paddingBottom: 100,
    },
    unavailable: {
      marginTop: 45,
      flexDirection: "row",
      paddingLeft: isMobile ? 18 : 0,
      width: "100vw",
    },
    unavailableText: {
      marginLeft: 6,
    },
    productsDiv: {
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      flexWrap: "wrap",
      maxWidth: `${listMaxWidth}px`
    },
    cardDiv: {
      marginTop: 72,
      flexWrap: 'wrap',
      alignContent: 'flex-end'
    },
    productsList: {
      flex: 1,
      minWidth: '410px'
    },
    calendarView: {
      marginVertical: Spacing.XL,
      alignItems: "flex-start",
      paddingLeft: isMobile ? 18 : 0,
    },
    loaderContainer: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      alignContent: "center",
      minHeight: "500px",
    }
  });
