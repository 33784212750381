"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_EDENRED_DAILY_BALANCE = void 0;
const client_1 = require("@apollo/client");
exports.GET_EDENRED_DAILY_BALANCE = client_1.gql `
  query getEdenredDailyBalance($idToken: String!, $accessToken: String!) {
    getUserDailyBalance(idToken: $idToken, accessToken: $accessToken) {
      dailyBalance
    }
  }
`;
