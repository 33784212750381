"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MARKETING_CARD_FRAGMENT = void 0;
const client_1 = require("@apollo/client");
exports.MARKETING_CARD_FRAGMENT = client_1.gql `
  fragment marketingCardFragment on MarketingCard {
    id
    imageForeground
    imageBackground
    active
    style {
      display
      color
    }
    marketingType
    contentTitle
    contentSubtitle
    contentImage
    content
    title
    language
  }
`;
