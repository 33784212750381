"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateBookingOrder = void 0;
class CreateBookingOrder {
    constructor(orderRepository) {
        this.orderRepository = orderRepository;
    }
    execute(request) {
        return this.orderRepository.createBookingOrder(request);
    }
}
exports.CreateBookingOrder = CreateBookingOrder;
