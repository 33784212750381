import { INFO_NEWS_CARD_GAP } from "@atomic";
import { Dimensions } from "react-native";
import style from "styled-components/native";
import { RESTAURANT_CARD_WIDTH } from "../RestaurantCard/RestaurantCard.style";

const NEWS_CARD_GAP = 37;

const deviceWith = Dimensions.get('window').width;
const mobileNewsWidth = deviceWith - (deviceWith - RESTAURANT_CARD_WIDTH);

export const Row = style.View`
    flex-direction: row;
    margin-top: 24px;
`;

export const EmptyImageContainer = style.View`
    top: 12px;
    margin-bottom: ${NEWS_CARD_GAP}px;
`;

export const NewsContainer = style.View<{
  gap?: number;
  isMobile?: boolean;
}>`
    width: ${({ isMobile }) =>
      isMobile ? `${mobileNewsWidth}px` : "auto"};
    margin-right: ${({ gap, isMobile }) => (gap && !isMobile ? gap : 0)}px;
    margin-bottom: ${({ isMobile }) =>
      isMobile ? INFO_NEWS_CARD_GAP : NEWS_CARD_GAP}px;
`;
