import React from "react";
import { StyleSheet, TouchableOpacity, ViewStyle } from "react-native";
import { BigBlackArrowRight, CarouselArrowRight } from "@assets";

interface IProps {
  style?: ViewStyle;
  forwardTestID?: string;
  onPress?: Function;
  bigBlackArrow?: boolean;
  size?: number;
}

export const RightArrow: React.FC<IProps> = React.memo(
  ({ forwardTestID, onPress, style, bigBlackArrow, size }) => {
    return (
      <TouchableOpacity
        testID={forwardTestID}
        style={[styles.container, style]}
        onPress={() => onPress?.()}
      >
        {bigBlackArrow ? <BigBlackArrowRight /> : <CarouselArrowRight size={size} />}
      </TouchableOpacity>
    );
  }
);

const styles = StyleSheet.create({
  container: {},
});
