import {
  ApolloLink,
  HttpLink,
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import fetch from "cross-fetch";
import { onError } from 'apollo-link-error';
import links from "./links";
import errorHandler from './errorHandler';

let apolloClient: ApolloClient<NormalizedCacheObject>;

export const cache: InMemoryCache = new InMemoryCache();

const initializeApolloClient = (): void => {
  const httpLink = new HttpLink({
    // @ts-ignore
    uri: window.config.API_GRAPHQL_ENDPOINT,
    fetch,
  });

  const errorLink = onError(errorHandler.onError);

  apolloClient = new ApolloClient<NormalizedCacheObject>({
    cache,
    // @ts-ignore
    link: ApolloLink.from([errorLink,...links, httpLink]),
  });
};

const getApolloClient = (): ApolloClient<NormalizedCacheObject> => apolloClient;

export { getApolloClient, initializeApolloClient };
