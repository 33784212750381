"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuantityRuleType = void 0;
var QuantityRuleType;
(function (QuantityRuleType) {
    QuantityRuleType["mealQuantity"] = "mealQuantity";
    QuantityRuleType["orderPerDay"] = "orderPerDay";
    QuantityRuleType["productsPerOrder"] = "productsPerOrder";
    QuantityRuleType["sameProductPerOrder"] = "sameProductPerOrder";
})(QuantityRuleType = exports.QuantityRuleType || (exports.QuantityRuleType = {}));
