import { ThunkResult } from "@redux";

const ThunkActionCreators = {
  getToken: (
    params?: any
  ): ThunkResult<Promise<any>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { getToggleToken } = getDependencies();
      return getToggleToken.execute(params);
    } catch (e) {
      return Promise.reject(e);
    }
  },
};

export { ThunkActionCreators as ToggleThunks };
