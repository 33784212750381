"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_I18N_PROVIDER_LOCALE = void 0;
const client_1 = require("@apollo/client");
exports.GET_I18N_PROVIDER_LOCALE = client_1.gql `
  query getI18nProviderLocale {
    getUser {
      id
      language
    }
  }
`;
