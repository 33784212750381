import React from "react";
import { StyleSheet } from "react-native";
import { ToastContainer } from "react-toastify";

interface IProps {}

export const Toast: React.FC<IProps> = React.memo(({}) => {
  return (
    <ToastContainer
      autoClose={5000}
      position="top-right"
      className="toast-container"
      style={styles.container}
    />
  );
});

const styles = StyleSheet.create({
  container: {
    width: "100vw",
    maxWidth: 500,
    justifyContent: "flex-end",
    marginTop: 50,
  },
});
