import { EdenredToken, ILanguage } from "@foodi/core";
import { hasTokenExpired } from "@utils";
import { Dispatch } from "redux";
import { PaymentsThunks, PaymentsActions } from "..";

export class EdenredPaymentViewModel {
  constructor(private dispatch: Dispatch<any>) {}

  storeEdenredToken(edenredToken: EdenredToken) {
    const accessTokenExpiresAt = new Date();
    accessTokenExpiresAt.setSeconds(
      accessTokenExpiresAt.getSeconds() + Number(edenredToken.expiresIn)
    );

    const refreshTokenExpiresAt = new Date();
    refreshTokenExpiresAt.setDate(refreshTokenExpiresAt.getDate() + 30); // refresh tokens expire in 30 days

    this.dispatch(
      PaymentsActions.setEdenredToken({
        ...edenredToken,
        accessTokenExpiresAt,
        refreshTokenExpiresAt,
      } as any)
    );
  }

  async getEdenredBalance(edenredToken: EdenredToken) {
    const edenredBalance = await this.dispatch(
      PaymentsThunks.getEdenredDailyBalance(
        edenredToken.idToken,
        edenredToken.accessToken
      )
    );
    //@ts-ignore
    this.dispatch(PaymentsActions.setEdenredBalance(edenredBalance));
  }

  getEdenredToken(edenredAuthorizationCode: string) {
    return this.dispatch(
      PaymentsThunks.generateEdenredTokens(
        edenredAuthorizationCode,
        undefined,
        true
      )
    );
  }

  async edenredAuthentication(language: string | undefined) {
    //@ts-ignore
    const url: string = await this.dispatch(
      PaymentsThunks.getEdenredAuthorizationURL(
        language === ILanguage.fr ? "fr-FR" : "en-US"
      )
    );

    const newUrl = new URL(url);
    const search_params = newUrl.searchParams;

    search_params.set(
      "redirect_uri",
      `${window.location.origin}/edenred/login-callback`
    );

    newUrl.search = search_params.toString();

    const new_url = newUrl.toString();

    window?.open?.(new_url, "_blank"); // open the edenred authentication page
  }

  async fetchEdenredToken(edenredAuthorizationCode: string) {
    const edenredToken = (await this.getEdenredToken(
      edenredAuthorizationCode
    )) as any;
    this.storeEdenredToken(edenredToken);
    this.getEdenredBalance(edenredToken);
  }

  async renewEdenredToken(
    edenredToken: EdenredToken
  ): Promise<EdenredToken | null> {
    if (!hasTokenExpired(edenredToken.accessTokenExpiresAt!)) {
      // return tokens, access token is still valid
      return edenredToken;
    }

    if (hasTokenExpired(edenredToken.refreshTokenExpiresAt!)) {
      // refresh token expired, requires new authentication verification
      return null;
    }

    // refresh token hasn't yet expired - try to renew it
    const renewedToken = (await this.dispatch(
      PaymentsThunks.generateEdenredTokens(
        undefined,
        edenredToken.refreshToken,
        true
      )
    )) as any;

    if (!renewedToken) {
      // failed to renewed the token, requires new authentication verification
      return null;
    }

    this.storeEdenredToken(renewedToken);
    this.getEdenredBalance(renewedToken);

    return renewedToken;
  }
}
