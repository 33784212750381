"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkTablesAvailabilityFixture = void 0;
exports.checkTablesAvailabilityFixture = {
    idPos: 1092,
    label: "LABEL",
    tables: [
        {
            status: 1,
            tableNumber: 3,
        },
        {
            status: 1,
            tableNumber: 12,
        }
    ]
};
