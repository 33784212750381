"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ADD_EPOINTAGE_SCHEDULER = void 0;
const client_1 = require("@apollo/client");
exports.ADD_EPOINTAGE_SCHEDULER = client_1.gql `
mutation addEPointageScheduler (
  $siteId: String!
  $etaId: String!
  $claId: String!
  $scheduleId: Int!
  $isInvoice: Boolean
  $scheduleNumberDay: Int
  $scheduleDeadline: String
  $checkingDay: String!
  $updateUser: Int!
  $updateDate: String!
  $students: [EPointageSchedulerStudentsInput]
){
  scheduler: addEPointageScheduler(
      siteId: $siteId
      etaId: $etaId
      claId: $claId
      scheduleId: $scheduleId
      isInvoice: $isInvoice
      scheduleNumberDay: $scheduleNumberDay
      scheduleDeadline: $scheduleDeadline
      checkingDay: $checkingDay
      updateUser: $updateUser
      updateDate: $updateDate
      students: $students
  ){
      scheduleId
      isInvoice
      scheduleNumberDay
      scheduleDeadline
      checkingDay
      updateUser
      updateDate
  }
}
`;
