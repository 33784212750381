import { IOptinCode, IUserOptin, IOptinOptions } from "@foodi/core";
import _ from "lodash";

export class OptinsViewModel {
  static initOptins(
    allOptins: { [key in IOptinCode]: { enabled: boolean, options?: IOptinOptions} },
    selectedOptins: IUserOptin[]
  ) {
    const _allOptins = _.cloneDeep(allOptins);
    selectedOptins.forEach((userOptin) => (_allOptins[userOptin.optin.code] = {
      enabled: true,
      options: userOptin.optinOptions ? _.omit(userOptin.optinOptions, '__typename') : undefined
    }));
    _allOptins.FOODI_TOS = { enabled: true };
    return _allOptins;
  }

  static getSelectedOptins(
    allOptins: { [key in IOptinCode]: { enabled: boolean; options?: IOptinOptions} }
  ): {code: IOptinCode, options?: IOptinOptions }[] {
    return Object.entries<any>(allOptins)
      .filter(([code, value]) => value.enabled)
      .map(([code, value]: any) => ({ code, options: value.options }))
      .flat();
  }

  static userConsentedToOptin(optins: IUserOptin[], desiredOptinCode: IOptinCode): boolean {
    return optins.some((o) => o.optin.code === desiredOptinCode);
  }
}
