import { Colors } from "@constants";
import styled from "styled-components/native";

export const Container = styled.View`
    z-index: 1;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vW;
    height: 60px;
    background-color: ${Colors.black};
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding-left: 20px;
    padding-right: 20px;
`;

export const DisabledCart = styled.View`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 60px;
    width: 100vw;
    z-index: 2;
    background-color: ${Colors.disabledBackground};
    opacity: 0.9;
`;

export const GoProfileContainer = styled.View`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 60px;
    width: 100vW;
    height: 110px;
    width: 100vw;
    z-index: 1;
    background-color: ${Colors.white};
`;

export const Quantity = styled.View`
    width: 35px;
    height: 29px;
    border-radius: 8px;
    background-color: ${Colors.lightGray};
    text-align: center;
    justify-content: center;
    margin-right: 20px;
`;

export const Info = styled.View`
    flex-direction: row;  
    text-align: center;
    align-items: center;
`;

