import React from "react";
import { I18n } from "react-redux-i18n";
import { TestIDs } from "@utils";
import {
  Container,
  FooterText,
  Gap,
  Row,
  IconAndTextContainer,
  Copyright,
  CopyrightText,
  Dot,
} from "./EpointageFooter.style";

export const EpointageFooter: React.FC = React.memo(() => {
  const handleLegalInformation = () => {
    window.open(window.config.CGU, "_blank")?.focus();
  };

  const handlePersonalDataPolicy = () => {
    window.open(window.config.PUD, "_blank")?.focus();
  };

  const handleCookiesPolicy = () => {
    window.open(window.config.COOKIES_POLICIES, "_blank")?.focus();
  };

  /* istanbul ignore next */
  const handleCookiesParameters = () => {
    // this is required because we need to force the reload of one trust functions to be able to display the cookies preferences on click
    (window as any)?.reloadOTBanner?.();
  };

  return (
    <Container testID={TestIDs.home.footer.views.footerContainer}>
      <Row>
        <Gap>
          <Row>
            <Copyright>
              <CopyrightText>c</CopyrightText>
            </Copyright>
            <FooterText> Compass Group France 2023</FooterText>
          </Row>
        </Gap>
        <Gap>
          <Dot />
        </Gap>
        <IconAndTextContainer
          testID={TestIDs.home.footer.actions.legalPage}
          onPress={handleLegalInformation}
          haveBreakLine
        >
          <FooterText>{`${I18n.t("homepage.footer.legal")}`}</FooterText>
        </IconAndTextContainer>
      </Row>
      <Row>
        <IconAndTextContainer
          testID={TestIDs.home.footer.actions.legalPage}
          onPress={handleLegalInformation}
        >
          <FooterText>{`${I18n.t("homepage.footer.information")}`}</FooterText>
        </IconAndTextContainer>
        <Gap>
          <Dot />
        </Gap>
        <IconAndTextContainer
          testID={TestIDs.home.footer.actions.personalDataPolicy}
          onPress={handlePersonalDataPolicy}
        >
          <FooterText>
            {I18n.t("homepage.footer.personalDataPolicy")}
          </FooterText>
        </IconAndTextContainer>
        <Gap>
          <Dot />
        </Gap>
      </Row>
      <Row>
        <IconAndTextContainer
          testID={TestIDs.home.footer.actions.cookiesPolicy}
          onPress={handleCookiesPolicy}
        >
          <FooterText>{I18n.t("homepage.footer.cookiesPolicy")}</FooterText>
        </IconAndTextContainer>
        <Gap>
          <Dot />
        </Gap>
        <div
          className="ot-sdk-show-settings-element"
          onMouseOver={handleCookiesParameters}
        >
          <IconAndTextContainer
            testID={TestIDs.home.footer.actions.cookiesParameters}
          >
            <FooterText>
              {I18n.t("homepage.footer.cookiesParameters")}
            </FooterText>
          </IconAndTextContainer>
        </div>
      </Row>
    </Container>
  );
});
