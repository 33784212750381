import React, { useMemo, useState } from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import { Title18 } from "@stylesheets";
import { useSelector } from "react-redux";
import { GlobalState } from "@redux";
import { Colors } from "@constants";
import { responsiveSizeWidth, useDevices } from "@hooks";
import { CalendarViewModel } from "@modules";

interface IProps {
  index: number;
  forwardTestID?: string;
  onSelect?: Function;
  isSelected?: boolean;
  isDisabled?: boolean;
}

const areEquals = (prevProps: IProps, nextProps: IProps) =>
  prevProps.isSelected === nextProps.isSelected &&
  prevProps.isDisabled === nextProps.isDisabled;

export const CalendarDay: React.FC<IProps> = React.memo(
  ({
    forwardTestID,
    onSelect,
    isSelected = false,
    isDisabled = false,
    index,
  }) => {
    const language = useSelector(
      (state: GlobalState) => state.auth?.userInfo?.language
    );
    const [CalendarVM] = useState<CalendarViewModel>(new CalendarViewModel());
    const { weekDayName, dayNumber } = CalendarVM.getDateInfo(index, language);
    const [isMobile] = useDevices();
    const brandTheme = useSelector(
      (state: GlobalState) => state.brandTheme.brandTheme
    );

    const backgroundColor = isSelected
      ? isDisabled
        ? Colors.disabledBackground
        : brandTheme.buttonActionColors?.backgroundColor || Colors.foodiDefault
      : Colors.white;

    const color = isSelected
      ? isDisabled
        ? Colors.darkGrey
        : brandTheme.buttonActionColors?.textColor || Colors.foodiBlack
      : isDisabled
      ? Colors.darkGrey
      : Colors.foodiBlack;

    const styles = useMemo(
      () => _styles(!!isSelected, !!isDisabled, isMobile),
      [isSelected, isDisabled, isMobile]
    );

    return (
      <TouchableOpacity
        style={[styles.container, { backgroundColor }]}
        testID={forwardTestID}
        onPress={() => onSelect?.(index)}
      >
        <Title18 style={[styles.title, styles.fontSize, { color }]}>
          {dayNumber}
        </Title18>
        <Title18 style={[styles.title, styles.fontSize, { color }]}>
          {weekDayName}
        </Title18>
      </TouchableOpacity>
    );
  },
  areEquals
);

const _styles = (isSelected: boolean, isDisabled: boolean, isMobile: boolean) =>
  StyleSheet.create({
    container: {
      height: 50,
      width: isMobile ? responsiveSizeWidth(75) : 100,
      justifyContent: "center",
      alignItems: "center",
    },
    title: {
      fontFamily: isSelected ? "manrope-bold" : "manrope",
      lineHeight: 20,
      color: isDisabled ? Colors.darkGrey : Colors.foodiBlack,
    },
    fontSize: {
      fontSize: isMobile ? responsiveSizeWidth(18) : 18,
    },
  });
