"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetBrandTheme = void 0;
class GetBrandTheme {
    constructor(brandThemeGateway) {
        this.brandThemeGateway = brandThemeGateway;
    }
    execute(request) {
        return this.brandThemeGateway.getBrandTheme(request);
    }
}
exports.GetBrandTheme = GetBrandTheme;
