import { Action } from "../action";
import { createReducer } from "@redux/createReducer";

export interface GetAppMobileState {
  showGetAppMobile: boolean;
}

/*************  Actions  ****************/

const ActionTypes = {
  SET_ACTIVE: "getAppMobile/SET_ACTIVE",
};

const ActionCreators = {
  setActive: (showGetAppMobile: boolean): Action<boolean> => ({
    type: ActionTypes.SET_ACTIVE,
    payload: showGetAppMobile,
  }),
};

/*************  Reducer  ****************/

const initialState: GetAppMobileState = {
  showGetAppMobile: true,
};

const Reduction = {
  setActive: (state: GetAppMobileState, { payload: showGetAppMobile }: Action<boolean>) => ({
    showGetAppMobile,
  }),
};

const reducer = createReducer(initialState, {
  [ActionTypes.SET_ACTIVE]: Reduction.setActive,
});

export default reducer;

export {
  reducer as GetAppMobileReducer,
  ActionTypes as GetAppMobileActionTypes,
  ActionCreators as GetAppMobileActions,
};
