import {
  DeleteAccountModal,
  DeleteAccountView,
  ProfileMenu,
  ScreenWithFooter,
} from "@atomic";
import { Colors } from "@constants";
import {
  AccountScreen,
  SettingsScreen,
  LegalInfoScreen,
  OrdersScreen,
} from "@modules/profile";
import { NavigationProp, Route } from "@react-navigation/core";
import { GlobalState } from "@redux";
import React, { useEffect, useMemo, useState } from "react";
import { StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";
import { useDevices } from "@hooks";
import { HomeScreens } from "@modules/home/navigation/HomeScreens";

interface IProps {
  navigation: NavigationProp<any>;
  route?: Route<any>;
}

export enum ProfileTabs {
  ACCOUNT = "account",
  ORDERS = "orders",
  SETTINGS = "settings",
  LEGAL_INFORMATION = "legal-information",
  HELP = "help",
  LOGOUT = "logout",
}

export const ProfileScreen: React.FC<IProps> = React.memo(
  ({ navigation, route }) => {
    const { tab = ProfileTabs.ACCOUNT, showLeftContainerFlag = true }: any =
      route?.params || {};
    const [isMobile] = useDevices();

    const userLanguage = useSelector(
      (state: GlobalState) => state.auth?.userInfo?.language
    );

    const [selectedTab, setSelectedTab] = useState<ProfileTabs>(tab);
    const [showLeftContainer, setShowLeftContainer] = useState<boolean>(
      showLeftContainerFlag === null ? true : showLeftContainerFlag
    );

    const isUserLogin = useSelector((state: GlobalState) => state.auth.isLogin);

    const styles = useMemo(() => _styles(isMobile), [isMobile]);

    const handleChangeTab = (tab: ProfileTabs) => {
      setSelectedTab(tab);
      if (isMobile) setShowLeftContainer(false);
    };

    useEffect(() => {
      if (!isUserLogin) {
        navigation?.navigate(HomeScreens.HOME_SCREEN);
      }
    }, [userLanguage]);

    return (
      <ScreenWithFooter navigation={navigation}>
        <View style={styles.container}>
          <View style={styles.subContainer}>
            <ProfileMenu
              callback={handleChangeTab}
              selectedTab={selectedTab}
              navigation={navigation}
              showLeftContainer={showLeftContainer}
            />
            {(!isMobile || (isMobile && !showLeftContainer)) && (
              <View style={styles.leftContainer}>
                {selectedTab === ProfileTabs.ACCOUNT && (
                  <View>
                    <AccountScreen
                      handleShowLeftContainer={() => setShowLeftContainer(true)}
                      navigation={navigation}
                    />
                    {isMobile && <View style={styles.separator} />}
                    <DeleteAccountView />
                    <DeleteAccountModal navigation={navigation} />
                  </View>
                )}
                {selectedTab === ProfileTabs.ORDERS && (
                  <View>
                    <OrdersScreen
                      handleShowLeftContainer={() => setShowLeftContainer(true)}
                      navigation={navigation}
                    />
                  </View>
                )}
                {selectedTab === ProfileTabs.SETTINGS && (
                  <SettingsScreen
                    handleShowLeftContainer={() => setShowLeftContainer(true)}
                    navigation={navigation}
                  />
                )}
                {selectedTab === ProfileTabs.LEGAL_INFORMATION && (
                  <LegalInfoScreen
                    handleShowLeftContainer={() => setShowLeftContainer(true)}
                  />
                )}
              </View>
            )}
          </View>
        </View>
      </ScreenWithFooter>
    );
  }
);

const _styles = (isMobile: boolean) =>
  StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: Colors.white,
    },
    subContainer: {
      flex: 1,
      marginHorizontal: isMobile ? 18 : 150,
      marginVertical: isMobile ? 20 : 40,
      justifyContent: "space-between",
      alignItems: "flex-start",
      flexDirection: "row",
    },
    leftContainer: {
      flex: 1,
      marginLeft: isMobile ? 0 : 80,
    },
    separator: {
      width: "100%",
      height: 1,
      backgroundColor: Colors.darkGrey,
      marginVertical: 15,
    },
  });
