"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateUserHoldingView = void 0;
class UpdateUserHoldingView {
    constructor(holdingRepository) {
        this.holdingRepository = holdingRepository;
    }
    execute(request) {
        return this.holdingRepository.updateUserHoldingView(request);
    }
}
exports.UpdateUserHoldingView = UpdateUserHoldingView;
