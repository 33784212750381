import { Colors } from "@constants";
import style from "styled-components/native";

export const Wrapper = style.View`
    width: 100%;
    height: 100%;
    zIndex: 2;
    background-color: ${Colors.white};
    position: absolute;
`;
