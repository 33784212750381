import { Colors } from "@constants";
import { getCloudImageUrl } from "@utils";
import React, { useMemo } from "react";
import { StyleSheet, Image, ImageStyle, View, PixelRatio } from "react-native";
import { useDevices } from "@hooks";
import { Images } from "@assets";

interface IProps {
  imagePath: string;
  style?: ImageStyle;
  forwardTestID?: string;
  width: number;
}

export const FastImage: React.FC<IProps> = React.memo(
  ({ imagePath, style, forwardTestID, width }) => {
    const uri = imagePath
      ? getCloudImageUrl(
          imagePath,
          "width",
          String(PixelRatio.getPixelSizeForLayoutSize(width))
        )
      : Images.defaultPosImg;

    const [isMobile] = useDevices();
    const styles = useMemo(() => _styles(isMobile), [isMobile]);

    return (
      <View style={[styles.container, style]}>
        <Image
          testID={forwardTestID}
          style={[styles.container, style]}
          source={{
            uri: uri,
          }}
        ></Image>
      </View>
    );
  }
);

const _styles = (isMobile: boolean) =>
  StyleSheet.create({
    container: {
      width: isMobile ? 414 : 481,
      height: isMobile ? 166 : 276,
      borderRadius: isMobile ? 0 : 8,
    },
  });
