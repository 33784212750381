"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetCartSummaryAdmissionAndPrice = void 0;
class GetCartSummaryAdmissionAndPrice {
    constructor(orderRepository) {
        this.orderRepository = orderRepository;
    }
    execute(request) {
        return this.orderRepository.cartSummaryAdmissionAndPrice(request);
    }
}
exports.GetCartSummaryAdmissionAndPrice = GetCartSummaryAdmissionAndPrice;
