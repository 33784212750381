import { Checked } from "@assets";
import { TestIDs } from "@utils";
import React from "react";
import { View } from "react-native";
import { Container } from "./EPointageCheckbox.style";
import { Colors } from "@constants";
import { ScheduleCheckboxStyle } from "@modules";

interface IProps {
  checked?: boolean;
  forwardTestID?: string;
  onPress?: () => void;
  checkboxStyle?: ScheduleCheckboxStyle;
  showCheck?: boolean;
  isPreckNeeded?: boolean;
  havePointer?: boolean;
}

export const EPointageCheckbox: React.FC<IProps> = React.memo(
  ({
    checked = false,
    forwardTestID,
    onPress,
    checkboxStyle,
    showCheck = true,
    isPreckNeeded = false,
    havePointer,
  }) => {
    return (
      <Container
        testID={forwardTestID}
        havePointer={havePointer}
        onPress={onPress}
        borderColor={
          checked
            ? isPreckNeeded
              ? Colors.epointageOrange
              : checkboxStyle?.checked.borderColor
            : checkboxStyle?.unChecked.borderColor
        }
        backgroundColor={
          checked
            ? isPreckNeeded
              ? Colors.epointageOrange
              : checkboxStyle?.checked.backgroundColor
            : checkboxStyle?.unChecked.backgroundColor
        }
      >
        {checked && showCheck && (
          <View testID={TestIDs.components.Checkbox.views.checkedIcon}>
            <Checked width={15} height={15} color={Colors.white} />
          </View>
        )}
      </Container>
    );
  }
);
