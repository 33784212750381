"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_POS = void 0;
const client_1 = require("@apollo/client");
const pointsOfSaleFragment2_1 = require("./pointsOfSaleFragment2");
exports.GET_POS = client_1.gql `
  ${pointsOfSaleFragment2_1.POINTS_OF_SALE_FRAGMENT_2}
  query getPos($id: ID!, $days: Int!) {
    getPos(id: $id) {
      ...pointsOfSaleFragment2
      menus(days: $days) {
        day
        elements {
          id
          label
          description
          idElement
          price {
            amount
            currency
          }
          dish {
            id
            dishGroup {
              id
              label
            }
          }
          allergens
          certifications
          products(main: false) {
            main
            id
            idRecipe
            label
            certifications
          }
        }
      }
      attendance {
        current {
          time
          count
        }
        prediction {
          values {
            time
            count
          }
          minWeekCount
          maxWeekCount
          intervalMinutes
        }
      }
    }
  }
`;
