"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetUserInfoForTracking = void 0;
class GetUserInfoForTracking {
    constructor(userRepository) {
        this.userRepository = userRepository;
    }
    execute(callback) {
        return this.userRepository.getUserInfoForTracking(callback);
    }
}
exports.GetUserInfoForTracking = GetUserInfoForTracking;
