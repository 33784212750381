import { Action } from "../action";
import { createReducer } from "@redux/createReducer";

export interface HeaderState {
  haveOpacity: boolean;
}

/*************  Actions  ****************/

const ActionTypes = {
  SET_HEADER_OPACITY: "header/SET_HEADER_OPACITY",
};

const ActionCreators = {
  setHeaderOpacity: (haveOpacity: boolean): Action<boolean> => ({
    type: ActionTypes.SET_HEADER_OPACITY,
    payload: haveOpacity,
  }),
};

/*************  Reducer  ****************/

const initialState: HeaderState = {
  haveOpacity: false,
};

const Reduction = {
  setHeaderOpacity: (state: HeaderState, { payload: haveOpacity }: Action<boolean>) => ({
    haveOpacity,
  }),
};

const reducer = createReducer(initialState, {
  [ActionTypes.SET_HEADER_OPACITY]: Reduction.setHeaderOpacity,
});

export default reducer;

export {
  reducer as HeaderReducer,
  ActionTypes as HeaderActionTypes,
  ActionCreators as HeaderActions,
};
