import { useNavigation, useRoute } from "@react-navigation/core";
import React, { useEffect } from "react";
import { View } from "react-native";
import { RouteProp } from "@react-navigation/native";
import { useDispatch, useSelector } from "react-redux";
import { AuthActions, AuthThunks } from "@modules/auth";
import { GlobalState, LoaderActions } from "@redux";
import { HomeScreens } from "@modules";
import { hasAccessTokenExpired, isAccountActivated } from "@utils";
import { Token } from "@foodi/core";

export const IDPCallback: React.FC<any> = React.memo(({ navigation }) => {
  const route: RouteProp<{ params: { code: string } }, "params"> = useRoute();

  const accessToken = useSelector(
    (state: GlobalState) => state.auth?.authToken?.accessToken
  );
  const hasTokenExpired = hasAccessTokenExpired(accessToken);
  const dispatch = useDispatch();

  useEffect(() => {
    if (hasTokenExpired) {
      //@ts-ignore
      dispatch(AuthActions.setAuthToken({}));
    }
    (async () => {
      setTimeout(async () => {
        const { params } = route ?? {};
        if (params && params.code) {
          dispatch(LoaderActions.setLoading(true));
          const authToken: any = await dispatch(
            AuthThunks.loginIDP(params.code)
          );
          if (!isAccountActivated(authToken.accessToken ?? "")) {
            dispatch(AuthActions.setLoginStatus(true));
            dispatch(AuthActions.setIDPConnexionStatus(true));
          }
          dispatch(LoaderActions.setLoading(false));
          navigation.navigate(HomeScreens.HOME_SCREEN);
        }
      }, 100);
    })();
  }, []);

  return null;
});
