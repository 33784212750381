import { createReducer } from "@redux";
import { Action } from "@redux/action";

export interface IConfirmationPageParams {
  id?: string;
  orderId?: string;
  isFirstTime?: boolean;
  isModify? : boolean;
  isRefillFromCart?: boolean;
  idPos?: any;
  offerSlot?: string | null;
}

export interface ConfirmationPageState {
  params: IConfirmationPageParams;
}

/*************  Actions  ****************/

const ActionTypes = {
  SET_CONFIRMATION_PAGE_PARAMS: "ConfirmationPage/SET_CONFIRMATION_PAGE_PARAMS",
};

const ActionCreators = {
  setConfirmationPageParams: (params: IConfirmationPageParams): Action<IConfirmationPageParams> => ({
    type: ActionTypes.SET_CONFIRMATION_PAGE_PARAMS,
    payload: params,
  }),
};

/*************  Reducer  ****************/

const initialState: ConfirmationPageState = {
  params: {
    id: "",
    orderId: "",
    isFirstTime: false,
    isModify: false,
    isRefillFromCart: false,
    idPos: -1,
    offerSlot: "",
  }
};

const Reduction = {
  setConfirmationPageParams: (
    state: ConfirmationPageState,
    { payload: params }: Action<IConfirmationPageParams>
  ): ConfirmationPageState => ({
    ...state,
    params,
  }),
};

const reducer = createReducer(initialState, {
  [ActionTypes.SET_CONFIRMATION_PAGE_PARAMS]: Reduction.setConfirmationPageParams,
});

export default reducer;

export {
  reducer as ConfirmationPageReducer,
  ActionTypes as ConfirmationPageActionTypes,
  ActionCreators as ConfirmationPageActions,
};
