"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RECENT_ORDERS_TO_RATE = void 0;
const client_1 = require("@apollo/client");
exports.RECENT_ORDERS_TO_RATE = client_1.gql `
    query getAvailableOrdersToRate {
      orders: getAvailableOrdersToRate {
        id
        idHuman
        state
        withdrawRange
        withdrawLocation
        updated
      }
    }
`;
