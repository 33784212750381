"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_HOLDING_WITH_IS_RECIPE_ENABLED = void 0;
const client_1 = require("@apollo/client");
exports.GET_HOLDING_WITH_IS_RECIPE_ENABLED = client_1.gql `
  query getHoldingWithIsRecipeEnabled {
    getUser {
      id
      guests {
        edges {
          node {
            id
            holding {
              id
              isRecipeDetailsEnabled
            }
          }
        }
      }
    }
  }
`;
