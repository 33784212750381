"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetToggleToken = void 0;
class GetToggleToken {
    constructor(toggleService) {
        this.toggleService = toggleService;
    }
    execute(variables) {
        return this.toggleService.getToggleToken(variables);
    }
}
exports.GetToggleToken = GetToggleToken;
