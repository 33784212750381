"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateVirtualTicket = void 0;
class UpdateVirtualTicket {
    constructor(profileService) {
        this.profileService = profileService;
    }
    execute(request) {
        return this.profileService.updateVirtualTicket(request);
    }
}
exports.UpdateVirtualTicket = UpdateVirtualTicket;
