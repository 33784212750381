import React, { useMemo } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { Colors } from "@constants";
import { Title18 } from "@stylesheets";
import { ProfileTabs } from "@modules/profile";
import { useDevices } from "@hooks";
import { Forward } from "@assets";

interface IProps {
  tabName: ProfileTabs;
  title: string;
  icon: React.ReactNode;
  isSelected: boolean;
  onSelectedTab: (tab: ProfileTabs) => void;
  forwardTestID?: string;
  showForward?: boolean;
}

export const ProfileMenuTab: React.FC<IProps> = React.memo(
  ({
    tabName,
    title,
    forwardTestID,
    icon,
    isSelected,
    onSelectedTab,
    showForward,
  }) => {
    const [isMobile] = useDevices();
    const onSelect = () => {
      onSelectedTab(tabName);
    };

    const styles = useMemo(() => _styles(isMobile), [isMobile]);

    return (
      <TouchableOpacity
        testID={forwardTestID}
        style={styles.container}
        onPress={onSelect}
      >
        <View style={styles.subContainer}>
          {icon}
          <Title18 style={[styles.title, isSelected && styles.selectedStyle]}>
            {title}
          </Title18>
        </View>

        {isMobile && showForward && <Forward />}
      </TouchableOpacity>
    );
  }
);

const _styles = (isMobile: boolean) =>
  StyleSheet.create({
    title: {
      color: Colors.black,
      marginLeft: 10,
    },
    container: {
      justifyContent: isMobile ? "space-between" : "center",
      alignItems: "center",
      flexDirection: "row",
      width: isMobile ? "90vw" : "auto",
    },
    subContainer: {
      flexDirection: "row",
    },
    selectedStyle: {
      fontWeight: isMobile ? "normal" : "bold",
    },
  });
