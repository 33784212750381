import React from "react";

export const Search: React.FC = () => (
  <svg version="1.1" width="24px" height="24px" viewBox="0 0 24.0 24.0" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
      <clipPath id="i0">
        <path d="M414,0 L414,736 L0,736 L0,0 L414,0 Z"></path>
      </clipPath>
      <clipPath id="i1">
        <path d="M8.56666667,0 C10.9222222,0 12.9277778,0.833333333 14.5833333,2.5 C16.2388889,4.16666667 17.0666667,6.17777778 17.0666667,8.53333333 C17.0666667,9.48888889 16.9111111,10.4111111 16.6,11.3 C16.2888889,12.1888889 15.8222222,13.0222222 15.2,13.8 L24,22.5333333 L22.5333333,24 L13.7666667,15.2333333 C13.1,15.8111111 12.3222222,16.2611111 11.4333333,16.5833333 C10.5444444,16.9055556 9.6,17.0666667 8.6,17.0666667 C6.2,17.0666667 4.16666667,16.2333333 2.5,14.5666667 C0.833333333,12.9 0,10.8888889 0,8.53333333 C0,6.17777778 0.833333333,4.16666667 2.5,2.5 C4.16666667,0.833333333 6.18888889,0 8.56666667,0 Z M8.56666667,2 C6.74444444,2 5.19444444,2.63888889 3.91666667,3.91666667 C2.63888889,5.19444444 2,6.73333333 2,8.53333333 C2,10.3333333 2.63888889,11.8722222 3.91666667,13.15 C5.19444444,14.4277778 6.74444444,15.0666667 8.56666667,15.0666667 C10.3666667,15.0666667 11.9,14.4277778 13.1666667,13.15 C14.4333333,11.8722222 15.0666667,10.3333333 15.0666667,8.53333333 C15.0666667,6.73333333 14.4333333,5.19444444 13.1666667,3.91666667 C11.9,2.63888889 10.3666667,2 8.56666667,2 Z"></path>
      </clipPath>
    </defs>
    <g transform="translate(-361.0 -170.0)">
      <g clipPath="url(#i0)">
        <g transform="translate(361.0 170.0)">
          <g clipPath="url(#i1)">
            <polygon points="0,0 24,0 24,24 0,24 0,0" stroke="none" fill="#000000"></polygon>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
