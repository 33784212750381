import React from "react";
import { I18n } from "react-redux-i18n";

export const OrdersSVG: React.FC = () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{I18n.t("profile.myOrders")}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        d="M22 9h-4.79l-4.38-6.56a.993.993 0 0 0-.83-.42c-.32 0-.64.14-.83.43L6.79 9H2c-.55 0-1 .45-1 1 0 .09.01.18.04.27l2.54 9.27c.23.84 1 1.46 1.92 1.46h13c.92 0 1.69-.62 1.93-1.46l2.54-9.27L23 10c0-.55-.45-1-1-1ZM12 4.8 14.8 9H9.2L12 4.8ZM18.5 19l-12.99.01L3.31 11H20.7l-2.2 8Z"
        fill="#000"
        fillRule="nonzero"
      />
      <path
        fill="#000"
        fillRule="nonzero"
        d="m13.266 14.233 1.977-.66L10 12l1.573 5.243.66-1.977 2.239 2.239 1.038-1.038z"
      />
    </g>
  </svg>
);
