import React from "react";
import { setLocale } from "react-redux-i18n";
import { useDispatch, useSelector } from "react-redux";

import { FlagFR, FlagUK } from "@assets";
import { Colors } from "@constants";
import { TestIDs } from "@utils";
import { ForceInfoAppUpdateActions, GlobalState } from "@redux";
import { Pressable, View, StyleSheet } from "react-native";
import { ILanguage } from "@foodi/core";
import { AuthThunks, ProfileThunks } from "@modules";

interface IProps {
  forwardTestID?: string;
  dismiss: (value: boolean) => void;
  language?: ILanguage | undefined;
  onLanguageChange: (value: ILanguage) => void;
}

export const LanguageToolTip: React.FC<IProps> = React.memo(
  ({ dismiss, language, onLanguageChange, forwardTestID }) => {
    const isLogin = useSelector((state: GlobalState) => state.auth?.isLogin);

    const languageOrLocale = useSelector(
      (state: GlobalState) =>
        state.auth?.userInfo?.language || state.i18n?.locale
    );

    const dispatch = useDispatch();

    const updateLanguage = async () => {
      dismiss(false);

      // change language in One Trust and reload banner, so cookies banner and preferences render in the new language
      // @ts-ignore
      window.OneTrust.changeLanguage(language!);
      // @ts-ignore
      window.reloadOTBanner();

      if(isLogin){
        await dispatch(ProfileThunks.updateLanguage({language : language!}));
        await dispatch(setLocale(language!));
        await dispatch(AuthThunks.getUserInfo())
        await dispatch(ForceInfoAppUpdateActions.setForceAppUpdate(true));
      }
      else if(!isLogin){
        await dispatch(setLocale(language!));
      }
    };

    React.useEffect(() => {
      if (language && languageOrLocale != language) {
        updateLanguage();
      }
    }, [language]);

    return (
      <View testID={forwardTestID} style={styles.tooltiptext}>
        <View style={styles.languageBlock}>
          <Pressable
            style={[styles.language]}
            onPress={() => onLanguageChange(ILanguage.fr)}
          >
            <View style={styles.flag}>
              <FlagFR />
            </View>
            {language === ILanguage.fr && (
              <View style={styles.selectedLanguage} />
            )}
          </Pressable>
          <Pressable
            testID={TestIDs.components.LanguageToolTip.actions.onLanguageChange}
            onPress={() => onLanguageChange(ILanguage.en)}
            style={[styles.language]}
          >
            <View style={styles.flag}>
              <FlagUK />
            </View>
            {language !== ILanguage.fr && (
              <View
                testID={TestIDs.components.LanguageToolTip.views.newLanguage}
                style={styles.selectedLanguage}
              />
            )}
          </Pressable>
        </View>
      </View>
    );
  }
);

const styles = StyleSheet.create({
  tooltiptext: {
    fontFamily: "manrope",
    justifyContent: "center",
    width: 140,
    height: 70,
    backgroundColor: Colors.white,
    textAlign: "left",
    padding: 5,
    position: "absolute",
    bottom: "calc(100% + 10px)",
    alignSelf: "center",
  },
  languageBlock: {
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  language: {
    borderColor: Colors.foodiBlack,
    justifyContent: "center",
    alignItems: "center",
    height: 46,
    width: 46,
    borderRadius: 23,
  },
  flag: {
    height: 40,
    width: 40,
    borderRadius: 20,
    overflow: "hidden",
    justifyContent: "center",
    alignItems: "center",
  },
  selectedLanguage: {
    height: 46,
    width: 46,
    borderRadius: 23,
    borderWidth: 4,
    borderColor: Colors.foodiDefault,
    position: "absolute",
  },
});
