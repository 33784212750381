import React, { useMemo, useState } from "react";
import {
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
  Text,
} from "react-native";
import { Title16, Title18, Title13 } from "@stylesheets";
import { FastImage, OfferColorState } from "@atomic";
import { Colors } from "@constants";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "@redux";
import { I18n } from "react-redux-i18n";
import { getLabelFromWithdrawalType, TestIDs } from "@utils";
import { OfferTemplateWithdrawalType } from "@foodi/core";
import { OfferViewModel } from "@modules";
import { useDevices } from "@hooks";

interface IProps {
  index: number;
  title: string;
  type: string;
  style?: ViewStyle;
  forwardTestID?: string;
  image: string;
  isSelected: boolean;
  onSelected: Function;
  orderStartDefault?: string;
  orderEndDefault?: string;
  availableToday: boolean;
  orderRange?: string;
}

export const Offer: React.FC<IProps> = 
  ({
    style,
    forwardTestID,
    image,
    isSelected,
    title,
    onSelected,
    index,
    type,
    orderEndDefault,
    orderStartDefault,
    availableToday,
    orderRange,
  }) => {
    const dispatch = useDispatch();
    const [isMobile] = useDevices();
    const styles = useMemo(() => _styles(isMobile), [isMobile]);

    const Title: React.FC<any> = isMobile ? Title16 : Title18;
    const OfferTypeLabel: React.FC<any> = isMobile ? Title13 : Title16;

    const [offerVM] = useState(new OfferViewModel(dispatch));

    const startTimeOffer = offerVM.getHourFromDefaultOrderRange(
      orderStartDefault
    );
    const endTimeOffer = offerVM.getHourFromDefaultOrderRange(orderEndDefault);
    const timeRange = !!orderRange
      ? offerVM.getHourFromOrderRange(orderRange)
      : `${startTimeOffer} - ${endTimeOffer}`;

    const onSelectOffer = () => {
      onSelected(index);
    };
    const brandTheme = useSelector(
      (state: GlobalState) => state.brandTheme.brandTheme
    );

    return (
      <View>
        <TouchableOpacity
          onPress={onSelectOffer}
          testID={forwardTestID}
          style={[
            styles.container,
            style,
            isSelected && {
              borderColor:
                brandTheme.buttonActionColors?.backgroundColor ||
                Colors.foodiDefault,
            },
          ]}
        >
          <FastImage imagePath={image} width={200} style={styles.image} />
          <View style={styles.rightSide}>
            <View style={styles.titleDiv}>
              <Title
                isBold
                bold
                style={styles.title}
                numberOfLines={2}
                ellipsizeMode="tail"
              >
                {title}
              </Title>
            </View>
            <View style={styles.detailDiv}>
              <OfferTypeLabel style={styles.offerType}>
                {I18n.t(
                  `restaurantDetail.tagTypeLegend.${getLabelFromWithdrawalType(
                    type as OfferTemplateWithdrawalType
                  )}`
                )}
              </OfferTypeLabel>
              <View style={styles.offerRangeDiv}>
                <OfferColorState
                  forwardTestID={`${TestIDs.restaurantDetail.views.offerColorStatus}${index}`}
                  status={availableToday}
                />
                <View style={styles.dot} />
                <Text style={styles.offerRange}>{timeRange}</Text>
              </View>
            </View>
          </View>
        </TouchableOpacity>
      </View>
    );
  };

const _styles = (isMobile: boolean) =>
  StyleSheet.create({
    container: {
      width: isMobile ? 261 : 320,
      height: isMobile ? 116 : 140,
      borderWidth: 5,
      borderRadius: 10,
      marginRight: 5,
      borderColor: "transparent",
      flexDirection: "row",
      justifyContent: "center",
      overflow: "hidden",
    },
    rightSide: {
      flexDirection: "column",
      justifyContent: "space-between",
      flex: 1,
      backgroundColor: Colors.foodiBlack,
    },
    image: {
      height: isMobile ? 116 : 140,
      width: isMobile ? 82 : 100,
      borderRadius: 0,
    },
    titleDiv: {
      paddingVertical: 5,
    },
    detailDiv: {
      paddingVertical: 5,
      marginBottom: 10,
    },
    title: {
      color: Colors.white,
      marginHorizontal: 10,
      textAlign: "left",
      lineHeight: 20,
      marginTop: isMobile ? 5 : 15,
    },
    offerRangeDiv: {
      marginHorizontal: 10,
      flexDirection: "row",
    },
    offerRange: {
      color: Colors.white,
      fontFamily: "manrope",
      fontSize: 13,
      lineHeight: 12,
    },
    offerType: {
      color: Colors.white,
      marginHorizontal: 10,
      textAlign: "left",
      marginBottom: isMobile ? 5 : 10,
    },
    dot: {
      width: 1,
      height: 1,
      borderRadius: 1,
      backgroundColor: Colors.white,
      alignSelf: "center",
      marginHorizontal: 3,
    },
  });
