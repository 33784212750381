import styled from "styled-components/native";

export const TitleSecondLine = styled.Text<{ isMobile?: boolean }>`
  font-size: ${({ isMobile }) => (isMobile ? "30px" : "48px")};
  line-height: ${({ isMobile }) => (isMobile ? "30px" : "60px")};
  font-family: manrope-bold;
  letter-spacing: 0;
  text-align: center;
  width: ${({ isMobile }) => (isMobile ? "86%" : "100%")};
`;

export const TitleFirstLine = styled.Text<{ isMobile?: boolean }>`
  font-size: ${({ isMobile }) => (isMobile ? "22px" : "36px")};
  line-height: ${({ isMobile }) => (isMobile ? "22px" : "48px")};
  letter-spacing: 0;
  font-family: manrope;
  ${({ isMobile }) => isMobile && `margin-bottom: 10px`};
  text-align: center;
`;

export const Container = styled.View<{ isLogged?: boolean }>`
  align-items: center;
  margin: ${({ isLogged }) => (isLogged ? "16px 0px 21px" : "78px 0px")};
`;
