import { NavigationService } from "./NavigationService";
import {
  Route,
  NavigationState,
  PartialState,
  NavigationContainerRef,
  ParamListBase,
  CommonActions,
} from "@react-navigation/native";
import { RefObject, createRef } from "react";

type RouteWithState =
  | (Route<string> & {
      state?: NavigationState | PartialState<NavigationState> | undefined;
    })
  | undefined;

const MAX_DIGGING_ITERATION = 50;

export class ReactNavigationService implements NavigationService {
  navigationRef: RefObject<NavigationContainerRef<any>>;

  constructor(ref: RefObject<NavigationContainerRef<any>>) {
    this.navigationRef = ref;
  }

  navigate<RouteName extends keyof ParamListBase>(
    ...args: ParamListBase[RouteName] extends undefined | any
      ? [RouteName] | [RouteName, ParamListBase[RouteName]]
      : [RouteName, ParamListBase[RouteName]]
  ): void {
    return this.navigationRef?.current?.navigate(...args);
  }

  setParams(params: object): void {
    return this.navigationRef?.current?.setParams(params);
  }

  /**
   * Gets the current screen from navigation state
   */
  getActiveRouteName(
    maxDiggingIteration: number = MAX_DIGGING_ITERATION
  ): string | undefined {
    let currentRoute: RouteWithState = this.getCurrentRoute();
    let diggingIteration = 0;
    while (
      currentRoute?.state?.index !== undefined &&
      currentRoute?.state?.routes?.length > 0 &&
      diggingIteration < maxDiggingIteration
    ) {
      currentRoute = currentRoute?.state?.routes[
        currentRoute?.state?.index || 0
      ] as RouteWithState;
      diggingIteration++;
    }
    return currentRoute?.name;
  }

  getAvailableRouteNames(): string[] {
    const currentRoute = this.getCurrentRoute();
    let routeNames: string[] = [];
    if (currentRoute?.state?.index !== undefined) {
      // @ts-ignore
      routeNames = currentRoute?.state?.routeNames;
      console.log(routeNames);
    }
    return routeNames;
  }

  private getCurrentRoute():
    | (Route<string> & {
        state?: NavigationState | PartialState<NavigationState> | undefined;
      })
    | undefined {
    let rootState = this.navigationRef?.current?.getRootState();
    return this.getCurrentRouteFromState(rootState);
  }

  public getCurrentRouteFromState(
    state: NavigationState | undefined
  ):
    | (Route<string> & {
        state?: NavigationState | PartialState<NavigationState> | undefined;
      })
    | undefined {
    let route = state?.routes[state?.index];

    while (route && route.state && route.state.index) {
      // @ts-ignore
      route = route.state.routes[route.state.index];
    }

    return route;
  }
  reset(routeName: string) {
    this.navigationRef?.current?.dispatch(
      CommonActions.reset({ routes: [{ name: routeName }] })
    );
  }
}

export const navigationRef = createRef<NavigationContainerRef<any>>();
export const navigationService = new ReactNavigationService(navigationRef);
