"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_POINTS_OF_SALE_WITH_MENU = void 0;
const client_1 = require("@apollo/client");
const pointsOfSaleFragment_1 = require("./pointsOfSaleFragment");
exports.GET_POINTS_OF_SALE_WITH_MENU = client_1.gql `
  ${pointsOfSaleFragment_1.POINTS_OF_SALE_FRAGMENT}
  query getPointsOfSaleWithMenu(
    $type: PosType,
    $first: Int
    ) {
    getUser {
      id
      guests {
        edges {
          node {
            id
            pos(type: $type, first: $first) {
              edges {
                node {
                  ...pointsOfSaleFragment
                  mainMenuElements(elements: 3) {
                    label
                    __typename
                  }
                  bookingOfferTemplate(published: true) {
                    id
                    nextOrderableOffers {
                      id
                      published
                      orderRange
                      fullyBooked
                      nextSlots: upcomingSlots {
                        selectableAt
                        withdrawRange
                        __typename
                      }
                      __typename
                    }
                    __typename
                  }
                  __typename
                }
                __typename
              }
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      currentHoldingView {
          pos(type: $type, first: $first) {
              edges {
                node {
                  ...pointsOfSaleFragment
                  mainMenuElements(elements: 3) {
                    label
                    __typename
                  }
                  bookingOfferTemplate(published: true) {
                    id
                    nextOrderableOffers {
                      id
                      published
                      orderRange
                      fullyBooked
                      nextSlots: upcomingSlots {
                        selectableAt
                        withdrawRange
                        __typename
                      }
                      __typename
                    }
                    __typename
                  }
                  __typename
                }
                __typename
              }
              __typename
            }
      }
      __typename
    }
  }
`;
