import { Colors } from "@constants";
import style from "styled-components/native";

export const Container = style.Pressable<{
    backgroundColor?: string;
    borderColor?: string;
    havePointer?: boolean;
    }>`
    width: 25px;
    height: 25px;
    border: 1px solid  ${({ borderColor }) => borderColor ? borderColor : Colors.border1};
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    ${({ backgroundColor }) => `backgroundColor: ${backgroundColor}`};
    cursor: ${({ havePointer }) => havePointer ? 'pointer' : 'default'};
`;
