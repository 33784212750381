"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_POINTS_OF_SALE = void 0;
const client_1 = require("@apollo/client");
const pointsOfSaleFragment_1 = require("./pointsOfSaleFragment");
exports.GET_POINTS_OF_SALE = client_1.gql `
  ${pointsOfSaleFragment_1.POINTS_OF_SALE_FRAGMENT}
  #import "./pointsOfSaleFragment.gql"

  query getPointsOfSale(
    $type: String,
    $offerTemplateType: String!,
    $offerTemplateWithdrawalType: String!
    ) {
    getUser {
      id
      guests {
        edges {
          node {
            id
            getPos(type: $type) {
              edges {
                node {
                  ...pointsOfSaleFragment
                  activeOfferTemplates(
                    querySearch: [
                      {
                        key: "type",
                        operator: "*",
                        value: $offerTemplateType
                      },
                      {
                        key: "withdrawalType",
                        operator: "*",
                        value: $offerTemplateWithdrawalType
                      }
                    ]
                  ) {
                    salesType
                    __typename
                  }
                  bookingOfferTemplate(published: true) {
                    id
                    __typename
                  }
                  __typename
                }
                __typename
              }
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      currentHoldingView {
          getPos(type: $type) {
              edges {
                node {
                  ...pointsOfSaleFragment
                  activeOfferTemplates(
                    querySearch: [
                      {
                        key: "type",
                        operator: "*",
                        value: $offerTemplateType
                      },
                      {
                        key: "withdrawalType",
                        operator: "*",
                        value: $offerTemplateWithdrawalType
                      }
                    ]
                  ) {
                    salesType
                    withdrawalType
                    __typename
                  }
                  bookingOfferTemplate(published: true) {
                    id
                    __typename
                  }
                  __typename
                }
                __typename
              }
              __typename
          }
      }
      __typename
    }
  }
`;
