"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_SCOLAPASS_INVOICES = void 0;
const client_1 = require("@apollo/client");
const scolapassInvoicesFragment_1 = require("./scolapassInvoicesFragment");
exports.GET_SCOLAPASS_INVOICES = client_1.gql `
  ${scolapassInvoicesFragment_1.SCOLAPASS_INVOICES_FRAGMENT}
  query getScolapassInvoices($after: Cursor, $first: Int) {
    getUser {
      id
      guests {
        edges {
          node {
            virtualTicket
            id
            invoices: getInvoices(
              order: "-FAC_Date_Facture"
              first: $first
              after: $after
            ) {
              ...scolapassInvoicesFragment
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
