import React from "react";
import { Pressable, StyleSheet, View, ViewStyle } from "react-native";
import { Title16, Title22 } from "@stylesheets";
import { Colors } from "@constants";
import { useDevices } from "@hooks";

interface IProps {
  title: string;
  isSelected: boolean;
  style?: ViewStyle;
  styleContainer?: ViewStyle;
  forwardTestID?: string;
  onPress: Function;
  tabIndex: number;
  icon?: Function;
}

export const TransactionTab: React.FC<IProps> = React.memo(
  ({
    style,
    styleContainer,
    forwardTestID,
    title,
    isSelected,
    onPress,
    tabIndex,
    icon,
  }) => {
    const [isMobile] = useDevices();
    const Title: React.FC<any> = isMobile ? Title16 : Title22;

    const onSelected = () => {
      onPress(tabIndex);
    };
    return (
      <Pressable
        testID={forwardTestID}
        style={[styles.container, styleContainer]}
        onPress={onSelected}
      >
        <View style={styles.iconTitleContainer}>
          <View style={styles.iconContainer}>{icon && icon()}</View>
          <Title style={[styles.title, style]}>{title}</Title>
        </View>
        {isSelected && (
          <View
            style={styles.underline}
            testID={`${forwardTestID}_UNDERLINE`}
          />
        )}
      </Pressable>
    );
  }
);

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
  },
  title: {
    color: Colors.foodiBlack,
    fontFamily: "manrope-bold",
  },
  underline: {
    height: 3,
    backgroundColor: Colors.foodiBlack,
    width: "100%",
  },
  iconTitleContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  iconContainer: {
    marginRight: 5,
  },
});
