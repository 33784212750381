import {
  GetInvoiceScolapassFileCommand,
  GetTransactionsCommand,
  ITransaction,
  TransactionsResponse,
} from "@foodi/core";
import { createReducer, ThunkResult } from "@redux";
import { Action } from "@redux/action";
import { displayToastError } from "@utils";

export interface TransactionsState {
  transactions: ITransaction[];
  mercanetStatus: boolean;
  refillFromCart: boolean;
}

/*************  Actions  ****************/

const ActionTypes = {
  SET_BALANCE_TRANSACTIONS: "transaction/SET_BALANCE_TRANSACTIONS",
  SET_MERCANET_REQUEST: "transaction/SET_MERCANET_REQUEST",
  SET_REFILL_FROM_CART: "transaction/SET_REFILL_FROM_CART",
};

const ActionCreators = {
  setTransactions: (transactions: ITransaction[]): Action<ITransaction[]> => ({
    type: ActionTypes.SET_BALANCE_TRANSACTIONS,
    payload: transactions,
  }),
  setMercanetStatus: (mercanetStatus: boolean): Action<boolean> => ({
    type: ActionTypes.SET_MERCANET_REQUEST,
    payload: mercanetStatus,
  }),
  setRefillFromCart: (refillFromCart: boolean): Action<boolean> => ({
    type: ActionTypes.SET_REFILL_FROM_CART,
    payload: refillFromCart,
  }),
};

/*************  Side Effects, only if applicable ****************/
// e.g. thunks, epics, etc
const ThunkActionCreators = {
  getTransactions: (
    params: GetTransactionsCommand
  ): ThunkResult<Promise<void>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { getTransactions } = getDependencies();
      const _transactions = await getTransactions.execute(params);
      dispatch(ActionCreators.setTransactions(_transactions.transactions));
    } catch (e) {
      displayToastError(dispatch);
      return Promise.reject(e);
    }
  },
};

/*************  Reducer  ****************/

const initialState: TransactionsState = {
  transactions: [],
  mercanetStatus: false,
  refillFromCart: false,
};

const Reduction = {
  setTransactions: (
    state: TransactionsState,
    { payload: transactions }: Action<ITransaction[]>
  ): TransactionsState => ({
    ...state,
    transactions,
  }),
  setMercanetStatus: (
    state: TransactionsState,
    { payload: mercanetStatus }: Action<boolean>
  ): TransactionsState => ({
    ...state,
    mercanetStatus,
  }),
  setRefillFromCart: (
    state: TransactionsState,
    { payload: refillFromCart }: Action<boolean>
  ): TransactionsState => ({
    ...state,
    refillFromCart,
  }),
};

const reducer = createReducer(initialState, {
  [ActionTypes.SET_BALANCE_TRANSACTIONS]: Reduction.setTransactions,
  [ActionTypes.SET_MERCANET_REQUEST]: Reduction.setMercanetStatus,
  [ActionTypes.SET_REFILL_FROM_CART]: Reduction.setRefillFromCart,
});

export default reducer;

export {
  reducer as TransactionsReducer,
  ActionTypes as TransactionsActionTypes,
  ActionCreators as TransactionsActions,
  ThunkActionCreators as TransactionsThunks,
};
