import { createStackNavigator } from "@react-navigation/stack";
import React from "react";
import { POSScreens } from ".";
import {
  RestaurantDetailScreen,
  ConfirmationPage,
  EdenredCallback,
} from "../screens";

export type POSStackParamList = {
  [POSScreens.RESTAURANT_DETAIL]: undefined;
  [POSScreens.CONFIRMATION_PAGE]: undefined;
  [POSScreens.EDENRED_CALLBACK]: undefined;
};

const Stack = createStackNavigator<POSStackParamList>();

export const POSNavigation = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        name={POSScreens.RESTAURANT_DETAIL}
        key="restaurantDetailScreen"
        component={RestaurantDetailScreen}
      />
      <Stack.Screen
        name={POSScreens.EDENRED_CALLBACK}
        key="edenredCallbackrestaurantDetailScreen"
        component={EdenredCallback}
      />
      <Stack.Screen
        name={POSScreens.CONFIRMATION_PAGE}
        key="confirmationPage"
        component={ConfirmationPage}
      />
    </Stack.Navigator>
  );
};
