import React, { useEffect } from "react";
import { Colors } from "@constants";
import { ButtonContainer, Container } from "./MiniBookingCart.style";
import { I18n } from "react-redux-i18n";
import { TestIDs } from "@utils";
import { useDispatch, useSelector } from "react-redux";
import { PointOfSaleActions } from "@modules";
import { Title18 } from "@stylesheets";
import { Pressable } from "react-native";
import { NavigationProp } from "@react-navigation/native";

interface IProps {
  navigation?: NavigationProp<any>;
}

export const MiniBookingCart: React.FC<IProps> = React.memo(
  ({ navigation }) => {
    const dispatch = useDispatch();

    const handlePress = () => {
      dispatch(PointOfSaleActions.setFullBookingCartStatus(true));
      dispatch(PointOfSaleActions.setMiniBookingCartStatus(false));
    };

    useEffect(() => {
      return () => {
        dispatch(PointOfSaleActions.setMiniBookingCartStatus(false));
      };
    }, []);

    return (
      <Container
        testID={TestIDs.restaurantDetail.views.miniBookingCartContainer}
      >
        <ButtonContainer>
          <Pressable
            testID={TestIDs.restaurantDetail.actions.openFullBookingCart}
            onPress={handlePress}
          >
            <Title18 style={{ color: Colors.white }}>
              {I18n.t("restaurantDetail.miniCart.makeReservation")}
            </Title18>
          </Pressable>
        </ButtonContainer>
      </Container>
    );
  }
);
