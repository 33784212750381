"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_PDF_EXPORT = void 0;
const client_1 = require("@apollo/client");
exports.GET_PDF_EXPORT = client_1.gql `
    query pdfExport($siteId: String!, $startDate: String!, $endDate: String!, $idService: Int!, $idSchools: [String!]) {
        pdfExport(
            siteId: $siteId,
            startDate: $startDate,
            endDate: $endDate,
            idService: $idService,
            idSchools: $idSchools,
        ) {
            schedules
            dates {
                date
                schools {
                    schoolName
                    classes {
                        className
                        students {
                            studentSurname
                            studentName
                        }
                    }
                }
            }
        }
    }
`;
