import style from "styled-components/native";
import { Colors } from "@constants";
import { Label } from "@atomic";

export const Container = style.View<{ isMobile?: boolean }>`
    height: 30;
    padding: 3px;
    flex-direction: row;
    justify-content: ${({ isMobile }) => isMobile ? 'center' : 'space-between'};
    align-items: center;
    width: 100%;

    ${({ isMobile }) => !isMobile ?  
        `border-radius: 18.5;
        border-width: 1;
        border-color: ${Colors.white};`
     : ``}
`;

export const CircleContainer = style.View`
    position: absolute;
    top: 7;
    left: 188;
`;

export const IconView = style.View`
    padding-right: 7px;
    padding-left: 5px;
`;

export const LableContainer = style(Label)`
    padding-left: 5px;
`;

export const Circle = style.View`
    background-color: ${Colors.white};
    border-radius: 2.5px;
    height: 5px;
    width: 5px;
    margin: 5px;
`;
