import { Colors } from "@constants";
import { RefillViewModel } from "@modules";
import React, { useState } from "react";
import { Platform, StyleSheet, TextInput, View, ViewStyle } from "react-native";

interface IProps {
  invalidInput: boolean;
  forwardTestID?: string;
  style?: ViewStyle;
  textStyle?: ViewStyle;
  setInvalidInput?: Function;
  handleOtherValue: (value: number) => void;
  defaultValue?: string;
  maxAmount?: number;
}

export const RefillTextInput: React.FC<IProps> = React.memo(
  ({
    invalidInput,
    style,
    forwardTestID,
    setInvalidInput,
    handleOtherValue,
    defaultValue = "0",
    maxAmount,
  }) => {
    const [value, setValue] = useState<string>(defaultValue);
    const [refillVM] = useState(new RefillViewModel(maxAmount));

    const onChangeBalance = (balance: string) => {
      let balanceWithoutCurrency = balance.replace(/€/g, "");
      if (
        balanceWithoutCurrency !== "" &&
        !/^[1-9]\d{0,3}(?:[,\.]\d{0,2})?$/.test(balanceWithoutCurrency)
      ) {
        return;
      }
      const balanceNum = parseFloat(balanceWithoutCurrency.replace(",", "."));
      handleOtherValue(balanceNum);
      setValue(`${balanceWithoutCurrency}`);
      setInvalidInput?.(refillVM.isSurpassLimitBalance(balanceNum));
    };

    return (
      <View
        style={[styles.container, style, invalidInput && styles.invalidInput]}
      >
        <TextInput
          testID={forwardTestID}
          style={styles.textInput}
          onChangeText={onChangeBalance}
          keyboardType="numeric"
          value={`${value}€`}
          selection={{ start: value.length }}
        />
      </View>
    );
  }
);

const styles = StyleSheet.create({
  container: {
    margin: 10,
    backgroundColor: Colors.white,
    height: 50,
    width: 200,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,
    borderWidth: 1,
    borderColor: Colors.border1,
  },
  textInput: {
    height: 40,
    width: 160,
    fontSize: 24,
    color: Colors.foodiBlack,
    fontWeight: "bold",
    textAlign: "center",
    ...(Platform.OS === "web" && {
      outlineColor: "transparent",
    }),
  },
  invalidInput: {
    borderWidth: 2,
    borderColor: Colors.redError,
  },
  invalidText: {
    color: Colors.redError,
    marginLeft: 2,
  },
  invalidDiv: {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
});
