import React from "react";
import { FlexDirectionRow, ProductDescription, Row } from "./Cart.style";
import { BlackBadge } from "@assets";
import { useNavigation } from "@react-navigation/native";
import { Pressable, StyleSheet } from "react-native";
import { I18n } from "react-redux-i18n";
import { Title16 } from "@stylesheets";
import { Colors } from "@constants";
import { HomeScreens } from "@modules";

export const AddBadge = () => {
  const navigation = useNavigation<any>();
  return (
    <Row style={styles.container}>
      <FlexDirectionRow style={styles.badgeContainer}>
        <BlackBadge />
        <ProductDescription marginLeft={5}>
          {I18n.t("account.badge")}
        </ProductDescription>
      </FlexDirectionRow>
      <Pressable
        onPress={() => {
          navigation.navigate(HomeScreens.PROFILE_STACK);
        }}
      >
        <Title16 color={Colors.blue1} bold style={styles.addYourBadge}>
          {I18n.t("restaurantDetail.cart.add_badge")}
        </Title16>
      </Pressable>
    </Row>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    marginTop: 6,
  },
  badgeContainer: {
    alignItems: "center",
  },
  addYourBadge: {
    textDecorationLine: "underline",
  },
});
