"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InMemoryPointsOfSaleService = void 0;
const domain_1 = require("../../../domain");
class InMemoryPointsOfSaleService {
    constructor(onePosFixture) {
        this.onePosFixture = onePosFixture;
    }
    /* istanbul ignore next */
    getPointsOfSaleWithMenu(variables) {
        throw new Error("Method not implemented.");
    }
    /* istanbul ignore next */
    getPos(variables) {
        throw new Error("Method not implemented.");
    }
    getOnePos(variables) {
        if (this.onePosFixture) {
            return Promise.resolve(this.onePosFixture);
        }
        return Promise.reject(new domain_1.PointsOfSaleServiceErrors.GetOnePosRequestError("GET_ONE_POS_REQUEST_FAILED"));
    }
    /* istanbul ignore next */
    getPointsOfSale(variables) {
        throw new Error("Method not implemented.");
    }
}
exports.InMemoryPointsOfSaleService = InMemoryPointsOfSaleService;
