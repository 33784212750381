import { EpointageFooter } from "@atomic";
import { NavigationProp } from "@react-navigation/native";
import React from "react";
import { ScrollView, View, useWindowDimensions, ViewStyle } from "react-native";

interface IProps {
  children?: React.ReactNode;
  navigation: NavigationProp<any>;
  style?: ViewStyle;
}

export const EpointageScreenWithFooter: React.FC<IProps> = React.memo(
  ({ children, navigation, style }) => {
    const { height } = useWindowDimensions();

    return (
      <ScrollView
        contentContainerStyle={[
          {
            minHeight: height,
          },
          style,
        ]}
      >
        <View style={{ flex: 1 }}>{children}</View>
        <EpointageFooter />
      </ScrollView>
    );
  }
);
