"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetPaymentStatus = void 0;
class GetPaymentStatus {
    constructor(paymentsService) {
        this.paymentsService = paymentsService;
    }
    execute(request) {
        const { idRefill, token } = request;
        return this.paymentsService.getPaymentStatus({ idRefill, token });
    }
}
exports.GetPaymentStatus = GetPaymentStatus;
