"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InMemoryOfferService = void 0;
const domain_1 = require("../../../domain");
class InMemoryOfferService {
    constructor(offerTemplatesFixture, bookingTemplateOfferFixture, offerSlotsFixture, offerFixture) {
        this.offerTemplatesFixture = offerTemplatesFixture;
        this.bookingTemplateOfferFixture = bookingTemplateOfferFixture;
        this.offerSlotsFixture = offerSlotsFixture;
        this.offerFixture = offerFixture;
    }
    getOfferSlots(variables) {
        if (this.offerSlotsFixture)
            return Promise.resolve(this.offerSlotsFixture);
        return Promise.reject(new domain_1.OfferServiceErrors.GetOfferSlotsRequestError("GET_OFFER_SLOTS_REQUEST_FAILED"));
    }
    getOffer(variables) {
        if (this.offerFixture) {
            return Promise.resolve(this.offerFixture);
        }
        return Promise.reject(new domain_1.OfferServiceErrors.GetOfferRequestError("GET_OFFER_REQUEST_FAILED"));
    }
    getOfferTemplates(variables) {
        if (this.offerTemplatesFixture) {
            return Promise.resolve(this.offerTemplatesFixture);
        }
        return Promise.reject(new domain_1.OfferServiceErrors.GetOfferTemplatesRequestError("GET_OFFER_TEMPLATES_REQUEST_FAILED"));
    }
    getBookingTemplateOffer(variables) {
        if (this.bookingTemplateOfferFixture) {
            return Promise.resolve(this.bookingTemplateOfferFixture);
        }
        return Promise.reject(new domain_1.OfferServiceErrors.GetBookingTemplateOfferRequestError("GET_BOOKING_OFFER_TEMPLATES_REQUEST_FAILED"));
    }
}
exports.InMemoryOfferService = InMemoryOfferService;
