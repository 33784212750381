import { Checkbox } from "@atomic";
import { IListItem } from "@modules";
import { Title16 } from "@stylesheets";
import React, { useMemo } from "react";
import { Pressable, StyleSheet, View } from "react-native";

interface IDropdownRowProps {
  id: string | "";
  value: string | "";
  selectHandler?: Function;
  handleCheckOption?: (school: IListItem, check: boolean) => void;
  isChecked?: boolean;
}

export const DropDownSelectRow: React.FC<IDropdownRowProps> = React.memo(({
  id,
  value,
  selectHandler,
  handleCheckOption,
  isChecked
}) => {
  const [check, setCheck] = React.useState<boolean>(!!isChecked);

  const pressHandler = () => {
    if (selectHandler) selectHandler({ id, name: value });
  };

  const handleCheckbox = () => {
    if (handleCheckOption) {
      handleCheckOption({ id, name: value }, !check);
      setCheck(!check);
    }
  };

  const styles = useMemo(() => _styles(!!handleCheckOption), [!!handleCheckOption]);

  return (
    <Pressable onPress={pressHandler} disabled={!!handleCheckOption} style={styles.dropBoxSelect}>
      {handleCheckOption &&
        <View style={styles.checkBoxContainer}>
          <Checkbox
              onPress={handleCheckbox}
              checked={check}
          />
        </View>
      }
      <Title16>{value}</Title16>
    </Pressable>
  );
});

const _styles = (handleCheckOption: boolean) =>
  StyleSheet.create({
    dropBoxSelect: {
      paddingVertical: 5,
      paddingHorizontal: 20,
      height: 32,
      flexDirection: handleCheckOption ? 'row' : 'column',
    },
    checkBoxContainer: {
      marginRight: 5
    }
  });
