import React, { useEffect, useRef } from "react";
import { View } from "react-native";

interface IProps {
  handleClose: () => void;
  children: React.ReactNode;
}
export const CloseClickOutsideTemplate: React.FC<IProps> = React.memo(
  ({ handleClose, children }) => {
    const checkIfClickOutside = (ref: any) => {
      useEffect(() => {
        function handleClickOutside(event: any) {
          if (ref.current && !ref.current.contains(event.target)) {
            handleClose();
          }
        }

        window.addEventListener("click", handleClickOutside);
        return () => {
          window.removeEventListener("click", handleClickOutside);
        };
      }, [ref]);
    };

    const wrapperRef = useRef(null);
    checkIfClickOutside(wrapperRef);

    return <View ref={wrapperRef}>{children}</View>;
  }
);
