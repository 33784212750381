import { Dimensions, PixelRatio } from "react-native";

const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get("window");

// based on iphone XR scale
const scaleW = SCREEN_WIDTH / 414;
const scaleH = SCREEN_HEIGHT / 896;

export function responsiveSizeWidth(size: number): number {
  const newSize = size * scaleW;
  return Math.round(PixelRatio.roundToNearestPixel(newSize));
}

export function responsiveSizeHeight(size: number): number {
  const newSize = size * scaleH;
  return Math.round(PixelRatio.roundToNearestPixel(newSize));
}
