import React, { useMemo } from "react";
import { ITransaction } from "@foodi/core";
import { StyleSheet, View } from "react-native";
import { TransactionRow } from "@atomic";
import { Colors, Spacing } from "@constants";
import { Title18, Title22 } from "@stylesheets";
import moment from "moment";
import _ from "lodash";
import { TestIDs } from "@utils";
import { useDevices } from "@hooks";
import { NavigationProp } from "@react-navigation/native";
import { TransactionsViewModel } from "@modules";

interface IProps {
  navigation: NavigationProp<any>;
  groupedTransactions: Record<string, ITransaction[]>;
  currentTab?: number;
}
export const TransactionsList: React.FC<IProps> = React.memo(
  ({ navigation, groupedTransactions, currentTab }) => {
    const groupedTransArray = Object.entries(groupedTransactions);
    const [isMobile] = useDevices();
    const styles = useMemo(() => _styles(isMobile), [isMobile]);

    const transactionVM = new TransactionsViewModel();

    const DateText: React.FC<any> = isMobile ? Title18 : Title22;

    const renderTransactionRow = (
      transaction: ITransaction,
      rowIndex: number,
      groupIndex: number
    ) => {
      const { pos, total, date, id, refill } = transaction;
      const { name, type } = pos;
      const transactionType = transactionVM.checkTypeOfTransactions(
        transaction
      );
      return (
        <TransactionRow
          forwardTestID={`${TestIDs.home.balance.views.transactionRow}${rowIndex}_${groupIndex}`}
          key={`${rowIndex}_${groupIndex}`}
          id={id}
          title={name}
          total={Number(total?.amount) ? total : refill}
          transactionDate={date}
          transactionType={transactionType}
          type={type}
          navigation={navigation}
        />
      );
    };

    return (
      <>
        {groupedTransArray.map((groupTransaction, groupIndex) => {
          const [transDate, transactions] = groupTransaction;
          const [month, year] = transDate.split("/");
          const _date = new Date(parseInt(year), parseInt(month));

          return (
            <View
              testID={`${TestIDs.home.balance.views.transactionGroup}${groupIndex}`}
              style={styles.group}
              key={groupIndex}
            >
              <DateText style={styles.date}>
                {_.upperFirst(moment(_date).format("MMMM YYYY"))}
              </DateText>
              <View>
                {transactions.map((transaction, rowIndex) =>
                  renderTransactionRow(transaction, rowIndex, groupIndex)
                )}
              </View>
            </View>
          );
        })}
      </>
    );
  }
);

const _styles = (isMobile: boolean) =>
  StyleSheet.create({
    group: {
      width: isMobile ? "100%" : 500,
      marginTop: Spacing.XL,
      borderWidth: isMobile ? 0 : 1,
      borderColor: Colors.border1,
      padding: isMobile ? 0 : Spacing.L,
      paddingHorizontal: isMobile ? Spacing.M : Spacing.L,
      borderRadius: isMobile ? 0 : 8,
    },
    date: {
      color: Colors.foodiBlack,
      textAlign: "left",
      fontFamily: "manrope-bold",
    },
  });
