/* istanbul ignore file */
import React, { useEffect, useState } from "react";
import { BookingOrder, Order } from "@foodi/core";
import { OrderViewModel, PointOfSaleActions } from "@modules";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "@redux";
import { ComingSoon } from "@atomic";
import { NavigationProp } from "@react-navigation/native";
import { hasAccessTokenExpired } from "@utils";

interface IProps {
  navigation?: NavigationProp<any>;
  routeName?: string;
}

export const ComingSoonContainer: React.FC<IProps> = React.memo(({ navigation, routeName }) => {
  const dispatch = useDispatch();
  const userId =
    useSelector((state: GlobalState) => state.auth?.userInfo?.idGuest) ?? "";
  const holdingId =
    useSelector((state: GlobalState) => state.auth?.userInfo?.holdingId) ?? "";
  const { hasNewOrder } =
    useSelector((state: GlobalState) => state?.pointOfSale) || {};
  const isLogin = useSelector((state: GlobalState) => state.auth?.isLogin);
  const userLanguage = useSelector(
    (state: GlobalState) => state.auth?.userInfo?.language
  );
  const accessToken = useSelector(
    (state: GlobalState) => state.auth?.authToken?.accessToken
  );

  const [orders, setOrders] = useState([] as Order[]);
  const [bookingOrders, setBookingOrders] = useState([] as BookingOrder[]);

  const orderVM = React.useRef<OrderViewModel | null>(null);

  const hasTokenExpired = hasAccessTokenExpired(accessToken);

  const handleGetOrders = async (idGuest: string) => {
    if (!orderVM.current)
      orderVM.current = new OrderViewModel(dispatch, idGuest);
    const ordersResult: any = await orderVM.current.getOrdersResult();

    if (hasNewOrder) dispatch(PointOfSaleActions.setNewOrder(false));

    if (ordersResult && ordersResult.orders !== undefined) {
      setOrders(ordersResult.orders);
    }
  };

  const handleGetBookings = async () => {
    if (!orderVM.current) orderVM.current = new OrderViewModel(dispatch, "");
    const bookingOrdersResult: any = await orderVM.current.getBookingOrdersResult();

    if (hasNewOrder) dispatch(PointOfSaleActions.setNewOrder(false));
    setBookingOrders(bookingOrdersResult.orders);
  };

  useEffect(() => {
    if (hasTokenExpired) return;
    
    if (isLogin) handleGetOrders(userId);
    
    if (holdingId) handleGetBookings();
    else setBookingOrders([]);
  }, [
    userId,
    hasNewOrder,
    holdingId
  ]);

  return (
    <ComingSoon
      navigation={navigation}
      bookingOrders={bookingOrders}
      orders={orders}
    />
  );
});
