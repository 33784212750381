"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cartAdmissionAndPriceFixtures = void 0;
exports.cartAdmissionAndPriceFixtures = {
    error: undefined,
    order: {
        id: "Order:40",
        admission: {
            amount: "3.00",
            currency: "EUR",
        },
        subsidies: {
            amount: "-3.00",
            currency: "EUR",
        },
        totalPrice: {
            amount: "3.00",
            currency: "EUR",
        },
        fee: {
            amount: "0.00",
            currency: "EUR",
        },
    }
};
