"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GenerateEdenredTokens = void 0;
class GenerateEdenredTokens {
    constructor(paymentsService) {
        this.paymentsService = paymentsService;
    }
    execute(request) {
        return this.paymentsService.generateEdenredTokens(request);
    }
}
exports.GenerateEdenredTokens = GenerateEdenredTokens;
