import React, { useMemo, useState } from 'react';
import { Dimensions, Pressable, StyleSheet, Text, TouchableWithoutFeedback, View } from 'react-native';
import { ModalTemplate } from '@atomic/templates';
import { FoodIllustrationFR, FoodIllustrationEN, StarActive, StarInactive, Sucess } from '@assets';
import { Colors, Spacing } from '@constants';
import { TextAreaInput } from '@atomic/atoms/TextAreaInput';
import { Button, Checkbox, FloatTextInput } from '@atomic/molecules';
import { I18n } from 'react-redux-i18n';
import { ButtonContainer } from '@atomic/organisms/Cart/Cart.style';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from '@redux';
import { HomeScreens, OrderViewModel, ProfileScreens, ProfileTabs } from '@modules';
import { AddOrderRatingCommand } from '@foodi/core';
import { NavigationProp } from '@react-navigation/native';
import moment from 'moment';
import { useDevices } from '@hooks';

type IRating = Omit<AddOrderRatingCommand, 'idOrder'> & { id: string, created: string };

interface IProps {
    brandColor?: string;
    idOrder: string;
    posName: string;
    withdrawalRange: string;
    idHuman: number;
    open: boolean;
    onClose: (discarded?: boolean) => void;
    onSuccessOrderRating?: (idOrder: string, rating: IRating) => void;
    preventOrderHistoryLink?: boolean;
    currentHeight?: number;
    navigation: NavigationProp<any>;
}

interface StarRatingProps {
    label: string;
    onChange: (rate: number) => void;
    rating?: number;
    required?: boolean;

}

const MAX_RATING_COMMENT_LENGTH = 250;

// Todo - add in organism
const StarRating: React.FC<StarRatingProps> = React.memo(({ rating = 0, required = false, label, onChange }: StarRatingProps) => {
    const [isMobile] = useDevices();
    const StartRatingStyle = useMemo(() => _startRatingStyle(isMobile), [isMobile]);

    const stars: number[] = Array.from({ length: 5 }, (v, index) => index < rating ? 1 : 0);

    return (
        <View style={StartRatingStyle.ratingArea}>
            <View style={StartRatingStyle.labelArea}>
                <Text style={[StartRatingStyle.textArea, required && StartRatingStyle.bold]}>
                    {label}
                </Text>
                {required ? <Text style={StartRatingStyle.requiredRatingLabel}> ({I18n.t('common.required')})</Text> : null}
            </View>
            <View style={StartRatingStyle.starsArea}>
            {
                stars.map((star, index) => <TouchableWithoutFeedback
                    onPress={() => {
                        onChange(index + 1);
                    }}
                >
                    <View>
                        {star === 1 ?
                            (<StarActive tooltip={I18n.t('orderRating.stars', { count: index + 1 })}/>) :
                            (<StarInactive tooltip={I18n.t('orderRating.stars', { count: index + 1 })} />)
                        }
                    </View>
                </TouchableWithoutFeedback>)
            }
            </View>
        </View>
    );
});

const _startRatingStyle = (isMobile: boolean) => StyleSheet.create({
    ratingArea: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingBottom: 10
    },
    labelArea: {
        display: 'flex',
        flexDirection: 'row'
    },
    textArea: {
        fontSize: isMobile ? 15 : 16,
        fontFamily: 'manrope-regular'
    },
    starsArea: {
        display: 'flex',
        flexDirection: 'row',
        width: isMobile ? '38%' : '35%',
        justifyContent: 'space-between',
        cursor: 'pointer'
    },
    bold: {
        fontFamily: 'manrope-bold'
    },
    requiredRatingLabel: {
        fontSize: 13,
        fontFamily: 'manrope-regular',
        paddingTop: 3
    }
});


interface IPropsCompleteModal {
    idOrder: number;
    isOpen: boolean;
    onClose: () => void;
    preventOrderHistoryLink?: boolean;
    navigation: NavigationProp<any>;
}
const CompleteModal: React.FC<IPropsCompleteModal> = React.memo((
    {
        preventOrderHistoryLink = false,
        idOrder,
        isOpen,
        onClose,
        navigation
    }
) => {
  
    const height = Dimensions.get('window').height;
    const [isMobile] = useDevices();
    const ModalStyle = useMemo(() => _styles(isMobile, height), [isMobile, height]);

    const navigateToMyOrders = () => {
        if (preventOrderHistoryLink) {
            return;
        }

        onClose();

        navigation.navigate(HomeScreens.PROFILE_STACK, {
            screen: ProfileScreens.PROFILE_SCREEN,
            params: {
                tab: ProfileTabs.ORDERS,
                // this will allow the web-mobile to navigate to the correct page
                showLeftContainerFlag: isMobile ? false : null,
            },
        });
    }

    return (
        <ModalTemplate
            isOpen={isOpen}
            handleClose={onClose}
            closeOnClickOutside={true}
            isCenter={true}
            isFullScreen={true}
            paddingHorizontal={0}
            paddingVertical={0}
            style={ModalStyle.modal}
            isMobile={isMobile}
        >
            <View style={CompleteModalStyle.successIcon}>
                <Sucess size={'100px'}/>
            </View>
            <View>
                <Text style={CompleteModalStyle.successMessage}>
                    {I18n.t('orderRating.thankYou')}
                </Text>
            </View>
            <View>
                <Text style={CompleteModalStyle.order}>
                    {I18n.t('orderRating.order')} #{idOrder}&nbsp;&nbsp;
                    <Text style={CompleteModalStyle.green}>
                        {I18n.t('orderRating.rated')}
                    </Text>
                </Text>
            </View>
            <View>
                <Text style={CompleteModalStyle.history}>
                    {I18n.t('orderRating.rateOthers')}&nbsp;
                    <TouchableWithoutFeedback onPress={navigateToMyOrders}>
                        <Text style={[ModalStyle.text, !preventOrderHistoryLink && ModalStyle.link]}>
                            {I18n.t('orderRating.orderHistory')}
                        </Text>
                    </TouchableWithoutFeedback>
                    .
                </Text>
            </View>
        </ModalTemplate>
    );
});
const CompleteModalStyle = StyleSheet.create({
    successIcon: {
        flex: 1,
        alignItems: 'center',
        paddingTop: 60,
        paddingBottom: Spacing.XL
    },
    successMessage: {
        fontFamily: 'manrope-bold',
        fontSize: 30,
        textAlign: 'center',
        paddingTop: Spacing.XS,
        paddingLeft: Spacing.S,
        paddingRight: Spacing.S
    },
    order: {
        fontFamily: 'manrope-regular',
        fontSize: 22,
        textAlign: 'center',
        paddingTop: Spacing.M,
        paddingBottom: Spacing.M
    },
    green: {
        fontFamily: 'manrope-bold',
        color: Colors.middleGreen,
    },
    history: {
        fontFamily: 'manrope-regular',
        textAlign: 'center',
        fontSize: 16,
        paddingBottom: Spacing.XXL
    }
});

export const OrderRatingModal: React.FC<IProps> = React.memo((
    {
        brandColor = Colors.foodiDefault,
        preventOrderHistoryLink = false,
        idOrder,
        idHuman,
        posName,
        withdrawalRange,
        open,
        onClose,
        onSuccessOrderRating,
        currentHeight = Dimensions.get('window').height,
        navigation
    }
) => {

    const [isMobile] = useDevices();
    const ModalStyle = useMemo(() => _styles(isMobile, currentHeight), [isMobile, currentHeight]);

    const language = useSelector(
        (state: GlobalState) => state.auth?.userInfo?.language || state.i18n?.locale
    );

    const dispatch = useDispatch();

    const [orderVM] = useState(new OrderViewModel(dispatch, ""));

    const [isOpen, setIsOpen] = React.useState<boolean>(open);

    const [comment, setComment] = React.useState<string>('');

    const [consent, setConsent] = React.useState<boolean>(false);

    const [contactInfo, setContactInfo] = React.useState<string>('');

    const [contactInfoError, setContactInfoError] = React.useState<boolean>(false);

    const [showCompleteModal, setShowCompleteModal] = React.useState<boolean>(false);

    const [isSubmittingRating, setIsSubmittingRating] = React.useState<boolean>(false);

    const [ratings, setRatings] = React.useState<{
        global: number,
        meal: number,
        presentation: number,
        service: number
    }>({
        global: 0,
        meal: 0,
        presentation: 0,
        service: 0
    });

    const withdrawalDate = moment(withdrawalRange.split('/')[0]);


    const onChangeComment = (newComment: string) => {
        setComment(newComment);
    }

    const changeConsent = () => {
        setConsent(!consent);
        setContactInfoError(false);
        setContactInfo('');
    }

    const onChangeContactInfo = (newContactInfo: string) => {
        setContactInfo(newContactInfo);
    }

    const navigateToProfileParameters = () => {
        onClose();
        // @ts-ignore
        navigation.push(HomeScreens.PROFILE_STACK, {
            screen: ProfileScreens.PROFILE_SCREEN,
            params: {
                tab: ProfileTabs.SETTINGS,
                // this will allow the web-mobile to navigate to the correct page
                showLeftContainerFlag: isMobile ? false : null,
            },
        });
    }

    const navigateToMyOrders = () => {
        if (preventOrderHistoryLink) {
            return;
        }
        onClose();

        navigation.navigate(HomeScreens.PROFILE_STACK, {
            screen: ProfileScreens.PROFILE_SCREEN,
            params: {
                tab: ProfileTabs.ORDERS,
                // this will allow the web-mobile to navigate to the correct page
                showLeftContainerFlag: isMobile ? false : null,
            },
        });
    }

    const onSubmitOrderRating = async () => {
        setIsSubmittingRating(true);

        if (consent && !contactInfo) {
            setContactInfoError(true);
            setIsSubmittingRating(false);
            return;
        }
        setContactInfoError(false);

        const orderRating = {
            ratings: {
                globalRating: ratings.global,
                mealRating: ratings.meal,
                presentationRating: ratings.presentation,
                serviceRating: ratings.service
            },
            comment,
            contactInfo
        };
        try {
            const { id, created } = await orderVM.addOrderRating({
                idOrder,
                ...orderRating
            });

            setShowCompleteModal(true);
            setIsOpen(false);
            if (onSuccessOrderRating)
                onSuccessOrderRating(idOrder, {
                    ...orderRating,
                    id,
                    created
                });
        } catch (e) {
            // prevent Unhandled Rejection error page; Toast error already being handled
        }
        finally {
            setIsSubmittingRating(false);
        }
    };

    return (
        <>
            <ModalTemplate
                isOpen={isOpen}
                handleClose={() => {
                    onClose(true);
                }}
                closeOnClickOutside={true}
                isCenter={true}
                paddingHorizontal={0}
                paddingVertical={0}
                style={ModalStyle.modal}
                closeXButtonStyle={ModalStyle.closeButton}
                closeXContainerStyle={ModalStyle.closeButtonContainer}
                isMobile={isMobile}
            >
                <View style={ModalStyle.centerSVG}>
                    {language === 'fr' ?
                        <FoodIllustrationFR brandColor={brandColor} /> :
                        <FoodIllustrationEN brandColor={brandColor} />
                    }
                </View>
                <View style={ModalStyle.content}>
                    <View style={ModalStyle.orderDetails}>
                        <Text style={ModalStyle.boldText}>
                            {I18n.t('orderRating.rateIt', {
                                idOrder: idHuman
                            })}
                        </Text>
                        <Text style={ModalStyle.boldText}>
                            {I18n.t('orderRating.pickUpNote', {
                                date: withdrawalDate.format('DD/MM/YYYY'),
                                time: withdrawalDate.format('HH:mm')
                            })}
                        </Text>
                        <Text style={ModalStyle.boldText}>
                            {I18n.t('orderRating.atPos', {
                                pos: posName
                            })}
                        </Text>
                    </View>
                    <View style={ModalStyle.rating}>
                        <StarRating rating={ratings.global} label={I18n.t('orderRating.globalRating')}
                                    onChange={(rate) => {
                                        setRatings({
                                            ...ratings,
                                            global: rate
                                        });
                                    }} required/>
                        <StarRating rating={ratings.meal} label={I18n.t('orderRating.mealRating')}
                                    onChange={(rate) => {
                                        setRatings({
                                            ...ratings,
                                            meal: rate
                                        });
                                    }} />
                        <StarRating rating={ratings.presentation} label={I18n.t('orderRating.presentationRating')}
                                    onChange={(rate) => {
                                        setRatings({
                                            ...ratings,
                                            presentation: rate
                                        });
                                    }} />
                        <StarRating rating={ratings.service} label={I18n.t('orderRating.serviceRating')}
                                    onChange={(rate) => {
                                        setRatings({
                                            ...ratings,
                                            service: rate
                                        });
                                    }} />
                    </View>
                    <TextAreaInput
                        value={comment}
                        onChangeText={onChangeComment}
                        placeholder={I18n.t('orderRating.writeAComment')}
                        maxLength={MAX_RATING_COMMENT_LENGTH}
                        numberOfLines={4}
                    />
                    <View style={ModalStyle.consent}>
                        <View style={ModalStyle.consentCheckbox}>
                            <Checkbox
                                onPress={changeConsent}
                                checked={consent}
                            />
                            <Text style={[ModalStyle.text, ModalStyle.contactLabel]}>{I18n.t('orderRating.withToBeContacted')}</Text>
                        </View>
                        {<View style={ModalStyle.contactInput}>
                            { consent && <FloatTextInput
                                value={contactInfo}
                                onChangeValue={onChangeContactInfo}
                                textPlaceHolder={I18n.t('orderRating.contactInfo')}
                                hasError={contactInfoError}
                                errorMessage={I18n.t('orderRating.contactInfoErrorMessage')}
                                noMargin
                                containerStyles={{
                                    mainContainer: ModalStyle.textAreaResponsiveness,
                                    textInputContainer: ModalStyle.textAreaResponsiveness,
                                    textInputStyle: ModalStyle.textAreaResponsiveness,
                                    errorContainer: ModalStyle.textAreaResponsiveness,
                                }}
                            />
                            }
                        </View>}
                    </View>
                </View>
                <View style={ModalStyle.footerArea}>
                    <ButtonContainer>
                        <Button
                            disabled={ratings.global === 0}
                            onPress={onSubmitOrderRating}
                            label={I18n.t("common.validate")}
                            loading={isSubmittingRating}
                        />
                    </ButtonContainer>
                    <View style={ModalStyle.parametersArea}>
                        <Text style={ModalStyle.text}>
                            {I18n.t('orderRating.changeParameters1')}
                        </Text>
                        <Text style={ModalStyle.text}>
                            {I18n.t('orderRating.changeParameters2')}&nbsp;
                            <Pressable onPress={navigateToProfileParameters}>
                                <Text style={[ModalStyle.text, ModalStyle.link]}>
                                    {I18n.t('orderRating.parameters')}
                                </Text>
                            </Pressable>
                            .
                        </Text>
                    </View>
                    <View style={ModalStyle.orderHistoryArea}>
                        <Text style={ModalStyle.text}>
                            {I18n.t('orderRating.viewRating1')}
                        </Text>
                        <Text style={ModalStyle.text}>
                            {I18n.t('orderRating.your')}&nbsp;
                            <TouchableWithoutFeedback onPress={navigateToMyOrders}>
                                <Text style={[ModalStyle.text, !preventOrderHistoryLink && ModalStyle.link]}>
                                    {I18n.t('orderRating.viewRating2')}
                                </Text>
                            </TouchableWithoutFeedback>
                            .
                        </Text>
                    </View>
                </View>
            </ModalTemplate>
            <CompleteModal
                idOrder={idHuman}
                isOpen={showCompleteModal}
                preventOrderHistoryLink={preventOrderHistoryLink}
                onClose={() => {
                    setShowCompleteModal(false);
                    onClose();
                }}
                navigation={navigation}
            />
        </>
    );
});


const _styles = (isMobile: boolean, height: number) => StyleSheet.create({
    modal: {
        maxWidth: isMobile ? Dimensions.get('window').width : '414px',
        maxHeight: isMobile ? height : "95vh",
    },
    centerSVG: {
      alignItems: 'center',
      marginTop: isMobile ? '-12px' : 0
    },
    closeButton: {
        backgroundColor: Colors.disabledBackground,
        padding: Spacing.S,
        borderRadius: 50
    },
    closeButtonContainer: {
        top: isMobile ? 20 : 10,
        right: isMobile ? 20 : 10,
    },
    content: {
        paddingHorizontal: isMobile ? Spacing.M : Spacing.XL,
    },
    orderDetails: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: Spacing.M,
        alignItems: 'center',
    },
    rating: {
      paddingTop: Spacing.L,
      paddingBottom: Spacing.S
    },
    boldText: {
      fontWeight: 'bold',
      fontFamily: 'manrope-regular'
    },
    consent: {
        paddingTop: Spacing.M,
    },
    consentCheckbox: {
        display: 'flex',
        flexDirection: 'row'
    },
    text: {
      fontFamily: 'manrope-regular'
    },
    contactLabel: {
      paddingLeft: Spacing.S
    },
    contactInput: {
        paddingTop: 10,
        paddingBottom: Spacing.M
    },
    footerArea: {
        backgroundColor: Colors.background1,
    },
    parametersArea: {
        display: 'flex',
        textAlign: 'center',
        paddingTop: Spacing.M
    },
    link: {
        textDecorationLine: 'underline',
        color: Colors.blueLink,
        cursor: 'pointer'
    },
    orderHistoryArea: {
        display: 'flex',
        textAlign: 'center',
        paddingTop: Spacing.M,
        paddingBottom: Spacing.XL
    },
    textAreaResponsiveness: {
        width: '100%'
    },
});