import React from "react";
import { Colors } from "@constants";
import { Platform, StyleSheet, TextInput, View, ViewStyle } from "react-native";

interface IProps {
  isInvalid: boolean;
  style?: ViewStyle;
  textStyle?: ViewStyle;
  forwardTestID?: string;
  solde: number;
  editable?: boolean;
  validate?: (value: number) => void;
  onChange: (value: number) => void;
}

export const SoldeTextInput: React.FC<IProps> = React.memo(
  ({
    isInvalid,
    style,
    textStyle,
    forwardTestID,
    solde,
    editable = true,
    validate,
    onChange,
  }) => {
    React.useEffect(() => {
      validate?.(solde);
    }, []);

    const onChangeSolde = (balance: string) => {
      let solde = parseInt(balance.replace(/€/g, ""));
      solde = isNaN(solde) ? 0 : solde;
      onChange(solde);
      validate?.(solde);
    };

    return (
      <View
        style={[styles.container, style, !editable ? styles.disabled : isInvalid && styles.isInvalid]}
      >
        <TextInput
          testID={forwardTestID}
          style={[styles.textInput, !editable && styles.disabled, textStyle]}
          onChangeText={onChangeSolde}
          keyboardType="numeric"
          value={`${solde}€`}
          selection={{ start: `${solde}`.length }}
          editable={editable}
        />
      </View>
    );
  }
);

const styles = StyleSheet.create({
  container: {
    margin: 10,
    backgroundColor: Colors.white,
    height: 30,
    width: 80,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,
    borderWidth: 1,
    borderColor: Colors.border1,
  },
  disabled: {
    opacity: 0.6,
  },
  textInput: {
    height: 20,
    width: 70,
    fontSize: 16,
    color: Colors.foodiBlack,
    fontWeight: "bold",
    textAlign: "center",
    ...(Platform.OS === "web" && {
      outlineColor: "transparent",
    }),
  },
  isInvalid: {
    borderWidth: 2,
    borderColor: Colors.redError,
  },
  invalidText: {
    color: Colors.redError,
    marginLeft: 2,
  },
  invalidDiv: {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
});
