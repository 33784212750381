import React, { useCallback, useMemo, useState } from "react";
import {
  formatAmount,
  IAmount,
  IPaymentTypes,
  OfferTemplateWithdrawalType,
  OrderRating,
  OrderState,
} from "@foodi/core";
import { Pressable, StyleSheet, View, ViewStyle, Text } from "react-native";
import { Title13, Title16 } from "@stylesheets";
import { Colors, Spacing } from "@constants";
import _ from "lodash";
import { I18n } from "react-redux-i18n";
import { EatIn, Forward, StarActive, TakeAway } from "@assets";
import { useDevices } from "@hooks";
import {
  OfferViewModel,
  OrderViewModel,
  HomeScreens,
  ConfirmationPageActions,
  POSScreens,
  BookingActions,
} from "@modules";
import { useDispatch } from "react-redux";
import { NavigationProp } from "@react-navigation/native";
import { getLabelFromState } from "@utils";

interface IProps {
  posName: string;
  orderDate: string;
  paymentType: IPaymentTypes | null;
  orderType?: OfferTemplateWithdrawalType;
  orderState: OrderState;
  total: IAmount;
  style?: ViewStyle;
  forwardTestID?: string;
  id: string;
  navigation: NavigationProp<any>;
  orderRating: OrderRating | null;
  lastUpdateAt: string;
  isOrderRatingEnabled?: boolean;
  openOrderRatingModal: () => void;
}

export const OrderRow: React.FC<IProps> = React.memo(
  ({
    forwardTestID,
    posName,
    paymentType,
    orderDate,
    total,
    id,
    navigation,
    orderType,
    orderState,
    orderRating,
    lastUpdateAt,
    isOrderRatingEnabled,
    openOrderRatingModal
  }) => {
    const dispatch = useDispatch();
    const [isMobile] = useDevices();

    const [orderVM] = useState(new OrderViewModel(dispatch, ""));
    const [offerVM] = useState(new OfferViewModel());

    const stateLabelColor = orderVM.getOrderLabelColor(orderState);
    const styles = useMemo(() => _styles(isMobile, stateLabelColor), [
      isMobile,
      stateLabelColor,
    ]);

    const isOnSite = offerVM.isOnSiteOffer(orderType || "");
    const OrderTypeIcon = useCallback(
      () => (isOnSite ? <EatIn /> : <TakeAway />),
      [isOnSite]
    );

    const orderTypeLabel = isOnSite
      ? I18n.t("profile.orders.eatIn")
      : I18n.t("profile.orders.takeOut");
    const orderPaymentTypeLabel =
      paymentType === IPaymentTypes.OnSite
        ? I18n.t("profile.orders.onSitePaymemt")
        : I18n.t("profile.orders.onlinePaymemt");
    const title = `${orderTypeLabel} - ${posName}`;

    const amount = parseFloat(total?.amount ?? "0");
    const formattedAmount = `${formatAmount(Math.abs(amount), "€")}`;

    const handleOnPress = () => {
      dispatch(BookingActions.setBookingSelected(false));

      dispatch(
        ConfirmationPageActions.setConfirmationPageParams({
          id,
          orderId: id,
          isFirstTime: false,
          idPos: -1,
          offerSlot: "",
        })
      );

      navigation?.navigate(HomeScreens.POINT_OF_SALE_STACK, {
        screen: POSScreens.CONFIRMATION_PAGE,
        params: {
          isMyOrderListDetail: true,
        },
      });
    };

    return (
      <Pressable
        testID={forwardTestID}
        style={styles.container}
        onPress={handleOnPress}
      >
        <>
          <View style={styles.rowStyle}>
            <View style={styles.iconDiv}>
              <OrderTypeIcon />
            </View>
            <View style={styles.infoDiv}>
              <Title16
                numberOfLines={1}
                ellipsizeMode={"tail"}
                bold
              >
                {title}
              </Title16>
              <View style={styles.rowStyle2}>
                <Title13 style={styles.date}>
                  {orderVM.getSmallDateDescription(orderDate)}
                </Title13>
                <Title13 bold style={styles.stateLabel}>
                  {I18n.t(
                    `homepage.comingSoon.${getLabelFromState(orderState)}`
                  )}
                </Title13>
              </View>
              <View style={styles.rowStyle2}>
                <Title13 bold style={styles.date}>
                  {paymentType && orderPaymentTypeLabel}
                </Title13>
              </View>
                <>
                  { orderRating && <View style={styles.orderRating}>
                    <StarActive tooltip={I18n.t('orderRating.stars', { count: orderRating.ratings.globalRating })}/>
                      <Text style={styles.globalRating}>{orderRating.ratings.globalRating}/5</Text>
                    </View>
                  }
                  { isOrderRatingEnabled && <Pressable
                      onPress={() => {
                          openOrderRatingModal();
                      }}
                      style={styles.bringToFront}>
                      { !orderRating && orderVM.isEligibleForRating(orderState, lastUpdateAt) &&
                        <Title13 bold style={[styles.ratingLink]}>{I18n.t('orderRating.rateTheOrder')}</Title13>
                      }
                    </Pressable>
                  }
                </>
            </View>
          </View>
        </>
        <View style={styles.totalDiv}>
          <Title16 bold>{formattedAmount}</Title16>
          <Forward />
        </View>
      </Pressable>
    );
  }
);

const _styles = (isMobile: boolean, stateLabelColor: string) =>
  StyleSheet.create({
    container: {
      marginTop: isMobile ? Spacing.M : Spacing.L,
      flex: 1,
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    rowStyle: {
      flex: 1,
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-start",
    },
    rowStyle2: {
      flex: 1,
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    iconDiv: {
      marginRight: 10,
      justifyContent: "center",
      alignItems: "flex-start",
    },
    infoDiv: {
      flex: 1,
      marginLeft: 0,
      justifyContent: "center",
      alignItems: "flex-start",
    },
    totalDiv: {
      flexDirection: "row",
    },
    date: {
      color: Colors.foodiBlack,
      marginRight: 10,
    },
    stateLabel: {
      color: stateLabelColor,
    },
    orderRating: {
      display: 'flex',
      flexDirection: 'row',
      paddingTop: 1
    },
    globalRating: {
      fontFamily: 'manrope-regular',
      fontSize: 13,
      paddingLeft: 5,
      paddingTop: 2
    },
    ratingLink: {
      textDecorationLine: 'underline',
      color: Colors.blueLink,
      cursor: 'pointer',
      fontSize: 16
    },
    bringToFront: {
      zIndex: 1
    }
  }
);
