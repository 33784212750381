"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetMarketingCards = void 0;
class GetMarketingCards {
    constructor(marketingCardService) {
        this.marketingCardService = marketingCardService;
    }
    execute(request) {
        return this.marketingCardService.getMarketingCards(request);
    }
}
exports.GetMarketingCards = GetMarketingCards;
