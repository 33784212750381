import React from "react";
import { TouchableOpacity } from "react-native";
import { I18n } from "react-redux-i18n";
import _ from "lodash";

import {
  ItemContainer,
  GreenCircle,
  ItemTextContainer,
  ItemDescription,
  ItemTitle,
  ItemTitleState,
} from "./ComingSoonModal.style";
import moment from "moment";
import { OfferTemplateWithdrawalType, OrderState } from "@foodi/core";
import OrderDescription from "./OrderDescription";
import Menu from "./Menu";
import { getDecodedId, getLabelFromState, TestIDs } from "@utils";
import { NavigationProp } from "@react-navigation/native";
import {
  BookingActions,
  ConfirmationPageActions,
  HomeScreens,
  POSScreens,
} from "@modules";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "@redux";

interface IOrder {
  order: any;
  navigation?: NavigationProp<any>;
  handleCloseModal: () => void;
}

const OrderItem: React.FC<IOrder> = React.memo(
  ({ order, navigation, handleCloseModal }) => {
    const userLanguage = useSelector(
      (state: GlobalState) => state.auth?.userInfo?.language
    );
    const dispatch = useDispatch();

    const handleOnPress = () => {
      handleCloseModal();

      const id = getDecodedId(order?.id)?.split(":")?.[1];

      dispatch(BookingActions.setBookingSelected(false));

      dispatch(
        ConfirmationPageActions.setConfirmationPageParams({
          id,
          orderId: order.id,
          isFirstTime: false,
          idPos: -1,
          offerSlot: "",
        })
      );

      navigation?.navigate(HomeScreens.POINT_OF_SALE_STACK, {
        screen: POSScreens.CONFIRMATION_PAGE,
      });
    };

    const pos = order?.offer?.offerTemplate_v2?.pos?.name;
    const cancelledState =
      order?.state === OrderState.CANCELLED ||
      order?.state === OrderState.REFUSED ||
      order?.state === OrderState.ABANDONED;

    const completedState =
      order?.state === OrderState.COMPLETED;

    const orderId =
      order?.offer?.offerTemplate?.withdrawalType ===
      OfferTemplateWithdrawalType.CONNECTED_LOCKERS
        ? getDecodedId(order?.id)?.split(":")?.[1]
        : order?.idWithdrawal;

    return (
      <TouchableOpacity
        testID={TestIDs.home.comingSoon.actions.openOrder}
        onPress={handleOnPress}
      >
        <ItemContainer>
          <ItemTextContainer>
            <GreenCircle />
            <ItemTitle>
              {_.upperFirst(I18n.t("homepage.comingSoon.order"))}
            </ItemTitle>
            {orderId && <ItemTitle>{` #${orderId}`}</ItemTitle>}
            {order?.state && (
              <ItemTitleState cancelled={cancelledState} completed={completedState}>{` ${I18n.t(
                `homepage.comingSoon.${getLabelFromState(order?.state)}`
              )}`}</ItemTitleState>
            )}
          </ItemTextContainer>
          <OrderDescription order={order} pos={pos} />
          <ItemTextContainer noMarginLeft>
            <Menu order={order} />
            {order.totalPrice && (
              <ItemDescription>
                {` ${order?.totalPrice?.amount}€`}
              </ItemDescription>
            )}
          </ItemTextContainer>
          {order.created && (
            <ItemTextContainer noMarginLeft>
              <ItemDescription isDate>
                {moment(order.created)
                  .locale(userLanguage || "fr")
                  .format("DD MMMM YYYY")}
              </ItemDescription>
            </ItemTextContainer>
          )}
        </ItemContainer>
      </TouchableOpacity>
    );
  }
);

export default OrderItem;
