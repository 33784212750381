import React from "react";
import { I18n } from "react-redux-i18n";

export const Delete: React.FC = () => (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
      <title>{I18n.t("profile.delete")}</title>
      <g id="Icons/Delete_black" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path d="M6,19 C6,20.1 6.9,21 8,21 L16,21 C17.1,21 18,20.1 18,19 L18,7 L6,7 L6,19 Z M7.5,8.5 L16.5,8.5 L16.5,19.5 L7.5,19.5 L7.5,8.5 Z M15.5,4 L14.5,3 L9.5,3 L8.5,4 L5,4 L5,5.53170101 L19,5.53170101 L19,4 L15.5,4 Z" id="Shape" fill="#090909" fill-rule="nonzero"></path>
      </g>
    </svg>
);
