"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_GUEST_PAYMENT_PROFILE = void 0;
const client_1 = require("@apollo/client");
const gestWithPaymentProfileFragment_1 = require("./gestWithPaymentProfileFragment");
exports.GET_GUEST_PAYMENT_PROFILE = client_1.gql `
  ${gestWithPaymentProfileFragment_1.GUEST_WITH_PAYMENT_PROFILE_FRAGMENT}
  query getGuestPaymentProfile {
    getUser {
      id
      guests {
        edges {
          node {
            ...guestWithPaymentProfileFragment
          }
        }
      }
    }
  }
`;
