"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeleteAccount = void 0;
class DeleteAccount {
    constructor(authenticationService) {
        this.authenticationService = authenticationService;
    }
    execute(request) {
        return this.authenticationService.deleteAccount(request);
    }
}
exports.DeleteAccount = DeleteAccount;
