import { useLayoutEffect, useState } from "react";

export const useWindowSize = () => {
  const [currentHeight, setCurrentHeight] = useState(0);
  const [currentWidth, setCurrentWidth] = useState(0);
  useLayoutEffect(() => {
    function updateSize() {
      setCurrentHeight(window.innerHeight);
      setCurrentWidth(window.innerWidth);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return [currentHeight, currentWidth];
};
