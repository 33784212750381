"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FOfferService = void 0;
const __1 = require("..");
const __2 = require("../../..");
const domain_1 = require("../../../domain");
const mappers_1 = require("../mappers");
class FOfferService {
    constructor(apolloClient) {
        this.apolloClient = apolloClient;
    }
    getOfferTemplates({ type, withdrawalType, language, posId, }) {
        return __awaiter(this, void 0, void 0, function* () {
            const offerTemplatesMapper = new mappers_1.OfferTemplatesMapper(language);
            try {
                const response = yield this.apolloClient.query({
                    query: __2.GET_OFFER_TEMPLATES,
                    variables: { type, withdrawalType, idPos: posId },
                    fetchPolicy: "no-cache",
                });
                return Promise.resolve(offerTemplatesMapper.toDomain(response));
            }
            catch (error) {
                return Promise.reject(new domain_1.OfferServiceErrors.GetOfferTemplatesRequestError("GET_OFFER_TEMPLATES_REQUEST_FAILED", error));
            }
        });
    }
    getOffer(variables) {
        return __awaiter(this, void 0, void 0, function* () {
            const offerMapper = new mappers_1.OfferMapper();
            try {
                const response = yield this.apolloClient.query({
                    query: __1.GET_OFFER,
                    variables,
                    fetchPolicy: "no-cache",
                });
                return Promise.resolve(offerMapper.toDomain(response));
            }
            catch (error) {
                return Promise.reject(new domain_1.OfferServiceErrors.GetOfferRequestError("GET_OFFER_REQUEST_FAILED", error));
            }
        });
    }
    getOfferSlots(variables) {
        return __awaiter(this, void 0, void 0, function* () {
            const offerSlotsMapper = new mappers_1.OfferSlotsMapper();
            try {
                const response = yield this.apolloClient.query({
                    query: __1.GET_OFFER_SLOTS,
                    variables,
                    fetchPolicy: "no-cache",
                });
                return Promise.resolve(offerSlotsMapper.toDomain(response));
            }
            catch (error) {
                return Promise.reject(new domain_1.OfferServiceErrors.GetOfferSlotsRequestError("GET_OFFER_SLOTS_REQUEST_FAILED", error));
            }
        });
    }
    getBookingTemplateOffer(variables) {
        return __awaiter(this, void 0, void 0, function* () {
            const bookingTemplateOfferMapper = new mappers_1.BookingTemplateOfferMapper();
            try {
                const response = yield this.apolloClient.query({
                    query: __1.GET_BOOKING_OFFER_TEMPLATES,
                    variables,
                    fetchPolicy: "no-cache",
                });
                return Promise.resolve(bookingTemplateOfferMapper.toDomain(response));
            }
            catch (error) {
                return Promise.reject(new domain_1.OfferServiceErrors.GetBookingTemplateOfferRequestError("GET_OFFER_TEMPLATES_REQUEST_FAILED", error));
            }
        });
    }
}
exports.FOfferService = FOfferService;
