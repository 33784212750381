import React from "react";
import { I18n } from "react-redux-i18n";

interface IProps {
  size?: number;
}

export const CheckCircle: React.FC<IProps> = ({ size = 144 }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    viewBox="0 0 144 144"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>{I18n.t("hoverIcons.success")}</title>
    <g
      id="Design-System"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Icons-pg2" transform="translate(-58.000000, -626.000000)">
        <g
          id="check_circle_black_24dp"
          transform="translate(58.000000, 626.000000)"
        >
          <polygon id="Path" points="0 0 144 0 144 144 0 144"></polygon>
          <path
            d="M72,12 C38.88,12 12,38.88 12,72 C12,105.12 38.88,132 72,132 C105.12,132 132,105.12 132,72 C132,38.88 105.12,12 72,12 Z"
            id="Shape"
            fill="#509E58"
            fillRule="nonzero"
          ></path>
          <polygon
            id="Path"
            fill="#FFFFFF"
            fillRule="nonzero"
            points="99.54 45.48 60 85.02 44.46 69.54 36 78 60 102 108 54"
          ></polygon>
        </g>
      </g>
    </g>
  </svg>
);
