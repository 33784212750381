"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetHoldingAffluence = void 0;
class GetHoldingAffluence {
    constructor(holdingRepository) {
        this.holdingRepository = holdingRepository;
    }
    execute() {
        return this.holdingRepository.getHoldingAffluence();
    }
}
exports.GetHoldingAffluence = GetHoldingAffluence;
