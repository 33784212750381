import { Colors } from "@constants";
import style from "styled-components/native";

export const Container = style.View`
    margin-bottom: 50px;
    margin-top: 20px;
    justify-content: center;
    align-items: center;

`;

export const ButtonContainer = style.View<{ isMobile?: boolean }>`
    margin-top: 20px;
    flex-direction:  ${({ isMobile }) => (isMobile ? "column" : "row")};
    justify-content: space-between;
    align-items: center;
`;

export const Button = style.TouchableOpacity<{
  color?: string;
  textColor?: string;
  hasBorder?: boolean;
}>`
    padding: 15px 54px;
    justify-content: center;
    background-color: ${({ color }) => color};
    font-family: manrope-bold;
    margin: 10px;
    font-size: 22px;
    text-align: center;
    border-radius: 8px;
    color: ${({ textColor }) => textColor};
    ${({ hasBorder }) => hasBorder && "border: 1px"}
`;
