import {
  GetScolapassInvoicesCommand,
  GetScolapassPaymentsCommand,
  IScolapassInvoice,
  IScolapassPayment,
} from "@foodi/core";
import { createReducer, ThunkResult } from "@redux";
import { Action } from "@redux/action";
import { displayToastError } from "@utils";

export interface HistoricalState {
  payments: IScolapassPayment[];
  invoices: any[];
}

/*************  Actions  ****************/

const ActionTypes = {
  SET_SCOLAPASS_PAYMENTS: "transaction/SET_SCOLAPASS_PAYMENTS",
  SET_SCOLAPASS_INVOICES: "transaction/SET_SCOLAPASS_INVOICES",
};

const ActionCreators = {
  setScolapassPayments: (
    payments: IScolapassPayment[]
  ): Action<IScolapassPayment[]> => ({
    type: ActionTypes.SET_SCOLAPASS_PAYMENTS,
    payload: payments,
  }),
  setScolapassInvoices: (
    Invoices: IScolapassInvoice[]
  ): Action<IScolapassInvoice[]> => ({
    type: ActionTypes.SET_SCOLAPASS_INVOICES,
    payload: Invoices,
  }),
};

/*************  Side Effects, only if applicable ****************/
// e.g. thunks, epics, etc
const ThunkActionCreators = {
  getScolapassPayments: (
    params: GetScolapassPaymentsCommand
  ): ThunkResult<Promise<void>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { getScolapassPayments } = getDependencies();

      const _scolapassPayments = await getScolapassPayments.execute(params);
      dispatch(
        ActionCreators.setScolapassPayments(
          _scolapassPayments.scolapassPayments
        )
      );
    } catch (e) {
      displayToastError(dispatch);
      return Promise.reject(e);
    }
  },
  getScolapassInvoices: (
    params: GetScolapassInvoicesCommand
  ): ThunkResult<Promise<void>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { getScolapassInvoices } = getDependencies();
      const _scolapassInvoices = await getScolapassInvoices.execute(params);
      dispatch(
        ActionCreators.setScolapassInvoices(
          _scolapassInvoices.scolapassInvoices
        )
      );
    } catch (e) {
      displayToastError(dispatch);
      return Promise.reject(e);
    }
  },
  getInvoiceScolapassFile: (
    fileName: string
  ): ThunkResult<Promise<string>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { getInvoiceScolapassFile } = getDependencies();
      const pdfFile = await getInvoiceScolapassFile.execute({ fileName });
      return Promise.resolve(pdfFile);
    } catch (e) {
      return Promise.reject(e);
    }
  },
};

/*************  Reducer  ****************/

const initialState: HistoricalState = {
  payments: [],
  invoices: [],
};

const Reduction = {
  setScolapassPayments: (
    state: HistoricalState,
    { payload: payments }: Action<IScolapassPayment[]>
  ): HistoricalState => ({
    ...state,
    payments,
  }),
  setScolapassInvoices: (
    state: HistoricalState,
    { payload: invoices }: Action<IScolapassInvoice[]>
  ): HistoricalState => ({
    ...state,
    invoices,
  }),
};

const reducer = createReducer(initialState, {
  [ActionTypes.SET_SCOLAPASS_PAYMENTS]: Reduction.setScolapassPayments,
  [ActionTypes.SET_SCOLAPASS_INVOICES]: Reduction.setScolapassInvoices,
});

export default reducer;

export {
  reducer as HistoricalReducer,
  ActionTypes as HistoricalActionTypes,
  ActionCreators as HistoricalActions,
  ThunkActionCreators as HistoricalThunks,
};
