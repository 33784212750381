"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ADD_EPOINTAGE_COMMENT = void 0;
const client_1 = require("@apollo/client");
exports.ADD_EPOINTAGE_COMMENT = client_1.gql `
  mutation addEPointageComment(
    $siteId: String!
    $etaId: String!
    $claId: String!
    $scheduleId: Int!
    $date: String!
    $updateUser: Int!
    $updateDate: String!
    $comment: String
  ) {
    addEPointageComment(
      siteId: $siteId
      etaId: $etaId
      claId: $claId
      scheduleId: $scheduleId
      date: $date
      updateUser: $updateUser
      updateDate: $updateDate
      comment: $comment
    ) {
      scheduleId
      date
      updateUser
      updateDate
      comment
    }
  }
`;
