import { Action } from "../action";
import { createReducer } from "@redux/createReducer";

export interface ForceInfoAppUpdateState {
  forceUpdate: boolean;
}

/*************  Actions  ****************/

const ActionTypes = {
  SET_FORCE_INFO_APP_UPDATE: "loader/SET_FORCE_INFO_APP_UPDATE",
};

const ActionCreators = {
  setForceAppUpdate: (forceUpdate: boolean): Action<boolean> => ({
    type: ActionTypes.SET_FORCE_INFO_APP_UPDATE,
    payload: forceUpdate,
  }),
};

/*************  Reducer  ****************/

const initialState: ForceInfoAppUpdateState = {
  forceUpdate: false,
};

const Reduction = {
  setForceInfoAppUpdate: (
    state: ForceInfoAppUpdateState,
    { payload: forceUpdate }: Action<boolean>
  ) => ({
    forceUpdate,
  }),
};

const reducer = createReducer(initialState, {
  [ActionTypes.SET_FORCE_INFO_APP_UPDATE]: Reduction.setForceInfoAppUpdate,
});

export default reducer;

export {
  reducer as ForceInfoAppUpdateReducer,
  ActionTypes as ForceInfoAppUpdateActionTypes,
  ActionCreators as ForceInfoAppUpdateActions,
};
