import { Images } from "@assets";
import { Article, Infonews } from "@atomic";
import { Colors } from "@constants";
import { MarketingCard, MarketingCardContents } from "@foodi/core";
import { useDevices } from "@hooks";
import { NavigationProp } from "@react-navigation/native";
import { GlobalState } from "@redux";
import { Title22, Title36 } from "@stylesheets";
import { TestIDs } from "@utils";
import React from "react";
import { FlatList, View, Image } from "react-native";
import { useSelector } from "react-redux";
import { I18n } from "react-redux-i18n";
import { NewsContainer, Row, EmptyImageContainer } from "./News.style";

enum NewsTypes {
  IMPORTANT_MESSAGE = "IMPORTANT_MESSAGE",
  ARTICLE = "ARTICLE",
}

interface IProps {
  navigation: NavigationProp<any>;
  news?: MarketingCard[];
}

const NEWS_CARD_LIST_COLUMNS = 2;
const ARTICLE_CARD_GAP = 130;
const ARTICLE_CARD_DOUBLE_GAP = 240;
export const INFO_NEWS_CARD_GAP = 24;

export const News: React.FC<IProps> = React.memo(({ news, navigation }) => {
  const userLanguage = useSelector(
    (state: GlobalState) => state.auth?.userInfo?.language
  );

  const [isMobile] = useDevices();

  const Title: React.FC<any> = isMobile ? Title22 : Title36;

  const renderItem = ({ item, index }: any) => {
    const gapPerType =
      item.type === NewsTypes.IMPORTANT_MESSAGE
        ? INFO_NEWS_CARD_GAP
        : ARTICLE_CARD_GAP;
    const hasGap = (index + 1) % NEWS_CARD_LIST_COLUMNS !== 0;
    let gap = hasGap ? gapPerType : 0;
    let content: MarketingCardContents | undefined =
      item.marketingCardContents?.[0];
    if (item.marketingCardContents.length > 1) {
      content = item.marketingCardContents?.find(
        (m: MarketingCardContents) => m?.language === userLanguage
      );
    }
    if (item.type === NewsTypes.IMPORTANT_MESSAGE) {
      if (hasGap && news?.[index + 1]?.type === NewsTypes.ARTICLE)
        gap = ARTICLE_CARD_GAP;
      return (
        <NewsContainer
          testID={`${TestIDs.home.news.views.item}_${index}`}
          gap={gap}
          isMobile={isMobile}
        >
          <Infonews
            forwardTestId={`${TestIDs.home.news.views.itemDetail}_${index}`}
            contentTitle={content?.contentTitle}
            resume={content?.resume}
            linkToExternalPage={item?.linkToExternalPage}
          />
        </NewsContainer>
      );
    }

    if (hasGap && news?.[index + 1]?.type === NewsTypes.ARTICLE)
      gap = ARTICLE_CARD_DOUBLE_GAP;

    return (
      <NewsContainer
        testID={`${TestIDs.home.news.views.item}_${index}`}
        gap={gap}
        isMobile={isMobile}
      >
        <Article
          forwardTestId={`${TestIDs.home.news.views.itemDetail}_${index}`}
          navigation={navigation}
          title={content?.contentTitle}
          resume={content?.resume}
          date={item.publicationRange}
          image={item?.mainImage?.path}
          content={content?.content}
          fullContent={item.marketingCardContents}
        />
      </NewsContainer>
    );
  };

  return (
    <View testID={TestIDs.home.news.views.mainContainer}>
      <Title
        testID={TestIDs.home.news.texts.titleText}
        color={Colors.foodiBlack}
      >
        {I18n.t("homepage.news")}
      </Title>
      {!isMobile && news?.length === 1 ? (
        <Row>
          {renderItem({ item: news[0], index: 0 })}
          <EmptyImageContainer>
            <Image
              style={{ width: 560, height: 400 }}
              source={{ uri: Images.InfonewsEmpty }}
            />
          </EmptyImageContainer>
        </Row>
      ) : (
        <FlatList
          style={{ marginTop: 24 }}
          data={news}
          key={isMobile ? "yM" : "nM"}
          renderItem={renderItem}
          keyExtractor={(item: any) => item?.id}
          numColumns={!isMobile ? NEWS_CARD_LIST_COLUMNS : 1}
        />
      )}
    </View>
  );
});
