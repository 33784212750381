"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CANCEL_BOOKING_ORDER = void 0;
const client_1 = require("@apollo/client");
exports.CANCEL_BOOKING_ORDER = client_1.gql `
  mutation cancelBookingOrder($idOrder: ID!, $newState: OrderState!) {
    order: changeBookingOrderState(idOrder: $idOrder, newState: $newState) {
      id
      cancelableUntil
      cancelableAt
      state
    }
  }
`;
