import { StyleSheet, View } from "react-native";
import React, { useMemo } from "react";
import { Information } from "@assets/icons/Information";
import { Title16 } from "@stylesheets";
import { I18n } from "react-redux-i18n";
import { Colors, Spacing } from "@constants";
import { useDevices } from "@hooks";

interface IProps {
    offerTemplatesError?: string;
    refreshDelay?: number;
}

export const OffersNoData: React.FC<IProps> = React.memo(
    ({ offerTemplatesError, refreshDelay }) => {
        const [isMobile] = useDevices();
        const styles = useMemo(() => _styles(isMobile), [isMobile]);

        const [countdown, setCountdown] = React.useState(refreshDelay);

        React.useEffect(() => {
          if (countdown && (countdown > 0)) {
            const countdownInterval = setInterval(() => {
              setCountdown((prevCountdown) => prevCountdown ? prevCountdown - 1 : undefined);
              // we want to set the new value each second that passes
            }, 1000);
      
            return () => clearInterval(countdownInterval);
          }
        }, [countdown]);

        const count = offerTemplatesError ? { count: countdown } : undefined;
        const message = offerTemplatesError? I18n.t('restaurantDetail.offersError', count) : undefined;
        
        return (
            <View style={styles.container}>
                {message && (
                    <View style={styles.info}>
                        <Information/>
                        <Title16 style={styles.infoText}>
                            {message}
                        </Title16>
                    </View>
                 )}
            </View>
        );
    }
);

const _styles = (isMobile: boolean) =>
    StyleSheet.create({
        container: {
            backgroundColor: Colors.background1,
            marginTop: isMobile ? 20 : 41,
        },
        info: {
            marginTop: 45,
            flexDirection: "row",
            paddingLeft: isMobile ? 18 : 0,
            width: "100vw",
        },
        infoText: {
            marginLeft: 6,
        },
        title: {
            marginTop: Spacing.M,
            textAlign: "left",
            paddingLeft: isMobile ? 18 : 0,
            width: "100vw",
        },
    });
