"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_OFFER_SLOTS = void 0;
const client_1 = require("@apollo/client");
exports.GET_OFFER_SLOTS = client_1.gql `
  query getOfferSlots($idOffer: ID!) {
    offer: get(id: $idOffer) {
      ... on Offer {
        id
        slots: upcomingSlots {
          selectableAt
          withdrawRange
          numOrders
        }
      }
    }
  }
`;
