import React from "react";

export const VisibilityOn: React.FC = () => (
  <svg width="22px" height="15px" xmlns="http://www.w3.org/2000/svg">
    <title>{"icons/visibility_off"}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M-1-4h24v24H-1V-4Zm0 0h24v24H-1V-4Zm0 0h24v24H-1V-4Zm0 0h24v24H-1V-4Z" />
      <path d="M-1-4h24v24H-1z" />
      <path
        d="M11 2a9.77 9.77 0 0 1 8.82 5.5A9.77 9.77 0 0 1 11 13a9.77 9.77 0 0 1-8.82-5.5A9.77 9.77 0 0 1 11 2m0-2C6 0 1.73 3.11 0 7.5 1.73 11.89 6 15 11 15s9.27-3.11 11-7.5C20.27 3.11 16 0 11 0Zm0 5a2.5 2.5 0 0 1 0 5 2.5 2.5 0 0 1 0-5m0-2C8.52 3 6.5 5.02 6.5 7.5S8.52 12 11 12s4.5-2.02 4.5-4.5S13.48 3 11 3Z"
        fill="#999"
        fillRule="nonzero"
      />
    </g>
  </svg>
);
