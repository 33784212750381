import React from "react";

export const AccordeonArrow: React.FC = () => (
  <svg
    width="12px"
    height="8px"
    viewBox="0 0 12 8"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Icons/Navigation/Arrows/Accordeon_arrow_default_black</title>
    <g
      id="Homepage"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Restaurant/Menu/Order/Basket-full"
        transform="translate(-1264.000000, -1367.000000)"
      >
        <g id="Basket" transform="translate(1009.000000, 805.000000)">
          <g id="Group" transform="translate(249.000000, 554.000000)">
            <g
              id="keyboard_arrow_right_black_24dp"
              transform="translate(12.000000, 12.000000) rotate(90.000000) translate(-12.000000, -12.000000) "
            >
              <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
              <polygon
                id="Path"
                fill="#090909"
                fill-rule="nonzero"
                points="8.59 16.59 13.17 12 8.59 7.41 10 6 16 12 10 18"
              ></polygon>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
