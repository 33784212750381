import React, { useState } from "react";
import { OrderItem } from "@foodi/core";
import {
  Column,
  ContainerDescription,
  ProductDescription,
  ProductDescriptionContainer,
  Row,
} from "./Cart.style";
import { OfferViewModel } from "@modules";
import { Tag } from "@atomic/atoms";
import { I18n } from "react-redux-i18n";
import { getLabelArticleBaking } from "@utils";
import { useDevices } from "@hooks";

interface IProductsInformations {
  products: OrderItem[];
  hasContainer: boolean;
  setHasContainer: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ProductsInformations = ({
  products,
  hasContainer,
  setHasContainer,
}: IProductsInformations) => {
  const [isMobile] = useDevices();
  const [offerVM] = useState(new OfferViewModel());
  return (
    <>
      {products.map((p: OrderItem, index: number) => {
        const amount = offerVM.getProductAmount(String(Number(p?.priceWhenAdded?.amount) * p?.quantity));

        const containerAmount = offerVM.getContainerAmount(
          String(Number(p?.containerPriceWhenAdded ? p?.containerPriceWhenAdded?.amount : "") * p?.quantity)
        );

        if (!hasContainer && p.containerLabelWhenAdded) {
          setHasContainer(true);
        }

        return (
          <>
            <Row
              key={p.id}
              isLast={!p.chosenBaking && index === products.length - 1}
              isCompact={!!p.chosenBaking}
            >
              <ProductDescriptionContainer>
                <ProductDescription marginRight={5}>
                  {p.quantity}
                </ProductDescription>
                <Column isCompact={!!p.chosenBaking}>
                  <ProductDescription>{p.labelWhenAdded}</ProductDescription>
                  {!!p?.products?.length && p?.products?.map(
                    (subP: any) => (
                      <ProductDescription width={'100%'}>- {subP.quantity} {subP.label}</ProductDescription>
                    )
                  )}
                  {p.containerLabelWhenAdded && (
                    <ContainerDescription isMobile={isMobile} numberOfLines={2}>
                      {I18n.t("restaurantDetail.cart.container")}{" "}
                      {p.containerLabelWhenAdded}
                    </ContainerDescription>
                  )}
                </Column>
              </ProductDescriptionContainer>
              <Column isCompact={!!p.chosenBaking}>
                <ProductDescription alignSelf="flex-end">
                  {amount.replace(".", ",")}€
                </ProductDescription>
                {p.containerLabelWhenAdded && containerAmount !== "" && (
                  <ProductDescription alignSelf="flex-end">
                    {containerAmount.replace(".", ",")}€
                  </ProductDescription>
                )}
              </Column>
            </Row>
            {!!p.chosenBaking && (
              <Row isLast={index === products.length - 1}>
                <Column>
                  <Tag
                    isSelected
                    text={I18n.t(
                      `restaurantDetail.articleBaking.${getLabelArticleBaking(
                        p.chosenBaking
                      )}`
                    )}
                  />
                </Column>
              </Row>
            )}
          </>
        );
      })}
    </>
  );
};
