import React from "react";

export const ArticleTag: React.FC = () => (
  <svg
    width="89px"
    height="24px"
    viewBox="0 0 89 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Homepage"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="HP-UI-D---Default"
        transform="translate(-190.000000, -1979.000000)"
      >
        <g id="L'actualité" transform="translate(150.000000, 1887.000000)">
          <g id="Article" transform="translate(0.000000, 92.000000)">
            <g
              id="atom/restaurant/label-copy-4"
              transform="translate(40.000000, 0.000000)"
            >
              <g id="Group-3" transform="translate(10.000000, 6.000000)">
                <path
                  d="M2.59448818,11.2975782 C3.06369295,12.145059 3.68225633,12.6277874 4.45017832,12.7457637 C5.6020613,12.922728 8.32903595,13.2565015 9.19346371,12.6605077 C10.0578915,12.064514 10.7839348,11.3166998 10.5655143,9.53397771 C10.4199007,8.34549634 9.20243335,6.40174453 6.91311227,3.70272228 C6.02434221,5.86982922 5.20336422,7.09853796 4.45017832,7.38884851 C3.32039946,7.82431434 2.22182643,8.77948897 2.12833086,9.53397771 C2.06600047,10.0369702 2.22138625,10.624837 2.59448818,11.2975782 Z"
                  id="Path-6"
                  fill="#F4B83A"
                ></path>
                <path
                  d="M4.45017832,12.7457637 C-0.729988618,11.0406448 -1.54134007,8.01405877 2.82747542,4.17754127 C2.88988707,5.20061261 3.01471037,6.18105597 3.88847347,6.7352196 C3.63882687,5.84003219 2.57782882,2.68556225 8.31970061,0 C6.57217442,5.58426436 18.9920927,7.16149933 9.19346371,12.6605077 C11.814753,9.0371301 7.38352586,9.16501402 7.25870256,5.79740422 C5.26152977,7.11887135 5.07429482,8.48296646 5.82323462,10.0602014 C5.01188317,9.84706157 4.20053172,9.29289794 3.70123852,8.56822241 C2.82747542,10.1028294 3.63882687,11.6800644 4.45017832,12.7457637 Z"
                  id="Path"
                  fill="#FF3131"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
