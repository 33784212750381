export const getCloudImageUrl = (
  // tslint:disable-next-line: no-any
  url: any,
  operation?: string,
  size?: string,
  filter?: string
): string => {
  let newUrl: string = window.config.CLOUDIMAGE_BASE_URL + "/";
  newUrl += operation ? operation + "/" : "n/";
  newUrl += size ? size + "/" : "n/";
  newUrl += filter ? filter + "/" : "n/";
  newUrl += url;

  return newUrl;
};

export default getCloudImageUrl;
