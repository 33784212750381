import React from "react";
import { I18n } from "react-redux-i18n";

export const VisibilityBlack: React.FC = () => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>{I18n.t("hoverIcons.open")}</title>
    <g
      id="Homepage"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="Solde-Copy-10" transform="translate(-174.000000, -799.000000)">
        <g id="Group" transform="translate(-0.000000, 414.000000)">
          <g id="Group-6" transform="translate(18.000000, 140.000000)">
            <g id="LeSelf-Copy-5" transform="translate(0.000000, 224.000000)">
              <g
                id="visibility_black"
                transform="translate(156.000000, 21.000000)"
              >
                <polygon id="Path" points="0 0 20 0 20 20 0 20"></polygon>
                <path
                  d="M10,5 C13.1583333,5 15.975,6.775 17.35,9.58333333 C15.975,12.3916667 13.1583333,14.1666667 10,14.1666667 C6.84166667,14.1666667 4.025,12.3916667 2.65,9.58333333 C4.025,6.775 6.84166667,5 10,5 M10,3.33333333 C5.83333333,3.33333333 2.275,5.925 0.833333333,9.58333333 C2.275,13.2416667 5.83333333,15.8333333 10,15.8333333 C14.1666667,15.8333333 17.725,13.2416667 19.1666667,9.58333333 C17.725,5.925 14.1666667,3.33333333 10,3.33333333 Z M10,7.5 C11.15,7.5 12.0833333,8.43333333 12.0833333,9.58333333 C12.0833333,10.7333333 11.15,11.6666667 10,11.6666667 C8.85,11.6666667 7.91666667,10.7333333 7.91666667,9.58333333 C7.91666667,8.43333333 8.85,7.5 10,7.5 M10,5.83333333 C7.93333333,5.83333333 6.25,7.51666667 6.25,9.58333333 C6.25,11.65 7.93333333,13.3333333 10,13.3333333 C12.0666667,13.3333333 13.75,11.65 13.75,9.58333333 C13.75,7.51666667 12.0666667,5.83333333 10,5.83333333 Z"
                  id="Shape"
                  fill="#090909"
                  fill-rule="nonzero"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
