import { Colors } from "@constants";
import style from "styled-components/native";

export const Container = style.TouchableOpacity<{
  color?: string;
}>`
  background-color: ${Colors.white};
  justify-content: center;
  border-radius: 8px;
  border-width: 1;
  color: ${({ color }) => color || Colors.foodiBlack};
  border-color: ${({ color }) => color || Colors.foodiBlack};
  
`;

export const Label = style.Text<{
  textColor?: string;
  hide?: boolean;
}>`
  padding: 15px 54px;
  color: ${({ textColor }) => textColor || Colors.foodiBlack};
  font-family: manrope-bold;
  font-size: 22px;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  opacity: ${({ hide }) => hide ? 0 : 1};
`;
