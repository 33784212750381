"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetEpointageServices = void 0;
class GetEpointageServices {
    constructor(epointageRepository) {
        this.epointageRepository = epointageRepository;
    }
    execute(request) {
        return this.epointageRepository.getEpointageServices(request);
    }
}
exports.GetEpointageServices = GetEpointageServices;
