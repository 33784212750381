// Redux
import {
  loadTranslations,
  setLocale,
  syncTranslationWithStore,
} from "react-redux-i18n";
import {
  AnyAction,
  applyMiddleware,
  compose,
  createStore,
  Middleware,
} from "redux";
import { createLogger } from "redux-logger";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk, { ThunkAction, ThunkDispatch } from "redux-thunk";
import { Dependencies, dependencyContainer } from "../configuration";
import { rootReducer, StoreState } from "./rootReducer";
import { translations } from "../i18n";
import { ILanguage } from "@foodi/core";
import { JEST } from "@utils";

export type ThunkResult<R> = ThunkAction<
  R,
  StoreState,
  ExtraArgument,
  AnyAction
>;

export interface ExtraArgument {
  getDependencies: () => Dependencies;
}

const persistenceConfigs = {
  key: "root", // whatever you want to keep as your key
  storage,
  whitelist: [
    "auth",
    "account",
    "brandTheme",
    "transactions",
    "historical",
    "getAppMobile",
    "pointOfSale",
    "offers",
    "booking",
    "payments",
    "confirmationPage",
    "articlePage",
  ],
};

const devTools = () => {
  // @ts-ignore
  const devtoolswindow = window as any;
  return (
    (devtoolswindow.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      devtoolswindow.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()) ||
    compose
  );
};

const middlewares: Middleware[] = [];

const configureStore = () => {
  // Set to true to enable devTools
  const debugging = window.config.ENV === 'development';
  const logger = createLogger({
    // ...options: @see https://github.com/LogRocket/redux-logger
    level: {
      prevState: false,
      action: "log",
      nextState: false,
      error: "error",
    },
    duration: true,
    collapsed: true,
    colors: {
      title: false,
      prevState: false,
      action: false,
      nextState: false,
      error: false,
    },
  });
  middlewares.push(
    thunk.withExtraArgument<ExtraArgument>({
      getDependencies: dependencyContainer,
    })
  );
  // istanbul ignore next
  if (true) {
    middlewares.push(logger);
  }

  middlewares.push(thunk);

  const persistedReducer = persistReducer(persistenceConfigs, rootReducer);
  const f = applyMiddleware<ThunkDispatch<StoreState, ExtraArgument, any>>(
    ...middlewares
  )(createStore);
  return debugging ? f(persistedReducer, devTools()) : f(persistedReducer);
};

export const store = configureStore();
syncTranslationWithStore(store);
store.dispatch(loadTranslations(translations));

const defaultLanguage =  JEST || navigator.language.slice(0,2) === ILanguage.fr ? ILanguage.fr : ILanguage.en
store.dispatch(setLocale(defaultLanguage));

export const persistedStore = persistStore(store);
export type AppDispatch = typeof store.dispatch;
