import React, { useState } from "react";
import { StyleSheet } from "react-native";
import { Container, Link, ListContainer } from "./ExternalServicesList.style";
import { I18n } from "react-redux-i18n";
import { TestIDs } from "@utils";
import { PosViewModel } from "@modules";
import { Title14, Title16, Title18, Title22 } from "@stylesheets";
import { ExternalServiceLink } from "@atomic/atoms";
import { useDevices } from "@hooks";
import { IExternalService } from "@foodi/core";

interface IProps {
  externalServices: IExternalService[];
}

export const ExternalServicesList: React.FC<IProps> = React.memo(
  ({ externalServices }) => {
    const [isMobile] = useDevices();
    const [posVM] = useState(new PosViewModel());
    const externalServicesActive = posVM.getActiveExternalServices(
      externalServices
    );

    const Title: React.FC<any> = isMobile ? Title16 : Title22;
    const TextLink: React.FC<any> = isMobile ? Title14 : Title18;

    return (
      <>
        {externalServicesActive?.length > 0 && (
          <Container
            testID={TestIDs.restaurantDetail.views.externalServicesList}
          >
            <Title style={styles.alignText} isBlack bold>
              {I18n.t("homepage.restaurantCard.externalServices")}
            </Title>
            <ListContainer>
              {externalServicesActive?.map(
                (service: any, index: number) =>
                  service.active && (
                    <Link>
                      <ExternalServiceLink service={service} index={index} />
                      <TextLink isBold bold>
                        {index + 1 !== externalServicesActive?.length &&
                          "  |  "}
                      </TextLink>
                    </Link>
                  )
              )}
            </ListContainer>
          </Container>
        )}
      </>
    );
  }
);

const styles = StyleSheet.create({
  alignText: {
    textAlign: "left",
  },
});
