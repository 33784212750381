import React, { useState, useMemo } from "react";
import { StyleSheet, Text, View, TouchableOpacity, TouchableWithoutFeedback} from "react-native";
import { I18n } from "react-redux-i18n";
import { Colors } from "@constants/Colors";
import { Title16, Title22 } from "@stylesheets";
import { Button, FloatTextInput, ModalTemplate } from "@atomic";
import { TestIDs } from "@utils";
import { responsiveSizeWidth, useDevices } from "@hooks";
import { ToolTip } from "@assets";
import { NavigationProp } from "@react-navigation/core";
import { AccountScreens } from "@modules/account/navigation/AccountScreens";
import { AuthThunks } from "@modules/auth/ducks/auth";
import { useDispatch } from "react-redux";

interface IProps {
  navigation: NavigationProp<any>;
  signupInputData: any;
  closeModal: () => void;
  handleSignUp: (skipStep: boolean) => void;
}

const BadgeValidationModal: React.FC<IProps> = ({
  navigation,
  signupInputData,
  closeModal,
  handleSignUp,
}) => {
  const dispatch = useDispatch();
  const [isMobile] = useDevices();
  const styles = useMemo(() => _styles(isMobile), [isMobile]);
  const [loading, setLoading] = useState(false);
  const [errorGraphQLMessage, setErrorGraphQLMessage] = useState("");
  const [securityCode, setSecurityCode] = useState<string>("");
  const [showToolTipMessage, setShowToolTipMessage] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [
    wrongSecuriyCodeMessage,
    setWrongSecuriyCodeMessage,
  ] = useState<string>("");

  const isSubmitBtnDisabled = !securityCode || showError;

  const onChangeSecurityCode = (text: string) => {
    setShowError(false);
    setWrongSecuriyCodeMessage("");

    setSecurityCode(text);
  };

  const onSubmitSecurityCode = async () => {
    const inputWithCode = {
      input: {
        ...signupInputData,
        guestValidationCode: securityCode,
      },
    };
    try {
      setLoading(true);
      await dispatch(AuthThunks.signUp(inputWithCode));
      navigation.navigate(AccountScreens.STEP_THREE_SCREEN);
      closeModal();
    } catch (error) {
      if (
        error?.cause?.message.includes("Validation code invalid") ||
        error?.cause?.message.includes("Validation code needed")
      ) {
        setShowError(true);
        setWrongSecuriyCodeMessage(
          I18n.t("account.badgeValidationModal.securityCodeNotMatchWithBadge")
        );
        setSecurityCode("");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalTemplate
      forwardTestID={TestIDs.account.views.badgeValidationModalModal}
      handleClose={closeModal}
      isMobile={isMobile}
      isFullScreen={isMobile}
      style={styles.modalTemplate}
      closeOnClickOutside
      isOpen
      isCenter
    >
      <TouchableWithoutFeedback onPress={() => setShowToolTipMessage(false)}>
        <View style={styles.container}>
          <View style={styles.textContainer}>
            <Title22 isBlack>
              {I18n.t("account.badgeValidationModal.securityStep")}
            </Title22>
          </View>
          <View style={styles.textContainer}>
            <Title16>
              {I18n.t("account.badgeValidationModal.enterSecurityCode")}
            </Title16>
          </View>
          <View style={styles.listContainer}>
            <View style={styles.textInputContainer}>
              <FloatTextInput
                forwardTestID={TestIDs.account.texts.securityCode}
                style={styles.textInput}
                onChangeValue={onChangeSecurityCode}
                value={securityCode}
                hasError={showError}
                errorMessage={wrongSecuriyCodeMessage}
                textPlaceHolder={I18n.t(
                  "account.badgeValidationModal.securityCode"
                )}
                maxLength={6}
              />
            </View>
            <View style={styles.toolTipContainer}>
              <TouchableOpacity
                onPress={() => setShowToolTipMessage(!showToolTipMessage)}
              >
                <ToolTip />
                {showToolTipMessage && (
                  <View>
                    <Text style={styles.toolTipText}>
                      {I18n.t(
                        "account.badgeValidationModal.securityCodeFoundAssociatedBadge"
                      )}
                    </Text>
                  </View>
                )}
              </TouchableOpacity>
            </View>
          </View>
          <View style={styles.textContainer}>
            <Button
              forwardTestID={
                TestIDs.account.actions.pressBadgeValidationModalViewModal
              }
              loading={loading}
              onPress={onSubmitSecurityCode}
              disabled={isSubmitBtnDisabled}
              label={I18n.t("account.badgeValidationModal.validateBtn")}
              styleButton={styles.firstButton}
            />
          </View>
        </View>
      </TouchableWithoutFeedback>
    </ModalTemplate>
  );
};

const _styles = (isMobile: boolean) =>
  StyleSheet.create({
    modalTemplate: {
      minHeight: 'auto'
    },
    container: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    textContainer: {
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      margin: 4,
      zIndex: 9999,
    },
    listContainer: {
      flexDirection: "row",
      alignItems: "center",
      backgroundColor: Colors.white,
      zIndex: 99999,
    },
    textInputContainer: {
      margin: 10,
      backgroundColor: Colors.white,
      width: isMobile ? responsiveSizeWidth(320) : 350,
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 8,
      borderWidth: 1,
      borderColor: Colors.transparent,
    },
    textInput: {
      height: 50,
      width: isMobile ? responsiveSizeWidth(320) : 350,
      fontSize: isMobile ? 14 : 16,
      color: Colors.foodiBlack,
      fontWeight: "400",
      borderRadius: 8,
      paddingHorizontal: 17,
      paddingTop: 15,
      paddingBottom: 14,
      maxHeight: 120,
    },
    firstButton: {
      marginTop: 16,
      alignSelf: "center",
    },
    toolTipContainer: {
      position: "relative",
      flex: 1,
    },
    toolTipText: {
      borderRadius: 4,
      fontFamily: "manrope",
      justifyContent: "center",
      width: isMobile ? responsiveSizeWidth(278) : 270,
      minWidth: 270,
      minHeight: 10,
      backgroundColor: Colors.background1,
      textAlign: "left",
      padding: 5,
      position: "absolute",
      top: "130%",
      left: "50%",
      marginLeft: isMobile ? responsiveSizeWidth(-270) : -210,
    },
  });

export default React.memo(BadgeValidationModal);
