import {
  GetBalanceCommand,
  CheckEmailCommand,
  CheckEmailResponse,
  GetNearHoldingsCommand,
  GetNearHoldingsResponse,
  SendUserEmailVerificationCommand,
  SendResetPasswordEmailCommand,
} from "@foodi/core";
import { UserBalance } from "@foodi/core/lib/domain/valueObjects/UserBalance";
import { createReducer, ThunkResult } from "@redux";
import { Action } from "@redux/action";
import { displayToastError } from "@utils";

export interface UserInfoStep1 {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  checkBoxComm: boolean;
  isSSO: boolean;
}
export interface AccountState {
  userBalance: UserBalance | null;
  currentStepIndex: number;
  userInfoStep1: UserInfoStep1 | null;
}

/*************  Actions  ****************/

const ActionTypes = {
  SET_USER_BALANCE: "account/SET_USER_BALANCE",
  SET_CURRENT_STEP_INDEX: "account/SET_CURRENT_STEP_INDEX",
  SET_USER_INFO_STEP1: "account/SET_USER_INFO_STEP1",
};

const ActionCreators = {
  setUserBalance: (userBalance: UserBalance | null): Action<UserBalance | null> => ({
    type: ActionTypes.SET_USER_BALANCE,
    payload: userBalance,
  }),
  setCurrentStepIndex: (currentStepIndex: number): Action<number> => ({
    type: ActionTypes.SET_CURRENT_STEP_INDEX,
    payload: currentStepIndex,
  }),
  setUserInfoStep1: (
    userInfoStep1: UserInfoStep1 | null
  ): Action<UserInfoStep1 | null> => ({
    type: ActionTypes.SET_USER_INFO_STEP1,
    payload: userInfoStep1,
  }),
};

/*************  Side Effects, only if applicable ****************/
// e.g. thunks, epics, etc
const ThunkActionCreators = {
  getBalance: (params: GetBalanceCommand): ThunkResult<Promise<void>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { getBalance } = getDependencies();
      const { balance,allowedBalance } = await getBalance.execute(params);
      dispatch(ActionCreators.setUserBalance({balance,allowedBalance}));
    } catch (e) {
      return Promise.reject(e);
    }
  },
  checkEmail: (
    params: CheckEmailCommand
  ): ThunkResult<Promise<CheckEmailResponse>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { checkEmail } = getDependencies();
      return checkEmail.execute(params);
    } catch (e) {
      displayToastError(dispatch);
      return Promise.reject(e);
    }
  },
  getNearHoldings: (
    params: GetNearHoldingsCommand
  ): ThunkResult<Promise<GetNearHoldingsResponse>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { getNearHoldings } = getDependencies();
      return getNearHoldings.execute(params);
    } catch (e) {
      displayToastError(dispatch);
      return Promise.reject(e);
    }
  },
  sendUserEmailVerification: (
    params: SendUserEmailVerificationCommand
  ): ThunkResult<Promise<any>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { sendUserEmailVerification } = getDependencies();
      return sendUserEmailVerification.execute(params);
    } catch (e) {
      displayToastError(dispatch);
      return Promise.reject(e);
    }
  },
  sendUserResetPasswordEmail: (
    params: SendResetPasswordEmailCommand
  ): ThunkResult<Promise<any>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { sendResetPasswordEmail } = getDependencies();
      const config = {
        ...params,
        AUTH0_WEB_CLIENT_ID: window.config.AUTH0_WEB_CLIENT_ID,
        AUTH0_WEB_DOMAIN: window.config.AUTH0_WEB_DOMAIN,
        AUTH0_WEB_RESET_PASSWORD: window.config.AUTH0_WEB_RESET_PASSWORD,
        AUTH0_WEB_CONNECTION: window.config.AUTH0_WEB_CONNECTION,
      };
      return sendResetPasswordEmail.execute(config);
    } catch (e) {
      displayToastError(dispatch);
      return Promise.reject(e);
    }
  },
};

/*************  Reducer  ****************/

const initialState: AccountState = {
  userBalance: null,
  currentStepIndex: 1,
  userInfoStep1: null,
};

const Reduction = {
  setUserInfo: (
    state: AccountState,
    { payload: userBalance }: Action<UserBalance>
  ): AccountState => ({
    ...state,
    userBalance,
  }),
  setCurrentStepIndex: (
    state: AccountState,
    { payload: currentStepIndex }: Action<number>
  ): AccountState => ({
    ...state,
    currentStepIndex,
  }),
  setUserInfoStep1: (
    state: AccountState,
    { payload: userInfoStep1 }: Action<UserInfoStep1>
  ): AccountState => ({
    ...state,
    userInfoStep1,
  }),
};

const reducer = createReducer(initialState, {
  [ActionTypes.SET_USER_BALANCE]: Reduction.setUserInfo,
  [ActionTypes.SET_CURRENT_STEP_INDEX]: Reduction.setCurrentStepIndex,
  [ActionTypes.SET_USER_INFO_STEP1]: Reduction.setUserInfoStep1,
});

export default reducer;

export {
  reducer as AccountReducer,
  ActionTypes as AccountActionTypes,
  ActionCreators as AccountActions,
  ThunkActionCreators as AccountThunks,
};
