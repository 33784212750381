import { Colors } from "@constants";
import style from "styled-components/native";

export const Wrapper = style.View<{ isMobile?: boolean }>`
    height: ${({ isMobile }) => (isMobile ? 244 : 412)}px;
    justify-content: flex-end;
`;

export const Container = style.View<{ isMobile?: boolean }>`
    height: ${({ isMobile }) => (isMobile ? 232 : 400)}px;
    width: ${({ isMobile }) => (isMobile ? 378 : 560)}px;
    padding: ${({ isMobile }) =>
      isMobile ? "31px 18px 16px 18px" : "100px 40px"};
`;

export const Row = style.Pressable`
    max-width: 200px;
    flex-direction: row;
    align-items: center;
`;

export const TitleContainer = style.View`
    margin-bottom: 10px;
`;

export const ResumeContainer = style.View<{ isMobile?: boolean }>`
    min-height: 120px;    
    margin-bottom: ${({ isMobile }) => (isMobile ? 3 : 20)}px;
`;

export const IconContainer = style.View`
    margin-left: 8px;
    top: 3px;
`;

export const ImageContainer = style.View`
    position: absolute;
`;

export const TagIconContainer = style.View`
    z-index: -1;
    position: absolute;
    width: 124px;
    border-radius: 8px;
    background-color: ${Colors.tagsGray};
`;

export const Tag = style.View<{ isMobile?: boolean }>`
    justify-content: center;
    top: 10px;
    left: ${({ isMobile }) => (isMobile ? 18 : 40)}px;
`;

export const TagTextContainer = style.View`
    margin-left: 40px;
`;
