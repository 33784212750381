import { Colors } from "@constants";
import { TableNumberViewModel } from "@modules";
import React, { useState } from "react";
import { Platform, StyleSheet, TextInput, View, ViewStyle } from "react-native";

interface IProps {
  isValidNumber: boolean;
  forwardTestID?: string;
  style?: ViewStyle;
  textStyle?: ViewStyle;
  setValidInput?: Function;
  callback: (tableNumber: number, isValid: boolean) => void;
  validNumbers?: number[];
}

export const TableNumberInput: React.FC<IProps> = React.memo(
  ({ isValidNumber, forwardTestID, setValidInput, callback, validNumbers }) => {
    const [value, setValue] = useState<string>("");

    const onChangeTableNumber = (value: string) => {
      const tableValue = TableNumberViewModel.getCorrectValue(value);
      const tableNumber = parseInt(tableValue);
      setValue(tableValue);
      const _isValidNumber = TableNumberViewModel.isTableNumberValid(tableNumber)
        && (!validNumbers || validNumbers.includes(tableNumber));
      setValidInput?.(_isValidNumber);
      callback(tableNumber, _isValidNumber);
    };

    return (
      <View style={[styles.container, !isValidNumber && styles.invalidInput]}>
        <TextInput
          testID={forwardTestID}
          style={styles.textInput}
          onChangeText={onChangeTableNumber}
          keyboardType="numeric"
          value={`${value}`}
          maxLength={3}
          selection={{ start: value.length }}
        />
      </View>
    );
  }
);

const styles = StyleSheet.create({
  container: {
    margin: 10,
    backgroundColor: Colors.white,
    height: 50,
    width: 80,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,
    borderWidth: 1,
    borderColor: Colors.border1,
  },
  textInput: {
    height: 40,
    width: 70,
    fontSize: 22,
    color: Colors.foodiBlack,
    fontWeight: "bold",
    textAlign: "center",
    ...(Platform.OS === "web" && {
      outlineColor: "transparent",
    }),
  },
  invalidInput: {
    borderWidth: 2,
    borderColor: Colors.redError,
  },
  invalidText: {
    color: Colors.redError,
    marginLeft: 2,
  },
  invalidDiv: {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
});
