"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateEpointageScheduler = exports.AddEpointageScheduler = void 0;
class AddEpointageScheduler {
    constructor(epointageRepository) {
        this.epointageRepository = epointageRepository;
    }
    execute(request) {
        return this.epointageRepository.addEpointageScheduler(request);
    }
}
exports.AddEpointageScheduler = AddEpointageScheduler;
class UpdateEpointageScheduler {
    constructor(epointageRepository) {
        this.epointageRepository = epointageRepository;
    }
    execute(request) {
        return this.epointageRepository.updateEpointageScheduler(request);
    }
}
exports.UpdateEpointageScheduler = UpdateEpointageScheduler;
