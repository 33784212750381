"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CHECK_EMAIL = void 0;
const client_1 = require("@apollo/client");
exports.CHECK_EMAIL = client_1.gql `
query signUpCheckEmail($email: String!) {
    checkEmail(email: $email) {
        userExist
        SAML
        valid
    }
  }
`;
