import style from "styled-components/native";

import { Colors, Spacing } from "@constants";

export const Wrapper = style.View<{ isMobile?: boolean }>`
    padding: 35px ${({ isMobile }) => (isMobile ? "18px" : "0px")};
    background-color: ${Colors.footerBackgroundGray};
`;

export const Container = style.View<{ isMobile?: boolean, marginBottom?: number }>`
    flex-wrap: wrap;
    flex-direction: ${({ isMobile }) => (isMobile ? "column" : "row")};
    justify-content: center;
    align-items: ${({ isMobile }) => (isMobile ? "flex-start" : "center")};
    margin-bottom: ${({ isMobile, marginBottom }) => (isMobile ? ( !!marginBottom ? marginBottom : "4") : "14")}px;
`;

export const Row = style.View<{ isMobile?: boolean }>`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${({ isMobile }) => (isMobile ? "10px" : "0px")};
`;

export const FooterText = style.Text<{ isMobile?: boolean }>`
    font-size: ${({ isMobile }) => (isMobile ? Spacing.M : "18")}px;
    color: ${Colors.footerGray};
    font-family: "manrope";
`;

export const Gap = style.View<{ isMobile?: boolean }>`
    margin-right: ${({ isMobile }) => (isMobile ? "10" : Spacing.M)}px;
`;

export const Separator = style.View`
    width: 1px;
    height: 33px;
    background-color: ${Colors.border1};
    margin: 0px ${Spacing.L}px;
`;

export const Dot = style.View`
    width: 2px;
    height: 2px;
    border-radius: 1px;
    background-color: ${Colors.footerGray};
    margin-top: 1px;
`;

export const IconAndTextContainer = style.Pressable<{ isMobile?: boolean, haveBreakLine?: boolean }>`
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: ${({ isMobile, haveBreakLine }) => ( isMobile ? "10" : ( haveBreakLine ? "5" : Spacing.M ))}px;
`;

export const IconText = style.Text<{ isMobile?: boolean }>`
    font-size: ${({ isMobile }) => (isMobile ? Spacing.M : "18")}px;
    color: ${Colors.footerGray};
    margin-left: 8px;
    font-family: "manrope";
`;

export const CopyrightText = style.Text`
    font-size: 13px;
    color: ${Colors.footerGray};
    font-family: "manrope";
    margin: 0px 0.5px 2.5px 0px;
`;

export const Copyright = style.View`
    width: 12px;
    height: 12px;
    border-radius: 6px;
    border: 1.5px solid ${Colors.footerGray};
    align-items: center;
    justify-content: center;
    margin-top: 1.5px;
`;
