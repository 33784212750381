"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OfferTemplateMapper = void 0;
class OfferTemplateMapper {
    constructor(lang) {
        this.lang = lang;
    }
    toDomain(result) {
        var _a;
        let offer = Object.assign({}, result);
        const translations = ((_a = result === null || result === void 0 ? void 0 : result.translations) === null || _a === void 0 ? void 0 : _a.filter((translation) => translation.language === this.lang)) || [];
        if (translations.length) {
            offer.name = translations[0].name;
            offer.description = translations[0].description;
            offer.fullDescription = translations[0].fullDescription;
        }
        return Object.assign({}, offer);
    }
}
exports.OfferTemplateMapper = OfferTemplateMapper;
