"use strict";
/* istanbul ignore file */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoginIDP = void 0;
class LoginIDP {
    constructor(authService) {
        this.authService = authService;
    }
    execute(request) {
        const { code } = request;
        return this.authService.loginIDP(code);
    }
}
exports.LoginIDP = LoginIDP;
