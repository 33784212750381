import { Back, Warning } from "@assets";
import { ScreenWithFooter } from "@atomic";
import { useDevices } from "@hooks";
import { BalanceScreens } from "@modules/balance/navigation";
import { NavigationProp, Route } from "@react-navigation/native";
import { TestIDs } from "@utils";
import React from "react";
import { I18n } from "react-redux-i18n";
import {
  Container,
  GoBackContainer,
  GoBackText,
  MessageContainer,
  Message,
  Title,
  IconContainer,
  EmailTo,
} from "./ErrorReceipt.style";

interface IProps {
  navigation: NavigationProp<any>;
  route?: Route<any>;
}

export const ErrorReceipt: React.FC<IProps> = ({ navigation, route }) => {
  //@ts-ignore
  const { errorType = "refill.defaultTitle" } = route?.params || {};
  const [isMobile] = useDevices();

  const isReceipt = errorType === "errorHandler.errorReceipt";

  const handleGoBack = () => {
    isReceipt
      ? navigation?.goBack()
      : navigation?.navigate(BalanceScreens.BALANCE_MAIN_SCREEN);
  };

  const handleEmailTo = () => {
    window.open("mailto:help@foodi.fr");
  };

  return (
    <ScreenWithFooter navigation={navigation}>
      <Container isMobile={isMobile}>
        <GoBackContainer
          testID={TestIDs.home.balance.actions.errorReceiptGoBack}
          onPress={handleGoBack}
        >
          <Back />
          <GoBackText isMobile={isMobile}>{I18n.t("common.return")}</GoBackText>
        </GoBackContainer>
        <Title isMobile={isMobile}>
          {I18n.t(isReceipt ? "confirmationPage.yourReceipt" : "\n\n")}
        </Title>
        <MessageContainer isMobile={isMobile}>
          <IconContainer isMobile={isMobile}>
            <Warning />
          </IconContainer>
          <Message isMobile={isMobile}>
            {I18n.t(errorType)}
            {!isReceipt && (
              <>
                {" "}
                <EmailTo onPress={handleEmailTo}>help@foodi.fr</EmailTo>
              </>
            )}
            .
          </Message>
        </MessageContainer>
      </Container>
    </ScreenWithFooter>
  );
};
