import React from "react";

export const InfonewsTag: React.FC = () => (
  <svg
    width="124px"
    height="24px"
    viewBox="0 0 124 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Homepage"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="HP-UI-D---Default"
        transform="translate(-770.000000, -1976.000000)"
      >
        <g id="L'actualité" transform="translate(150.000000, 1887.000000)">
          <g id="Info-News" transform="translate(580.000000, 89.000000)">
            <g
              id="Atom/Tag/InfoNews"
              transform="translate(40.000000, 0.000000)"
            >
              <g
                id="campaign_black_24dp"
                transform="translate(10.108710, -3.000000)"
              >
                <rect
                  id="Rectangle"
                  x="0"
                  y="0"
                  width="24.2609036"
                  height="24"
                ></rect>
                <path
                  d="M18.1956777,13.4 C18.1956777,14.07 18.1956777,14.73 18.1956777,15.4 C19.4087229,15.4 20.9856816,15.4 22.2391616,15.4 C22.2391616,14.73 22.2391616,14.07 22.2391616,13.4 C20.9856816,13.4 19.4087229,13.4 18.1956777,13.4 Z"
                  id="Path"
                  fill="#527EDB"
                  fill-rule="nonzero"
                ></path>
                <path
                  d="M16.1739357,20.01 C17.1443719,20.72 18.4079606,21.66 19.4087229,22.4 C19.8130713,21.87 20.2174197,21.33 20.6217681,20.8 C19.6210058,20.06 18.3574171,19.12 17.3869809,18.4 C16.9826325,18.94 16.5782841,19.48 16.1739357,20.01 Z"
                  id="Path"
                  fill="#527EDB"
                  fill-rule="nonzero"
                ></path>
                <path
                  d="M20.6217681,8 C20.2174197,7.47 19.8130713,6.93 19.4087229,6.4 C18.4079606,7.14 17.1443719,8.08 16.1739357,8.8 C16.5782841,9.33 16.9826325,9.87 17.3869809,10.4 C18.3574171,9.68 19.6210058,8.75 20.6217681,8 Z"
                  id="Path"
                  fill="#527EDB"
                  fill-rule="nonzero"
                ></path>
                <path
                  d="M4.04348393,11.4 C2.93152585,11.4 2.02174197,12.3 2.02174197,13.4 L2.02174197,15.4 C2.02174197,16.5 2.93152585,17.4 4.04348393,17.4 L5.05435492,17.4 L5.05435492,21.4 L7.07609688,21.4 L7.07609688,17.4 L8.08696787,17.4 L13.1413228,20.4 L13.1413228,8.4 L8.08696787,11.4 L4.04348393,11.4 Z M9.12816498,13.11 L11.1195808,11.93 L11.1195808,16.87 L9.12816498,15.69 L8.64294691,15.4 L8.08696787,15.4 L4.04348393,15.4 L4.04348393,13.4 L8.08696787,13.4 L8.64294691,13.4 L9.12816498,13.11 Z"
                  id="Shape"
                  fill="#527EDB"
                  fill-rule="nonzero"
                ></path>
                <path
                  d="M15.6685002,12 C15.6685002,10.67 15.0821951,9.47 14.1521938,8.65 L14.1521938,15.34 C15.0821951,14.53 15.6685002,13.33 15.6685002,12 Z"
                  id="Path"
                  fill="#527EDB"
                  fill-rule="nonzero"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
