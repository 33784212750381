"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetInvoiceScolapassFile = void 0;
class GetInvoiceScolapassFile {
    constructor(userRepository) {
        this.userRepository = userRepository;
    }
    execute(request) {
        return this.userRepository.getInvoiceScolapassFile(request);
    }
}
exports.GetInvoiceScolapassFile = GetInvoiceScolapassFile;
