import { Action } from "../action";
import { createReducer } from "../createReducer";
import { Colors } from "@constants";
import {
  BrandTheme,
  BrandThemeResponse,
  GetBrandThemeCommand,
} from "@foodi/core";
import { displayToastError } from "@utils";
import { ThunkResult } from "@redux";
import _ from "lodash";

export interface BrandThemeState {
  brandTheme: BrandTheme;
}

/*************  Actions  ****************/

const ActionTypes = {
  SET_BRAND_THEME: "BRAND_THEME/SET_BRAND_THEME",
  RESET_BRAND_THEME: "BRAND_THEME/RESET_BRAND_THEME",
};

const ActionCreators = {
  setBrandTheme: (brandTheme: BrandTheme): Action<BrandTheme> => ({
    type: ActionTypes.SET_BRAND_THEME,
    payload: brandTheme,
  }),
  resetBrandTheme: () => ({
    type: ActionTypes.RESET_BRAND_THEME,
  }),
};

/*************  Side Effects, only if applicable ****************/
// e.g. thunks, epics, etc
const ThunkActionCreators = {
  getBrandTheme: (
    params: GetBrandThemeCommand
  ): ThunkResult<Promise<BrandThemeResponse> | any> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { getBrandTheme } = getDependencies();
      const { holding_name } = params;

      if (!holding_name) {
        dispatch(ActionCreators.resetBrandTheme());
        return;
      }

      let response = await getBrandTheme.execute(params);
      const hasHolding = !!response?.data?.name;
      if (!hasHolding) {
        response = await getBrandTheme.execute({
          ...params,
          holding_name: "eurest",
        });
      }

      const brandData: BrandTheme = _.get(response, "data");
      // if we don't get the brand's data we need only to show the default heroImage (eurest)
      const brandTheme = hasHolding
        ? brandData
        : { ...defaultTheme, heroBackImage: brandData.heroBackImage };
      dispatch(ActionCreators.setBrandTheme(brandTheme));
      return Promise.resolve(response);
    } catch (error) {
      displayToastError(dispatch);
      return Promise.reject(error);
    }
  },
};
/*************  Reducer  ****************/

const defaultTheme: BrandTheme = {
  name: "default",
  logo: {
    web: "",
    mobile: "",
  },
  colors: {
    primary: Colors.darkMagenta,
    secondary: Colors.purple,
  },
  heroBackImage: "",
  infoNewsColors: {
    backgroundColor: Colors.foodiDefault,
    textColor: Colors.foodiBlack,
  },
  buttonActionColors: {
    backgroundColor: Colors.foodiDefault,
    textColor: Colors.foodiBlack,
  },
};

const initialState: BrandThemeState = {
  brandTheme: defaultTheme,
};

const Reduction = {
  setBrandTheme: (
    state: BrandThemeState,
    { payload: brandTheme }: Action<BrandTheme>
  ) => ({
    brandTheme,
  }),
  resetBrandTheme: (): BrandThemeState => ({
    ...initialState,
  }),
};

const reducer = createReducer(initialState, {
  [ActionTypes.SET_BRAND_THEME]: Reduction.setBrandTheme,
  [ActionTypes.RESET_BRAND_THEME]: Reduction.resetBrandTheme,
});

export default reducer;

export {
  reducer as BrandThemeReducer,
  ActionTypes as BrandThemeActionTypes,
  ActionCreators as BrandThemeActions,
  ThunkActionCreators as BrandThemeThunks,
};
