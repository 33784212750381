import { Colors } from "@constants";
import style, { css } from "styled-components/native";

export const Container = style.View<{ isMobile?: boolean }>`
    width: ${({ isMobile }) => (isMobile ? "100vw" : "283px")};
    border-radius: 8px;
    background-color: ${Colors.white};
    padding: ${({ isMobile }) =>
      isMobile ? "12px 18px 20px 18px" : "12px 12px 20px 12px"};
`;

export const GoBackText = style.Text`
    color: ${Colors.foodiBlack};
    font-family: manrope-bold;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 30px;
    margin-left: 7px;
`;

export const Title = style.Text<{ isMobile?: boolean }>`
    color: ${Colors.foodiBlack};
    font-family: manrope-bold;
    font-size: ${({ isMobile }) => (isMobile ? 22 : 26)}px;
    letter-spacing: 0;
    line-height: 30px;
    text-align: center;
    margin-bottom: 15px;
`;

export const Text = style.Text<{ isFaded?: boolean }>`
    color: ${({ isFaded }) => (isFaded ? Colors.darkGrey : Colors.foodiBlack)};
    font-family: manrope;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    margin-bottom: ${({ isFaded }) => (isFaded ? 32 : 5)}px;
`;

export const ProductDescriptionContainer = style.View`
    flex-direction: row;
`;

export const Row = style.View<{ isLast?: boolean; isCompact?: boolean }>`
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: ${({ isLast, isCompact }) =>
      isCompact ? 0 : isLast ? 12 : 16}px;
`;

export const Column = style.View<{ isLast?: boolean; isCompact?: boolean }>`
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: ${({ isLast, isCompact }) =>
      isCompact ? 5 : isLast ? 12 : 16}px;
`;

export const Col = style.View<{ isLast?: boolean }>`
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: ${({ isLast }) => (isLast ? 0 : 20)}px;
`;

export const Separator = style.View`
    height: 1px;
    width: 100%;
    background-color: ${Colors.disabledBackground};
    margin-bottom: 12px;
`;

export const ButtonContainer = style.View`
    align-items: center;
    margin-top: 20px;
`;

export const ErrorContainer = style.View`
    flex-direction: row;
    justify-content: center;
    margin-top: 19px;
`;

export const SmallDescription = style.Text<{
  hasMargin?: boolean;
  bold?: boolean;
  fullWidth?: boolean;
  marginBottom?: number;
  marginLeft?: number;
}>`
    color: ${Colors.foodiBlack};
    font-family: ${({ bold }) => (bold ? "manrope-bold" : "manrope")};
    font-size: 13px;
    letter-spacing: 0;
    line-height: 18px;
    margin-left: ${({ hasMargin }) => (hasMargin ? 5 : 0)}px;
    max-width: ${({ fullWidth }) => (fullWidth ? null : 229)}px;
    flex-wrap: wrap;
    ${({marginBottom}) =>  marginBottom && css `margin-bottom: ${marginBottom}px;` }
    ${({marginLeft}) =>  marginLeft && css `margin-left: ${marginLeft}px;` }
`;

export const ProductDescription = style.Text<{
  marginRight?: number;
  marginLeft?: number;
  bold?: boolean;
  fullWidth?: boolean;
  underline?: boolean;
  alignSelf?: string;
  marginTop?: number;
  width?: number | string;
}>`
    color: ${Colors.foodiBlack};
    font-family: ${({ bold }) => (bold ? "manrope-bold" : "manrope")};
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    max-width: ${({ fullWidth }) => (fullWidth ? null : 167)}px;
    flex-wrap: wrap;
    margin-right: ${({ marginRight }) => marginRight}px;
    margin-left: ${({ marginLeft }) => marginLeft}px;
    margin-top: ${({ marginTop }) => marginTop}px;
    text-decoration-line: ${({ underline }) =>
      underline ? "underline" : "none"};
    ${({alignSelf}) =>  alignSelf && css `align-self: ${alignSelf};` }
    ${({width}) =>  width && css `width: ${width};` }
`;

export const ContainerDescription = style.Text<{ isMobile?: boolean }>`
  color: ${Colors.foodiBlack};
  font-family: "manrope";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  max-width: ${({ isMobile }) => (isMobile ? "75vw" : "200px")};
`;

export const LowBalanceDescription = style.Text`
    color: ${Colors.redError};
    font-family: "manrope";
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
    max-width: 167px;
`;

export const SectionTitle = style.Text<{ extraStyles?: string }>`
    color: ${Colors.foodiBlack};
    font-family: manrope-bold;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    ${({ extraStyles }) => !!extraStyles && extraStyles}
`;

export const PaymentContainer = style.View`
    flex-direction: row;
    margin: 6px 0px 17px;
    justify-content: space-between;
    align-items: center;
`;

export const PaymentTextContainer = style.View`
    margin-left: 8px;
`;

export const FlexDirectionRow = style.View`
    flex-direction: row;
`;

export const TolTipRow = style.View`
    flex-direction: row;
    align-items: flex-end;
`;

export const SeeAction = style.Pressable`
    height: 40px;
    width: 151px;
    border: 1px solid ${Colors.foodiBlack};
    border-radius: 8px;
    align-self: center;
    justify-content: center;
    margin-top: 5px;
`;

export const GoBackContainer = style.Pressable`
    align-items: center;
    max-width: 250px;
    flex-direction: column;
`;

export const GoBackContainerMobile = style.Pressable`
    max-width: 250px;
    flex-direction: row;
    align-items: center;
`;

export const IconContainer = style.View<{ height?: number }>`
    max-width: 25px;
    max-height: ${({ height }) => height || 17}px;
    align-items: center;
    margin: 0px 2px 0px 2px;
`;

export const IconRow = style.View`
    align-items: center;
    flex-direction: row;
    top: -8px;
    margin-right: -5px;
`;

export const WarningMessageContainer = style.View<{ marginTop?: number }>`
    flex-direction: row;
    margin-bottom: 13px;
    margin-top: ${({ marginTop = 9 }) => marginTop}px;
`;

export const WarningMessage = style.Text`
    color: ${Colors.foodiBlack};
    font-family: manrope;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 18px;
    margin-left: 6px;
    max-right: 16px;
    flex:1
`;

export const DropdownRow = style.Pressable`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
`;

export const AccordeonArrowStyle = style.View<{ rotate?: boolean }>`
    transform: ${({ rotate }) => (rotate ? "rotate(180deg)" : null)};
`;

export const SearchBar = style.View`
    flex-direction: row;
    align-items: center;
    border: 1px solid ${Colors.foodiBlack};
    marginTop: 10px;
    border-radius: 8px;
`;

export const SearchInput = style.TextInput`
    font-family: manrope-regular;
    line-height: 20px;
    font-size: 18px;
    padding: 16px 0px 15px 17px;
    margin-right: 10px;
`;

export const LastPickupPointTitle = style.Text`
    font-family: manrope-regular;
    text-align: center;
    line-height: 20px;
    font-size: 15px;
    margin-top: 20px
    margin-bottom: 5px
`;