"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetBookingTemplateOffer = void 0;
class GetBookingTemplateOffer {
    constructor(offerService) {
        this.offerService = offerService;
    }
    execute(request) {
        return this.offerService.getBookingTemplateOffer(request);
    }
}
exports.GetBookingTemplateOffer = GetBookingTemplateOffer;
