import {
  Holding,
  GetBrandCommand,
  MarketingCardsResponse,
  GetMarketingCardsCommand,
  PublishedMarketingCardsNewResponse,
  GetPublishedMarketingCardsNewCommand,
  IExternalService,
} from "@foodi/core";
import { createReducer, ThunkResult } from "@redux";
import { displayToastError } from "@utils";
import { Action } from "redux";

export interface MarketingCardState {
  externalsServices: IExternalService[];
}

/*************  Actions  ****************/

const ActionTypes = {
  SET_EXTERNALS_SERVICES: "MarketingCard/SET_EXTERNALS_SERVICES",
};

const ActionCreators = {
  setExternalsServices: (
    externalsServices: IExternalService[]
  ): Action<IExternalService[]> => ({
    //@ts-ignore
    type: ActionTypes.SET_EXTERNALS_SERVICES,
    payload: externalsServices,
  }),
};

/*************  Side Effects, only if applicable ****************/
// e.g. thunks, epics, etc
const ThunkActionCreators = {
  getBrand: (params: GetBrandCommand): ThunkResult<Promise<Holding>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    const { getBrand } = getDependencies();
    const response: Holding = await getBrand.execute(params);
    dispatch(
      ActionCreators.setExternalsServices(response?.externalServices || [])
    );
    return Promise.resolve(response);
  },
  getMarketingCards: (
    params: GetMarketingCardsCommand
  ): ThunkResult<Promise<MarketingCardsResponse>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    const { getMarketingCards } = getDependencies();
    return getMarketingCards.execute(params);
  },
  getPublishedMarketingCardsNew: (
    params: GetPublishedMarketingCardsNewCommand
  ): ThunkResult<Promise<PublishedMarketingCardsNewResponse>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { getPublishedMarketingCardsNew } = getDependencies();
      return getPublishedMarketingCardsNew.execute(params);
    } catch (error) {
      displayToastError(dispatch);
      return Promise.reject(error);
    }
  },
};

/*************  Reducer  ****************/

const initialState: MarketingCardState = {
  externalsServices: [],
};

const Reduction = {
  setExternalsServices: (
    state: MarketingCardState,
    //@ts-ignore
    { payload: externalsServices }: Action<IExternalService[]>
  ): MarketingCardState => ({
    ...state,
    externalsServices,
  }),
};

const reducer = createReducer(initialState, {
  [ActionTypes.SET_EXTERNALS_SERVICES]: Reduction.setExternalsServices,
});

export default reducer;

export {
  reducer as MarketingCardReducer,
  ActionTypes as MarketingCardActionTypes,
  ActionCreators as MarketingCardActions,
  ThunkActionCreators as MarketingCardThunks,
};
