import { GlobalState } from "@redux";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

export const useDevices = () => {
  const isMobileState = useSelector(
    (state: GlobalState) => state.mobileState.isMobile
  );

  const isMobile =
    isMobileState ||
    (useMediaQuery({ query: "(min-width:320px)" }) &&
      useMediaQuery({ query: "(max-width:600px)" }));
  const isSmallTablet = useMediaQuery({ query: "(min-width:1024px)" });
  const isMediumDesktop = useMediaQuery({ query: "(min-width:1280px)" });
  const isLargeDesktop = useMediaQuery({ query: "(min-width:1440px)" });

  return [isMobile, isSmallTablet, isMediumDesktop, isLargeDesktop];
};
