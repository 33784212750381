import React, { useState } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { Title13, Title14, Title16 } from "@stylesheets";
import { Colors, Spacing } from "@constants";
import { ArticleFamily, IPosMenuElement, OrderItem } from "@foodi/core";
import {
  FastImage,
  ProductQuantity,
  ProductItemModal,
  FormulasModal,
  IImportationTypes,
  Icon,
  OfferFormulaItem
} from "@atomic";
import { IOfferItem, OfferViewModel } from "@modules";
import { Container } from "@assets";
import { I18n } from "react-redux-i18n";
import { useDevices } from "@hooks";
import { useSelector } from "react-redux";
import { GlobalState } from "@redux";
import { allergensMapper } from "@utils/allergensMapper";
import { get, capitalize } from "lodash";

interface IProps {
  item: IOfferItem;
  selectedOfferId?: string;
  forwardTestID?: string;
  hasQuantity?: boolean;
  updateOrders?: Function;
  outOfStockItem?: boolean;
  sameProductPerOrder?: number;
  isOverProductsLimit?: boolean;
  lastProduct?: boolean;
  orderItem?: OrderItem;
  menuType?: IImportationTypes;
}

export const ProductItem: React.FC<IProps> = React.memo(
  ({
    item,
    forwardTestID,
    hasQuantity = true,
    selectedOfferId,
    updateOrders,
    outOfStockItem = false,
    sameProductPerOrder,
    isOverProductsLimit,
    lastProduct,
    orderItem,
    menuType,
  }) => {
    const {
      id,
      inheritedLabel,
      inheritedImage,
      inheritedPrice,
      inheritedFamily,
      container,
      baking,
      quantityOverall,
    } = item;
    const isMenu = menuType !== undefined;
    const offer = useSelector(
      (state: GlobalState) => state.offers.activeOrderableOffer
    );
    const menuElements = offer?.menus ? offer.menus[0]?.elements : null;
    const itemMenu: any = isMenu
      ? item
      : menuElements
      ? menuElements.find(
          (element) =>
            element?.idElement === item?.localArticle?.article?.idElement
        )
      : ({} as IPosMenuElement);
    const allergens: [string] = itemMenu?.allergens || [];
    const certifications = itemMenu?.certifications || [];
    const [offerVM] = useState(new OfferViewModel());
    const [isMobile] = useDevices();

    const styles = _styles(outOfStockItem, isMobile, lastProduct);

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isFormulaOpen, setIsFormulaOpen] = useState<boolean>(false);

    const totalQuantity = useSelector((state: GlobalState) => {
      return state.offers.orderItems
        .filter((item) => {
          return item.id === id;
        })
        .map((item) => item.quantity)
        .reduce((prev, curr) => prev + curr, 0);
    });

    const orderItems = useSelector((state: GlobalState) => {
      return state.offers.orderItems.filter((item) => {
        return item.id === id;
      });
    });
    const productTransparencyPreferences = isMenu
      ? useSelector((state: GlobalState) => {
          return state.holdingSetting.productTransparencyPreferences;
        })
      : useSelector((state: GlobalState) => {
          return state.holdingSetting.offersProductTransparencyPreferences;
        });

    const {
      showIngredients = false,
      showPhoto = false,
      showPrice = false,
      showDescription = false,
    } = productTransparencyPreferences ?? {};

    const handleFormulaModal = (isOpen?: boolean) => {
        if (!outOfStockItem){
            setIsFormulaOpen(!!isOpen);
        }
    };

    const handleOpenModal = () => {
      setIsOpen(true);
    };

    const handleCloseModal = () => {
      setIsOpen(false);
    };

    const onRemoveFromCartWithBaking = () => {
      let lastItem = orderItems.slice(-1).pop();
      const orderItem: OrderItem = {
        __typename: "OrderItem",
        id: id,
        quantity: (lastItem?.quantity ?? 1) - 1,
        chosenBaking: lastItem?.chosenBaking ?? null,
        labelWhenAdded: inheritedLabel,
        priceWhenAdded: {
          amount: inheritedPrice.amount,
          currency: inheritedPrice.currency,
        },
        containerLabelWhenAdded: container?.label ? container.label : null,
        containerPriceWhenAdded: {
          amount: container?.price?.amount ? container.price.amount : "",
          currency: inheritedPrice.currency,
        },
        offerItem: {
          __typename: "OfferItem",
          id: selectedOfferId || "id",
          inheritedFamily: inheritedFamily,
        },
      };
      updateOrders?.(orderItem);
      handleCloseModal();
    };

    const onControlQuantity = (quantity: number) => {
      const orderItem: OrderItem = {
        __typename: "OrderItem",
        id: id,
        quantity: quantity,
        chosenBaking: null,
        labelWhenAdded: inheritedLabel,
        priceWhenAdded: {
          amount: inheritedPrice.amount,
          currency: inheritedPrice.currency,
        },
        containerLabelWhenAdded: container?.label ? container.label : null,
        containerPriceWhenAdded: {
          amount: container?.price?.amount ? container.price.amount : "",
          currency: inheritedPrice.currency,
        },
        offerItem: {
          __typename: "OfferItem",
          id: selectedOfferId || "id",
          inheritedFamily: inheritedFamily,
        },
      };
      updateOrders?.(orderItem);
    };

    const price = inheritedPrice?.amount ?? get(item, "price.amount");
    const description = offerVM.getOfferDescription(item, menuType);
    const showAllergens =
      productTransparencyPreferences?.showAllergens &&
      allergens &&
      allergens.filter((allergen) => allergen !== "ALLERGEN_FREE").length > 0;

    const showLabels =
      productTransparencyPreferences?.showLabels &&
        certifications &&
        certifications.length > 0;

    const hasContent =
      (item.baking && item.baking.length > 0) ||
      (inheritedImage) ||
      ((showAllergens) ||
        (showLabels) ||
        (showDescription && description) ||
        (showIngredients && item.products && item.products?.length > 1));

      const isFormulaItem = item.inheritedFamily === ArticleFamily.FORMULA;

    return (
      <>
        <Pressable style={styles.pressable} onPress={isFormulaItem ? () => handleFormulaModal(true) : hasContent ? handleOpenModal : null}>
          <View testID={forwardTestID} style={styles.container}>
            <View style={styles.innerContainer}>
              <View style={styles.upperDiv}>
                <View style={styles.titleContainer}>
                    <View style={styles.titleIconContainer}>
                        <Title16 style={styles.title}>
                            {capitalize(inheritedLabel.trim())}
                            {!!container && (
                                <View style={styles.containerIcon}>
                                    <Title16 style={styles.title}> + </Title16>
                                    <Container />
                                </View>
                            )}
                        </Title16>
                    </View>
                    {hasContent && <Icon name={"Navigation_Arrows_Right_Black"} size={16} color={Colors.foodiBlack} style={{ marginRight: Spacing.XS, marginLeft: 10 }}/>}
                </View>
                  <View style={[styles.body]}>
                    <View style={styles.leftDiv}>
                      {showDescription && !!description && (
                        <Title16
                          style={[
                            styles.subTitle,
                            styles.marginTopDiv
                          ]}
                          numberOfLines={2}
                          ellipsizeMode="tail"
                        >
                          {description}.
                        </Title16>
                      )}
                    </View>
                    {showPhoto && !!inheritedImage && (
                      <View style={styles.rightDiv}>
                        <FastImage
                          imagePath={inheritedImage}
                          width={80}
                          style={styles.image}
                        />
                      </View>
                    )}
                  </View>
              </View>
              {((showPrice && !!price) || outOfStockItem) && <View style={[styles.middleDiv, (!showPhoto || !inheritedImage) && styles.marginTopDiv]}>
                    {showPrice && !!price && (
                        <View style={styles.currencyContainer}>
                            <Title16 style={styles.amount}>
                                {offerVM.getProductAmount(String(price))}€
                            </Title16>
                            {container?.price?.amount && (
                                <Title16 style={[styles.amount, styles.consignCurrency]}>
                                    + {offerVM.getProductAmount(container?.price?.amount)}€ {I18n.t("restaurantDetail.container.name")}
                                </Title16>
                            )}
                        </View>
                    )}
                    {outOfStockItem ? (
                        <View style={styles.soldOutContainer}>
                            <View style={styles.soldOut}>
                                <Title14>{I18n.t("restaurantDetail.soldOut")}</Title14>
                            </View>
                        </View>
                    ) : (
                        hasQuantity && (
                            <ProductQuantity
                                isOverProductsLimit={isOverProductsLimit}
                                sameProductPerOrder={sameProductPerOrder}
                                onHandle={
                                    !!baking
                                        ? onRemoveFromCartWithBaking
                                        : onControlQuantity
                                }
                                productId={id}
                                quantityValue={
                                  inheritedFamily === ArticleFamily.FORMULA
                                    ? totalQuantity
                                    :  !!baking ? totalQuantity : orderItem?.quantity
                                }
                                addQuantityOverride={
                                  inheritedFamily === ArticleFamily.FORMULA
                                    ? () => handleFormulaModal(true)
                                    : !!baking ? handleOpenModal : undefined
                                }
                                style={styles.productQuantityContainer}
                                quantityOverall={quantityOverall}
                            />
                        )
                    )}
                </View>}
            </View>
            {(showLabels || showAllergens) && <View style={styles.footer}>
                {showAllergens && <Title13 style={styles.transparencyInfo}>
                    <Icon name={'Peanut'} size={16} style={{ marginRight: 5 }}/>
                    {I18n.t("restaurantDetail.hasAllergens")}
                </Title13>}
                {showLabels && <Title13 style={styles.transparencyInfo}>
                    <Icon name={"label-indicator-black"} size={16} style={{ marginRight: 5 }}/>
                    {I18n.t("restaurantDetail.hasLabels")}
                </Title13>}
            </View>}
          </View>
        </Pressable>
        {!isFormulaItem &&
          <ProductItemModal
            item={item}
            forwardTestID={`${forwardTestID}-in-modal`}
            selectedOfferId={selectedOfferId}
            updateOrders={updateOrders}
            sameProductPerOrder={sameProductPerOrder}
            isOverProductsLimit={isOverProductsLimit}
            handleClose={handleCloseModal}
            isOpen={isOpen}
            productTransparencyPreferences={productTransparencyPreferences}
            menuType={menuType}
          />
        }
        {isFormulaItem &&
          <FormulasModal
            isOpen={isFormulaOpen}
            handleClose={() => handleFormulaModal(false)}
            item={item as OfferFormulaItem}
            selectedOfferId={selectedOfferId}
            onHandle={(formulaItem) => updateOrders?.(formulaItem)}
            productTransparencyPreferences={productTransparencyPreferences}
            menuType={menuType}
          />
        }
      </>
    );
  }
);

const _styles = (
  outOfStockItem?: boolean,
  isMobile?: boolean,
  lastProduct?: boolean
) =>
  StyleSheet.create({
    pressable: {
    },
    container: {
      marginTop: 15,
      width: isMobile ? "100vw" : 378,
      flex: 1,
      backgroundColor: outOfStockItem
        ? Colors.disabledBackground
        : Colors.white,
      borderBottomColor: "#DDDDDD",
      borderBottomWidth: isMobile ? (lastProduct ? 0 : 1) : 0,
      borderRadius: 8,
      boxShadow: '0 2px 10px 0 rgba(153,153,153,0.2)',
      justifyContent: "space-between"
},
    innerContainer: {
      width: isMobile ? "95%" : "100%",
      // flex: 1,
      borderRadius: 8,
      backgroundColor: outOfStockItem
        ? Colors.disabledBackground
        : Colors.white,
      padding: 15,
    },
    upperDiv: {
        flex: 1
    },
    body: {
        flexDirection: "row",
    },
    middleDiv: {
      width: "100%",
      alignSelf: "flex-end",
      flexDirection: "row",
      alignItems: "center",
    },
    leftDiv: {
      flex: 1,
      marginRight: Spacing.S,
    },
    marginTopDiv: {
      marginTop: 10,
    },
    title: {
      color: Colors.foodiBlack,
      fontFamily: "manrope-bold",
      overflow: "hidden",
      width: "100%",
      lineHeight: 24
    },
    titleContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
    },
    subTitle: {
      color: Colors.gray1,
      lineHeight: 24
    },
    image: {
      marginTop: Spacing.XS,
      height: 82,
      width: 82,
      borderRadius: 4,
    },
    rightDiv: {
    },
    amount: {
      color: Colors.foodiBlack,
      lineHeight: 24
    },
    soldOutContainer: {
      alignItems: "center",
      width: 80,
      marginLeft: "auto",
      marginTop: Spacing.L,
    },
    soldOut: {
      paddingHorizontal: 10,
      paddingVertical: 6,
      backgroundColor: Colors.footerBackgroundGray,
      borderRadius: 11.5,
    },
    titleIconContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
      flex: 1
    },
    currencyContainer: {
      flexDirection: "row",
    },
    consignCurrency: {
      paddingLeft: Spacing.S,
    },
    containerIcon: {
      flexDirection: "row",
      alignItems: "center",
    },
    productQuantityContainer: {
      marginTop: Spacing.L,
      marginLeft: "auto",
    },
    footer: {
        width: "100%",
        height: 34,
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
        backgroundColor: Colors.footerCardGray,
        flexDirection: "row",
        paddingLeft: Spacing.M,
    },
    transparencyInfo: {
        color: '#090909',
        marginRight: Spacing.L,
        display: 'flex',
        alignItems: 'center',
    }
  });
