import React from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import { Title18 } from "@stylesheets";
import { Colors } from "@constants";

interface IProps {
  isAddition: boolean;
  forwardTestID?: string;
  callback: () => void;
  isDisabled?: boolean;
}

export const ControlQuantityBtn: React.FC<IProps> = React.memo(
  ({ isAddition, forwardTestID, callback, isDisabled }) => {
    const styles = _styles(isAddition, isDisabled);
    const symbol = isAddition ? "＋" : "—";

    return (
      <TouchableOpacity
        onPress={callback}
        testID={forwardTestID}
        style={styles.container}
        disabled={isDisabled}
      >
        <Title18 style={styles.title}>{symbol}</Title18>
      </TouchableOpacity>
    );
  }
);

const _styles = (isAddition: boolean, isDisabled?: boolean) =>
  StyleSheet.create({
    container: {
      height: 25,
      width: 25,
      borderRadius: 4,
      borderColor: isDisabled ? Colors.disabledBackground : Colors.black,
      borderWidth: 1,
      backgroundColor: isDisabled
        ? Colors.disabledBackground
        : isAddition
        ? Colors.black
        : Colors.white,
      justifyContent: "center",
      alignItems: "center",
    },
    title: {
      color: isDisabled
        ? Colors.white
        : isAddition
        ? Colors.white
        : Colors.black,
    },
  });
