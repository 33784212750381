import React, { useEffect, useRef, useState } from "react";
import { RefillInputs } from "@atomic";
import {
  PaymentsThunks,
  Refills,
  RefillViewModel,
  TransactionsActions,
} from "@modules";
import { useDispatch, useSelector } from "react-redux";
import { submitToUrl } from "@utils/submitToUrl";
import { GlobalState } from "@redux";
import { I18n } from "react-redux-i18n";

const MAX_AMOUNT_SCOLAPASS = 5000;
const MAX_AMOUNT_REFILL = 150;

import { PaymentInput } from "@atomic/organisms/PaymentInput";

export const RefillsTemplate: React.FC = React.memo(() => {
  const dispatch = useDispatch();
  const accessToken = useSelector(
    (state: GlobalState) => state.auth.authToken?.accessToken
  );
  const balanceInfo = useSelector(
    (state: GlobalState) => state.account.userBalance?.balance
  );
  const { isScolaPassCashSystem } =
    useSelector((state: GlobalState) => state?.auth?.userInfo) ?? {};

  const prevSelectedInput = useRef(-1);
  const refillVM = new RefillViewModel();
  const balance = parseFloat(balanceInfo?.amount as string);
  const defaultPayment = balance < 0 ? Math.abs(balance) : 0;

  // useState
  const [refills, setRefills] = useState<Refills[]>([]);
  const [isOtherRefill, setOtherRefill] = useState<boolean>(false);
  const otherValue = React.useRef(0);
  const paymentValue = React.useRef(defaultPayment);

  // useEffects
  useEffect(() => {
    if (!isOtherRefill) {
      setRefills(refillVM.getInitState());
    } else {
      prevSelectedInput.current = -1;
    }
  }, [isOtherRefill]);

  const onSelectRefillInput = (inputIndex: number) => {
    if (prevSelectedInput.current === inputIndex) return;
    const refills = refillVM.getRefillInputs(inputIndex);
    const otherRefillIndex = refills?.length === 2 ? 1 : 3;
    prevSelectedInput.current = inputIndex;
    setRefills(refills);
    setOtherRefill(inputIndex === otherRefillIndex);
  };

  /* istanbul ignore next */
  const handleValidate = async () => {
    dispatch(TransactionsActions.setMercanetStatus(true));

    const amount = (function () {
      if (isScolaPassCashSystem) {
        return `${paymentValue.current}`;
      }
      return isOtherRefill
        ? `${otherValue.current}`
        : refills?.[prevSelectedInput.current]?.amount;
    })();

    if (amount && accessToken) {
      //@ts-ignore
      const { uri, method, body } = await dispatch(
        PaymentsThunks.getPaymentRequest(
          amount,
          accessToken,
          // @ts-ignore
          window.config.MERCANET_CALLBACK_URL
        )
      );
      submitToUrl(uri, method, body);
    }
  };

  const handleOtherValue = (value: number) => {
    otherValue.current = value;
  };

  const handlePayment = (value: number) => {
    paymentValue.current = value;
  };

  const title = isScolaPassCashSystem
    ? I18n.t("refill.scolapass.payment")
    : I18n.t("refill.refill");

  const maxAmount = isScolaPassCashSystem
    ? MAX_AMOUNT_SCOLAPASS
    : MAX_AMOUNT_REFILL;

  const errorDesc = I18n.t("refill.refillLimitError", {
    amount: String(maxAmount),
  });

  if (isScolaPassCashSystem) {
    return (
      <PaymentInput
        onValidate={handleValidate}
        handlePayment={handlePayment}
        balance={balance}
        defaultPayment={defaultPayment}
        maxAmount={MAX_AMOUNT_SCOLAPASS}
        title={title}
        errorDescription={errorDesc}
      />
    );
  }

  return (
    <RefillInputs
      onValidate={handleValidate}
      handleOtherValue={handleOtherValue}
      onSelectRefillInput={onSelectRefillInput}
      refills={refills}
      isOtherRefill={isOtherRefill}
      setOtherRefill={setOtherRefill}
      isDisabled={refillVM.noInputIsSelected(refills)}
      title={title}
      errorDescription={errorDesc}
    />
  );
});
