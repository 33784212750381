import React from "react";
import { I18n } from "react-redux-i18n";

interface IProps {
  backgroundColor?: string;
  color?: string;
  width?: string;
  style?: any;
}

export const Information: React.FC<IProps> = ({
  backgroundColor = "#527EDB",
  width = "22px",
  style,
  color = '#FFFFFF"',
}) => {
  return (
  <svg style={style} width={width} height="22px" viewBox="0 0 22 22" version="1.1">
    <title>{I18n.t("hoverIcons.important")}</title>
    <g id="Atom/Alerts/Information" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <circle id="Oval" fill={backgroundColor} cx="11" cy="11" r="11"></circle>
      <path d="M9.8,6.62244898 L9.8,4.5 L12.3,4.5 L12.3,6.62244898 L9.8,6.62244898 Z M9.8,17.5 L9.8,7.94897959 L12.3,7.94897959 L12.3,17.5 L9.8,17.5 Z" id="Shape" fill="#FFFFFF" fill-rule="nonzero"></path>
    </g>
  </svg>
  );
};
