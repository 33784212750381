import React from "react";
import { StyleSheet, TouchableOpacity, ViewStyle } from "react-native";
import { CloseBlack } from "@assets";

interface IProps {
  forwardTestID?: string;
  callback: Function;
  closeXButtonStyle?: ViewStyle;
}

export const CloseXButton: React.FC<IProps> = React.memo(
  ({ callback, forwardTestID, closeXButtonStyle }) => {
    return (
      <TouchableOpacity
        testID={forwardTestID}
        onPress={() => callback()}
        style={[styles.closeBtn, closeXButtonStyle]}
      >
        <CloseBlack />
      </TouchableOpacity>
    );
  }
);

const styles = StyleSheet.create({
  closeBtn: {
    position: "absolute",
    top: 0,
    right: 0,
  },
});
