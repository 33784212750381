import React, { useMemo } from "react";
import { Pressable, StyleSheet, View, ViewStyle } from "react-native";
import { Information } from "@assets/icons/Information";
import { Title16, Title18 } from "@stylesheets";
import { I18n } from "react-redux-i18n";
import { useDevices } from "@hooks";
import {
  AppStoreEN,
  AppStoreFR,
  GooglePlayEN,
  GooglePlayFR,
  Images,
} from "@assets";
import { useSelector } from "react-redux";
import { GlobalState } from "@redux";
interface IProps {}

export const FormulaApp: React.FC<IProps> = React.memo(({}) => {
  const [isMobile] = useDevices();
  const styles = useMemo(() => _styles(isMobile), [isMobile]);
  const userLanguage = useSelector(
    (state: GlobalState) => state.auth?.userInfo?.language
  );

  const GoogleStore = userLanguage === "fr" ? GooglePlayFR : GooglePlayEN;
  const AppStore = userLanguage === "fr" ? AppStoreFR : AppStoreEN;

  const handleAppleStore = () => {
    window
      .open(
        "https://apps.apple.com/fr/app/foodi/id1377579624?l=fr&ls=1",
        "_blank"
      )
      ?.focus();
  };

  const handleAndroidStore = () => {
    window
      .open(
        "https://play.google.com/store/apps/details?id=fr.compassgroup.foodi",
        "_blank"
      )
      ?.focus();
  };

  return (
    <View>
      <View style={styles.unavailable}>
        <Information />
        <Title16 style={styles.unavailableText}>
          {I18n.t("restaurantDetail.formula.availableOnApp")}
        </Title16>
      </View>
      <Title18 style={styles.downloadApp}>
        {I18n.t("restaurantDetail.formula.downloadApp")}
      </Title18>

      <View style={styles.iconContainer}>
        <Pressable onPress={handleAppleStore}>
          <AppStore />
        </Pressable>
        <Pressable onPress={handleAndroidStore}>
          <GoogleStore />
        </Pressable>
      </View>
    </View>
  );
});

const _styles = (isMobile: boolean) =>
  StyleSheet.create({
    unavailable: {
      marginTop: 30,
      flexDirection: "row",
      paddingLeft: isMobile ? 18 : 0,
      width: "100vw",
    },
    unavailableText: {
      marginLeft: 6,
    },
    downloadApp: {
      marginTop: 20,
      textAlign: "left",
      marginLeft: isMobile ? 18 : 0,
    },
    iconContainer: {
      flexDirection: "row",
      marginTop: 20,
      width: 260,
      justifyContent: "space-between",
      marginLeft: isMobile ? 18 : 0,
    },
  });
