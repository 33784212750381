import { ModalTemplate } from "@atomic/templates";
import React, { useState, memo, useMemo } from "react";
import { I18n } from "react-redux-i18n";
import { ProductDescription, Row, Col } from "./Cart.style";
import { CartSummaryAdmissionAndPrice, IPaymentTypes } from "@foodi/core";
import { Pressable, StyleSheet } from "react-native";
import { Title16 } from "@stylesheets";
import { Colors, Spacing } from "@constants";
import { useDevices } from "@hooks";
import { useSelector } from "react-redux";
import { GlobalState } from "@redux/rootReducer";

interface IQuotation {
  quotation: CartSummaryAdmissionAndPrice;
  paymentMethod: IPaymentTypes | any;
  isCatCSI: boolean;
  isSchool?: boolean;
}

export const QuotationSummary: React.FC<IQuotation> = memo(
  ({ quotation, paymentMethod, isCatCSI, ...props }) => {
    const [isOpenQutaionModal, setIsOpenQutaionModal] = useState(false);
    const userInfo = useSelector(
      (state: GlobalState) => state.auth?.userInfo || {}
    );
    const isSchool = props.isSchool || userInfo.isSchool;

    const [isMobile] = useDevices();
    const TRANSLATION_PATH = "restaurantDetail.cart.quotation";
    const quotationContent = useMemo(() => {
      return [
        {
          title: I18n.t(`${TRANSLATION_PATH}.admission.title`),
          descirption: I18n.t(`${TRANSLATION_PATH}.admission.description`),
        },
        {
          title: I18n.t(`${TRANSLATION_PATH}.subsidy.title`),
          descirption: I18n.t(`${TRANSLATION_PATH}.subsidy.description`),
        },
        {
          title: I18n.t(`${TRANSLATION_PATH}.collectedOnAccount.title`),
          descirption: I18n.t(
            `${TRANSLATION_PATH}.collectedOnAccount.description`
          ),
        },
        {
          title: I18n.t(`${TRANSLATION_PATH}.halfBoardSubsidy.title`),
          descirption: I18n.t(
            `${TRANSLATION_PATH}.halfBoardSubsidy.description`
          ),
        },
      ];
    }, []);

    const styles = useMemo(() => _styles({ isMobile }), [isMobile]);

    const renderQuotationValues = () => {
      if (isSchool && paymentMethod === IPaymentTypes.OnSite) {
        return (
          <Row>
            <ProductDescription>
              {I18n.t("restaurantDetail.cart.halfBoardSubsidy")}
            </ProductDescription>
            <ProductDescription>-€</ProductDescription>
          </Row>
        );
      }
      return (
        <>
          <Row>
            <ProductDescription>
              {I18n.t("restaurantDetail.cart.admission")}
            </ProductDescription>
            <ProductDescription>
              {quotation.admission?.amount}€
            </ProductDescription>
          </Row>
          <Row>
            <ProductDescription>
              {I18n.t("restaurantDetail.cart.subventions")}
            </ProductDescription>
            <ProductDescription>
              {paymentMethod === IPaymentTypes.OnSite && !isCatCSI
                ? "-"
                : quotation.subsidies?.amount}
              €
            </ProductDescription>
          </Row>
          {quotation.fee && paymentMethod !== IPaymentTypes.Badge && (
            <Row isLast>
              <ProductDescription>
                {I18n.t("restaurantDetail.cart.fee")}
              </ProductDescription>
              <ProductDescription>{quotation.fee?.amount}€</ProductDescription>
            </Row>
          )}
        </>
      );
    };
    return (
      <>
        {renderQuotationValues()}
        <Pressable onPress={() => setIsOpenQutaionModal((state) => !state)}>
          <Title16 color={Colors.blue1} bold style={styles.readMore}>
            {I18n.t(`${TRANSLATION_PATH}.readMore`)}
          </Title16>
        </Pressable>
        <ModalTemplate
          isOpen={isOpenQutaionModal}
          handleClose={() => setIsOpenQutaionModal((state) => !state)}
          isFullScreen
          closeOnClickOutside
          isCenter
          closeXButtonStyle={styles.closeXButtonStyle}
          closeXContainerStyle={styles.closeXContainerStyle}
          style={styles.modalStyle}
        >
          {quotationContent.map((content, index) => {
            return (
              <Col key={index} isLast={quotationContent.length - 1 === index}>
                <Title16 bold>{content.title}</Title16>
                <Title16>{content.descirption}</Title16>
              </Col>
            );
          })}
        </ModalTemplate>
      </>
    );
  }
);

const _styles = ({ isMobile }: { isMobile: boolean }) =>
  StyleSheet.create({
    readMore: {
      textDecorationLine: "underline",
      marginBottom: 12,
    },
    closeXButtonStyle: {
      backgroundColor: Colors.disabledBackground,
      padding: Spacing.S,
      borderRadius: 50,
    },
    closeXContainerStyle: {
      top: 10,
      right: 10,
    },
    modalStyle: {
      width: isMobile ? "100%" : 415,
      paddingVertical: Spacing.L,
    },
  });
