"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.historicalFixtures = void 0;
exports.historicalFixtures = {
    isLoading: false,
    hasNextPage: false,
    scolapassPayments: [
        {
            id: 1,
            familyId: "UGF5bWVudDoxOTAwMDE0",
            paymentType: "PI",
            date: "2020-01-30T00:00:00.000Z",
            amount: 80
        },
        {
            id: 2,
            familyId: "UGF5bWVudDoxOTAwMDE0",
            paymentType: "MO",
            date: "2020-02-20T00:00:00.000Z",
            amount: 0
        },
        {
            id: 3,
            familyId: "UGF5bWVudDoxOTAwMDE0",
            paymentType: "PI",
            date: "2020-03-13T00:00:00.000Z",
            amount: 30
        },
    ]
};
