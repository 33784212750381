"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_SCOLAPASS_PAYMEMENTS = void 0;
const client_1 = require("@apollo/client");
const scolapassPaymentsFragment_1 = require("./scolapassPaymentsFragment");
exports.GET_SCOLAPASS_PAYMEMENTS = client_1.gql `
  ${scolapassPaymentsFragment_1.SCOLAPASS_PAYMENTS_FRAGMENT}
  query getScolapassPayments($after: Cursor, $first: Int) {
    getUser {
      id
      guests {
        edges {
          node {
            id
            payments: getPayments(order: "-PAI_Date", first: $first, after: $after) {
              ...scolapassPaymentsFragment
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
