import React from "react";

export const UnknownAffluence: React.FC = () => (
<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="34px" height="14px" viewBox="0 0 34 14" version="1.1">
    <title>9FC149D5-826F-44EA-BA95-DD8AC6199E28</title>
    <g id="Icons/Affluence/Unknown" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path d="M13,6.76328502 C12.0375,6.76328502 11.25,6.44766506 10.6375,5.81642512 C10.025,5.18518519 9.71875,4.37359098 9.71875,3.38164251 C9.71875,2.38969404 10.025,1.57809984 10.6375,0.946859903 C11.25,0.315619968 12.0375,0 13,0 C13.9625,0 14.75,0.315619968 15.3625,0.946859903 C15.975,1.57809984 16.28125,2.38969404 16.28125,3.38164251 C16.28125,4.37359098 15.975,5.18518519 15.3625,5.81642512 C14.75,6.44766506 13.9625,6.76328502 13,6.76328502 Z M6,14 L6,11.8808374 C6,11.3097155 6.13854167,10.821256 6.415625,10.4154589 C6.69270833,10.0096618 7.05,9.70155663 7.4875,9.49114332 C8.46458333,9.04025765 9.4015625,8.7020934 10.2984375,8.47665056 C11.1953125,8.25120773 12.0958333,8.13848631 13,8.13848631 C13.9041667,8.13848631 14.8010417,8.25496511 15.690625,8.48792271 C16.5802083,8.7208803 17.5135417,9.05528717 18.490625,9.49114332 C18.9427083,9.70155663 19.3072917,10.0096618 19.584375,10.4154589 C19.8614583,10.821256 20,11.3097155 20,11.8808374 L20,14 L6,14 Z" id="Shape" fill="#999999" fill-rule="nonzero"/>
        <path d="M25,14 L23,14 L23,12 L25,12 L25,14 Z M27.07,6.25 L26.17,7.17 C25.45,7.9 25,8.5 25,10 L23,10 L23,9.5 C23,8.4 23.45,7.4 24.17,6.67 L25.41,5.41 C25.78,5.05 26,4.55 26,4 C26,2.9 25.1,2 24,2 C22.9,2 22,2.9 22,4 L20,4 C20,1.79 21.79,0 24,0 C26.21,0 28,1.79 28,4 C28,4.88 27.64,5.68 27.07,6.25 Z" id="Combined-Shape" fill="#999999"/>
    </g>
</svg>
);
