import { Colors } from "@constants";
import style from "styled-components/native";

export const OFFER_SLOT_GAP = 11;

export const Container = style.View`
    align-items: center;
    justify-content: center;
`;

export const Title = style.Text`
    color: ${Colors.foodiBlack};
    font-family: manrope-bold;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
`;

export const LocalDateContainer = style.View`
    margin: 5px 0px 10px;
`;

export const Description = style.Text<{
  isDate?: boolean;
}>`
    color: ${Colors.foodiBlack};
    font-family: 
    ${({ isDate }) =>
      isDate ? "manrope-bold" : "manrope"}
    font-size: 18;
    letter-spacing: 0;
    line-height: 24;
    text-align: center;
    margin-top: 5;
`;

export const Slot = style.Pressable<{
  hasGap?: boolean;
  isSelected?: boolean;
  brandColor?: string;
  notSelectableAt?: boolean;
  isMobile?: boolean;
}>`
    background-color: ${({ isSelected, brandColor }) =>
      isSelected ? brandColor : Colors.white};
    align-items: center;
    justify-content: center;
    width: ${({ isMobile }) => (isMobile ? 116 : 79)}px;
    height: ${({ isMobile }) => (isMobile ? 75 : 51)}px;
    border: ${({ isSelected }) => (isSelected ? 0 : 1)}px solid ${
  Colors.foodiBlack
};
    border-radius: 8px;
    padding: 10px 20px;
    margin-right: ${({ hasGap }) => (hasGap ? OFFER_SLOT_GAP : 0)}px;
    opacity: ${({ notSelectableAt }) => (notSelectableAt ? 0.25 : 1)};
`;

export const SlotText = style.Text<{ bold?: boolean, textColor?: string }>`
    color: ${({ textColor }) => textColor || Colors.foodiBlack};
    font-family: ${({ bold }) => (bold ? "manrope-bold" : "manrope")};
    font-size: ${({ bold }) => (bold ? 16 : 13)}px;
    letter-spacing: 0;
    line-height: ${({ bold }) => (bold ? 22 : 15)}px;
    text-align: center;
`;

export const WarningMessageContainer = style.View`
    flex-direction: row;
    margin-bottom: 13px;
    margin-top: 9px;
`;

export const WarningMessage = style.Text<{ textColor?: string }>`
    color: ${({ textColor }) => textColor || Colors.foodiBlack};
    font-family: manrope;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 18px;
    margin-left: 6px;
    max-width: 195px;
`;
