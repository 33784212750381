import React from "react";
import { I18n } from "react-redux-i18n";

export const CloseBlack: React.FC = () => (
  <svg
    width="14px"
    height="14px"
    viewBox="0 0 14 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>{I18n.t("hoverIcons.close")}</title>
    <g
      id="Homepage"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="HP-UI---Not-Connected---Fill-ON-Copy"
        transform="translate(-942.000000, -337.000000)"
        fill="#000000"
        fill-rule="nonzero"
      >
        <g id="close_black" transform="translate(942.000000, 337.000000)">
          <polygon
            id="Path"
            points="14 1.41 12.59 0 7 5.59 1.41 0 0 1.41 5.59 7 0 12.59 1.41 14 7 8.41 12.59 14 14 12.59 8.41 7"
          ></polygon>
        </g>
      </g>
    </g>
  </svg>
);
