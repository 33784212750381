"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetNearHoldings = void 0;
class GetNearHoldings {
    constructor(holdingRepository) {
        this.holdingRepository = holdingRepository;
    }
    execute(request) {
        return this.holdingRepository.getNearHoldings(request);
    }
}
exports.GetNearHoldings = GetNearHoldings;
