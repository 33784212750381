"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_SERVICES = void 0;
const client_1 = require("@apollo/client");
exports.GET_SERVICES = client_1.gql `
  query getServices {
    getUser {
      id
      guests {
        edges {
          node {
            id
            holding {
              id
              services {
                id
                url
                type
              }
            }
          }
        }
      }
    }
  }
`;
