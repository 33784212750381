"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetGuestPaymentProfile = void 0;
class GetGuestPaymentProfile {
    constructor(guestService) {
        this.guestService = guestService;
    }
    execute(request) {
        return this.guestService.getGuestPaymentProfile(request);
    }
}
exports.GetGuestPaymentProfile = GetGuestPaymentProfile;
