"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userLastUsedPickupPointFixture = void 0;
exports.userLastUsedPickupPointFixture = {
    loading: false,
    pickupPoint: {
        id: 'userLastUsedPickupPoint:3',
        numericId: 3,
        idHolding: 142,
        name: 'almada',
        withdrawalSchedule: "as 14h",
        companyCode: "Holding:142",
        description: null,
        selected: true
    }
};
