import { Back, VisibilityBlack } from "../../../../assets";
import { CloseClickOutsideTemplate, CloseXButton, ModalTemplate, ScreenWithFooter } from "../../../../atomic";
import { BalanceScreens, TicketThunks, TransactionsViewModel } from "../../..";
import { NavigationProp, Route } from "@react-navigation/native";
import { TestIDs } from "../../../../utils";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { I18n } from "react-redux-i18n";
import { Container, GoBackText } from "./TicketPage.style";
import { useDevices } from "../../../../hooks";
import { Pressable, View, StyleSheet, Text } from "react-native";
import { formatAmount, ITicket, ITicketElement } from "@foodi/core";
import { Colors } from "../../../../constants";
import moment from "moment";
import { Receipt } from "../../../../stylesheets";

interface IProps {
  navigation?: NavigationProp<any>;
  route?: Route<any>;
}

export const TicketPage: React.FC<IProps> = React.memo(
  ({ navigation, route }) => {
    const dispatch = useDispatch();
    const [isMobile] = useDevices();

    const [ticket, setTicket] = useState<ITicket | undefined>();
    const [receipt, setReceipt] = useState<string | undefined>("");

    const styles = useMemo(() => _styles(isMobile), [isMobile]);

    const { id }: any = route?.params || {};

    React.useEffect(() => {
        if(id){
        dispatch(
          TicketThunks.getTicket({
            transactionId: id,
          })
          //@ts-ignore
        ).then((response) => {
          setTicket(response?.ticket);
        });
      }
    }, [id]);

    const handleGoBack = () => {
      navigation?.goBack();
    };

    const transactionVM = new TransactionsViewModel();

    const handleReceipt = async () => {
      const result = await transactionVM.getHandleReceipt(
        receipt || "",
        setReceipt,
        dispatch,
        id
      );
      if (_.isEmpty(result))
        navigation?.navigate(BalanceScreens.ERROR_RECEIPT, {
          errorType: "errorHandler.errorReceipt",
        });
    };

    const handleClose = () => {
      setReceipt(undefined);
    };

    const subTotalElements = ticket?.elements?.reduce((acc, p) => {
      return acc + parseFloat(p.total.amount.replace(",", "."));
    }, 0);

    const totalAdmission = (parseFloat(ticket?.admission.amount || "") + parseFloat(ticket?.admission.subsidy || ""));
    const formattedAmount = `${formatAmount((totalAdmission), "€")}`;

    return (
      <ScreenWithFooter navigation={navigation || ({} as any)}>
        <Container
          testID={TestIDs.home.confirmationPage.views.mainContainer}
          isMobile={isMobile}
        >
          <Pressable style={styles.goBackContainer}
            testID={TestIDs.home.confirmationPage.actions.goBack}
            onPress={handleGoBack}
          >
            <Back />
            <GoBackText isMobile={isMobile}>
              {I18n.t("common.return")}
            </GoBackText>
          </Pressable>

        <View style={styles.detailBlock}>
          <View style={styles.ticketTitle}>
            <Text style={styles.receipt}>{I18n.t("transactionDetail.receipt")} {moment(ticket?.date).format(`DD/MM`)}</Text>
            <Pressable style={styles.row1} 
              onPress={handleReceipt}>
              {I18n.t("transactionDetail.proof")}
              <VisibilityBlack />
            </Pressable>
          </View>

          <ModalTemplate
            isOpen={!!receipt}
            handleClose={handleClose}
            style={styles.receiptDiv}
            customComponentCloseButton={<CloseXButton callback={handleClose}/>}
          >
            <CloseClickOutsideTemplate handleClose={handleClose}>
              {receipt && (
                <Receipt style={styles.receipt}  dangerouslySetInnerHTML={{ __html: receipt }} />
              )}
            </CloseClickOutsideTemplate>
          </ModalTemplate>

          <View style={styles.centeredContainer}>
            <Text style={styles.blockTitleNoMargin}>{ticket?.guest.firstName} {ticket?.guest.lastName}</Text>
            <Text style={styles.pos}>{ticket?.pos.name}</Text>
          </View>

          <View style={styles.centeredContainer}>
            <View style={styles.alignBox}>
              <View>
                <Text style={styles.blockTitleNoMargin}>{I18n.t("Le restaurant")}</Text>
                <Text style={styles.last}>{I18n.t("transactionDetail.the")} {moment(ticket?.date).format(`DD/MM/yyyy ${I18n.t("common.at")} HH:mm`)}</Text>
              </View>
              <View style={styles.rightBox}>
                <Text style={styles.centeredPrice}>{ticket?.total.amount?.replace(".", ",")}€</Text>
              </View>
            </View>
          </View>

          <View style={styles.centeredContainer}>
            <Text style={styles.blockTitle}>{I18n.t("transactionDetail.plate")}</Text>
              {ticket?.elements?.map((p: ITicketElement) => {
                return(
                  <View style={styles.elementContainerProduct}>
                    <Text style={styles.productDescription}>
                      {p.quantity} {p.label}                  
                    </Text>
                    <View style={styles.priceDescription}>
                        {p.total.amount.replace(".", ",")}€
                    </View>
                  </View>
                );
                })}
            <View style={styles.separator} />
            <View style={styles.subtotalContainer}>
              <Text style={styles.lastPrice}>{I18n.t("transactionDetail.subtotal")}</Text>
              <Text style={styles.price}>{subTotalElements}€</Text>
            </View>
          </View>

          <View style={styles.centeredContainer}>
          <Text style={styles.blockTitle}>{I18n.t("transactionDetail.admission")}</Text>
            <View style={styles.elementContainer}>
              <Text style={styles.left}>{I18n.t("transactionDetail.admission")}</Text>
              <Text style={styles.price}>{ticket?.admission.amount?.replace(".", ",")}€</Text>
            </View>

            <View style={styles.elementContainer}>
              <Text style={styles.left}>{I18n.t("transactionDetail.subAdmission")}</Text>
              <Text style={styles.price}>{ticket?.admission.subsidy?.replace(".", ",")}€</Text>
            </View>

            <View style={styles.separator} />
            <View style={styles.subtotalContainer}>
              <Text style={styles.lastPrice}>{I18n.t("transactionDetail.subtotal")}</Text>
              <Text style={styles.price}>{formattedAmount}</Text>
            </View>
          </View>

          <View style={styles.centeredContainer}>
          <Text style={styles.blockTitle}>{I18n.t("transactionDetail.total")}</Text>
            <View style={styles.elementContainer}>
              <Text style={styles.left}>{I18n.t("transactionDetail.oldBalance")}</Text>
              <Text style={styles.price}>{ticket?.oldBalance.amount?.replace(".", ",")}€</Text>
            </View>

            <View style={styles.elementContainer}>
              <Text style={styles.left}>{I18n.t("transactionDetail.totalAmountPaid")}</Text>
              <Text style={styles.price}>{ticket?.total.amount?.replace(".", ",")}€</Text>
            </View>

            <View style={styles.elementContainer}>
              <Text style={styles.last}>{I18n.t("transactionDetail.newBalance")}</Text>
              <Text style={styles.price}>{ticket?.newBalance.amount?.replace(".", ",")}€</Text>
            </View>
          </View>

          </View>
        </Container>
      </ScreenWithFooter>
    );
  }
);

const _styles = (isMobile?: boolean, ) =>
  StyleSheet.create({
    ticketTitle: {
      flexDirection: "row",
      alignItems: "baseline",
      justifyContent: "space-between",
    },
    row1: {
      flexDirection: "row",
      fontFamily: "manrope",
      textDecorationLine: "underline",
      color: Colors.blue1,
      fontWeight: "bold",
      alignItems: "flex-end",
      textAlign: "right"
    },
    date: {
      color: "Colors.foodiBlack",
      marginRight: 10,
    },
    left: {
      textAlign: "left",
      fontSize: 16,
      fontFamily: "manrope",
      marginBottom: 12
    },
    elementContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
    elementContainerProduct: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: 12,
    },
    subtotalContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
      fontWeight: "bold",
      fontSize: 16
    },
    separator: {
      width: "100%",
      height: 1,
      backgroundColor: Colors.border1,
      marginBottom: 12,

    },
    receipt: {
      fontSize: isMobile ? 22 : 30,
      textAlign: "left",
      fontFamily: "manrope-bold",
      color: Colors.foodiBlack,
      letterSpacing: 0,
      lineHeight: isMobile ? 41 : 49,
      marginTop: 24
    },
    detailBlock: {
      width: isMobile ? "25em" : "400px",
      alignSelf: "center",
      paddingHorizontal: isMobile ? 20 : 0,
    },
    centeredPrice: {
      textAlign: "right",
      fontSize: 22,
      fontWeight: "bold",
      fontFamily: "manrope"
    },
    pos: {
      fontSize: 16,
      fontFamily: "manrope"
    },
    last: {
      textAlign: "left",
      fontSize: 16,
      fontFamily: "manrope",
    },
    lastPrice: {
      textAlign: "left",
      fontSize: 16,
      fontFamily: "manrope-bold",
    },
    centeredContainer: {
      width: isMobile ? '42vh' : '400px',
      marginTop: 30,
      borderWidth: 1,
      bordeColor: Colors.border1,
      paddingHorizontal: 20,
      paddingVertical: 12,
      borderRadius: 8,
      alignSelf: "center",
      display: "flex",
      flexDirection: "column",
    },
    alignBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between"
    },
    rightBox: {
      justifyContent: "center"
    },
    productDescription: {
      color: Colors.foodiBlack,
      fontFamily: "manrope",
      fontSize: 16,
      lineHeight: 22,
      textDecorationLine: "none",
    },
    column: {
      display: "flex",
      flexDirection: "column",
    },
    priceDescription: {
      alignSelf: "flex-end",
      fontFamily: "manrope",
      fontSize: 16
    },
    price: {
      textAlign: "right",
      fontSize: 16,
      fontFamily: "manrope"
    },
    receiptDiv: {
      width: isMobile ? "100%" : 520,
      maxHeight: isMobile ? "100%" : "95vh",
    },
    goBackContainer: {
      flexDirection: "row",
      alignItems: "center",
      maxWidth: 250
    },
    blockTitle: {
      color: Colors.foodiBlack,
      fontFamily: "manrope-bold",
      fontSize: 22,
      marginBottom: 14
    },
    blockTitleNoMargin: {
      color: Colors.foodiBlack,
      fontFamily: "manrope-bold",
      fontSize: 22,
    }
  });