"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InMemoryAuthenticationService = void 0;
const domain_1 = require("../../../domain");
class InMemoryAuthenticationService {
    constructor(checkEmailExistFixtures) {
        this.checkEmailExistFixtures = checkEmailExistFixtures;
    }
    loginIDP(code) {
        throw new Error("Method not implemented.");
    }
    deleteAccount(request) {
        return __awaiter(this, void 0, void 0, function* () {
            throw new Error("Method not implemented.");
        });
    }
    initializeAuth0Client(config) {
        throw new Error("Method not implemented.");
    }
    login(config) {
        throw new Error("Method not implemented.");
    }
    retrieveTokens(dispatchCallback) {
        throw new Error("Method not implemented.");
    }
    refreshToken() {
        throw new Error("Method not implemented.");
    }
    checkEmail(variables) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.checkEmailExistFixtures) {
                return Promise.resolve(this.checkEmailExistFixtures);
            }
            return Promise.reject(new domain_1.AuthenticationServiceErrors.CheckEmailRequestError("CHECK_EMAIL_REQUEST_FAILED"));
        });
    }
    signUp(variables) {
        return __awaiter(this, void 0, void 0, function* () {
            throw new Error("Method not implemented.");
        });
    }
    loginSSO(config) {
        throw new Error("Method not implemented.");
    }
    sendResetPasswordEmail(request) {
        return __awaiter(this, void 0, void 0, function* () {
            throw new Error("Method not implemented.");
        });
    }
}
exports.InMemoryAuthenticationService = InMemoryAuthenticationService;
