"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_EPOINTAGE_SERVICES = void 0;
const client_1 = require("@apollo/client");
exports.GET_EPOINTAGE_SERVICES = client_1.gql `
query getEPointageServices ($siteId: String, $published: String){
    list: getEPointageServices (siteId: $siteId, published: $published){
            id
            name
            published
      }
  }  
`;
