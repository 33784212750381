"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetTicket = void 0;
class GetTicket {
    constructor(ticketRepository) {
        this.ticketRepository = ticketRepository;
    }
    execute(request) {
        return this.ticketRepository.getTicket(request);
    }
}
exports.GetTicket = GetTicket;
