import React from "react";
import { WarningOrange } from "@assets";
import { TestIDs } from "@utils";
import { I18n } from "react-redux-i18n";
import {
  Container,
  WarningMessage,
  WarningMessageContainer,
} from "./OrderComment.style";
import { TextAreaInput } from "@atomic/atoms/TextAreaInput";

interface IProps {
  comment: string;
  onChange: (comment: string) => void;
}

const MAX_COMMENT_LENGHT = 250;

export const OrderComment = React.memo(({ comment, onChange }: IProps) => {
  const showWarning = comment.length >= MAX_COMMENT_LENGHT;

  return (
    <Container testID={TestIDs.restaurantDetail.views.orderCommentContainer}>
      <TextAreaInput
        forwardTestID={TestIDs.restaurantDetail.inputs.orderComment}
        value={comment}
        onChangeText={onChange}
        warningHighlight={showWarning}
        placeholder={I18n.t("restaurantDetail.cart.commentPlaceholder")}
        maxLength={MAX_COMMENT_LENGHT}
        numberOfLines={3}
      />
      {showWarning && (
        <WarningMessageContainer>
          <WarningOrange />
          <WarningMessage
            testID={TestIDs.restaurantDetail.texts.orderCommentWarning}
          >
            {I18n.t("restaurantDetail.cart.commentMaxLengthReached", {
              maxLength: MAX_COMMENT_LENGHT,
            })}
          </WarningMessage>
        </WarningMessageContainer>
      )}
    </Container>
  );
});
