import { Container, ButtonContainer, Button, Label } from "./LoginInfo.style";
import { TestIDs } from "@utils";
import React, { useMemo } from "react";
import { Title22, Title30 } from "@stylesheets";
import { useSelector, useDispatch } from "react-redux";
import { GlobalState } from "@redux";
import { Colors } from "@constants";
import { AuthThunks, HomeScreens } from "@modules";
import { NavigationProp } from "@react-navigation/native";
import { useDevices } from "@hooks";
import { I18n } from "react-redux-i18n";
import { StyleSheet } from "react-native";

interface IProps {
  navigation?: NavigationProp<any>;
}

export const LoginInfo: React.FC<IProps> = React.memo(({ navigation }) => {
  const brandTheme = useSelector(
    (state: GlobalState) => state.brandTheme.brandTheme
  );

  const [isMobile] = useDevices();
  const styles = useMemo(() => _styles(isMobile), [isMobile]);
  const dispatch = useDispatch();

  const handleCreateAccount = () => {
    navigation?.navigate(HomeScreens.ACCOUNT_STACK);
  };

  const handleLogin = () => {
    dispatch(AuthThunks.login());
  };

  const Title: React.FC<any> = isMobile ? Title22 : Title30;

  return (
    <Container testID={TestIDs.home.loginInfo.views.container}>
      <Title isBlack style={styles.title}>
        {I18n.t("homepage.notConnected")}
      </Title>

      <ButtonContainer isMobile={isMobile}>
        {!isMobile ? (
          <>
            <Button
              testID={TestIDs.home.loginInfo.actions.goToCreateAccount}
              onPress={handleCreateAccount}
              color={Colors.white}
              hasBorder={true}
            >
              <Label>{I18n.t("homepage.createAccount")}</Label>
            </Button>
            <Button
              testID={TestIDs.home.loginInfo.actions.goToLogin}
              onPress={handleLogin}
              color={
                brandTheme.buttonActionColors?.backgroundColor ||
                Colors.foodiDefault
              }
            >
              <Label
                textColor={
                  brandTheme.buttonActionColors?.textColor || Colors.foodiBlack
                }
              >
                {I18n.t("homepage.login")}
              </Label>
            </Button>
          </>
        ) : (
          <>
            <Button
              testID={TestIDs.home.loginInfo.actions.goToLogin}
              onPress={handleLogin}
              color={
                brandTheme.buttonActionColors?.backgroundColor ||
                Colors.foodiDefault
              }
            >
              <Label
                textColor={
                  brandTheme.buttonActionColors?.textColor || Colors.foodiBlack
                }
              >
                {I18n.t("homepage.login")}
              </Label>
            </Button>
            <Button
              testID={TestIDs.home.loginInfo.actions.goToCreateAccount}
              onPress={handleCreateAccount}
              color={Colors.white}
              hasBorder={true}
            >
              <Label>{I18n.t("homepage.createAccount")}</Label>
            </Button>
          </>
        )}
      </ButtonContainer>
    </Container>
  );
});

const _styles = (isMobile: boolean) => StyleSheet.create({
  title: {
    fontFamily: "manrope",
    letterSpacing: 0,
    fontWeight: "normal",
    fontSize: isMobile ? 16 : 18,
    lineHeight: 21,
    alignSelf: "center"
  },
});
