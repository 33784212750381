"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.bookingMenusFixtures = void 0;
exports.bookingMenusFixtures = [
    {
        day: "2022-02-08",
        elements: [
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTYx",
                label: "Tarte aux pommes crumble",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTYz",
                label: "Tartelette citron, confit pommes & rave, pavot",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTY0",
                label: "Pavé de boeuf champignons, grenailles",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjQx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTY1",
                label: "Cabillaud à l'ail & citronnelle, chop suey et noodles",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjQ0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTY3",
                label: "Pomme golden",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTY4",
                label: "Banane",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTY5",
                label: "Kiwi",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTcw",
                label: "Kaki ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTc0",
                label: "Tiramisu Savoiardi au mètre",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjY4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTc1",
                label: "Crème de poireaux & pomme de terre, pain au lard",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTc3",
                label: "Wrap grillé poulet césar",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTc4",
                label: "Houmous, champignons & salade d'herbes",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTgw",
                label: "Riz de chou-fleur, pesto rouge, pois chiches rôtis et fêta",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjE3Mg==",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTQ1",
                label: "Salade mêlée",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjg=",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTQ2",
                label: "Oeufs durs béarnaise à l'estragon",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjg=",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTQ3",
                label: "Jambon de paris",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjg=",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTU4",
                label: "Choucroute de la mer",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjE4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTcy",
                label: " crème dessert chocolat danette",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI1",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6OA==",
                        label: "PRODUITS LAITIERS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTcz",
                label: "Liégeois chocolat dessert",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI1",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6OA==",
                        label: "PRODUITS LAITIERS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTgw",
                label: "Fromage blanc ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTgx",
                label: "Fromage blanc o%",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTgy",
                label: "Compotée pommes cannelle",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTgz",
                label: "Semoule au lait",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTg0",
                label: "Creme de marron",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTg2",
                label: "Tarte flan chocolat ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTg3",
                label: "Île flottante au caramel et crème anglaise aux amandes effiles",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTg4",
                label: "Mille-feuille au citron",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTg5",
                label: "Smoothie orange ananas gingembre",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTkw",
                label: "Crème brûlée",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTkx",
                label: "Boeuf aux carottes confites et macaronis",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI5",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTky",
                label: "Carotte",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTkz",
                label: "Concombre",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTk0",
                label: "Chou fleur croquant aux zestes de citron",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTk1",
                label: "Salade feuille de chene",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTk2",
                label: "Haricots plats et oignons rouges",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTk3",
                label: "Semoule BIO complète",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTk4",
                label: "Salade de brocolis et pousses d'épinards façon caésar",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTk5",
                label: "Frites",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjM0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mw==",
                        label: "LEGUMES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjAw",
                label: "Filet de maquereau vinaigrette de coriandre",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjM1",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjAx",
                label: "Pintade braisée au chou vert",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjQx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjAy",
                label: "Noix de veau rôtie jus corsé au romarin",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjQx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjAz",
                label: "Filet de julienne vinaigrette de câpre et moutarde",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjQ0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjA0",
                label: "Steak haché",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjUx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjA1",
                label: "Filet de poulet grillé",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjUx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjA2",
                label: "Pavé de rumsteak grillé",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjUx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjA4",
                label: "Ananas frais",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjA5",
                label: "Orange",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjEw",
                label: "Lentilles ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjY0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mw==",
                        label: "LEGUMES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjEx",
                label: "Riz pilaf aux amandes et raisin",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjY0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mw==",
                        label: "LEGUMES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjEy",
                label: "Céleri braisé",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjY0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mw==",
                        label: "LEGUMES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjEz",
                label: "Petits pois aux oignons rouges",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjY0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mw==",
                        label: "LEGUMES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjE5",
                label: "Pomme granny smith",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjIw",
                label: "Banane",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjIx",
                label: "Kiwi",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjIy",
                label: "Orange",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjIz",
                label: "Poire",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjI0",
                label: "Clémentines",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjI1",
                label: "Kebab de volaille, sauce blanche, pommes de terre rôties ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjI2",
                label: "Tagliatelles carbonara",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjgy",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjI3",
                label: "Croûtons boulanger",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkw",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTY=",
                        label: "TABLE A CONDIMENT",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjI5",
                label: "Trévise en chiffonade",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkw",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTY=",
                        label: "TABLE A CONDIMENT",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjMx",
                label: "Soupe à l’oignon ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjMy",
                label: "Tartine parisienne: jambon, emmental ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjMz",
                label: "Salade gourmande acidulée",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjM0",
                label: "Poireaux mimosa et câpres",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjM1",
                label: "Rillettes de saumon, herbes et citron vert",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjM2",
                label: "Muffin des de brebis et raisins secs",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
        ],
    },
    {
        day: "2022-02-09",
        elements: [
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ2MDAz",
                label: "Saumon entier zaatar",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjQ0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ2MDA1",
                label: "Pomme golden",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ2MDA2",
                label: "Banane",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ2MDA3",
                label: "Kiwi",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ2MDA4",
                label: "Kaki ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ2MDEz",
                label: "Soupe de patates douces et épices indiennes, orange & amandes",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ2MDE1",
                label: "Crémeux carotte, riz & beluga, salade de copeaux",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ2MDE3",
                label: "Houmous betterave, chèvre",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ2MDE4",
                label: "Sarrasin grillé et lentilles, falafel légumes & Labneh",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjE3Mg==",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjQw",
                label: "Salade mêlée",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjg=",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjQx",
                label: "Oeufs durs à la parisienne",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjg=",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjQy",
                label: "Jambon de paris",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjg=",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjUz",
                label: "Poulet au citron en tajine et boulgour aux fèves et petits pois ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjE4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjY3",
                label: " crème dessert chocolat danette",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI1",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6OA==",
                        label: "PRODUITS LAITIERS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjY4",
                label: "Liégeois chocolat dessert",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI1",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6OA==",
                        label: "PRODUITS LAITIERS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjc1",
                label: "Fromage blanc ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjc2",
                label: "Fromage blanc o%",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjc3",
                label: "Compote pommes poires",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjc4",
                label: "Riz au lait",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjc5",
                label: "Coulis de framboise",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjgx",
                label: "Paris-brest",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjgy",
                label: "Gateau moelleux au chocolat blanc",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjgz",
                label: "Muffin poire et chocolat",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjg0",
                label: "Ananas caramel, crème vanillée ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjg1",
                label: "Flamri au chocolat",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjg2",
                label: "Gnocchi sauce tomate piquante et roquette",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI5",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjg3",
                label: "Carotte",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjg4",
                label: "Concombre",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjg5",
                label: "Champignon blanc",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjkw",
                label: "Salade batavia",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjkx",
                label: "Salad bar bolly tandoori de choux fleurs frais",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjky",
                label: "Salade de pois chiches à la coriandre et cumin",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjkz",
                label: "Salade de patates douces et pousses d'épinards",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjk0",
                label: "Frites",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjM0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mw==",
                        label: "LEGUMES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjk1",
                label: "Pavé de thon sauce chien",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjM1",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjk2",
                label: "Filet mignon de porc rôti pesto de cerfeuil aux noix et tomates séchées",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjQx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjk3",
                label: "Cuisse de poulet sauce aigre-douce",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjQx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjk4",
                label: "Filet de sandre oignon confit  graine de moutarde",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjQ0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjk5",
                label: "Steak haché",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjUx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzAw",
                label: "Filet de poulet grillé",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjUx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzAx",
                label: "Pavé de rumsteak grillé",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjUx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzAz",
                label: "Ananas frais",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzA0",
                label: "Clémentine",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzA1",
                label: "Macaronis",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjY0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mw==",
                        label: "LEGUMES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzA2",
                label: "Semoule à la coriandre",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjY0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mw==",
                        label: "LEGUMES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzA3",
                label: "Endives braisées à l’orange et coriandre",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjY0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mw==",
                        label: "LEGUMES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzA4",
                label: "Flan d'épinards",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjY0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mw==",
                        label: "LEGUMES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzE0",
                label: "Pomme golden",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzE1",
                label: "Banane",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzE2",
                label: "Kiwi",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzE3",
                label: "Orange",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzE4",
                label: "Poire",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzE5",
                label: "Kaki",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzIw",
                label: "Burger boeuf chedar",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzIy",
                label: "Croûtons boulanger",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkw",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTY=",
                        label: "TABLE A CONDIMENT",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzI0",
                label: "Bleu en dés",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkw",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTY=",
                        label: "TABLE A CONDIMENT",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzI2",
                label: "Velouté de carotte au lait de coco",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzI3",
                label: "Terrine de poisson salade de mache aux fruits secs et crème acidulée",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzI4",
                label: "Rémoulade de céleri-rave tzatziki",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzI5",
                label: "Salade de mojette et lardons au vinaigre de cidre",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzMw",
                label: "Salade de mâche aux poire et roquefort",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzMx",
                label: "Tartine oeuf avocat",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
        ],
    },
    {
        day: "2022-02-10",
        elements: [
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTYx",
                label: "Tarte aux pommes crumble",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTYz",
                label: "Tartelette citron, confit pommes & rave, pavot",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTY0",
                label: "Pavé de boeuf champignons, grenailles",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjQx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTY1",
                label: "Cabillaud à l'ail & citronnelle, chop suey et noodles",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjQ0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTY3",
                label: "Pomme golden",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTY4",
                label: "Banane",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTY5",
                label: "Kiwi",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTcw",
                label: "Kaki ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTc0",
                label: "Tiramisu Savoiardi au mètre",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjY4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTc1",
                label: "Crème de poireaux & pomme de terre, pain au lard",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTc3",
                label: "Wrap grillé poulet césar",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTc4",
                label: "Houmous, champignons & salade d'herbes",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTgw",
                label: "Riz de chou-fleur, pesto rouge, pois chiches rôtis et fêta",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjE3Mg==",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTQ1",
                label: "Salade mêlée",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjg=",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTQ2",
                label: "Oeufs durs béarnaise à l'estragon",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjg=",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTQ3",
                label: "Jambon de paris",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjg=",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTU4",
                label: "Choucroute de la mer",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjE4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTcy",
                label: " crème dessert chocolat danette",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI1",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6OA==",
                        label: "PRODUITS LAITIERS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTcz",
                label: "Liégeois chocolat dessert",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI1",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6OA==",
                        label: "PRODUITS LAITIERS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTgw",
                label: "Fromage blanc ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTgx",
                label: "Fromage blanc o%",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTgy",
                label: "Compotée pommes cannelle",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTgz",
                label: "Semoule au lait",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTg0",
                label: "Creme de marron",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTg2",
                label: "Tarte flan chocolat ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTg3",
                label: "Île flottante au caramel et crème anglaise aux amandes effiles",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTg4",
                label: "Mille-feuille au citron",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTg5",
                label: "Smoothie orange ananas gingembre",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTkw",
                label: "Crème brûlée",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTkx",
                label: "Boeuf aux carottes confites et macaronis",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI5",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTky",
                label: "Carotte",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTkz",
                label: "Concombre",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTk0",
                label: "Chou fleur croquant aux zestes de citron",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTk1",
                label: "Salade feuille de chene",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTk2",
                label: "Haricots plats et oignons rouges",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTk3",
                label: "Semoule BIO complète",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTk4",
                label: "Salade de brocolis et pousses d'épinards façon caésar",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTk5",
                label: "Frites",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjM0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mw==",
                        label: "LEGUMES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjAw",
                label: "Filet de maquereau vinaigrette de coriandre",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjM1",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjAx",
                label: "Pintade braisée au chou vert",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjQx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjAy",
                label: "Noix de veau rôtie jus corsé au romarin",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjQx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjAz",
                label: "Filet de julienne vinaigrette de câpre et moutarde",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjQ0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjA0",
                label: "Steak haché",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjUx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjA1",
                label: "Filet de poulet grillé",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjUx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjA2",
                label: "Pavé de rumsteak grillé",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjUx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjA4",
                label: "Ananas frais",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjA5",
                label: "Orange",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjEw",
                label: "Lentilles ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjY0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mw==",
                        label: "LEGUMES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjEx",
                label: "Riz pilaf aux amandes et raisin",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjY0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mw==",
                        label: "LEGUMES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjEy",
                label: "Céleri braisé",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjY0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mw==",
                        label: "LEGUMES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjEz",
                label: "Petits pois aux oignons rouges",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjY0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mw==",
                        label: "LEGUMES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjE5",
                label: "Pomme granny smith",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjIw",
                label: "Banane",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjIx",
                label: "Kiwi",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjIy",
                label: "Orange",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjIz",
                label: "Poire",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjI0",
                label: "Clémentines",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjI1",
                label: "Kebab de volaille, sauce blanche, pommes de terre rôties ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjI2",
                label: "Tagliatelles carbonara",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjgy",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjI3",
                label: "Croûtons boulanger",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkw",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTY=",
                        label: "TABLE A CONDIMENT",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjI5",
                label: "Trévise en chiffonade",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkw",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTY=",
                        label: "TABLE A CONDIMENT",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjMx",
                label: "Soupe à l’oignon ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjMy",
                label: "Tartine parisienne: jambon, emmental ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjMz",
                label: "Salade gourmande acidulée",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjM0",
                label: "Poireaux mimosa et câpres",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjM1",
                label: "Rillettes de saumon, herbes et citron vert",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjM2",
                label: "Muffin des de brebis et raisins secs",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
        ],
    },
    {
        day: "2022-02-11",
        elements: [
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ2MDAz",
                label: "Saumon entier zaatar",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjQ0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ2MDA1",
                label: "Pomme golden",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ2MDA2",
                label: "Banane",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ2MDA3",
                label: "Kiwi",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ2MDA4",
                label: "Kaki ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ2MDEz",
                label: "Soupe de patates douces et épices indiennes, orange & amandes",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ2MDE1",
                label: "Crémeux carotte, riz & beluga, salade de copeaux",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ2MDE3",
                label: "Houmous betterave, chèvre",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ2MDE4",
                label: "Sarrasin grillé et lentilles, falafel légumes & Labneh",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjE3Mg==",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjQw",
                label: "Salade mêlée",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjg=",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjQx",
                label: "Oeufs durs à la parisienne",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjg=",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjQy",
                label: "Jambon de paris",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjg=",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjUz",
                label: "Poulet au citron en tajine et boulgour aux fèves et petits pois ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjE4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjY3",
                label: " crème dessert chocolat danette",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI1",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6OA==",
                        label: "PRODUITS LAITIERS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjY4",
                label: "Liégeois chocolat dessert",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI1",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6OA==",
                        label: "PRODUITS LAITIERS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjc1",
                label: "Fromage blanc ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjc2",
                label: "Fromage blanc o%",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjc3",
                label: "Compote pommes poires",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjc4",
                label: "Riz au lait",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjc5",
                label: "Coulis de framboise",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjgx",
                label: "Paris-brest",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjgy",
                label: "Gateau moelleux au chocolat blanc",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjgz",
                label: "Muffin poire et chocolat",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjg0",
                label: "Ananas caramel, crème vanillée ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjg1",
                label: "Flamri au chocolat",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjg2",
                label: "Gnocchi sauce tomate piquante et roquette",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI5",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjg3",
                label: "Carotte",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjg4",
                label: "Concombre",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjg5",
                label: "Champignon blanc",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjkw",
                label: "Salade batavia",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjkx",
                label: "Salad bar bolly tandoori de choux fleurs frais",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjky",
                label: "Salade de pois chiches à la coriandre et cumin",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjkz",
                label: "Salade de patates douces et pousses d'épinards",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjk0",
                label: "Frites",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjM0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mw==",
                        label: "LEGUMES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjk1",
                label: "Pavé de thon sauce chien",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjM1",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjk2",
                label: "Filet mignon de porc rôti pesto de cerfeuil aux noix et tomates séchées",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjQx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjk3",
                label: "Cuisse de poulet sauce aigre-douce",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjQx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjk4",
                label: "Filet de sandre oignon confit  graine de moutarde",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjQ0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjk5",
                label: "Steak haché",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjUx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzAw",
                label: "Filet de poulet grillé",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjUx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzAx",
                label: "Pavé de rumsteak grillé",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjUx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzAz",
                label: "Ananas frais",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzA0",
                label: "Clémentine",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzA1",
                label: "Macaronis",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjY0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mw==",
                        label: "LEGUMES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzA2",
                label: "Semoule à la coriandre",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjY0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mw==",
                        label: "LEGUMES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzA3",
                label: "Endives braisées à l’orange et coriandre",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjY0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mw==",
                        label: "LEGUMES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzA4",
                label: "Flan d'épinards",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjY0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mw==",
                        label: "LEGUMES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzE0",
                label: "Pomme golden",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzE1",
                label: "Banane",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzE2",
                label: "Kiwi",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzE3",
                label: "Orange",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzE4",
                label: "Poire",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzE5",
                label: "Kaki",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzIw",
                label: "Burger boeuf chedar",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzIy",
                label: "Croûtons boulanger",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkw",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTY=",
                        label: "TABLE A CONDIMENT",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzI0",
                label: "Bleu en dés",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkw",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTY=",
                        label: "TABLE A CONDIMENT",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzI2",
                label: "Velouté de carotte au lait de coco",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzI3",
                label: "Terrine de poisson salade de mache aux fruits secs et crème acidulée",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzI4",
                label: "Rémoulade de céleri-rave tzatziki",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzI5",
                label: "Salade de mojette et lardons au vinaigre de cidre",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzMw",
                label: "Salade de mâche aux poire et roquefort",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzMx",
                label: "Tartine oeuf avocat",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
        ],
    },
    {
        day: "2022-02-12",
        elements: [
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTYx",
                label: "Tarte aux pommes crumble",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTYz",
                label: "Tartelette citron, confit pommes & rave, pavot",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTY0",
                label: "Pavé de boeuf champignons, grenailles",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjQx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTY1",
                label: "Cabillaud à l'ail & citronnelle, chop suey et noodles",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjQ0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTY3",
                label: "Pomme golden",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTY4",
                label: "Banane",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTY5",
                label: "Kiwi",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTcw",
                label: "Kaki ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTc0",
                label: "Tiramisu Savoiardi au mètre",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjY4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTc1",
                label: "Crème de poireaux & pomme de terre, pain au lard",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTc3",
                label: "Wrap grillé poulet césar",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTc4",
                label: "Houmous, champignons & salade d'herbes",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTgw",
                label: "Riz de chou-fleur, pesto rouge, pois chiches rôtis et fêta",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjE3Mg==",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTQ1",
                label: "Salade mêlée",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjg=",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTQ2",
                label: "Oeufs durs béarnaise à l'estragon",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjg=",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTQ3",
                label: "Jambon de paris",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjg=",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTU4",
                label: "Choucroute de la mer",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjE4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTcy",
                label: " crème dessert chocolat danette",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI1",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6OA==",
                        label: "PRODUITS LAITIERS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTcz",
                label: "Liégeois chocolat dessert",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI1",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6OA==",
                        label: "PRODUITS LAITIERS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTgw",
                label: "Fromage blanc ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTgx",
                label: "Fromage blanc o%",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTgy",
                label: "Compotée pommes cannelle",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTgz",
                label: "Semoule au lait",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTg0",
                label: "Creme de marron",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTg2",
                label: "Tarte flan chocolat ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTg3",
                label: "Île flottante au caramel et crème anglaise aux amandes effiles",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTg4",
                label: "Mille-feuille au citron",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTg5",
                label: "Smoothie orange ananas gingembre",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTkw",
                label: "Crème brûlée",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTkx",
                label: "Boeuf aux carottes confites et macaronis",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI5",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTky",
                label: "Carotte",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTkz",
                label: "Concombre",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTk0",
                label: "Chou fleur croquant aux zestes de citron",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTk1",
                label: "Salade feuille de chene",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTk2",
                label: "Haricots plats et oignons rouges",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTk3",
                label: "Semoule BIO complète",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTk4",
                label: "Salade de brocolis et pousses d'épinards façon caésar",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTk5",
                label: "Frites",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjM0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mw==",
                        label: "LEGUMES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjAw",
                label: "Filet de maquereau vinaigrette de coriandre",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjM1",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjAx",
                label: "Pintade braisée au chou vert",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjQx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjAy",
                label: "Noix de veau rôtie jus corsé au romarin",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjQx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjAz",
                label: "Filet de julienne vinaigrette de câpre et moutarde",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjQ0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjA0",
                label: "Steak haché",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjUx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjA1",
                label: "Filet de poulet grillé",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjUx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjA2",
                label: "Pavé de rumsteak grillé",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjUx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjA4",
                label: "Ananas frais",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjA5",
                label: "Orange",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjEw",
                label: "Lentilles ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjY0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mw==",
                        label: "LEGUMES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjEx",
                label: "Riz pilaf aux amandes et raisin",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjY0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mw==",
                        label: "LEGUMES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjEy",
                label: "Céleri braisé",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjY0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mw==",
                        label: "LEGUMES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjEz",
                label: "Petits pois aux oignons rouges",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjY0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mw==",
                        label: "LEGUMES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjE5",
                label: "Pomme granny smith",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjIw",
                label: "Banane",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjIx",
                label: "Kiwi",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjIy",
                label: "Orange",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjIz",
                label: "Poire",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjI0",
                label: "Clémentines",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjI1",
                label: "Kebab de volaille, sauce blanche, pommes de terre rôties ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjI2",
                label: "Tagliatelles carbonara",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjgy",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjI3",
                label: "Croûtons boulanger",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkw",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTY=",
                        label: "TABLE A CONDIMENT",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjI5",
                label: "Trévise en chiffonade",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkw",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTY=",
                        label: "TABLE A CONDIMENT",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjMx",
                label: "Soupe à l’oignon ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjMy",
                label: "Tartine parisienne: jambon, emmental ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjMz",
                label: "Salade gourmande acidulée",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjM0",
                label: "Poireaux mimosa et câpres",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjM1",
                label: "Rillettes de saumon, herbes et citron vert",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjM2",
                label: "Muffin des de brebis et raisins secs",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
        ],
    },
    {
        day: "2022-02-13",
        elements: [
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ2MDAz",
                label: "Saumon entier zaatar",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjQ0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ2MDA1",
                label: "Pomme golden",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ2MDA2",
                label: "Banane",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ2MDA3",
                label: "Kiwi",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ2MDA4",
                label: "Kaki ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ2MDEz",
                label: "Soupe de patates douces et épices indiennes, orange & amandes",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ2MDE1",
                label: "Crémeux carotte, riz & beluga, salade de copeaux",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ2MDE3",
                label: "Houmous betterave, chèvre",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ2MDE4",
                label: "Sarrasin grillé et lentilles, falafel légumes & Labneh",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjE3Mg==",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjQw",
                label: "Salade mêlée",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjg=",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjQx",
                label: "Oeufs durs à la parisienne",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjg=",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjQy",
                label: "Jambon de paris",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjg=",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjUz",
                label: "Poulet au citron en tajine et boulgour aux fèves et petits pois ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjE4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjY3",
                label: " crème dessert chocolat danette",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI1",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6OA==",
                        label: "PRODUITS LAITIERS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjY4",
                label: "Liégeois chocolat dessert",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI1",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6OA==",
                        label: "PRODUITS LAITIERS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjc1",
                label: "Fromage blanc ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjc2",
                label: "Fromage blanc o%",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjc3",
                label: "Compote pommes poires",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjc4",
                label: "Riz au lait",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjc5",
                label: "Coulis de framboise",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjgx",
                label: "Paris-brest",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjgy",
                label: "Gateau moelleux au chocolat blanc",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjgz",
                label: "Muffin poire et chocolat",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjg0",
                label: "Ananas caramel, crème vanillée ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjg1",
                label: "Flamri au chocolat",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjg2",
                label: "Gnocchi sauce tomate piquante et roquette",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI5",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjg3",
                label: "Carotte",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjg4",
                label: "Concombre",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjg5",
                label: "Champignon blanc",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjkw",
                label: "Salade batavia",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjkx",
                label: "Salad bar bolly tandoori de choux fleurs frais",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjky",
                label: "Salade de pois chiches à la coriandre et cumin",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjkz",
                label: "Salade de patates douces et pousses d'épinards",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjk0",
                label: "Frites",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjM0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mw==",
                        label: "LEGUMES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjk1",
                label: "Pavé de thon sauce chien",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjM1",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjk2",
                label: "Filet mignon de porc rôti pesto de cerfeuil aux noix et tomates séchées",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjQx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjk3",
                label: "Cuisse de poulet sauce aigre-douce",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjQx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjk4",
                label: "Filet de sandre oignon confit  graine de moutarde",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjQ0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjk5",
                label: "Steak haché",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjUx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzAw",
                label: "Filet de poulet grillé",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjUx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzAx",
                label: "Pavé de rumsteak grillé",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjUx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzAz",
                label: "Ananas frais",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzA0",
                label: "Clémentine",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzA1",
                label: "Macaronis",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjY0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mw==",
                        label: "LEGUMES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzA2",
                label: "Semoule à la coriandre",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjY0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mw==",
                        label: "LEGUMES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzA3",
                label: "Endives braisées à l’orange et coriandre",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjY0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mw==",
                        label: "LEGUMES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzA4",
                label: "Flan d'épinards",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjY0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mw==",
                        label: "LEGUMES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzE0",
                label: "Pomme golden",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzE1",
                label: "Banane",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzE2",
                label: "Kiwi",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzE3",
                label: "Orange",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzE4",
                label: "Poire",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzE5",
                label: "Kaki",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzIw",
                label: "Burger boeuf chedar",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzIy",
                label: "Croûtons boulanger",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkw",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTY=",
                        label: "TABLE A CONDIMENT",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzI0",
                label: "Bleu en dés",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkw",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTY=",
                        label: "TABLE A CONDIMENT",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzI2",
                label: "Velouté de carotte au lait de coco",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzI3",
                label: "Terrine de poisson salade de mache aux fruits secs et crème acidulée",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzI4",
                label: "Rémoulade de céleri-rave tzatziki",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzI5",
                label: "Salade de mojette et lardons au vinaigre de cidre",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzMw",
                label: "Salade de mâche aux poire et roquefort",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzMx",
                label: "Tartine oeuf avocat",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
        ],
    },
    {
        day: "2022-02-14",
        elements: [
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTYx",
                label: "Tarte aux pommes crumble",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTYz",
                label: "Tartelette citron, confit pommes & rave, pavot",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTY0",
                label: "Pavé de boeuf champignons, grenailles",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjQx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTY1",
                label: "Cabillaud à l'ail & citronnelle, chop suey et noodles",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjQ0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTY3",
                label: "Pomme golden",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTY4",
                label: "Banane",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTY5",
                label: "Kiwi",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTcw",
                label: "Kaki ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTc0",
                label: "Tiramisu Savoiardi au mètre",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjY4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTc1",
                label: "Crème de poireaux & pomme de terre, pain au lard",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTc3",
                label: "Wrap grillé poulet césar",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTc4",
                label: "Houmous, champignons & salade d'herbes",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTgw",
                label: "Riz de chou-fleur, pesto rouge, pois chiches rôtis et fêta",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjE3Mg==",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTQ1",
                label: "Salade mêlée",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjg=",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTQ2",
                label: "Oeufs durs béarnaise à l'estragon",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjg=",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTQ3",
                label: "Jambon de paris",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjg=",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTU4",
                label: "Choucroute de la mer",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjE4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTcy",
                label: " crème dessert chocolat danette",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI1",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6OA==",
                        label: "PRODUITS LAITIERS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTcz",
                label: "Liégeois chocolat dessert",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI1",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6OA==",
                        label: "PRODUITS LAITIERS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTgw",
                label: "Fromage blanc ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTgx",
                label: "Fromage blanc o%",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTgy",
                label: "Compotée pommes cannelle",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTgz",
                label: "Semoule au lait",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTg0",
                label: "Creme de marron",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTg2",
                label: "Tarte flan chocolat ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTg3",
                label: "Île flottante au caramel et crème anglaise aux amandes effiles",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTg4",
                label: "Mille-feuille au citron",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTg5",
                label: "Smoothie orange ananas gingembre",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTkw",
                label: "Crème brûlée",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTkx",
                label: "Boeuf aux carottes confites et macaronis",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI5",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTky",
                label: "Carotte",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTkz",
                label: "Concombre",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTk0",
                label: "Chou fleur croquant aux zestes de citron",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTk1",
                label: "Salade feuille de chene",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTk2",
                label: "Haricots plats et oignons rouges",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTk3",
                label: "Semoule BIO complète",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTk4",
                label: "Salade de brocolis et pousses d'épinards façon caésar",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTk5",
                label: "Frites",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjM0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mw==",
                        label: "LEGUMES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjAw",
                label: "Filet de maquereau vinaigrette de coriandre",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjM1",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjAx",
                label: "Pintade braisée au chou vert",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjQx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjAy",
                label: "Noix de veau rôtie jus corsé au romarin",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjQx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjAz",
                label: "Filet de julienne vinaigrette de câpre et moutarde",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjQ0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjA0",
                label: "Steak haché",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjUx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjA1",
                label: "Filet de poulet grillé",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjUx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjA2",
                label: "Pavé de rumsteak grillé",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjUx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjA4",
                label: "Ananas frais",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjA5",
                label: "Orange",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjEw",
                label: "Lentilles ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjY0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mw==",
                        label: "LEGUMES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjEx",
                label: "Riz pilaf aux amandes et raisin",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjY0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mw==",
                        label: "LEGUMES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjEy",
                label: "Céleri braisé",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjY0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mw==",
                        label: "LEGUMES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjEz",
                label: "Petits pois aux oignons rouges",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjY0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mw==",
                        label: "LEGUMES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjE5",
                label: "Pomme granny smith",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjIw",
                label: "Banane",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjIx",
                label: "Kiwi",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjIy",
                label: "Orange",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjIz",
                label: "Poire",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjI0",
                label: "Clémentines",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjI1",
                label: "Kebab de volaille, sauce blanche, pommes de terre rôties ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjI2",
                label: "Tagliatelles carbonara",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjgy",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjI3",
                label: "Croûtons boulanger",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkw",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTY=",
                        label: "TABLE A CONDIMENT",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjI5",
                label: "Trévise en chiffonade",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkw",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTY=",
                        label: "TABLE A CONDIMENT",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjMx",
                label: "Soupe à l’oignon ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjMy",
                label: "Tartine parisienne: jambon, emmental ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjMz",
                label: "Salade gourmande acidulée",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjM0",
                label: "Poireaux mimosa et câpres",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjM1",
                label: "Rillettes de saumon, herbes et citron vert",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjM2",
                label: "Muffin des de brebis et raisins secs",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
        ],
    },
    {
        day: "2022-02-15",
        elements: [
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ2MDAz",
                label: "Saumon entier zaatar",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjQ0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ2MDA1",
                label: "Pomme golden",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ2MDA2",
                label: "Banane",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ2MDA3",
                label: "Kiwi",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ2MDA4",
                label: "Kaki ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ2MDEz",
                label: "Soupe de patates douces et épices indiennes, orange & amandes",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ2MDE1",
                label: "Crémeux carotte, riz & beluga, salade de copeaux",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ2MDE3",
                label: "Houmous betterave, chèvre",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ2MDE4",
                label: "Sarrasin grillé et lentilles, falafel légumes & Labneh",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjE3Mg==",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjQw",
                label: "Salade mêlée",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjg=",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjQx",
                label: "Oeufs durs à la parisienne",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjg=",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjQy",
                label: "Jambon de paris",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjg=",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjUz",
                label: "Poulet au citron en tajine et boulgour aux fèves et petits pois ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjE4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjY3",
                label: " crème dessert chocolat danette",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI1",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6OA==",
                        label: "PRODUITS LAITIERS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjY4",
                label: "Liégeois chocolat dessert",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI1",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6OA==",
                        label: "PRODUITS LAITIERS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjc1",
                label: "Fromage blanc ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjc2",
                label: "Fromage blanc o%",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjc3",
                label: "Compote pommes poires",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjc4",
                label: "Riz au lait",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjc5",
                label: "Coulis de framboise",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjgx",
                label: "Paris-brest",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjgy",
                label: "Gateau moelleux au chocolat blanc",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjgz",
                label: "Muffin poire et chocolat",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjg0",
                label: "Ananas caramel, crème vanillée ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjg1",
                label: "Flamri au chocolat",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjg2",
                label: "Gnocchi sauce tomate piquante et roquette",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI5",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjg3",
                label: "Carotte",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjg4",
                label: "Concombre",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjg5",
                label: "Champignon blanc",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjkw",
                label: "Salade batavia",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjkx",
                label: "Salad bar bolly tandoori de choux fleurs frais",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjky",
                label: "Salade de pois chiches à la coriandre et cumin",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjkz",
                label: "Salade de patates douces et pousses d'épinards",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjk0",
                label: "Frites",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjM0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mw==",
                        label: "LEGUMES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjk1",
                label: "Pavé de thon sauce chien",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjM1",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjk2",
                label: "Filet mignon de porc rôti pesto de cerfeuil aux noix et tomates séchées",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjQx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjk3",
                label: "Cuisse de poulet sauce aigre-douce",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjQx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjk4",
                label: "Filet de sandre oignon confit  graine de moutarde",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjQ0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjk5",
                label: "Steak haché",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjUx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzAw",
                label: "Filet de poulet grillé",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjUx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzAx",
                label: "Pavé de rumsteak grillé",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjUx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzAz",
                label: "Ananas frais",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzA0",
                label: "Clémentine",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzA1",
                label: "Macaronis",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjY0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mw==",
                        label: "LEGUMES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzA2",
                label: "Semoule à la coriandre",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjY0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mw==",
                        label: "LEGUMES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzA3",
                label: "Endives braisées à l’orange et coriandre",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjY0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mw==",
                        label: "LEGUMES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzA4",
                label: "Flan d'épinards",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjY0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mw==",
                        label: "LEGUMES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzE0",
                label: "Pomme golden",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzE1",
                label: "Banane",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzE2",
                label: "Kiwi",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzE3",
                label: "Orange",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzE4",
                label: "Poire",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzE5",
                label: "Kaki",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzIw",
                label: "Burger boeuf chedar",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzIy",
                label: "Croûtons boulanger",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkw",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTY=",
                        label: "TABLE A CONDIMENT",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzI0",
                label: "Bleu en dés",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkw",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTY=",
                        label: "TABLE A CONDIMENT",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzI2",
                label: "Velouté de carotte au lait de coco",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzI3",
                label: "Terrine de poisson salade de mache aux fruits secs et crème acidulée",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzI4",
                label: "Rémoulade de céleri-rave tzatziki",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzI5",
                label: "Salade de mojette et lardons au vinaigre de cidre",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzMw",
                label: "Salade de mâche aux poire et roquefort",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzMx",
                label: "Tartine oeuf avocat",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
        ],
    },
    {
        day: "2022-02-16",
        elements: [
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTYx",
                label: "Tarte aux pommes crumble",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTYz",
                label: "Tartelette citron, confit pommes & rave, pavot",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTY0",
                label: "Pavé de boeuf champignons, grenailles",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjQx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTY1",
                label: "Cabillaud à l'ail & citronnelle, chop suey et noodles",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjQ0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTY3",
                label: "Pomme golden",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTY4",
                label: "Banane",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTY5",
                label: "Kiwi",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTcw",
                label: "Kaki ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTc0",
                label: "Tiramisu Savoiardi au mètre",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjY4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTc1",
                label: "Crème de poireaux & pomme de terre, pain au lard",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTc3",
                label: "Wrap grillé poulet césar",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTc4",
                label: "Houmous, champignons & salade d'herbes",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ1OTgw",
                label: "Riz de chou-fleur, pesto rouge, pois chiches rôtis et fêta",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjE3Mg==",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTQ1",
                label: "Salade mêlée",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjg=",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTQ2",
                label: "Oeufs durs béarnaise à l'estragon",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjg=",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTQ3",
                label: "Jambon de paris",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjg=",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTU4",
                label: "Choucroute de la mer",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjE4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTcy",
                label: " crème dessert chocolat danette",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI1",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6OA==",
                        label: "PRODUITS LAITIERS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTcz",
                label: "Liégeois chocolat dessert",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI1",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6OA==",
                        label: "PRODUITS LAITIERS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTgw",
                label: "Fromage blanc ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTgx",
                label: "Fromage blanc o%",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTgy",
                label: "Compotée pommes cannelle",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTgz",
                label: "Semoule au lait",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTg0",
                label: "Creme de marron",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTg2",
                label: "Tarte flan chocolat ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTg3",
                label: "Île flottante au caramel et crème anglaise aux amandes effiles",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTg4",
                label: "Mille-feuille au citron",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTg5",
                label: "Smoothie orange ananas gingembre",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTkw",
                label: "Crème brûlée",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTkx",
                label: "Boeuf aux carottes confites et macaronis",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI5",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTky",
                label: "Carotte",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTkz",
                label: "Concombre",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTk0",
                label: "Chou fleur croquant aux zestes de citron",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTk1",
                label: "Salade feuille de chene",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTk2",
                label: "Haricots plats et oignons rouges",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTk3",
                label: "Semoule BIO complète",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTk4",
                label: "Salade de brocolis et pousses d'épinards façon caésar",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNTk5",
                label: "Frites",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjM0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mw==",
                        label: "LEGUMES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjAw",
                label: "Filet de maquereau vinaigrette de coriandre",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjM1",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjAx",
                label: "Pintade braisée au chou vert",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjQx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjAy",
                label: "Noix de veau rôtie jus corsé au romarin",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjQx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjAz",
                label: "Filet de julienne vinaigrette de câpre et moutarde",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjQ0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjA0",
                label: "Steak haché",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjUx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjA1",
                label: "Filet de poulet grillé",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjUx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjA2",
                label: "Pavé de rumsteak grillé",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjUx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjA4",
                label: "Ananas frais",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjA5",
                label: "Orange",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjEw",
                label: "Lentilles ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjY0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mw==",
                        label: "LEGUMES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjEx",
                label: "Riz pilaf aux amandes et raisin",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjY0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mw==",
                        label: "LEGUMES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjEy",
                label: "Céleri braisé",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjY0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mw==",
                        label: "LEGUMES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjEz",
                label: "Petits pois aux oignons rouges",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjY0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mw==",
                        label: "LEGUMES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjE5",
                label: "Pomme granny smith",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjIw",
                label: "Banane",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjIx",
                label: "Kiwi",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjIy",
                label: "Orange",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjIz",
                label: "Poire",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjI0",
                label: "Clémentines",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjI1",
                label: "Kebab de volaille, sauce blanche, pommes de terre rôties ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjI2",
                label: "Tagliatelles carbonara",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjgy",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjI3",
                label: "Croûtons boulanger",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkw",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTY=",
                        label: "TABLE A CONDIMENT",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjI5",
                label: "Trévise en chiffonade",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkw",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTY=",
                        label: "TABLE A CONDIMENT",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjMx",
                label: "Soupe à l’oignon ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjMy",
                label: "Tartine parisienne: jambon, emmental ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjMz",
                label: "Salade gourmande acidulée",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjM0",
                label: "Poireaux mimosa et câpres",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjM1",
                label: "Rillettes de saumon, herbes et citron vert",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjM2",
                label: "Muffin des de brebis et raisins secs",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
        ],
    },
    {
        day: "2022-02-17",
        elements: [
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ2MDAz",
                label: "Saumon entier zaatar",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjQ0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ2MDA1",
                label: "Pomme golden",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ2MDA2",
                label: "Banane",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ2MDA3",
                label: "Kiwi",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ2MDA4",
                label: "Kaki ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ2MDEz",
                label: "Soupe de patates douces et épices indiennes, orange & amandes",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ2MDE1",
                label: "Crémeux carotte, riz & beluga, salade de copeaux",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ2MDE3",
                label: "Houmous betterave, chèvre",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEyODQ2MDE4",
                label: "Sarrasin grillé et lentilles, falafel légumes & Labneh",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjE3Mg==",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjQw",
                label: "Salade mêlée",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjg=",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjQx",
                label: "Oeufs durs à la parisienne",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjg=",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjQy",
                label: "Jambon de paris",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjg=",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjUz",
                label: "Poulet au citron en tajine et boulgour aux fèves et petits pois ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjE4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjY3",
                label: " crème dessert chocolat danette",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI1",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6OA==",
                        label: "PRODUITS LAITIERS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjY4",
                label: "Liégeois chocolat dessert",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI1",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6OA==",
                        label: "PRODUITS LAITIERS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjc1",
                label: "Fromage blanc ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjc2",
                label: "Fromage blanc o%",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjc3",
                label: "Compote pommes poires",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjc4",
                label: "Riz au lait",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjc5",
                label: "Coulis de framboise",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjgx",
                label: "Paris-brest",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjgy",
                label: "Gateau moelleux au chocolat blanc",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjgz",
                label: "Muffin poire et chocolat",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjg0",
                label: "Ananas caramel, crème vanillée ",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjg1",
                label: "Flamri au chocolat",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Nw==",
                        label: "DESSERTS ",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjg2",
                label: "Gnocchi sauce tomate piquante et roquette",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjI5",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjg3",
                label: "Carotte",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjg4",
                label: "Concombre",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjg5",
                label: "Champignon blanc",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjkw",
                label: "Salade batavia",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjkx",
                label: "Salad bar bolly tandoori de choux fleurs frais",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjky",
                label: "Salade de pois chiches à la coriandre et cumin",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjkz",
                label: "Salade de patates douces et pousses d'épinards",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjMz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NQ==",
                        label: "SALAD BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjk0",
                label: "Frites",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjM0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mw==",
                        label: "LEGUMES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjk1",
                label: "Pavé de thon sauce chien",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjM1",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjk2",
                label: "Filet mignon de porc rôti pesto de cerfeuil aux noix et tomates séchées",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjQx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjk3",
                label: "Cuisse de poulet sauce aigre-douce",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjQx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjk4",
                label: "Filet de sandre oignon confit  graine de moutarde",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjQ0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNjk5",
                label: "Steak haché",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjUx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzAw",
                label: "Filet de poulet grillé",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjUx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzAx",
                label: "Pavé de rumsteak grillé",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjUx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzAz",
                label: "Ananas frais",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzA0",
                label: "Clémentine",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjYx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Ng==",
                        label: "DESSERTS BAR AU KILOS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzA1",
                label: "Macaronis",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjY0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mw==",
                        label: "LEGUMES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzA2",
                label: "Semoule à la coriandre",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjY0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mw==",
                        label: "LEGUMES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzA3",
                label: "Endives braisées à l’orange et coriandre",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjY0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mw==",
                        label: "LEGUMES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzA4",
                label: "Flan d'épinards",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjY0",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mw==",
                        label: "LEGUMES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzE0",
                label: "Pomme golden",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzE1",
                label: "Banane",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzE2",
                label: "Kiwi",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzE3",
                label: "Orange",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzE4",
                label: "Poire",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzE5",
                label: "Kaki",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc3",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTE=",
                        label: "FRUITS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzIw",
                label: "Burger boeuf chedar",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjc4",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6Mg==",
                        label: "PLATS CHAUDS",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzIy",
                label: "Croûtons boulanger",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkw",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTY=",
                        label: "TABLE A CONDIMENT",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzI0",
                label: "Bleu en dés",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkw",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6MTY=",
                        label: "TABLE A CONDIMENT",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzI2",
                label: "Velouté de carotte au lait de coco",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkx",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzI3",
                label: "Terrine de poisson salade de mache aux fruits secs et crème acidulée",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzI4",
                label: "Rémoulade de céleri-rave tzatziki",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzI5",
                label: "Salade de mojette et lardons au vinaigre de cidre",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzMw",
                label: "Salade de mâche aux poire et roquefort",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
            {
                id: "TWVudUVsZW1lbnQ6NDEzNDAxNzMx",
                label: "Tartine oeuf avocat",
                price: {
                    amount: 0,
                    currency: "EUR",
                },
                dish: {
                    id: "TWVudUVsZW1lbnREaXNoOjkz",
                    dishGroup: {
                        id: "TWVudUVsZW1lbnREaXNoR3JvdXA6NA==",
                        label: "HORS D'OEUVRES",
                    },
                },
            },
        ],
    },
];
