import styled from "styled-components/native";

export const Container = styled.View`
    flex-direction: column;
    justify-content: flex-start;
`;

export const ListContainer = styled.View`
    margin-top: 5px;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
`;

export const Link = styled.View`
    flex-direction: row;
`;