import { Colors } from "@constants";
import { Title13 } from "@stylesheets";
import style from "styled-components/native";

export const Container = style.View`
    margin-bottom: 20px;
    flex-direction: row;
`;

export const Text = style(Title13)<{
  warning?: boolean;
  underline?: boolean;
  isMobile?: boolean;
}>`
    width: ${({ isMobile }) => (isMobile ? "700px" : "217px")};
    color: ${({ warning }) => (warning ? Colors.redError : Colors.foodiBlack)};
    text-decoration-line: ${({ underline }) =>
      underline ? "underline" : "none"};
    flex-wrap: wrap;
`;
