import React, { useMemo } from "react";
import { StyleSheet, View, ViewStyle } from "react-native";
import { TransactionTab } from "@atomic";
import { TestIDs } from "@utils";
import { useDevices } from "@hooks";
import { Spacing } from "@constants";

interface IProps {
  onTabSelected: Function;
  tabs: any[];
  style?: ViewStyle;
  styleContainer?: ViewStyle;
  isHistorical?: boolean;
}

export const TransactionTabs: React.FC<IProps> = React.memo(
  ({ onTabSelected, tabs, style, styleContainer, isHistorical = false }) => {
    const [isMobile] = useDevices();
    const styles = useMemo(() => _styles(isMobile, isHistorical), [
      isMobile,
      isHistorical,
    ]);

    return (
      <View style={[styles.container, style]}>
        {tabs.map(({ title, isSelected, icon }, index) => {
          return (
            <TransactionTab
              key={index}
              tabIndex={index}
              forwardTestID={`${TestIDs.home.balance.actions.transactionTab}${index}`}
              title={title}
              isSelected={isSelected}
              onPress={onTabSelected}
              icon={icon}
              styleContainer={
                index !== tabs.length - 1 ? styleContainer : undefined
              }
            />
          );
        })}
      </View>
    );
  }
);

const _styles = (isMobile: boolean, isHistorical: boolean) =>
  StyleSheet.create({
    container: {
      width: "100%",
      paddingHorizontal: isMobile ? Spacing.M : 0,
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: isMobile && !isHistorical ? "space-between" : "center",
    },
  });
