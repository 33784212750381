import React from "react";
import { ViewStyle } from "react-native";
import { useDevices } from "@hooks";
import {
  TitleFirstLine,
  TitleSecondLine,
  Container,
} from "./WelcomingSentence.style";

interface IProps {
  firstLabel: string;
  secondLabel?: string;
  fallbackLabel?: string;
  style?: ViewStyle;
  forwardTestID?: string;
  children?: React.ReactNode;
  isLogged?: boolean;
  userName?: string;
}

export const WelcomingSentence: React.FC<IProps> = ({
  style,
  forwardTestID,
  firstLabel,
  secondLabel,
  fallbackLabel,
  isLogged,
  userName,
}) => {
  const [isMobile] = useDevices();

  return (
    <Container isLogged={isLogged} testID={forwardTestID}>
      {isLogged && userName ? (
        <>
          <TitleFirstLine
            isMobile={isMobile}
          >{`${userName}, ${firstLabel}.`}</TitleFirstLine>
          <TitleSecondLine isMobile={isMobile} style={[style]}>
            {secondLabel}
          </TitleSecondLine>
        </>
      ) : (
        <TitleSecondLine isMobile={isMobile}>{fallbackLabel}</TitleSecondLine>
      )}
    </Container>
  );
};
