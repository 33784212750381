import React from "react";
import { I18n } from "react-redux-i18n";

interface IProps {
  size?: string;
}

export const Sucess: React.FC<IProps> = ({ size = '22px'}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 22 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>{I18n.t("hoverIcons.success")}</title>
    <g
      id="Atom/Alerts/Sucess"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <circle id="Oval" fill="#509E50" cx="11" cy="11" r="11"></circle>
      <polygon
        id="Path"
        fill="#FFFFFF"
        fill-rule="nonzero"
        points="15.59 6 9 12.59 6.41 10.01 5 11.42 9 15.42 17 7.42"
      ></polygon>
    </g>
  </svg>
);
