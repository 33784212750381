import { IProductTransparencyPreferences } from "@foodi/core";
import { createReducer } from "@redux";
import { Action } from "@redux/action";
import _ from "lodash";

export interface HoldingSettingState {
  productTransparencyPreferences: IProductTransparencyPreferences | null;
  offersProductTransparencyPreferences: IProductTransparencyPreferences | null;
}

/*************  Actions  ****************/

const ActionTypes = {
  SET_PRODUCT_TRANSPARENCY_PREFERENCES: "HoldingSetting/SET_PRODUCT_TRANSPARENCY_PREFERENCES",
  SET_OFFERS_PRODUCT_TRANSPARENCY_PREFERENCES: "HoldingSetting/SET_OFFERS_PRODUCT_TRANSPARENCY_PREFERENCES",
};

const ActionCreators = {
  setProductTransparencyPreferences: (productTransparencyPreferences: IProductTransparencyPreferences | null): Action<IProductTransparencyPreferences | null> => ({
    type: ActionTypes.SET_PRODUCT_TRANSPARENCY_PREFERENCES,
    payload: productTransparencyPreferences,
  }),
  setOffersProductTransparencyPreferences: (productTransparencyPreferences: IProductTransparencyPreferences | null): Action<IProductTransparencyPreferences | null> => ({
    type: ActionTypes.SET_OFFERS_PRODUCT_TRANSPARENCY_PREFERENCES,
    payload: productTransparencyPreferences,
  }),
};

/*************  Reducer  ****************/

const initialState: HoldingSettingState = {
  productTransparencyPreferences: null,
  offersProductTransparencyPreferences: null,
};

const Reduction = {
  setProductTransparencyPreferences: (
    state: HoldingSettingState,
    { payload: productTransparencyPreferences }: Action<IProductTransparencyPreferences>
  ): HoldingSettingState => ({
    ...state,
    productTransparencyPreferences,
  }),
  setOffersProductTransparencyPreferences: (
    state: HoldingSettingState,
    { payload: offersProductTransparencyPreferences }: Action<IProductTransparencyPreferences>
  ): HoldingSettingState => ({
    ...state,
    offersProductTransparencyPreferences,
  }),
};

const reducer = createReducer(initialState, {
  [ActionTypes.SET_PRODUCT_TRANSPARENCY_PREFERENCES]: Reduction.setProductTransparencyPreferences,
  [ActionTypes.SET_OFFERS_PRODUCT_TRANSPARENCY_PREFERENCES]: Reduction.setOffersProductTransparencyPreferences,
});

export default reducer;

export {
  reducer as HoldingSettingReducer,
  ActionTypes as HoldingSettingTypes,
  ActionCreators as HoldingSettingActions,
};
