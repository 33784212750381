import React from "react";
import { Pressable, StyleSheet, View, Image } from "react-native";
import { TestIDs } from "@utils";
import { GlobalState } from "@redux";
import { useSelector } from "react-redux";
import { NavigationProp } from "@react-navigation/native";
import { Title22, Title36 } from "@stylesheets";
import { Colors } from "@constants";
import {
  AppStoreEN,
  AppStoreFR,
  GooglePlayEN,
  GooglePlayFR,
  Images,
} from "@assets";
import { I18n } from "react-redux-i18n";

interface IProps {
  navigation?: NavigationProp<any>;
}

export const GetApp: React.FC<IProps> = React.memo(({ navigation }) => {
  const userLanguage = useSelector(
    (state: GlobalState) => state.auth?.userInfo?.language || state.i18n?.locale
  );

  const GoogleStore = userLanguage === "fr" ? GooglePlayFR : GooglePlayEN;
  const AppStore = userLanguage === "fr" ? AppStoreFR : AppStoreEN;

  const handleAppleStore = () => {
    window
      .open(
        "https://apps.apple.com/fr/app/foodi/id1377579624?l=fr&ls=1",
        "_blank"
      )
      ?.focus();
  };

  const handleAndroidStore = () => {
    window
      .open(
        "https://play.google.com/store/apps/details?id=fr.compassgroup.foodi",
        "_blank"
      )
      ?.focus();
  };
  return (
    <View testID={TestIDs.home.gepApp.views.container} style={styles.container}>
      <View style={styles.leftSide}>
        <Image style={styles.image} source={{ uri: Images.foodiAppScreens }} />
      </View>
      <View style={styles.rightSide}>
        <Title36 color={Colors.white} textAlign="left">
          {I18n.t("homepage.getApp.title")}
        </Title36>
        <Title22 textAlign="left" style={styles.subTitle}>
          {I18n.t("homepage.getApp.subTitle")}
        </Title22>
        <View style={styles.iconContainer}>
          <Pressable
            testID={TestIDs.home.gepApp.actions.goToAppStore}
            onPress={handleAppleStore}
          >
            <AppStore />
          </Pressable>
          <Pressable
            testID={TestIDs.home.gepApp.actions.goToGoogleStore}
            onPress={handleAndroidStore}
          >
            <GoogleStore />
          </Pressable>
        </View>
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    width: "100%",
    minHeight: 353,
    backgroundColor: Colors.black,
    flexDirection: "row",
  },
  leftSide: {
    width: "55%",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  rightSide: {
    width: "35%",
    textAlign: "left",
    paddingTop: 48,
  },
  iconContainer: {
    flexDirection: "row",
    marginTop: 20,
    width: 260,
    justifyContent: "space-between",
  },
  subTitle: {
    fontFamily: "manrope-regular",
    marginTop: 16,
  },
  image: {
    width: 705,
    height: 331,
    resizeMode: "contain",
  },
});
