"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_ONE_POS = void 0;
const client_1 = require("@apollo/client");
exports.GET_ONE_POS = client_1.gql `
  query getOnePos($id: ID!) {
    getOnePos(id: $id) {
      id
      concept {
        name
        image
      }
      type
      name
      status @client
      schedules {
        days
        hours
      }
      zone {
        id
        name
        holding {
          id
          importationType
        }
      }
      externalServices {
        title
        content
        active
      }
      content
      description
      image
      hideMenuBooking
    }
  }
`;
