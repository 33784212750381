import { Text, View, StyleSheet, Image } from "react-native";
import _ from "lodash";
import React from "react";
import { Images } from "@assets";
import { I18n } from "react-redux-i18n";
import { Colors } from "@constants";

export const NoPointageAccount: React.FC = React.memo(() => {
  return (
    <View style={styles.mainContainer}>
      <View style={styles.titleContainer}>
        <Text style={styles.title}>
          {I18n.t("epointage.homepage.pointageAccountNotCreated")}
        </Text>
      </View>
      <View style={styles.descriptionContainer}>
        <Text style={styles.message}>
          {I18n.t("epointage.homepage.pointageAccountNotCreatedInfo")}
        </Text>
        <View style={styles.imageContainer}>
          <Image
            style={styles.image}
            source={{
              uri: Images.NoPointageAccount,
            }}
            resizeMode="contain"
          />
        </View>
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    paddingHorizontal: 150,
    paddingVertical: 100,
  },
  titleContainer: {
    alignItems: "flex-start",
  },
  descriptionContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  imageContainer: {
    width: 400,
    height: 434,
    marginLeft: 33,
    marginTop: 30,
  },
  image: {
    alignSelf: "center",
    width: "100%",
    height: "100%",
  },
  title: {
    fontSize: 30,
    color: Colors.foodiBlack,
    fontFamily: "manrope-bold",
    lineHeight: 60,
    marginBottom: 10,
  },
  message: {
    fontSize: 22,
    color: Colors.foodiBlack,
    fontFamily: "manrope",
    lineHeight: 30,
  },
});
