import { Colors } from "@constants";
import style from "styled-components/native";

export const Container = style.View`
  padding-bottom: 13px;
  padding-top: 9px;
`

export const WarningMessageContainer = style.View`
    flex-direction: row;
    margin-top: 9px;
`;

export const WarningMessage = style.Text`
    color: ${Colors.foodiBlack};
    font-family: manrope;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 18px;
    margin-left: 6px;
    max-width: 195px;
`;
