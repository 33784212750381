import { Colors } from "@constants";
import style from "styled-components/native";

export const Container = style.View<{ isMobile?: boolean }>`
    padding: ${({ isMobile }) => (isMobile ? "20px 18px" : "45px 150px")};
    background-color: white;
    height: 100%;
`;

export const GoBackText = style.Text<{ isMobile?: boolean }>`
    color: ${Colors.foodiBlack};
    font-family: manrope-bold;
    font-size: ${({ isMobile }) => (isMobile ? 16 : 22)}px;
    letter-spacing: 0;
    line-height: 30px;
    margin-left: 7px;
`;