"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetEdenredAuthorizationURL = void 0;
class GetEdenredAuthorizationURL {
    constructor(paymentsService) {
        this.paymentsService = paymentsService;
    }
    execute(request) {
        return this.paymentsService.getEdenredAuthorizationURL(request);
    }
}
exports.GetEdenredAuthorizationURL = GetEdenredAuthorizationURL;
