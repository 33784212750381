import React, { useMemo, useRef, useState, useLayoutEffect } from "react";
import { StyleSheet, Pressable, View, Animated } from "react-native";
import { Title16, Title18, Title22, Title36 } from "@stylesheets";
import { Colors, Spacing } from "@constants";
import { I18n } from "react-redux-i18n";
import { useDevices } from "@hooks";
import { OfferTemplateWithdrawalType } from "@foodi/core";
import { getLabelFromWithdrawalType } from "@utils";
import { Icon } from "@atomic";

interface IProps {
  offerName: string;
  offerDescription: string;
  offerType: string | null;
}

const DURATION = 300;
const SHOWMORE_LENGHT_TRIGGER = 114;
const MOBILE_SHOWMORE_LENGHT_TRIGGER = 80;

export const OfferDescription: React.FC<IProps> = React.memo(
  ({ offerName, offerDescription, offerType }) => {
    const [isMobile] = useDevices();
    const ref = useRef(null);

    const [isDescriptionOpen, setIsDescriptionOpen] = useState(false);
    const [maxHeight, setMaxHeight] = useState(0);
    const containerHeight = useRef(new Animated.Value(0)).current;

    const descriptionContainerHeight = containerHeight.interpolate({
      inputRange: [0, 1],
      outputRange: ["50px", `${maxHeight}px`],
    });
    const triggerShowMore = isMobile
      ? MOBILE_SHOWMORE_LENGHT_TRIGGER
      : SHOWMORE_LENGHT_TRIGGER;

    const styles = useMemo(() => _styles(isMobile), [isMobile]);

    const OfferTitle: React.FC<any> = isMobile ? Title22 : Title36;
    const OfferDescription: React.FC<any> = isMobile ? Title16 : Title18;

    const arrowIcon = isDescriptionOpen
      ? "Accordeon_arrow_open_white"
      : "Accordeon_arrow_default_white";

    const actionlabel = isDescriptionOpen
      ? I18n.t("common.seeLess")
      : I18n.t("common.seeMore");

    const handleShowMore = () => {
      setIsDescriptionOpen(!isDescriptionOpen);
      handleAnimation(isDescriptionOpen ? 0 : 1);
    };

    const fadetextValueTrigger = isMobile
      ? MOBILE_SHOWMORE_LENGHT_TRIGGER
      : SHOWMORE_LENGHT_TRIGGER;
    const triggerFade =
      offerDescription.length >= fadetextValueTrigger ? 50 : 100;
    const fadeText = isDescriptionOpen ? 100 : triggerFade;

    const handleAnimation = (toValue: number) => {
      Animated.timing(containerHeight, {
        toValue: toValue,
        duration: DURATION,
        useNativeDriver: false,
      }).start();
    };

    useLayoutEffect(() => {
      // @ts-ignore
      setMaxHeight(ref.current.offsetHeight);
    }, [offerDescription]);

    return (
      <>
        <OfferTitle isBlack style={styles.offerTitle}>
          {offerName}
        </OfferTitle>
        <Animated.View
          style={[
            styles.descriptionContainer,
            {
              height: descriptionContainerHeight,
              // @ts-ignore
              maskImage: `linear-gradient(black ${fadeText}%, transparent)`,
            },
          ]}
        >
          <OfferDescription isBold bold ref={ref}>
            {I18n.t(
              `restaurantDetail.tagTypeLegend.${getLabelFromWithdrawalType(
                offerType as OfferTemplateWithdrawalType
              )}`
            )}
            {offerDescription && " - "}
            <OfferDescription>{offerDescription}</OfferDescription>
          </OfferDescription>
        </Animated.View>
        {offerDescription.length > triggerShowMore && (
          <View style={styles.seeMoreContainer}>
            <OfferDescription isBold bold>
              {actionlabel}
            </OfferDescription>
            <Pressable style={styles.arrowContainer} onPress={handleShowMore}>
              <Icon name={arrowIcon} color={Colors.foodiBlack} size={11} />
            </Pressable>
          </View>
        )}
      </>
    );
  }
);

const _styles = (isMobile: boolean) =>
  StyleSheet.create({
    descriptionContainer: {
      overflow: "hidden",
      paddingLeft: isMobile ? 18 : 0,
    },
    seeMoreContainer: {
      flexDirection: "row",
      alignItems: "center",
      paddingLeft: isMobile ? 18 : 0,
    },
    offerTitle: {
      marginTop: Spacing.M,
      textAlign: "left",
      paddingLeft: isMobile ? 18 : 0,
      width: "100vw",
    },
    arrowContainer: {
      marginLeft: 10,
    },
  });
