import React, { useEffect, useMemo, useRef, useState } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { BookingOffer, LeftArrow, RightArrow } from "@atomic";
import { TestIDs } from "@utils";
import { useDevices } from "@hooks";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "@redux";
import { OfferViewModel } from "@modules";

interface IProps {
  items: any[];
  itemWidth: number;
  onSelectItem?: Function;
  renderItems: (
    elements: any[],
    onSelectedItem: (selectedElementIndex: number) => void
  ) => JSX.Element[];
  width: number;
  height: number;
  hasBorder?: boolean;
  hasArrowsForShow?: boolean;
  showRestaurantMenu?: boolean;
  offersDisplayed?: number;
  biggerArrows?: boolean;
}

export const Carousel: React.FC<IProps> = 
  ({
    items = [],
    onSelectItem,
    itemWidth = 325,
    renderItems,
    width,
    height,
    hasBorder,
    hasArrowsForShow,
    showRestaurantMenu,
    offersDisplayed,
    biggerArrows,
  }) => {
    const [isMobile] = useDevices();
    const dispatch = useDispatch();
    const styles = useMemo(
      () => _styles(isMobile, width, height, hasBorder, hasArrowsForShow),
      [isMobile, width, height, hasBorder, hasArrowsForShow]
    );
    const carouselRef = useRef(null);
    const xOffsetRed = useRef(0);
    const carouselIndex = useRef(0);
    const [elements, setElements] = useState<any[]>([]);
    const scrollItems = items?.length - (offersDisplayed ?? 0);
    const itemsWidth = scrollItems * itemWidth;
    const [showLeftArrow, setShowLeftArrow] = useState<boolean>(false);
    const [showRightArrow, setShowRightArrow] = useState<boolean>(false);

    const [offerVM] = useState(new OfferViewModel(dispatch));

    const isBookingSelected = useSelector(
      (state: GlobalState) => state?.booking?.isBookingSelected
    );

    useEffect(() => {
      const _elements =
        items?.map((item, index) => {
          return {
            ...item,
            isSelected: index === 0,
          };
        }) || [];

      setElements(_elements);
      resetCarousel();
    }, [items, offersDisplayed]);

    const onSelectedItem = (selectedElementIndex: number) => {
      if (onSelectItem) {
        const _elements = elements.map((item, index) => {
          return {
            ...item,
            isSelected: index === selectedElementIndex,
          };
        });
        setElements(_elements);
        onSelectItem(_elements[selectedElementIndex]);
      }
    };

    const resetCarousel = () => {
      carouselIndex.current = 0;
      xOffsetRed.current = 0;
      onIndexChanged();
      scrollToXOffset(true);
    };

    const goToPrev = () => {
      if (xOffsetRed.current === 0 || hasArrowsForShow) return;
      xOffsetRed.current = xOffsetRed.current - itemWidth;
      scrollToXOffset();
    };

    const goToNext = () => {
      if (hasArrowsForShow) return;
      if (xOffsetRed.current === 0 && showRestaurantMenu) {
        xOffsetRed.current =
          xOffsetRed.current + offerVM.BOOKING_CARD_WIDTH_WITHGAP;
      } else {
        xOffsetRed.current = xOffsetRed.current + itemWidth;
      }
      scrollToXOffset();
    };

    const scrollToXOffset = (noUpdate?: boolean) => {
      //@ts-ignore
      carouselRef?.current?.scrollTo({
        x: xOffsetRed.current,
        animated: true,
      });
      noUpdate ?? updateCarouselIndex();
    };

    const updateCarouselIndex = () => {
      carouselIndex.current = Math.round(xOffsetRed.current / itemWidth);
      onIndexChanged();
    };

    const onIndexChanged = () => {
      setShowLeftArrow(carouselIndex.current !== 0);
      setShowRightArrow(
        carouselIndex.current !==
          Math.max(0, Math.round(scrollItems)) +
            (showRestaurantMenu && items?.length > 2 ? 1 : 0)
      );
    };

    return (
      <View style={styles.container}>
        <ScrollView
          ref={carouselRef}
          horizontal
          contentContainerStyle={styles.contentContainerStyle}
          scrollEnabled={isMobile}
          showsHorizontalScrollIndicator={false}
        >
          {showRestaurantMenu && (
            <BookingOffer
              isSelected={isBookingSelected}
              forwardTestID={TestIDs.restaurantDetail.actions.selectMenuBooking}
            />
          )}
          {renderItems(elements, onSelectedItem)}
          {isMobile && <View style={{ width: 250 }}></View>}
        </ScrollView>
        {showLeftArrow && !isMobile && (
          <LeftArrow
            forwardTestID={TestIDs.restaurantDetail.actions.carouselLeftArrow}
            size={biggerArrows ? 40 : undefined}
            style={styles.leftArrow}
            onPress={goToPrev}
          />
        )}
        {showRightArrow && (!isMobile || hasArrowsForShow) && (
          <RightArrow
            forwardTestID={TestIDs.restaurantDetail.actions.carouselRightArrow}
            size={biggerArrows ? 40 : undefined}
            style={styles.rightArrow}
            onPress={goToNext}
          />
        )}
      </View>
    );
  };

const _styles = (
  isMobile: boolean,
  width: number,
  height: number,
  hasBorder?: boolean,
  hasArrowsForShow?: boolean
) =>
  StyleSheet.create({
    container: {
      width,
      justifyContent: "center",
    },
    contentContainerStyle: {
      height,
      width,
    },
    leftArrow: {
      position: "absolute",
      left: hasBorder ? -5 : -15,
    },
    rightArrow: {
      position: "absolute",
      right:
        isMobile && hasArrowsForShow
          ? 10
          : isMobile
          ? 255
          : hasBorder
          ? 0
          : -10,
    },
  });
