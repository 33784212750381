"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FUserRepository = void 0;
const __1 = require("../../..");
const domain_1 = require("../../../domain");
const mappers_1 = require("../mappers");
class FUserRepository {
    constructor(apolloClient) {
        this.apolloClient = apolloClient;
    }
    getUserProfile(callback) {
        return __awaiter(this, void 0, void 0, function* () {
            const userProfileMapper = new mappers_1.UserProfileMapper();
            try {
                const response = yield this.apolloClient.query({
                    query: __1.GET_USER_PROFILE,
                });
                if (callback)
                    callback();
                return Promise.resolve(userProfileMapper.toDomain(response));
            }
            catch (error) {
                return Promise.reject(new domain_1.UserRepositoryErrors.GetUserProfileRequestError("GET_USER_PROFILE_REQUEST_FAILED", error));
            }
        });
    }
    getI18nProviderLocale(callback) {
        return __awaiter(this, void 0, void 0, function* () {
            const i18nProviderLocaleMapper = new mappers_1.I18nProviderLocaleMapper();
            try {
                const response = yield this.apolloClient.query({
                    query: __1.GET_I18N_PROVIDER_LOCALE,
                });
                if (callback)
                    callback();
                return Promise.resolve(i18nProviderLocaleMapper.toDomain(response));
            }
            catch (error) {
                return Promise.reject(new domain_1.UserRepositoryErrors.GetI18nProviderLocaleRequestError("GET_I18N_PROVIDER_LOCALE_REQUEST_FAILED", error));
            }
        });
    }
    getTransactions(variables, callback) {
        return __awaiter(this, void 0, void 0, function* () {
            const transactionsMapper = new mappers_1.TransactionsMapper();
            try {
                const response = yield this.apolloClient.query({
                    query: __1.GET_TRANSACTIONS,
                    variables,
                });
                if (callback)
                    callback();
                return Promise.resolve(transactionsMapper.toDomain(response));
            }
            catch (error) {
                return Promise.reject(new domain_1.UserRepositoryErrors.GetTransactionsRequestError("GET_TRANSACTIONS_REQUEST_FAILED", error));
            }
        });
    }
    getScolapassPayments(variables, callback) {
        return __awaiter(this, void 0, void 0, function* () {
            const scolapassPaymentsMapper = new mappers_1.ScolapassPaymentsMapper();
            try {
                const response = yield this.apolloClient.query({
                    query: __1.GET_SCOLAPASS_PAYMEMENTS,
                    variables,
                });
                if (callback)
                    callback();
                return Promise.resolve(scolapassPaymentsMapper.toDomain(response));
            }
            catch (error) {
                return Promise.reject(new domain_1.UserRepositoryErrors.GetScolapassPaymentsRequestError("GET_SCOLAPASS_PAYMENTS_REQUEST_FAILED", error));
            }
        });
    }
    getProfileSectionsData(callback) {
        return __awaiter(this, void 0, void 0, function* () {
            const profileSectionsDataMapper = new mappers_1.ProfileSectionsDataMapper();
            try {
                const response = yield this.apolloClient.query({
                    query: __1.GET_PROFILE_SECTIONS_DATA,
                });
                if (callback)
                    callback();
                return Promise.resolve(profileSectionsDataMapper.toDomain(response));
            }
            catch (error) {
                return Promise.reject(new domain_1.UserRepositoryErrors.GetProfileSectionsDataRequestError("GET_PROFILE_SECTIONS_DATA_REQUEST_FAILED", error));
            }
        });
    }
    getUserInfoForTracking(callback) {
        return __awaiter(this, void 0, void 0, function* () {
            const userInfoForTrackingMapper = new mappers_1.UserInfoForTrackingMapper();
            try {
                const response = yield this.apolloClient.query({
                    query: __1.GET_USER_INFO_FOR_TRACKING,
                });
                if (callback)
                    callback();
                return Promise.resolve(userInfoForTrackingMapper.toDomain(response));
            }
            catch (error) {
                return Promise.reject(new domain_1.UserRepositoryErrors.GetUserInfoForTrackingRequestError("GET_USER_INFO_FOR_TRACKING_REQUEST_FAILED", error));
            }
        });
    }
    getScolapassInvoices(variables, callback) {
        return __awaiter(this, void 0, void 0, function* () {
            const scolapassInvoicesMapper = new mappers_1.ScolapassInvoicesMapper();
            try {
                const response = yield this.apolloClient.query({
                    query: __1.GET_SCOLAPASS_INVOICES,
                    variables,
                });
                if (callback)
                    callback();
                return Promise.resolve(scolapassInvoicesMapper.toDomain(response));
            }
            catch (error) {
                return Promise.reject(new domain_1.UserRepositoryErrors.GetScolapassPaymentsRequestError("GET_SCOLAPASS_PAYMENTS_REQUEST_FAILED", error));
            }
        });
    }
    getInvoiceScolapassFile(variables) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield this.apolloClient.query({
                    query: __1.GET_INVOICE_SCOLAPASS_FILE,
                    variables,
                    fetchPolicy: "no-cache",
                });
                return Promise.resolve(response.data);
            }
            catch (error) {
                return Promise.reject();
            }
        });
    }
}
exports.FUserRepository = FUserRepository;
