"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_LANGUAGE = void 0;
const client_1 = require("@apollo/client");
exports.UPDATE_LANGUAGE = client_1.gql `
  mutation updateLanguage($input: InputUpdateUser) {
    updateUser(input: $input) {
      id
      language
    }
  }
`;
