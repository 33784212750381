"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.holdingFixtures = void 0;
exports.holdingFixtures = {
    hasError: false,
    isLoading: false,
    holdingInfo: [
        {
            __typename: "Holding",
            id: "SG9sZGluZzoxNDI=",
            name: "SMART UP TEST ",
            foodi: true,
            address: {
                __typename: "Address",
                zip: "06200",
            },
            isBadgeRequired: true,
        },
        {
            __typename: "Holding",
            id: "SG9sZGluZzozNzkxNDg2",
            name: "SMART UP TEST C&C",
            foodi: true,
            address: {
                __typename: "Address",
                zip: "06200",
            },
            isBadgeRequired: true,
        },
        {
            __typename: "Holding",
            id: "SG9sZGluZzozNzkxNDg3",
            name: "HOLDING 3",
            foodi: true,
            address: {
                __typename: "Address",
                zip: "06200",
            },
            isBadgeRequired: false,
        },
    ],
};
