import { I18n } from "react-redux-i18n";

export interface Refills {
  amount: string;
  currencySymbol: string;
  isSelected: boolean;
}

export class RefillViewModel {
  // default maxAmount is 150
  constructor(private limitRefillAmount: number = 150) {}

  getInitState = () => {
    return this.getRefillInputs(-1);
  };

  getRefillInputs(inputIndex: number) {
    const refills: Refills[] = [
      {
        amount: "20",
        currencySymbol: "€",
        isSelected: false,
      },
      {
        amount: "30",
        currencySymbol: "€",
        isSelected: false,
      },
      {
        amount: "50",
        currencySymbol: "€",

        isSelected: false,
      },
      {
        amount: I18n.t("refill.other"),
        currencySymbol: "",
        isSelected: false,
      },
    ];

    const _refills = refills.map((refill, index) => {
      return { ...refill, isSelected: inputIndex === index };
    });
    return _refills;
  }

  noInputIsSelected = (_refills: Refills[]): boolean =>
    _refills.every(({ isSelected }) => !isSelected);

  isSurpassLimitBalance = (amount: number): boolean =>
    amount > this.limitRefillAmount;
}
