"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_EDENRED_AUTHORIZATION_URL = void 0;
const client_1 = require("@apollo/client");
exports.GET_EDENRED_AUTHORIZATION_URL = client_1.gql `
  query getEdenredAuthorizationURL($locale: String) {
    getEdenredAuthorizationURL(locale: $locale) {
      authorizationUrl
    }
  }
`;
