"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderState = void 0;
var OrderState;
(function (OrderState) {
    OrderState["ABANDONED"] = "ABANDONED";
    OrderState["ACCEPTED"] = "ACCEPTED";
    OrderState["CANCELLED"] = "CANCELLED";
    OrderState["CART"] = "CART";
    OrderState["COMPLETED"] = "COMPLETED";
    OrderState["EMPLOYEE_CART"] = "EMPLOYEE_CART";
    OrderState["ON_HOLD"] = "ON_HOLD";
    OrderState["PREPARING"] = "PREPARING";
    OrderState["READY"] = "READY";
    OrderState["REFUSED"] = "REFUSED";
})(OrderState = exports.OrderState || (exports.OrderState = {}));
