"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConfirmOrder = void 0;
class ConfirmOrder {
    constructor(orderRepository) {
        this.orderRepository = orderRepository;
    }
    execute(request) {
        return this.orderRepository.confirmOrder(request);
    }
}
exports.ConfirmOrder = ConfirmOrder;
