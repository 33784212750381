export const submitToUrl = (url: string, method: string, body: string): void => {
  // @ts-ignore
  const form = document.createElement('form');
  form.action = url;
  form.method = method;

  body.split('&').forEach(
    (pairs: string): void => {
      const [attributeName, attributeValue] = pairs.split('=');
      // @ts-ignore
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = attributeName;
      input.value = decodeURIComponent(attributeValue);
      form.appendChild(input);
    }
  );
  // @ts-ignore
  document.body.appendChild(form);

  form.submit();
};
