import React from "react";
import _ from "lodash";
import { Title16, Title22 } from "@stylesheets";
import { ModalTemplate } from "@atomic";
import { View, StyleSheet } from "react-native";
import { ComputedClassroom } from "@foodi/core";
import { Colors } from "@constants";
import { useDispatch } from "react-redux";
import { SchedulerViewModel } from "@modules";

interface IProps {
  commentsByClassroom?: ComputedClassroom[];
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedService: number;
}

export const EpointageCommentModal: React.FC<IProps> = ({
  commentsByClassroom,
  isModalOpen,
  setIsModalOpen,
  selectedService,
}) => {
  const dispatch = useDispatch();
  const [schedulerVM] = React.useState<SchedulerViewModel>(
    new SchedulerViewModel(dispatch)
  );

  return (
    <ModalTemplate
      isOpen={isModalOpen}
      handleClose={() => setIsModalOpen(false)}
      isFullScreen
      closeOnClickOutside
      isCenter
      style={styles.modalStyle}
    >
      <View>
        <Title22 color={Colors.darkGray}>Commentaires</Title22>
        <View>
          {commentsByClassroom?.map((c: ComputedClassroom, index: number) => {
            const comment = schedulerVM.getCommentWithServiceId(
              c.comments,
              selectedService
            );
            if (comment?.scheduleId !== selectedService) return;
            return (
              <View style={styles.commentRow} key={index}>
                <Title16 bold>{c.name}</Title16>
                <View style={styles.commentContainer}>
                  <Title16 color={Colors.gray1}>{comment?.comment}</Title16>
                </View>
              </View>
            );
          })}
        </View>
      </View>
    </ModalTemplate>
  );
};

const styles = StyleSheet.create({
  commentRow: {
    marginTop: 30,
  },
  commentContainer: {
    marginTop: 5,
  },
  modalStyle: {
    flex: 1,
    maxHeight: 353,
  },
});
