"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetUserProfile = void 0;
class GetUserProfile {
    constructor(userRepository) {
        this.userRepository = userRepository;
    }
    execute(callback) {
        return this.userRepository.getUserProfile(callback);
    }
}
exports.GetUserProfile = GetUserProfile;
