"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetServices = void 0;
class GetServices {
    constructor(holdingRepository) {
        this.holdingRepository = holdingRepository;
    }
    execute(callback) {
        return this.holdingRepository.getServices(callback);
    }
}
exports.GetServices = GetServices;
