import React, { useEffect } from "react";
import { StyleSheet, Text } from "react-native";
import {
  Container,
  Title,
  ButtonContainer,
  SeeAction,
  GoBackContainer,
  ProductDescription,
  GoBackText,
  GoBackContainerMobile,
} from "../Cart/Cart.style";
import { BookingOrder, Slot } from "@foodi/core";
import { I18n } from "react-redux-i18n";
import { getDecodedId, TestIDs } from "@utils";
import { Button, CalendarDays, OfferSlots } from "@atomic";
import { GlobalState } from "@redux";
import { useDispatch, useSelector } from "react-redux";
import { NavigationProp } from "@react-navigation/native";
import {
  BookingActions,
  CalendarFlow,
  CalendarViewModel,
  ConfirmationPageActions,
  HomeScreens,
  OffersActions,
  OrderViewModel,
  PointOfSaleActions,
  POSScreens,
  selectedBookingOfferSelector,
} from "@modules";
import { useDevices } from "@hooks";
import { Back } from "@assets";
import { Colors, Spacing } from "@constants";

interface IProps {
  navigation?: NavigationProp<any>;
  idPos: number;
  isModify: any;
}

export const BookingCart: React.FC<IProps> = React.memo(
  ({ navigation, idPos, isModify }) => {
    const dispatch = useDispatch();
    const [isMobile] = useDevices();

    const [offerSlots, setOfferSlots] = React.useState<Slot[]>();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [bookingForDifferentPos, setBookingForDifferentPos] = React.useState<
      BookingOrder | undefined
    >();

    const selectedBookingOffer = useSelector(selectedBookingOfferSelector);
    const idGuest = useSelector(
      (state: GlobalState) => state?.auth?.userInfo?.idGuest
    );
    const offerSlot = useSelector(
      (state: GlobalState) => state.offers?.selectedOfferSlot?.withdrawRange
    );
    const bookingOrders = useSelector(
      (state: GlobalState) => state?.booking?.bookingOrders
    );
    const selectedDay = useSelector(
      (state: GlobalState) => state.booking?.selectedDay ?? 0
    );
    const userLanguage = useSelector(
      (state: GlobalState) => state.auth?.userInfo?.language
    );
    const holdingId = useSelector(
      (state: GlobalState) => state?.auth?.userInfo?.holdingId
    );

    const idOffer = selectedBookingOffer?.id;

    const orderVM = new OrderViewModel(dispatch, idGuest || "");

    const [CalendarVM] = React.useState<CalendarViewModel>(
      new CalendarViewModel()
    );

    useEffect(() => {
      const { dayNumber } = CalendarVM.getDateInfo(selectedDay, userLanguage);
      const booking = orderVM.getBookingForTheDay(
        bookingOrders,
        dayNumber,
        idPos
      );
      setBookingForDifferentPos(booking as BookingOrder);
    }, [selectedDay]);

    useEffect(() => {
      setOfferSlots(selectedBookingOffer?.slots ?? []);
    }, [selectedBookingOffer]);

    const currentReservation = orderVM.getCurrentReservation(
      bookingOrders,
      offerSlot
    );

    const disabledButton =
      isModify?.withdrawRange === offerSlot ||
      currentReservation?.withdrawRange === offerSlot ||
      !offerSlot;

    const onPress = async () => {
      if (idOffer) {
        setLoading(true);
        let res: any = {};
        if (
          currentReservation ||
          (currentReservation === undefined &&
            isModify &&
            offerSlot &&
            orderVM.isTheSameDay(offerSlot, isModify?.withdrawRange))
        ) {
          res = await orderVM.updateBookingOrderSlot({
            idOrder:
              currentReservation === undefined
                ? isModify?.id
                : currentReservation?.id,
            withdrawRange: offerSlot,
          });
        } else {
          res = await orderVM.createBookingOrder({
            idGuest: "",
            idOffer,
            withdrawRange: offerSlot,
          });
        }
        setLoading(false);

        const offerSlotValue = offerSlot;

        dispatch(OffersActions.setSelectedOfferSlot(null));
        dispatch(PointOfSaleActions.setNewOrder(true));
        dispatch(BookingActions.setBookingSelected(true));

        const id = getDecodedId(res?.id);

        dispatch(
          ConfirmationPageActions.setConfirmationPageParams({
            id: id?.split(":")?.[1],
            orderId: res?.id,
            isFirstTime: true,
            idPos,
            offerSlot: offerSlotValue,
          })
        );

        navigation?.navigate(HomeScreens.POINT_OF_SALE_STACK, {
          screen: POSScreens.CONFIRMATION_PAGE,
        });
      }
    };

    const showButton =
      !selectedBookingOffer?.fullyBooked &&
      selectedBookingOffer?.published &&
      !!offerSlots?.length &&
      !bookingForDifferentPos;

    const handleSeeAction = () => {
      dispatch(PointOfSaleActions.setBookingButtonStatus(true));

      const id =
        bookingForDifferentPos &&
        orderVM.getIdFromBookingOrder(bookingForDifferentPos);
      //@ts-ignore
      navigation?.push(HomeScreens.POINT_OF_SALE_STACK, {
        screen: POSScreens.RESTAURANT_DETAIL,
        params: {
          id,
          isModify: bookingForDifferentPos,
          isRefillFromCart: false,
        },
      });
    };

    const handleGoBack = () => {
      navigation?.navigate(HomeScreens.HOME_SCREEN);
    };

    const handleGoBackMiniCart = () => {
      dispatch(PointOfSaleActions.setMiniBookingCartStatus(true));
      dispatch(PointOfSaleActions.setFullBookingCartStatus(false));
      dispatch(OffersActions.setSelectedOfferSlot(null));
    };

    const isSameHolding =
      currentReservation?.bookingOffer?.bookingOfferTemplate?.pos?.zone?.holding
        ?.id === holdingId;

    return (
      <Container
        testID={TestIDs.restaurantDetail.views.cartContainer}
        isMobile={isMobile}
      >
        {isMobile && (
          <GoBackContainerMobile
            testID={TestIDs.home.confirmationPage.actions.goBack}
            onPress={handleGoBackMiniCart}
          >
            <Back />
            <GoBackText>{I18n.t("common.return")}</GoBackText>
          </GoBackContainerMobile>
        )}
        <Title style={isMobile && styles.title}>
          {I18n.t("restaurantDetail.cart.booking")}
        </Title>
        {isMobile && (
          <CalendarDays
            style={styles.calendarView}
            isModify={isModify}
            userLanguage={userLanguage}
            calendarFlow={CalendarFlow.BOOKING}
          />
        )}
        {offerSlots && (
          <OfferSlots
            offerSlots={offerSlots}
            isModify={isModify}
            selectedBookingOffer={selectedBookingOffer}
            bookingForDifferentPos={bookingForDifferentPos}
          />
        )}
        {bookingForDifferentPos && selectedBookingOffer?.published && (
          <>
            {isSameHolding && (
              <SeeAction onPress={handleSeeAction}>
                <Text style={styles.manageBooking}>
                  {I18n.t("homepage.restaurantCard.see")}
                </Text>
              </SeeAction>
            )}
            {!isMobile && (
              <GoBackContainer onPress={handleGoBack}>
                <ProductDescription fullWidth underline>
                  {I18n.t("common.returnHome")}
                </ProductDescription>
              </GoBackContainer>
            )}
          </>
        )}
        {showButton && (
          <ButtonContainer>
            <Button
              forwardTestID={TestIDs.components.Button.views.container}
              disabled={disabledButton || loading}
              onPress={onPress}
              label={I18n.t("restaurantDetail.cart.validate")}
              loading={loading}
            />
          </ButtonContainer>
        )}
      </Container>
    );
  }
);

const styles = StyleSheet.create({
  calendarView: {
    marginVertical: Spacing.XL,
    alignItems: "flex-start",
  },
  manageBooking: {
    fontSize: 16,
    color: Colors.foodiBlack,
    fontFamily: "manrope-bold",
    lineHeight: 17,
    textAlign: "center",
    paddingBottom: 4,
  },
  title: {
    marginBottom: 0,
  },
});
