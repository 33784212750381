"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetEdenredDailyBalance = void 0;
class GetEdenredDailyBalance {
    constructor(paymentsService) {
        this.paymentsService = paymentsService;
    }
    execute(request) {
        return this.paymentsService.getEdenredDailyBalance(request);
    }
}
exports.GetEdenredDailyBalance = GetEdenredDailyBalance;
