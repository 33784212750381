import React from "react";
import { AccordeonArrow as AccordeonArrowIcon } from "@assets";
import { Pressable, StyleProp, StyleSheet, ViewStyle } from "react-native";

interface IProps {
  rotate?: boolean;
  style?: StyleProp<ViewStyle>;
  onPress?: () => void;
}

export const AccordeonArrow = React.memo(
  ({ rotate, onPress, style }: IProps) => {
    return (
      <Pressable onPress={onPress} style={[style, rotate && styles.rotate]}>
        <AccordeonArrowIcon />
      </Pressable>
    );
  }
);

const styles = StyleSheet.create({
  rotate: {
    transform: [{ rotate: '180deg' }],
  },
});
