import React, { useState, useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { Title22, Title36 } from "@stylesheets";
import { I18n } from "react-redux-i18n";
import { ValidButton, RefillTextInput, LabelIconError } from "@atomic";
import { Colors, Spacing } from "@constants";
import { TestIDs } from "@utils";
import { useDevices } from "@hooks";

interface IProps {
  forwardTestID?: string;
  children?: React.ReactNode;
  onValidate: Function;
  handlePayment: (value: number) => void;
  balance: number;
  defaultPayment: number;
  maxAmount: number;
  title: string;
  errorDescription: string;
}

export const PaymentInput: React.FC<IProps> = React.memo(
  ({
    onValidate,
    handlePayment,
    defaultPayment,
    maxAmount,
    title,
    errorDescription,
  }) => {
    const [invalidInput, setInvalidInput] = useState<boolean>(false);
    const [isMobile] = useDevices();
    const [value, setValue] = useState<number>(defaultPayment);
    const styles = useMemo(() => _styles(isMobile), [isMobile]);
    const Title = isMobile ? Title22 : Title36;

    return (
      <View style={styles.container}>
        <Title
          testID={TestIDs.home.balance.texts.refillText}
          style={styles.title}
        >
          {title}
        </Title>
        <View style={styles.textInputDiv}>
          <RefillTextInput
            forwardTestID={TestIDs.home.balance.texts.refillTextInput}
            invalidInput={invalidInput}
            setInvalidInput={setInvalidInput}
            handleOtherValue={(value) => {
              setValue(value);
              handlePayment(value);
            }}
            defaultValue={String(defaultPayment)}
            maxAmount={maxAmount}
          />
          {invalidInput && (
            <LabelIconError
              forwardTestID={TestIDs.home.balance.views.errorRefillLimit}
              style={styles.labelIconError}
              textStyle={styles.labelIconErrorText}
              title={errorDescription}
            />
          )}
        </View>
        <ValidButton
          forwardTestID={TestIDs.home.balance.actions.validate}
          title={I18n.t("refill.validate")}
          onPress={onValidate}
          isDisabled={invalidInput || value === 0}
          textStyle={styles.labelValidateButton}
        />
      </View>
    );
  }
);

const _styles = (isMobile: boolean) =>
  StyleSheet.create({
    container: {
      width: "100%",
      backgroundColor: Colors.background1,
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 8,
      paddingHorizontal: isMobile ? 0 : Spacing.XL,
      paddingVertical: isMobile ? Spacing.M : Spacing.XXL,
    },
    labelIconError: {
      marginTop: Spacing.M,
    },
    labelIconErrorText: {
      fontSize: isMobile ? 13 : 16,
    },
    labelValidateButton: {
      paddingHorizontal: 54,
      paddingVertical: 15,
    },
    textInputDiv: {
      flex: 1,
      marginVertical: isMobile ? Spacing.M : Spacing.XL,
      justifyContent: "center",
      alignItems: "center",
    },
    title: {
      color: Colors.foodiBlack,
      fontFamily: "manrope-bold",
    },
  });
