import { Action } from "../action";
import { createReducer } from "../createReducer";

export interface MobileSizeState {
  isMobile: boolean;
}

/*************  Actions  ****************/

const ActionTypes = {
  SET_MOBILE_STATE: "WINDOW/SET_MOBILE_STATE",
};

const ActionCreators = {
  setMobileSizeState: (isMobile: boolean): Action<boolean> => ({
    type: ActionTypes.SET_MOBILE_STATE,
    payload: isMobile,
  }),
};

/*************  Reducer  ****************/

const initialState: MobileSizeState = {
  isMobile: false,
};

const Reduction = {
  setMobileSizeState: (
    state: MobileSizeState,
    { payload: isMobile }: Action<boolean>
  ) => ({
    isMobile,
  }),
};

const reducer = createReducer(initialState, {
  [ActionTypes.SET_MOBILE_STATE]: Reduction.setMobileSizeState,
});

export default reducer;

export {
  reducer as MobileStateReducer,
  ActionTypes as MobileStateActionTypes,
  ActionCreators as MobileStateActions,
};
