import { Colors } from "@constants";
import style from "styled-components/native";

export const Container = style.View<{ isMobile?: boolean }>`
    padding: ${({ isMobile }) => (isMobile ? "20px 18px" : "45px 150px")};
    background-color: ${Colors.white};
    height: 100%;
`;

export const GoBackContainer = style.Pressable`
    flex-direction: row;
    align-items: center;
    max-width: 250px;
`;

export const GoBackText = style.Text<{ isMobile?: boolean }>`
    color: ${Colors.foodiBlack};
    font-family: manrope-bold;
    font-size: ${({ isMobile }) => (isMobile ? 16 : 22)}px;
    letter-spacing: 0;
    line-height: ${({ isMobile }) => (isMobile ? 22 : 30)}px;
    margin-left: 7px;
`;

export const Title = style.Text<{ isMobile?: boolean }>`
    color: ${Colors.foodiBlack};
    font-family: manrope-bold;
    font-size: ${({ isMobile }) => (isMobile ? 22 : 36)}px;
    letter-spacing: 0;
    line-height: ${({ isMobile }) => (isMobile ? 24 : 49)}px;
    margin-top: ${({ isMobile }) => (isMobile ? 6 : 100)}px;
    text-align: ${({ isMobile }) => (isMobile ? "left" : "center")};
`;

export const MessageContainer = style.View<{ isMobile?: boolean }>`
    flex-direction: row;
    justify-content: center;
    margin-top: ${({ isMobile }) => (isMobile ? 13 : 20)}px;
`;

export const Message = style.Text<{ isMobile?: boolean }>`
    color: ${Colors.foodiBlack};
    font-family: manrope;
    font-size: ${({ isMobile }) => (isMobile ? 16 : 22)}px;
    letter-spacing: 0;
    line-height: ${({ isMobile }) => (isMobile ? 22 : 30)}px;
    margin-left: 7px;
    text-align: center;
    max-width: ${({ isMobile }) => (isMobile ? 356 : 862)}px;
`;

export const IconContainer = style.View<{ isMobile?: boolean }>`
    top: ${({ isMobile }) => (isMobile ? 2 : 5)}px;
`;

export const EmailTo = style.Pressable`
    color: ${Colors.redError};
`;
