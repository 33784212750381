import React from "react";
import { TouchableOpacity } from "react-native";
import { I18n } from "react-redux-i18n";
import _ from "lodash";

import {
  ItemContainer,
  GreenCircle,
  ItemTextContainer,
  ItemTitle,
} from "./ComingSoonModal.style";
import { BookingOrder } from "@foodi/core";
import OrderDescription from "./OrderDescription";
import { NavigationProp } from "@react-navigation/native";
import {
  BookingActions,
  ConfirmationPageActions,
  HomeScreens,
  OffersActions,
  POSScreens,
} from "@modules";
import { getDecodedId } from "@utils";
import { useDispatch } from "react-redux";
import { TestIDs } from "@utils";

interface IReservation {
  reservation: BookingOrder;
  navigation?: NavigationProp<any>;
  handleCloseModal: () => void;
}

const ReservationItem: React.FC<IReservation> = React.memo(
  ({ reservation, navigation, handleCloseModal }) => {
    const dispatch = useDispatch();

    const handleOnPress = () => {
      handleCloseModal();

      const id = getDecodedId(reservation?.id)?.split(":")?.[1];
      const idPos = getDecodedId(
        reservation?.bookingOffer?.bookingOfferTemplate?.pos?.id
      )?.split(":")?.[1];

      dispatch(OffersActions.setSelectedOfferSlot(null));
      dispatch(BookingActions.setBookingSelected(true));

      dispatch(
        ConfirmationPageActions.setConfirmationPageParams({
          id,
          orderId: reservation.id,
          isFirstTime: false,
          idPos,
          offerSlot: "",
        })
      );

      navigation?.navigate(HomeScreens.POINT_OF_SALE_STACK, {
        screen: POSScreens.CONFIRMATION_PAGE,
      });
    };

    const pos = reservation?.offerTemplateName;

    return (
      <TouchableOpacity
        testID={TestIDs.home.comingSoon.actions.openBooking}
        onPress={handleOnPress}
      >
        <ItemContainer>
          <ItemTextContainer>
            <GreenCircle />
            <ItemTitle>
              {_.upperFirst(I18n.t("homepage.comingSoon.reservation"))}
            </ItemTitle>
          </ItemTextContainer>
          <OrderDescription order={reservation} pos={pos} />
        </ItemContainer>
      </TouchableOpacity>
    );
  }
);

export default ReservationItem;
