"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.epointageFixture = void 0;
const calendarFixtures = {
    siteId: '1',
    year: '2023',
    month: '5',
    type: 'lunisolar',
    open: true,
};
const serviceFixtures = [
    {
        id: '1',
        name: 'lunch',
        published: true,
        holdingId: '1',
    },
    {
        id: '2',
        name: 'lunch',
        published: true,
        holdingId: '2',
    },
];
const establishmentFixtures = [
    {
        establishmentId: '1',
        siteId: '1',
        name: 'school1',
        address1: 'address1',
        address2: 'address2',
        zipCode: 'zipcode1',
    },
    {
        establishmentId: '2',
        siteId: '2',
        name: 'school2',
        address1: 'address3',
        address2: 'address4',
        zipCode: 'zipcode2',
    },
];
const classroomFixtures = [
    {
        siteId: '1',
        establishmentId: '1',
        classId: '1',
        name: 'classroom1',
    },
    {
        siteId: '2',
        establishmentId: '2',
        classId: '2',
        name: 'classroom2',
    },
];
exports.epointageFixture = {
    calendarFixtures,
    serviceFixtures,
    establishmentFixtures,
    classroomFixtures
};
