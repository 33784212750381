import React from "react";
import { StyleSheet, Document, Page, View, Text, Font } from '@react-pdf/renderer';
import { I18n } from "react-redux-i18n";
import moment from "moment";
import _ from "lodash";
import { PointageLogo, Time } from "@assets";
import { IListItem } from "@modules";
import { Colors } from "@constants";

//@ts-ignore
import ManropeBold from '../../../assets/fonts/Manrope-Bold.ttf';
//@ts-ignore
import ManropeRegular from '../../../assets/fonts/Manrope-Regular.ttf';

Font.register({ family: 'Manrope-Bold', src: ManropeBold });
Font.register({ family: 'Manrope', src: ManropeRegular });

interface IProps {
    schedules: string[];
    dates: any[];
    service: IListItem;
}

export const EpointagePDF: React.FC<IProps> = React.memo(({
    schedules, dates, service
}) => {
    const MAX_STUDENTS_PER_PAGE = 30;

    const currentDate = moment();
    const exported = I18n.t("epointage.homepage.epointagePdf.exported");
    const day = currentDate.format("DD.MM.YYYY");
    const hour = currentDate.format("HH[h]mm");
    const at = I18n.t("common.at");
    const exportedOn = `${exported} ${day} ${at} ${hour}`;

    interface ISection1 {
        date?: any;
        school?: any;
        classe?: any;
    }

    const Section1: React.FC<ISection1> = ({
        date, school, classe
    }) => {
        const weekDay = moment(date?.date).format('dddd');
        const dateString = moment(date?.date).format('D MMMM').toLowerCase();
        return (
            <View style={styles.section1}>
                <View style={styles.initialInfo}>
                    <PointageLogo isPdf />
                    <View style={styles.textContainer}>
                        <Text style={styles.date}>{weekDay}</Text>
                        <Text style={styles.date}>{dateString}</Text>
                    </View>
                    <View style={styles.separator} />
                    <Text style={styles.service}>{service?.name}</Text>
                    <View style={styles.separator} />
                    <Text style={styles.service}>{school?.schoolName}</Text>
                    <Text style={styles.date}>{classe?.className}</Text>
                </View>
                <View style={styles.boxesContainer}>
                    <Text style={styles.total}>{I18n.t("restaurantDetail.cart.total")}</Text>
                    <View style={styles.totalSpace} />
                    <Text style={styles.total}>{I18n.t("confirmationPage.comment")}</Text>
                    <View style={styles.commentSpace} />
                    <Text
                        style={styles.pageText}
                        render={({ pageNumber, totalPages }) => (
                            `${I18n.t("epointage.homepage.epointagePdf.page")} ${pageNumber}/${totalPages}`
                        )}
                    />
                    <View style={styles.row}>
                        <View style={styles.copyright}>
                            <Text style={styles.copyrightText}>c</Text>
                        </View>
                        <Text style={styles.compass}>Compass Group France</Text>
                    </View>
                    <Text style={styles.compass}>{exportedOn}</Text>
                </View>
            </View>
        );
    };

    interface ISection2 {
        students?: any[];
    }

    const Section2: React.FC<ISection2> = ({
        students
    }) => {
        return (
            <View style={styles.section2}>
                <View style={styles.initialRow}>
                    <Text style={styles.students}>{I18n.t('epointage.homepage.classroom.students')}</Text>
                    <View style={styles.schedulesContainer}>
                        {schedules?.map((schedule: string, index4: number) => (
                            <View key={`schedules_${index4}_${schedule}`} style={styles.scheduleContainer}>
                                <Time isPdf size={15} />
                                <Text style={styles.scheduleText}>{schedule?.replace(":", "h")}</Text>
                            </View>
                        ))}
                    </View>
                </View>
                <View style={styles.studentsList}>
                    <View style={styles.studentSeparator} />
                    {students?.map((student: any, index5: number) => (
                        <>
                            <View key={index5} style={[styles.initialRow, styles.margin]}>
                                <Text style={styles.studentText}>{student?.studentName} {student?.studentSurname}</Text>
                                <View style={styles.schedulesContainer}>
                                    {schedules?.map((schedule: string, index6: number) => (
                                        <View key={`${index5}_${index6}`} style={styles.checkbox} />
                                    ))}
                                </View>
                            </View>
                            <View style={styles.studentSeparator} />
                        </>
                    ))}
                </View>
            </View>
        );
    };

    return (
        <Document>
            {dates?.map?.((date: any, index1: number) => {
                return date?.schools?.map((school: any, index2: number) => {
                    let pagesList = [];
                    for (let index3 = 0, studentsIndex = 0; index3 < school?.classes?.length;) {
                        const classe = school?.classes?.[index3];
                        const students = _.chunk(classe?.students, MAX_STUDENTS_PER_PAGE);
                        pagesList.push(
                            <Page key={`${index1}_${index2}_${index3}_${studentsIndex}`} size="A4" style={styles.page}>
                                <Section1 date={date} school={school} classe={classe} />
                                <Section2 students={students?.[studentsIndex]} />
                            </Page>
                        );

                        if (studentsIndex < students?.length - 1) ++studentsIndex;
                        else {
                            ++index3;
                            studentsIndex = 0;
                        }
                    }
                    return pagesList;
                });
            })}
        </Document>
    );
});

const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: Colors.white,
        flex: 1
    },
    section1: {
        flex: 1,
        justifyContent: 'space-between',
        backgroundColor: Colors.disabledBackground,
        maxWidth: 178,
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
        padding: 30
    },
    section2: {
        flex: 1,
        padding: 30,
    },
    date : {
        color: Colors.foodiBlack,
        fontFamily: 'Manrope-Bold',
        fontSize: 16
    },
    textContainer: {
        marginTop: 20,
        marginBottom: 10
    },
    separator: {
        height: 1.5,
        width: 118,
        backgroundColor: Colors.darkGrey,
    },
    service: {
        color: Colors.foodiBlack,
        fontFamily: 'Manrope-Bold',
        fontSize: 11,
        marginVertical: 10
    },
    total: {
        color: Colors.foodiBlack,
        fontFamily: 'Manrope-Bold',
        fontSize: 9,
        marginTop: 10,
        marginBottom: 5
    },
    totalSpace: {
        boxSizing: 'border-box',
        height: 39,
        width: 118,
        border: `1px solid ${Colors.darkGrey}`,
        borderRadius: 8,
        backgroundColor: Colors.white,
    },
    commentSpace: {
        boxSizing: 'border-box',
        height: 235,
        width: 118,
        border: `1px solid ${Colors.darkGrey}`,
        borderRadius: 8,
        backgroundColor: Colors.white,
    },
    boxesContainer: {
        flex: 1,
        justifyContent: 'flex-end'
    },
    initialInfo: {
        flex: 1,
    },
    copyrightText: {
        fontSize: 3,
        color: Colors.foodiBlack,
        fontFamily: 'Manrope',
    },
    copyright: {
        width: 6,
        height: 6,
        borderRadius: 3,
        border: `0.5px solid ${Colors.foodiBlack}`,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 2,
    },
    compass: {
        color: Colors.foodiBlack,
        fontFamily: 'Manrope',
        fontSize: 7,
    },
    pageText: {
        color: Colors.foodiBlack,
        fontFamily: 'Manrope-Bold',
        fontSize: 11,
        marginTop: 15,
        marginBottom: 5
    },
    row: {
        flexDirection: 'row',
    },
    students: {
        color: Colors.foodiBlack,
        fontFamily: 'Manrope-Bold',
        fontSize: 11,
        flex: 0.5,
    },
    scheduleContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    scheduleText: {
        color: Colors.foodiBlack,
        fontFamily: 'Manrope-Bold',
        fontSize: 11,
        marginLeft: 2
    },
    schedulesContainer: {
        flex: 0.5,
        flexDirection: 'row',
        justifyContent: 'space-evenly'
    },
    initialRow: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    studentsList: {
        marginTop: 15
    },
    checkbox: {
        boxSizing: 'border-box',
        height: 16,
        width: 16,
        border: `1px solid ${Colors.foodiBlack}`,
        borderRadius: 7,
    },
    studentText: {
        color: Colors.foodiBlack,
        fontFamily: 'Manrope',
        fontSize: 9,
        flex: 0.55
    },
    studentSeparator: {
        height: 1,
        width: '100%',
        backgroundColor: Colors.footerCardGray,
        marginBottom: 3.85
    },
    margin: {
        marginBottom: 3.85
    }
});
