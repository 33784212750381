import React, { useEffect, useState } from "react";
import { StyleSheet, View, ViewStyle } from "react-native";
import { Title18 } from "@stylesheets";
import { Colors } from "@constants";
import { TestIDs } from "@utils";
import { ControlQuantityBtn } from "./ControlQuantityBtn";
import { useSelector } from "react-redux";
import { GlobalState } from "@redux";
import { ActiveOffer } from "@foodi/core";
import { OfferViewModel } from "@modules";

interface IProps {
  forwardTestID?: string;
  onHandle: Function;
  productId: string;
  sameProductPerOrder?: number;
  isOverProductsLimit?: boolean;
  quantityValue?: number;
  style?: ViewStyle | ViewStyle[];
  disableDefaultStyle?: boolean;
  addQuantityOverride?: (() => void) | undefined;
  quantityOverall?: number;
  isBakingModal?: boolean;
}

export const ProductQuantity: React.FC<IProps> = React.memo(
  ({
    onHandle,
    productId,
    sameProductPerOrder,
    isOverProductsLimit,
    quantityValue = 0,
    style,
    disableDefaultStyle = false,
    addQuantityOverride,
    quantityOverall = 0,
    isBakingModal = false,
  }) => {
    const [quantity, setQuantity] = useState<number>(quantityValue);
    const isOverOrderLimit =
      sameProductPerOrder === undefined
        ? false
        : quantity >= sameProductPerOrder;

    const addQuantity = () => {
      if (quantity + 1 <= quantityOverall) {
        setQuantity(quantity + 1);
        onHandle(quantity + 1);
      }
    };

    const subtractQuantity = () => {
      setQuantity(quantity - 1);
      onHandle(quantity - 1);
    };

    useEffect(() => {
      if (quantityValue !== quantity) {
        setQuantity(quantityValue);
      }
    }, [quantityValue]);

    const [offerVM] = useState(new OfferViewModel());

    const selectedActiveOffer = useSelector(
      (state: GlobalState) => state.offers.activeOrderableOffer
    );
    return (
      <View style={[disableDefaultStyle ? {} : styles.container, style]}>
        <ControlQuantityBtn
          isDisabled={
            offerVM.isActiveOfferReady(selectedActiveOffer as ActiveOffer) ||
            isOverOrderLimit ||
            isOverProductsLimit ||
            quantity === quantityOverall
          }
          isAddition
          callback={addQuantityOverride ?? addQuantity}
          forwardTestID={`${TestIDs.restaurantDetail.actions.addProductQuantity}_${productId}`}
        />

        {!!quantity && <Title18 style={styles.title}>{quantity}</Title18>}
        {!!quantity && (
          <ControlQuantityBtn
            isDisabled={isBakingModal && quantity === 1}
            isAddition={false}
            callback={subtractQuantity}
            forwardTestID={`${TestIDs.restaurantDetail.actions.subtractProductQuantity}_${productId}`}
          />
        )}
      </View>
    );
  }
);

const styles = StyleSheet.create({
  container: {
    marginTop: 20,
    width: 80,
    height: 25,
    flexDirection: "row-reverse",
    alignItems: "flex-end",
    justifyContent: "space-between",
  },
  title: {
    color: Colors.black,
    fontFamily: "manrope-bold",
    textAlign: "center",
  },
});
