"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CONFIRM_ORDER = void 0;
const client_1 = require("@apollo/client");
exports.CONFIRM_ORDER = client_1.gql `
  mutation confirmOrder(
    $idOrder: ID!
    $newState: OrderState!
    $paymentMethod: PaymentMethod
    $accessToken: String
    $tableNumber: Int
  ) {
    order: changeOrderState(
      idOrder: $idOrder
      newState: $newState
      paymentMethod: $paymentMethod
      accessToken: $accessToken
      tableNumber: $tableNumber
    ) {
      id
    }
  }
`;
