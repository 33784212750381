"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.onePosFixture = void 0;
exports.onePosFixture = {
    pointOfSale: {
        //@ts-ignore
        id: "Pos:1092",
        concept: {
            id: "Pos:1092",
            name: "Autre",
            image: "_app/user-media/8e1dfa37782cf8a1db7d537b9980a69d182d4b0c.jpg",
        },
        type: "SELF",
        name: "LES NOUVEAUX ARTISANS",
        schedules: [
            {
                days: [1, 2, 3, 4, 5],
                hours: ["11:30:00", "15:00:00"],
            },
            {
                days: [6],
                hours: ["09:00:00", "16:00:00"],
            },
        ],
        zone: {
            id: "Zone:549",
            name: "LES NOUVEAUX ARTISANS",
            holding: {
                id: "Holding:142",
                name: "SMARTUP",
                importationType: "OTHER",
            },
        },
        externalServices: [
            {
                title: "Service 1",
                content: "http://service1.com",
                active: true
            },
            {
                title: "Service 2",
                content: "http://service2.com",
                active: true
            },
            {
                title: "Service 3",
                content: "http://service3.com",
                active: true
            }
        ],
        content: "Description du restaurant. Le lorem ipsum est, en imprimerie, une suite de mots sans signification.\n\nLa page, le texte définitifvenant remplacer le faux-texte dès qu'il est prêt ou que la mise en page est achevée. Le lorem ipsum est, en imprimerie, une suite de mots sans signification.",
        image: "",
        __typename: "Pos",
    },
};
