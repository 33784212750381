"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.bookingOrdersFixtures = void 0;
exports.bookingOrdersFixtures = [
    {
        id: "Order:6",
        __typename: "BookingOrder",
        //@ts-ignore
        state: "PREPARING",
        idWithdrawal: null,
        created: "2021-10-05T19:30:00.000Z",
        updated: "",
        idOffer: 5,
        offerTemplateName: "templateName",
        withdrawRange: "2022-10-11T11:15:00.000Z/2022-10-11T11:30:00.000Z",
        guest: {
            __typename: "Guest",
            firstName: "Joao",
            lastName: "Leitao",
        },
        bookingOffer: {
            __typename: "Offer",
            id: "Offer:5",
            withdrawRange: "2022-10-11T11:15:00.000Z/2022-10-11T11:30:00.000Z",
            bookingOfferTemplate: {
                __typename: "OfferTemplate",
                id: "OfferTemplate:1",
                pos: {
                    __typename: "Pos",
                    id: "Pos:1",
                    name: "Nom restaurant",
                },
                //@ts-ignore
                type: "BOOKING_SERVICE",
            },
        },
    },
    {
        id: "Order:7",
        __typename: "BookingOrder",
        //@ts-ignore
        state: "PREPARING",
        idWithdrawal: null,
        created: "2021-10-05T12:30:00.000Z",
        updated: "",
        idOffer: 5,
        offerTemplateName: "templateName",
        withdrawRange: null,
        guest: {
            __typename: "Guest",
            firstName: "Joao",
            lastName: "Leitao",
        },
        bookingOffer: {
            __typename: "Offer",
            id: "Offer:5",
            withdrawRange: "2022-10-26T12:00:00.000Z/2022-10-26T12:15:00.000Z",
            bookingOfferTemplate: {
                __typename: "OfferTemplate",
                id: "OfferTemplate:1",
                pos: {
                    __typename: "Pos",
                    id: "Pos:1",
                    name: "Nom restaurant",
                },
                //@ts-ignore
                type: "BOOKING_SERVICE",
            },
        },
    },
];
