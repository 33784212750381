"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IPaymentTypes = void 0;
var IPaymentTypes;
(function (IPaymentTypes) {
    IPaymentTypes["Badge"] = "BADGE";
    IPaymentTypes["CreditCard"] = "CREDIT_CARD";
    IPaymentTypes["Edenred"] = "EDENRED";
    IPaymentTypes["OnSite"] = "ON_SITE";
})(IPaymentTypes = exports.IPaymentTypes || (exports.IPaymentTypes = {}));
;
;
