"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.bookingTemplateOfferFixture = void 0;
exports.bookingTemplateOfferFixture = {
    pos: {
        id: "UG9zOjEwOTM=",
        name: "L'ATELIER ",
        schedules: [
            {
                days: [1, 2, 3, 4, 5],
                hours: ["11:30:00", "21:30:00"],
            },
        ],
        //@ts-ignore
        zone: {
            id: "Wm9uZTo1NDk=",
            idHolding: "142",
        },
        bookingOfferTemplate: [
            {
                id: "T2ZmZXJUZW1wbGF0ZTozNg==",
                name: "L'ATELIER",
                period: "DAILY",
                published: true,
                timezone: "Europe/Paris",
                withdrawStartDefault: "PT7H30M",
                withdrawEndDefault: "PT18H",
                maxOrdersPerSlotDefault: 4,
                withdrawSlotDuration: "PT30M",
                nextOrderableOffers: [
                    {
                        id: "T2ZmZXI6NDU4MzA=",
                        published: true,
                        orderRange: "2021-12-06T23:00:00.000+00:00/2021-12-07T17:00:00.000+00:00",
                        withdrawRange: "2021-12-07T06:30:00.000+00:00/2021-12-07T17:00:00.000+00:00",
                        maxOrdersPerSlot: 4,
                        fullyBooked: false,
                        slots: [
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-07T14:30:00.000+00:00/2021-12-07T15:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-07T15:00:00.000+00:00/2021-12-07T15:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-07T15:30:00.000+00:00/2021-12-07T16:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-07T16:00:00.000+00:00/2021-12-07T16:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-07T16:30:00.000+00:00/2021-12-07T17:00:00.000+00:00",
                            },
                        ],
                    },
                    {
                        id: "T2ZmZXI6NDU4NDI=",
                        published: true,
                        orderRange: "2021-12-07T23:00:00.000+00:00/2021-12-08T17:00:00.000+00:00",
                        withdrawRange: "2021-12-08T06:30:00.000+00:00/2021-12-08T17:00:00.000+00:00",
                        maxOrdersPerSlot: 4,
                        fullyBooked: false,
                        slots: [
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-08T06:30:00.000+00:00/2021-12-08T07:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-08T07:00:00.000+00:00/2021-12-08T07:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-08T07:30:00.000+00:00/2021-12-08T08:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-08T08:00:00.000+00:00/2021-12-08T08:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-08T08:30:00.000+00:00/2021-12-08T09:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-08T09:00:00.000+00:00/2021-12-08T09:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-08T09:30:00.000+00:00/2021-12-08T10:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-08T10:00:00.000+00:00/2021-12-08T10:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-08T10:30:00.000+00:00/2021-12-08T11:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-08T11:00:00.000+00:00/2021-12-08T11:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-08T11:30:00.000+00:00/2021-12-08T12:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-08T12:00:00.000+00:00/2021-12-08T12:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-08T12:30:00.000+00:00/2021-12-08T13:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-08T13:00:00.000+00:00/2021-12-08T13:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-08T13:30:00.000+00:00/2021-12-08T14:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-08T14:00:00.000+00:00/2021-12-08T14:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-08T14:30:00.000+00:00/2021-12-08T15:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-08T15:00:00.000+00:00/2021-12-08T15:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-08T15:30:00.000+00:00/2021-12-08T16:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-08T16:00:00.000+00:00/2021-12-08T16:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-08T16:30:00.000+00:00/2021-12-08T17:00:00.000+00:00",
                            },
                        ],
                    },
                    {
                        id: "T2ZmZXI6NDU4NDc=",
                        published: true,
                        orderRange: "2021-12-08T23:00:00.000+00:00/2021-12-09T17:00:00.000+00:00",
                        withdrawRange: "2021-12-09T06:30:00.000+00:00/2021-12-09T17:00:00.000+00:00",
                        maxOrdersPerSlot: 4,
                        fullyBooked: false,
                        slots: [
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-09T06:30:00.000+00:00/2021-12-09T07:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-09T07:00:00.000+00:00/2021-12-09T07:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-09T07:30:00.000+00:00/2021-12-09T08:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-09T08:00:00.000+00:00/2021-12-09T08:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-09T08:30:00.000+00:00/2021-12-09T09:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-09T09:00:00.000+00:00/2021-12-09T09:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-09T09:30:00.000+00:00/2021-12-09T10:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-09T10:00:00.000+00:00/2021-12-09T10:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-09T10:30:00.000+00:00/2021-12-09T11:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-09T11:00:00.000+00:00/2021-12-09T11:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-09T11:30:00.000+00:00/2021-12-09T12:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-09T12:00:00.000+00:00/2021-12-09T12:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-09T12:30:00.000+00:00/2021-12-09T13:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-09T13:00:00.000+00:00/2021-12-09T13:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-09T13:30:00.000+00:00/2021-12-09T14:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-09T14:00:00.000+00:00/2021-12-09T14:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-09T14:30:00.000+00:00/2021-12-09T15:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-09T15:00:00.000+00:00/2021-12-09T15:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-09T15:30:00.000+00:00/2021-12-09T16:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-09T16:00:00.000+00:00/2021-12-09T16:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-09T16:30:00.000+00:00/2021-12-09T17:00:00.000+00:00",
                            },
                        ],
                    },
                    {
                        id: "T2ZmZXI6NDU4NjE=",
                        published: true,
                        orderRange: "2021-12-09T23:00:00.000+00:00/2021-12-10T17:00:00.000+00:00",
                        withdrawRange: "2021-12-10T06:30:00.000+00:00/2021-12-10T17:00:00.000+00:00",
                        maxOrdersPerSlot: 4,
                        fullyBooked: false,
                        slots: [
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-10T06:30:00.000+00:00/2021-12-10T07:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-10T07:00:00.000+00:00/2021-12-10T07:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-10T07:30:00.000+00:00/2021-12-10T08:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-10T08:00:00.000+00:00/2021-12-10T08:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-10T08:30:00.000+00:00/2021-12-10T09:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-10T09:00:00.000+00:00/2021-12-10T09:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-10T09:30:00.000+00:00/2021-12-10T10:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-10T10:00:00.000+00:00/2021-12-10T10:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-10T10:30:00.000+00:00/2021-12-10T11:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-10T11:00:00.000+00:00/2021-12-10T11:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-10T11:30:00.000+00:00/2021-12-10T12:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-10T12:00:00.000+00:00/2021-12-10T12:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-10T12:30:00.000+00:00/2021-12-10T13:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-10T13:00:00.000+00:00/2021-12-10T13:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-10T13:30:00.000+00:00/2021-12-10T14:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-10T14:00:00.000+00:00/2021-12-10T14:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-10T14:30:00.000+00:00/2021-12-10T15:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-10T15:00:00.000+00:00/2021-12-10T15:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-10T15:30:00.000+00:00/2021-12-10T16:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-10T16:00:00.000+00:00/2021-12-10T16:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-10T16:30:00.000+00:00/2021-12-10T17:00:00.000+00:00",
                            },
                        ],
                    },
                    {
                        id: "T2ZmZXI6NDU4NTc=",
                        published: true,
                        orderRange: "2021-12-10T23:00:00.000+00:00/2021-12-11T17:00:00.000+00:00",
                        withdrawRange: "2021-12-11T06:30:00.000+00:00/2021-12-11T17:00:00.000+00:00",
                        maxOrdersPerSlot: 4,
                        fullyBooked: false,
                        slots: [
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-11T06:30:00.000+00:00/2021-12-11T07:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-11T07:00:00.000+00:00/2021-12-11T07:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-11T07:30:00.000+00:00/2021-12-11T08:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-11T08:00:00.000+00:00/2021-12-11T08:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-11T08:30:00.000+00:00/2021-12-11T09:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-11T09:00:00.000+00:00/2021-12-11T09:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-11T09:30:00.000+00:00/2021-12-11T10:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-11T10:00:00.000+00:00/2021-12-11T10:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-11T10:30:00.000+00:00/2021-12-11T11:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-11T11:00:00.000+00:00/2021-12-11T11:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-11T11:30:00.000+00:00/2021-12-11T12:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-11T12:00:00.000+00:00/2021-12-11T12:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-11T12:30:00.000+00:00/2021-12-11T13:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-11T13:00:00.000+00:00/2021-12-11T13:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-11T13:30:00.000+00:00/2021-12-11T14:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-11T14:00:00.000+00:00/2021-12-11T14:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-11T14:30:00.000+00:00/2021-12-11T15:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-11T15:00:00.000+00:00/2021-12-11T15:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-11T15:30:00.000+00:00/2021-12-11T16:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-11T16:00:00.000+00:00/2021-12-11T16:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-11T16:30:00.000+00:00/2021-12-11T17:00:00.000+00:00",
                            },
                        ],
                    },
                    {
                        id: "T2ZmZXI6NDU4NTg=",
                        published: true,
                        orderRange: "2021-12-11T23:00:00.000+00:00/2021-12-12T17:00:00.000+00:00",
                        withdrawRange: "2021-12-12T06:30:00.000+00:00/2021-12-12T17:00:00.000+00:00",
                        maxOrdersPerSlot: 4,
                        fullyBooked: false,
                        slots: [
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-12T06:30:00.000+00:00/2021-12-12T07:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-12T07:00:00.000+00:00/2021-12-12T07:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-12T07:30:00.000+00:00/2021-12-12T08:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-12T08:00:00.000+00:00/2021-12-12T08:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-12T08:30:00.000+00:00/2021-12-12T09:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-12T09:00:00.000+00:00/2021-12-12T09:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-12T09:30:00.000+00:00/2021-12-12T10:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-12T10:00:00.000+00:00/2021-12-12T10:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-12T10:30:00.000+00:00/2021-12-12T11:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-12T11:00:00.000+00:00/2021-12-12T11:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-12T11:30:00.000+00:00/2021-12-12T12:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-12T12:00:00.000+00:00/2021-12-12T12:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-12T12:30:00.000+00:00/2021-12-12T13:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-12T13:00:00.000+00:00/2021-12-12T13:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-12T13:30:00.000+00:00/2021-12-12T14:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-12T14:00:00.000+00:00/2021-12-12T14:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-12T14:30:00.000+00:00/2021-12-12T15:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-12T15:00:00.000+00:00/2021-12-12T15:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-12T15:30:00.000+00:00/2021-12-12T16:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-12T16:00:00.000+00:00/2021-12-12T16:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-12T16:30:00.000+00:00/2021-12-12T17:00:00.000+00:00",
                            },
                        ],
                    },
                    {
                        id: "T2ZmZXI6NDU4NzM=",
                        published: true,
                        orderRange: "2021-12-12T23:00:00.000+00:00/2021-12-13T17:00:00.000+00:00",
                        withdrawRange: "2021-12-13T06:30:00.000+00:00/2021-12-13T17:00:00.000+00:00",
                        maxOrdersPerSlot: 4,
                        fullyBooked: false,
                        slots: [
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-13T06:30:00.000+00:00/2021-12-13T07:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-13T07:00:00.000+00:00/2021-12-13T07:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-13T07:30:00.000+00:00/2021-12-13T08:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-13T08:00:00.000+00:00/2021-12-13T08:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-13T08:30:00.000+00:00/2021-12-13T09:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-13T09:00:00.000+00:00/2021-12-13T09:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-13T09:30:00.000+00:00/2021-12-13T10:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-13T10:00:00.000+00:00/2021-12-13T10:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-13T10:30:00.000+00:00/2021-12-13T11:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-13T11:00:00.000+00:00/2021-12-13T11:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-13T11:30:00.000+00:00/2021-12-13T12:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-13T12:00:00.000+00:00/2021-12-13T12:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-13T12:30:00.000+00:00/2021-12-13T13:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-13T13:00:00.000+00:00/2021-12-13T13:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-13T13:30:00.000+00:00/2021-12-13T14:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-13T14:00:00.000+00:00/2021-12-13T14:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-13T14:30:00.000+00:00/2021-12-13T15:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-13T15:00:00.000+00:00/2021-12-13T15:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-13T15:30:00.000+00:00/2021-12-13T16:00:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-13T16:00:00.000+00:00/2021-12-13T16:30:00.000+00:00",
                            },
                            {
                                selectableAt: true,
                                numOrders: 40,
                                withdrawRange: "2021-12-13T16:30:00.000+00:00/2021-12-13T17:00:00.000+00:00",
                            },
                        ],
                    },
                ],
            },
        ],
    },
};
