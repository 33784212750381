import React, { useMemo } from "react";
import { Pressable, StyleSheet, Text } from "react-native";
import { Colors, Spacing } from "@constants";

interface IProps {
  text: string;
  forwardTestID?: string;
  isSelected: boolean;
  onPress?: () => void | null | undefined;
}

export const Tag: React.FC<IProps> = React.memo(
  ({ forwardTestID, text, isSelected, onPress }) => {
    const styles = useMemo(() => _styles(!!isSelected, !onPress), [
      isSelected,
      !onPress,
    ]);
    return (
      <Pressable
        testID={forwardTestID}
        style={styles.container}
        onPress={onPress}
      >
        <Text style={styles.text}>{text}</Text>
      </Pressable>
    );
  }
);

const _styles = (isSelected: boolean, isDisabled: boolean) =>
  StyleSheet.create({
    container: {
      justifyContent: "center",
      paddingHorizontal: Spacing.L,
      paddingVertical: 4,
      borderWidth: 0.5,
      borderColor: isDisabled
        ? Colors.footerBackgroundGray
        : isSelected
        ? Colors.middleGreen
        : Colors.foodiBlack,
      borderRadius: 4,
      backgroundColor: isDisabled
        ? Colors.footerBackgroundGray
        : isSelected
        ? Colors.middleGreen
        : Colors.transparent,
    },
    text: {
      color: isDisabled
        ? Colors.foodiBlack
        : isSelected
        ? Colors.white
        : Colors.foodiBlack,
      fontFamily: "manrope-regular",
      fontSize: 13,
      letterSpacing: 0,
      lineHeight: 18,
      textAlign: "center",
    },
  });
