"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_PICKUP_POINTS = void 0;
const client_1 = require("@apollo/client");
exports.GET_PICKUP_POINTS = client_1.gql `
  query getPickUpPointsWithSelected($idHolding: ID, $idOfferTemplate: ID, $search: String) {
    pickUpPointsWithSelected(idHolding:$idHolding, idOfferTemplate:$idOfferTemplate, search:$search ){
          id
          numericId
          idHolding
          name
          withdrawalSchedule
          companyCode
          description
          selected
    }
  }
`;
