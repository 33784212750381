"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetEpointageSchools = void 0;
class GetEpointageSchools {
    constructor(epointageRepository) {
        this.epointageRepository = epointageRepository;
    }
    execute(request) {
        return this.epointageRepository.getEpointageSchools(request);
    }
}
exports.GetEpointageSchools = GetEpointageSchools;
