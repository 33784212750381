"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ordersListFixtures = void 0;
exports.ordersListFixtures = {
    "7/2022": [
        {
            __typename: "Order",
            id: "Order:4",
            //@ts-ignore
            state: "ACCEPTED",
            idWithdrawal: 100,
            created: "2015-10-21T12:05:00.000Z",
            updated: "2015-10-21T12:30:00.000Z",
            offerTemplateName: "Commande a table",
            withdrawRange: null,
            cancelableUntil: null,
            //@ts-ignore
            paymentMethod: "BADGE",
            totalPrice: {
                amount: "7.00",
                currency: "EUR"
            },
            offer: {
                id: "Offer:4",
                published: true,
                withdrawRange: null,
                offerTemplate_v2: {
                    id: "TableServiceOfferTemplate:4",
                    //@ts-ignore
                    withdrawalType: "TABLE_SERVICE",
                    //@ts-ignore
                    pos: {
                        id: "Pos:1092",
                        name: "LES NOUVEAUX ARTISANS"
                    },
                    //@ts-ignore
                    type: "TABLE_SERVICE"
                }
            }
        },
        {
            __typename: "Order",
            id: "Order:3",
            //@ts-ignore
            state: "ACCEPTED",
            idWithdrawal: 101,
            created: "2019-05-20T12:21:00.000Z",
            updated: "2019-05-20T12:25:00.000Z",
            offerTemplateName: "Daily Monop",
            withdrawRange: "2019-05-20T13:00:00.000+00:00/2019-05-20T13:15:00.000+00:00",
            cancelableUntil: "2019-05-20T12:45:00.000Z",
            //@ts-ignore
            paymentMethod: "BADGE",
            totalPrice: {
                amount: "7.00",
                currency: "EUR"
            },
            offer: {
                id: "Offer:2",
                published: true,
                withdrawRange: "2023-01-11T06:00:00.000+00:00/2023-01-11T22:00:00.000+00:00",
                offerTemplate_v2: {
                    id: "OfferTemplate:2",
                    //@ts-ignore
                    withdrawalType: "TABLE_SERVICE",
                    //@ts-ignore
                    pos: {
                        id: "Pos:1092",
                        name: "LES NOUVEAUX ARTISANS"
                    },
                    //@ts-ignore
                    type: "CLICK_COLLECT"
                }
            }
        }
    ]
};
