import React from "react";
import { Svg, G, Path } from '@react-pdf/renderer';

interface IProps {
    isPdf?: boolean;
    size?: number;
}

export const Time: React.FC<IProps> = ({ isPdf = false, size = 20 }) => isPdf ? (
    <Svg width={`${size}px`} height={`${size}px`} viewBox="0 0 20 20">
        <G stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <Path d="M13.3075,14.3425 L14.32,13.33 L10.7425,9.73 L10.7425,5.2075 L9.3925,5.2075 L9.3925,10.27 L13.3075,14.3425 Z M10,19 C8.77,19 7.6075,18.76375 6.5125,18.29125 C5.4175,17.81875 4.46125,17.17375 3.64375,16.35625 C2.82625,15.53875 2.18125,14.5825 1.70875,13.4875 C1.23625,12.3925 1,11.23 1,10 C1,8.77 1.23625,7.6075 1.70875,6.5125 C2.18125,5.4175 2.82625,4.46125 3.64375,3.64375 C4.46125,2.82625 5.4175,2.18125 6.5125,1.70875 C7.6075,1.23625 8.77,1 10,1 C11.23,1 12.3925,1.23625 13.4875,1.70875 C14.5825,2.18125 15.53875,2.82625 16.35625,3.64375 C17.17375,4.46125 17.81875,5.4175 18.29125,6.5125 C18.76375,7.6075 19,8.77 19,10 C19,11.23 18.76375,12.3925 18.29125,13.4875 C17.81875,14.5825 17.17375,15.53875 16.35625,16.35625 C15.53875,17.17375 14.5825,17.81875 13.4875,18.29125 C12.3925,18.76375 11.23,19 10,19 Z M10,17.65 C12.1,17.65 13.9,16.9 15.4,15.4 C16.9,13.9 17.65,12.1 17.65,10 C17.65,7.9 16.9,6.1 15.4,4.6 C13.9,3.1 12.1,2.35 10,2.35 C7.9,2.35 6.1,3.1 4.6,4.6 C3.1,6.1 2.35,7.9 2.35,10 C2.35,12.1 3.1,13.9 4.6,15.4 C6.1,16.9 7.9,17.65 10,17.65 Z" fill="#090909" fill-rule="nonzero"></Path>
        </G>
    </Svg>
) : (
    <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="Icons/Time_black" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M13.3075,14.3425 L14.32,13.33 L10.7425,9.73 L10.7425,5.2075 L9.3925,5.2075 L9.3925,10.27 L13.3075,14.3425 Z M10,19 C8.77,19 7.6075,18.76375 6.5125,18.29125 C5.4175,17.81875 4.46125,17.17375 3.64375,16.35625 C2.82625,15.53875 2.18125,14.5825 1.70875,13.4875 C1.23625,12.3925 1,11.23 1,10 C1,8.77 1.23625,7.6075 1.70875,6.5125 C2.18125,5.4175 2.82625,4.46125 3.64375,3.64375 C4.46125,2.82625 5.4175,2.18125 6.5125,1.70875 C7.6075,1.23625 8.77,1 10,1 C11.23,1 12.3925,1.23625 13.4875,1.70875 C14.5825,2.18125 15.53875,2.82625 16.35625,3.64375 C17.17375,4.46125 17.81875,5.4175 18.29125,6.5125 C18.76375,7.6075 19,8.77 19,10 C19,11.23 18.76375,12.3925 18.29125,13.4875 C17.81875,14.5825 17.17375,15.53875 16.35625,16.35625 C15.53875,17.17375 14.5825,17.81875 13.4875,18.29125 C12.3925,18.76375 11.23,19 10,19 Z M10,17.65 C12.1,17.65 13.9,16.9 15.4,15.4 C16.9,13.9 17.65,12.1 17.65,10 C17.65,7.9 16.9,6.1 15.4,4.6 C13.9,3.1 12.1,2.35 10,2.35 C7.9,2.35 6.1,3.1 4.6,4.6 C3.1,6.1 2.35,7.9 2.35,10 C2.35,12.1 3.1,13.9 4.6,15.4 C6.1,16.9 7.9,17.65 10,17.65 Z" id="Shape" fill="#090909" fill-rule="nonzero"></path>
        </g>
    </svg>
);
