import { IScolapassInvoice, IScolapassPayment } from "@foodi/core";
import _ from "lodash";
import { I18n } from "react-redux-i18n";

export enum IScolapassPaymentType {
  CB = "CB",
  CF = "CF",
  CQ = "CQ",
  VI = "VI",
  CI = "CI",
  ES = "ES",
  ME = "ME",
  PI = "PI",
  PR = "PR",
  RS = "RS",
}

export interface IHistoricalType {
  title: string;
  isSelected: boolean;
}

export interface IHistoric {
  id: number;
  familyId: string;
  paymentType?: string | null;
  date: any | null;
  amount: number | null;
  pdfFileName?: string;
  invoiceNumber?: number;
}

export class HistoricalViewModel {
  payments: IScolapassPayment[] = [];
  invoices: IScolapassInvoice[] = [];
  currentHistoric: IHistoric[] = [];
  tabs: IHistoricalType[] = [
    {
      title: I18n.t("refill.invoices"),
      isSelected: true,
    },
    {
      title: I18n.t("refill.payments"),
      isSelected: false,
    },
  ];

  setPayments(payments: IScolapassPayment[]) {
    this.payments = payments;
    this.sortPayments();
  }

  setInvoice(invoices: IScolapassInvoice[]) {
    this.invoices = invoices;
    this.sortInvoices();
  }

  private sortPayments() {
    this.payments.sort(this.sortArrayByDate);
  }
  private sortInvoices() {
    this.invoices.sort(this.sortArrayByDate);
  }

  private sortArrayByDate(paymentA: any, paymentB: any) {
    const date1 = new Date(paymentA.date);
    const date2 = new Date(paymentB.date);
    const diffDays = date2.getTime() - date1.getTime();
    return diffDays;
  }

  filterHistoric(tabIndex: number) {
    let filteredHistoric = this.currentHistoric;
    if (tabIndex === 0) filteredHistoric = this.invoices;
    if (tabIndex === 1) filteredHistoric = this.payments;
    this.currentHistoric = filteredHistoric;
  }

  public getGroupedHistoric() {
    return this.currentHistoric.reduce<Record<string, IHistoric[]>>(
      (groupHistoric, historic) => {
        const paymentDate = new Date(historic.date);
        const monthAndYear =
          paymentDate.getMonth() + "/" + paymentDate.getFullYear();
        const group = groupHistoric[monthAndYear] || [];
        group.push(historic);
        groupHistoric[monthAndYear] = group;
        return groupHistoric;
      },
      {}
    );
  }

  public getInitHistoricalTabs(): IHistoricalType[] {
    return this.tabs;
  }

  public getTranslatedHistoricalTabs(currentTabs: IHistoricalType[]): IHistoricalType[] {
    return [
      {
        ...currentTabs[0],
        title: I18n.t("refill.invoices"),
      },
      {
        ...currentTabs[1],
        title: I18n.t("refill.payments"),
      }
    ]
  }

  public getHistoricalTabs(tabIndex: number): IHistoricalType[] {
    const _tabs = this.tabs.map((tab, index) => {
      return { ...tab, isSelected: tabIndex === index };
    });
    return _tabs;
  }

  public checkIfDateHaveTime(historicDate: string): boolean {
    const getTime = historicDate?.split("T")?.[1] ?? "0000:00.000Z";
    const numericTime = parseInt(getTime.replace(":", ""));
    return numericTime > 0;
  }

  public getTypeOfPaymentLabel(paymentType: string | null): string {
    let paymentLabel = I18n.t("refill.paymentType_unknown");
    if (
      Object.values(IScolapassPaymentType).includes(
        paymentType as IScolapassPaymentType
      )
    ) {
      paymentLabel = I18n.t(`refill.paymentType_${paymentType}`);
    }

    return paymentLabel;
  }
}
