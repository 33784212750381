import { Title16 } from "@stylesheets";
import React, { useMemo } from "react";
import { Pressable, View, StyleSheet, ScrollView } from "react-native";
import { Icon } from "@atomic/atoms/Icon";
import { IListItem } from "../../screens/Homepage";
import { DropDownSelectRow } from "../index";
import { Colors } from "@constants";
import _ from "lodash";

interface IDropdownProps {
  type: string;
  list: IListItem[];
  selected?: string;
  colorize?: boolean;
  disabled?: boolean;
  selectIdHandler?: Function;
  staysOpen?: boolean;
  handleCheckOption?: (school: IListItem, check: boolean) => void;
  allOptionsText?: string;
  optionsChecked?: IListItem[];
  hasErrors?: boolean;
  isDynamic?: boolean;
}

export const DropDownSelect: React.FC<IDropdownProps> = React.memo(({
  selected,
  type,
  list,
  colorize,
  disabled,
  selectIdHandler,
  staysOpen = false,
  handleCheckOption,
  allOptionsText,
  optionsChecked,
  hasErrors,
  isDynamic
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [canOpen, setCanOpen] = React.useState<boolean>(false);

  const handleOpenDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelection = (props: any) => {
    if (selectIdHandler) {
      selectIdHandler(props);
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    setCanOpen(!!list.length);
  }, [list]);

  const handleOutOfFocus = () => {
    if (!staysOpen) setIsOpen(false);
  };

  const orderedList = _.sortBy(list, (item: IListItem) => item?.name?.toLowerCase());

  const paintWhenClicked = isOpen && staysOpen;

  const styles = useMemo(() => _styles(
    staysOpen, !!selected, hasErrors, isDynamic, disabled
  ), [
    staysOpen, selected, hasErrors, isDynamic, disabled
  ]);

  const handleIsChecked = (id: string) => {
    return optionsChecked?.some((i: IListItem) => i.id === id);
  };

  const isAll = optionsChecked?.length === list.length;

  return (
    <div
      style={{ display: "flex", flexDirection: "column" }}
      onMouseLeave={handleOutOfFocus}
    >
      <Pressable
        style={[
          styles.dropDownSelect,
          styles.borderAfterSelection,
          disabled
            ? styles.unselectable
            : colorize || paintWhenClicked
            ? styles.pressSelect
            : styles.unselectable,
        ]}
        onPress={handleOpenDropdown}
        disabled={disabled}
      >
        <Title16
          color={!disabled ? "#090909" : "#666666"}
          bold={paintWhenClicked}
          numberOfLines={1}
        >
          {selected && selected !== "" ? selected : type}
        </Title16>
        <Icon
          size={12}
          style={styles.icon}
          color={!disabled ? "#090909" : "#666666"}
          name={
            isOpen && !disabled && canOpen
              ? "Accordeon_arrow_open_black"
              : "Accordeon_arrow_default_black"
          }
        />
      </Pressable>
      {isOpen && canOpen && !!list.length && (
        <ScrollView style={styles.dropBoxContainer}>
          {allOptionsText && (
            <>
              <DropDownSelectRow
                key={`all_${isAll}`}
                id="all"
                value={allOptionsText}
                selectHandler={selectIdHandler}
                handleCheckOption={handleCheckOption}
                isChecked={isAll}
              />
              <View style={styles.allOptionsContainer} />
            </>
          )}
          {orderedList.map((item: IListItem) => {
            const isChecked = handleIsChecked(item.id);
            return (
              <DropDownSelectRow
                key={`${item.id}_${isChecked}`}
                id={item.id}
                value={item.name}
                selectHandler={handleSelection}
                handleCheckOption={handleCheckOption}
                isChecked={isChecked}
              />
            )
          })}
        </ScrollView>
      )}
    </div>
  );
});

const _styles = (
  staysOpen?: boolean, selected?: boolean, hasErrors?: boolean, isDynamic?: boolean, disabled?: boolean
) =>
  StyleSheet.create({
    dropDownSelect: {
      borderColor: disabled ? Colors.gray1 : hasErrors ? Colors.redError : Colors.foodiBlack,
      borderWidth: 1,
      height: 32,
      borderRadius: 10,
      padding: 5,
      paddingLeft: 20,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    unselectable: {
      backgroundColor: "#F2F2F2",
    },
    icon: {
      padding: 5,
      paddingLeft: 10,
      fontSize: 12,
    },
    dropBoxContainer: {
      maxHeight: (isDynamic || staysOpen) ? 245 : 'unset',
      width: staysOpen ? "100%" : "max-content",
      position: "absolute",
      borderColor: "#090909",
      borderStyle: "solid",
      borderWidth: 1,
      borderRadius: 10,
      backgroundColor: "white",
      paddingTop: 5,
      paddingBottom: 5,
      top: 32,
    },
    allOptionsContainer: {
      boxSizing: 'border-box',
      height: 1,
      backgroundColor: Colors.darkGrey,
      marginHorizontal: 20,
      marginVertical: 2.5
    },
    pressSelect: {
      backgroundColor: Colors.foodiDefault,
      borderWidth: 0,
    },
    borderAfterSelection: staysOpen && selected ? {
      boxSizing: 'border-box',
      border: `2px solid ${Colors.foodiDefault}`,
      borderRadius: 8,
    } : {}
  });
