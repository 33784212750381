import { TestIDs } from "@utils";
import React, { useMemo } from "react";
import { View, Pressable, StyleSheet } from "react-native";
import { I18n } from "react-redux-i18n";
import { Container, Text } from "./CGU.style";
import { useDevices } from "@hooks";

interface IProps {
  pdfUrl?: string;
}

export const CGU: React.FC<IProps> = React.memo(({ pdfUrl }) => {
  const [isMobile] = useDevices();
  const styles = useMemo(() => _styles(isMobile), [isMobile]);

  const handleCGU = () => {
    window.open(pdfUrl || window.config.CGV, "_blank")?.focus();
  };

  return (
    <Container testID={TestIDs.components.CGU.views.container}>
      <View style={styles.container}>
        <Pressable
          testID={TestIDs.components.CGU.actions.press}
          onPress={handleCGU}
        >
          <Text
            testID={TestIDs.components.CGU.texts.cguText}
            style={styles.cguText}
          >
            {`${I18n.t("restaurantDetail.cart.cguText")} `}
            <Text underline>
              {I18n.t("restaurantDetail.cart.termsOfSales")}
            </Text>
            .
          </Text>
        </Pressable>
      </View>
    </Container>
  );
});

const _styles = (isMobile: boolean) =>
  StyleSheet.create({
    container: {},
    cguText: {
      flexDirection: "row",
      width: isMobile ? "80vw" : "259px",
    },
  });
