import style from 'styled-components/native';

export const Container = style.View`
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
`;

export const AlertMessageContainer = style.View`
  align-items: center;
  justify-content: center;
  margin: 15px 0;
`;

export const AlertMessageText = style.Text`
  font-family: manrope-regular;
  line-height: 14px;
  font-size: 13px
  text-align: center;
`;

export const Row = style.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const PickupPointsNoResults = style.Text`
  font-family: manrope-regular;
  line-height: 20px;
  font-size: 17px;
  margin-bottom: 20px
  margin-top: 10px
`;
