import { TestIDs } from "@utils";
import React from "react";
import { ViewStyle, View } from "react-native";
import { Container, Content } from "./RadioGroup.style";

interface RadioButtonProps {
  id: string;
}

interface IProps {
  radioButtonsProps: RadioButtonProps[];
  value?: string;
  style?: ViewStyle;
  onPress: (id: string) => void;
}

export const RadioGroup: React.FC<IProps> = React.memo(
  ({ radioButtonsProps, value, style, onPress }) => (
    <View testID={TestIDs.components.RadioGroup.views.container} style={style}>
      {radioButtonsProps?.map((r, index) => (
        <Container
          key={`${r.id}_${index}`}
          testID={`${TestIDs.components.RadioGroup.actions.press}_${index}`}
          onPress={() => onPress(r.id)}
        >
          {r.id === value && <Content />}
        </Container>
      ))}
    </View>
  )
);
