import { Colors } from "@constants";
import style from "styled-components/native";

export const Container = style.View`
    margin-bottom: 50px;
    margin-top: 20px;
`;

export const ButtonContainer = style.View<{ isMobile?: boolean }>`
    margin-top: ${({isMobile}) => (isMobile ? "10px" : "20px")};
    flex-direction:  ${({ isMobile }) => (isMobile ? "column" : "row")};
    align-self: center;
    align-items: center;
`;

export const Button = style.TouchableOpacity<{
  color?: string;
  hasBorder?: boolean;
}>`
    justify-content: center;
    background-color: ${({ color }) => color};
    margin: 10px;
    border-radius: 8px;
    ${({ hasBorder }) => hasBorder && "border: 1px"}
`;

export const Label = style.Text<{
    textColor?: string;
  }>`
    font-family: manrope-bold;
    font-size: 22px;
    padding: 15px 54px;
    text-align: center;
    color: ${({ textColor }) => textColor ?? "#000"};
`;
