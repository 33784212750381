"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_BOOKING_OFFER_TEMPLATES = void 0;
const client_1 = require("@apollo/client");
exports.GET_BOOKING_OFFER_TEMPLATES = client_1.gql `
  query getBookingTemplateOffer($idPos: String!) {
    pos: search(
      type: "Pos"
      querySearch: [{ key: "id", value: $idPos, operator: "=" }]
    ) {
      ... on Pos {
        id
        name
        schedules {
          days
          hours
        }
        zone {
          id
          idHolding
        }
        bookingOfferTemplate {
          id
          name
          period
          published
          timezone
          withdrawStartDefault
          withdrawEndDefault
          maxOrdersPerSlotDefault
          withdrawSlotDuration
          nextOrderableOffers {
            id
            published
            orderRange
            withdrawRange
            maxOrdersPerSlot
            fullyBooked
            slots: upcomingSlots {
              selectableAt
              withdrawRange
              numOrders
            }
          }
        }
      }
    }
  }
`;
