"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_BALANCE = void 0;
const client_1 = require("@apollo/client");
const balanceFragment_1 = require("./balanceFragment");
exports.GET_BALANCE = client_1.gql `
  ${balanceFragment_1.GET_BALANCE_FRAGMENT}
  query getBalance {
    getUser {
      id
      guests {
        edges {
          node {
            ...balanceFragment
          }
        }
      }
    }
  }
`;
