import React, { useEffect, useState } from "react";
import { Colors } from "@constants";
import { Container, Quantity, Info } from "./MiniCart.style";
import { OrderItem } from "@foodi/core";
import { I18n } from "react-redux-i18n";
import { TestIDs } from "@utils";
import { GlobalState } from "@redux";
import { useDispatch, useSelector } from "react-redux";
import {
  isHeartMealsRuleValidSelector,
  OffersActions,
  OfferViewModel,
  PointOfSaleActions,
} from "@modules";
import { Title18 } from "@stylesheets";
import { Pressable } from "react-native";
import { NavigationProp } from "@react-navigation/native";

interface IProps {
  navigation: NavigationProp<any>;
  isRefillFromCart?: boolean;
}

export const MiniCart: React.FC<IProps> = React.memo(() => {
  const [quantity, setQuantity] = useState(0);
  const [formattedTotalPrice, setFormattedTotalPrice] = useState("0,00€");

  const orderItems = useSelector(
    (state: GlobalState) => state.offers.orderItems
  );

  const [offerVM] = useState(new OfferViewModel());
  const dispatch = useDispatch();

  const isHeartMealsRuleValid = useSelector(isHeartMealsRuleValidSelector);

  const calculateQuantity = () => {
    const quantity = orderItems
      .map((item) => item.quantity)
      .reduce((firstItem, secondItem) => {
        return firstItem + secondItem;
      }, 0);
    setQuantity(quantity);
  };

    const calculateTotal = () => {
      let totalPrice: number = 0;
      let totalContainerPrice: number = 0;
      orderItems.map((p: OrderItem, index: number) => {
        const amount = offerVM.getProductAmount(p.priceWhenAdded.amount);
        const containerAmount = offerVM.getContainerAmount(
          p.containerPriceWhenAdded
            ? p.containerPriceWhenAdded.amount
            : ""
        );
        totalPrice += amount * p.quantity;
        totalContainerPrice += containerAmount * p.quantity;
        setFormattedTotalPrice(`${(totalPrice + totalContainerPrice).toFixed(2).replace(".", ",")}€`);
      });

    if (orderItems.length === 0) setFormattedTotalPrice("0,00€");
  };

  const handlePress = () => {
    if (quantity === 0 || !isHeartMealsRuleValid) return;
    dispatch(PointOfSaleActions.setFullCartStatus(true));
  };

  useEffect(() => {
    return () => {
      dispatch(PointOfSaleActions.setMiniCartStatus(false));
      dispatch(OffersActions.initOrderItems());
    };
  }, []);

  useEffect(() => {
    calculateQuantity();
    calculateTotal();
  }, [orderItems]);

  let buttonText = "";

  if (orderItems.length > 0 && isHeartMealsRuleValid) {
    buttonText = I18n.t("restaurantDetail.miniCart.goCart");
  } else {
    buttonText = I18n.t("restaurantDetail.miniCart.addProduct");
  }

  return (
    <Container testID={TestIDs.restaurantDetail.views.miniCartContainer}>
      <Info>
        <Quantity>
          <Title18 isBold style={{ color: Colors.white }}>
            {quantity}
          </Title18>
        </Quantity>
        {orderItems.length > 0 && (
          <Title18 isBold style={{ color: Colors.white }}>
            {formattedTotalPrice}
          </Title18>
        )}
      </Info>
      <Pressable
        testID={TestIDs.restaurantDetail.actions.openFullCart}
        onPress={handlePress}
      >
        <Title18 style={{ color: Colors.white }}>{buttonText}</Title18>
      </Pressable>
    </Container>
  );
});
