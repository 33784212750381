import React from "react";

export const Forward: React.FC = () => (
  <svg width="24px" height="24px" xmlns="http://www.w3.org/2000/svg">
    <title>Icons/Navigation/Arrows/Forward</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        fill="#000"
        fillRule="nonzero"
        d="M10 6 8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"
      />
    </g>
  </svg>
);
