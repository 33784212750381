import useIntersectionObserver from "@hooks/useIntersectionObserver";
import React, { useEffect, useRef, useState } from "react";
import { View, ViewStyle } from "react-native";

interface IProps {
  handleIsVisible: (isVisible: boolean) => void;
  freeze?: boolean;
  children?: React.ReactNode;
  style?: ViewStyle;
}

export const OnScreenCheck: React.FC<IProps> = ({
  handleIsVisible,
  freeze,
  children,
  style,
}) => {
  const ref = useRef(null);
  const entry = useIntersectionObserver(ref, { freezeOnceVisible: freeze });
  const isVisible = !!entry?.isIntersecting;
  const [handleVisible, setHandleVisible] = useState(isVisible);

  useEffect(() => {
    if (handleVisible !== isVisible) {
      handleIsVisible(isVisible);
      setHandleVisible(isVisible);
    }
  }, [isVisible]);

  return (
    <View ref={ref} style={style}>
      {children}
    </View>
  );
};
