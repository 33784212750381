"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CHECK_TABLES_AVAILABILITY = void 0;
const client_1 = require("@apollo/client");
exports.CHECK_TABLES_AVAILABILITY = client_1.gql `
  query getServices(
    $idPos: String!
  ) {
    checkTablesAvailability(idPos: $idPos) {
      idPos
      label
      tables {
        status
        tableNumber
      }
    }
  }
`;
