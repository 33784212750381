"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_EPOINTAGE_SCHOOLS = void 0;
const client_1 = require("@apollo/client");
exports.GET_EPOINTAGE_SCHOOLS = client_1.gql `
query getEPointageSchools ($siteId: String!){
    list: getEPointageSchools (siteId: $siteId){
        siteId
        schoolId
        name
    }
}  
`;
