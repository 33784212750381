import { Action } from "../action";
import { createReducer } from "../createReducer";

export enum ROUTES {
  AUTH = "Auth",
  HOME = "HOME",
}

export enum SplashScreenState {
  NOT_PLAYED = "NOT_PLAYED",
  PLAYING = "PLAYING",
  PLAYED = "PLAYED",
}

export interface NavigationState {
  route: ROUTES;
  isInitialLinkHandled: boolean;
  splashScreenState: SplashScreenState;
}

/*************  Actions  ****************/

const ActionTypes = {
  SET_ROUTE: "navigation/SET_ROUTE",
  SET_IS_INITIAL_LINK_HANDLED: "navigation/SET_IS_INITIAL_LINK_HANDLED",
  SET_SPLASH_SCREEN_STATE: "navigation/SET_SPLASH_SCREEN_STATE",
};

const ActionCreators = {
  setRoute: (route: ROUTES): Action<ROUTES> => ({
    type: ActionTypes.SET_ROUTE,
    payload: route,
  }),

  setIsInitialLinkHandled: (isHandled: boolean): Action<boolean> => ({
    type: ActionTypes.SET_IS_INITIAL_LINK_HANDLED,
    payload: isHandled,
  }),

  setSplashScreenState: (
    state: SplashScreenState
  ): Action<SplashScreenState> => ({
    type: ActionTypes.SET_SPLASH_SCREEN_STATE,
    payload: state,
  }),
};

/*************  Side Effects, only if applicable ****************/
// e.g. thunks, epics, etc
const ThunkActionCreators = {};

/*************  Reducer  ****************/

const initialState: NavigationState = {
  route: ROUTES.AUTH,
  isInitialLinkHandled: false,
  splashScreenState: SplashScreenState.NOT_PLAYED,
};

const Reduction = {
  setRoute: (
    state: NavigationState,
    { payload: route }: Action<ROUTES>
  ): NavigationState => ({
    ...state,
    route,
  }),

  setIsInitialLinkHandled: (
    state: NavigationState,
    { payload: isHandled }: Action<boolean>
  ): NavigationState => ({
    ...state,
    isInitialLinkHandled: isHandled,
  }),

  setSplashScreenPlayed: (
    state: NavigationState,
    { payload: newSplashScreenState }: Action<SplashScreenState>
  ): NavigationState => ({
    ...state,
    splashScreenState: newSplashScreenState,
  }),
};

const reducer = createReducer(initialState, {
  [ActionTypes.SET_ROUTE]: Reduction.setRoute,
  [ActionTypes.SET_IS_INITIAL_LINK_HANDLED]: Reduction.setIsInitialLinkHandled,
  [ActionTypes.SET_SPLASH_SCREEN_STATE]: Reduction.setSplashScreenPlayed,
});

export default reducer;

export {
  reducer as NavigationReducer,
  ActionTypes as NavigationActionTypes,
  ActionCreators as NavigationActions,
  ThunkActionCreators as NavigationThunks,
};
