import React, { useMemo } from "react";
import {
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
  Text,
} from "react-native";
import { Colors } from "@constants";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "@redux";
import { I18n } from "react-redux-i18n";
import { BookingActions, OffersActions, PointOfSaleActions } from "@modules";
import { EatInSizable } from "@assets";
import { useDevices } from "@hooks";

interface IProps {
  style?: ViewStyle;
  forwardTestID?: string;
  isSelected: boolean;
}

export const BookingOffer: React.FC<IProps> = React.memo(
  ({ style, forwardTestID, isSelected }) => {
    const dispatch = useDispatch();
    const [isMobile] = useDevices();
    const styles = useMemo(() => _styles(isMobile), [isMobile]);

    const onSelect = () => {
      dispatch(BookingActions.setBookingSelected(true));
      dispatch(PointOfSaleActions.setMiniCartStatus(false));
      dispatch(OffersActions.initOrderItems());
    };
    const brandTheme = useSelector(
      (state: GlobalState) => state.brandTheme.brandTheme
    );

    return (
      <TouchableOpacity
        onPress={onSelect}
        testID={forwardTestID}
        style={[
          styles.container,
          style,
          isSelected && {
            borderColor:
              brandTheme.buttonActionColors?.backgroundColor ||
              Colors.foodiDefault,
          },
        ]}
      >
        <View style={styles.titleDiv}>
          <Text style={styles.title} numberOfLines={2} ellipsizeMode="tail">
            {I18n.t("restaurantDetail.booking")}
          </Text>
          <EatInSizable size={30} />
        </View>
      </TouchableOpacity>
    );
  }
);

const _styles = (isMobile: boolean) =>
  StyleSheet.create({
    container: {
      width: isMobile ? 150 : 182,
      height: isMobile ? 116 : 140,
      borderWidth: 5,
      borderRadius: 8,
      marginRight: 5,
      borderColor: "transparent",
      flexDirection: "row",
      justifyContent: "center",
      overflow: "hidden",
    },
    titleDiv: {
      paddingVertical: 5,
      flex: 1,
      backgroundColor: Colors.footerBackgroundGray,
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    },
    title: {
      color: Colors.foodiBlack,
      fontSize: isMobile ? 18 : 22,
      lineHeight: 22,
      textAlign: "center",
      fontFamily: "manrope-bold",
      marginBottom: isMobile ? 5 : 15,
    },
  });
