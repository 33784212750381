import { BookingOrder, Order, OrderState } from "@foodi/core";
import { BookingActions } from "@modules";
import { getWithdrawalDate, isOrderUpToDate } from "@utils";
import moment from "moment";
import { Dispatch } from "react";

export const cancelableOrderStates: string[] = [
  OrderState.ACCEPTED,
  OrderState.CART,
  OrderState.EMPLOYEE_CART,
  OrderState.ON_HOLD,
];

export class ComingSoonViewModel {
  getSortedOrders(anyOrders: (Order | BookingOrder)[]) {
    return anyOrders
      ?.flat()
      ?.sort((a: Order | BookingOrder, b: Order | BookingOrder) => {
        const aRange = getWithdrawalDate(a.withdrawRange);
        const bRange = getWithdrawalDate(b.withdrawRange);

        if (aRange === bRange && a.idWithdrawal && b.idWithdrawal)
          return a.idWithdrawal - b.idWithdrawal;

        return (new Date(aRange) as any) - (new Date(bRange) as any);
      });
  }

  getUpToDateOrders(
    orders: any,
    dispatch?: Dispatch<any>,
    isBooking?: boolean
  ) {
    const res = orders.filter(
      (o: Order | BookingOrder) =>
        o.withdrawRange && isOrderUpToDate(getWithdrawalDate(o.withdrawRange))
    );
    if (isBooking && dispatch) dispatch(BookingActions.setBookingOrders(res));
    return res;
  }

  /**
   * to check if the order is 'inAdvance' we used the created date from the order and compare it with the day of the withdrawal (withdrawRange.start)
   * if the day from the creation of the order is before the day of the order, the order is 'inAdvance'
   */
  isInAdvanceOrder = (order: Order): boolean => (
      moment(order?.created?.split("T")?.[0]) <
      moment(order?.withdrawRange?.split("/")?.[1].split("T")?.[0])
  );

  getFirstHours = (withdrawRange: string) : string => {
    const slots = withdrawRange.split("/");
    const initSlot = slots[0];
    const firstHours = moment(initSlot).format("HH:mm");
    return firstHours;
  };
}
