import {
  BookingOrder,
  GetBookingTemplateOfferCommand,
  NextOrderableOffer,
} from "@foodi/core";
import { CalendarViewModel } from "@modules";

import { createReducer, GlobalState, LoaderActions, ThunkResult } from "@redux";

import { Action } from "@redux/action";
import { displayToastError } from "@utils";
import _ from "lodash";
import { createSelector } from "reselect";
import { BookingPeriod } from "../viewmodels";

export interface BookingOffers extends NextOrderableOffer {
  isSelected?: boolean;
}

export interface BookingState {
  bookingOffers: BookingOffers[];
  selectedDay: number;
  isBookingSelected: boolean;
  bookingOrders: BookingOrder[];
  bookingPeriod: BookingPeriod;
}

/*************  Actions  ****************/

const ActionTypes = {
  SET_BOOKING_OFFERS: "Booking/SET_BOOKING_OFFERS",
  SET_SELECTED_DAY_CALENDAR: "Booking/SET_SELECTED_DAY_CALENDAR",
  SET_IS_BOOKING_SELECTED: "Booking/SET_IS_BOOKING_SELECTED",
  SET_BOOKING_ORDERS: "Booking/SET_BOOKING_ORDERS",
  SET_BOOKING_PERIOD: "Booking/SET_BOOKING_PERIOD",
  INIT_BOOKING_ORDERS: "Booking/INIT_BOOKING_ORDERS",
};

const ActionCreators = {
  setBookingOffers: (bookingOffers: BookingOffers): Action<BookingOffers> => ({
    type: ActionTypes.SET_BOOKING_OFFERS,
    payload: bookingOffers,
  }),
  setSelectedDay: (selectedDay: number): Action<number> => ({
    type: ActionTypes.SET_SELECTED_DAY_CALENDAR,
    payload: selectedDay,
  }),
  setBookingSelected: (isBookingSelected: boolean): Action<boolean> => ({
    type: ActionTypes.SET_IS_BOOKING_SELECTED,
    payload: isBookingSelected,
  }),
  setBookingOrders: (
    bookingOrders: BookingOrder[]
  ): Action<BookingOrder[]> => ({
    type: ActionTypes.SET_BOOKING_ORDERS,
    payload: bookingOrders,
  }),
  setBookingPeriod: (bookingPeriod: BookingPeriod): Action<BookingPeriod> => ({
    type: ActionTypes.SET_BOOKING_PERIOD,
    payload: bookingPeriod,
  }),
  initBookingOrders: (): Action<BookingOrder[]> => ({
    type: ActionTypes.INIT_BOOKING_ORDERS,
    payload: [],
  }),
};

const ThunkActionCreators = {
  getBookingTemplateOffer: (
    params: GetBookingTemplateOfferCommand
  ): ThunkResult<Promise<void>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { getBookingTemplateOffer } = getDependencies();
      const response = await getBookingTemplateOffer.execute(params);
      const bookingOffers =
        _.get(response, "pos.bookingOfferTemplate[0].nextOrderableOffers") ??
        [];
      const bookingPeriod =
        _.get(response, "pos.bookingOfferTemplate[0].period") ??
        BookingPeriod.DAILY;
      dispatch(ActionCreators.setBookingOffers(bookingOffers));
      dispatch(ActionCreators.setBookingPeriod(bookingPeriod));
    } catch (e) {
      displayToastError(dispatch);
      return Promise.reject(e);
    }
  },
};

/*************  Reducer  ****************/

const initialState: BookingState = {
  bookingOffers: [],
  selectedDay: 0,
  isBookingSelected: false,
  bookingOrders: [],
  bookingPeriod: BookingPeriod.DAILY,
};

const Reduction = {
  setBookingOffers: (
    state: BookingState,
    { payload: bookingOffers }: Action<BookingOffers[]>
  ): BookingState => ({
    ...state,
    bookingOffers,
  }),
  setSelectedDay: (
    state: BookingState,
    { payload: selectedDay }: Action<number>
  ): BookingState => ({
    ...state,
    selectedDay,
  }),
  setBookingSelected: (
    state: BookingState,
    { payload: isBookingSelected }: Action<boolean>
  ): BookingState => ({
    ...state,
    isBookingSelected,
  }),
  setBookingOrders: (
    state: BookingState,
    { payload: bookingOrders }: Action<BookingOrder[]>
  ): BookingState => ({
    ...state,
    bookingOrders,
  }),
  setBookingPeriod: (
    state: BookingState,
    { payload: bookingPeriod }: Action<BookingPeriod>
  ): BookingState => ({
    ...state,
    bookingPeriod,
  }),
  initBookingOrders: (
    state: BookingState,
    {}: Action<BookingOrder[]>
  ): BookingState => ({
    ...state,
    bookingOrders: [],
  }),
};
const reducer = createReducer(initialState, {
  [ActionTypes.SET_BOOKING_OFFERS]: Reduction.setBookingOffers,
  [ActionTypes.SET_SELECTED_DAY_CALENDAR]: Reduction.setSelectedDay,
  [ActionTypes.SET_IS_BOOKING_SELECTED]: Reduction.setBookingSelected,
  [ActionTypes.SET_BOOKING_ORDERS]: Reduction.setBookingOrders,
  [ActionTypes.SET_BOOKING_PERIOD]: Reduction.setBookingPeriod,
  [ActionTypes.INIT_BOOKING_ORDERS]: Reduction.initBookingOrders,
});

//SELECTOR

const selectedDay = (state: GlobalState) => state.booking.selectedDay;
const bookingOffers = (state: GlobalState) => state.booking.bookingOffers;

export const selectedBookingOfferSelector = createSelector(
  [bookingOffers, selectedDay],
  (bookingOffers: BookingOffers[], selectedDay: number) => {
    return bookingOffers.find(({ withdrawRange }: BookingOffers) => {
      const selectedDate = CalendarViewModel.getDateAtIndex(
        selectedDay
      ).toString();
      const offerDate = withdrawRange.split("/")[0];
      return CalendarViewModel.isTheSameDay(selectedDate, offerDate);
    });
  }
);

export default reducer;

export {
  reducer as BookingReducer,
  ActionTypes as BookingActionTypes,
  ActionCreators as BookingActions,
  ThunkActionCreators as BookingThunks,
};
