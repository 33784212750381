import { TestIDs } from "@utils";
import React from "react";
import { ButtonContainer } from "./Cart.style";
import { Button } from "@atomic";

interface ICartSubmitButton {
  disabled: boolean;
  onPress: () => void;
  label: string;
  loading: boolean;
}

export const CartSubmitButton: React.FC<ICartSubmitButton> = ({
  disabled,
  label,
  onPress,
  loading,
}) => {
  return (
    <ButtonContainer>
      <Button
        forwardTestID={TestIDs.components.Button.views.container}
        disabled={disabled}
        onPress={onPress}
        label={label}
        loading={loading}
      />
    </ButtonContainer>
  );
};
