"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_ORDER_CC = void 0;
const client_1 = require("@apollo/client");
exports.GET_ORDER_CC = client_1.gql `
  query getOrder2($idOrder: ID!, $paymentMethod: PaymentMethod) {
    # Order:1
    order: get(id: $idOrder) {
      ... on Order {
        id
        created
        cancelableUntil
        cancelableAt
        withdrawRange
        idWithdrawal
        state
        withdrawLocation
        offerTemplateName
        tableNumber
        paymentMethod
        comment
        totalPrice(paymentMethod: $paymentMethod) {
          amount
          currency
        }
        admission {
          amount
          currency
        }
        subsidies(paymentMethod: $paymentMethod) {
          amount
          currency
        }
        fee(paymentMethod: $paymentMethod) {
          amount
          currency
        }
        offer(querySearch: [{ key: "withInactive", value: "true" }]) {
          id
          published
          offerTemplate: offerTemplate_v2(
            querySearch: [{ key: "withInactive", value: "true" }]
          ) {
            withdrawalType
            onlineHelp {
              language
              text
            }
            ... on Entity {
              id
            }
          }
        }
        orderItems {
          id
          quantity
          chosenBaking
          labelWhenAdded
          priceWhenAdded {
            amount
            currency
          }
          containerLabelWhenAdded
          containerPriceWhenAdded {
            amount
            currency
          }
          offerItem {
            id
            inheritedFamily
          }
        }
        pickupPoint {
          id
          name
          withdrawalSchedule
          description
        }
        orderItemsFormula {
          id
          quantity
          chosenBaking
          labelWhenAdded
          priceWhenAdded {
            amount
            currency
          }
          containerLabelWhenAdded
          containerPriceWhenAdded {
            amount
            currency
          }
          stepNumber
          orderItemFormulaParent {
            id
          }
        }
        isSchool
      }
    }
  }
`;
