import {
  ArticleBaking,
  ArticleFamily,
  OfferTemplateWithdrawalType,
  OrderState,
} from "@foodi/core";
import { AppDispatch, LoaderActions } from "@redux";
import jwtDecode from "jwt-decode";
import moment from "moment";
import { I18n } from "react-redux-i18n";
import { toast } from "react-toastify";

export const JEST = process.env.JEST_WORKER_ID !== undefined;

export const DEV = window.config.ENV === "development";

export const LOCAL = window.config.ENV === "local";

export const isToday = (date: string) => moment().isSame(date, "day");

export const isOrderUpToDate = (date: string) =>
  moment().isSameOrBefore(date, "day");

export const getWithdrawalDate = (date: string) => date?.split("/")?.[1];

export const getDecodedId = (id?: string) =>
  !JEST && id && !id?.includes(":") ? atob(id) : id;

export const getNumericId = (id?: string) => getDecodedId(id)?.split(':')?.[1];

export const getLabelFromState = (state: OrderState) => {
  return {
    ABANDONED: "abandoned",
    ACCEPTED: "accepted",
    CANCELLED: "cancelled",
    CART: "cart",
    COMPLETED: "completed",
    EMPLOYEE_CART: "employeeCart",
    ON_HOLD: "onHold",
    PREPARING: "preparing",
    READY: "ready",
    REFUSED: "refused",
  }[state];
};

export const getLabelFromWithdrawalType = (
  state: OfferTemplateWithdrawalType
) => {
  return {
    CONNECTED_LOCKERS: "connectedLockers",
    POS_AT_SITE: "posAtSite",
    POS_TAKE_AWAY: "posTakeAway",
    TABLE_SERVICE: "tableService",
    POS_CLICK_SERVE: "posClickServe",
    INSTANT_CLICK_COLLECT: "instantClickCollect",
    CLICK_AND_PICK_UP: "clickAndPickup"
  }[state];
};

export const getLabelFromArticleFamily = (family: ArticleFamily) => {
  return {
    BEVERAGE: "beverage",
    BREAD: "bread",
    DAIRY: "dairy",
    DESSERT: "dessert",
    DISH: "dish",
    FRUIT: "fruit",
    MISCELLANEOUS: "miscellaneous",
    PASTRY: "pastry",
    SALAD: "salad",
    SANDWICH: "sandwich",
    SIDE_DISH: "sideDish",
    SNACKING: "snacking",
    SOUP: "soup",
    STARTER: "starter",
    BREAK: "break",
    FORMULA: "formula",
  }[family];
};

export const getLabelForLanguage = (language?: string) => {
  switch (language) {
    case "fr":
      return "Français";
    case "en":
      return "English";
    default:
      return "Français";
  }
};

export const getLabelArticleBaking = (baking: ArticleBaking) => {
  return {
    ROSE: "rose",
    RAW: "raw",
    RARE: "rare",
    MEDIUMRARE: "mediumrare",
    MEDIUM: "medium",
    WELLDONE: "welldone",
  }[baking];
};

export const displayToastError = (
  dispatch: AppDispatch,
  customMessage?: string
) => {
  dispatch(LoaderActions.setLoading(false));
  toast.error(customMessage ?? I18n.t("errorHandler.generic"));
};

export const hasTokenExpired = (expiresAt: string): boolean => {
  const now = new Date().getTime();
  const expiresDate = new Date(expiresAt).getTime();
  return now > expiresDate;
};

export const getQueryParam = (
  location: string,
  param: string
): string | null => {
  return new URLSearchParams(location)?.get?.(param);
};

export const isAccountActivated = (accessToken?: string) => {
  const clientInfo =
    accessToken && (jwtDecode(accessToken) as Record<string, string>);

  return (
    clientInfo && clientInfo?.[window?.config?.AUTH0_APP_EMAIL_VERIFICATION_KEY]
  );
};

export const hasAccessTokenExpired = (accessToken?: string) => {
  const clientInfo =
    accessToken && (jwtDecode(accessToken) as Record<string, string>);
  //@ts-ignore
  if (!clientInfo?.exp && isNaN(clientInfo?.exp)) return true;
  //@ts-ignore
  const expirationDate = clientInfo?.exp * 1000;
  return moment(expirationDate).isBefore(moment().toISOString());
};
