"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_ORDER = void 0;
const client_1 = require("@apollo/client");
exports.GET_ORDER = client_1.gql `
  query getOrder2($idOrder: ID!) {
    # Order:1
    order: get(id: $idOrder) {
      ... on Order {
        id
        cancelableUntil
        cancelableAt
        withdrawRange
        idWithdrawal
        state
        withdrawLocation
        offerTemplateName
        totalPrice {
          amount
          currency
        }
        admission {
          amount
          currency
        }
        subsidies {
          amount
          currency
        }
        offer {
          id
          offerTemplate: offerTemplate_v2 {
            ... on Entity {
              id
            }
          }
        }
        orderItems {
          id
          quantity
          chosenBaking
          labelWhenAdded
          priceWhenAdded {
            amount
            currency
          }
          containerLabelWhenAdded
          containerPriceWhenAdded {
            amount
            currency
          }
          offerItem {
            id
            inheritedFamily
          }
        }
        pickupPoint {
          id
          name
          withdrawalSchedule
          description
        }
        isSchool
      }
    }
  }
`;
