import React from "react";
import { NavigationProp } from "@react-navigation/native";
import { Pressable } from "react-native";
import {
  Button,
  Container,
  HelpContainer,
  Label,
  LabelLogOut,
  LogOutContainer,
  LogoContainer,
} from "./EpointageMenu.style";
import { I18n } from "react-redux-i18n";
import { TestIDs } from "@utils";
import { persistedStore, store } from "@redux";
import { getApolloClient } from "components/src/apollo";
import { AuthActions } from "@modules";
import { HelpEPointageSVG, PointageLogo } from "@assets";

interface IProps {
  navigation?: NavigationProp<any>;
}

export const EpointageMenu: React.FC<IProps> = React.memo(({ navigation }) => {
  const handleEmailTo = () => {
    window.open("mailto:help@foodi.fr");
  };

  const handleLogout = async () => {
    await persistedStore.purge();

    await getApolloClient().cache.reset();

    store.dispatch(AuthActions.logOutUser());

    localStorage.removeItem("persist:root");
    localStorage.clear();

    document.cookie =
      "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

    await persistedStore.flush();
  };

  const LogOut: React.FC = () => (
    <Pressable onPress={handleLogout}>
      <LabelLogOut>{I18n.t("epointage.homepage.menu.logout")}</LabelLogOut>
    </Pressable>
  );

  return (
    <Container>
      <LogoContainer>
        <PointageLogo />
      </LogoContainer>
      <Button testID={TestIDs.epointage.homepage.menu.actions.goToPointage}>
        <Label>{I18n.t("epointage.homepage.menu.pointage")}</Label>
      </Button>
      <HelpContainer>
        <Pressable onPress={handleEmailTo}>
          <HelpEPointageSVG />
        </Pressable>
      </HelpContainer>
      <LogOutContainer>
        <LogOut />
      </LogOutContainer>
    </Container>
  );
});
