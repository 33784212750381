import { Button } from "@atomic";
import { useDevices } from "@hooks";
import { AccountThunks } from "@modules";
import { GlobalState } from "@redux";
import { Title18, Title22, Title30, Title36 } from "@stylesheets";
import { TestIDs } from "@utils";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { I18n } from "react-redux-i18n";
import {
  Container,
  Wrapper,
  ContentContainer,
  TextWrapper,
} from "./AccountValidationInfo.style";

interface IProps {}

export const AccountValidationInfo: React.FC<IProps> = React.memo(() => {
  const [isMobile] = useDevices();

  const [loading, setLoading] = React.useState(false);

  const dispatch = useDispatch();

  const token = useSelector(
    (state: GlobalState) => state?.auth?.authToken?.accessToken
  );

  const onPress = () => {
    if (token) {
      setLoading(true);
      dispatch(AccountThunks.sendUserEmailVerification({ token }))
        //@ts-ignore
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const HeaderText: React.FC<any> = isMobile ? Title22 : Title36;
  const TitleText: React.FC<any> = isMobile ? Title30 : Title36;

  return (
    <Wrapper
      testID={TestIDs.account.views.accountValidationMessage}
      isMobile={isMobile}
    >
      <HeaderText isBlack textAlign="left">
        {I18n.t("account.accountCreation")}
      </HeaderText>
      <Container isMobile={isMobile}>
        <ContentContainer>
          <TitleText>{I18n.t("account.accountNotActivated")}</TitleText>
          <TextWrapper marginTop={8}>
            <Title18 textAlign="center">
              {I18n.t("account.accountValidationCta")}
            </Title18>
          </TextWrapper>
          <TextWrapper marginTop={30}>
            <Button
              forwardTestID={
                TestIDs.account.actions.accountValidationMessageCtaButton
              }
              onPress={onPress}
              label={I18n.t("account.resendEmail")}
              loading={loading}
            />
          </TextWrapper>
          <TextWrapper marginTop={isMobile ? 20 : 30}>
            <Title18>{I18n.t("account.seeYouSoon")}</Title18>
          </TextWrapper>
        </ContentContainer>
      </Container>
    </Wrapper>
  );
});
