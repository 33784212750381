import React from "react";

export const HighAffluence: React.FC = () => (
<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="34px" height="14px" viewBox="0 0 34 14" version="1.1">
    <title>FDF3D6A3-85FA-4CEA-8692-4E40076EA1B9</title>
    <g id="Icons/Affluence/High" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path d="M21.9815246,9.27157655 C22.1498841,9.34165672 22.3195842,9.41484565 22.490625,9.49114332 C22.9427083,9.70155663 23.3072917,10.0096618 23.584375,10.4154589 C23.8614583,10.821256 24,11.3097155 24,11.8808374 L24,14 L20,14 L20,11.8808374 C20,11.3097155 20.1385417,10.821256 20.415625,10.4154589 C20.6927083,10.0096618 21.05,9.70155663 21.4875,9.49114332 L21.9037946,9.30480791 Z M27,8.13848631 C27.9041667,8.13848631 28.8010417,8.25496511 29.690625,8.48792271 C30.5802083,8.7208803 31.5135417,9.05528717 32.490625,9.49114332 C32.9427083,9.70155663 33.3072917,10.0096618 33.584375,10.4154589 C33.8614583,10.821256 34,11.3097155 34,11.8808374 L34,14 L26,14 L26,11.8808374 C26,11.0180172 25.7973509,10.2172507 25.3822966,9.51722289 L25.2360649,9.28766442 C25.0186938,8.96931767 24.7678861,8.68361162 24.4864873,8.43269178 L24.2984375,8.47665056 C25.1953125,8.25120773 26.0958333,8.13848631 27,8.13848631 Z M27,0 C27.9625,0 28.75,0.315619968 29.3625,0.946859903 C29.975,1.57809984 30.28125,2.38969404 30.28125,3.38164251 C30.28125,4.37359098 29.975,5.18518519 29.3625,5.81642512 C28.75,6.44766506 27.9625,6.76328502 27,6.76328502 C26.0375,6.76328502 25.25,6.44766506 24.6375,5.81642512 C24.025,5.18518519 23.71875,4.37359098 23.71875,3.38164251 C23.71875,2.38969404 24.025,1.57809984 24.6375,0.946859903 C25.25,0.315619968 26.0375,0 27,0 Z" id="Combined-Shape" fill="#999999" fill-rule="nonzero"/>
        <path d="M11.9815246,9.27157655 C12.1498841,9.34165672 12.3195842,9.41484565 12.490625,9.49114332 C12.9427083,9.70155663 13.3072917,10.0096618 13.584375,10.4154589 C13.8614583,10.821256 14,11.3097155 14,11.8808374 L14,14 L10,14 L10,11.8808374 C10,11.3097155 10.1385417,10.821256 10.415625,10.4154589 C10.6927083,10.0096618 11.05,9.70155663 11.4875,9.49114332 L11.9037946,9.30480791 Z M17,8.13848631 C17.9041667,8.13848631 18.8010417,8.25496511 19.690625,8.48792271 C20.5802083,8.7208803 21.5135417,9.05528717 22.490625,9.49114332 C22.9427083,9.70155663 23.3072917,10.0096618 23.584375,10.4154589 C23.8614583,10.821256 24,11.3097155 24,11.8808374 L24,14 L16,14 L16,11.8808374 C16,11.0180172 15.7973509,10.2172507 15.3822966,9.51722289 L15.2360649,9.28766442 C15.0186938,8.96931767 14.7678861,8.68361162 14.4864873,8.43269178 L14.2984375,8.47665056 C15.1953125,8.25120773 16.0958333,8.13848631 17,8.13848631 Z M17,0 C17.9625,0 18.75,0.315619968 19.3625,0.946859903 C19.975,1.57809984 20.28125,2.38969404 20.28125,3.38164251 C20.28125,4.37359098 19.975,5.18518519 19.3625,5.81642512 C18.75,6.44766506 17.9625,6.76328502 17,6.76328502 C16.0375,6.76328502 15.25,6.44766506 14.6375,5.81642512 C14.025,5.18518519 13.71875,4.37359098 13.71875,3.38164251 C13.71875,2.38969404 14.025,1.57809984 14.6375,0.946859903 C15.25,0.315619968 16.0375,0 17,0 Z" id="Combined-Shape" fill="#F4B83A" fill-rule="nonzero"/>
        <path d="M7,6.76328502 C6.0375,6.76328502 5.25,6.44766506 4.6375,5.81642512 C4.025,5.18518519 3.71875,4.37359098 3.71875,3.38164251 C3.71875,2.38969404 4.025,1.57809984 4.6375,0.946859903 C5.25,0.315619968 6.0375,0 7,0 C7.9625,0 8.75,0.315619968 9.3625,0.946859903 C9.975,1.57809984 10.28125,2.38969404 10.28125,3.38164251 C10.28125,4.37359098 9.975,5.18518519 9.3625,5.81642512 C8.75,6.44766506 7.9625,6.76328502 7,6.76328502 Z M0,14 L0,11.8808374 C0,11.3097155 0.138541667,10.821256 0.415625,10.4154589 C0.692708333,10.0096618 1.05,9.70155663 1.4875,9.49114332 C2.46458333,9.04025765 3.4015625,8.7020934 4.2984375,8.47665056 C5.1953125,8.25120773 6.09583333,8.13848631 7,8.13848631 C7.90416667,8.13848631 8.80104167,8.25496511 9.690625,8.48792271 C10.5802083,8.7208803 11.5135417,9.05528717 12.490625,9.49114332 C12.9427083,9.70155663 13.3072917,10.0096618 13.584375,10.4154589 C13.8614583,10.821256 14,11.3097155 14,11.8808374 L14,14 L0,14 Z" id="Shape" fill="#F4B83A" fill-rule="nonzero"/>
    </g>
</svg>
);
