"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ordersFixtures = void 0;
exports.ordersFixtures = [
    {
        __typename: "Order",
        created: "2021-10-19T12:00:00.000Z",
        id: "Order:2",
        idWithdrawal: 104,
        offer: {
            __typename: "Offer",
            id: "Offer:1",
            published: true,
            withdrawRange: null,
            offerTemplate_v2: {
                __typename: "OfferTemplate",
                id: "",
                pos: {
                    __typename: "Pos",
                    id: "Pos:1",
                    name: "Nom restaurant/POS",
                },
                //@ts-ignore
                type: "CLICK_COLLECT",
            },
        },
        offerTemplateName: "Daily Monop",
        orderItems: [
            {
                chosenBaking: null,
                id: "OrderItem:1",
                labelWhenAdded: "Salade de pates",
                containerLabelWhenAdded: "Medium bowl",
                offerItem: {
                    __typename: "OfferItem",
                    id: "OfferItem:1",
                    inheritedFamily: null,
                },
                priceWhenAdded: {
                    amount: "5.00",
                    currency: "EUR",
                },
                containerPriceWhenAdded: {
                    amount: "1.00",
                    currency: "EUR",
                },
                quantity: 1,
                __typename: "OrderItem",
            },
            {
                chosenBaking: null,
                id: "OrderItem:2",
                labelWhenAdded: "Salade de poullet",
                offerItem: {
                    __typename: "OfferItem",
                    id: "OfferItem:3",
                    //@ts-ignore
                    inheritedFamily: "DISH",
                },
                priceWhenAdded: {
                    amount: "10.00",
                    currency: "EUR",
                },
                quantity: 1,
                __typename: "OrderItem",
            },
            {
                chosenBaking: null,
                id: "OrderItem:3",
                labelWhenAdded: "Salade de poisson",
                offerItem: {
                    __typename: "OfferItem",
                    id: "OfferItem:3",
                    //@ts-ignore
                    inheritedFamily: "DISH",
                },
                priceWhenAdded: {
                    amount: "10.00",
                    currency: "EUR",
                },
                quantity: 1,
                __typename: "OrderItem",
            },
            {
                chosenBaking: null,
                id: "OrderItem:4",
                labelWhenAdded: "eau",
                offerItem: {
                    __typename: "OfferItem",
                    id: "OfferItem:2",
                    //@ts-ignore
                    inheritedFamily: "BEVERAGE",
                },
                priceWhenAdded: {
                    amount: "2.00",
                    currency: "EUR",
                },
                quantity: 1,
                __typename: "OrderItem",
            },
        ],
        //@ts-ignore
        state: "PREPARING",
        totalPrice: { amount: "7.00", currency: "EUR" },
        updated: "2019-05-20T12:25:00.000Z",
        cancelableUntil: "2019-05-20T12:35:00.000Z",
        withdrawRange: "2022-10-21T13:00:00.000+00:00/2022-10-21T13:15:00.000+00:00",
    },
    {
        __typename: "Order",
        created: "2021-10-19T12:00:00.000Z",
        id: "Order:3",
        idWithdrawal: 105,
        comment: "Bonjour, est-il possible d’avoir la sauce du bowl séparément. Merci.",
        offer: {
            __typename: "Offer",
            id: "Offer:1",
            published: true,
            withdrawRange: null,
            offerTemplate_v2: {
                __typename: "OfferTemplate",
                id: "",
                pos: {
                    __typename: "Pos",
                    id: "Pos:1",
                    name: "Nom restaurant/POS",
                },
                //@ts-ignore
                type: "CLICK_COLLECT",
            },
        },
        offerTemplateName: "Daily Monop",
        orderItems: [
            {
                chosenBaking: null,
                id: "OrderItem:1",
                labelWhenAdded: "Salade de pates",
                offerItem: {
                    __typename: "OfferItem",
                    id: "OfferItem:1",
                    //@ts-ignore
                    inheritedFamily: "STARTER",
                },
                priceWhenAdded: {
                    amount: "5.00",
                    currency: "EUR",
                },
                quantity: 1,
                __typename: "OrderItem",
            },
            {
                chosenBaking: null,
                id: "OrderItem:2",
                labelWhenAdded: "Salade de poullet",
                offerItem: {
                    __typename: "OfferItem",
                    id: "OfferItem:3",
                    //@ts-ignore
                    inheritedFamily: "DISH",
                },
                priceWhenAdded: {
                    amount: "10.00",
                    currency: "EUR",
                },
                quantity: 1,
                __typename: "OrderItem",
            },
            {
                chosenBaking: null,
                id: "OrderItem:3",
                labelWhenAdded: "Salade de poisson",
                offerItem: {
                    __typename: "OfferItem",
                    id: "OfferItem:3",
                    //@ts-ignore
                    inheritedFamily: "DISH",
                },
                priceWhenAdded: {
                    amount: "10.00",
                    currency: "EUR",
                },
                quantity: 1,
                __typename: "OrderItem",
            },
            {
                chosenBaking: null,
                id: "OrderItem:4",
                labelWhenAdded: "eau",
                containerLabelWhenAdded: "Medium bowl",
                offerItem: {
                    __typename: "OfferItem",
                    id: "OfferItem:2",
                    inheritedFamily: null,
                },
                priceWhenAdded: {
                    amount: "2.00",
                    currency: "EUR",
                },
                containerPriceWhenAdded: {
                    amount: "1.00",
                    currency: "EUR",
                },
                quantity: 1,
                __typename: "OrderItem",
            },
        ],
        //@ts-ignore
        state: "PREPARING",
        totalPrice: { amount: "7.00", currency: "EUR" },
        updated: "2019-05-20T12:25:00.000Z",
        withdrawRange: null,
    },
];
