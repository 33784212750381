import React from "react";

interface IProps {
    tooltip?: string;
}

export const StarActive: React.FC<IProps> = ({ tooltip }) => (
    <svg
        width="20px"
        height="20px"
        viewBox="0 0 20 20"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      {tooltip && <title>{tooltip}</title>}
      <g
          id="Icons/Notation-star/Active"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
      >
        <polygon
            id="Path"
            stroke="#090909"
            fill="#090909"
            fillRule="nonzero"
            points="3.825 20 5.45 12.6052632 0 7.63157895 7.2 6.97368421 10 0 12.8 6.97368421 20 7.63157895 14.55 12.6052632 16.175 20 10 16.0789474"
        ></polygon>
      </g>
    </svg>
);
