"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InMemoryTableService = void 0;
const domain_1 = require("../../../domain");
class InMemoryTableService {
    constructor(checkTablesAvailabilityFixture) {
        this.checkTablesAvailabilityFixture = checkTablesAvailabilityFixture;
    }
    checkTablesAvailability(variables) {
        if (this.checkTablesAvailabilityFixture) {
            return Promise.resolve(this.checkTablesAvailabilityFixture);
        }
        return Promise.reject(new domain_1.TableServiceErrors.CheckTablesAvailabilityRequestError("CHECK_TABLES_AVAILABILITY_REQUEST_FAILED"));
    }
}
exports.InMemoryTableService = InMemoryTableService;
