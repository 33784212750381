"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeleteUserHoldingView = void 0;
class DeleteUserHoldingView {
    constructor(holdingRepository) {
        this.holdingRepository = holdingRepository;
    }
    execute(request) {
        return this.holdingRepository.deleteUserHoldingView(request);
    }
}
exports.DeleteUserHoldingView = DeleteUserHoldingView;
