"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SCOLAPASS_INVOICES_FRAGMENT = void 0;
const client_1 = require("@apollo/client");
exports.SCOLAPASS_INVOICES_FRAGMENT = client_1.gql `
  fragment scolapassInvoicesFragment on InvoiceConnection {
    pageInfo {
      endCursor
      hasNextPage
      __typename
    }
    edges {
      node {
        FAC_Numero_Facture
        FAM_ID
        FAC_Date_Facture
        FAC_Montant
        FAC_PDF
        __typename
      }
      __typename
    }
    __typename
  }
`;
