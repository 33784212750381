import React from "react";
import { StyleSheet, View } from "react-native";
import { Title16, Title18, Title13 } from "@stylesheets";
import { Colors } from "@constants";
import { useDevices } from "@hooks";
import { IFormattedSchedules } from "@modules";

interface IProps {
  schedule: IFormattedSchedules;
}

export const PosScheduleRow: React.FC<IProps> = React.memo(({ schedule }) => {
  const [isMobile] = useDevices();

  const isCurrentDay = schedule.isCurrentDay;

  const RegularText: React.FC<any> = isCurrentDay ? Title18 : Title16;
  const MobileText: React.FC<any> = isCurrentDay ? Title16 : Title13;
  const ScheduleText: React.FC<any> = isMobile ? MobileText : RegularText;

  return (
    <View style={styles.container}>
      <View style={styles.column}>
        <ScheduleText
          isBold={isCurrentDay}
          bold={isCurrentDay}
          color={Colors.foodiBlack}
        >
          {schedule.dayWeekName}
        </ScheduleText>
      </View>

      <ScheduleText
        isBold={isCurrentDay}
        bold={isCurrentDay}
        color={Colors.foodiBlack}
      >
        {schedule.schedules}
      </ScheduleText>
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    marginBottom: 5,
    flexDirection: "row",
  },
  column: {
    width: 110,
  },
});
