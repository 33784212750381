import React from "react";

export const BigBlackArrowLeft: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px">
    <g fill="none" fillRule="evenodd">
      <circle cx={15} cy={15} r={15} fill="#090909" />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M19.375 9.262 13.58 15l5.795 5.738-1.784 1.762L10 15l7.591-7.5z"
      />
    </g>
  </svg>
);
