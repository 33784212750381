"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_BOOKING_ORDERS = void 0;
const client_1 = require("@apollo/client");
exports.GET_BOOKING_ORDERS = client_1.gql `
  fragment getBookingOrdersFragment on EntityConnection {
    edges {
      node {
        ... on Order {
          id
          state
          idWithdrawal
          created
          updated
          idOffer
          offerTemplateName
          withdrawRange
          isSchool
          bookingOffer {
            id
            withdrawRange
            bookingOfferTemplate {
              ... on Entity {
                id
              }
              pos {
                id
                name
                zone {
                  id
                  idHolding
                  holding {
                    id
                    name
                  }
                }
              }
              type
            }
          }
        }
      }
    }
  }

  query getBookingOrders($idGuest: String!, $fromDate: String!) {
    active: list(
      type: "Order"
      order: "-id"
      querySearch: [
        { key: "idGuest", value: $idGuest }
        { key: "state", operator: "*", value: "ACCEPTED" }
        { key: "withdrawRange", operator: ">", value: $fromDate }
        { key: "offerTemplateTypes", value: "BOOKING_SERVICE" }
        { key: "withInactive", value: "true" }
      ]
      orderType: "booking"
    ) {
      ...getBookingOrdersFragment
    }
  }
`;
