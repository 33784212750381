import {
  ServicesResponse,
  ICallback,
  HoldingWithRecipeStatusResponse,
  GetNearHoldingsCommand,
  GetNearHoldingsResponse,
  AddUserHoldingViewCommand,
  SwitchUserHoldingViewCommand,
  HoldingView,
  GetUserHoldingViewCommand,
  UserProfile,
  MigrateToHoldingViewCommand,
  DeleteUserHoldingViewCommand,
  UpdateUserHoldingViewCommand,
} from "@foodi/core";
import { ThunkResult } from "@redux";
import { displayToastError } from "@utils";
import { PosAffluence } from "@foodi/core/lib/domain/entities/PosAffluence";

const ThunkActionCreators = {
  getServices: (
    callback?: ICallback
  ): ThunkResult<Promise<ServicesResponse>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { getServices } = getDependencies();
      return getServices.execute(callback);
    } catch (error) {
      displayToastError(dispatch);
      return Promise.reject(error);
    }
  },
  getHoldingWithIsRecipeDetailsEnabled: (
    callback?: ICallback
  ): ThunkResult<Promise<HoldingWithRecipeStatusResponse>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { getHoldingWithRecipeStatus } = getDependencies();
      return getHoldingWithRecipeStatus.execute(callback);
    } catch (error) {
      displayToastError(dispatch);
      return Promise.reject(error);
    }
  },
  getNearHoldings: (
    params: GetNearHoldingsCommand
  ): ThunkResult<Promise<GetNearHoldingsResponse>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { getNearHoldings } = getDependencies();
      return getNearHoldings.execute(params);
    } catch (e) {
      displayToastError(dispatch);
      return Promise.reject(e);
    }
  },
  addUserHoldingView: (
    request: AddUserHoldingViewCommand
  ): ThunkResult<Promise<HoldingView>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { addUserHoldingView } = getDependencies();
      return addUserHoldingView.execute(request);
    } catch (error) {
      displayToastError(dispatch);
      return Promise.reject(error);
    }
  },
  switchUserHoldingView: (
    request: SwitchUserHoldingViewCommand
  ): ThunkResult<Promise<HoldingView>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { switchUserHoldingView } = getDependencies();
      return switchUserHoldingView.execute(request);
    } catch (error) {
      displayToastError(dispatch);
      return Promise.reject(error);
    }
  },
  getUserHoldingView: (
    request: GetUserHoldingViewCommand
  ): ThunkResult<Promise<Array<HoldingView>>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { getUserHoldingView } = getDependencies();
      return getUserHoldingView.execute(request);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getHoldingAffluence: (): ThunkResult<Promise<Array<PosAffluence>>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { getHoldingAffluence } = getDependencies();
      return getHoldingAffluence.execute();
    } catch (error) {
      return Promise.reject(error);
    }
  },
  migrateToHoldingView: (
    request?: MigrateToHoldingViewCommand
  ): ThunkResult<Promise<UserProfile>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { migrateToHoldingView } = getDependencies();
      return migrateToHoldingView.execute(request);
    } catch (error) {
      displayToastError(dispatch);
      return Promise.reject(error);
    }
  },
  deleteUserHoldingView: (
    request: DeleteUserHoldingViewCommand
  ): ThunkResult<Promise<any>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { deleteUserHoldingView } = getDependencies();
      return deleteUserHoldingView.execute(request);
    } catch (error) {
      displayToastError(dispatch);
      return Promise.reject(error);
    }
  },
  updateUserHoldingView: (
    request: UpdateUserHoldingViewCommand
  ): ThunkResult<Promise<HoldingView>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { updateUserHoldingView } = getDependencies();
      return updateUserHoldingView.execute(request);
    } catch (error) {
      displayToastError(dispatch);
      return Promise.reject(error);
    }
  },
};

export { ThunkActionCreators as HoldingThunks };
