import React, { useCallback } from "react";
import { Button } from "@atomic";
import { StyleSheet, Text, View } from "react-native";
import { useDevices } from "@hooks";
import { AccountThunks } from "@modules";
import { Title18, Title22, Title30, Title36 } from "@stylesheets";
import { TestIDs } from "@utils";
import { useDispatch } from "react-redux";
import { I18n } from "react-redux-i18n";
import {
  Container,
  Wrapper,
  ContentContainer,
  TextWrapper,
} from "./PasswordExpirationInfo.style";
import { Colors } from "@constants";

interface IPasswordExpirationInfo {
  info: string;
}

const handleEmailTo = () => {
  window.open("mailto:help@foodi.fr");
};

export const PasswordExpirationInfo: React.FC<IPasswordExpirationInfo> = React.memo(
  ({ info }) => {
    const [isMobile] = useDevices();
    const [loading, setLoading] = React.useState(false);
    const dispatch = useDispatch();

    const onPress = useCallback(async () => {
      try {
        setLoading(true);
        const email = info.split("|")[1];
        await dispatch(AccountThunks.sendUserResetPasswordEmail({ email }));
      } catch (e) {
      } finally {
        setLoading(false);
      }
    }, []);

    const TitleText: React.FC<any> = isMobile ? Title30 : Title36;

    return (
      <Wrapper
        testID={TestIDs.account.views.resetPasswordMessage}
        isMobile={isMobile}
      >
        <Container isMobile={isMobile}>
          <ContentContainer>
            <View style={styles.contnetnContainer}>
              <TitleText>{I18n.t("account.resetPasswordTitle")}</TitleText>
              <TextWrapper marginTop={8}>
                <Title22 textAlign="center" isBlack isNotBold>
                  {I18n.t("account.resetPasswordDescription")}
                </Title22>
              </TextWrapper>
              <TextWrapper>
                <Title22 textAlign="center" isBlack isNotBold>
                  {I18n.t("account.resetPasswordHasBeenSent")}
                </Title22>
              </TextWrapper>
              <TextWrapper marginTop={30}>
                <Button
                  forwardTestID={
                    TestIDs.account.actions.accountValidationMessageCtaButton
                  }
                  onPress={onPress}
                  label={I18n.t("account.resendEmail")}
                  loading={loading}
                />
              </TextWrapper>
              <TextWrapper marginTop={20}>
                <Title18>
                  {I18n.t("common.inCaseOfProb")}
                  <Text style={styles.mailTo} onPress={handleEmailTo}>
                    help@foodi.fr
                  </Text>
                </Title18>
              </TextWrapper>
              <TextWrapper marginTop={isMobile ? 20 : 30}>
                <Title18>{I18n.t("account.seeYouSoon")}</Title18>
              </TextWrapper>
            </View>
          </ContentContainer>
        </Container>
      </Wrapper>
    );
  }
);

const styles = StyleSheet.create({
  mailTo: {
    color: Colors.blueLink,
    marginLeft: 5,
    fontWeight: "bold",
    textDecorationLine: "underline",
  },
  contnetnContainer: {
    alignItems: "center",
  },
});
