"use strict";
/* istanbul ignore file */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoginSSO = void 0;
class LoginSSO {
    constructor(authService) {
        this.authService = authService;
    }
    execute(request) {
        const { config } = request;
        return this.authService.loginSSO(config);
    }
}
exports.LoginSSO = LoginSSO;
