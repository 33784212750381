import React from "react";
import { I18n } from "react-redux-i18n";
import _ from "lodash";

import { ItemDescription } from "./ComingSoonModal.style";
import { ArticleFamily, Order } from "@foodi/core";
import { getLabelFromArticleFamily } from "@utils";

interface IOrder {
  order: Order;
}

const Menu: React.FC<IOrder> = React.memo(({ order }) => {
  const articleFamily: any = {};

  order?.orderItems?.forEach((item) => {
    const inheritedFamily = item?.offerItem?.inheritedFamily;
    if (inheritedFamily) {
      if (articleFamily[inheritedFamily]) {
        articleFamily[inheritedFamily] += item.quantity;
      } else articleFamily[inheritedFamily] = item.quantity;
    }
  });

  return (
    <ItemDescription>
      {Object.keys(articleFamily)
        ?.map(
          (f) =>
            `${articleFamily[f]} ${I18n.t(
              `homepage.comingSoon.${getLabelFromArticleFamily(
                f as ArticleFamily
              )}${articleFamily[f] > 1 ? "Plural" : ""}`
            )}`
        )
        .join(" - ")}
    </ItemDescription>
  );
});

export default Menu;
