"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_ORDERS = void 0;
const client_1 = require("@apollo/client");
exports.GET_ORDERS = client_1.gql `
  fragment getOrdersFragment on EntityConnection {
    edges {
      node {
        ... on Order {
          id
          state
          idWithdrawal
          created
          updated
          offerTemplateName
          withdrawRange
          cancelableUntil
          totalPrice {
            amount
            currency
          }
          offer {
            id
            published
            withdrawRange
            offerTemplate_v2 {
              ... on Entity {
                id
              }
              pos {
                id
                name
              }
              type
            }
            offerTemplate {
              name
              id
              withdrawalType
            }
          }
          orderItems {
            id
            quantity
            chosenBaking
            labelWhenAdded
            priceWhenAdded {
              amount
              currency
            }
            offerItem {
              id
              inheritedFamily
            }
          }
        }
      }
    }
  }

  query getOrders(
    $idGuest: String!
    $fromDate: String!
    $offerTemplateTypes: String!
    $withdrawalTypes: String!
  ) {
    active: list(
      type: "Order"
      order: "-id"
      first: 5
      querySearch: [
        { key: "idGuest", value: $idGuest }
        { key: "offerTemplateTypes", value: $offerTemplateTypes }
        { key: "state", operator: "*", value: "ACCEPTED,PREPARING,READY" }
        { key: "withInactive", value: "true" }
        {
          key: "offerTemplate.withdrawalType"
          operator: "*"
          value: $withdrawalTypes
        }
      ]
    ) {
      ...getOrdersFragment
    }

    recent: list(
      type: "Order"
      order: "-id"
      first: 5
      querySearch: [
        { key: "idGuest", value: $idGuest }
        # Date == debut de la journee d'hier avec la timezone du telephone
        { key: "updated", operator: ">", value: $fromDate }
        { key: "offerTemplateTypes", value: $offerTemplateTypes }
        {
          key: "state"
          operator: "*"
          value: "CANCELLED,REFUSED,COMPLETED,ABANDONED"
        }
        { key: "withInactive", value: "true" }
        {
          key: "offerTemplate.withdrawalType"
          operator: "*"
          value: $withdrawalTypes
        }
      ]
    ) {
      ...getOrdersFragment
    }
  }
`;
