import { Colors } from "@constants";
import React from "react";

export const User: React.FC<{ color?: string }> = ({ color = Colors.foodiBlack }) => (
    <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="Icons/User_black" stroke={color} stroke-width="1" fill={color} fill-rule="evenodd">
            <path d="M10,9.76328502 C9.0375,9.76328502 8.25,9.44766506 7.6375,8.81642512 C7.025,8.18518519 6.71875,7.37359098 6.71875,6.38164251 C6.71875,5.38969404 7.025,4.57809984 7.6375,3.9468599 C8.25,3.31561997 9.0375,3 10,3 C10.9625,3 11.75,3.31561997 12.3625,3.9468599 C12.975,4.57809984 13.28125,5.38969404 13.28125,6.38164251 C13.28125,7.37359098 12.975,8.18518519 12.3625,8.81642512 C11.75,9.44766506 10.9625,9.76328502 10,9.76328502 Z M3,17 L3,14.8808374 C3,14.3097155 3.13854167,13.821256 3.415625,13.4154589 C3.69270833,13.0096618 4.05,12.7015566 4.4875,12.4911433 C5.46458333,12.0402576 6.4015625,11.7020934 7.2984375,11.4766506 C8.1953125,11.2512077 9.09583333,11.1384863 10,11.1384863 C10.9041667,11.1384863 11.8010417,11.2549651 12.690625,11.4879227 C13.5802083,11.7208803 14.5135417,12.0552872 15.490625,12.4911433 C15.9427083,12.7015566 16.3072917,13.0096618 16.584375,13.4154589 C16.8614583,13.821256 17,14.3097155 17,14.8808374 L17,17 L3,17 Z" id="Shape" fill={color} fill-rule="nonzero"></path>
        </g>
    </svg>
);
