import React from "react";
import { Colors } from "@constants";
import { Platform, StyleSheet, TextInput, TextInputProps, View, ViewStyle } from "react-native";

interface IProps extends TextInputProps {
  forwardTestID?: string;
  warningHighlight?: boolean;
  style?: ViewStyle;
  textStyle?: TextInputProps['style'];
}

export const TextAreaInput = React.memo(
  ({ warningHighlight, forwardTestID, style, textStyle, ...textInputProps }: IProps) => {

    return (
      <View style={[styles.container, warningHighlight && styles.invalidInput, style]}>
        <TextInput
          multiline
          testID={forwardTestID}
          style={[styles.textInput, textStyle]}
          placeholderTextColor={Colors.darkGrey}
          {...textInputProps}
        />
      </View>
    );
  }
);

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: Colors.darkGrey,
  },
  textInput: {
    fontSize: 16,
    fontFamily: 'Manrope',
    letterSpacing: 0,
    lineHeight: 22,
    color: Colors.foodiBlack,
    padding: 10,
    borderRadius: 8,
  },
  invalidInput: {
    borderWidth: 2,
    borderColor: Colors.foodiDefault,
  },
});
