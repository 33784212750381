import style from "styled-components/native";
import webStyle from "styled-components";
import { Colors } from "@constants";

export const CONTENT_WIDTH = 800;
export const CONTENT_WIDTH_MOBILE = 380;
export const IMAGE_WIDTH = 390;
export const IMAGE_WIDTH_MOBILE = 380;
export const IMAGE_HEIGHT = 239;
export const IMAGE_HEIGHT_MOBILE = 303;
export const IMAGE_MARGIN = 20;
export const ITEM_WIDTH_WITH_MARGIN = IMAGE_WIDTH + IMAGE_MARGIN;

export const Wrapper = style.ScrollView`
    flex: 1;
    background-color: ${Colors.white};
`;

export const Container = style.View<{ isMobile?: boolean }>`
    align-self: center;
    width: ${({ isMobile }) =>
      isMobile ? CONTENT_WIDTH_MOBILE : CONTENT_WIDTH}px;
    margin-bottom: 23px;
`;

export const TitleContainer = style.View`
    margin: 30px 0px 24px 0px;
`;

export const CarouselContainer = style.View`
    margin-bottom: 37px;
`;

export const ImageContainer = style.Image<{
  isMobile?: boolean;
  hasGap?: boolean;
}>`
    width: ${({ isMobile }) => (isMobile ? IMAGE_WIDTH_MOBILE : IMAGE_WIDTH)}px;
    height: ${({ isMobile }) =>
      isMobile ? IMAGE_HEIGHT_MOBILE : IMAGE_HEIGHT}px;
    margin-right: ${({ hasGap }) => (hasGap ? IMAGE_MARGIN : 0)}px;
    border-radius: 8px;
`;

export const HTML = webStyle.div<{ isMobile?: boolean }>`
    color: ${Colors.foodiBlack};
    font-family: "manrope";
    font-size: ${({ isMobile }) => (isMobile ? 16 : 18)}px;
    letter-spacing: 0;
    line-height: 24px;

    p {
        margin-bottom: 40px;
    }
`;

export const InfoNewsHTML = webStyle(HTML)<{ isMobile?: boolean }>`
    width: ${({ isMobile }) => (isMobile ? CONTENT_WIDTH_MOBILE : 560)}px;
    height: auto;
    margin: 40px 0px 37px 0px;

    #isValid {
        margin-top: unset !important;
    }
`;

export const InfoNewsContainer = style.View`
    align-self: center;
`;

export const TagIconContainer = style.View`
    z-index: -1;
    position: absolute;
    width: 124px;
    border-radius: 8px;
    background-color: ${Colors.tagsGray};
`;

export const Tag = style.View<{ isMobile?: boolean }>`
    justify-content: center;
    top: 50px;
    left: ${({ isMobile }) => (isMobile ? 18 : 40)}px;
    z-index: 2;
`;

export const TagTextContainer = style.View`
    margin-left: 40px;
`;
