import React, { useMemo } from "react";
import { StyleSheet, View, Text } from "react-native";
import { Colors } from "@constants";
import { I18n } from "react-redux-i18n";

interface IProps {
  status: boolean;
  forwardTestID?: string;
}

export const OfferColorState: React.FC<IProps> = React.memo(
  ({ status, forwardTestID }) => {
    const styles = useMemo(() => _styles(status), [status]);
    const offerStatus = status
      ? I18n.t("homepage.restaurantCard.opened")
      : I18n.t("homepage.restaurantCard.closed");

    return (
      <View style={styles.container}>
        <View testID={forwardTestID} style={styles.dot} />
        <Text style={styles.label}>{offerStatus}</Text>
      </View>
    );
  }
);

const _styles = (status: boolean) =>
  StyleSheet.create({
    container: {
      flexDirection: "row",
    },
    dot: {
      width: 12,
      height: 12,
      borderWidth: 2,
      borderColor: Colors.white,
      backgroundColor: status ? Colors.middleGreen : Colors.redError,
      borderRadius: 10,
      marginRight: 4,
    },
    label: {
      color: Colors.white,
      fontFamily: "manrope-bold",
      fontSize: 13,
      lineHeight: 12,
    },
  });
