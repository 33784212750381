import { BookingOrder, Order } from "@foodi/core";
import { ComingSoonViewModel } from "@modules";
import { GlobalState } from "@redux";
import { getWithdrawalDate, isToday } from "@utils";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { I18n } from "react-redux-i18n";
import {
  ItemTextContainer,
  ItemDescription,
  ItemTitle,
  Row,
} from "./ComingSoonModal.style";

interface IProps {
  order: Order | BookingOrder;
  pos?: string;
}

const OrderDescription: React.FC<IProps> = ({ order, pos }) => {
  const userLanguage = useSelector(
    (state: GlobalState) => state.auth?.userInfo?.language
  );
  const withdrawalDate = getWithdrawalDate(order?.withdrawRange);

  const comingSoonViewModel = new ComingSoonViewModel();
  const firstHour = comingSoonViewModel.getFirstHours(order?.withdrawRange);

  const checkToday = isToday(withdrawalDate);

  const formattedDate = moment(withdrawalDate)
    .locale(userLanguage || "fr")
    .format("DD MMMM");

  const dateText = checkToday
    ? I18n.t("homepage.comingSoon.today")
    : formattedDate;

  const forText = I18n.t(
    `homepage.comingSoon.${checkToday ? "pour" : "pourLe"}`
  );

  return (
    <ItemTextContainer noMarginLeft>
      <Row>
        <ItemDescription>{forText} </ItemDescription>
        <ItemTitle>{dateText}</ItemTitle>
        <ItemDescription> - </ItemDescription>
        <ItemTitle>{firstHour}</ItemTitle>
      </Row>
      {pos && (
        <ItemDescription numberOfLines={1} ellipsizeMode="tail">
          {" "}
          - {pos}
        </ItemDescription>
      )}
    </ItemTextContainer>
  );
};

export default OrderDescription;
