import { Action } from "../action";
import { createReducer } from "@redux/createReducer";

export interface ModalState {
  open: boolean;
}

/*************  Actions  ****************/

const ActionTypes = {
  SET_OPEN: "modal/SET_OPEN",
};

const ActionCreators = {
  setOpen: (open: boolean): Action<boolean> => ({
    type: ActionTypes.SET_OPEN,
    payload: open,
  }),
};

/*************  Reducer  ****************/

const initialState: ModalState = {
  open: false,
};

const Reduction = {
  setOpen: (state: ModalState, { payload: open }: Action<boolean>) => ({
    open,
  }),
};

const reducer = createReducer(initialState, {
  [ActionTypes.SET_OPEN]: Reduction.setOpen,
});

export default reducer;

export {
  reducer as ModalReducer,
  ActionTypes as ModalActionTypes,
  ActionCreators as ModalActions,
};
