"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetHoldingWithRecipeStatus = void 0;
class GetHoldingWithRecipeStatus {
    constructor(holdingRepository) {
        this.holdingRepository = holdingRepository;
    }
    execute(callback) {
        return this.holdingRepository.getHoldingWithRecipeStatus(callback);
    }
}
exports.GetHoldingWithRecipeStatus = GetHoldingWithRecipeStatus;
