import React from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { Title13, Title18 } from "@stylesheets";
import { Colors } from "@constants";
import { I18n } from "react-redux-i18n";
import { Delete, Modify } from "@assets";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "@redux";
import { HoldingView, UserProfile } from "@foodi/core";
import { TestIDs } from "@utils";
import { useDevices } from "@hooks";
import { HoldingViewModel } from "@modules";
import { NavigationProp } from "@react-navigation/native";
import { SecurityCode } from "@assets/icons/SecurityCode";
import DisplaySecurityCodeModal from "../DisplaySecurityCodeModal";

interface IProps {
  navigation: NavigationProp<any>;
  hv: HoldingView;
  index: number;
  handleModifyHoldingView: (hv: HoldingView) => () => void;
  handleHoldingViewSwitch: (hv: HoldingView) => () => void;
  handleDeleteHoldingView: (
    hv: HoldingView,
    index: number,
    isActiveHoldingView?: boolean
  ) => () => void;
}
export const HoldingViewRow: React.FC<IProps> = React.memo(
    ({
         hv,
         index,
         handleModifyHoldingView,
         handleDeleteHoldingView,
         handleHoldingViewSwitch,
     }) => {
        const dispatch = useDispatch();
        const [isMobile] = useDevices();
        const styles = _styles(isMobile);
        const [isHover, setIsHover] = React.useState<string>();

    const [openShowSecurityCodeModal, setOpenShowSecurityCodeModal] = React.useState<boolean>(false);

    const HoldingViewRowText: React.FC<any> = isMobile ? Title13 : Title18;
    const isScholapassSystem: boolean = hv?.holding?.isScolaPassCashSystem === true;

    const hasBadge: boolean = hv?.guest !== null && (hv?.guest?.serialNumber !== '' || hv?.guest?.serialNumber !== null);

    const validationCode: string = hv?.guest?.validationCode?.toString() || '';

    const { idHoldingView } =
      useSelector((state: GlobalState) => state.auth?.userInfo) ??
      ({} as UserProfile);

    const holdingViewModel = new HoldingViewModel(dispatch, isMobile);

    const setHoverValue = (value?: string) =>
      /* istanbul ignore next */
      () => setIsHover(value);

    const modifyIconSize = isMobile ? 24 : 30;
    const securityCodeIconSize = isMobile ? 24 : 30;

    const isActiveHoldingView = holdingViewModel.isActiveHoldingView(
      idHoldingView,
      hv.id
    );
    const rowStyle = isActiveHoldingView
      ? [styles.holdingView, styles.activeHoldingView]
      : [styles.holdingView];

    const { firstName } =
      useSelector((state: GlobalState) => state.auth?.userInfo) ??
      ({} as UserProfile);

    const { alias, establishment, badge } = holdingViewModel.getHoldingViewRowData(hv, firstName);

        return (
            <View>
                {isActiveHoldingView && (
                    <HoldingViewRowText style={styles.active} color={Colors.middleGreen}>
                        {I18n.t("account.active")}
                    </HoldingViewRowText>
                )}
                <View style={[styles.row, styles.marginBottom]}>
                    <Pressable
                        key={hv.id}
                        style={[...rowStyle, isHover === hv.id && styles.rowHover]}
                        //@ts-ignore
                        onHoverIn={setHoverValue(hv.id)}
                        onHoverOut={setHoverValue(undefined)}
                        disabled={isActiveHoldingView}
                        onPress={handleHoldingViewSwitch(hv)}
                    >
                        <View>
                            <HoldingViewRowText
                                bold isBold
                                color={Colors.foodiBlack}
                                style={isActiveHoldingView && styles.activeHoldingViewText}
                            >
                                {alias}
                            </HoldingViewRowText>
                        </View>
                        <View style={styles.ellipsisText}>
                            <HoldingViewRowText
                                bold isBold
                                color={Colors.foodiBlack}
                                style={isActiveHoldingView && styles.activeHoldingViewText}
                                numberOfLines={1}
                                ellipsizeMode='tail'
                            >
                                {establishment}
                            </HoldingViewRowText>
                        </View>
                        <View>
                            <HoldingViewRowText
                                bold isBold
                                color={Colors.foodiBlack}
                                style={isActiveHoldingView && styles.activeHoldingViewText}
                            >
                                {badge}
                            </HoldingViewRowText>
                        </View>
                    </Pressable>
                    <View style={styles.actions}>
                        {hasBadge && (<Pressable
                            testID={TestIDs.profile.actions.pressShowSecurityCode}
                            style={styles.marginLeft}
                            onPress={() => setOpenShowSecurityCodeModal(true)}
                        >
                            <SecurityCode width={securityCodeIconSize} height={securityCodeIconSize}/>
                        </Pressable>)}
                        <Pressable
                            testID={TestIDs.profile.actions.pressEditHoldingView}
                            style={styles.marginLeft}
                            onPress={handleModifyHoldingView(hv)}
                        >
                            <Modify width={modifyIconSize} height={modifyIconSize}/>
                        </Pressable>
                        <Pressable
                            testID={TestIDs.profile.actions.pressDeleteHoldingView}
                            style={styles.separator}
                            onPress={handleDeleteHoldingView(hv, index + 1, isActiveHoldingView)}
                        >
                            <Delete/>
                        </Pressable>
                    </View>
                </View>
                {index === 0 && <View style={styles.divider}/>}
                {openShowSecurityCodeModal && (
                    <DisplaySecurityCodeModal
                        securityCode={validationCode}
                        isScholapass={isScholapassSystem}
                        closeModal={() => setOpenShowSecurityCodeModal(false)}
                    />
                )}
            </View>
        );
    }
);

const _styles = (isMobile: boolean) =>  StyleSheet.create({
    holdingView: {
        backgroundColor: Colors.disabledBackground,
        borderRadius: 15,
        paddingHorizontal: 8,
        paddingVertical: 2,
        borderWidth: 2,
        borderColor: Colors.transparent,
        flexDirection: "row",
        flexShrink: 1,
        flexGrow: 0,
    },
    activeHoldingView: {
        backgroundColor: Colors.middleGreen,
    },
    activeHoldingViewText: {
        color: Colors.white,
    },
    row: {
        flexDirection: "row",
        alignItems: "center",
        ...(isMobile && { justifyContent: "space-between" }),
    },
    separator: {
        marginLeft: 10,
    },
    active: {
        fontWeight: "bold",
        fontSize: 13
    },
    divider: {
        height: 1,
        backgroundColor: '#DDDDDD',
        ...(isMobile ? { marginBottom: 25 } : { marginBottom: 15 }),
    },
    ellipsisText: {
        flexShrink: 1,
        flexGrow: 0
    },
    marginLeft: {
        marginLeft: 20,
    },
    rowHover: {
        borderColor: Colors.middleGreen,
        borderWidth: 2,
    },
    marginBottom: {
        ...(isMobile ? { marginBottom: 20 } : { marginBottom: 15 }),
    },
    actions: {
        marginLeft: 10,
        flexDirection: "row",
        alignItems: "center",
    },
});
