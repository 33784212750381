import {
  ExternalServicesList,
  FastImage,
  Icon,
  PosScheduleList,
} from "@atomic";
import { Colors, Spacing } from "@constants";
import { PointOfSaleActions, RestaurantCardViewModel } from "@modules";
import { NavigationProp, Route } from "@react-navigation/native";
import { GlobalState } from "@redux";
import { Title16, Title18, Title22, Title36 } from "@stylesheets";
import { TestIDs } from "@utils";
import _ from "lodash";
import React, { useMemo, useRef } from "react";
import { View, StyleSheet, Pressable, Animated } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { I18n } from "react-redux-i18n";
import { useDevices } from "@hooks";

interface IProps {
  navigation: NavigationProp<any>;
  route?: Route<any>;
}

const DURATION = 300;

export const RestaurantInfo: React.FC<IProps> = React.memo(
  ({ navigation, route }) => {
    const dispatch = useDispatch();
    const [isMobile] = useDevices();

    const [isScheduleOpen, setIsScheduleOpen] = React.useState(false);

    const scheduleContainerHeight = useRef(new Animated.Value(0)).current;
    const scheduleContainerOpacity = useRef(new Animated.Value(0)).current;
    const styles = useMemo(() => _styles(isMobile), [isMobile]);

    const StatusTimeLabel: React.FC<any> = isMobile ? Title18 : Title22;
    const Description: React.FC<any> = isMobile ? Title16 : Title22;

    const pointOfSale = useSelector(
      (state: GlobalState) => state.pointOfSale?.selectedPos?.pointOfSale
    );

    if (!pointOfSale) return null;

    const {
      schedules,
      image,
      description,
      name,
      externalServices,
    } = pointOfSale;

    const restaurantCardViewModel = new RestaurantCardViewModel();

    const mappedSchedules = restaurantCardViewModel.getMappedSchedules(
      schedules
    );

    const posStatus = restaurantCardViewModel.getPosStatusFromMappedSchedules(
      mappedSchedules
    );

    const formatScheduleText = restaurantCardViewModel.formatScheduleText();

    const posStatusLabel = I18n.t(
      `homepage.restaurantCard.${posStatus.status.toLowerCase()}`
    );

    const status = restaurantCardViewModel.getStatus();
    dispatch(PointOfSaleActions.setPosStatus(status));

    const finalDescription = restaurantCardViewModel.getRestaurantDescription(
      name,
      description
    );

    const arrowIcon = isScheduleOpen
      ? "Accordeon_arrow_open_white"
      : "Accordeon_arrow_default_white";

    const handleShowSchedules = () => {
      setIsScheduleOpen(!isScheduleOpen);
      !isScheduleOpen ? openScheduleList() : closeScheduleList();
    };

    const openScheduleList = () => {
      Animated.timing(scheduleContainerHeight, {
        toValue: 180,
        duration: DURATION,
        useNativeDriver: false,
      }).start();
      Animated.timing(scheduleContainerOpacity, {
        toValue: 1,
        duration: 500,
        useNativeDriver: false,
      }).start();
    };

    const closeScheduleList = () => {
      Animated.timing(scheduleContainerHeight, {
        toValue: 0,
        duration: DURATION,
        useNativeDriver: false,
      }).start();
      Animated.timing(scheduleContainerOpacity, {
        toValue: 0,
        duration: DURATION,
        useNativeDriver: false,
      }).start();
    };

    return (
      <View style={styles.container}>
        {!isMobile && (
          <Title36
            style={styles.title}
            testID={TestIDs.restaurantDetail.texts.title}
          >
            {name}
          </Title36>
        )}
        <View style={styles.detailsContainer}>
          <View>
            <FastImage
              width={481}
              forwardTestID={TestIDs.restaurantDetail.views.image}
              imagePath={image}
            />
          </View>
          {isMobile && (
            <Title22
              isBlack
              style={styles.title}
              testID={TestIDs.restaurantDetail.texts.titleMobile}
            >
              {name}
            </Title22>
          )}
          <View style={styles.rightDiv}>
            <View style={styles.opening}>
              <StatusTimeLabel
                testID={TestIDs.restaurantDetail.texts.posLabel}
                isBold
                style={{
                  color: status ? Colors.middleGreen : Colors.redError,
                }}
              >
                {posStatusLabel}
              </StatusTimeLabel>
              {formatScheduleText && (
                <>
                  <StatusTimeLabel
                    testID={TestIDs.restaurantDetail.texts.nextHour}
                    isBold
                    style={styles.hours}
                  >
                    ⋅{formatScheduleText}
                  </StatusTimeLabel>
                  <Pressable
                    testID={TestIDs.restaurantDetail.actions.toggleScheduleList}
                    style={styles.arrowContainer}
                    onPress={handleShowSchedules}
                  >
                    <Icon
                      name={arrowIcon}
                      color={Colors.foodiBlack}
                      size={14}
                    />
                  </Pressable>
                </>
              )}
            </View>
            <Animated.View
              style={{
                height: scheduleContainerHeight,
                opacity: scheduleContainerOpacity,
              }}
            >
              {isScheduleOpen && (
                <PosScheduleList schedules={mappedSchedules} />
              )}
            </Animated.View>

            <Description
              testID={TestIDs.restaurantDetail.texts.content}
              style={styles.content}
            >
              {finalDescription}
            </Description>
            <View style={styles.externalServicesDiv}>
              <ExternalServicesList externalServices={externalServices} />
            </View>
          </View>
        </View>
      </View>
    );
  }
);

const _styles = (isMobile: boolean) =>
  StyleSheet.create({
    container: {
      paddingTop: isMobile ? 0 : Spacing.XL,
      paddingBottom: Spacing.XL,
      backgroundColor: Colors.white,
    },
    title: {
      textAlign: "left",
      marginTop: 11,
      paddingLeft: isMobile ? 18 : 0,
    },
    arrowContainer: {
      marginLeft: 10,
    },
    detailsContainer: {
      marginTop: isMobile ? 0 : Spacing.XL,
      flexDirection: isMobile ? "column" : "row",
    },
    rightDiv: {
      flex: 1,
      marginLeft: isMobile ? 18 : 130,
      height: "100%",
    },
    externalServicesDiv: {
      marginTop: 15,
    },
    opening: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    content: {
      marginTop: Spacing.M,
      fontFamily: "manrope-regular",
      color: "#313234",
      textAlign: "left",
      paddingRight: isMobile ? 18 : 0,
    },
    hours: {
      color: Colors.foodiBlack,
    },
  });
