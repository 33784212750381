import style from "styled-components/native";

export const Container = style.View`
    margin-top: 10px;
`;

export const Row = style.View<{ isMobile?: boolean }>`
    flex-direction: row;
    justify-content: ${({ isMobile }) => isMobile ? "flex-start" : "center"};
    margin-left: ${({ isMobile }) => isMobile ? "15px" : "0"};
`;
