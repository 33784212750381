"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_MARKETING_CARDS = void 0;
const client_1 = require("@apollo/client");
const marketingCardFragment_1 = require("./marketingCardFragment");
exports.GET_MARKETING_CARDS = client_1.gql `
  ${marketingCardFragment_1.MARKETING_CARD_FRAGMENT}
  query getMarketingCards {
    getUser {
      id
      guests {
        edges {
          node {
            id
            holding {
              id
              marketingCards {
                ...marketingCardFragment
              }
            }
          }
        }
      }
    }
  }
`;
