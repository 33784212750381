"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userHoldingViewFixtures = void 0;
exports.userHoldingViewFixtures = [
    {
        id: "HoldingView:2",
        idHolding: "142",
        guestLastName: "McConnell",
        serialNumber: "1234567",
        alias: "test",
    },
    {
        id: "HoldingView:14",
        idHolding: "143",
        guestLastName: "",
        serialNumber: "",
        alias: "test",
    },
];
