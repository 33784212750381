"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.POINTS_OF_SALE_FRAGMENT_2 = void 0;
const client_1 = require("@apollo/client");
exports.POINTS_OF_SALE_FRAGMENT_2 = client_1.gql `
  fragment pointsOfSaleFragment2 on Pos {
    id
    concept {
      name
      image
    }
    type
    name
    status @client
    schedules {
      days
      hours
    }
    zone {
      id
      name
      holding {
        id
        importationType
        settings {
          productTransparencyPreferences {
            showLabels
            showIngredients
            showAllergens
            showNutriScore
            showEcoScore
            showCalories
            showNutritionalData
            showPhoto
            showPrice
            showDescription
          }
          offersProductTransparencyPreferences {
            showLabels
            showIngredients
            showAllergens
            showNutriScore
            showEcoScore
            showCalories
            showNutritionalData
            showPhoto
            showPrice
            showDescription
          }
        }
      }
    }
    externalServices {
      title
      content
      active
    }
    content
    description
    image
    hideMenuBooking
    typeModel {
      id
      name
      group
      image
    }
  }
`;
