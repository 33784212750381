"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetPointsOfSale = void 0;
class GetPointsOfSale {
    constructor(pointsOfSaleService) {
        this.pointsOfSaleService = pointsOfSaleService;
    }
    execute(request) {
        return this.pointsOfSaleService.getPointsOfSale(request);
    }
}
exports.GetPointsOfSale = GetPointsOfSale;
