import React from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { useDispatch } from "react-redux";
import {
  EPointageRules,
  ScheduleTimeline,
  SchedulerViewModel,
  SchedulerWithDynamicKeys,
  SchedulersWithDynamicKeys,
  Schedules,
} from "@modules";
import { Colors } from "@constants";
import { Title16 } from "@stylesheets";
import { EpointageClassDetail, Icon } from "@atomic";
import { User } from "@assets";
import { ComputedClassroom } from "@foodi/core";

interface IProps {
  scheduler?: SchedulerWithDynamicKeys;
  mappedScheduler?: SchedulersWithDynamicKeys;
  serviceId: number;
  classroomId?: string;
  schoolId: string;
  selectedDate: string;
  userId?: string;
  userRole?: string;
  handleSchedulerInfo: () => void;
}

export const EpointageClassrooms: React.FC<IProps> = ({
  scheduler,
  mappedScheduler,
  serviceId,
  schoolId,
  classroomId,
  selectedDate,
  userId = "",
  userRole = EPointageRules.Client,
  handleSchedulerInfo,
}) => {
  const dispatch = useDispatch();
  const [schedulerVM] = React.useState<SchedulerViewModel>(
    new SchedulerViewModel(dispatch)
  );
  const classrooms = schedulerVM.getClassroomsFromScheduler(
    scheduler,
    classroomId
  );

  const ClassroomRow: React.FC<{
    classroom: ComputedClassroom;
  }> = ({ classroom }) => {
    const [classDetailOpen, setClassDetailOpen] = React.useState<boolean>(
      !!classroomId
    );
    const schedules = schedulerVM.getSchedulesFromClassroom(
      classroom,
      selectedDate
    );

    return (
      <>
        {classDetailOpen ? (
          <EpointageClassDetail
            classroomId={classroom.id}
            serviceId={serviceId}
            schoolId={schoolId}
            schedulerMapped={mappedScheduler}
            schedules={schedules}
            closeDetail={() => setClassDetailOpen(false)}
            selectedDate={selectedDate}
            userId={userId}
            userRole={userRole}
            handleSchedulerInfo={handleSchedulerInfo}
          />
        ) : (
          <Pressable
            style={styles.row}
            onPress={() => setClassDetailOpen(!classDetailOpen)}
          >
            <View style={styles.classContainer}>
              <View style={styles.nameWrapper}>
                <Title16 bold>{classroom.name}</Title16>
              </View>
              {schedules?.map((s: Schedules, index: number) => {
                const havePrecheck =
                  (s.haveInitialPreCheck || s.needsPrecheckFromPrevious) &&
                  s.schedulerTimeline === ScheduleTimeline.CURRENT_SCHEDULE;

                return (
                  <View key={index} style={styles.sectionWrapper}>
                    <User
                      color={
                        havePrecheck
                          ? Colors.epointageOrange
                          : s.currentStyleSchedule.deadlineColor
                      }
                    />
                    <Title16
                      bold
                      color={
                        havePrecheck
                          ? Colors.epointageOrange
                          : s.currentStyleSchedule.deadlineColor
                      }
                    >
                      {s?.schedulerTimeline !== ScheduleTimeline.FUTURE_SCHEDULE
                        ? s?.students?.length
                        : 0}
                    </Title16>
                  </View>
                );
              })}
              <View style={styles.arrowWrapper}>
                <Icon
                  size={12}
                  color={Colors.foodiBlack}
                  name="Accordeon_arrow_default_black"
                />
              </View>
            </View>
          </Pressable>
        )}
      </>
    );
  };

  return (
    <View style={styles.listContainer}>
      {!!scheduler &&
        classrooms?.map((key: string, index: number) => (
          <ClassroomRow key={index} classroom={scheduler[key]} />
        ))}
    </View>
  );
};

const styles = StyleSheet.create({
  listContainer: {
    flex: 1,
    width: "100%",
  },
  row: {
    height: 46,
    width: "100%",
    boxSizing: "border-box",
    border: `1px solid ${Colors.disabledBackground}`,
    borderRadius: 8,
    backgroundColor: Colors.white,
    alignItems: "center",
    justifyContent: "space-evenly",
    flexDirection: "row",
    paddingVertical: 12,
    paddingHorizontal: 20,
    marginTop: 20,
  },
  nameWrapper: {
    flex: 1,
    maxWidth: 310,
    justifyContent: "center",
    alignItems: "flex-start",
  },
  sectionWrapper: {
    flex: 1,
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "row",
  },
  arrowWrapper: {
    position: "absolute",
    right: 0,
    alignSelf: "center",
  },
  classContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
});
