import { Colors } from "@constants";
import style from "styled-components/native";

export const Wrapper = style.View<{ isMobile?: boolean }>`
    width: 100%;
    height: 100%;
    padding: ${({ isMobile }) =>
      isMobile ? "80px 18px 60px" : "89px 150px 30px"};
`;

export const Container = style.View<{ isMobile?: boolean }>`
    border: ${({ isMobile }) => (isMobile ? 0 : 1)}px solid ${Colors.border1};
    border-radius: 8px;
    margin-top: ${({ isMobile }) => (isMobile ? 60 : 20)}px;
    padding: ${({ isMobile }) => (isMobile ? "0px" : "81px 10px 0px")};;
    align-items: center;
`;

export const ContentContainer = style.View`
    align-items: center;
    height: 53.5vh;
`;

export const TextWrapper = style.View<{ marginTop?: number }>`
    margin-top: ${({ marginTop }) => marginTop}px;
`;
