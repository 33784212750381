"use strict";
/* istanbul ignore file */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RetrieveTokens = void 0;
class RetrieveTokens {
    constructor(authService) {
        this.authService = authService;
    }
    execute(request) {
        const { dispatchCallback } = request;
        return this.authService.retrieveTokens(dispatchCallback);
    }
}
exports.RetrieveTokens = RetrieveTokens;
