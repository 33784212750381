"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.newsFixtures = void 0;
exports.newsFixtures = {
    isLoading: false,
    marketingCardsNew: [
        {
            id: "TWFya2V0aW5nQ2FyZE5ldzozNTQ=",
            type: "IMPORTANT_MESSAGE",
            publicationRange: "2021-11-30T23:00:00.000+00:00/3000-01-01T00:00:00.000+00:00",
            linkToExternalPage: null,
            mainImage: {
                path: "_app/user-media/86c5798f5bc16b3fa8e72cac3efe024781918afc.png",
                __typename: "Image",
            },
            marketingCardContents: [
                {
                    id: "TWFya2V0aW5nQ2FyZENvbnRlbnQ6MzU0",
                    resume: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent facilisis nisl eget consequat viverra. Donec consectetur orci vel rutrum",
                    language: "fr",
                    contentTitle: "test display article ",
                    content: [],
                    __typename: "MarketingCardContent",
                },
                {
                    id: "TWFya2V0aW5nQ2FyZENvbnRlbnQ6MzU0",
                    resume: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent facilisis nisl eget consequat viverra. Donec consectetur orci vel rutrum",
                    language: "en",
                    contentTitle: "test display article ",
                    content: [],
                    __typename: "MarketingCardContent",
                },
            ],
            __typename: "MarketingCardNew",
        },
        {
            id: "TWFya2V0aW5nQ2FyZE5ldzozNTI=",
            type: "IMPORTANT_MESSAGE",
            publicationRange: "2021-10-28T23:00:00.000+00:00/3000-01-01T00:00:00.000+00:00",
            linkToExternalPage: "https://youtu.be/DQGPuSWoR1w",
            mainImage: {
                path: "_app/user-media/dc9a11916faea5db1b1150769aa24dbd2d07ba99.png",
                __typename: "Image",
            },
            marketingCardContents: [
                {
                    id: "TWFya2V0aW5nQ2FyZENvbnRlbnQ6MzUy",
                    resume: "FS-1735-[1]",
                    language: "fr",
                    contentTitle: "FS-1735-[1]",
                    content: [],
                    __typename: "MarketingCardContent",
                },
            ],
            __typename: "MarketingCardNew",
        },
        {
            id: "TWFya2V0aW5nQ2FyZE5ldzozNDg=",
            type: "ARTICLE",
            publicationRange: "2021-07-06T22:00:00.000+00:00/3000-01-01T00:00:00.000+00:00",
            linkToExternalPage: null,
            mainImage: {
                path: "_app/user-media/d088c724bad429564ea7950e12029fa9ed213bd5.jpg",
                __typename: "Image",
            },
            marketingCardContents: [
                {
                    id: "TWFya2V0aW5nQ2FyZENvbnRlbnQ6MzQ4",
                    resume: "",
                    language: "fr",
                    contentTitle: "Test",
                    content: [
                        {
                            key: "paragraph",
                            content: '<p class="title">This is a title</p><p class="subtitle">this is a subtitle</p><p>this is some content</p>',
                            __typename: "MarketingCardKeyContent",
                        },
                    ],
                    __typename: "MarketingCardContent",
                },
            ],
            __typename: "MarketingCardNew",
        },
        {
            id: "TWFya2V0aW5nQ2FyZE5ldzozMzU=",
            type: "ARTICLE",
            publicationRange: "2021-06-14T23:00:00.000+00:00/3000-01-01T00:00:00.000+00:00",
            linkToExternalPage: null,
            mainImage: {
                path: "_app/user-media/0da08c51d5bb63666b08c5da4e9fdd2a098c40a4.jpg",
                __typename: "Image",
            },
            marketingCardContents: [
                {
                    id: "TWFya2V0aW5nQ2FyZENvbnRlbnQ6MzMz",
                    resume: " Découvrez le nouveau site web Eurest, en cliquant ici",
                    language: "fr",
                    contentTitle: "Eurest fait peau neuve ! ✏️",
                    content: [
                        {
                            key: "paragraph",
                            content: '<p>Plus qu\'une envie, notre mission.</p><p>Chez Eurest, nous nourrissons une envie : <strong>faire le bon autour de nous. Faire le bon dans votre assiette et être bon avec le monde qui nous entoure</strong>.</p><p>Et c’est plus qu’une ambition, <strong>c’est notre mission.</strong></p><p>Découvrez le nouveau site web en cliquant <a target="_blank" rel="noopener noreferrer" href="https://www.eurest.fr/">ICI</a> !</p><p>&nbsp;</p>',
                            __typename: "MarketingCardKeyContent",
                        },
                        {
                            key: "paragraph",
                            content: '<p>Présentation de la nouvelle offre Eurest</p><figure class="media"><div data-oembed-url="https://vimeo.com/551830242"><div style="position: relative; padding-bottom: 100%; height: 0; padding-bottom: 56.2493%;"><iframe src="https://player.vimeo.com/video/551830242" style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen="" frameborder="0"></iframe></div></div></figure>',
                            __typename: "MarketingCardKeyContent",
                        },
                        {
                            key: "infoNews",
                            content: '<a href="https://www.eurest.fr/" target="_blank" style="width: 100%;"><div id="isValid" style="position: relative; width: 100%; border-radius: 8px; margin-top: 20px;">\n                            <img alt="SW1hZ2U6MTk5" src="https://images.dev01.foodi.fr//resize/225x374/_app/user-media/86c5798f5bc16b3fa8e72cac3efe024781918afc.png" style="width: 100%; position: relative; border-radius: 8px;"/>\n                        <div style="position: absolute; top: 0px; margin: 20px; display: flex; flex-direction: column; justify-content: space-between; height: 85%;">\n                            <div>\n                                <p id="Title" style="margin: 0; color: #FFFFFF; font-family:\'Open Sans\', sans-serif; font-size: 18px; font-weight: 600; letter-spacing: 0; line-height: 22px;">“La bonne cuisine est honnête, sincère et simple.”</p>\n                                <br/>\n                                <p id="Content" style="margin: 0; color: #FFFFFF; font-family:\'Open Sans\', sans-serif; font-size: 16px; letter-spacing: 0; line-height: 20px;">N’hésitez pas à visiter le nouveau sit internet Eurest !</p>\n                            </div>\n                            <span style="color: #FFFFFF; font-family:\'Open Sans\', sans-serif; font-size: 18px; margin-bottom: 10px;">VOIR PLUS > </span>\n                        </div>\n                    </div></a>',
                            __typename: "MarketingCardKeyContent",
                        },
                        {
                            key: "image",
                            content: '[{"id":"SW1hZ2U6MjY0","hash":"cf7d8d98fd3ef68883d28983485d3f53537f83ad","path":"_app/user-media/cf7d8d98fd3ef68883d28983485d3f53537f83ad.jpg","created":"2021-06-15T07:26:35.947Z","__typename":"Image","order":1},{"id":"SW1hZ2U6MjU1","hash":"8e13d9387e206e5964c753c523c15d7009f40fb4","path":"_app/user-media/8e13d9387e206e5964c753c523c15d7009f40fb4.jpg","created":"2021-06-11T13:43:46.520Z","__typename":"Image","order":2},{"id":"SW1hZ2U6MjU4","hash":"62121131c793e4886b0b6c86ea02074c884d16ec","path":"_app/user-media/62121131c793e4886b0b6c86ea02074c884d16ec.jpg","created":"2021-06-14T08:52:11.376Z","__typename":"Image","order":3},{"id":"SW1hZ2U6MjU2","hash":"c613f020a921e4f39a60ad6ebd2a695c7187dc0d","path":"_app/user-media/c613f020a921e4f39a60ad6ebd2a695c7187dc0d.jpg","created":"2021-06-11T13:43:56.347Z","__typename":"Image","order":4},{"id":"SW1hZ2U6MjU3","hash":"0da08c51d5bb63666b08c5da4e9fdd2a098c40a4","path":"_app/user-media/0da08c51d5bb63666b08c5da4e9fdd2a098c40a4.jpg","created":"2021-06-11T13:44:04.345Z","__typename":"Image"},{"id":"SW1hZ2U6MjQz","hash":"7c5a47349da1f281b35037720ad67ac46eefe8a8","path":"_app/user-media/7c5a47349da1f281b35037720ad67ac46eefe8a8.png","created":"2021-05-26T14:33:20.970Z","__typename":"Image"}]',
                            __typename: "MarketingCardKeyContent",
                        },
                    ],
                    __typename: "MarketingCardContent",
                },
                {
                    id: "TWFya2V0aW5nQ2FyZENvbnRlbnQ6MzMy",
                    resume: "Discover the new website by clicking here!",
                    language: "en",
                    contentTitle: "New Eurest website is alive ! 🎉",
                    content: [
                        {
                            key: "paragraph",
                            content: "<p>Discover the wesite by clicking HERE !</p>",
                            __typename: "MarketingCardKeyContent",
                        },
                        {
                            key: "paragraph",
                            content: '<p>Eurest new offer presentation</p><figure class="media"><div data-oembed-url="https://vimeo.com/551830242"><div style="position: relative; padding-bottom: 100%; height: 0; padding-bottom: 56.2493%;"><iframe src="https://player.vimeo.com/video/551830242" style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen="" frameborder="0"></iframe></div></div></figure>',
                            __typename: "MarketingCardKeyContent",
                        },
                        {
                            key: "infoNews",
                            content: '<a href="https://www.eurest.fr/" target="_blank" style="width: 100%;"><div id="isValid" style="position: relative; width: 100%; border-radius: 8px; margin-top: 20px;">\n                            <img alt="SW1hZ2U6MTk5" src="https://images.dev01.foodi.fr//resize/225x374/_app/user-media/86c5798f5bc16b3fa8e72cac3efe024781918afc.png" style="width: 100%; position: relative; border-radius: 8px;"/>\n                        <div style="position: absolute; top: 0px; margin: 20px; display: flex; flex-direction: column; justify-content: space-between; height: 85%;">\n                            <div>\n                                <p id="Title" style="margin: 0; color: #FFFFFF; font-family:\'Open Sans\', sans-serif; font-size: 18px; font-weight: 600; letter-spacing: 0; line-height: 22px;">“Good food is honest, sincere and straightforward.”</p>\n                                <br/>\n                                <p id="Content" style="margin: 0; color: #FFFFFF; font-family:\'Open Sans\', sans-serif; font-size: 16px; letter-spacing: 0; line-height: 20px;">Don’t hesitate to visit the new Eurest website !</p>\n                            </div>\n                            <span style="color: #FFFFFF; font-family:\'Open Sans\', sans-serif; font-size: 18px; margin-bottom: 10px;">SEE MORE > </span>\n                        </div>\n                    </div></a>',
                            __typename: "MarketingCardKeyContent",
                        },
                        {
                            key: "image",
                            content: '[{"id":"SW1hZ2U6MjY0","hash":"cf7d8d98fd3ef68883d28983485d3f53537f83ad","path":"_app/user-media/cf7d8d98fd3ef68883d28983485d3f53537f83ad.jpg","created":"2021-06-15T07:26:35.947Z","__typename":"Image","order":1},{"id":"SW1hZ2U6MjU1","hash":"8e13d9387e206e5964c753c523c15d7009f40fb4","path":"_app/user-media/8e13d9387e206e5964c753c523c15d7009f40fb4.jpg","created":"2021-06-11T13:43:46.520Z","__typename":"Image","order":2},{"id":"SW1hZ2U6MjU4","hash":"62121131c793e4886b0b6c86ea02074c884d16ec","path":"_app/user-media/62121131c793e4886b0b6c86ea02074c884d16ec.jpg","created":"2021-06-14T08:52:11.376Z","__typename":"Image","order":3},{"id":"SW1hZ2U6MjU2","hash":"c613f020a921e4f39a60ad6ebd2a695c7187dc0d","path":"_app/user-media/c613f020a921e4f39a60ad6ebd2a695c7187dc0d.jpg","created":"2021-06-11T13:43:56.347Z","__typename":"Image","order":4},{"id":"SW1hZ2U6MjU3","hash":"0da08c51d5bb63666b08c5da4e9fdd2a098c40a4","path":"_app/user-media/0da08c51d5bb63666b08c5da4e9fdd2a098c40a4.jpg","created":"2021-06-11T13:44:04.345Z","__typename":"Image"},{"id":"SW1hZ2U6MjQz","hash":"7c5a47349da1f281b35037720ad67ac46eefe8a8","path":"_app/user-media/7c5a47349da1f281b35037720ad67ac46eefe8a8.png","created":"2021-05-26T14:33:20.970Z","__typename":"Image"}]',
                            __typename: "MarketingCardKeyContent",
                        },
                    ],
                    __typename: "MarketingCardContent",
                },
            ],
            __typename: "MarketingCardNew",
        },
    ],
};
