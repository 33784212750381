"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SIGNUP = void 0;
const client_1 = require("@apollo/client");
exports.SIGNUP = client_1.gql `
  mutation signUpV2($input: InputSignupV2!) {
    signupV2(input: $input) {
        id
    }
  }
`;
