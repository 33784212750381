"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetOffer = void 0;
class GetOffer {
    constructor(offerService) {
        this.offerService = offerService;
    }
    execute(request) {
        return this.offerService.getOffer(request);
    }
}
exports.GetOffer = GetOffer;
