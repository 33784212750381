import { AccountScreens } from "@modules";
import { createStackNavigator } from "@react-navigation/stack";
import React from "react";
import { Step1, Step2, Step3 } from "../screens";

export type AccountStackParamList = {
  [AccountScreens.STEP_ONE_SCREEN]: undefined;
  [AccountScreens.STEP_TWO_SCREEN]: undefined;
  [AccountScreens.STEP_THREE_SCREEN]: undefined;
};

const Stack = createStackNavigator<AccountStackParamList>();

export const AccountNavigation = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        name={AccountScreens.STEP_ONE_SCREEN}
        key="step1"
        component={Step1}
      />
      <Stack.Screen
        name={AccountScreens.STEP_TWO_SCREEN}
        key="step2"
        component={Step2}
      />
      <Stack.Screen
        name={AccountScreens.STEP_THREE_SCREEN}
        key="step3"
        component={Step3}
      />
    </Stack.Navigator>
  );
};
