"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RecentOrdersToRate = void 0;
class RecentOrdersToRate {
    constructor(orderRepository) {
        this.orderRepository = orderRepository;
    }
    execute() {
        return this.orderRepository.recentOrdersToRate();
    }
}
exports.RecentOrdersToRate = RecentOrdersToRate;
