import { Container, ButtonContainer, Button } from "./CompleteProfile.style";
import { TestIDs } from "@utils";
import React from "react";
import { Title16, Title18, Title22, Title30 } from "@stylesheets";
import { StyleSheet } from "react-native";
import { useSelector } from "react-redux";
import { GlobalState } from "@redux";
import { WarningOrange } from "@assets";
import { HomeScreens } from "@modules";
import { NavigationProp } from "@react-navigation/native";
import { useDevices } from "@hooks";
import { I18n } from "react-redux-i18n";
import { Colors, Spacing } from "@constants";

interface IProps {
  navigation: NavigationProp<any>;
  subtitle: string;
}

export const CompleteProfile: React.FC<IProps> = React.memo(
  ({ navigation, subtitle }) => {
    const brandTheme = useSelector(
      (state: GlobalState) => state.brandTheme.brandTheme
    );
    const [isMobile] = useDevices();

    const Title: React.FC<any> = isMobile ? Title22 : Title30;
    const SubTitle: React.FC<any> = isMobile ? Title16 : Title18;

    const goToProfile = () => {
      navigation.navigate(HomeScreens.PROFILE_STACK);
    };

    return (
      <Container>
        <Title isBlack style={styles.title}>
          <WarningOrange />{" "}
          {I18n.t("homepage.welcomingSentence.profileIncomplete")}
        </Title>
        <SubTitle style={styles.subTitle}>{subtitle}</SubTitle>
        <ButtonContainer isMobile={isMobile}>
          {isMobile ? (
            <Button
              testID={TestIDs.home.homepage.actions.goToProfile}
              onPress={goToProfile}
              color={
                brandTheme.buttonActionColors?.backgroundColor ||
                Colors.foodiDefault
              }
              textColor={
                brandTheme.buttonActionColors?.textColor || Colors.foodiBlack
              }
            >
              {I18n.t("homepage.welcomingSentence.completeMyProfile")}
            </Button>
          ) : (
            <Button
              testID={TestIDs.home.homepage.actions.goToProfile}
              onPress={goToProfile}
              color={
                brandTheme.buttonActionColors?.backgroundColor ||
                Colors.foodiDefault
              }
              textColor={
                brandTheme.buttonActionColors?.textColor || Colors.foodiBlack
              }
            >
              {I18n.t("homepage.welcomingSentence.completeMyProfile")}
            </Button>
          )}
        </ButtonContainer>
      </Container>
    );
  }
);

const styles = StyleSheet.create({
  title: {
    fontFamily: "manrope-regular",
    fontWeight: "normal",
  },
  subTitle: {
    fontFamily: "manrope-regular",
    marginTop: Spacing.S,
    marginHorizontal: Spacing.M,
    textAlign: "center",
  },
});
