"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MIGRATE_TO_HOLDING_VIEW = void 0;
const client_1 = require("@apollo/client");
exports.MIGRATE_TO_HOLDING_VIEW = client_1.gql `
  mutation migrateToHoldingView {
    migrateToHoldingView {
      id
      lastName
      firstName
      email
      language
      virtualTicket
      idHoldingView
    }
  }
`;
