import React from "react";

export const NextStep: React.FC = () => (
    <svg width="40px" height="40px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <title>DB374036-9432-4B1B-8003-832E52BAB686</title>
        <g id="Icons/Navigation/Next-step-black" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <circle id="Oval" stroke="#090909" cx="20" cy="20" r="19.5"></circle>
            <polygon id="Path-Copy" fill="#090909" fill-rule="nonzero" points="23 11 21.59 12.8128571 26.17 18.7142857 10 18.7142857 10 21.2857143 26.17 21.2857143 21.58 27.1871429 23 29 30 20"></polygon>
        </g>
    </svg>
);
