import { Icon } from "@atomic";
import { COMING_SOON_WIDTH } from "@atomic/organisms/ComingSoon/ComingSoon.style";
import { RESTAURANT_CARD_WIDTH } from "@atomic/organisms/RestaurantCard/RestaurantCard.style";
import { Colors } from "@constants";
import style from "styled-components/native";

export const Container = style.View<{ isMobile?: boolean }>`
    width: ${({ isMobile }) => (isMobile ? `${RESTAURANT_CARD_WIDTH}` : `${COMING_SOON_WIDTH}px`)};
    background-color: ${Colors.lightWhite};
    border-radius: 8px;
    padding: 8.5px 8.5px 11.5px 11.5px;
    position: absolute;
`;

export const ItemContainer = style.View`
    margin: 5px 0px 15px 13px;
`;

export const ItemTextContainer = style.View<{ noMarginLeft?: boolean }>`
    flex-direction: row;
    align-items: center;
    margin-left: ${({ noMarginLeft }) => (noMarginLeft ? 0 : '-13px')};
`;

export const Row = style.View`
    flex-direction: row;
    align-items: center;
`;

export const ItemTitle = style.Text<{ endOfList?: boolean }>`
    color: ${({ endOfList }) => (endOfList ? Colors.darkGray : Colors.black)};
    font-family: manrope-bold;
    font-size: 13px;
`;

export const ItemTitleState = style.Text<{ endOfList?: boolean, cancelled?: boolean, completed?:boolean }>`
    color: ${({ cancelled, completed }) => (
        cancelled ? 
            Colors.redError : 
            ( completed ? 
                Colors.darkGrey : 
                Colors.middleGreen
            )
    )};
    font-family: manrope-bold;
    font-size: 13px;
`;

export const ItemDescription = style.Text<{ isDate?: boolean }>`
    color: ${({ isDate }) => (isDate ? Colors.lightGray : Colors.black)};
    font-family: "manrope";
    font-size: 13px;
`;

export const GreenCircle = style.View`
    height: 10px;
    width: 10px;
    border-radius: 5;
    background-color: ${Colors.middleGreen};
    margin-right: 3px;
`;

export const Separator = style.View`
    height: 1px;
    width: 100%;
    background-color: ${Colors.middleGray};
    margin-top: 4px;
    margin-bottom: 5px;
`;

export const LastItemContainer = style.View`
    margin: 10px 0px 10px 16.5px;
    flex-direction: row;
    align-items: center;
`;

export const IconContainer = style(Icon)`
    margin: 1.5px 0px 0px 2px;
`;

export const ScrollContainer = style.View`
    position: absolute;
    bottom: 60;
    right: 10;
`;
