import { Colors } from "@constants";
import { Platform, ViewStyle } from "react-native";
import style from "styled-components/native";

export const RESTAURANT_CARD_WIDTH = 360;

export const webBoxShadow = Platform.select({
  web: {
    boxShadow: `0 2px 2px 0 ${Colors.darkGrey}`,
  },
}) as ViewStyle;

export const Container = style.Pressable`
    width: ${RESTAURANT_CARD_WIDTH}px;
    margin: 1px;
    height: 160px;
    border-radius: 8px;
    background-color: ${Colors.background1};
`;

export const Row = style.View`
    flex-direction: row;
`;

export const ImageContainer = style.Image`
    width: 140px;
    height: 160px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
`;

export const TextContainer = style.View`
    padding: 25px 12px 20px;
    justify-content: space-between;
    flex: 1;
`;

export const Title = style.Text`
    color: ${Colors.foodiBlack};
    font-family: manrope-bold;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0;
    flex: 1;
    flex-wrap: wrap;
`;

export const DescriptionContainer = style.View``;

export const Description = style.Text`
    color: ${Colors.foodiBlack};
    font-family: manrope;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0;
    margin-bottom: 2px;
`;

export const Status = style.Text<{ isOpened?: boolean }>`
    font-family: manrope-bold;
    color: ${({ isOpened }) =>
      isOpened ? Colors.restaurantCardStatusGreen : Colors.redError};
`;

export const Hours = style.Text`
    font-family: manrope;
    color: ${Colors.footerGray};
`;

export const Separator = style.View`
    width: 1.5px;
    height: 1.5px;
    background-color: ${Colors.footerGray};
    border-radius: 0.75px;
    align-self: center;
    margin: 0px 2px;
`;
