import { TicketResponse, GetTicketCommand } from "@foodi/core";
import { ThunkResult } from "@redux";
import { displayToastError } from "@utils";

const ThunkActionCreators = {
  getTicket: (
    params: GetTicketCommand
  ): ThunkResult<Promise<TicketResponse>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { getTicket } = getDependencies();
      return getTicket.execute(params);
    } catch (e) {
      displayToastError(dispatch);
      return Promise.reject(e);
    }
  },
};

export { ThunkActionCreators as TicketThunks };
