"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.offerSlotsFixture = void 0;
exports.offerSlotsFixture = {
    offer: {
        id: "T2ZmZXI6NDQzMDg=",
        slots: [
            {
                numOrders: 0,
                selectableAt: true,
                withdrawRange: "2022-10-28T10:00:00.000+00:00/2022-10-28T10:05:00.000+00:00",
                __typename: "OfferSlot",
            },
            {
                numOrders: 0,
                selectableAt: true,
                withdrawRange: "2022-10-28T10:05:00.000+00:00/2022-10-28T10:10:00.000+00:00",
                __typename: "OfferSlot",
            },
            {
                numOrders: 0,
                selectableAt: true,
                withdrawRange: "2022-10-28T10:10:00.000+00:00/2022-10-28T10:15:00.000+00:00",
                __typename: "OfferSlot",
            },
            {
                numOrders: 0,
                selectableAt: true,
                withdrawRange: "2022-10-28T10:15:00.000+00:00/2022-10-28T10:20:00.000+00:00",
                __typename: "OfferSlot",
            },
        ],
        __typename: "Offer",
    }
};
