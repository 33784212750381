import React from "react";
import { PixelRatio, Pressable } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { navigationService } from "@navigation";
import { Route } from "@react-navigation/native";
import { ComingSoonContainer, ExternalServicesHome, Icon } from "@atomic";
import { Colors } from "@constants";
import { GlobalState, ForceInfoAppUpdateActions, LoaderActions } from "@redux";
import { getCloudImageUrl, isAccountActivated, TestIDs, hasAccessTokenExpired } from "@utils";
import { useDevices, useLogoIconForBrand } from "@hooks";
import {
  Container,
  BalanceContainer,
  Amount,
  RightSide,
  UserName,
  ConnectionContainer,
  LogoIcon,
} from "./Header.style";
import { NavigationProp } from "@react-navigation/native";
import {
  AccountThunks,
  AuthThunks,
  HoldingThunks,
  HomeScreens,
  ProfileScreens,
  ProfileTabs,
} from "@modules";
import { getApolloClient } from "../../../apollo";
import { I18n } from "react-redux-i18n";
import _ from "lodash";

interface IProps {
  balanceAmount?: string;
  navigation?: NavigationProp<any>;
  route?: Route<any>;
}

export const HEADER_HEIGHT = 60;

export const Header: React.FC<IProps> = ({
  balanceAmount = "-",
  navigation,
  route,
}) => {
  const dispatch = useDispatch();

  //selectors
  const { holdingName, hardLinked, id } =
    useSelector((state: GlobalState) => state?.auth?.userInfo) ?? {};
  const isLogin = useSelector((state: GlobalState) => state.auth?.isLogin);
  const accessToken = useSelector(
    (state: GlobalState) => state?.auth?.authToken?.accessToken
  );
  const haveOpacity = useSelector(
    (state: GlobalState) => state.header.haveOpacity
  );
  const amountValue =
    useSelector((state: GlobalState) => state.account?.userBalance?.balance?.amount) ||
    balanceAmount;
  const brandName = useSelector(
    (state: GlobalState) => state.auth?.userInfo?.holdingBrand
  );
  const isBadgeRequired = useSelector(
    (state: GlobalState) => state.auth?.userInfo?.isBadgeRequired
  );
  const externalUrl = useSelector(
    (state: GlobalState) => state.auth?.userInfo?.externalUrl
  );
  const externalsServices =
    useSelector(
      (state: GlobalState) => state?.marketingCard?.externalsServices
    ) || [];
  const userName =
    useSelector((state: GlobalState) => state.auth?.userInfo?.firstName) ||
    I18n.t("homepage.login");
  const { logo } = useSelector(
    (state: GlobalState) => state.brandTheme.brandTheme
  );

  // to update language => DONT REMOVE IT
  const localLanguage = useSelector((state: GlobalState) => state.i18n.locale);

  const [isMobile] = useDevices();

  const [balanceIsHover, setBalanceIsHover] = React.useState(false);

  const [loginIsHover, setLoginIsHover] = React.useState(false);

  const routeName = navigationService.getActiveRouteName();

  const DefaultLogoIcon = useLogoIconForBrand(brandName);

  const logoLink = isMobile ? logo?.mobile : logo?.web;

  const logoUri = getCloudImageUrl(
    logoLink,
    "width",
    String(PixelRatio.getPixelSizeForLayoutSize(isMobile ? 90 : 140))
  );
  const iconSize = isMobile ? 18 : 20;

  /* istanbul ignore next */
  const isHomePage = routeName === undefined || routeName === "HomePage";

  const handleOpacity =
    routeName === undefined ||
    (routeName === "HomePage" && isLogin && !haveOpacity);

  const isNotSticky = !isHomePage;

  const isAccountValidated = isAccountActivated(accessToken);

  const hasTokenExpired = hasAccessTokenExpired(accessToken);

  const isExternalLinksActive =
    !!externalsServices?.length &&
    !externalsServices.every((service) => !service.active || !service.content);

  const handleGetBalance = () => {
    if (hasTokenExpired) return;
    dispatch(
      AccountThunks.getBalance({
        id: id || "",
      })
    );
  };

  React.useEffect(() => {
    if (hasTokenExpired) return;
    if (id && isLogin && accessToken) {
      const balanceInterval = setInterval(
        handleGetBalance,
        window.config.BALANCE_POLLING_INTERVAL
      );
      return () => clearInterval(balanceInterval);
    }
  }, [id, isLogin, hasTokenExpired]);

  const handleLogin = async () => {
    if (isLogin && !isAccountValidated) {
      if (hardLinked) {
        const res = await dispatch(HoldingThunks.migrateToHoldingView());

        //@ts-ignore
        if (res) {
          await getApolloClient().cache.reset();

          //@ts-ignore
          navigation?.push(HomeScreens.HOME_SCREEN);
        }
      }

      //@ts-ignore
      navigation?.push(HomeScreens.PROFILE_STACK, {
        screen: ProfileScreens.PROFILE_SCREEN,
        params: {
          tab: ProfileTabs.ACCOUNT,
          showLeftContainerFlag: null,
        },
      });
    } else {
      dispatch(AuthThunks.login());
    }
  };

  const refreshAppInfo = async () => {
    dispatch(LoaderActions.setLoading(true));
    dispatch(ForceInfoAppUpdateActions.setForceAppUpdate(true));
    await getApolloClient().cache.reset();
    dispatch(LoaderActions.setLoading(false));
    dispatch(ForceInfoAppUpdateActions.setForceAppUpdate(false));
  };

  const handleHomepage = () => {
    refreshAppInfo();
    navigation?.navigate(HomeScreens.HOME_SCREEN);
  };

  const handleBalance = () => {
    if (isBadgeRequired === false && !_.isEmpty(externalUrl))
      return window.open(externalUrl, "_blank")?.focus();
    if (isLogin && holdingName) {
      // @ts-ignore
      navigation?.push(HomeScreens.BALANCE_SCREEN);
    }
  };

  const handleBalanceHover = (state: boolean) => {
    if (route?.name === HomeScreens.BALANCE_SCREEN) return;
    setBalanceIsHover(state);
  };

  const handleLoginHover = (state: boolean) => {
    if (route?.name === HomeScreens.PROFILE_STACK) return;
    setLoginIsHover(state);
  };

  const balanceRefill = isBadgeRequired
    ? `${amountValue}€`
    : !isMobile && I18n.t("homepage.balanceRefill");

  React.useEffect(() => {
    if (route?.name === HomeScreens.BALANCE_SCREEN) setBalanceIsHover(true);
    if (route?.name === HomeScreens.PROFILE_STACK) setLoginIsHover(true);
  }, [route?.name]);

  return (
    <Container
      testID={TestIDs.home.header.views.headerContainer}
      isMobile={isMobile}
      isNotSticky={isNotSticky}
      handleOpacity={!handleOpacity}
    >
      <Pressable
        testID={TestIDs.home.header.actions.goToHomePage}
        onPress={handleHomepage}
      >
        {!!logoLink ? (
          <LogoIcon
            resizeMode={"contain"}
            isMobile={isMobile}
            source={{ uri: logoUri, cache: "only-if-cached" }}
          />
        ) : (
          <DefaultLogoIcon />
        )}
      </Pressable>
      <RightSide>
        {!isMobile && isLogin && isExternalLinksActive && (
          <ExternalServicesHome
            services={externalsServices}
            isMobile={isMobile}
          />
        )}
        {isLogin && <ComingSoonContainer navigation={navigation} routeName={routeName}/>}
        {isBadgeRequired === false && _.isEmpty(externalUrl) ? null : (
          <BalanceContainer
            testID={TestIDs.home.header.actions.goToBalance}
            isMobile={isMobile}
            onPress={handleBalance}
            // @ts-ignore
            onHoverIn={() => handleBalanceHover(true)}
            onHoverOut={() => handleBalanceHover(false)}
            disabled={isBadgeRequired === undefined}
          >
            <Icon
              name="Wallet_Balance_white"
              color={Colors.white}
              size={iconSize}
            />
            <Amount isMobile={isMobile} isHover={balanceIsHover}>
              {balanceRefill}
            </Amount>
          </BalanceContainer>
        )}
        <ConnectionContainer
          testID={TestIDs.home.header.actions.login}
          onPress={handleLogin}
          isMobile={isMobile}
          // @ts-ignore
          onHoverIn={() => handleLoginHover(true)}
          onHoverOut={() => handleLoginHover(false)}
        >
          <Icon name="Profile_white" color={Colors.white} size={iconSize} />
          {!isMobile && <UserName isHover={loginIsHover}>{userName}</UserName>}
        </ConnectionContainer>
      </RightSide>
    </Container>
  );
};
