import { Colors } from "@constants";
import style from "styled-components/native";

export const Container = style.View<{
  isMobile: boolean;
}>`
    z-index: 1;
    width: 100%;
    height: 60px;
    background-color: ${Colors.foodiBlack};
    padding: 20px ${({ isMobile }) => (isMobile ? "18" : "150")}px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;
