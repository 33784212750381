import React from "react";
import { I18n } from "react-redux-i18n";

interface IProps {
  backgroundColor?: string;
}

export const InformationOutline: React.FC<IProps> = () => {
  return (
    <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <title>{I18n.t("hoverIcons.info")}</title>
        <defs>
            <path d="M12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 Z M12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 Z M12,11 C12.5522847,11 13,11.4477153 13,12 L13,12 L13,16 C13,16.5522847 12.5522847,17 12,17 C11.4477153,17 11,16.5522847 11,16 L11,16 L11,12 C11,11.4477153 11.4477153,11 12,11 Z M11.2928932,8.29289322 C11.6834175,7.90236893 12.3165825,7.90236893 12.7071068,8.29289322 C13.0976311,8.68341751 13.0976311,9.31658249 12.7071068,9.70710678 C12.3165825,10.0976311 11.6834175,10.0976311 11.2928932,9.70710678 C10.9023689,9.31658249 10.9023689,8.68341751 11.2928932,8.29289322 Z" id="info-outline-path-1"></path>
        </defs>
        <g id="05---Casiers---produits" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Pouce---QR" transform="translate(-17.000000, -117.000000)">
                <g id="atom/icon/information" transform="translate(16.000000, 116.000000)">
                    <mask id="info-outline-mask-2" fill="white">
                        <use xlinkHref="#info-outline-path-1"></use>
                    </mask>
                    <use id="Combined-Shape" fill="#000000" fillRule="nonzero" xlinkHref="#info-outline-path-1"></use>
                </g>
            </g>
        </g>
    </svg>
  );
};
