"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetOnePos = void 0;
class GetOnePos {
    constructor(pointsOfSaleService) {
        this.pointsOfSaleService = pointsOfSaleService;
    }
    execute(request) {
        return this.pointsOfSaleService.getOnePos(request);
    }
}
exports.GetOnePos = GetOnePos;
