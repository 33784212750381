import React from "react";
import { I18n } from "react-redux-i18n";

interface IProps {
  size?: number;
}
export const EatInSizable: React.FC<IProps> = ({ size = 20 }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    viewBox="0 0 20 21"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>{I18n.t("homepage.restaurantCard.eatIn")}</title>
    <g
      id="Design-System"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="Icons-pg1" transform="translate(-264.000000, -407.000000)">
        <g id="Group" transform="translate(263.875000, 408.000000)">
          <path
            d="M12,8.2 L12,12.6 L13.8,12.6 L13.8,17 L15,17 L15,6 C13.344,6 12,7.232 12,8.2 Z M8.8,9.85 L7.6,9.85 L7.6,6 L6.4,6 L6.4,9.85 L5.2,9.85 L5.2,6 L4,6 L4,9.85 C4,11.0655 5.074,12.05 6.4,12.05 L6.4,17 L7.6,17 L7.6,12.05 C8.926,12.05 10,11.0655 10,9.85 L10,6 L8.8,6 L8.8,9.85 Z"
            id="Sur-Place"
            fill="#000000"
            fill-rule="nonzero"
          ></path>
          <rect id="Rectangle" x="0" y="0" width="20" height="20"></rect>
          <polyline
            id="Path"
            stroke="#000000"
            stroke-width="1.5"
            fill-rule="nonzero"
            points="10 0 19 6.33333333 19 19 1 19 1 6.33333333 10 0"
          ></polyline>
        </g>
      </g>
    </g>
  </svg>
);
