import React, { useState, useMemo } from "react";
import {
  StyleSheet,
  View,
  TouchableOpacity,
  TouchableWithoutFeedback,
  Text,
} from "react-native";
import { I18n } from "react-redux-i18n";
import { Colors } from "@constants/Colors";
import { Title16, Title22 } from "@stylesheets";
import { CloseXButton, ModalTemplate } from "@atomic";
import { TestIDs } from "@utils";
import { responsiveSizeWidth, useDevices } from "@hooks";
import { CopyBlack } from "@assets/icons/CopyBlack";

interface IProps {
  securityCode: string;
  isScholapass: boolean;
  closeModal: () => void;
}

const DisplaySecurityCodeModal: React.FC<IProps> = ({
  securityCode,
  isScholapass,
  closeModal,
}) => {
  const [isMobile] = useDevices();
  const styles = useMemo(() => _styles(isMobile), [isMobile]);

  const copySecurityCode = () => {
    navigator.clipboard.writeText(securityCode);
  };

  return (
    <ModalTemplate
      handleClose={closeModal}
      isMobile={isMobile}
      isFullScreen={isMobile}
      closeOnClickOutside
      isOpen
      isCenter
    >
      <View style={styles.container}>
        <View style={styles.textContainer}>
          <Title22 isBlack>
            {I18n.t("profile.badgeValidationModal.securityCodeTitle")}
          </Title22>
        </View>
        <View style={styles.textContainer}>
          <Title16 style={styles.provideCode}>
            {isScholapass
              ? I18n.t("profile.badgeValidationModal.provideScholapassCode")
              : I18n.t("profile.badgeValidationModal.provideCode")}
          </Title16>
        </View>
        <View style={styles.listContainer}>
          <View style={styles.textSecurityCodeContainer}>
            <Text style={styles.textSecurityCode} onPress={copySecurityCode}>
              {securityCode}
            </Text>
            <TouchableOpacity onPress={copySecurityCode}>
              <CopyBlack />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </ModalTemplate>
  );
};
const _styles = (isMobile: boolean) =>
  StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    textContainer: {
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      margin: 4,
      zIndex: 9999,
    },
    provideCode: {
      width: 320,
    },
    listContainer: {
      flexDirection: "row",
      alignItems: "center",
      backgroundColor: Colors.white,
      zIndex: 99999,
    },
    textSecurityCodeContainer: {
      margin: 10,
      backgroundColor: Colors.white,
      width: isMobile ? responsiveSizeWidth(320) : 350,
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
      borderRadius: 8,
      borderWidth: 1,
      borderColor: Colors.transparent,
      marginTop: 24,
    },
    textSecurityCode: {
      height: 50,
      lineHeight: 45,
      marginRight: 8,
      paddingLeft: 40,
      paddingRight: 40,
      fontSize: 22,
      textAlign: "center",
      color: Colors.gray1,
      backgroundColor: Colors.tagsGray,
      borderWidth: 1,
      borderColor: Colors.gray1,
      fontWeight: "400",
      borderRadius: 8,
    },
  });
export default React.memo(DisplaySecurityCodeModal);
