"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CREATE_BOOKING_ORDER = void 0;
const client_1 = require("@apollo/client");
exports.CREATE_BOOKING_ORDER = client_1.gql `
  mutation createBookingOrder($input: InputCreateBookingOrder!) {
    order: createBookingOrder(input: $input) {
      id
    }
  }
`;
