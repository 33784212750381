"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ToggleTokenServiceErrors = exports.EpointageErrors = exports.PickupPointsErrors = exports.TableServiceErrors = exports.BrandThemeGatewayErrors = exports.ProfileServiceErrors = exports.OrderRepositoryErrors = exports.TicketRepositoryErrors = exports.UserRepositoryErrors = exports.HoldingRepositoryErrors = exports.OfferServiceErrors = exports.PointsOfSaleServiceErrors = exports.MarketingCardErrors = exports.GuestServiceErrors = exports.AccountServiceErrors = exports.PaymentServiceErrors = exports.AuthenticationServiceErrors = exports.DomainError = void 0;
class DomainError extends Error {
    constructor(message, cause) {
        super(message);
        this.name = this.constructor.name;
        this.cause = cause;
        // https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-2.html#support-for-newtarget
        // Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
    }
}
exports.DomainError = DomainError;
var AuthenticationServiceErrors;
(function (AuthenticationServiceErrors) {
    class LoginRequestError extends DomainError {
    }
    AuthenticationServiceErrors.LoginRequestError = LoginRequestError;
    class CheckEmailRequestError extends DomainError {
    }
    AuthenticationServiceErrors.CheckEmailRequestError = CheckEmailRequestError;
    class SignUpRequestError extends DomainError {
    }
    AuthenticationServiceErrors.SignUpRequestError = SignUpRequestError;
    class DeleteAccountRequestError extends DomainError {
    }
    AuthenticationServiceErrors.DeleteAccountRequestError = DeleteAccountRequestError;
    class SendResetPasswordEmail extends DomainError {
    }
    AuthenticationServiceErrors.SendResetPasswordEmail = SendResetPasswordEmail;
})(AuthenticationServiceErrors = exports.AuthenticationServiceErrors || (exports.AuthenticationServiceErrors = {}));
var PaymentServiceErrors;
(function (PaymentServiceErrors) {
    class GetPaymentRequestError extends DomainError {
    }
    PaymentServiceErrors.GetPaymentRequestError = GetPaymentRequestError;
    class GetPaymentStatusError extends DomainError {
    }
    PaymentServiceErrors.GetPaymentStatusError = GetPaymentStatusError;
    class GetEdenredAuthorizationURLRequestError extends DomainError {
    }
    PaymentServiceErrors.GetEdenredAuthorizationURLRequestError = GetEdenredAuthorizationURLRequestError;
    class GetEdenredDailyBalanceRequestError extends DomainError {
    }
    PaymentServiceErrors.GetEdenredDailyBalanceRequestError = GetEdenredDailyBalanceRequestError;
    class GenerateEdenredTokensRequestError extends DomainError {
    }
    PaymentServiceErrors.GenerateEdenredTokensRequestError = GenerateEdenredTokensRequestError;
})(PaymentServiceErrors = exports.PaymentServiceErrors || (exports.PaymentServiceErrors = {}));
var AccountServiceErrors;
(function (AccountServiceErrors) {
    class GetBalanceRequestError extends DomainError {
    }
    AccountServiceErrors.GetBalanceRequestError = GetBalanceRequestError;
    class SendUserEmailVerificationRequestError extends DomainError {
    }
    AccountServiceErrors.SendUserEmailVerificationRequestError = SendUserEmailVerificationRequestError;
})(AccountServiceErrors = exports.AccountServiceErrors || (exports.AccountServiceErrors = {}));
var GuestServiceErrors;
(function (GuestServiceErrors) {
    class GetGuestPaymentProfileRequestError extends DomainError {
    }
    GuestServiceErrors.GetGuestPaymentProfileRequestError = GetGuestPaymentProfileRequestError;
})(GuestServiceErrors = exports.GuestServiceErrors || (exports.GuestServiceErrors = {}));
var MarketingCardErrors;
(function (MarketingCardErrors) {
    class GetMarketingCardsRequestError extends DomainError {
    }
    MarketingCardErrors.GetMarketingCardsRequestError = GetMarketingCardsRequestError;
    class GetPublishedMarketingCardsNewRequestError extends DomainError {
    }
    MarketingCardErrors.GetPublishedMarketingCardsNewRequestError = GetPublishedMarketingCardsNewRequestError;
    class GetBrandRequestError extends DomainError {
    }
    MarketingCardErrors.GetBrandRequestError = GetBrandRequestError;
})(MarketingCardErrors = exports.MarketingCardErrors || (exports.MarketingCardErrors = {}));
var PointsOfSaleServiceErrors;
(function (PointsOfSaleServiceErrors) {
    class GetPointsOfSaleWithMenuRequestError extends DomainError {
    }
    PointsOfSaleServiceErrors.GetPointsOfSaleWithMenuRequestError = GetPointsOfSaleWithMenuRequestError;
    class GetPosRequestError extends DomainError {
    }
    PointsOfSaleServiceErrors.GetPosRequestError = GetPosRequestError;
    class GetOnePosRequestError extends DomainError {
    }
    PointsOfSaleServiceErrors.GetOnePosRequestError = GetOnePosRequestError;
    class GetPointsOfSaleRequestError extends DomainError {
    }
    PointsOfSaleServiceErrors.GetPointsOfSaleRequestError = GetPointsOfSaleRequestError;
})(PointsOfSaleServiceErrors = exports.PointsOfSaleServiceErrors || (exports.PointsOfSaleServiceErrors = {}));
var OfferServiceErrors;
(function (OfferServiceErrors) {
    class GetOfferTemplatesRequestError extends DomainError {
    }
    OfferServiceErrors.GetOfferTemplatesRequestError = GetOfferTemplatesRequestError;
    class GetOfferRequestError extends DomainError {
    }
    OfferServiceErrors.GetOfferRequestError = GetOfferRequestError;
    class GetOfferSlotsRequestError extends DomainError {
    }
    OfferServiceErrors.GetOfferSlotsRequestError = GetOfferSlotsRequestError;
    class GetOfferItemsRequestError extends DomainError {
    }
    OfferServiceErrors.GetOfferItemsRequestError = GetOfferItemsRequestError;
    class GetBookingTemplateOfferRequestError extends DomainError {
    }
    OfferServiceErrors.GetBookingTemplateOfferRequestError = GetBookingTemplateOfferRequestError;
})(OfferServiceErrors = exports.OfferServiceErrors || (exports.OfferServiceErrors = {}));
var HoldingRepositoryErrors;
(function (HoldingRepositoryErrors) {
    class GetServicesRequestError extends DomainError {
    }
    HoldingRepositoryErrors.GetServicesRequestError = GetServicesRequestError;
    class GetHoldingWithRecipeStatusRequestError extends DomainError {
    }
    HoldingRepositoryErrors.GetHoldingWithRecipeStatusRequestError = GetHoldingWithRecipeStatusRequestError;
    class GetNearHoldingsRequestError extends DomainError {
    }
    HoldingRepositoryErrors.GetNearHoldingsRequestError = GetNearHoldingsRequestError;
    class AddUserHoldingViewRequestError extends DomainError {
    }
    HoldingRepositoryErrors.AddUserHoldingViewRequestError = AddUserHoldingViewRequestError;
    class SwitchUserHoldingViewRequestError extends DomainError {
    }
    HoldingRepositoryErrors.SwitchUserHoldingViewRequestError = SwitchUserHoldingViewRequestError;
    class GetUserHoldingViewRequestError extends DomainError {
    }
    HoldingRepositoryErrors.GetUserHoldingViewRequestError = GetUserHoldingViewRequestError;
    class MigrateToHoldingViewRequestError extends DomainError {
    }
    HoldingRepositoryErrors.MigrateToHoldingViewRequestError = MigrateToHoldingViewRequestError;
    class DeleteUserHoldingViewRequestError extends DomainError {
    }
    HoldingRepositoryErrors.DeleteUserHoldingViewRequestError = DeleteUserHoldingViewRequestError;
    class UpdateUserHoldingViewRequestError extends DomainError {
    }
    HoldingRepositoryErrors.UpdateUserHoldingViewRequestError = UpdateUserHoldingViewRequestError;
})(HoldingRepositoryErrors = exports.HoldingRepositoryErrors || (exports.HoldingRepositoryErrors = {}));
var UserRepositoryErrors;
(function (UserRepositoryErrors) {
    class GetUserProfileRequestError extends DomainError {
    }
    UserRepositoryErrors.GetUserProfileRequestError = GetUserProfileRequestError;
    class GetI18nProviderLocaleRequestError extends DomainError {
    }
    UserRepositoryErrors.GetI18nProviderLocaleRequestError = GetI18nProviderLocaleRequestError;
    class GetTransactionsRequestError extends DomainError {
    }
    UserRepositoryErrors.GetTransactionsRequestError = GetTransactionsRequestError;
    class GetScolapassPaymentsRequestError extends DomainError {
    }
    UserRepositoryErrors.GetScolapassPaymentsRequestError = GetScolapassPaymentsRequestError;
    class GetProfileSectionsDataRequestError extends DomainError {
    }
    UserRepositoryErrors.GetProfileSectionsDataRequestError = GetProfileSectionsDataRequestError;
    class GetUserInfoForTrackingRequestError extends DomainError {
    }
    UserRepositoryErrors.GetUserInfoForTrackingRequestError = GetUserInfoForTrackingRequestError;
})(UserRepositoryErrors = exports.UserRepositoryErrors || (exports.UserRepositoryErrors = {}));
var TicketRepositoryErrors;
(function (TicketRepositoryErrors) {
    class GetTicketRequestError extends DomainError {
    }
    TicketRepositoryErrors.GetTicketRequestError = GetTicketRequestError;
})(TicketRepositoryErrors = exports.TicketRepositoryErrors || (exports.TicketRepositoryErrors = {}));
var OrderRepositoryErrors;
(function (OrderRepositoryErrors) {
    class GetOrdersRequestError extends DomainError {
    }
    OrderRepositoryErrors.GetOrdersRequestError = GetOrdersRequestError;
    class GetAllOrdersRequestError extends DomainError {
    }
    OrderRepositoryErrors.GetAllOrdersRequestError = GetAllOrdersRequestError;
    class GetOrderRequestError extends DomainError {
    }
    OrderRepositoryErrors.GetOrderRequestError = GetOrderRequestError;
    class GetBookingOrdersRequestError extends DomainError {
    }
    OrderRepositoryErrors.GetBookingOrdersRequestError = GetBookingOrdersRequestError;
    class UpsertOrderRequestError extends DomainError {
    }
    OrderRepositoryErrors.UpsertOrderRequestError = UpsertOrderRequestError;
    class ConfirmOrderRequestError extends DomainError {
    }
    OrderRepositoryErrors.ConfirmOrderRequestError = ConfirmOrderRequestError;
    class CartSummaryAdmissionAndPriceRequestError extends DomainError {
    }
    OrderRepositoryErrors.CartSummaryAdmissionAndPriceRequestError = CartSummaryAdmissionAndPriceRequestError;
    class AddOrderRatingRequestError extends DomainError {
    }
    OrderRepositoryErrors.AddOrderRatingRequestError = AddOrderRatingRequestError;
    class DiscardOrderRatingRequestError extends DomainError {
    }
    OrderRepositoryErrors.DiscardOrderRatingRequestError = DiscardOrderRatingRequestError;
    class RecentOrdersToRateError extends DomainError {
    }
    OrderRepositoryErrors.RecentOrdersToRateError = RecentOrdersToRateError;
    class GetOrderCCRequestError extends DomainError {
    }
    OrderRepositoryErrors.GetOrderCCRequestError = GetOrderCCRequestError;
    class CreateBookingOrderRequestError extends DomainError {
    }
    OrderRepositoryErrors.CreateBookingOrderRequestError = CreateBookingOrderRequestError;
    class UpdateBookingOrderSlotRequestError extends DomainError {
    }
    OrderRepositoryErrors.UpdateBookingOrderSlotRequestError = UpdateBookingOrderSlotRequestError;
    class CancelBookingOrderRequestError extends DomainError {
    }
    OrderRepositoryErrors.CancelBookingOrderRequestError = CancelBookingOrderRequestError;
    class CancelOrderRequestError extends DomainError {
    }
    OrderRepositoryErrors.CancelOrderRequestError = CancelOrderRequestError;
    class GetOrderReceiptRequestError extends DomainError {
    }
    OrderRepositoryErrors.GetOrderReceiptRequestError = GetOrderReceiptRequestError;
})(OrderRepositoryErrors = exports.OrderRepositoryErrors || (exports.OrderRepositoryErrors = {}));
var ProfileServiceErrors;
(function (ProfileServiceErrors) {
    class UpdateOptinsRequestError extends DomainError {
    }
    ProfileServiceErrors.UpdateOptinsRequestError = UpdateOptinsRequestError;
    class UpdateOptoutsRequestError extends DomainError {
    }
    ProfileServiceErrors.UpdateOptoutsRequestError = UpdateOptoutsRequestError;
    class UpdateLanguageRequestError extends DomainError {
    }
    ProfileServiceErrors.UpdateLanguageRequestError = UpdateLanguageRequestError;
    class UpdateVirtualTicketRequestError extends DomainError {
    }
    ProfileServiceErrors.UpdateVirtualTicketRequestError = UpdateVirtualTicketRequestError;
})(ProfileServiceErrors = exports.ProfileServiceErrors || (exports.ProfileServiceErrors = {}));
var BrandThemeGatewayErrors;
(function (BrandThemeGatewayErrors) {
    class GetBrandThemeError extends DomainError {
    }
    BrandThemeGatewayErrors.GetBrandThemeError = GetBrandThemeError;
})(BrandThemeGatewayErrors = exports.BrandThemeGatewayErrors || (exports.BrandThemeGatewayErrors = {}));
var TableServiceErrors;
(function (TableServiceErrors) {
    class CheckTablesAvailabilityRequestError extends DomainError {
    }
    TableServiceErrors.CheckTablesAvailabilityRequestError = CheckTablesAvailabilityRequestError;
})(TableServiceErrors = exports.TableServiceErrors || (exports.TableServiceErrors = {}));
var PickupPointsErrors;
(function (PickupPointsErrors) {
    class getPickupPointsError extends DomainError {
    }
    PickupPointsErrors.getPickupPointsError = getPickupPointsError;
    class getUserLastUsedPickupPointError extends DomainError {
    }
    PickupPointsErrors.getUserLastUsedPickupPointError = getUserLastUsedPickupPointError;
})(PickupPointsErrors = exports.PickupPointsErrors || (exports.PickupPointsErrors = {}));
var EpointageErrors;
(function (EpointageErrors) {
    class getEpointageServicesError extends DomainError {
    }
    EpointageErrors.getEpointageServicesError = getEpointageServicesError;
    class getEpointageSchedulerError extends DomainError {
    }
    EpointageErrors.getEpointageSchedulerError = getEpointageSchedulerError;
    class getEpointageSchoolsError extends DomainError {
    }
    EpointageErrors.getEpointageSchoolsError = getEpointageSchoolsError;
    class addEpointageCommentError extends DomainError {
    }
    EpointageErrors.addEpointageCommentError = addEpointageCommentError;
    class updateEpointageCommentError extends DomainError {
    }
    EpointageErrors.updateEpointageCommentError = updateEpointageCommentError;
    class addEpointageSchedulerError extends DomainError {
    }
    EpointageErrors.addEpointageSchedulerError = addEpointageSchedulerError;
    class updateEpointageSchedulerError extends DomainError {
    }
    EpointageErrors.updateEpointageSchedulerError = updateEpointageSchedulerError;
    class getEpointagePdfExportError extends DomainError {
    }
    EpointageErrors.getEpointagePdfExportError = getEpointagePdfExportError;
})(EpointageErrors = exports.EpointageErrors || (exports.EpointageErrors = {}));
var ToggleTokenServiceErrors;
(function (ToggleTokenServiceErrors) {
    class CheckToggleTokenAvailabilityRequestError extends DomainError {
    }
    ToggleTokenServiceErrors.CheckToggleTokenAvailabilityRequestError = CheckToggleTokenAvailabilityRequestError;
})(ToggleTokenServiceErrors = exports.ToggleTokenServiceErrors || (exports.ToggleTokenServiceErrors = {}));
