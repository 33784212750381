
import { Colors, Spacing } from "@constants";
import style from "styled-components/native";

export const Section = style.View`
  padding: ${Spacing.S}px ${Spacing.M}px;
`;

export const Row = style.View<{ center?: boolean, wrap?: boolean }>`
    flex-direction: row;
    align-items: center;
    padding: 0 ${Spacing.M}px;
    justify-content: ${({ center }) => center ? 'center' : 'flex-start'};
    flex-wrap: ${({ wrap }) => wrap ? 'wrap' : 'nowrap'};
`;

export const Action = style.Pressable<{ disabled?: boolean }>`
  border-radius: 8px;
  background-color: ${Colors.foodiDefault};
  padding: 5px 24px;
  background-color: ${({ disabled }) => !disabled ? Colors.foodiDefault : Colors.disabledBackground};
`;

export const AddActionText = style.Text<{ disabled?: boolean }>`
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
  color: ${({ disabled }) => !disabled ? Colors.foodiBlack : Colors.white};
`;

export const IconButton = style.TouchableOpacity<{ disabled?: boolean }>`
    position: absolute;
    top: ${Spacing.S}px;
    right: ${Spacing.S}px;
    borderRadius: ${Spacing.XXL}px;
    backgroundColor: ${Colors.disabledBackground};
    padding: ${Spacing.S}px;
`;


export const Label = style.View`
  background-color: #EEEEEE;
  padding: ${0}px ${Spacing.S}px;
  border-radius: 20;
`;

export const AccordeonArrowStyle = style.View<{ rotate?: boolean }>`
    transform: ${({ rotate }) => (rotate ? "rotate(180deg)" : null)};
`;
