import { createStackNavigator } from "@react-navigation/stack";
import React from "react";
import { ProfileScreens } from ".";
import { ProfileScreen } from "../screens";

export type ProfileStackParamList = {
  [ProfileScreens.PROFILE_SCREEN]: undefined;
};

const Stack = createStackNavigator<ProfileStackParamList>();

export const ProfileNavigation = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        name={ProfileScreens.PROFILE_SCREEN}
        key="profileScreen"
        component={ProfileScreen}
      />

    </Stack.Navigator>
  );
};
