"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FAccountService = void 0;
const __1 = require("../../..");
const domain_1 = require("../../../domain");
const mappers_1 = require("../mappers");
class FAccountService {
    constructor(apolloClient) {
        this.apolloClient = apolloClient;
    }
    getBalance(variables) {
        return __awaiter(this, void 0, void 0, function* () {
            const accountBalanceMapper = new mappers_1.AccountBalanceResponseMapper();
            try {
                const response = yield this.apolloClient.query({
                    query: __1.GET_BALANCE,
                    variables,
                    fetchPolicy: "no-cache",
                });
                if (variables.callback)
                    variables.callback();
                return Promise.resolve(accountBalanceMapper.toDomain(response));
            }
            catch (error) {
                return Promise.reject(new domain_1.AccountServiceErrors.GetBalanceRequestError("GET_BALANCE_REQUEST_FAILED", error));
            }
        });
    }
    sendUserEmailVerification(input) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield this.apolloClient.mutate({
                    mutation: __1.SEND_USER_EMAIL_VERIFICATION,
                    variables: input,
                });
                return Promise.resolve(response);
            }
            catch (error) {
                return Promise.reject(new domain_1.AccountServiceErrors.SendUserEmailVerificationRequestError("SEND_USER_EMAIL_VERIFICATION_REQUEST_FAILED", error));
            }
        });
    }
}
exports.FAccountService = FAccountService;
