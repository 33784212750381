import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import { Title18 } from "@stylesheets";
import { Colors } from "@constants";
import _ from "lodash";
import { useSelector } from "react-redux";
import { GlobalState } from "@redux";
import moment from "moment";
import { upperFirst } from "lodash";
import { TestIDs } from "@utils";
import { LeftArrow, RightArrow } from "@atomic/atoms";

interface IProps {
  date: moment.Moment;
  nextDate?: () => void;
  prevDate?: () => void;
}

export const Calendar: React.FC<IProps> = React.memo(
  ({ date, nextDate, prevDate }) => {
    const localLanguage = useSelector(
      (state: GlobalState) => state.i18n.locale
    );
    moment.locale(localLanguage);

    const [currentDate, setCurrentDate] = useState(date);

    const nextDay = () => {
      setCurrentDate(moment(currentDate).add(1, "d"));
      !!nextDate && nextDate();
    };

    const previousDay = () => {
      setCurrentDate(moment(currentDate).subtract(1, "d"));
      !!prevDate && prevDate();
    };

    return (
      <View style={styles.mainContainer}>
        <LeftArrow
          forwardTestID={
            TestIDs.epointage.homepage.calendar.actions.previousDay
          }
          style={styles.leftArrow}
          onPress={previousDay}
          bigBlackArrow
        />
        <View style={styles.dateContainer}>
          <Title18 isBold>
            {upperFirst(currentDate.format("dddd DD MMMM"))}
          </Title18>
        </View>
        <RightArrow
          forwardTestID={TestIDs.epointage.homepage.calendar.actions.nextDay}
          style={styles.rightArrow}
          onPress={nextDay}
          bigBlackArrow
        />
      </View>
    );
  }
);

const styles = StyleSheet.create({
  mainContainer: {
    marginBottom: 30,
  },
  dateContainer: {
    width: 350,
    height: 40,
    borderRadius: 8,
    backgroundColor: Colors.white,
    justifyContent: "center",
    alignItems: "center",
  },
  leftArrow: {
    position: "absolute",
    left: -15,
    top: 5,
    zIndex: 1,
  },
  rightArrow: {
    position: "absolute",
    right: -15,
    top: 5,
    zIndex: 1,
  },
});
