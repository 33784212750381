import { Warning } from "@assets";
import { TestIDs } from "@utils";
import React from "react";
import { I18n } from "react-redux-i18n";
import { Text, Container, Circle } from "./PasswordRulesMessage.style";

interface PasswordRules {
  characters: number;
  uppercase: number;
  lowercase: number;
  number: number;
}

interface IProps {
  passCharacterRules: boolean;
  passUpperCaseRules: boolean;
  passLowerCaseRules: boolean;
  passNumberRules: boolean;
  passwordRules: PasswordRules;
  isErrorMessage: boolean;
}

export const PasswordRulesMessage: React.FC<IProps> = React.memo(
  ({
    passCharacterRules,
    passUpperCaseRules,
    passLowerCaseRules,
    passNumberRules,
    passwordRules,
    isErrorMessage,
  }) => {
    return (
      <Container testID={TestIDs.account.views.passwordRules}>
        <Text isRed={isErrorMessage}>
          {isErrorMessage && <Warning />} {I18n.t("account.passwordRules")}
          <Text
            isGreen={passCharacterRules}
            isRed={!passCharacterRules}
            isErrorMessage={passCharacterRules && isErrorMessage}
          >
            <Circle isGreen={passCharacterRules} isRed={!passCharacterRules} />
            {passwordRules.characters}{" "}
            {I18n.t("account.passwordRulesCharacters")}
          </Text>
        </Text>
        <Text>
          <Text
            isGreen={passUpperCaseRules}
            isRed={!passUpperCaseRules}
            isErrorMessage={passUpperCaseRules && isErrorMessage}
          >
            <Circle isGreen={passUpperCaseRules} isRed={!passUpperCaseRules} />
            {passwordRules.uppercase} {I18n.t("account.passwordRulesUpperCase")}
          </Text>
          <Text
            isGreen={passLowerCaseRules}
            isRed={!passLowerCaseRules}
            isErrorMessage={passLowerCaseRules && isErrorMessage}
          >
            <Circle isGreen={passLowerCaseRules} isRed={!passLowerCaseRules} />
            {passwordRules.lowercase} {I18n.t("account.passwordRulesLowerCase")}
          </Text>
          <Text
            isGreen={passNumberRules}
            isRed={!passNumberRules}
            isErrorMessage={passNumberRules && isErrorMessage}
          >
            <Circle isGreen={passNumberRules} isRed={!passNumberRules} />
            {passwordRules.number} {I18n.t("account.passwordRulesNumbers")}
          </Text>
        </Text>
      </Container>
    );
  }
);
