import React, { useEffect, useState } from "react";
import { Wrapper } from "./FullCart.style";
import { TestIDs } from "@utils";
import { Cart, ScreenWithFooter } from "@atomic";
import { GlobalState } from "@redux";
import { useSelector, useDispatch } from "react-redux";
import { NavigationProp, Route } from "@react-navigation/native";
import { PointOfSaleActions } from "@modules";

interface IProps {
  navigation: NavigationProp<any>;
  route?: Route<any>;
  isRefillFromCart?: boolean;
}

export const FullCart: React.FC<IProps> = React.memo(
  ({ navigation, route, isRefillFromCart }) => {
    const orderItems = useSelector(
      (state: GlobalState) => state.offers.orderItems
    );

    const dispatch = useDispatch();

    useEffect(() => {
      return () => {
        dispatch(PointOfSaleActions.setFullCartStatus(false));
      };
    }, []);

    return (
      <Wrapper testID={TestIDs.restaurantDetail.views.fullCartContainer}>
        <ScreenWithFooter navigation={navigation || ({} as any)}>
          <Cart
            route={route}
            navigation={navigation}
            products={orderItems}
            isRefillFromCart={isRefillFromCart}
          />
        </ScreenWithFooter>
      </Wrapper>
    );
  }
);
