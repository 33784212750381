"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateOptouts = void 0;
class UpdateOptouts {
    constructor(profileService) {
        this.profileService = profileService;
    }
    execute(request) {
        return this.profileService.updateOptouts(request);
    }
}
exports.UpdateOptouts = UpdateOptouts;
