"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GENERATE_EDENRED_TOKENS = void 0;
const client_1 = require("@apollo/client");
exports.GENERATE_EDENRED_TOKENS = client_1.gql `
  query generateEdenredTokens(
    $authorizationCode: String
    $refreshToken: String
    $isWeb: Boolean
  ) {
    generateTokens(
      refreshToken: $refreshToken
      authorizationCode: $authorizationCode
      isWeb: $isWeb
    ) {
      idToken
      accessToken
      expiresIn
      tokenType
      refreshToken
      scope
    }
  }
`;
