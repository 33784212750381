"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InMemoryPickupPoints = void 0;
const domain_1 = require("../../../domain");
class InMemoryPickupPoints {
    constructor(pickupPointsFixture, userLastUsedPickupPointFixture) {
        this.pickupPointsFixture = pickupPointsFixture;
        this.userLastUsedPickupPointFixture = userLastUsedPickupPointFixture;
    }
    getPickupPoints(variables) {
        if (this.pickupPointsFixture) {
            return Promise.resolve(this.pickupPointsFixture);
        }
        return Promise.reject(new domain_1.PickupPointsErrors.getPickupPointsError("FAILED_TO_RETRIEVE_OFFER_TEMPLATE_PICKUP_POINTS"));
    }
    getUserLastUsedPickupPoint(variables) {
        if (this.userLastUsedPickupPointFixture) {
            return Promise.resolve(this.userLastUsedPickupPointFixture);
        }
        return Promise.reject(new domain_1.PickupPointsErrors.getUserLastUsedPickupPointError("FAILED_TO_RETRIEVE_LAST_USED_PICKUP_POINT"));
    }
}
exports.InMemoryPickupPoints = InMemoryPickupPoints;
