import {
  EdenredToken,
  MercanetResponse,
  MercanetStatusResponse,
} from "@foodi/core";
import { createReducer, ThunkResult } from "@redux";
import { Action } from "@redux/action";
import { displayToastError } from "@utils";

export interface PaymentsState {
  edenredToken?: EdenredToken;
  edenredAuthorizationCode?: string;
  edenredBalance?: string;
}

/*************  Actions  ****************/

const ActionTypes = {
  SET_EDENRED_TOKEN: "Payments/SET_EDENRED_TOKEN",
  SET_EDENRED_AUTHORIZATION_CODE: "Payments/SET_EDENRED_AUTHORIZATION_CODE",
  SET_EDENRED_BALANCE: "Payments/SET_EDENRED_BALANCE",
  RESET_PAYMENT: "Payments/RESET_PAYMENT",
};

const ActionCreators = {
  setEdenredToken: (edenredToken: EdenredToken): Action<EdenredToken> => ({
    type: ActionTypes.SET_EDENRED_TOKEN,
    payload: edenredToken,
  }),
  setEdenredAuthorizationCode: (
    edenredAuthorizationCode: string
  ): Action<string> => ({
    type: ActionTypes.SET_EDENRED_AUTHORIZATION_CODE,
    payload: edenredAuthorizationCode,
  }),
  setEdenredBalance: (edenredBalance: string): Action<string> => ({
    type: ActionTypes.SET_EDENRED_BALANCE,
    payload: edenredBalance,
  }),
  resetPayment: () => ({
    type: ActionTypes.RESET_PAYMENT,
  }),
};

const ThunkActionCreators = {
  getPaymentRequest: (
    amount: string,
    token: string,
    userRedirectUrl: string
  ): ThunkResult<Promise<MercanetResponse>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { getPaymentRequest } = getDependencies();
      return getPaymentRequest.execute({ amount, token, userRedirectUrl });
    } catch (error) {
      displayToastError(dispatch);
      return Promise.reject(error);
    }
  },
  getPaymentSatus: (
    idRefill: string,
    token: string
  ): ThunkResult<Promise<MercanetStatusResponse>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { getPaymentStatus } = getDependencies();
      return getPaymentStatus.execute({ idRefill, token });
    } catch (error) {
      displayToastError(dispatch);
      return Promise.reject(error);
    }
  },
  getEdenredAuthorizationURL: (
    locale: string
  ): ThunkResult<Promise<string>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { getEdenredAuthorizationURL } = getDependencies();
      return getEdenredAuthorizationURL.execute({ locale });
    } catch (error) {
      displayToastError(dispatch);
      return Promise.reject(error);
    }
  },
  getEdenredDailyBalance: (
    idToken: string,
    accessToken: string
  ): ThunkResult<Promise<string>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { getEdenredDailyBalance } = getDependencies();
      return getEdenredDailyBalance.execute({ idToken, accessToken });
    } catch (error) {
      displayToastError(dispatch);
      return Promise.reject(error);
    }
  },
  generateEdenredTokens: (
    authorizationCode?: string,
    refreshToken?: string,
    isWeb?: boolean
  ): ThunkResult<Promise<EdenredToken>> => async (
    dispatch,
    getState,
    { getDependencies }
  ) => {
    try {
      const { generateEdenredTokens } = getDependencies();
      return generateEdenredTokens.execute({
        authorizationCode,
        refreshToken,
        isWeb,
      });
    } catch (error) {
      displayToastError(dispatch);
      return Promise.reject(error);
    }
  },
};

/*************  Reducer  ****************/

const initialState: PaymentsState = {
  edenredToken: undefined,
  edenredAuthorizationCode: undefined,
  edenredBalance: undefined,
};

const Reduction = {
  setEdenredToken: (
    state: PaymentsState,
    { payload: edenredToken }: Action<EdenredToken>
  ): PaymentsState => ({
    ...state,
    edenredToken,
  }),
  setEdenredAuthorizationCode: (
    state: PaymentsState,
    { payload: edenredAuthorizationCode }: Action<string>
  ): PaymentsState => ({
    ...state,
    edenredAuthorizationCode,
  }),
  setEdenredBalance: (
    state: PaymentsState,
    { payload: edenredBalance }: Action<string>
  ): PaymentsState => ({
    ...state,
    edenredBalance,
  }),
  resetPayment: (): PaymentsState => ({
    ...initialState,
  }),
};
const reducer = createReducer(initialState, {
  [ActionTypes.SET_EDENRED_TOKEN]: Reduction.setEdenredToken,
  [ActionTypes.SET_EDENRED_AUTHORIZATION_CODE]:
    Reduction.setEdenredAuthorizationCode,
  [ActionTypes.SET_EDENRED_BALANCE]: Reduction.setEdenredBalance,
  [ActionTypes.RESET_PAYMENT]: Reduction.resetPayment,
});

export default reducer;

export {
  reducer as PaymentsReducer,
  ActionTypes as PaymentsActionTypes,
  ActionCreators as PaymentsActions,
  ThunkActionCreators as PaymentsThunks,
};
