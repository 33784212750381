import React, { useEffect } from "react";
import { Wrapper } from "./FullBookingCart.style";
import { TestIDs } from "@utils";
import { BookingCart, ScreenWithFooter } from "@atomic";
import { NavigationProp, Route } from "@react-navigation/native";
import { PointOfSaleActions } from "@modules";
import { useDispatch } from "react-redux";

interface IProps {
  navigation?: NavigationProp<any>;
  route?: Route<any>;
}

export const FullBookingCart: React.FC<IProps> = React.memo(
  ({ navigation, route }) => {
    //@ts-ignore
    const { id, isModify } = route?.params || {};

    const dispatch = useDispatch();

    useEffect(() => {
      return () => {
        dispatch(PointOfSaleActions.setFullBookingCartStatus(false));
      };
    }, []);

    return (
      <Wrapper testID={TestIDs.restaurantDetail.views.fullBookingCartContainer}>
        <ScreenWithFooter navigation={navigation || ({} as any)}>
          <BookingCart navigation={navigation} idPos={id} isModify={isModify} />
        </ScreenWithFooter>
      </Wrapper>
    );
  }
);
