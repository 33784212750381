"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_OPTINS = void 0;
const client_1 = require("@apollo/client");
exports.UPDATE_OPTINS = client_1.gql `
  mutation updateOptins($input: InputUpdateUser) {
    updateUser(input: $input) {
      id
      userOptins {
        optin {
          code
        }
        optinOptions {
          minBalance
        }
      }
    }
  }
`;
