import React from "react";
import { I18n } from "react-redux-i18n";

export const LegalInfoBlack: React.FC = () => (
  <svg
    width="18px"
    height="18px"
    viewBox="0 0 18 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>{I18n.t("profile.legalInformation")}</title>
    <g
      id="Homepage"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="Profil-parametres" transform="translate(-153.000000, -242.000000)">
        <g id="item_nav" transform="translate(149.000000, 109.000000)">
          <g
            id="article_black_24dp"
            transform="translate(1.000000, 130.000000)"
          >
            <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
            <path
              d="M19,5 L19,19 L5,19 L5,5 L19,5 M19,3 L5,3 C3.9,3 3,3.9 3,5 L3,19 C3,20.1 3.9,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,5 C21,3.9 20.1,3 19,3 L19,3 Z"
              id="Shape"
              fill="#090909"
              fill-rule="nonzero"
            ></path>
            <path
              d="M14,17 L7,17 L7,15 L14,15 L14,17 Z M17,13 L7,13 L7,11 L17,11 L17,13 Z M17,9 L7,9 L7,7 L17,7 L17,9 Z"
              id="Shape"
              fill="#090909"
              fill-rule="nonzero"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
