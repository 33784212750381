import style from "styled-components/native";

import { Colors, Spacing } from "@constants";

export const Container = style.View`
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 35px;
    background-color: ${Colors.white};
`;

export const Row = style.View`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const FooterText = style.Text`
    font-size: 18px;
    color: ${Colors.footerGray};
    font-family: "manrope";
`;

export const Gap = style.View`
    margin-right: ${Spacing.M}px;
`;

export const Dot = style.View`
    width: 2px;
    height: 2px;
    border-radius: 1px;
    background-color: ${Colors.footerGray};
    margin-top: 1px;
`;

export const IconAndTextContainer = style.Pressable<{ haveBreakLine?: boolean }>`
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: ${({ haveBreakLine }) => ( haveBreakLine ? "5" : Spacing.M )}px;
`;

export const CopyrightText = style.Text`
    font-size: 13px;
    color: ${Colors.footerGray};
    font-family: "manrope";
    margin: 0px 0.5px 2.5px 0px;
`;

export const Copyright = style.View`
    width: 12px;
    height: 12px;
    border-radius: 6px;
    border: 1.5px solid ${Colors.footerGray};
    align-items: center;
    justify-content: center;
    margin-top: 1.5px;
`;
