import React from "react";
import { I18n } from "react-redux-i18n";

interface IProps {
    width?: number;
    height?: number;
}

export const Modify: React.FC<IProps> = ({ width = 30, height = 30 }) => (
    <svg width={`${width}px`} height={`${height}px`} viewBox="0 0 30 30" version="1.1">
        <title>{I18n.t("confirmationPage.edit")}</title>
        <g id="Icons/Edit-medium" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <circle id="Oval" stroke="#090909" cx="15" cy="15" r="14.5"></circle>
            <path
                d="M19.6541235,8 C19.47158,8 19.3302262,8.11572657 19.2211069,8.22493997 L18,9.43991826 L20.5666039,12 L21.8320137,10.7340026 C22.0559954,10.5098277 22.0559954,10.193101 21.8320137,9.9689261 L20.0972711,8.22493997 C19.9824087,8.1099785 19.836667,8 19.6541235,8 Z M16.2147198,11 L8,19.2147198 L8,22 L10.7852802,22 L19,13.7852802 L16.2147198,11 Z"
                id="Shape" fill="#090909" fill-rule="nonzero"></path>
        </g>
    </svg>
);
