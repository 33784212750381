import { NavigationProp, Route } from "@react-navigation/native";
import { View, StyleSheet } from "react-native";
import { HomeScreens, OfferViewModel, OffersActions, CalendarFlow, OffersThunks } from "@modules";
import React, { useEffect, useMemo, useState } from "react";
import {
  BookingTemplate,
  CalendarDays,
  LoaderAnimationComponent,
  OfferOderContainer,
  Offers,
} from "@atomic";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "@redux";
import { ILanguage, IOfferTemplate } from "@foodi/core";
import _ from "lodash";
import { getNumericId } from '@utils';

import { Title22, Title36 } from "@stylesheets";
import { Colors, Spacing } from "@constants";
import { useDevices } from "@hooks";
import { I18n } from "react-redux-i18n";
interface IProps {
  navigation: NavigationProp<any>;
  route?: Route<any>;
  isRefillFromCart?: boolean;
  isModify?: boolean;
  posId: any;
}

export const ClickAndCollectTemplate: React.FC<IProps> =
  ({ navigation, route, isRefillFromCart, posId, isModify }) => {
    const currentPosIdFromParams = _.get(route, "params.id");
    const [isMobile] = useDevices();

    const styles = useMemo(() => _styles(isMobile), [isMobile]);

    //States
    const [isLoading, setIsLoading] = useState(false);
    const { type }: any = route?.params || {};
    const [offers, setOffers] = useState<IOfferTemplate[]>([]);
    const [isOfferFetching, setIsOfferFetching] = useState(false);
    const [offerTemplatesError, setOfferTemplatesError] = useState();
    const userInfo = useSelector((state: GlobalState) => state.auth?.userInfo);
    const userLanguage = (userInfo?.language as ILanguage) || ILanguage.fr;
    const dispatch = useDispatch();
    const [offerVM] = useState(new OfferViewModel(dispatch));
    
    const cartCurrentInfo = useSelector((state: GlobalState) => {
      const selectedPos = state.pointOfSale?.selectedPos?.pointOfSale;
      if (selectedPos && getNumericId(selectedPos.id) === currentPosIdFromParams) {
          return state.pointOfSale?.cartCurrentInfo;
      }
      return undefined;
    });
    const maxRequestTries = useSelector(
      (state: GlobalState) => state.offers?.maxRequestTries
    );
    const isBookingSelected = useSelector(
      (state: GlobalState) => state?.booking?.isBookingSelected
    );
    const { fullBookingCartEnabled } = useSelector(
      (state: GlobalState) => state?.pointOfSale
    );
    const hideMenuBooking = useSelector(
      (state: GlobalState) =>
        state.pointOfSale?.selectedPos?.pointOfSale.hideMenuBooking
    );

    const reloadDelayVariable = Number(window.config?.OFFER_PAGE_RELOAD_DELAY_MILLISECONDS);
    const RELOAD_DELAY_SECONDS = reloadDelayVariable / 1000;

    const handleOffers = async () => {
      setIsLoading(true);
      try {
        //@ts-ignore
        const { offerTemplates } = await dispatch(
          OffersThunks.getOfferTemplates({
            type: "CLICK_COLLECT",
            withdrawalType:
              "CONNECTED_LOCKERS,POS_TAKE_AWAY,POS_AT_SITE,TABLE_SERVICE,POS_CLICK_SERVE,INSTANT_CLICK_COLLECT,CLICK_AND_PICK_UP",
            language: userLanguage,
            //@ts-ignore
            posId: currentPosIdFromParams,
          })
        );
        const currentType = type || cartCurrentInfo?.type;
        setOffers(
            offerVM
                //@ts-ignore
                .sortOffers(offerTemplates.filter(offerTemplate => offerVM.hasAvailableOffer(offerTemplate)), currentType)
                .filter(item=> getNumericId(item.pos?.id) === currentPosIdFromParams));
        dispatch(OffersActions.setRequestTries(1));
      } catch(error: any) {
        setOfferTemplatesError(error);
      } finally {
        setIsLoading(false);
      }
    };

    useEffect(() => {
      // FS-8462: This provides an auto refresh to the page in case the offer templates request fails
      if (offerTemplatesError) {
        const reloadTimeout = setTimeout(() => {
          dispatch(OffersActions.setRequestTries(maxRequestTries + 1));
          document.location.reload();
        }, reloadDelayVariable);

        if (maxRequestTries > Number(window.config?.MAX_OFFER_REQUEST_TRIES)) navigation.navigate(HomeScreens.HOME_SCREEN);
        
        return () => clearTimeout(reloadTimeout);
      }
    }, [offerTemplatesError]);

    useEffect(() => {
      handleOffers();
    }, [userInfo, posId, currentPosIdFromParams]);

    if (isLoading) {
      return (
        <View style={styles.loadingStyle}>
          <LoaderAnimationComponent />
        </View>
      );
    }

    return (
        <View style={styles.container}>
          <Offers
            isRefillFromCart={isRefillFromCart}
            offers={offers}
            setIsOfferFetching={setIsOfferFetching}
            offerTemplatesError={offerTemplatesError}
            refreshDelay={RELOAD_DELAY_SECONDS}
            route={route}
          />
          {isBookingSelected && !hideMenuBooking ? (
            <View style={styles.bookingView}>
              {isMobile ? (
                <Title22 isBlack style={styles.title}>
                  {I18n.t("restaurantDetail.menuTitle")}
                </Title22>
              ) : (
                <Title36>{I18n.t("restaurantDetail.menuTitle")}</Title36>
              )}
              {!fullBookingCartEnabled && (
                <CalendarDays
                  style={styles.calendarView}
                  isModify={isModify}
                  userLanguage={userLanguage}
                  calendarFlow={CalendarFlow.BOOKING}
                />
              )}
              <BookingTemplate
                navigation={navigation}
                route={route}
                isModify={isModify}
              />
          </View>
        ) : (
          <OfferOderContainer
            navigation={navigation}
            route={route}
            isRefillFromCart={isRefillFromCart}
            isOfferFetching={isOfferFetching}
          />
        )}
      </View>
    );
  };

const _styles = (isMobile: boolean) =>
  StyleSheet.create({
    container: {
      flex: 1,
      width: "100%",
    },
    loadingStyle: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      minHeight: "500px",
    },
    calendarView: {
      marginVertical: Spacing.XL,
      alignItems: "flex-start",
      paddingLeft: isMobile ? 18 : 0,
    },
    title: {
      paddingLeft: isMobile ? 18 : 0,
    },
    bookingView: {
      backgroundColor: Colors.background1,
      paddingHorizontal: 150,
      marginHorizontal: -150,
      alignItems: "flex-start",
      paddingTop: 7,
    },
  });
