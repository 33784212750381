import { Colors } from "@constants";
import styled from "styled-components/native";

export const Container = styled.View`
    z-index: 1;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vW;
`;

export const ButtonContainer = styled.View`
    height: 60px;
    background-color: ${Colors.black};
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding-left: 20px;
    padding-right: 20px;
`;

