import { Colors } from "@constants";
import style from "styled-components/native";

export const Container = style.TouchableOpacity`
    height: 20px;
    width: 20px;
    border-radius: 10px;
    border-width: 2px;
    border-color: ${Colors.black};
    align-items: center;
    justify-content: center;
`;

export const Content = style.View`
    height: 10px;
    width: 10px;
    border-radius: 5px;
    background-color: ${Colors.black};
`;
