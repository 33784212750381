import { Back, CheckCircle, CheckCircleMobile } from "@assets";
import { ScreenWithFooter } from "@atomic";
import {
  ConfirmOrderCommand,
  OfferTemplateWithdrawalType as WithdrawalType,
} from "@foodi/core";
import {
  AccountThunks,
  HomeScreens,
  OrderViewModel,
  PointOfSaleActions,
} from "@modules";
import { NavigationProp, Route } from "@react-navigation/native";
import { GlobalState, LoaderActions } from "@redux";
import { TestIDs } from "@utils";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { I18n } from "react-redux-i18n";
import { BookingContent } from "./BookingContent";
import { ClickAndCollectContent } from "./ClickAndCollectContent";
import {
  Container,
  Title,
  GoBackContainer,
  GoBackText,
  ContentContainer,
} from "./ConfirmationPage.style";
import { useDevices } from "@hooks";

interface IProps {
  navigation?: NavigationProp<any>;
  route?: Route<any>;
}

export const ConfirmationPage: React.FC<IProps> = React.memo(
  ({ navigation, route }) => {
    const dispatch = useDispatch();
    const [isMobile] = useDevices();

    const { isMyOrderListDetail }: any = route?.params || {};
    
    const confirmationPageParams = useSelector(
      (state: GlobalState) => state?.confirmationPage?.params
    );
    const isBooking = useSelector(
      (state: GlobalState) => state?.booking?.isBookingSelected
    );

    const [order, setOrder] = React.useState<any>();

    const userLanguage = useSelector(
      (state: GlobalState) => state.auth?.userInfo?.language
    );
    const userId = useSelector(
      (state: GlobalState) => state.auth?.userInfo?.id
    );

    const { orderId, isFirstTime, idPos, offerSlot } = confirmationPageParams;

    const orderVM = new OrderViewModel(dispatch, "");

    React.useEffect(() => {
      if (userId) {
        handleBalance(userId);
      }
      if (orderId) {
        dispatch(LoaderActions.setLoading(true));
        if (isBooking) {
          orderVM
            .getBooking({
              idOrder: orderId,
            } as ConfirmOrderCommand)
            //@ts-ignore
            .then(setOrder)
            .finally(() => {
              dispatch(LoaderActions.setLoading(false));
            });
        } else {
          orderVM
            .getOrderCC({
              idOrder: orderId,
            } as ConfirmOrderCommand)
            //@ts-ignore
            .then(setOrder)
            .finally(() => {
              dispatch(LoaderActions.setLoading(false));
            });
        }
      }
    }, [orderId, offerSlot, userId]);

    const handleBalance = (id: string) => {
      dispatch(
        AccountThunks.getBalance({
          id,
        })
      );
    };

    const handleGoBack = () => {
      isMyOrderListDetail ? navigation?.goBack() : navigation?.navigate(HomeScreens.HOME_SCREEN);
    };

    const isInstantCCOrClickPickup: boolean =
    order?.offer?.offerTemplate?.withdrawalType ===
      WithdrawalType.INSTANT_CLICK_COLLECT ||
    order?.offer?.offerTemplate?.withdrawalType ===
      WithdrawalType.CLICK_AND_PICK_UP;

    const date = orderVM.getDateDescription(
      order?.withdrawRange || "",
      userLanguage,
      isInstantCCOrClickPickup,
      order?.offer?.offerTemplate?.withdrawalType === WithdrawalType.CLICK_AND_PICK_UP
    );
    const cancelDate = orderVM.getSmallDateDescription(
      order?.cancelableUntil || ""
    );

    const information = isBooking
      ? "confirmationPage.reservation"
      : "confirmationPage.purchase";

    const thankYou = isMobile
      ? "confirmationPage.thankYouPurchaseMobile"
      : "confirmationPage.thankYouPurchase";

    return (
      <ScreenWithFooter navigation={navigation || ({} as any)}>
        <Container
          testID={TestIDs.home.confirmationPage.views.mainContainer}
          isMobile={isMobile}
        >
          <GoBackContainer
            testID={TestIDs.home.confirmationPage.actions.goBack}
            onPress={handleGoBack}
          >
            <Back />
            <GoBackText isMobile={isMobile}>
              {isMyOrderListDetail ? I18n.t("common.return") : I18n.t("common.returnHome")}
            </GoBackText>
          </GoBackContainer>
          <ContentContainer>
            {isFirstTime && (
              <>
                {isMobile ? <CheckCircleMobile /> : <CheckCircle />}
                <Title isMobile={isMobile}>
                  {I18n.t(thankYou, {
                    label: I18n.t(information),
                  })}
                </Title>
              </>
            )}
            {isBooking ? (
              <BookingContent
                navigation={navigation}
                isFirstTime={isFirstTime}
                order={order}
                date={date}
                idPos={idPos}
              />
            ) : (
              <ClickAndCollectContent
                navigation={navigation}
                order={order}
                date={date}
                cancelDate={cancelDate}
                idPos={idPos}
              />
            )}
          </ContentContainer>
        </Container>
      </ScreenWithFooter>
    );
  }
);
