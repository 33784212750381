import { Colors } from "@constants";
import styled from "styled-components/native";

export const Container = styled.View<{ isMobile?: boolean }>`
  background-color: ${Colors.white};
  align-items: center;
  text-align: center;
  padding-top: ${({ isMobile }) => (isMobile ? 30 : 50)}px;
  padding-bottom: ${({ isMobile }) => (isMobile ? 20 : 30)}px;
`;

export const NameContainer = styled.View`
  align-items: flex-start;
  text-align: center;
`;

export const LoginDataContainer = styled.View<{ isMobile?: boolean }>`
  margin-top: 30px;
  align-items: center;
  text-align: center;
  width: ${({ isMobile }) => (isMobile ? 320 : 350)}px;
`;

export const CheckBoxContainer = styled.View<{ isMobile?: boolean }>`
  margin-top: 30px;
  width: 400px;
  margin-left: ${({ isMobile }) => (isMobile ? 80 : 50)}px;
  margin-bottom: 10px;
`;

export const RowCheckBoxContainer = styled.View`
  flex-direction: row;
  margin-bottom: 19px;
`;

export const Row = styled.View`
  margin-top: 10px;
  flex-direction: row;
`;

export const Text = styled.Text<{ isBold?: boolean; isUnderline?: boolean }>`
  margin-left: 5px;
  color: ${Colors.foodiBlack};
  flex-direction: row;
  text-align: left;
  font-family: ${({ isBold }) => (isBold ? "manrope-bold" : "manrope")};
  font-size: 13;
  text-decoration: ${({ isUnderline }) => (isUnderline ? "underline" : "")};
`;
